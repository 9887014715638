import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DebtorAddressMatchingDTO } from '../../../../../apiclient/v1.1/models';

@Component({
  selector: 'app-company-address-matching-display',
  templateUrl: './company-address-matching-display.component.html',
  styleUrls: ['./company-address-matching-display.component.scss'],
})
export class CompanyAddressMatchingDisplayComponent implements OnInit {
  @Input() matchingResults: DebtorAddressMatchingDTO[];

  @Output() matchSelected = new EventEmitter<DebtorAddressMatchingDTO>();
  @Output() actionTaken = new EventEmitter<boolean>();

  public buyIconPath = '../../../../assets/images/factoring/buy-outline.png';
  public noBuyIconPath = '../../../../assets/images/factoring/nobuy-outline.png';

  constructor() {}

  public ngOnInit(): void {}

  public getMatchStreetAddress(match: DebtorAddressMatchingDTO): string {
    return `${match.address.line1} ${match.address.line2 ? match.address.line2 : ''}`;
  }

  public matchSelectedEvent(match: DebtorAddressMatchingDTO) {
    this.matchSelected.emit(match);
  }

  public cancel(): void {
    this.actionTaken.emit(false);
  }

  public create(): void {
    this.actionTaken.emit(true);
  }
}
