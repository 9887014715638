<div class="formgrid grid" [formGroup]="formGroup">
  <app-form-field
    class="col-12"
    label="Category"
    type="select"
    placeholder="Select Category"
    [selectOptions]="mainCategories"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    formControlName="category"
    labelClass="p-2"
    controlClass="col-12 px-2 py-1"
    errorClass="col-12 pl-2"
  ></app-form-field>

  <div class="col-12">
    <div class="col-12 p-2 label-black">Subcategory</div>
    <div class="col-12 px-2 pt-1">
      <p-autoComplete
        styleClass="w-full"
        formControlName="subcategory"
        placeholder="All"
        [suggestions]="subcategoryOptions"
        (completeMethod)="filterSubcategoryOptions($event)"
        field="label"
        [dropdown]="true"
        [forceSelection]="true"
      >
      </p-autoComplete>
    </div>
  </div>
  <div class="col-12">
    <div class="grid px-1">
      <div class="col-6">
        <app-form-field
          class="col-12"
          label="From"
          type="calendar"
          placeholder="mm/dd/yyyy"
          [formGroup]="formGroup"
          [formErrors]="formErrors"
          formControlName="fromDate"
          labelClass="col-12 pl-1 py-2"
          controlClass="col-12 pl-1 py-1"
          errorClass="col-12 pl-1"
        ></app-form-field>
      </div>
      <div class="col-6">
        <app-form-field
          class="col-12"
          label="To"
          type="calendar"
          placeholder="mm/dd/yyyy"
          [formGroup]="formGroup"
          [formErrors]="formErrors"
          formControlName="toDate"
          labelClass="col-12 pl-1 py-2"
          controlClass="col-12 pr-1 py-1"
          errorClass="col-12 pl-1"
        ></app-form-field>
      </div>
    </div>
  </div>

  <app-form-field
    class="col-12"
    label="Date Type"
    type="select"
    placeholder="Select Date Filter"
    [selectOptions]="dateTypes"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    formControlName="dateType"
    labelClass="col-12 p-2 pt-0"
    controlClass="col-12 px-2 py-1"
    errorClass="col-12 pl-2"
  ></app-form-field>

  <div class="col-12 mx-1 pr-3 pt-3">
    <div
      class="flex align-items-center cursor-pointer"
      (click)="showAdvancedSettings = !showAdvancedSettings"
      id="advanced-settings"
    >
      <i class="pl-1" [ngClass]="showAdvancedSettings ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i>
      <span><strong>Advanced Settings</strong></span>
    </div>
    <div *ngIf="showAdvancedSettings" class="col-12 align-items-center pt-1">
      <p-checkbox
        class="pl-1"
        label="Include Multiple Stop Shippers/Consignees"
        formControlName="includeMultipleShippersConsignees"
        binary="true"
      ></p-checkbox>
      <i
        class="fas fa-question-circle pl-2 tooltip"
        pTooltip="Select this options if your customer base has multiple picks and drops on a consistent basis"
        tooltipPosition="right"
      ></i>
    </div>
  </div>
</div>
