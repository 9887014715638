import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FactoringAccountExecutiveDTO } from '../../../../apiclient/v1.1/models';
import { AccountExecutivesService } from '../../../../services/account-executives/account-executives.service';
import { FundingStatus } from '../../funding-status-icon/funding-status';
import { FundingStatusIconColors } from '../../funding-status-icon/funding-status-icon-colors';

@Component({
  selector: 'app-load-status-overlay',
  templateUrl: './load-status-overlay.component.html',
  styleUrls: ['./load-status-overlay.component.scss'],
})
export class LoadStatusOverlayComponent implements OnInit {
  @Input() status: string;

  public spacer = '../../../../assets/images/factoring/spacer.png';
  public check = '../../../../assets/images/factoring/check-outline.png';
  public fundingStatus = FundingStatus;

  public accountExecutive$: Observable<FactoringAccountExecutiveDTO>;

  constructor(private accountExecutivesService: AccountExecutivesService) {}

  public ngOnInit(): void {
    this.accountExecutive$ = this.accountExecutivesService.getAccountExecutives().pipe(
      filter((accountExecutives) => accountExecutives && accountExecutives.length > 0),
      map((accountExecutives) => accountExecutives[0])
    );
  }

  public getStageOneIcon(): string {
    switch (this.status) {
      case FundingStatus.Queued:
        return FundingStatusIconColors.DarkGreyOutline;
      case FundingStatus.Submitted:
      case FundingStatus.OnHold:
        return FundingStatusIconColors.LightGrey;
      case FundingStatus.Funded:
        return FundingStatusIconColors.LightGreyOutline;
    }
  }

  public getStageTwoIcon(): string {
    switch (this.status) {
      case FundingStatus.Queued:
      case FundingStatus.OnHold:
      case FundingStatus.Funded:
        return FundingStatusIconColors.LightGrey;
      case FundingStatus.Submitted:
        return FundingStatusIconColors.DarkGrey;
    }
  }

  public getStageThreeIcon(): string {
    switch (this.status) {
      case FundingStatus.Queued:
      case FundingStatus.Submitted:
        return FundingStatusIconColors.LightGrey;
      case FundingStatus.OnHold:
        return FundingStatusIconColors.Red;
      case FundingStatus.Funded:
        return FundingStatusIconColors.Green;
    }
  }

  public getCurrentStage(): number {
    switch (this.status) {
      case FundingStatus.Queued:
        return 1;
      case FundingStatus.Submitted:
        return 2;
      case FundingStatus.OnHold:
      case FundingStatus.Funded:
        return 3;
    }
  }

  public showStatusCheck(): boolean {
    return this.status === FundingStatus.Funded;
  }

  public getStatusText(): string {
    switch (this.status) {
      case FundingStatus.Queued:
        return 'Queued for Submission';
      case FundingStatus.Submitted:
        return 'Submitted';
      case FundingStatus.OnHold:
        return 'On Hold - Contact Account Manager';
      case FundingStatus.Funded:
        return 'Funded';
    }
  }
}
