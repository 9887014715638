<div class="centered-card-container-small">
  <p-card [style]="{ padding: '10%' }">
    <div *ngIf="!formSubmitted" id="sign-up-form">
      <div class="header-text">Sign Up</div>
      <form [formGroup]="signUpForm" class="grid">
        <app-form-field
          class="col-6"
          placeholder="First Name"
          type="text"
          labelClass="col-12"
          controlClass="col-12"
          errorClass="col-12"
          [formGroup]="signUpForm"
          [formErrors]="formErrors"
          formControlName="firstName"
        ></app-form-field>
        <app-form-field
          class="col-6"
          placeholder="Last Name"
          type="text"
          labelClass="col-12"
          controlClass="col-12"
          errorClass="col-12"
          [formGroup]="signUpForm"
          [formErrors]="formErrors"
          formControlName="lastName"
        ></app-form-field>
        <app-phone-number-v2
          class="col-12"
          placeholder="Phone Number"
          labelClass="col-12"
          controlClass="col-12 p-1"
          errorClass="col-12"
          formControlName="phoneNumber"
          [formErrors]="formErrors"
          [touched]="signUpForm.controls['phoneNumber'].touched"
          [dirty]="signUpForm.controls['phoneNumber'].dirty"
        ></app-phone-number-v2>
        <app-form-field
          class="col-12"
          placeholder="Email Address"
          type="text"
          labelClass="col-12"
          controlClass="col-12"
          errorClass="col-12"
          [formGroup]="signUpForm"
          [formErrors]="formErrors"
          formControlName="email"
        ></app-form-field>
        <div class="action-footer">
          <div class="submit-button">
            <button pButton type="submit" label="Sign Up" (click)="signUp()"></button>
          </div>
          <div>
            <div>
              <span>Already have an account </span>
              <a class="fauxlink" (click)="routeToLogin()">Log In</a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="formSubmitted" class="confirmation-container">
      <div class="confirmation-block"
        >Thanks for signing up for Truxio, {{ signUpForm.controls['firstName'].value }}, we have sent a confirmation to
        your email <strong>{{ signUpForm.controls['email'].value }}</strong
        >. Please follow the link in the email to complete your registration.
      </div>
      <div class="confirmation-block">
        <span
          >If you do not receive the email, please check your spam folder and ensure the email you entered is correct.
          If the problem persists, please reach out to our
        </span>
        <a class="fauxlink" [href]="supportLink" target="_blank">
          support team
          <i class="fas fa-external-link-alt text-xs"></i> </a
        >.
      </div>

      <div class="back-button">
        <span>Back to </span>
        <a class="fauxlink" (click)="routeToLogin()">Log In</a>
      </div>
    </div>
  </p-card>
</div>
