<td
  *ngFor="let col of columns"
  (click)="cellClick(col, row)"
  class="{{ cellClass(col, row) }}"
  [class.ui-resizable-column]="isResizable"
  [ngSwitch]="col.format"
>
  <ng-container *ngSwitchCase="'Actions'">
    <div class="table-action-cell">
      <div *ngFor="let ab of col.buttons">
        <button
          pButton
          type="button"
          (click)="actionHandler(row, ab)"
          *ngIf="showButton(ab, row)"
          icon="{{ iconClass(ab, row) }}"
          pTooltip="{{ showTooltip(ab, row) }}"
          class="{{ ab.class }} {{ iconColor(ab, row) }}"
        >
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'cssCell'">
    <span [innerHTML]="cellFormat(col, row)" class="{{ cellClass(col, row) }}"></span>
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <p-checkbox [(ngModel)]="row.isChecked" binary="true"></p-checkbox>
  </ng-container>
  <ng-container *ngSwitchCase="'readonlyCheckbox'">
    <p-checkbox [(ngModel)]="row[col.field]" binary="true" disabled="true"></p-checkbox>
  </ng-container>
  <ng-container *ngSwitchCase="'conditionalCheckbox'">
    <p-checkbox
      [(ngModel)]="row.isChecked"
      binary="true"
      [disabled]="!(col.conditions.values.indexOf(row[col.conditions.field]) === -1)"
    >
    </p-checkbox>
  </ng-container>
  <ng-container *ngSwitchCase="'statusAlert'">
    <span [innerHTML]="cellFormat(col, row)"></span>
    <i
      class="fa fa-exclamation-triangle documentNeeded"
      *ngIf="(!row.hasPOD && !row.hasBOL) || row.payStatus === 'Alert' || row.payStatus === 'PaidWithAlert'"
      [pTooltip]="!row.hasPOD && !row.hasBOL ? 'Either POD or BOL Needed' : 'Documents Needed'"
    ></i>
  </ng-container>
  <ng-container *ngSwitchCase="'dateWithAttachmentIcon'">
    <span [innerHTML]="cellFormat(col, row)"></span>
    <i class="fa fa-file payDate" *ngIf="row.payDate" pTooltip="Click to review this settlement"></i>
  </ng-container>

  <ng-container *ngSwitchCase="'actionCellLoad'">
    <span [innerHTML]="cellFormat(col, row)"></span>
    <!-- The red icon is intended for claims/mediation -->
    <!-- <i class="fa fa-exclamation-circle needsAlert"
		   *ngIf="needsAlert(row)"
      ></i> -->
  </ng-container>

  <ng-container *ngSwitchCase="'currency'">
    {{ fieldValue(row, col.field) | currency : 'USD' : 'symbol' }}
  </ng-container>

  <ng-container *ngSwitchCase="'fundingAmount'">
    <div class="funding-amount-container">
      <div class="funding-amount">
        {{ fieldValue(row, col.field) | currency : 'USD' : 'symbol' }}
      </div>
      <app-load-status
        *ngIf="row.eligibleForFactoring && fieldValueWithNulls(row, col.innerField)"
        class="funding-status"
        [status]="fieldValue(row, col.innerField)"
      ></app-load-status>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'Tracking'">
    <span [innerHTML]="cellFormat(col, row)" style="display: none"></span>
    <span>
      <i class="fas fa-clock statusicon {{ getLegStatusClass(row) }}" *ngIf="getLegstatus(row) !== ''"></i>
      <span class="cellClass(col,row)">{{ getLegstatus(row) | formatErrorMessage }}</span>
      <i
        class="fa fa-exclamation-triangle statusicon warn"
        *ngIf="needsAlert(row)"
        pTooltip="{{ alertText }}"
        style="float: right"
      ></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'TrackingV2'">
    <i class="fas fa-clock statusicon {{ cellFormat(col, row) == 'Delayed' ? 'redclock' : 'greenclock' }}"></i>
    {{ cellFormat(col, row) }}
  </ng-container>

  <ng-container *ngSwitchCase="'buyStatusWithIcon'">
    <div class="buy-status-with-icon-container">
      <span>
        {{ row.buyNoBuy | buyStatus }}
      </span>
      <img
        class="status-icon"
        src="{{ !row.buyNoBuy ? noBuyIconPath : buyIconPath }}"
        pTooltip="{{ row.buyNoBuy | buyStatus }}"
      />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'LockedOut'">
    <span
      name="locked-out-field"
      [innerHTML]="cellFormat(col, row)"
      [pTooltip]="row.isLockedOut ? 'User has made too many login attempts and is locked. Contact Truxio support.' : ''"
    ></span>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span [innerHTML]="cellFormat(col, row)"></span>
    <i
      class="fa {{ col.documentParams.icon }} defaultColumn"
      *ngIf="col.viewDocumentIcon && row[col.documentParams.documentIdField]"
      pTooltip="View"
    ></i>
  </ng-container>

  <div class="alert-div" *ngIf="col.displayAlerts">
    <i class="fa fa-exclamation-triangle alert" *ngIf="needsAlert(row)" pTooltip="{{ alertText }}"></i>
  </div>
</td>
