import { Component, OnInit, Input, Injector, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { FormGroup } from '@angular/forms';
import { LoadLegStopDTO, SimpleAddressDTO, SimpleContactDTO } from 'src/apiclient/models';

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss'],
})
export class StopsComponent extends DtoFormBase<LoadLegStopDTO> implements OnInit, ControlValueAccessor, OnChanges {
  companyShipperId: number;

  @Input() formGroup: FormGroup;
  @Input() stops: Array<LoadLegStopDTO> = new Array<LoadLegStopDTO>();
  @Input() readonly: boolean = false;

  @Output() stopsChanged: EventEmitter<Array<LoadLegStopDTO>> = new EventEmitter<Array<LoadLegStopDTO>>();
  @Output() stopDragged: EventEmitter<LoadLegStopDTO> = new EventEmitter<LoadLegStopDTO>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.sortStops();
  }

  private propagateChange = (_: any) => {};

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.stopsModelChanged();
  }

  private stopsModelChanged() {
    this.propagateChange(this.stops);
    this.stopsChanged.emit(this.stops);
  }

  writeValue(stopsObj: Array<LoadLegStopDTO>): void {
    this.stops = stopsObj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }

  dragAStop($event, stop) {
    this.stopDragged.emit(stop);
  }

  addStop() {
    setTimeout(() => {
      if (!this.stops) {
        this.stops = new Array();
      }
      const newStop = { companyAddress: {} } as LoadLegStopDTO;
      newStop.stopType = 'Pick';
      newStop.address = {} as SimpleAddressDTO;
      newStop.contact = {} as SimpleContactDTO;
      this.stops.push(newStop);
      this.stopsModelChanged();
    });
  }

  removeStop(stop) {
    setTimeout(() => {
      const index = this.stops.indexOf(stop);
      this.stops.splice(index, 1);
      this.stopsModelChanged();
    });
  }

  getStopTypeCount(stop: LoadLegStopDTO) {
    return this.stops.filter((s) => s.stopType === stop.stopType).indexOf(stop) + 1;
  }

  stopChanged($event: LoadLegStopDTO, idx) {
    setTimeout((_) => {
      if (this.stops[idx].loadLegStopId !== $event.loadLegStopId) {
        throw new Error('Unable to match stop on index');
      }
      this.stops[idx] = $event;
    });
  }

  sortStops() {
    if (!this.stops) {
      return;
    }
    const sortedStops = this.stops.sort((stopA, stopB) => {
      if (!stopB.scheduledTime || !stopA.scheduledTime) {
        return 0;
      }
      return new Date(stopA.scheduledTime).getTime() - new Date(stopB.scheduledTime).getTime();
    });
    setTimeout((_) => {
      this.stops = sortedStops;
      this.stopsModelChanged();
    });
  }

  formatScheduledTimeDate(dt) {
    const dtObj = new Date(dt);
    return dtObj.toLocaleDateString();
  }

  formatScheduledTimeTime(dt) {
    const dtObj = new Date(dt);
    const formattedTime = dtObj.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });

    // typescript version too old for hourCycle property that would fix showing 24:00
    if (formattedTime === '24:00') {
      return '00:00';
    }
    return formattedTime;
  }
}
