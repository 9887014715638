<div *ngIf="loading">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div class="layout-content-container p-0">
  <form [formGroup]="formGroup">
    <app-edit-section
      title="System"
      (saved)="saveSystemSettings('system')"
      (cancelled)="reset('system')"
      [(editMode)]="editing.system"
      [disableSave]="disableSave"
    >
      <div read>
        <app-edit-section-row label="Payment terms default">
          <span class="font-bold"> {{ model.paymentTermDefault }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Session timeout default">
          <span class="font-bold"> {{ model.userSessionTimeout }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Mobile session timeout default">
          <span class="font-bold"> {{ model.mobileSessionTimeout }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Impersonation session timeout">
          <span class="font-bold"> {{ model.impersonationSessionTimeout }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Max session timeout">
          <span class="font-bold"> {{ model.userSessionTimeoutMax }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Magic match radius">
          <span class="font-bold"> {{ model.magicMatchRadius }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Wrong password limit">
          <span class="font-bold"> {{ model.wrongPasswordLimit }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Load status update hours">
          <span class="font-bold"> {{ model.loadStatusUpdateHours }} </span>
        </app-edit-section-row>

        <app-edit-section-row label="Registration whitelist csv (When set only these domains allowed)">
          <span class="font-bold"> {{ model.registrationWhitelistCsv }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Registration blacklist csv (Blocks registrations from these domains)">
          <span class="font-bold"> {{ model.registrationBlacklistCsv }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Address to send new tenant alert email (Use ; to separate multiple addresses)">
          <span class="font-bold"> {{ model.newTenantAlertEmail }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="GPS Throttle Hours">
          <span class="font-bold"> {{ model.gpsThrottleHours }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="GPS Throttle Miles">
          <span class="font-bold"> {{ model.gpsThrottleMiles }} </span>
        </app-edit-section-row>
      </div>
      <div edit>
        <app-edit-section-row label="Payment terms default">
          <app-form-field
            [(formGroup)]="formGroup"
            type="select"
            [config]="{ selectOptions: paymentTermsData }"
            formControlName="paymentTermDefault"
            [(ngModel)]="model.paymentTermDefault"
            [formErrors]="formErrors"
            placeholder="Required"
            labelClass="nolabel"
            controlClass="col-4 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Session timeout default">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="userSessionTimeout"
            [(ngModel)]="model.userSessionTimeout"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.userSessionTimeout }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Mobile session timeout default">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="mobileSessionTimeout"
            [(ngModel)]="model.mobileSessionTimeout"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.mobileSessionTimeout }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Impersonation session timeout">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="impersonationSessionTimeout"
            [(ngModel)]="model.impersonationSessionTimeout"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.impersonationSessionTimeout }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Max session timeout default">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="userSessionTimeoutMax"
            [(ngModel)]="model.userSessionTimeoutMax"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.userSessionTimeoutMax }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Magic match radius">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="magicMatchRadius"
            [(ngModel)]="model.magicMatchRadius"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.magicMatchRadius }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Wrong password limit">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="wrongPasswordLimit"
            [(ngModel)]="model.wrongPasswordLimit"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.wrongPasswordLimit }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Load status update hours">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="loadStatusUpdateHours"
            [(ngModel)]="model.loadStatusUpdateHours"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.loadStatusUpdateHours }}</div>
        </app-edit-section-row>

        <app-edit-section-row label="Registration whitelist csv (When set only these domains allowed)">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="registrationWhitelistCsv"
            [(ngModel)]="model.registrationWhitelistCsv"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.registrationWhitelistCsv }}</div>
        </app-edit-section-row>

        <app-edit-section-row label="Registration blacklist csv (Blocks registrations from these domains)">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="registrationBlacklistCsv"
            [(ngModel)]="model.registrationBlacklistCsv"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.registrationBlacklistCsv }}</div>
        </app-edit-section-row>

        <app-edit-section-row label="Address to send new tenant alert email (Use ; to separate multiple addresses)">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="newTenantAlertEmail"
            [(ngModel)]="model.newTenantAlertEmail"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.newTenantAlertEmail }}</div>
        </app-edit-section-row>

        <app-edit-section-row label="GPS Throttle Hours">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="gpsThrottleHours"
            [(ngModel)]="model.gpsThrottleHours"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.gpsThrottleHours }}</div>
        </app-edit-section-row>

        <app-edit-section-row label="GPS Throttle Miles">
          <input
            pInputText
            class="col-4 p-1"
            type="text"
            formControlName="gpsThrottleMiles"
            [(ngModel)]="model.gpsThrottleMiles"
            placeholder=""
          />
          <div class="col-12 px-0 py-1 formerrors">{{ formErrors.gpsThrottleMiles }}</div>
        </app-edit-section-row>
      </div>
    </app-edit-section>
  </form>
</div>
