import { Component, EventEmitter, Input, Output, OnInit, Injector, OnChanges } from '@angular/core';
import { CompanyAddressDTO, AddressDTO, SimpleContactDTO } from '../../../../apiclient/models';
import { DebtorAddressMatchingDTO } from '../../../../apiclient/v1.1/models';
import { HandleErrorBase } from '../../../shared/HandleErrorBase';

@Component({
  selector: 'app-company-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent extends HandleErrorBase implements OnInit, OnChanges {
  @Input() companyId: number;
  @Input() primaryContact: any;
  @Input() creditLimitAccess: boolean;
  @Input() addressId: number;
  @Input() addresses: CompanyAddressDTO[];
  @Input() formSubmitted: boolean;
  @Input() collapsed: boolean;
  @Input() parentVisible: boolean;
  index: number = -1;
  @Input() selectedIndex: number;
  @Output() newCreditLimitChanged = new EventEmitter<any>();
  @Output() creditLimitError = new EventEmitter();
  @Output() doneUpdatesEvent = new EventEmitter();
  @Output() debtorSelectedEvent = new EventEmitter<DebtorAddressMatchingDTO>();
  @Output() companyAddressMergeEvent = new EventEmitter<DebtorAddressMatchingDTO>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (this.collapsed) {
      this.index = -1;
    }
  }

  ngOnChanges() {
    if (this.addresses.length === 0) {
      this.addAddress();
    } else {
      if (this.addressId > 0) {
        this.selectedIndex = this.addresses.findIndex((address) => address.companyAddressId === Number(this.addressId));
        window.scrollTo(0, 0);
      }
      if (this.collapsed) {
        this.index = -1;
      } else {
        this.index = this.selectedIndex;
      }
    }
  }

  addAddress() {
    const newAddress = {} as CompanyAddressDTO;
    newAddress.address = {} as AddressDTO;
    newAddress.primaryContact = {} as SimpleContactDTO;
    newAddress.billToContact = {} as SimpleContactDTO;
    newAddress.shipperContact = {} as SimpleContactDTO;
    newAddress.consigneeContact = {} as SimpleContactDTO;
    newAddress.isBillTo = true;
    newAddress.isConsignee = false;
    newAddress.isShipper = false;
    this.addresses.push(newAddress);
    this.index = this.addresses.length - 1;
    console.log('index on addAddress ' + this.index);
    setTimeout((_) => {
      this.index = -1;
    }, 30);
    setTimeout((_) => {
      this.index = this.addresses.length - 1;
    }, 30);
  }

  addressTypes(address) {
    const types =
      (address.isBillTo ? 'Bill To, ' : '') +
      (address.isConsignee ? 'Consignee, ' : '') +
      (address.isShipper ? 'Shipper, ' : '');
    return types.trim().replace(/^[,]+|[,]+$/g, '');
  }
  onCreditLimitChanged(event) {
    this.newCreditLimitChanged.emit(event);
  }

  onCreditLimitError(event) {
    this.creditLimitError.emit(event);
  }

  doneUpdatesHandler() {
    this.doneUpdatesEvent.emit(true);
  }

  public onDebtorSelected(event: DebtorAddressMatchingDTO): void {
    this.debtorSelectedEvent.emit(event);
  }

  public onCompanyAddressMerge(recordToMergeWith: DebtorAddressMatchingDTO): void {
    this.companyAddressMergeEvent.emit(recordToMergeWith);
  }
}
