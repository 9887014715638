import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfig } from './config/app.config';
import { Error404Component } from './core/error404/error-404.component';
import { TenantTypeAuthorizationGuard } from './shared/guards/authorization/tenant-type-authorization.guard';
import { AuthorizationDataProperties } from './shared/guards/authorization/authorization-data-properties';
import { TenantType } from './data/tenant-type';

const routes: Routes = [
  {
    path: AppConfig.routes.error404,
    component: Error404Component,
  },
  {
    path: AppConfig.routes.documentViewer,
    loadChildren: () => import('./modules/document-viewer/document-viewer.module').then((m) => m.DocumentViewerModule),
  },
  {
    path: AppConfig.routes.logistics,
    canLoad: [TenantTypeAuthorizationGuard],
    loadChildren: () => import('./logistics/logistics.module').then((m) => m.LogisticsModule),
    data: {
      [AuthorizationDataProperties.TenantType]: TenantType.Logistics,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
