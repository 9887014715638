<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<app-pdf-utility #pdfutil appendTo="body" (dialogClosed)="onCloseDialog()"></app-pdf-utility>

<p-dialog
  header="Fuel Expenses"
  modal="true"
  id="settlementUI"
  [(visible)]="showSettlementUI"
  [closable]="false"
  [style]="{ 'max-width': '31.25rem' }"
  [focusOnShow]="false"
>
  <div class="layout-content-container">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-12 p-0 py-2">Enter the driver's total fuel deductions for this pay period.</div>

      <div class="grid grid-nogutter col-12 p-0">
        <div class="col-6 pl-0 pr-2">
          <input
            class="col-12"
            type="text"
            pInputText
            placeholder="Enter description"
            [(ngModel)]="driverFuelExpenseDescription"
          />
        </div>

        <div class="col-4 pl-0">
          <div class="ui-inputgroup p-0">
            <div class="ui-inputgroup-addon"><i class="fa fa-dollar-sign"></i></div>
            <input
              type="text"
              pKeyFilter="num"
              pInputText
              placeholder="0.00"
              [ngModel]="driverFuelExpenses | number : '1.2-2'"
              (ngModelChange)="driverFuelExpenses = $event"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="showSettlementUI = false"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button type="button" pButton icon="fa fa-check" (click)="doSettlement()" label="Continue"></button>
  </p-footer>
</p-dialog>
