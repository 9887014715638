<div class="grid grid-nogutter col-12 p-0">
  <p-card class="col-12 address-card" [formGroup]="formGroup">
    <div class="col-12 p-0">
      <app-form-field
        [(formGroup)]="formGroup"
        label=""
        formControlName="{{ prefix }}-line1"
        [(ngModel)]="Address.line1"
        [formErrors]="formErrors"
        placeholder="Street"
        labelClass="nolabel"
        controlClass="col-12 px-0"
      >
      </app-form-field>
    </div>

    <div class="col-12 p-0">
      <app-form-field
        [(formGroup)]="formGroup"
        label=""
        formControlName="{{ prefix }}-line2"
        [(ngModel)]="Address.line2"
        [formErrors]="formErrors"
        placeholder="Line 2"
        labelClass="nolabel"
        controlClass="col-12 px-0"
      >
      </app-form-field>
    </div>

    <div class="col-12 p-0">
      <app-form-field
        [(formGroup)]="formGroup"
        label=""
        formControlName="{{ prefix }}-city"
        [(ngModel)]="Address.city"
        [formErrors]="formErrors"
        placeholder="City"
        labelClass="nolabel"
        controlClass="col-12 px-0"
        (inputChanged)="changeLocation()"
      >
      </app-form-field>
    </div>

    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-4">
        <app-form-field
          [(formGroup)]="formGroup"
          id="{{ prefix }}-addr-state"
          label=""
          type="state-selector"
          placeholder="State"
          labelClass="nolabel"
          controlClass="col-12 p-0 mt-0"
          controlClassLevel1="col-12 p-1 pl-0"
          formControlName="{{ prefix }}-state"
          [(ngModel)]="Address.state"
          [formErrors]="formErrors"
          (stateChanged)="onStateChange($event)"
          [viewMode]="readonly ? 'readonly' : ''"
        ></app-form-field>
      </div>
      <div class="col-8 p-1 pr-0">
        <app-form-field
          [(formGroup)]="formGroup"
          label=""
          formControlName="{{ prefix }}-zip"
          [(ngModel)]="Address.zip"
          [formErrors]="formErrors"
          placeholder="Zip"
          labelClass="nolabel"
          controlClass="col-12 p-0"
        >
        </app-form-field>
      </div>
    </div>
  </p-card>
</div>
