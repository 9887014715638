<div [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'display'">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-4 p-2"> Address </div>
      <div class="col-8 py-2">
        {{ Address.line1 }}
        <br />
        {{ Address.line2 }}
        <br />
        {{ Address.city }}, {{ Address.state }} {{ Address.zip }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <p-panel
      class="grid grid-nogutter col-12 ui-panel-content"
      [formGroup]="formGroup"
      [showHeader]="false"
      *ngIf="displayMode == 'panel'"
    >
      <div class="grid grid-nogutter col-12 p-0">
        <span class="ui-custom-title" *ngIf="!noTittle"> {{ prefix | titleCase }} address </span>
        <div class="col-12 p-0">
          <span class="ui-float-label col-12 p-0">
            <app-form-field
              [(formGroup)]="formGroup"
              label="Line 1 {{ requiredLabel }}"
              labelClass="float-label"
              formControlName="{{ prefix + '-line1' }}"
              [formErrors]="formErrors"
              [(ngModel)]="Address.line1"
              [config]="config"
              [viewMode]="collapsed ? 'readonly' : ''"
              class="col-12 p-0"
            ></app-form-field>
          </span>
        </div>
        <div class="col-12 p-0">
          <span class="ui-float-label col-12 p-0">
            <app-form-field
              [(formGroup)]="formGroup"
              label="Line 2"
              labelClass="float-label"
              formControlName="{{ prefix + '-line2' }}"
              [formErrors]="formErrors"
              [(ngModel)]="Address.line2"
              [config]="config"
              [viewMode]="collapsed ? 'readonly' : ''"
              class="col-12p-0"
            ></app-form-field>
          </span>
        </div>

        <div class="col-6 p-0">
          <span class="ui-float-label col-12 p-0">
            <app-form-field
              [(formGroup)]="formGroup"
              id="{{ prefix }}-addr-city"
              label="City {{ requiredLabel }}"
              labelClass="float-label"
              formControlName="{{ prefix }}-city"
              [formErrors]="formErrors"
              [(ngModel)]="Address.city"
              [config]="config"
              [viewMode]="collapsed ? 'readonly' : ''"
              class="col-12 p-0"
            >
            </app-form-field>
          </span>
        </div>

        <div class="col-3 py-0">
          <span class="ui-float-label col-12 p-0">
            <app-state-selector
              id="{{ prefix }}-addr-state"
              class="col-12 p-0"
              labelClass="float-label"
              [formGroup]="formGroup"
              formControlName="{{ prefix }}-state"
              [ngModel]="Address.state"
              label="State {{ requiredLabel }}"
              [readonly]="collapsed ? true : false"
              (stateChanged)="onStateChange($event)"
            >
            </app-state-selector>
          </span>
          <span class="formerrors">{{ formErrors[this.prefix + '-state'] }}</span>
        </div>

        <div class="col-3 p-0">
          <span class="ui-float-label col-12 p-0">
            <app-form-field
              [(formGroup)]="formGroup"
              id="{{ prefix }}-addr-zip"
              label="Zip code {{ requiredLabel }}"
              [viewMode]="collapsed ? 'readonly' : ''"
              labelClass="float-label"
              formControlName="{{ prefix + '-zip' }}"
              [formErrors]="formErrors"
              [(ngModel)]="Address.zip"
              [config]="config"
              type="text"
              class="col-12 p-0"
            >
            </app-form-field>
          </span>
        </div>
      </div>
    </p-panel>

    <div class="grid grid-nogutter col-12 p-0" [formGroup]="formGroup" *ngIf="displayMode == 'twocol'">
      <app-form-field
        class="col-12"
        [formGroup]="formGroup"
        [label]="title"
        [viewMode]="collapsed ? 'readonly' : ''"
        type="text"
        formControlName="{{ prefix }}-line1"
        [(ngModel)]="Address.line1"
        [formErrors]="formErrors"
        placeholder="Line 1"
        labelClass="col-4"
        controlClass="col-8"
      >
      </app-form-field>

      <app-form-field
        class="col-8 col-offset-4"
        [formGroup]="formGroup"
        type="text"
        formControlName="{{ prefix }}-line2"
        [(ngModel)]="Address.line2"
        [formErrors]="formErrors"
        [viewMode]="collapsed ? 'readonly' : ''"
        placeholder="Line 2"
        labelClass="nolabel"
        controlClass="col-12"
      >
      </app-form-field>

      <app-form-field
        class="col-8 col-offset-4"
        [formGroup]="formGroup"
        type="text"
        formControlName="{{ prefix }}-city"
        [(ngModel)]="Address.city"
        [formErrors]="formErrors"
        [viewMode]="collapsed ? 'readonly' : ''"
        placeholder="City"
        labelClass="nolabel"
        controlClass="col-12"
      >
      </app-form-field>

      <div class="grid grid-nogutter col-8 col-offset-4 p-0">
        <app-form-field
          class="col-6"
          type="state-selector"
          [formGroup]="formGroup"
          formControlName="{{ prefix }}-state"
          [ngModel]="Address.state"
          [viewMode]="collapsed ? 'readonly' : ''"
          (stateChanged)="onStateChange($event)"
          labelClass="nolabel"
          controlClass="col-12 p-0"
          controlClassLevel1="p-1 pr-0"
        >
        </app-form-field>
        <app-form-field
          class="col-6"
          [formGroup]="formGroup"
          type="text"
          formControlName="{{ prefix }}-zip"
          [(ngModel)]="Address.zip"
          [formErrors]="formErrors"
          [viewMode]="collapsed ? 'readonly' : ''"
          labelClass="nolabel"
          placeholder="Zip"
          labelClass="nolabel"
          controlClass="col-12 p-1 pl-0"
        >
        </app-form-field>
      </div>
    </div>
  </ng-container>
</div>
