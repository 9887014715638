<div class="p-2 section">
  <span><strong>Incomplete Registrations Report</strong></span>
  <form
    class="pt-5 flex justify-content-between"
    [formGroup]="incompleteRegistrationForm"
    id="incomplete-registration-form"
  >
    <div class="flex">
      <app-form-field
        class="pr-5"
        [(formGroup)]="incompleteRegistrationForm"
        label="Start Date"
        type="calendar"
        formControlName="startDate"
        placeholder="mm/dd/yyyy"
        labelClass="pl-1"
        controlClass="col-12"
      ></app-form-field>

      <app-form-field
        [(formGroup)]="incompleteRegistrationForm"
        label="End Date"
        type="calendar"
        formControlName="endDate"
        placeholder="mm/dd/yyyy"
        labelClass="pl-1"
        controlClass="col-12"
      ></app-form-field>
    </div>

    <div class="flex justify-content-end align-items-end">
      <p-button
        type="submit"
        (onClick)="getResults()"
        label="Export Report"
        icon="fa fa-download"
        iconPos="right"
        [disabled]="loadingResults"
        class="ui-button-secondary"
        id="export-report"
      ></p-button>
    </div>
  </form>
</div>
