<div class="flex flex-row justify-content-center">
  <div class="flex-grow-1"></div>
  <div
    *ngFor="let step of stepsToDisplay(); index as index; last as isLast"
    class="flex-grow-1 flex align-items-center"
    id="progress-step"
  >
    <app-wizard-progress-step-header
      class="step-header"
      [id]="'step-header-' + index"
      [isComplete]="isStepComplete(step.stepProgressNumber)"
      [isSelected]="isStepSelected(step.stepProgressNumber)"
      [stepProgressNumber]="step.stepProgressNumber"
      [stepName]="step.stepName"
    ></app-wizard-progress-step-header>
    <div *ngIf="!isLast" class="flex-grow-1 spacer" [id]="'step-spacer-' + index">
      <hr [class.completed]="isStepComplete(step.stepProgressNumber)" />
    </div>
  </div>
</div>
