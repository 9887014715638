import { Component, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ClientTokenService } from '../../../services/client-token-service';
import { LogisticsConfig } from 'src/app/config/logistics.config';
import { AppConfig } from 'src/app/config/app.config';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {
  public dashboardRoute: string;
  private itemsSubscription: Subscription;
  private loggedInSubscription: Subscription;

  public items: MenuItem[] = [];
  public isloggedIn: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private clientTokenService: ClientTokenService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.dashboardRoute = this.navigationService.getDefaultDashboardRoute();
    this.itemsSubscription = this.breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
      this.cdr.detectChanges();
    });
    this.loggedInSubscription = this.clientTokenService.isLoggedIn().subscribe((isLoggedIn) => {
      this.isloggedIn = isLoggedIn;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
  }
}
