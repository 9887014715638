import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { ClientTokenService } from '../../../services/client-token-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-lbt-integration',
  templateUrl: './lbt-integration.component.html',
  styleUrls: ['./lbt-integration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LbtIntegrationComponent implements OnInit {
  iframeUrl: SafeResourceUrl;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private token: ClientTokenService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.breadcrumbService.setItems('lbt-integration');
    this.iframeUrlGet();
  }

  public iframeUrlGet() {
    const url = this.token.getClaims().lbtIframeUrl;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
