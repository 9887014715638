import {
  Component,
  OnInit,
  Input,
  Injector,
  ViewEncapsulation,
  ElementRef,
  HostListener,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DtoFormBase } from '../../../shared/FormBase';
import { SendFileDTO } from '../../../../apiclient/v1.1/models';
import { FormControl } from '@angular/forms';
import { Utils } from '../../../../utils/utils';
import { ApiValidators } from '../../../../utils/api-validators';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MailOptions } from '../../../shared/models/mail-options';

@Component({
  selector: 'app-document-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentMailComponent extends DtoFormBase<SendFileDTO> implements OnInit {
  @Input() attachments?: any[];

  @ViewChildren('focusableInput', { read: ElementRef })
  focusableInputs: QueryList<ElementRef>;

  displayDialog = false;
  recipients: any[];
  chooseAttachments: boolean = false;

  disableSend: boolean = false;
  errorMessage: string = '';
  onSend: (model: SendFileDTO) => Observable<void>;
  private closeDialogOnSend: boolean;

  constructor(protected injector: Injector) {
    super(injector);
    this.model = {} as SendFileDTO;
    this.model.recipients = [];
    this.recipients = [];
    this.errorMessage = '';
  }

  ngOnInit() {
    this.initializeComponent();
  }

  init(mailOptions: MailOptions) {
    this.onSend = mailOptions.onSend;
    this.closeDialogOnSend = mailOptions.closeDialogOnSend;
    this.model = {} as SendFileDTO;

    this.initializeComponent();

    this.initializedRecipients(mailOptions.recipients);

    this.initializeAttachments(mailOptions.manualAttachments, mailOptions.manualOnly, mailOptions.chooseAttachments);

    this.initializeViewMode();

    this.initializeDocumentMerge(mailOptions.requireDocumentMerge);
  }

  getTime(date?: Date) {
    return date != null && date ? new Date(date).getTime() : 0;
  }

  public sendMail(): void {
    if (!this.formValid()) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.disableSend = true;
    if (this.closeDialogOnSend) {
      this.displayDialog = false;
    }
    this.onSend(this.model)
      .pipe(
        tap(() => (this.displayDialog = false)),
        catchError((error) => {
          if (error.status === 0 || error.status === 413) {
            this.errorMessage = 'Email Attachments cannot exceed 25MB';
          }
          return this.handleError(error);
        }),
        finalize(() => (this.disableSend = false))
      )
      .subscribe();
  }

  sendAllSelected() {
    if (this.model.merge) {
      this._NS.warn(
        'PDF Format',
        'All Docs in Image or PDF Format will be combined to create 1 Document. Remaining Docs will be attached individually.'
      );
    }
  }
  checkEmail(event) {
    const retValidators: any = [];
    if (this.model.recipients.filter((x) => x.address === event.value).length > 0) {
      this.recipients.pop();
      this.formErrors['recipients'] = 'Email address already added.';
      return;
    }
    if (!Utils.EMAIL_REGEX.test(event.value)) {
      this.recipients.pop();
      this.formErrors['recipients'] = ApiValidators.emailValidationMessages.pattern;
    } else {
      this.model.recipients.push({ address: event.value });
      this.formGroup.patchValue({ recipients: this.model.recipients });
      setTimeout(function () {
        const emailInput = Array.from(document.getElementsByClassName('ui-chips-token-icon'));
        emailInput.forEach((element) => {
          element.classList.add('fa-times');
        });
      });
    }
  }

  deleteEmail(event) {
    this.model.recipients = this.model.recipients.filter((x) => x.address !== event.value);
  }
  cancel() {
    this.errorMessage = '';
    this.displayDialog = false;
  }

  sortAttachmentIDs() {
    this.errorMessage = '';
    this.model.attachmentIds.sort(function (a, b) {
      return a - b;
    });
  }

  checkRecipients($event) {
    if (this.model.recipients.length === 0) {
      this.formErrors['recipients'] = 'Email recipient is required';
    }
  }

  public areAttachmentsSelected(): boolean {
    return this.model.attachmentIds && this.model.attachmentIds.length > 0;
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();

      const focusableInputs = this.focusableInputs.toArray();

      const currentEl = focusableInputs.find((input) => {
        const activeElement = event.target;
        return input && (input.nativeElement === activeElement || input.nativeElement.contains(activeElement));
      });

      if (focusableInputs && focusableInputs.length > 0) {
        if (!currentEl) {
          this.focusChildInput(focusableInputs[0]);
        } else {
          const focusedIndex = focusableInputs.indexOf(currentEl);

          if (event.shiftKey) {
            if (focusedIndex === -1 || focusedIndex === 0) {
              this.focusChildInput(focusableInputs[focusableInputs.length - 1]);
            } else {
              this.focusChildInput(focusableInputs[focusedIndex - 1]);
            }
          } else {
            if (focusedIndex === -1 || focusedIndex === focusableInputs.length - 1) {
              this.focusChildInput(focusableInputs[0]);
            } else {
              this.focusChildInput(focusableInputs[focusedIndex + 1]);
            }
          }
        }
      }
    }
  }

  private initializeComponent(): void {
    this.initFormFor('SendFileDTO');
    this.errorMessage = '';
    this.formGroup.addControl('recipientsCopy', new FormControl());
    // tslint:disable-next-line:forin
    for (const name in this.formGroup.controls) {
      this.formErrors[name] = '';
    }
  }

  private initializedRecipients(recipients?: string[]): void {
    this.model.recipients = [];
    this.recipients = [];

    // Add default recipients
    if (recipients && recipients.length > 0) {
      for (const r of recipients) {
        this.recipients.push(r);
        this.model.recipients.push({ address: r });
      }
      this.formGroup.patchValue({ recipients: this.model.recipients });
    }

    setTimeout(function () {
      const emailInput = Array.from(document.getElementsByClassName('ui-chips-token-icon'));
      emailInput.forEach((element) => {
        element.classList.add('fa-times');
      });
    });
  }

  private initializeAttachments(manualAttachments?: any[], manualOnly?: boolean, chooseAttachments?: boolean): void {
    this.chooseAttachments = chooseAttachments;
    this.attachments = this.attachments ? this.attachments : [];

    if (manualAttachments) {
      if (!this.attachments || manualOnly) {
        this.attachments = manualAttachments;
      } else {
        this.attachments = this.attachments.concat(manualAttachments);
      }
    }

    this.model.attachmentIds = [];
    if (this.attachments && this.attachments.length > 0) {
      this.attachments = this.attachments.sort((b, a) => {
        return this.getTime(a.modifiedOn) - this.getTime(b.modifiedOn);
      });
      this.displayDialog = true;
      this.attachments.forEach((attachment) => {
        this.model.attachmentIds.push(attachment.attachmentId);
      });
      this.formGroup.patchValue({ attachmentIds: this.model.attachmentIds });
    } else {
      this.displayDialog = false;
      this._NS.error('No Document Attached', 'You must add a document first');
    }
  }

  private initializeViewMode(): void {
    setTimeout((_) => {
      this.focusChildInput(this.focusableInputs.first);
    });
  }

  private initializeDocumentMerge(requireDocumentMerge: boolean): void {
    this.model.merge = requireDocumentMerge;
    requireDocumentMerge ? this.formGroup.controls.merge.disable() : this.formGroup.controls.merge.enable();
    this.formGroup.patchValue({ merge: this.model.merge });
  }

  private focusChildInput(el: ElementRef) {
    const formElement = el.nativeElement
      .querySelector(`button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
[href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
[tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
[contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])`);

    if (formElement) {
      // input element was a child of the native element
      formElement.focus();
    } else {
      // input element should be the native element itself, so formElement is null
      el.nativeElement.focus();
    }
  }
}
