import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { RegisterService } from 'src/apiclient/v1.1/services';
import { RegistrationFields } from '../../registration-fields';
import { WizardFormBase } from '../wizard-form-base';

@Component({
  selector: 'app-administrator-information-form',
  templateUrl: './administrator-information-form.component.html',
  styleUrls: ['./administrator-information-form.component.scss', '../../user-registration-wizard.component.scss'],
})
export class AdministratorInformationFormComponent extends WizardFormBase implements OnInit, OnDestroy {
  @Input() registrationInProgress: boolean;

  public existingUser = false;

  private readonly passwordRegex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`\\\|~\!@#\$%\^\&\*\(\)-_=\+\[\]{}\\|/\.,\?><';:\"]).{8,100}/;
  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private registerService: RegisterService) {
    super();
  }

  public ngOnInit(): void {
    this.initFormGroup(
      this.formBuilder.group({
        [RegistrationFields.Email]: [this.tempUser.email, [Validators.required]],
        [RegistrationFields.ExistingEmail]: [false, [Validators.required]],
        [RegistrationFields.Password]: ['', [Validators.required, Validators.pattern(this.passwordRegex)]],
        'confirm-password': ['', [Validators.required]],
        'passwords-match': [false, [Validators.requiredTrue]],
      })
    );

    this.addFieldNotToSanitize(RegistrationFields.Password);

    this.wizardStep.stepForm.controls[RegistrationFields.Email].disable();
    this.checkForExistingUser();
  }

  public disableFinish(): boolean {
    return !this.wizardStep.stepForm.valid || this.registrationInProgress;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkForExistingUser() {
    if (!this.tempUser.confirmationCode) {
      this.existingUser = true;
      this.setValidationForExistingUser();
      return;
    }
    this.registerService
      .EmailExists(this.tempUser.email)
      .pipe(
        tap((emailExists) => {
          this.existingUser = emailExists;
          if (emailExists) {
            this.setValidationForExistingUser();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setValidationForExistingUser() {
    this.wizardStep.stepForm.controls[RegistrationFields.ExistingEmail].setValue(true);
    // Set a fake password on the user's behalf. This password will never be stored or used.
    this.wizardStep.stepForm.controls[RegistrationFields.Password].setValue('no-password-required');
    this.wizardStep.stepForm.controls['confirm-password'].setValue('no-password-required');
    this.wizardStep.stepForm.controls['passwords-match'].setValue(true);
    this.wizardStep.stepForm.controls[RegistrationFields.Password].setValidators([]);
    this.wizardStep.stepForm.controls[RegistrationFields.Password].updateValueAndValidity();
  }
}
