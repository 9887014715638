import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyEditComponent } from './edit/edit.component';
import { CompanyListComponent } from './list/list.component';
import { AppConfig } from 'src/app/config/app.config';

const routes: Routes = [
  { path: AppConfig.routes.company, component: CompanyListComponent },
  { path: 'company/new', component: CompanyEditComponent },
  { path: 'company/edit/:id', component: CompanyEditComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
