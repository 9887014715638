import { Component, Input, ViewChild, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AddressDTO } from '../../../apiclient/models';
import { AutoComplete } from 'primeng/autocomplete';
import { ValidatorsService } from '../../core/validators-service';
import { ApiValidators } from '../../../utils/api-validators';
import { TitleCasePipe } from '@angular/common';
import { NotificationsService } from '../notifications/notifications.service';
import { State } from '../../../services/states-service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [TitleCasePipe],
})
export class AddressComponent implements OnInit, OnChanges {
  @ViewChild(AutoComplete, { static: true }) autoCompleteComponent: AutoComplete;

  public addressTitle: string;

  @Input() readonly: boolean = false;
  @Input() requireInput: boolean = true;
  @Input() formSubmitted: boolean = false;

  private initComplete: boolean = false;
  private addressValidators: any;
  private addrFormErrorsTemplate = {
    line1: '',
    city: '',
    state: '',
    zip: '',
  };

  constructor(
    private validatorsService: ValidatorsService,
    private titleCasePipe: TitleCasePipe,
    _NS: NotificationsService
  ) {}

  @Input() formGroup: FormGroup;
  @Input() prefix: string = 'main';
  @Input() Address?: AddressDTO;
  @Input() formErrors: any;
  @Input() validationMessages: any;
  @Input() collapsed: boolean = false; // if this is changed to true, validators will be turned off for this address

  @Input() includeCanadian?: boolean = false;
  @Output() locationChanged: EventEmitter<string> = new EventEmitter<string>();

  public ngOnInit(): void {
    this.addressTitle = this.titleCasePipe.transform(this.prefix);
    if (this.requireInput) {
      this.addressValidators = this.validatorsService.getByName('AddressDTO');
    } else {
      this.addressValidators = this.validatorsService.getByName('SimpleAddressDTO');
    }

    this.initFormErrors();
    this.addValidationMessages();
    this.buildForm();
    this.initComplete = true;
    this.updateLoadUIViewMode();
  }

  public ngOnChanges(changes): void {
    if (this.initComplete) {
      if (changes['collapsed']) {
        this.setValidators();
      }
      if (changes['formSubmitted']) {
        this.showValidations();
      }
    }
  }

  public changeLocation(): void {
    const city = this.Address.city ? this.Address.city : '';
    const state = this.Address.state ? this.Address.state : '';
    this.locationChanged.emit(`${city}, ${state}`);
  }

  public onStateChange(event: State): void {
    if (event) {
      this.Address.state = event.abbr;
      this.changeLocation();
      this.setZipValidator(event.country);
    }
  }

  private updateLoadUIViewMode(): void {
    setTimeout((_) => {
      if (this.readonly) {
        this.formGroup.controls[this.prefix + '-city'].disable();
        this.formGroup.controls[this.prefix + '-line1'].disable();
        this.formGroup.controls[this.prefix + '-line2'].disable();
        this.formGroup.controls[this.prefix + '-state'].disable();
        this.formGroup.controls[this.prefix + '-zip'].disable();
      } else {
        this.formGroup.controls[this.prefix + '-city'].enable();
        this.formGroup.controls[this.prefix + '-line1'].enable();
        this.formGroup.controls[this.prefix + '-line2'].enable();
        this.formGroup.controls[this.prefix + '-state'].enable();
        this.formGroup.controls[this.prefix + '-zip'].enable();
      }
    });
  }

  private buildForm(): void {
    this.formGroup.addControl(this.prefix + '-city', new FormControl(this.Address.city));
    this.formGroup.addControl(this.prefix + '-line1', new FormControl(this.Address.line1));
    this.formGroup.addControl(this.prefix + '-line2', new FormControl(this.Address.line2));
    this.formGroup.addControl(this.prefix + '-state', new FormControl(this.Address.state));
    this.formGroup.addControl(this.prefix + '-zip', new FormControl(this.Address.zip));
    this.setValidators();
    this.setZipValidator('US');
  }

  private setValidators(): void {
    this.initFormErrors();

    if (this.collapsed) {
      this.formGroup.controls[this.prefix + '-city'].clearValidators();
      this.formGroup.controls[this.prefix + '-line1'].clearValidators();
      this.formGroup.controls[this.prefix + '-line2'].clearValidators();
      this.formGroup.controls[this.prefix + '-state'].clearValidators();
    } else {
      this.formGroup.controls[this.prefix + '-city'].setValidators(
        ApiValidators.getValidatorsForFormField('city', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-line1'].setValidators(
        ApiValidators.getValidatorsForFormField('line1', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-line2'].setValidators(
        ApiValidators.getValidatorsForFormField('line2', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-state'].setValidators(
        ApiValidators.getValidatorsForFormField('state', this.addressValidators)
      );
    }

    this.formGroup.controls[this.prefix + '-city'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-line1'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-line2'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-state'].updateValueAndValidity();
  }

  private showValidations(): void {
    if (!this.Address.state) {
      this.formErrors[this.prefix + '-state'] = this.validationMessages[this.prefix + '-state'].required;
    }
    this.formGroup.controls[this.prefix + '-city'].markAsTouched();
    this.formGroup.controls[this.prefix + '-line1'].markAsTouched();
    this.formGroup.controls[this.prefix + '-line2'].markAsTouched();
    this.formGroup.controls[this.prefix + '-state'].markAsTouched();
    this.formGroup.controls[this.prefix + '-zip'].markAsTouched();
  }

  private setZipValidator(country: String): void {
    this.formGroup.controls[this.prefix + '-zip'].clearValidators();
    let zipPattern;
    let zipPatternMessage;
    if (country === 'CA') {
      zipPatternMessage = 'Zip must be in the formats: T2X 1V4 or T2X1V4';
      zipPattern = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
    } else {
      zipPatternMessage = 'Zip must be in the formats: 12345, 12345-1234, or 12345 1234';
      zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    }
    const theValidator = Validators.pattern(zipPattern);

    this.validationMessages[this.prefix + '-zip'] = {
      required: 'Zip is required.',
      pattern: zipPatternMessage,
    };

    this.formGroup.controls[this.prefix + '-zip'].setValidators([theValidator, Validators.required]);
    this.formGroup.controls[this.prefix + '-zip'].updateValueAndValidity();
  }

  private addValidationMessages(): void {
    const addrValidationMessages = ApiValidators.getValidationMessagesForFormGroup(this.addressValidators);
    for (const messageKey of Object.keys(addrValidationMessages)) {
      if (messageKey !== 'zip') {
        this.validationMessages[this.prefix + '-' + messageKey] = addrValidationMessages[messageKey];
      }
    }
  }

  private initFormErrors(): void {
    for (const errorKey of Object.keys(this.addrFormErrorsTemplate)) {
      this.formErrors[this.prefix + '-' + errorKey] = '';
    }
  }
}
