import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { NgModule } from '@angular/core';
import { Permission } from 'src/app/data/static-data';
import { PermissionAuthorizationGuard } from 'src/app/shared/guards/authorization/permission-authorization.guard';
import { AppConfig } from 'src/app/config/app.config';
import { AuthorizationDataProperties } from 'src/app/shared/guards/authorization/authorization-data-properties';

const routes: Routes = [
  {
    path: AppConfig.routes.users,
    component: UsersComponent,
    canActivate: [PermissionAuthorizationGuard],
    data: {
      [AuthorizationDataProperties.Permission]: Permission.UserEdit,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
