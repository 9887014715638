import { Component, OnInit, Injector, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { HandleErrorBase } from '../../shared/HandleErrorBase';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdNamePairDTO } from '../../../apiclient/models';
import { TenantService, EquipmentService } from '../../../apiclient/services';

@Component({
  selector: 'app-equipment-type-multiselect',
  templateUrl: './equipment-type-multiselect.component.html',
  styleUrls: ['./equipment-type-multiselect.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentTypeMultiselectComponent),
      multi: true,
    },
  ],
})
export class EquipmentTypeMultiselectComponent extends HandleErrorBase implements OnInit, ControlValueAccessor {
  selected: Array<IdNamePairDTO> = [];
  options: IdNamePairDTO[];

  /**
   * This property is bound to the ngModel
   */
  model: Array<Number>;

  /**
   * When equipmentId changes
   */
  @Input() formErrors: any = {};
  @Input() errorClass: string = 'col-12';

  @Input() formControlName: string;
  @Input() formGroup: FormGroup;
  @Output() equipmentChanged: EventEmitter<Array<Number>> = new EventEmitter();
  @Output() equipmentBlur: EventEmitter<Array<Number>> = new EventEmitter();

  @Input()
  readonly: boolean = false;

  disabled: boolean = false;

  constructor(private equipment: EquipmentService, protected injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.options = await this.getOptions();
  }

  async writeValue(val: Array<number>): Promise<void> {
    this.model = val;

    /*
    if (this.model.length > 0) {
      if (this.options === undefined || this.options == null) {
        this.options = await this.getOptions();
      }

      const item = this.options.filter(x => x.id === this.model);

      if (item.length === 0) {
        throw new Error(`Could not initialize app-equipment-type-selector. Equipment was not returned from API.`);
      } else if (this.selected == null || this.selected.id !== item[0].id) {
        this.selected = item[0];
      }
    } else {
      this.selected = [];
    }
*/
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  public onChange() {
    if (this.selected !== undefined && this.selected !== null) {
      if (this.model !== this.selected) {
        this.model = this.selected.map((x) => x.id);
        this.modelChanged();
      }
    } else {
      if (this.model.length > 0) {
        this.model = [];
        this.modelChanged();
      }
    }
  }
  public onBlur() {
    this.equipmentBlur.emit(this.selected.map((x) => x.id));
  }
  private modelChanged() {
    this.propagateChange(this.model);
    this.equipmentChanged.emit(this.selected.map((x) => x.id));
  }

  private async getOptions(): Promise<IdNamePairDTO[]> {
    try {
      return await this.equipment.ApiEquipmentTypesGet(this.clientToken.auth()).toPromise();
    } catch (error) {
      this.handleBasicError(error);
    }
  }
  showErrors() {
    if (this.formGroup.controls[this.formControlName]) {
      if (this.formGroup.controls[this.formControlName].touched) {
        if (this.formErrors[this.formControlName]) {
          return this.formErrors[this.formControlName] || '';
        }
      }
    }
    return '';
  }
}
