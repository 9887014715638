import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PlatformNotificationService } from 'src/apiclient/v1.1/services';
import { TableColumn } from 'src/app/shared/models/TableColumn';
import { ClientTokenService } from 'src/services/client-token-service';
import { PlatformNotificationSummaryDTO } from '../models/platform-notification-summary-dto';
import { Utils } from 'src/utils/utils';
import { PfsTableBodyComponent } from 'src/app/components/table/pfs-table-body/pfs-table-body.component';
import { PlatformNotificationStatusFilter } from '../models/platform-notification-status-filter';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { PlatformNotificationDTO, PlatformNotificationTypeDTO } from 'src/apiclient/v1.1/models';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/config/app.config';
import { PlatformNotificationEditMode } from './enums/platform-notification-edit-mode';
import { PlatformNotificationActionType } from './enums/platform-notification-action-type';
import { PlatformNotificationStatusName } from './enums/platform-notification-status-name';

@Component({
  selector: 'app-platform-notifications',
  templateUrl: './platform-notifications.component.html',
  styleUrls: ['./platform-notifications.component.scss'],
})
export class PlatformNotificationsComponent implements OnInit, OnDestroy {
  public notifications: PlatformNotificationSummaryDTO[];
  public loading: boolean = false;
  public statusFilters: PlatformNotificationStatusFilter[];
  public cols: TableColumn[];
  public selectedNotificationId: number;
  public notificationTypes: PlatformNotificationTypeDTO[];
  public isEditMode: boolean = false;
  public editModeType: PlatformNotificationEditMode;
  public showActionDialog: boolean = false;
  public actionType: PlatformNotificationActionType;

  private destroy$ = new Subject<void>();

  @ViewChildren('pfsNotificationsTable') pfsNotificationsTable: QueryList<PfsTableBodyComponent>;

  constructor(
    private platformNotificationService: PlatformNotificationService,
    private clientTokenService: ClientTokenService,
    private notificationsService: NotificationsService,
    private errorHandlingService: ErrorHandlingService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.getNotificationTypes()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => this.errorHandlingService.handleError(error))
      )
      .subscribe((data) => {
        this.notificationTypes = data;
        this.checkUrlForParam();
      });
    this.initStatusFilters();
    this.setTableColumns();
    this.filterNotificationsByStatus(this.statusFilters.find((x) => x.active));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public filterNotificationsByStatus(status: PlatformNotificationStatusFilter): void {
    this.statusFilters.map((i) => (i.active = false));
    status.active = true;

    this.getNotifications(status);
  }

  public sortTable(event: any): void {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
    }
    Utils.sortData(event, this.notifications);
  }

  public actionHandler(actionConfig): void {
    this[actionConfig.func]?.(actionConfig.paramsParsed);
  }

  public addNotification(): void {
    this.selectedNotificationId = 0;
    this.isEditMode = true;
    this.editModeType = PlatformNotificationEditMode.Add;
  }

  public editNotification(params: { row: PlatformNotificationSummaryDTO }): void {
    this.router.navigate([`${AppConfig.routes.admin.platformNotifications}/${params.row.platformNotificationId}`]);
  }

  public openDeleteDialog(params): void {
    this.showActionDialog = true;
    this.actionType = PlatformNotificationActionType.Delete;
    this.selectedNotificationId = params.row.platformNotificationId;
  }

  public closeDialog(): void {
    this.showActionDialog = false;
    this.selectedNotificationId = null;
  }

  public cancelEdit(): void {
    this.isEditMode = false;
    this.router.navigate([AppConfig.routes.admin.platformNotifications]);
  }

  public createNotification(notification: PlatformNotificationDTO): void {
    this.loading = true;

    const params: PlatformNotificationService.CreateNotificationParams = {
      Authorization: this.clientTokenService.auth(),
      body: notification,
    };

    this.platformNotificationService
      .CreateNotification(params)
      .pipe(
        tap((data) => {
          this.notificationsService.success(
            'Succcess!',
            'Notification ' + data.platformNotificationId + ' has been successfully added.'
          );
        }),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => {
          this.filterNotificationsByStatus(this.statusFilters.find((x) => x.active));
          this.loading = false;
          this.isEditMode = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public updateNotification(notification: PlatformNotificationDTO): void {
    this.loading = true;

    const params: PlatformNotificationService.UpdateNotificationParams = {
      Authorization: this.clientTokenService.auth(),
      body: notification,
    };

    this.platformNotificationService
      .UpdateNotification(params)
      .pipe(
        tap((data) => {
          this.notificationsService.success(
            'Succcess!',
            'Notification ' + data.platformNotificationId + ' has been successfully updated.'
          );
        }),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => {
          this.loading = false;
          this.isEditMode = false;
          this.router.navigate([AppConfig.routes.admin.platformNotifications]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public deleteNotification(platformNotificationId: number): void {
    this.loading = true;

    const params: PlatformNotificationService.DeleteNotificationParams = {
      Authorization: this.clientTokenService.auth(),
      id: platformNotificationId,
    };

    this.platformNotificationService
      .DeleteNotification(params)
      .pipe(
        tap(() => {
          this.notificationsService.success(
            'Succcess!',
            'Notification ' + platformNotificationId + ' has been successfully deleted.'
          );
        }),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => {
          this.filterNotificationsByStatus(this.statusFilters.find((x) => x.active));
          this.loading = false;
          this.isEditMode = false;
          this.showActionDialog = false;
          this.selectedNotificationId = null;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private checkUrlForParam(): void {
    const passedNotificationId = this.activatedRoute.snapshot.paramMap.get('id');

    if (passedNotificationId) {
      this.selectedNotificationId = Number(passedNotificationId);
      this.isEditMode = true;
      this.editModeType = PlatformNotificationEditMode.Edit;
    }
  }

  private getNotifications(status: PlatformNotificationStatusFilter): void {
    this.loading = true;

    const params: PlatformNotificationService.GetNotificationsParams = {
      Authorization: this.clientTokenService.auth(),
      StatusEquals: status.value,
    };

    this.platformNotificationService
      .GetNotifications(params)
      .pipe(
        tap((data) => (this.notifications = data)),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getNotificationTypes(): Observable<PlatformNotificationTypeDTO[]> {
    return this.platformNotificationService.GetNotificationTypes(this.clientTokenService.auth());
  }

  private initStatusFilters(): void {
    this.statusFilters = [
      {
        label: 'All',
        value: PlatformNotificationStatusName.All,
        active: true,
      },
      {
        label: 'Pending',
        value: PlatformNotificationStatusName.Pending,
        active: false,
      },
      {
        label: 'Active',
        value: PlatformNotificationStatusName.Active,
        active: false,
      },
      {
        label: 'Expired',
        value: PlatformNotificationStatusName.Expired,
        active: false,
      },
    ];
  }

  private setTableColumns(): void {
    this.cols = [
      {
        field: 'platformNotificationId',
        header: 'ID',
        format: 'actionCell',
        sortable: true,
        action: {
          func: 'editNotification',
          params: {
            row: 'row',
          },
        },
        width: '5em',
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true,
        width: '7em',
      },
      {
        field: 'platformNotificationType.name',
        header: 'Type',
        sortable: true,
      },
      {
        field: 'title',
        header: 'Title',
      },
      {
        field: 'text',
        header: 'Text',
        format: 'limitText',
      },
      {
        field: 'startDate',
        header: 'Start',
        format: 'DateLocale',
        sortable: true,
      },
      {
        field: 'endDate',
        header: 'End',
        format: 'DateLocale',
        sortable: true,
      },
      {
        field: 'createdOn',
        header: 'Created',
        format: 'DateLocale',
        sortable: true,
      },
      {
        field: 'modifiedOn',
        header: 'Updated',
        format: 'DateLocale',
        sortable: true,
      },
      {
        field: 'pfs_table_actions',
        header: 'Actions',
        format: 'Actions',
        width: '8em',
        buttons: [
          {
            icon: 'fas fa-edit',
            class: 'icon-big',
            func: 'editNotification',
            tooltip: 'Edit Notification',
            params: {
              row: 'row',
            },
          },
          {
            icon: 'fas fa-trash',
            class: 'icon-inactive icon-big',
            func: 'openDeleteDialog',
            tooltip: 'Delete Notification',
            params: {
              row: 'row',
            },
          },
        ],
      },
    ];
  }
}
