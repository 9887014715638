/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DriverPayDTO } from '../models/driver-pay-dto';
@Injectable({
  providedIn: 'root',
})
class DriverPayService extends __BaseService {
  static readonly GetPath = '/api/DriverPay';
  static readonly ExportPath = '/api/DriverPay/export';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List of loads for the Driver Pay (settlement) page.
   * This data is LoadLeg centric - because a load can have multiple legs/drivers.
   * - Blank or null values will be excluded from filter.
   * @param params The `DriverPayService.GetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`:
   *
   * - `shippingBegin`:
   *
   * - `isPaid`:
   *
   * - `invoiceEndUtc`:
   *
   * - `invoiceBeginUtc`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `deliveryEnd`:
   *
   * - `deliveryBegin`:
   *
   * @return Collection of loadlegs
   */
  GetResponse(params: DriverPayService.GetParams): __Observable<__StrictHttpResponse<Array<DriverPayDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.isPaid != null) __params = __params.set('isPaid', params.isPaid.toString());
    if (params.invoiceEndUtc != null) __params = __params.set('invoiceEndUtc', params.invoiceEndUtc.toString());
    if (params.invoiceBeginUtc != null) __params = __params.set('invoiceBeginUtc', params.invoiceBeginUtc.toString());
    if (params.driverIdEquals != null) __params = __params.set('driverIdEquals', params.driverIdEquals.toString());
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverPay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DriverPayDTO>>;
      })
    );
  }
  /**
   * List of loads for the Driver Pay (settlement) page.
   * This data is LoadLeg centric - because a load can have multiple legs/drivers.
   * - Blank or null values will be excluded from filter.
   * @param params The `DriverPayService.GetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`:
   *
   * - `shippingBegin`:
   *
   * - `isPaid`:
   *
   * - `invoiceEndUtc`:
   *
   * - `invoiceBeginUtc`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `deliveryEnd`:
   *
   * - `deliveryBegin`:
   *
   * @return Collection of loadlegs
   */
  Get(params: DriverPayService.GetParams): __Observable<Array<DriverPayDTO>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<DriverPayDTO>)
    );
  }

  /**
   * Returns CSV file for driver pay records that have not been exported yet.
   * Method sets the DriverPayExported flag which will prevent duplicate exports.
   * loadLegIds is required. Either send the selected ids or all the ids currently on the screen.
   * @param params The `DriverPayService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadLegIds`:
   *
   * @return A CSV file
   */
  ExportResponse(params: DriverPayService.ExportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.loadLegIds || []).forEach(val => {if (val != null) __params = __params.append('loadLegIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverPay/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file for driver pay records that have not been exported yet.
   * Method sets the DriverPayExported flag which will prevent duplicate exports.
   * loadLegIds is required. Either send the selected ids or all the ids currently on the screen.
   * @param params The `DriverPayService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadLegIds`:
   *
   * @return A CSV file
   */
  Export(params: DriverPayService.ExportParams): __Observable<Blob> {
    return this.ExportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module DriverPayService {

  /**
   * Parameters for Get
   */
  export interface GetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;
    shippingEnd?: string;
    shippingBegin?: string;
    isPaid?: boolean;
    invoiceEndUtc?: string;
    invoiceBeginUtc?: string;
    driverIdEquals?: number;
    driverContains?: string;
    deliveryEnd?: string;
    deliveryBegin?: string;
  }

  /**
   * Parameters for Export
   */
  export interface ExportParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    loadLegIds?: Array<number>;
  }
}

export { DriverPayService }
