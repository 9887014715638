import { LoadPageType } from './load-page-type';

export class LoadStatus {
  public label: string;
  public value: string;
  public disabled: boolean;
  public permissions: string[];
  public availablePages: LoadPageType[];

  constructor(
    status: string,
    permissions: string[],
    availablePages: LoadPageType[],
    label: string = status,
    disabled: boolean = false
  ) {
    this.value = status;
    this.label = label;
    this.disabled = disabled;
    this.permissions = permissions;
    this.availablePages = availablePages;
  }
}
