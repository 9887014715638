import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PanelContentType } from 'src/app/data/static-data';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input() headerText: string;
  @Input() openPanelButtonIcon: string;
  @Input() closePanelButtonIcon: string;
  @Input() contentType: string;
  @Input() content: any[];
  @Input() alertCount: number;
  @Output() panelOpened = new EventEmitter<void>();

  public showPanel: boolean = false;
  public isPanelOpen: boolean = false;
  public panelContentType = PanelContentType;

  private maxDisplayedAlertCount = 5;

  constructor(private eRef: ElementRef) {}

  public openPanel(): void {
    this.showPanel = true;
    this.panelOpened.emit();
  }

  public closePanel(): void {
    this.showPanel = false;
  }

  @HostListener('document:click', ['$event'])
  public clickout(event: PointerEvent): void {
    if (!this.eRef.nativeElement.contains(event.target) && this.isPanelOpen) {
      this.closePanel();
      this.isPanelOpen = false;
      return;
    }
    this.isPanelOpen = this.showPanel;
  }

  public getFormattedAlertCount(): string {
    return this.alertCount > this.maxDisplayedAlertCount ? `${this.maxDisplayedAlertCount}+` : String(this.alertCount);
  }
}
