import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TempUser } from 'src/apiclient/v1.1/models';
import { WizardNavigationDirection } from '../wizard-navigation-direction';
import { WizardStep } from '../wizard-step';
import { RegistrationFields } from '../registration-fields';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class WizardFormBase {
  public readonly formFields = RegistrationFields;

  private _wizardStep: WizardStep;

  @Input() tempUser: TempUser;
  @Input() navigationDirection: WizardNavigationDirection;
  @Input() set wizardStep(value: WizardStep) {
    this._wizardStep = value;
    this.wizardStepChange.emit(this._wizardStep);
  }
  get wizardStep(): WizardStep {
    return this._wizardStep;
  }
  @Output() wizardStepChange = new EventEmitter<WizardStep>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();

  constructor() {}

  public initFormGroup(newForm: FormGroup) {
    if (Object.keys(this.wizardStep.stepForm.controls).length === 0) {
      Object.keys(newForm.controls).forEach((key) => {
        this.wizardStep.stepForm.addControl(key, newForm.controls[key]);
      });
    }
  }

  public addFieldNotToSanitize(field: string) {
    if (!this.wizardStep.fieldsNotToSanitize.includes(field)) {
      this.wizardStep.fieldsNotToSanitize.push(field);
    }
  }

  public previous() {
    this.previousStep.emit();
  }

  public next() {
    this.nextStep.emit();
  }
}
