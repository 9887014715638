import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
})
export class NotificationsPanelComponent {
  @Input() notifications: any[];

  constructor() {}
}
