import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wizard-progress-step-header',
  templateUrl: './wizard-progress-step-header.component.html',
  styleUrls: ['./wizard-progress-step-header.component.scss'],
})
export class WizardProgressStepHeaderComponent {
  @Input() stepProgressNumber: number;
  @Input() isComplete: boolean;
  @Input() isSelected: boolean;
  @Input() stepName: string;
}
