<div class="page-container">
  <div class="layout-content-container">
    <h1>Buy/No Buy</h1>
  </div>

  <p-card>
    <div class="layout-content-container">
      <div class="grid mb-3">
        <div class="col-12">
          <h2>Factoring Account Executives</h2>
        </div>
        <div class="col-12">
          <app-account-executives-table></app-account-executives-table>
        </div>
      </div>

      <div class="grid debtor-section">
        <div class="col-12">
          <form [formGroup]="searchFormGroup">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon ui-inputgroup-addon-inside">
                <i class="fa fa-search"></i>
              </span>
              <input class="w-3" formControlName="buy-no-buy-search" placeholder="Search Customers" pInputText />
            </div>
          </form>
        </div>

        <div class="col-12 debtor-table" *ngIf="debtors$ | async as debtors">
          <p-table
            id="debtorTable"
            [ngClass]="debtors && debtors.length >= truncatedResultsLimit ? 'truncated-debtor-table' : ''"
            [columns]="debtorCols"
            [value]="debtors"
            [lazy]="true"
            [loading]="isDebtorDataLoaded$ | async"
            [autoLayout]="true"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="debtorCols">
              <tr pfs-table-body class="debtor-row" [columns]="debtorCols" [row]="rowData"></tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </p-card>
</div>
