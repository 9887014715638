import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCurrency',
})
export class FormatCurrency implements PipeTransform {
  transform(value: any, format?: any): any {
    if (!value || value === '') {
      return value;
    }
    return parseFloat(value).toFixed(2);
  }
}
