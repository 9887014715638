import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class TenantSettingsComponent implements OnInit {
  public tenantId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.tenantId = this.getTenantId();
  }

  private getTenantId(): number {
    const idInRoute = this.activatedRoute.snapshot.paramMap.get('id');
    const id = +idInRoute;
    if (idInRoute && id) {
      return id;
    }

    this.notificationsService.error('Tenant does not exist', `Tenant with id '${idInRoute}' does not exist.`);
    this.router.navigate(['admin', 'tenants']);
    return undefined;
  }
}
