import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from '../../shared/modules/primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/modules/shared.module';
import { DriverProfileRoutingModule } from './driver-profile-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PrimeNgModule, SharedModule, DriverProfileRoutingModule],
  declarations: [],
})
export class DriverProfileModule {}
