import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '../../../components/notifications/notifications.service';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'app-cancelled',
  template: '',
})
export class CancelledComponent implements OnInit {
  constructor(private router: Router, private notify: NotificationsService) {}

  ngOnInit() {
    this.notify.error('Cancelled', 'The checkout was cancelled.');
    this.router.navigate(['/' + AppConfig.routes.subscription.required]);
  }
}
