import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phone-number-v2',
  templateUrl: './phone-number-v2.component.html',
  styleUrls: ['./phone-number-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberV2Component),
      multi: true,
    },
  ],
})
export class PhoneNumberV2Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() labelLeft: boolean = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() boldLabel: boolean = true;
  @Input() labelClass: string;
  @Input() controlClass: string = 'col-12 p-1';
  @Input() errorClass: string = 'col-12';
  @Input() errorUnderInput: boolean = false;
  @Input() formControlName: string;
  @Input() formErrors: object;
  @Input() set touched(value: boolean) {
    if (value) {
      this.phoneControl.markAsTouched();
    }
  }
  @Input() set dirty(value: boolean) {
    if (value) {
      this.phoneControl.markAsDirty();
    }
  }

  @Input() set optional(optional: boolean) {
    optional ? this.phoneControl.clearValidators() : this.phoneControl.setValidators(Validators.required);
  }

  public phoneControl = new FormControl('', [Validators.required]);

  private destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this.phoneControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.sanitizeValue(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string): void {
    this.phoneControl.setValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.phoneControl.disable() : this.phoneControl.enable();
  }

  private onChange = (value: string | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getFormError() {
    if (this.formErrors && this.phoneControl.touched && this.phoneControl.dirty) {
      return this.formErrors[this.formControlName];
    }
  }

  private sanitizeValue(value): void {
    this.onChange(value?.replace(/\D/g, ''));
  }
}
