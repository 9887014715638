import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Observable, Subject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ReportingFilter } from '../models/reporting-filter';
import { SubcategoryService } from '../services/subcategory/subcategory.service';
import { CategoryDateType } from '../models/category-date-type';
import { ReportingWizardFilterCategoryLabels } from './reporting-wizard-filter-category-labels';
import { ReportingWizardFilterCategoryValues } from './reporting-wizard-filter-category-values';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { ClientTokenService } from 'src/services/client-token-service';
import { Role } from 'src/app/data/static-data';

interface Category {
  label: string;
  value: string;
  subcategoryOptions?: (filterText: string) => Observable<SelectItem[]>;
  defaultDateType?: CategoryDateType;
  disabled?: boolean;
}

@Component({
  selector: 'app-reporting-wizard-filter',
  templateUrl: './reporting-wizard-filter.component.html',
  styleUrls: ['./reporting-wizard-filter.component.scss'],
  providers: [
    // To ensure the service clears driver data on `OnDestroy`, a component provider is needed.
    // Otherwise, if a user logs in/out into a new tenant, the same drivers would be displayed even if they don't exist in the tenant.
    SubcategoryService,
  ],
})
export class ReportingWizardFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public formErrors: {};
  public readonly mainCategories: SelectItem[];
  public readonly dateTypes: SelectItem[];
  public subcategoryOptions: SelectItem[] = [];
  public showAdvancedSettings: boolean;

  private readonly categories: Category[];
  private readonly subcategories: Category[];
  private readonly defaultSubcategory: SelectItem = { label: 'All', value: undefined };
  private readonly filterSubcategoryOptionsStream$ = new Subject<string>();
  private readonly destroy$ = new Subject<void>();
  private onChange(reportingFilter: ReportingFilter) {}

  constructor(
    private formBuilder: FormBuilder,
    private subcategoryService: SubcategoryService,
    private control: NgControl,
    private formValidationService: FormValidationService,
    private formValidationErrorsService: FormValidationErrorsService,
    private clientTokenService: ClientTokenService
  ) {
    this.control.valueAccessor = this;
    this.categories = this.getCategories();
    this.mainCategories = this.getMainCategories(this.categories);
    this.subcategories = this.getSubcategories(this.categories);
    this.dateTypes = this.getDateTypes();
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      category: [null, Validators.required],
      subcategory: [this.defaultSubcategory, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      dateType: [null, Validators.required],
      includeMultipleShippersConsignees: [false],
    });

    this.formGroup.controls.subcategory.disable();
    this.updateFilteredSubcategoryOptions();
    this.updateDateTypeBasedOnCategory();
    this.emitOnChange();

    this.formGroup.valueChanges
      .pipe(
        tap(() => (this.formErrors = this.formValidationErrorsService.getFormErrors(this.formGroup))),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public filterSubcategoryOptions(event: { originalEvent: Event; query: string }): void {
    // Create new array reference of original options to trigger change detection and display existing options in drop-down.
    this.subcategoryOptions = this.subcategoryOptions.map((c) => c);
    this.filterSubcategoryOptionsStream$.next(event.query);
  }

  public writeValue(reportingFilter: ReportingFilter): void {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}

  public validateForm(): boolean {
    return this.formValidationService.validateForm(this.formGroup);
  }

  private getCategoryChanges(): Observable<string> {
    return this.formGroup.controls.category.valueChanges.pipe(distinctUntilChanged());
  }

  private updateFilteredSubcategoryOptions(): void {
    this.getCategoryChanges()
      .pipe(
        tap(() => {
          this.subcategoryOptions = [this.defaultSubcategory];
          this.formGroup.controls.subcategory.setValue(this.defaultSubcategory);
        }),
        filter((selectedCategory) => !!selectedCategory),
        switchMap((selectedCategory) => this.getSubcategoryOptions(selectedCategory)),
        map((c) => {
          const options: SelectItem[] = [];
          options.push(this.defaultSubcategory);
          // Creating a new array reference due to limitation of PrimeNG change detection.
          // https://www.primefaces.org/primeng-v9-lts/#/autocomplete:~:text=Change%20Detection%20of%20Suggestions
          options.push(...c);
          return options;
        }),
        tap((c) => (this.subcategoryOptions = c)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getSubcategoryOptions(selectedCategory: string): Observable<SelectItem[]> {
    const findCategoryByValue = (c: Category) => c.value === selectedCategory;
    const subcategoryControl = this.formGroup.controls.subcategory;
    if (!this.subcategories.some(findCategoryByValue)) {
      subcategoryControl.disable();
      return of([]);
    }

    subcategoryControl.enable();
    const subcategory = this.categories.find(findCategoryByValue);
    return this.filterSubcategoryOptionsStream$.pipe(
      distinctUntilChanged(),
      switchMap((filterText) => subcategory.subcategoryOptions(filterText))
    );
  }

  private updateDateTypeBasedOnCategory(): void {
    this.getCategoryChanges()
      .pipe(
        map((value) => this.categories.find((c) => c.value === value)),
        filter((selectedCategory) => !!selectedCategory),
        tap((selectedCategory) => {
          const dateTypeControl = this.formGroup.controls.dateType;
          dateTypeControl.setValue(selectedCategory.defaultDateType);
          selectedCategory.defaultDateType ? dateTypeControl.disable() : dateTypeControl.enable();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private emitOnChange(): void {
    this.formGroup.valueChanges
      .pipe(
        map(() => this.formGroup.getRawValue()),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));
  }

  private getMainCategories(categories: Category[]): SelectItem[] {
    return categories.map<SelectItem>((c) => ({ label: c.label, value: c.value, disabled: c.disabled }));
  }

  private getSubcategories(categories: Category[]): Category[] {
    return categories.filter((c) => !!c.subcategoryOptions);
  }

  private isRoleAloudToRunDriverSettlement(): boolean {
    return (
      this.clientTokenService.hasRole(Role.SuperAdmin) ||
      this.clientTokenService.hasRole(Role.Admin) ||
      this.clientTokenService.hasRole(Role.Accounting)
    );
  }

  private getCategories(): Category[] {
    const drivers$ = (filterText: string) => this.subcategoryService.getDrivers(filterText);
    return [
      {
        label: ReportingWizardFilterCategoryLabels.Driver,
        value: ReportingWizardFilterCategoryValues.Driver,
        subcategoryOptions: drivers$,
      },
      {
        label: ReportingWizardFilterCategoryLabels.BillTo,
        value: ReportingWizardFilterCategoryValues.BillTo,
        subcategoryOptions: (filterText) => this.subcategoryService.getBillToCustomers(filterText),
      },
      {
        label: ReportingWizardFilterCategoryLabels.Shipper,
        value: ReportingWizardFilterCategoryValues.Shipper,
        subcategoryOptions: (filterText) => this.subcategoryService.getShippers(filterText),
      },
      {
        label: ReportingWizardFilterCategoryLabels.Consignee,
        value: ReportingWizardFilterCategoryValues.Consignee,
        subcategoryOptions: (filterText) => this.subcategoryService.getConsignees(filterText),
      },
      {
        label: ReportingWizardFilterCategoryLabels.Dispatcher,
        value: ReportingWizardFilterCategoryValues.Dispatcher,
        subcategoryOptions: (filterText) => this.subcategoryService.getDispatchers(filterText),
      },
      {
        label: ReportingWizardFilterCategoryLabels.TruckNumber,
        value: ReportingWizardFilterCategoryValues.TruckNumber,
      },
      {
        label: ReportingWizardFilterCategoryLabels.EquipmentType,
        value: ReportingWizardFilterCategoryValues.EquipmentType,
      },
      {
        label: ReportingWizardFilterCategoryLabels.TrailerNumber,
        value: ReportingWizardFilterCategoryValues.TrailerNumber,
      },
      {
        label: ReportingWizardFilterCategoryLabels.Invoiced,
        value: ReportingWizardFilterCategoryValues.Invoiced,
        defaultDateType: CategoryDateType.InvoiceDate,
      },
      {
        label: ReportingWizardFilterCategoryLabels.Delivered,
        value: ReportingWizardFilterCategoryValues.Delivered,
        defaultDateType: CategoryDateType.DeliveryDate,
      },
      {
        label: ReportingWizardFilterCategoryLabels.DriverSettlement,
        value: ReportingWizardFilterCategoryValues.DriverSettlement,
        defaultDateType: CategoryDateType.DateIssued,
        subcategoryOptions: drivers$,
        disabled: !this.isRoleAloudToRunDriverSettlement(),
      },
    ];
  }

  private getDateTypes(): SelectItem[] {
    return [
      {
        label: 'Ship Date',
        value: CategoryDateType.ShipDate,
      },
      {
        label: 'Delivery Date',
        value: CategoryDateType.DeliveryDate,
      },
      {
        label: 'Invoice Date',
        value: CategoryDateType.InvoiceDate,
      },
      {
        label: 'Created Date',
        value: CategoryDateType.CreatedDate,
      },
      {
        label: 'Date Issued',
        value: CategoryDateType.DateIssued,
        disabled: true,
      },
    ];
  }
}
