import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NoteDTO, TenantDTO } from 'src/apiclient/v1.1/models';
import { AllTenantService, LogisticsTenantService, TenantService } from 'src/apiclient/v1.1/services';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { NoteType } from 'src/app/data/static-data';
import { ClientTokenService } from 'src/services/client-token-service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';

@Component({
  selector: 'app-settings-notes',
  templateUrl: './settings-notes.component.html',
  styleUrls: ['./settings-notes.component.scss'],
})
export class SettingsNotesComponent implements OnDestroy {
  @Input() set tenant(tenant: TenantDTO) {
    this._tenant = tenant;
    this.conditionallyDisableLogisticsNotes();
  }
  get tenant(): TenantDTO {
    return this._tenant;
  }
  @Input() isSuperAdmin: boolean = false;

  public disableLogisticsNotes: boolean = true;

  private destroy$ = new Subject<void>();
  private _tenant: TenantDTO;

  constructor(
    private clientTokenService: ClientTokenService,
    private tenantService: TenantService,
    private allTenantService: AllTenantService,
    private logisticsTenantService: LogisticsTenantService,
    private errorHandlingService: ErrorHandlingService,
    private notificationsService: NotificationsService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveDispatchNotes(newNotes: string): void {
    const note: NoteDTO = {
      noteType: NoteType.Dispatch,
      text: newNotes,
    };
    this.saveTenantNotes(note)
      .pipe(
        tap(() => (this.tenant.dispatchNotes = newNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public saveInvoiceNotes(newNotes: string): void {
    const note: NoteDTO = {
      noteType: NoteType.Invoice,
      text: newNotes,
    };
    this.saveTenantNotes(note)
      .pipe(
        tap(() => (this.tenant.invoiceNotes = newNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public saveRateConfirmationSheetNotes(newNotes: string): void {
    this.saveLogisticsTenantNotes(newNotes, 'rateConfirmationSheetNotes')
      .pipe(
        tap(() => (this.tenant.logisticsTenant.rateConfirmationSheetNotes = newNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public saveBillOfLadingNotes(newNotes: string): void {
    this.saveLogisticsTenantNotes(newNotes, 'billOfLadingNotes')
      .pipe(
        tap(() => (this.tenant.logisticsTenant.billOfLadingNotes = newNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private saveTenantNotes(notes: NoteDTO): Observable<void> {
    return of(this.isSuperAdmin).pipe(
      switchMap((isSuperAdmin) => {
        return isSuperAdmin
          ? this.allTenantService.SetTenantNotes({
              id: this.tenant.tenantId,
              body: notes,
              Authorization: this.clientTokenService.auth(),
            })
          : this.tenantService.SetTenantDispatchNotes({
              body: notes,
              Authorization: this.clientTokenService.auth(),
            });
      }),
      tap(() => this.notificationsService.success('Info Updated.', 'Your updates were saved successfully.')),
      catchError((error) => this.errorHandlingService.handleError(error))
    );
  }

  private saveLogisticsTenantNotes(newNotes: string, property: string): Observable<void> {
    return this.logisticsTenantService
      .Patch({
        Authorization: this.clientTokenService.auth(),
        body: [
          {
            value: newNotes,
            path: `/${property}`,
            op: 'replace',
          },
        ],
      })
      .pipe(
        tap(() => this.notificationsService.success('Info Updated.', 'Your updates were saved successfully.')),
        catchError((error) => this.errorHandlingService.handleError(error))
      );
  }

  private conditionallyDisableLogisticsNotes(): void {
    if (!this.tenant.isLogistics) {
      this.disableLogisticsNotes = true;
      return;
    }

    this.disableLogisticsNotes = this.tenant.tenantId !== this.clientTokenService.tenantId();
  }
}
