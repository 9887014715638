<p-card styleClass="ui-card-shadow">
  <div class="grid">
    <div class="col-12">
      <h1>System Administration</h1>
    </div>
    <div class="col-12">
      <p-tabMenu [model]="tabItems"> </p-tabMenu>
      <router-outlet></router-outlet>
    </div>
  </div>
</p-card>
