<div class="panel-container bg-white z-5 absolute h-full" [ngClass]="showPanel ? 'open' : 'closed'">
  <div class="panel-header-container bg-white pt-2 pl-4 pb-2 flex justify-content-between flex-wrap font-semibold">
    <div *ngIf="showPanel" class="panel-header-text line-height-3">
      {{ headerText }}
    </div>
  </div>
  <div class="panel-buttons">
    <div
      *ngIf="!showPanel"
      class="panel-button open-panel-button border-circle absolute fa-stack"
      id="open-button"
      (click)="openPanel()"
    >
      <i class="fa fa-stack-2x" [ngClass]="openPanelButtonIcon" id="open-icon" aria-hidden="true"></i>
      <i *ngIf="alertCount" id="alert-badge" class="fa fa-stack-1x" aria-hidden="true"
        ><i class="alert-count">{{ getFormattedAlertCount() }}</i></i
      >
    </div>
    <button
      *ngIf="showPanel"
      id="close-button"
      class="panel-button close-panel-button border-circle absolute"
      [ngClass]="closePanelButtonIcon"
      (click)="closePanel()"
    ></button>
  </div>
  <div class="flex-auto panel-content-container">
    <p-scrollPanel
      *ngIf="showPanel"
      [style]="{ width: '100%', height: '100%', 'border-top': 'none' }"
      styleClass="scrollpanel-styles"
    >
      <app-notifications-panel *ngIf="contentType === panelContentType.Notifications" [notifications]="content">
      </app-notifications-panel>
    </p-scrollPanel>
  </div>
</div>
