/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedLogisticsLoadSummaryDTO } from '../models/paged-logistics-load-summary-dto';
import { LogisticsLoadDTO } from '../models/logistics-load-dto';
@Injectable({
  providedIn: 'root',
})
class LogisticsLoadService extends __BaseService {
  static readonly GetLoadsPath = '/api/LogisticsLoad';
  static readonly CreateLoadPath = '/api/LogisticsLoad';
  static readonly GetLoadPath = '/api/LogisticsLoad/{id}';
  static readonly DeletePath = '/api/LogisticsLoad/{id}';
  static readonly UnDeletePath = '/api/LogisticsLoad/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all loads for user
   * @param params The `LogisticsLoadService.GetLoadsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * - `SortByAscending`:
   *
   * - `SortBy`:
   *
   * - `SearchTerm`:
   *
   * - `SalesRepresentativeContains`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   *
   * - `Hot`:
   *
   * - `FilterBy`:
   *
   * - `CarrierContains`:
   *
   * - `CarrierAgentContains`:
   *
   * - `AccountManagerContains`:
   *
   * @return Success
   */
  GetLoadsResponse(params: LogisticsLoadService.GetLoadsParams): __Observable<__StrictHttpResponse<PagedLogisticsLoadSummaryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.workOrder != null) __params = __params.set('workOrder', params.workOrder.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.shipperContains != null) __params = __params.set('shipperContains', params.shipperContains.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    if (params.needsAttention != null) __params = __params.set('needsAttention', params.needsAttention.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    if (params.invoiceUtcEnd != null) __params = __params.set('invoiceUtcEnd', params.invoiceUtcEnd.toString());
    if (params.invoiceUtcBegin != null) __params = __params.set('invoiceUtcBegin', params.invoiceUtcBegin.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    (params.driverIdEquals || []).forEach(val => {if (val != null) __params = __params.append('driverIdEquals', val.toString())});
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.dispatcherContains != null) __params = __params.set('dispatcherContains', params.dispatcherContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    if (params.customerContains != null) __params = __params.set('customerContains', params.customerContains.toString());
    if (params.consigneeContains != null) __params = __params.set('consigneeContains', params.consigneeContains.toString());
    if (params.accountingExported != null) __params = __params.set('accountingExported', params.accountingExported.toString());
    if (params.SortByAscending != null) __params = __params.set('SortByAscending', params.SortByAscending.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SearchTerm != null) __params = __params.set('SearchTerm', params.SearchTerm.toString());
    if (params.SalesRepresentativeContains != null) __params = __params.set('SalesRepresentativeContains', params.SalesRepresentativeContains.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageIndex != null) __params = __params.set('PageIndex', params.PageIndex.toString());
    if (params.Hot != null) __params = __params.set('Hot', params.Hot.toString());
    if (params.FilterBy != null) __params = __params.set('FilterBy', params.FilterBy.toString());
    if (params.CarrierContains != null) __params = __params.set('CarrierContains', params.CarrierContains.toString());
    if (params.CarrierAgentContains != null) __params = __params.set('CarrierAgentContains', params.CarrierAgentContains.toString());
    if (params.AccountManagerContains != null) __params = __params.set('AccountManagerContains', params.AccountManagerContains.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LogisticsLoad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedLogisticsLoadSummaryDTO>;
      })
    );
  }
  /**
   * Gets all loads for user
   * @param params The `LogisticsLoadService.GetLoadsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * - `SortByAscending`:
   *
   * - `SortBy`:
   *
   * - `SearchTerm`:
   *
   * - `SalesRepresentativeContains`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   *
   * - `Hot`:
   *
   * - `FilterBy`:
   *
   * - `CarrierContains`:
   *
   * - `CarrierAgentContains`:
   *
   * - `AccountManagerContains`:
   *
   * @return Success
   */
  GetLoads(params: LogisticsLoadService.GetLoadsParams): __Observable<PagedLogisticsLoadSummaryDTO> {
    return this.GetLoadsResponse(params).pipe(
      __map(_r => _r.body as PagedLogisticsLoadSummaryDTO)
    );
  }

  /**
   * Create load
   * @param params The `LogisticsLoadService.CreateLoadParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Created
   */
  CreateLoadResponse(params: LogisticsLoadService.CreateLoadParams): __Observable<__StrictHttpResponse<LogisticsLoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LogisticsLoad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LogisticsLoadDTO>;
      })
    );
  }
  /**
   * Create load
   * @param params The `LogisticsLoadService.CreateLoadParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Created
   */
  CreateLoad(params: LogisticsLoadService.CreateLoadParams): __Observable<LogisticsLoadDTO> {
    return this.CreateLoadResponse(params).pipe(
      __map(_r => _r.body as LogisticsLoadDTO)
    );
  }

  /**
   * Gets load by Id
   * @param params The `LogisticsLoadService.GetLoadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetLoadResponse(params: LogisticsLoadService.GetLoadParams): __Observable<__StrictHttpResponse<LogisticsLoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LogisticsLoad/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LogisticsLoadDTO>;
      })
    );
  }
  /**
   * Gets load by Id
   * @param params The `LogisticsLoadService.GetLoadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetLoad(params: LogisticsLoadService.GetLoadParams): __Observable<LogisticsLoadDTO> {
    return this.GetLoadResponse(params).pipe(
      __map(_r => _r.body as LogisticsLoadDTO)
    );
  }

  /**
   * Soft-delete load by ID
   * @param params The `LogisticsLoadService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteResponse(params: LogisticsLoadService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/LogisticsLoad/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete load by ID
   * @param params The `LogisticsLoadService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  Delete(params: LogisticsLoadService.DeleteParams): __Observable<null> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Undelete load by ID
   * @param params The `LogisticsLoadService.UnDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  UnDeleteResponse(params: LogisticsLoadService.UnDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/LogisticsLoad/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Undelete load by ID
   * @param params The `LogisticsLoadService.UnDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  UnDelete(params: LogisticsLoadService.UnDeleteParams): __Observable<null> {
    return this.UnDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LogisticsLoadService {

  /**
   * Parameters for GetLoads
   */
  export interface GetLoadsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    workOrder?: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingEnd?: string;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingBegin?: string;
    shipperContains?: string;
    poNumber?: string;
    needsAttention?: boolean;
    loadIdEquals?: Array<number>;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcEnd?: string;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcBegin?: string;
    invoiceNumber?: number;
    driverIdEquals?: Array<number>;
    driverContains?: string;
    dispatcherContains?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryEnd?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryBegin?: string;
    customerContains?: string;
    consigneeContains?: string;
    accountingExported?: boolean;
    SortByAscending?: boolean;
    SortBy?: string;
    SearchTerm?: string;
    SalesRepresentativeContains?: string;
    PageSize?: number;
    PageIndex?: number;
    Hot?: boolean;
    FilterBy?: string;
    CarrierContains?: string;
    CarrierAgentContains?: string;
    AccountManagerContains?: string;
  }

  /**
   * Parameters for CreateLoad
   */
  export interface CreateLoadParams {
    body: LogisticsLoadDTO;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetLoad
   */
  export interface GetLoadParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UnDelete
   */
  export interface UnDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { LogisticsLoadService }
