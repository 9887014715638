import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FullAddressDTO } from 'src/apiclient/v1.1/models';

@Pipe({
  name: 'formatAddress',
})
@Injectable({
  providedIn: 'root',
})
export class FormatAddress implements PipeTransform {
  public transform(value: FullAddressDTO): string {
    if (!value) {
      return null;
    }

    const streetAddress = `${value?.line1} ${value.line2 ?? ''}`.trim();
    return `${streetAddress}, ${value.city} ${value.stateOrTerritory} ${value.postalCode}, ${value.country}`;
  }
}
