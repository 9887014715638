/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FactoringClientDTO } from '../models/factoring-client-dto';
@Injectable({
  providedIn: 'root',
})
class TenantFactoringService extends __BaseService {
  static readonly SetFactoringStatusPath = '/api/TenantFactoring/SetFactoringStatus';
  static readonly AreTenantBusinessAssociatesReadyForFactoringPath = '/api/TenantFactoring/BusinessAssociatesCheck';
  static readonly CreateFactoringClientPath = '/api/TenantFactoring/FactoringClients/{id}';
  static readonly GetFactoringClientByTenantIdPath = '/api/TenantFactoring/FactoringClients/{id}';
  static readonly GetFactoringClientPath = '/api/TenantFactoring/FactoringClient';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Set Tenant Factoring Status
   * @param params The `TenantFactoringService.SetFactoringStatusParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `enabled`:
   */
  SetFactoringStatusResponse(params: TenantFactoringService.SetFactoringStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.enabled != null) __params = __params.set('enabled', params.enabled.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TenantFactoring/SetFactoringStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set Tenant Factoring Status
   * @param params The `TenantFactoringService.SetFactoringStatusParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `enabled`:
   */
  SetFactoringStatus(params: TenantFactoringService.SetFactoringStatusParams): __Observable<null> {
    return this.SetFactoringStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Checks if business associates are ready for factoring
   * @param params The `TenantFactoringService.AreTenantBusinessAssociatesReadyForFactoringParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   */
  AreTenantBusinessAssociatesReadyForFactoringResponse(params: TenantFactoringService.AreTenantBusinessAssociatesReadyForFactoringParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantFactoring/BusinessAssociatesCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Checks if business associates are ready for factoring
   * @param params The `TenantFactoringService.AreTenantBusinessAssociatesReadyForFactoringParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   */
  AreTenantBusinessAssociatesReadyForFactoring(params: TenantFactoringService.AreTenantBusinessAssociatesReadyForFactoringParams): __Observable<null> {
    return this.AreTenantBusinessAssociatesReadyForFactoringResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create Factoring Client for Tenant
   * @param params The `TenantFactoringService.CreateFactoringClientParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  CreateFactoringClientResponse(params: TenantFactoringService.CreateFactoringClientParams): __Observable<__StrictHttpResponse<FactoringClientDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TenantFactoring/FactoringClients/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FactoringClientDTO>;
      })
    );
  }
  /**
   * Create Factoring Client for Tenant
   * @param params The `TenantFactoringService.CreateFactoringClientParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  CreateFactoringClient(params: TenantFactoringService.CreateFactoringClientParams): __Observable<FactoringClientDTO> {
    return this.CreateFactoringClientResponse(params).pipe(
      __map(_r => _r.body as FactoringClientDTO)
    );
  }

  /**
   * Get Factoring Client for Tenant
   * @param params The `TenantFactoringService.GetFactoringClientByTenantIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetFactoringClientByTenantIdResponse(params: TenantFactoringService.GetFactoringClientByTenantIdParams): __Observable<__StrictHttpResponse<FactoringClientDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantFactoring/FactoringClients/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FactoringClientDTO>;
      })
    );
  }
  /**
   * Get Factoring Client for Tenant
   * @param params The `TenantFactoringService.GetFactoringClientByTenantIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetFactoringClientByTenantId(params: TenantFactoringService.GetFactoringClientByTenantIdParams): __Observable<FactoringClientDTO> {
    return this.GetFactoringClientByTenantIdResponse(params).pipe(
      __map(_r => _r.body as FactoringClientDTO)
    );
  }

  /**
   * Get Factoring Client for current Tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetFactoringClientResponse(Authorization: string): __Observable<__StrictHttpResponse<FactoringClientDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantFactoring/FactoringClient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FactoringClientDTO>;
      })
    );
  }
  /**
   * Get Factoring Client for current Tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetFactoringClient(Authorization: string): __Observable<FactoringClientDTO> {
    return this.GetFactoringClientResponse(Authorization).pipe(
      __map(_r => _r.body as FactoringClientDTO)
    );
  }
}

module TenantFactoringService {

  /**
   * Parameters for SetFactoringStatus
   */
  export interface SetFactoringStatusParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    tenantId?: number;
    enabled?: boolean;
  }

  /**
   * Parameters for AreTenantBusinessAssociatesReadyForFactoring
   */
  export interface AreTenantBusinessAssociatesReadyForFactoringParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    tenantId?: number;
  }

  /**
   * Parameters for CreateFactoringClient
   */
  export interface CreateFactoringClientParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetFactoringClientByTenantId
   */
  export interface GetFactoringClientByTenantIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { TenantFactoringService }
