export enum ReportingWizardFilterCategoryLabels {
  Driver = 'Driver',
  BillTo = 'Bill To/Customer',
  Shipper = 'Shipper',
  Consignee = 'Consignee',
  Dispatcher = 'Dispatcher',
  TruckNumber = 'Truck Number',
  EquipmentType = 'Equipment Type',
  TrailerNumber = 'Trailer Number',
  Invoiced = 'Invoiced',
  Delivered = 'Delivered',
  DriverSettlement = 'Driver Settlement',
}
