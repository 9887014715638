import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buyStatus',
})
export class FormatBuyStatus implements PipeTransform {
  transform(value: boolean, format?: any): string {
    return value ? 'Buy' : 'No Buy';
  }
}
