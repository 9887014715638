import { Component, OnInit, Injector, ViewEncapsulation, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DtoFormBase } from '../../../shared/FormBase';
import { AccountingService } from '../../../../apiclient/services';
import { LoadService } from '../../../../apiclient/services/load.service';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { Utils } from '../../../../utils/utils';
import { saveAs } from 'file-saver/FileSaver';
import { BulkActionName, LoadStatusName } from '../../../data/static-data';
import { PfsTableBodyComponent } from '../../../components/table/pfs-table-body/pfs-table-body.component';
import { FactoringLoginService } from '../../../../services/factoring-login/factoring-login.service';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadFundingStatusService } from '../../../../services/load-funding-status-service';
import { Observable, Subject } from 'rxjs';
import { TableColumn } from '../../../shared/models/TableColumn';
import { BulkActionItem } from 'src/app/components/bulk-action/bulk-action-item';
import { ListLoadSummaryDTO } from '../models/list-load-summary-dto';
import { LoadSummaryDTO } from 'src/apiclient/models';
import { BulkActionService } from 'src/services/bulk-action/bulk-action-service';
import { StatusFilter } from 'src/app/shared/components/status-filter/models/status-filter';
import { SelectedLoad } from 'src/app/shared/load/components/list/load-action-dialog/models/selected-load';

/**
 * App Route: /BackOffice/Invoicing
 */
@Component({
  selector: 'app-load-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadPaymentsComponent extends DtoFormBase<null> implements OnInit, OnDestroy {
  loading: boolean;
  showDialog: boolean;
  actionType: string;
  disabled: boolean = false;

  public cols: TableColumn[];

  loads: ListLoadSummaryDTO[];
  loadsOriginal: ListLoadSummaryDTO[];
  selectedForAction: SelectedLoad[];

  disableApply: boolean = false;

  private amountColHeader = 'Amount';
  private destroy$ = new Subject();

  readonly ALL_STATUS_INDEX = 6;
  public statusFilters: StatusFilter[];
  public actionOptions: BulkActionItem[];

  bulkCheckAll: boolean = false;

  filter: any = {
    applied: false,
    status: null,
    search: '',
    by: null,
    byOptions: [
      {
        label: 'Customer',
        value: 'customer',
      },
      {
        label: 'Driver',
        value: 'driver',
      },
    ],
    contains: null,
    dateType: null,
    dateRange: [],
    dateBegin: null,
    dateEnd: null,
    shippingBegin: null,
    shippingEnd: null,
    deliveryBegin: null,
    deliveryEnd: null,
    invoiceUtcBegin: null,
    invoiceUtcEnd: null,
    datesOptions: [
      {
        label: 'Ship Date',
        value: 'ship',
      },
      {
        label: 'Delivery Date',
        value: 'delivery',
      },
      {
        label: 'Invoice Date',
        value: 'invoice',
      },
    ],
    workOrder: null,
    invoiceNumber: null,
  };
  exportItems = [
    {
      label: 'Export to Excel',
      command: () => {
        this.export();
      },
    },
  ];
  tenantFactoringEnabled: boolean;
  public showLoadDialog = false;
  public selectedLoadId: number;

  @ViewChildren('pfsPaymentTable') pfsPaymentTable: QueryList<PfsTableBodyComponent>;

  constructor(
    protected injector: Injector,
    private loadService: LoadService,
    private accountingService: AccountingService,
    private breadcrumbService: BreadcrumbService,
    private factoringLoginService: FactoringLoginService,
    private loadFundingStatusService: LoadFundingStatusService,
    private bulkActionService: BulkActionService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initFilterStatuses();
    this.initBulkActionOptions();

    this.breadcrumbService.setItems('invoicing');

    this.getDataFiltered();

    this.clientToken.isFactoringEnabledForTenant().subscribe((isFactoringEnabled) => {
      this.tenantFactoringEnabled = isFactoringEnabled;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDataFiltered() {
    this.loading = true;

    let filterStatuses = null;
    if (!this.statusFilters[this.ALL_STATUS_INDEX].active) {
      filterStatuses = [];
      this.statusFilters.filter((x) => x.active).forEach((x) => filterStatuses.push(x.value));
    }

    const statusNotEquals = <any[]>[
      LoadStatusName.Open,
      LoadStatusName.Assigned,
      LoadStatusName.Dispatched,
      LoadStatusName.InTransit,
      LoadStatusName.InYard,
    ];

    if (filterStatuses && !filterStatuses.includes('Archive')) {
      statusNotEquals.push(LoadStatusName.Archive);
    }

    const params: LoadService.ApiLoadFilterGetParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: filterStatuses,
      statusNotEquals: statusNotEquals,
      shippingBegin: this.filter.shippingBegin,
      shippingEnd: this.filter.shippingEnd,
      deliveryBegin: this.filter.deliveryBegin,
      deliveryEnd: this.filter.deliveryEnd,
      invoiceUtcBegin: this.filter.invoiceUtcBegin,
      invoiceUtcEnd: this.filter.invoiceUtcEnd,
      workOrder: this.filter.workOrder,
      invoiceNumber: this.filter.invoiceNumber,
    };

    if (this.filter.by) {
      params[this.filter.by + 'Contains'] = this.filter.contains;
    }

    this.loadService
      .ApiLoadFilterGet(params)
      .pipe(
        catchError((error) => this.handleError(error)),
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$),
        tap((data) => {
          this.filter.applied = true;
          this.setLoadData(data);
        }),
        switchMap(() => this.setFundingStatusesOnLoads())
      )
      .subscribe();
  }

  private setFundingStatusesOnLoads(): Observable<void> {
    // HACK: display random funding statuses for a customer demo. FC integration is currently offline. 2024-11-08, ibliskavka
    const statuses = ['Queued', 'Submitted', 'Funded', 'On Hold'];

    const temp = [...this.loads];
    temp.forEach((x) => {
      (x as any).fundingStatus = statuses[Math.floor(Math.random() * statuses.length)];
    });
    this.setLoadData(temp);
    return this.clientToken.isFactoringEnabledForTenant().pipe(
      filter((isFactoringEnabled) => isFactoringEnabled),
      switchMap(() =>
        this.loadFundingStatusService.setFundingStatusesForLoads(this.loads).pipe(
          takeUntil(this.destroy$),
          tap((result) => {
            this.setLoadData(result);
          }),
          map(() => null)
        )
      )
    );
  }

  private setLoadData(data): void {
    this.loads = data;
    this.loadsOriginal = data;

    this.formatLoadData();
    this.updateSummaryCols();
  }

  formatLoadData() {
    for (const load of this.loads) {
      if (!load.tracking) {
        load['tracking'] = ' ';
      }
    }
  }

  filterDataByStatus(status: StatusFilter) {
    this.statusFilters.forEach((i) => (i.active = false));
    status.active = true;

    this.bulkCheckAll = false;
    this.actionOptions = this.bulkActionService.setActions(this.actionOptions, status);
    this.getDataFiltered();
  }

  private updateSummaryCols(): void {
    this.cols = this.getTableColumns();

    this.clientToken
      .isFactoringEnabledForTenant()
      .pipe(
        filter((isFactoringEnabled) => isFactoringEnabled),
        tap(() => this.updateSummaryColsForFactoring())
      )
      .subscribe();
  }

  private updateSummaryColsForFactoring() {
    const indexOfAmountCol = this.cols.findIndex((c) => c.header === this.amountColHeader);

    this.cols[indexOfAmountCol] = {
      header: this.amountColHeader,
      field: 'total',
      sortable: true,
      format: 'fundingAmount',
      innerField: 'fundingStatus',
      width: '12.5em',
    };
  }

  filterDateRangeChange() {
    switch (this.filter.dateType) {
      case 'ship':
        this.filter.shippingBegin = Utils.formatDate(this.filter.dateBegin);
        this.filter.shippingEnd = Utils.formatDate(this.filter.dateEnd);
        this.filter.deliveryBegin = null;
        this.filter.deliveryEnd = null;
        this.filter.invoiceUtcBegin = null;
        this.filter.invoiceUtcEnd = null;
        break;
      case 'delivery':
        this.filter.shippingBegin = null;
        this.filter.shippingEnd = null;
        this.filter.deliveryBegin = Utils.formatDate(this.filter.dateBegin);
        this.filter.deliveryEnd = Utils.formatDate(this.filter.dateEnd);
        this.filter.invoiceUtcBegin = null;
        this.filter.invoiceUtcEnd = null;
        break;
      case 'invoice':
        this.filter.shippingBegin = null;
        this.filter.shippingEnd = null;
        this.filter.deliveryBegin = null;
        this.filter.deliveryEnd = null;
        this.filter.invoiceUtcBegin = Utils.formatDateUtc(this.filter.dateBegin);
        this.filter.invoiceUtcEnd = Utils.formatDateUtc(this.filter.dateEnd);
        break;
      default:
        this.filter.shippingBegin = null;
        this.filter.shippingEnd = null;
        this.filter.deliveryBegin = null;
        this.filter.deliveryEnd = null;
        this.filter.invoiceUtcBegin = null;
        this.filter.invoiceUtcEnd = null;
        break;
    }
  }

  filterClear() {
    this.filter = {
      ...this.filter,
      applied: false,
      status: null,
      search: '',
      by: null,
      contains: null,
      dateType: null,
      dateRange: [],
      dateBegin: null,
      dateEnd: null,
      shippingBegin: null,
      shippingEnd: null,
      deliveryBegin: null,
      deliveryEnd: null,
      invoiceUtcBegin: null,
      invoiceUtcEnd: null,
      workOrder: null,
      invoiceNumber: null,
    };
  }

  actionHandler = (actionConfig) => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  handleModelEdit(data) {
    if (data.dataChanged) {
      this.getDataFiltered();
    }
  }

  public openEditLoadDialog(params: { row: ListLoadSummaryDTO }): void {
    this.selectedLoadId = params.row.loadId;
    this.disabled = params.row.isDeleted;
    this.showLoadDialog = true;
  }

  sortTable(event: any) {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
    }
    Utils.sortData(event, this.loads);
  }

  filterTable() {
    if (this.filter.search !== '') {
      this.loads = this.loadsOriginal.filter(
        (item) =>
          item.loadNumber.toString().includes(this.filter.search.toLowerCase()) ||
          item.status.toLowerCase().includes(this.filter.search.toLowerCase()) ||
          (item.workOrder && item.workOrder.toLocaleLowerCase().includes(this.filter.search.toLowerCase())) ||
          (item.invoiceNumber && item.invoiceNumber.toLocaleLowerCase().includes(this.filter.search.toLowerCase())) ||
          item.customer.name.toLowerCase().includes(this.filter.search.toLowerCase())
      );
    } else {
      this.loads = this.loadsOriginal;
    }
  }

  bulkToggleCheckAll() {
    this.loads.forEach((load) => {
      load.isChecked = this.bulkCheckAll;
    });

    this.pfsPaymentTable.forEach((component) => component.updateValues());
  }

  public async handleAuditAction(selected: ListLoadSummaryDTO[]): Promise<void> {
    await this.accountingService
      .ApiAccountingSetStatusByStatusPost({
        status: LoadStatusName.Audited,
        loadIds: selected.map((x) => x.loadId),
        Authorization: this.clientToken.auth(),
      })
      .toPromise();
  }

  public async handlePaidAction(selected: ListLoadSummaryDTO[]): Promise<void> {
    await this.accountingService
      .ApiAccountingSetStatusByStatusPost({
        status: LoadStatusName.Paid,
        loadIds: selected.map((x) => x.loadId),
        Authorization: this.clientToken.auth(),
      })
      .toPromise();
  }

  public async handleExportAction(selected: ListLoadSummaryDTO[]): Promise<void> {
    const data = await this.accountingService
      .ApiAccountingExportGetResponse({
        loadIdEquals: selected.map((x) => x.loadId),
        Authorization: this.clientToken.auth(),
      })
      .toPromise();
    saveAs(data.body, 'invoicing.csv');
  }

  public async handleDeleteAction(selected: LoadSummaryDTO[]): Promise<void> {
    this.showDialog = true;
    this.actionType = BulkActionName.DeleteLoad;
    this.selectedForAction = selected.map((l) => ({ loadId: l.loadId, loadNumber: l.loadNumber }));
    return Promise.resolve();
  }

  public async handleUndeleteAction(selected: LoadSummaryDTO[]): Promise<void> {
    this.showDialog = true;
    this.actionType = BulkActionName.UndeleteLoad;
    this.selectedForAction = selected.map((l) => ({ loadId: l.loadId, loadNumber: l.loadNumber }));
    return Promise.resolve();
  }

  public handleBulkActionComplete(): void {
    this.bulkCheckAll = false;
    this.bulkToggleCheckAll();

    this.getDataFiltered();
  }

  getSelectedItems(): ListLoadSummaryDTO[] {
    if (this.loads) {
      return this.loads.filter((item) => item.isChecked === true);
    }
    return [];
  }

  export() {
    let filterStatuses = null;
    if (!this.statusFilters[this.ALL_STATUS_INDEX].active) {
      filterStatuses = [];
      this.statusFilters.filter((x) => x.active).forEach((x) => filterStatuses.push(x.value));
    }
    const params: LoadService.ApiLoadExportGetParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: filterStatuses,
      statusNotEquals: <any[]>[
        LoadStatusName.Open,
        LoadStatusName.Assigned,
        LoadStatusName.Dispatched,
        LoadStatusName.InTransit,
        LoadStatusName.InYard,
      ],
      shippingBegin: this.filter.shippingBegin,
      shippingEnd: this.filter.shippingEnd,
      deliveryBegin: this.filter.deliveryBegin,
      deliveryEnd: this.filter.deliveryEnd,
      invoiceUtcBegin: this.filter.invoiceUtcBegin,
      invoiceUtcEnd: this.filter.invoiceUtcEnd,
    };
    const call = this.loadService.ApiLoadExportGet(params);
    call.subscribe((data) => {
      saveAs(data, 'Load-list.csv');
    }, this.handleBasicError);
  }

  goToFundingDetails() {
    this.factoringLoginService.logInToFactorCloud();
  }

  private getTableColumns(): TableColumn[] {
    return [
      {
        header: '-',
        format: 'checkbox',
        field: 'isChecked',
        width: '3em',
      },
      {
        header: 'Load',
        field: 'loadNumber',
        sortable: true,
        format: 'actionCell',
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditLoadDialog',
          params: {
            row: 'row',
          },
        },
        width: '6em',
      },
      {
        header: 'Status',
        field: 'status',
        format: 'statusAlert',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Work Order/PO/BOL',
        field: 'workOrder',
        sortable: true,
        width: '12.5em',
      },
      {
        header: 'Customer',
        field: 'customer',
        sortable: true,
        sortableField: 'customer.name',
        format: 'UserName',
        width: '12.5em',
      },
      {
        header: 'Invoice',
        field: 'invoiceNumber',
        sortable: true,
        width: '7em',
      },
      {
        header: 'Invoice Date',
        field: 'invoiceDate',
        sortable: true,
        format: 'Date',
        width: '10em',
      },
      {
        header: this.amountColHeader,
        field: 'total',
        sortable: true,
        format: 'currency',
        width: '7em',
      },
      {
        header: 'Driver',
        field: 'driver',
        sortable: true,
        sortableField: 'driver.name',
        format: 'UserName',
        width: '12.5em',
      },
      {
        header: 'Ship Date',
        field: 'shipDate',
        sortable: true,
        format: 'Date',
        width: '10em',
      },
      {
        header: 'Delivery Date',
        field: 'deliveryDate',
        sortable: true,
        format: 'Date',
        width: '10em',
      },
      {
        header: 'Exported',
        format: 'readonlyCheckbox',
        field: 'accountingExported',
        width: '5em',
      },
    ];
  }

  private initFilterStatuses(): void {
    this.statusFilters = [
      {
        label: 'Delivered',
        value: LoadStatusName.Delivered,
        active: true,
      },
      {
        label: 'Audited',
        value: LoadStatusName.Audited,
        active: false,
      },
      {
        label: 'Invoiced',
        value: LoadStatusName.Invoiced,
        active: false,
      },
      {
        label: 'Paid',
        value: LoadStatusName.Paid,
        active: false,
      },
      {
        label: 'Claims',
        value: LoadStatusName.Claim,
        active: false,
      },
      {
        label: 'Archive',
        value: LoadStatusName.Archive,
        active: false,
      },
      {
        label: 'All',
        value: null,
        active: false,
      },
      {
        label: 'Deleted',
        value: LoadStatusName.Deleted,
        active: false,
      },
    ];
  }

  private initBulkActionOptions(): void {
    this.actionOptions = [
      {
        label: 'Mark as Audited',
        value: BulkActionName.MarkAsAudited,
        action: (selected) => this.handleAuditAction(selected),
      },
      {
        label: 'Mark as Paid',
        value: BulkActionName.MarkAsPaid,
        action: (selected) => this.handlePaidAction(selected),
      },
      {
        label: 'Export',
        value: BulkActionName.Export,
        action: (selected) => this.handleExportAction(selected),
      },
      {
        label: 'Delete Load',
        value: BulkActionName.DeleteLoad,
        maximumItemsAllowed: 5,
        action: (selected) => this.handleDeleteAction(selected),
      },
      {
        label: 'Undelete Load',
        value: BulkActionName.UndeleteLoad,
        maximumItemsAllowed: null,
        action: (selected) => this.handleUndeleteAction(selected),
      },
    ];

    this.actionOptions = this.bulkActionService.setActions(
      this.actionOptions,
      this.statusFilters.find((status) => status.value === null)
    );
  }
}
