import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoadAttachmentDTO } from '../../../../apiclient/v1.1/models';
import { LoadService } from '../../../../apiclient/v1.1/services';
import { StaticData } from '../../../data/static-data';
import { DtoFormBase } from '../../../shared/FormBase';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-document-add-dialog',
  templateUrl: './document-add-dialog.component.html',
  styleUrls: ['./document-add-dialog.component.scss'],
})
export class DocumentAddDialogComponent extends DtoFormBase<LoadAttachmentDTO> implements OnInit, OnDestroy {
  @Input() loadId: number;
  @Input() dialogVisible: boolean;
  @Output() fileUploaded = new EventEmitter<void>();
  @Output() dialogClosed = new EventEmitter<void>();

  public fileTypeConfig = {
    selectOptions: StaticData.loadAttachmentTypes,
  };
  public disableSave = false;
  private destroy$ = new Subject<void>();
  private uploadedFile: File;

  constructor(protected injector: Injector, private loadService: LoadService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('LoadAttachmentDTO');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onFileSelect(event: { originalEvent: Event; files: FileList }): void {
    const files = event.files;
    if (files && files.length > 0) {
      this.formErrors['file'] = '';
    }
    this.uploadedFile = files[0];
  }

  public typeChanged(): void {
    this.formErrors['type'] = '';
  }

  public closeDialog(): void {
    this.dialogVisible = false;
    this.dialogClosed.emit();
  }

  public saveModel(): void {
    if (!this.uploadedFile) {
      this.formErrors['file'] = 'File is required.';
    }
    if (!this.uploadedFile || !this.formValid()) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.disableSave = true;
    this.loadService
      .UploadAttachment({
        loadId: this.loadId,
        file: this.uploadedFile,
        Authorization: this.clientToken.auth(),
        fileType: this.formGroup.controls.type.value,
      })
      .pipe(
        tap(() => {
          this.fileUploaded.emit();
          this.closeDialog();
        }),
        catchError((err) => this.handleUploadError(err)),
        finalize(() => (this.disableSave = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleUploadError(err): Observable<never> {
    // Doing this to show error related to filePath and prevent an exception.
    if (err.error['filePath']) {
      Object.defineProperty(err.error, 'type', Object.getOwnPropertyDescriptor(err.error, 'filePath'));
      delete err.error['filePath'];
    }
    return this.handleError(err);
  }
}
