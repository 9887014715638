<div class="layout-content-container p-0" *ngIf="model">
  <form [formGroup]="formGroup">
    <app-edit-section
      title="Company Info"
      (saved)="saveTenant('info')"
      (cancelled)="reset('info')"
      [(editMode)]="editing.info"
      [disableSave]="disableSave"
    >
      <div read>
        <app-edit-section-row label="Company Logo" *ngIf="false" description="Shown on invoices and reports">
          <img [src]="model.logoUrl" width="3.125rem" />
        </app-edit-section-row>
        <app-edit-section-row label="Company Name">
          <span class="font-bold"> {{ model.name }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="MC number">
          <span class="font-bold"> {{ model.motorCarrierNumber || '-' }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="USDOT number *">
          <span class="font-bold"> {{ model.usDotNumber }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Number of trucks">
          <span class="font-bold">{{ model.numberOfTrucks }}</span>
        </app-edit-section-row>
        <app-edit-section-row label="Tenant Role" *ngIf="isRoleVisible">
          <span class="bold-text">{{ roleName }}</span>
        </app-edit-section-row>
      </div>
      <div edit>
        <app-edit-section-row
          label="Company Logo"
          *ngIf="false"
          description="Shown on invoices and reports"
          [(editMode)]="editing.info"
        >
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="logoUrl"
            [(ngModel)]="model.logoUrl"
            [formErrors]="formErrors"
            placeholder=""
            labelClass="nolabel"
            controlClass="col-12 md:col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Company Name" [(editMode)]="editing.info">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="name"
            [(ngModel)]="model.name"
            [formErrors]="formErrors"
            placeholder=""
            labelClass="nolabel"
            controlClass="col-12 md:col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="MC number" [(editMode)]="editing.info">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="motorCarrierNumber"
            [(ngModel)]="model.motorCarrierNumber"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-12 md:col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="USDOT number *" [(editMode)]="editing.info">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="usDotNumber"
            [(ngModel)]="model.usDotNumber"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-12 md:col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Number of trucks" [(editMode)]="editing.info">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="numberOfTrucks"
            [(ngModel)]="model.numberOfTrucks"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-12 md:col-6 p-0"
          >
          </app-form-field>
          <div class="col-12 p-0 formerrors">{{ formErrors.numberOfTrucks }}</div>
        </app-edit-section-row>
        <app-edit-section-row label="Tenant Role" [editMode]="true" *ngIf="isRoleVisible">
          {{ roleName }}
        </app-edit-section-row>
      </div>
    </app-edit-section>

    <app-edit-section
      title="Address"
      (saved)="saveTenant('address')"
      (cancelled)="reset('address')"
      [(editMode)]="editing.address"
      [disableSave]="disableSave"
    >
      <div read>
        <app-edit-section-row label="Mailing address">
          <span class="font-bold"> {{ mailingAddressFormat() }} </span>
        </app-edit-section-row>
        <div class="grid grid-nogutter col-12">
          <div class="col-4">Physical address</div>
          <div class="col-8">
            <span [class.font-bold]="!isOneAddress()">
              {{ isOneAddress() ? 'Same as mailing address' : physicalAddressFormat() }}
            </span>
          </div>
        </div>
      </div>
      <div edit>
        <div class="grid grid-nogutter col-12 p-0">
          <app-address-v2
            class="col-12"
            [(formGroup)]="formGroup"
            prefix="mailing"
            title="Mailing Address"
            displayMode="twocol"
            [(Address)]="model.mailing"
            [validationMessages]="validationMessages"
          >
          </app-address-v2>
          <div class="col-4"></div>
          <div class="col-8 p-2">
            <p-toggleButton
              class="addressesToggle col-12"
              [(ngModel)]="addressesSame"
              onLabel="'Using Single Address'"
              offLabel="Click To Use One Address"
              onIcon="fa fa-check-square"
              offIcon="fa fa-square"
              formControlName="addressesSame"
            ></p-toggleButton>
          </div>
          <app-address-v2
            class="col-12"
            [(formGroup)]="formGroup"
            prefix="physical"
            title="Physical address"
            displayMode="twocol"
            [(Address)]="model.physical"
            [validationMessages]="validationMessages"
          >
          </app-address-v2>
        </div>
      </div>
    </app-edit-section>

    <app-edit-section
      title="Contact info"
      (saved)="saveTenant('contact')"
      (cancelled)="reset('contact')"
      [(editMode)]="editing.contact"
      [disableSave]="disableSave"
    >
      <div read>
        <app-phone-number
          [editMode]="false"
          prefix="phone"
          [(vm)]="model.phone"
          [(formGroup)]="formGroup"
          [(formErrors)]="formErrors"
          [(validationMessages)]="validationMessages"
          labelClass="col-4"
          controlClass="col-8"
          errorClass=""
        >
        </app-phone-number>
        <app-edit-section-row label="Website">
          <span class="font-bold"> {{ model.website }} </span>
        </app-edit-section-row>
      </div>
      <div edit>
        <div class="grid grid-nogutter col-12 p-2 pb-0">
          <app-phone-number
            class="col-12"
            [editMode]="true"
            prefix="phone"
            [(vm)]="model.phone"
            [(formGroup)]="formGroup"
            [(formErrors)]="formErrors"
            [(validationMessages)]="validationMessages"
            labelClass="col-4 font-bold"
            controlClass="col-8 p-0 pb-3"
          >
          </app-phone-number>

          <app-form-field
            class="col-12"
            [(formGroup)]="formGroup"
            label="Website"
            formControlName="website"
            [formErrors]="formErrors"
            [(ngModel)]="model.website"
            placeholder=""
            labelClass="col-4 bold-text"
            controlClass="col-8 p-0 pb-2"
          >
          </app-form-field>
        </div>
      </div>
    </app-edit-section>

    <app-edit-section
      title="System"
      (saved)="systemSaveClick()"
      (cancelled)="reset('system')"
      [(editMode)]="editing.system"
      [disableSave]="disableSave"
      data-test="system"
    >
      <div read>
        <app-edit-section-row
          label="Session Timeout"
          *appRenderRequiresPermission="superAdminPermissionRequired"
          data-test="session-timeout-read"
        >
          {{ model.sessionTimeout }}
        </app-edit-section-row>
        <app-edit-section-row label="Payment Terms Options">
          {{ model.paymentTermOptions }}
        </app-edit-section-row>
        <app-edit-section-row label="Load Status Update Hours">
          {{ model.loadStatusUpdateHours }}
        </app-edit-section-row>
        <app-edit-section-row label="Invoice number sequence">
          {{ model.nextLoadNumber }}
        </app-edit-section-row>
      </div>
      <div edit>
        <app-edit-section-row
          label="Session Timeout"
          [(editMode)]="editing.system"
          *appRenderRequiresPermission="superAdminPermissionRequired"
          data-test="session-timeout-edit"
        >
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="sessionTimeout"
            [(ngModel)]="model.sessionTimeout"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Payment Terms Options" [(editMode)]="editing.system">
          <p-checkbox
            *ngFor="let paymentTerm of paymentTermOptions"
            class="col-6 md:col-6"
            name="paymentTermOptions"
            formControlName="paymentTermOptions"
            value="{{ paymentTerm.label }}"
            label="{{ paymentTerm.value }}"
            [(ngModel)]="model.paymentTermOptions"
          ></p-checkbox>
        </app-edit-section-row>
        <app-edit-section-row label="Load Status Update Hours" [(editMode)]="editing.system">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="loadStatusUpdateHours"
            [(ngModel)]="model.loadStatusUpdateHours"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-6 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Invoice Number Sequence" [(editMode)]="editing.system">
          <app-form-field
            [(formGroup)]="formGroup"
            label=""
            formControlName="nextLoadNumber"
            [(ngModel)]="model.nextLoadNumber"
            [formErrors]="formErrors"
            placeholder=""
            keyFilter="number"
            labelClass="nolabel"
            controlClass="col-6 p-0"
            [viewMode]="nextLoadNumberLocked ? 'readonly' : ''"
          >
          </app-form-field>
        </app-edit-section-row>
      </div>
    </app-edit-section>

    <app-billing-status [model]="model"></app-billing-status>

    <app-factoring-status [model]="model"></app-factoring-status>
  </form>
</div>
<p-confirmDialog appendTo="body" key="NextLoadNumberConfirmation"></p-confirmDialog>
