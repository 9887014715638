<div class="grid grid-nogutter col-12 p-0">
  <div class="md:col-4 sm:col-12 p-2 font-bold">Bill To</div>
  <div class="md:col-8 sm:col-12 p-1">
    <div class="autoCompleteWrapper">
      <p-autoComplete
        styleClass="col-12 p-0"
        [(ngModel)]="selected"
        field="companyDisplayName"
        [suggestions]="results"
        (completeMethod)="search($event)"
        (onSelect)="onSelect()"
        (onClear)="onClear()"
        [forceSelection]="true"
        [dropdown]="true"
        minLength="1"
        emptyMessage="No Results"
        [disabled]="disabled"
        id="bill-to-dropdown"
      >
        <ng-template let-company pTemplate="item">
          <div *ngIf="company.companyAddressId > -1" class="billto-dropdown-item">
            <img *ngIf="company.iconPath" class="status-icon" [src]="company.iconPath" />
            <div>
              <div
                ><strong
                  >{{ company.companyName }} - {{ company.address?.city }}, {{ company.address?.state }}</strong
                ></div
              >
              <div
                >{{ company.address?.line1 }}, {{ company.address?.city }}, {{ company.address?.state }}
                {{ company.address?.zip }}</div
              >
            </div>
          </div>
          <div *ngIf="company.companyAddressId === -1" id="add-new-bill-to">{{ company.companyName }}</div>
        </ng-template>
      </p-autoComplete>
      <span class="autoCompleteClear" *ngIf="selected && selected.companyName && !readonly" (click)="onClear()">
        <i class="ui-dropdown-clear-icon pi pi-times"></i>
      </span>
    </div>
  </div>
</div>
<app-company-address-quick-add
  *ngIf="dialogVisible"
  [(visible)]="dialogVisible"
  [quickAddType]="companyAddressType.BillTo"
  [companyName]="searchValue"
  [inputCompanyAddressId]="selectedCompanyAddressId"
  (companySelectedEvent)="newBillToCreated($event)"
  (dialogClosedEvent)="dialogClosed()"
  id="add-new-dialog"
></app-company-address-quick-add>

<div *ngIf="confirmBillToDialogVisible">
  <app-bill-to-link-debtor
    [masterDebtor]="selectedMasterDebtor"
    [(visible)]="confirmBillToDialogVisible"
    (newBillToCreated)="newBillToCreated($event)"
  ></app-bill-to-link-debtor>
</div>
