import {
  Component,
  OnInit,
  Output,
  Injector,
  AfterViewInit,
  HostListener,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { DriverProfileDTO, ChargeDTO, AddressDTO, DocumentOnFileDTO } from '../../../../apiclient/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { DriverProfileService } from '../../../../apiclient/services/driver-profile.service';
import { ScrollManagerService } from '../../../../services/scroll-manager.service';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-driver-profile-edit',
  templateUrl: './driver-profile-edit.component.html',
  styleUrls: ['./driver-profile-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable:one-line
export class DriverProfileEditComponent
  extends DtoFormBase<DriverProfileDTO>
  implements OnInit, AfterViewInit, OnDestroy
{
  // tslint:enable:one-line
  @Output() modelEdited;

  public displayDialog: boolean;
  public viewAccounting: boolean = false;
  public dialogWidth: number;
  public dialogHeight: number;
  public contentDialogStyle: any;
  public isNewRecord = false;
  public viewOnly = true;
  public disableSave: boolean = false;
  public categoryConfig: any;
  public statusConfig: any;
  public emergencyContactRelationConfig: any;
  public payTypeConfig: any;
  public licenseTypeConfig: any;

  private modelId: number;
  private destroy$ = new Subject();

  @HostListener('window:resize') onResize() {
    this.dialogHeight = window.innerHeight;
    this.contentDialogStyle = { minHeight: window.innerHeight - 108 + 'px' };
  }

  constructor(
    private scrollManagerService: ScrollManagerService,
    private driverProfileService: DriverProfileService,
    protected injector: Injector
  ) {
    super(injector);
    this.modelEdited = new EventEmitter();
    this.model = {
      address: {},
      recurringPayments: [],
      recurringDeductions: [],
    } as DriverProfileDTO;

    this.initFormFor('DriverProfileDTO');
  }

  public ngOnInit(): void {
    this.initCategoryConfig();
    this.initStatusConfig();
    this.initEmergencyContactRelationConfig();
    this.initPayTypeConfig();
    this.initLicenseTypeConfig();
  }

  public ngAfterViewInit(): void {
    setTimeout((_) => {
      this.dialogHeight = window.innerHeight;
      this.contentDialogStyle = { minHeight: window.innerHeight - 108 + 'px' };
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(id): void {
    this.scrollManagerService.resetDialogScroll();

    this.viewAccounting = false;
    if (
      !this.clientToken.hasPermission('DriverProfileRead') &&
      !this.clientToken.hasPermission('DriverProfileAccounting')
    ) {
      this.router.navigate(['/']);
    }
    if (
      !(
        this.clientToken.hasRole('SuperAdmin') ||
        this.clientToken.hasRole('Admin') ||
        this.clientToken.hasRole('Dispatch')
      )
    ) {
      this.viewAccounting = true;
    }

    this.displayDialog = true;
    this.viewOnly = true;
    this.modelId = id;
    this.model = {} as DriverProfileDTO;
    this.model.address = {} as AddressDTO;
    this.model.recurringPayments = [] as ChargeDTO[];
    this.model.recurringDeductions = [] as ChargeDTO[];
    this.model.documentsOnFile = [] as DocumentOnFileDTO[];
    this.model.endorsementT = false;
    this.model.endorsementP = false;
    this.model.endorsementN = false;
    this.model.endorsementH = false;
    this.model.endorsementX = false;

    this.initFormFor('DriverProfileDTO');
    this.initModel();
    this.initFormErrors();
  }

  public saveModel(): void {
    this.formGroup.markAllAsTouched();
    if (this.formValid()) {
      this.disableSave = true;

      of(this.isNewRecord)
        .pipe(
          switchMap((isNewRecord) => (isNewRecord ? this.createDriver() : this.updateDriver())),
          tap(() => this.modelEdited.emit({ dataChanged: true })),
          catchError((error) => this.handleError(error)),
          finalize(() => {
            this.scrollManagerService.resetDialogScroll();
            this.displayDialog = false;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  public cancel(): void {
    this.scrollManagerService.resetDialogScroll();
    this.displayDialog = false;
    this.disableSave = false;
  }

  public cancelEditing(): void {
    this.initModel();
    this.viewOnly = true;
  }

  private updateDriver(): Observable<DriverProfileDTO> {
    return this.driverProfileService
      .ApiDriverProfileByIdPut({
        id: this.model.driverProfileId,
        Authorization: this.clientToken.auth(),
        dto: this.model,
      })
      .pipe(
        tap(() => {
          this._NS.success('Info updated!', 'Your changes were saved successfully.');
        })
      );
  }

  private createDriver(): Observable<DriverProfileDTO> {
    return this.driverProfileService
      .ApiDriverProfilePost({
        Authorization: this.clientToken.auth(),
        dto: this.model,
      })
      .pipe(
        tap(() => {
          this._NS.success('Driver created!', 'A new User was created successfully.');
        })
      );
  }

  private initFormErrors(): void {
    for (const errorKey of Object.keys(this.formErrors)) {
      this.formErrors[errorKey] = '';
    }
  }

  private initModel(): void {
    this.disableSave = true;
    this.isNewRecord = this.modelId === 0 || !this.modelId;

    of(this.isNewRecord)
      .pipe(
        switchMap((isNewRecord) => (isNewRecord ? this.setModelForNew() : this.setModelFromExisting())),
        catchError((error) => this.handleError(error)),
        finalize(() => (this.disableSave = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private disableFields(): void {
    const fields = ['firstName', 'lastName', 'phone', 'email'];
    for (const field of fields) {
      this.formGroup.get(field).disable();
    }
  }

  private setModelFromExisting(): Observable<DriverProfileDTO> {
    return this.driverProfileService
      .ApiDriverProfileByIdGet({
        id: this.modelId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        tap((driver) => {
          this.model = driver;
          this.viewOnly = true;
          this.disableFields();
          this.initFormErrors();
        })
      );
  }

  private setModelForNew(): Observable<DriverProfileDTO> {
    this.viewOnly = false;
    this.model.status = 'Active';
    this.formGroup.controls['status'].setValue('Active');

    return of(this.model);
  }

  private initCategoryConfig(): void {
    this.categoryConfig = {
      selectOptions: [
        {
          label: 'Owner Operator',
          value: 'OwnerOperator',
        },
        {
          label: 'Employee',
          value: 'Employee',
        },
        {
          label: 'Third Party',
          value: 'ThirdParty',
        },
      ],
    };
  }

  private initStatusConfig(): void {
    this.statusConfig = {
      selectOptions: [
        {
          label: 'Active',
          value: 'Active',
        },
        {
          label: 'Inactive',
          value: 'Inactive',
        },
      ],
    };
  }

  private initEmergencyContactRelationConfig(): void {
    this.emergencyContactRelationConfig = {
      selectOptions: [
        { label: 'Relative', value: 'Relative' },
        { label: 'Coworker', value: 'Coworker' },
      ],
    };
  }

  private initPayTypeConfig(): void {
    this.payTypeConfig = {
      selectOptions: [
        {
          label: 'Per mile',
          value: 'PerMile',
        },
        {
          label: 'Percent (%) of load',
          value: 'PercentOfRate',
        },
      ],
    };
  }

  private initLicenseTypeConfig(): void {
    this.licenseTypeConfig = {
      selectOptions: [
        {
          label: 'CDL-A',
          value: 'CdlA',
        },
        {
          label: 'CDL-B',
          value: 'CdlB',
        },
        {
          label: 'Non Commercial',
          value: 'NonCommercial',
        },
        {
          label: '-',
          value: null,
        },
      ],
    };
  }
}
