<app-user-edit #globalUserEditDialog></app-user-edit>

<div [class]="isImpersonating ? 'topbar clearfix impersonating' : 'topbar clearfix'">
  <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)" *ngIf="showMenu">
    <i class="fa fa-bars"></i>
  </a>

  <img class="logo" alt="{{ appConfig.apptitle }}" src="/assets/images/truxio.rgb.reverse.png" />

  <a *ngIf="isloggedIn" href="#" class="profile" style="padding-top: 9px" (click)="app.onTopbarMenuButtonClick($event)">
    <span class="username">{{ nickName }}</span>
    <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" *ngIf="false" />
    <i class="fa fa-angle-down"></i>
  </a>

  <a
    *ngIf="isloggedIn"
    href="#"
    class="profile-tenant"
    style="padding-top: 9px"
    (click)="app.onTenantMenuButtonClick($event)"
  >
    <span class="username" style="margin-right: 10px">{{ tenantName }}</span>
    <i class="fa fa-angle-down" id="tenant-dropdown"></i>
  </a>
  <span class="topbar-search" *ngIf="false">
    <input type="text" pInputText placeholder="Search" />
    <span class="fa fa-search"></span>
  </span>

  <span class="topbar-themeswitcher" *ngIf="false">
    <p-inputSwitch [(ngModel)]="app.darkTheme" (onChange)="themeChange($event)"></p-inputSwitch>
  </span>

  <ul class="topbar-menu topbar-menu-tenant fadeInDown" [ngClass]="{ 'topbar-menu-visible': app.topbarMenuTenant }">
    <li *ngIf="tenants?.length > 1" class="showTenants">
      <a (click)="showTenants($event)" class="fauxlink showTenants">
        <i class="fa fa-users showTenants"></i>
        <span class="showTenants">Switch Tenants</span>
        <i class="fa fa-angle-down pl-2 showTenants"></i>
      </a>
      <ul class="topbar-menu fadeInDown topbar-submenu" [ngClass]="{ 'topbar-menu-visible': visibleTenants }">
        <li role="menuitem" class="fauxlink tenant-{{ item.id }}" *ngFor="let item of tenants">
          <a (click)="switchToTenant(item)">{{ item.name }} </a>
        </li>
      </ul>
    </li>
    <li>
      <a class="fauxlink" [routerLink]="appConfig.routes.userRegistration" id="new-tenant-button">
        <i class="fa fa-plus"></i>
        <span>New Tenant</span>
      </a>
    </li>
  </ul>

  <ul class="topbar-menu fadeInDown" [ngClass]="{ 'topbar-menu-visible': app.topbarMenuActive }">
    <li role="menuitem" *ngIf="!isImpersonating">
      <a (click)="globalUserEditDialog.initSelf()" class="fauxlink">
        <i class="fa fa-fw fa-user"></i>
        <span>Profile</span>
      </a>
    </li>
    <li role="menuitem" class="fauxlink">
      <a (click)="logOut()" class="fauxlink">
        <i class="fas fa-sign-out-alt"></i>
        <span>Logout</span>
      </a>
    </li>
    <!--li role="menuitem"
			class="fauxlink">
			<ul class="fadeInDown">
				<li role="menuitem">
				</li>
			</ul>

		</li-->
  </ul>
</div>
