<div class="layout-content-container table-container">
  <div class="grid">
    <div *ngIf="!isEditMode">
      <div class="flex col-12 justify-content-between">
        <div>
          <span
            class="filter-menu pl-1"
            *ngFor="let status of statusFilters"
            [class.active]="status.active"
            (click)="filterNotificationsByStatus(status)"
          >
            {{ status.label }}
          </span>
        </div>
        <p-button
          (onClick)="addNotification()"
          icon="fa fa-fw fa-plus"
          label="New Notification"
          class="ui-button-secondary load-list-button"
        ></p-button>
      </div>
      <div class="notifications-table-container col-12">
        <p-table
          [columns]="cols"
          [value]="notifications"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="64.25vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
          responsive="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [ngSwitch]="col.format"
                [pSortableColumn]="col.sortable ? col.field : null"
                pResizableColumn
              >
                <ng-container *ngSwitchDefault>
                  {{ col.header }}
                  <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              #pfsNotificationsTable
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div *ngIf="isEditMode" class="col-12">
      <app-edit-notifications
        [notificationId]="selectedNotificationId"
        [notificationTypes]="notificationTypes"
        [editModeType]="editModeType"
        (cancel)="cancelEdit()"
        (create)="createNotification($event)"
        (update)="updateNotification($event)"
      >
      </app-edit-notifications>
    </div>
  </div>
</div>
<div *ngIf="showActionDialog">
  <app-notification-action-dialog
    [showDialog]="showActionDialog"
    [notificationId]="selectedNotificationId"
    [actionType]="actionType"
    (cancel)="closeDialog()"
    (delete)="deleteNotification($event)"
  >
  </app-notification-action-dialog>
</div>
