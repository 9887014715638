import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadListComponent } from './list/list.component';
import { LoadPaymentsComponent } from './payments/payments.component';
import { DriverPayComponent } from './driverpay/driverpay.component';
import { PermissionAuthorizationGuard } from '../../shared/guards/authorization/permission-authorization.guard';
import { Permission } from '../../data/static-data';
import { AppConfig } from 'src/app/config/app.config';
import { AuthorizationDataProperties } from 'src/app/shared/guards/authorization/authorization-data-properties';

const routes: Routes = [
  { path: AppConfig.routes.load, component: LoadListComponent },
  {
    path: AppConfig.routes.backOffice.invoicing,
    component: LoadPaymentsComponent,
    canActivate: [PermissionAuthorizationGuard],
    data: {
      [AuthorizationDataProperties.Permission]: Permission.LoadAccounting,
    },
  },
  { path: AppConfig.routes.backOffice.driverPay, component: DriverPayComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LoadRoutingModule {}
