import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { BulkActionItem } from './bulk-action-item';

@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss'],
})
export class BulkActionComponent extends DtoFormBase<null> {
  @Input() actionOptions: BulkActionItem[];
  @Input() disableApply: boolean;

  private _selectedItems: any[];
  @Input() set selectedItems(value: any[]) {
    this._selectedItems = value;
    this.ensureSelectedActionIsStillValid();
  }
  get selectedItems(): any[] {
    return this._selectedItems;
  }

  @Output() actionEvent = new EventEmitter<string>();

  public selectedAction: string;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public async applyAction(): Promise<void> {
    if (this.isApplyActionValid()) {
      try {
        this.disableApply = true;
        const actionItem = this.getActionByValue(this.selectedAction);
        await actionItem.action(this.selectedItems);
        this.selectedAction = null;
      } catch (e) {
        this.handleError(e);
      } finally {
        this.disableApply = false;
        this.actionEvent.emit(this.selectedAction);
      }
    }
  }

  public shouldActionItemBeDisabled(action: BulkActionItem): boolean {
    action.disabled = this.doesActionExceedAllowedSelectedItems(action) || action.disableAction;
    return action.disabled;
  }

  public getDisabledToolTip(action: BulkActionItem): string {
    if (this.doesActionExceedAllowedSelectedItems(action)) {
      return `Please select no more than ${action.maximumItemsAllowed} item(s) to use this action.`;
    }

    return 'Action unavailable.';
  }

  private doesActionExceedAllowedSelectedItems(action: BulkActionItem): boolean {
    return action.maximumItemsAllowed && this.selectedItems && action.maximumItemsAllowed < this.selectedItems.length;
  }

  private ensureSelectedActionIsStillValid(): void {
    if (this.selectedAction) {
      const actionItem = this.getActionByValue(this.selectedAction);

      if (this.shouldActionItemBeDisabled(actionItem)) {
        this.selectedAction = null;
      }
    }
  }

  private getActionByValue(actionLabel: string): BulkActionItem {
    return this.actionOptions.find((item) => item.value === actionLabel);
  }

  private isApplyActionValid(): boolean {
    if (!this.selectedAction) {
      this._NS.warn('No Action Selected', 'Please select an action.');
      return false;
    }
    if (!this.selectedItems || this.selectedItems.length === 0) {
      this._NS.warn('Nothing Selected', 'Please select at least one item.');
      return false;
    }

    return true;
  }
}
