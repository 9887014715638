import { IdNamePairDTO, TokenDTO, TokenResponseDTO } from 'src/apiclient/v1.1/models';
import { ClientTokenService } from './client-token-service';
import { TmsJwtPayload } from 'src/app/shared/models/TmsJwtPayload';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * Provides Cognito-specific initialization and translation token methods.
 */
@Injectable()
export class CognitoClientTokenService extends ClientTokenService {
  private tokenResponse: TokenResponseDTO;

  /*
      Gets the token from local storage.
      Returns null if no token or token is expired.
  */
  public getToken(): TokenResponseDTO {
    if (!this.tokenResponse) {
      const data = this.storage.get('token');
      if (data) {
        if (data.jwtToken) {
          console.log('Encountered a legacy token. Logging out to get a new token.');
          this.revokeToken();
        } else {
          this.tokenResponse = data;
          this.decodeToken(this.tokenResponse);
          this.loggedIn.next(true);

          this.startTimer(this.claims.exp);
        }
      }
    }

    this.isTokenExpired();
    return this.tokenResponse;
  }

  public setToken(tokenResponse: TokenResponseDTO): void {
    this.storage.set('token', tokenResponse);
    this.tokenResponse = tokenResponse;
    this.decodeToken(tokenResponse);

    this.startTimer(this.claims.exp);

    if (this.checkAllowedRoles()) {
      this.loggedIn.next(true);
    } else {
      const message = 'Access not allowed for roles: ' + this.roles().join(', ');
      this.revokeToken();
      throw new Error(message);
    }
  }

  public revokeToken(): void {
    this.storage.remove('token');
    this.storage.remove('tenants');
    this.token = null;
    this.tokenResponse = null;
    this.claims = null;
    this.loggedIn.next(false);
    this.tenantFactoringEnabled.next(false);

    if (!this.isPathValidForUnAuthorizedUsers(this.location.path())) {
      this.router.navigate(['/login']);
    }
  }

  public auth(): string {
    if (!this.isTokenExpired()) {
      return 'Bearer ' + this.tokenResponse.accessToken;
    }
  }

  public logOut(): void {
    const revokeTokenRequest = { refreshToken: this.getRefreshToken() };
    this.authenticationService
      .RevokeToken(revokeTokenRequest)
      .pipe(
        catchError((err) => {
          // Revoke local token regardless of error
          this.revokeToken();
          this.timerSubscription.unsubscribe();
          this.timeoutExpired.unsubscribe();
          return new Observable();
        })
      )
      .subscribe((resp) => {
        this.revokeToken();
      });
  }

  public getTenants(): TokenDTO[] | IdNamePairDTO[] {
    return this.tokenResponse.tenants;
  }

  public getRefreshToken(): string {
    return this.tokenResponse?.refreshToken;
  }

  protected decodeToken(tokenResponse: TokenResponseDTO): void {
    const decoded = this.jwtDecodeService.decode<TmsJwtPayload>(tokenResponse.accessToken);

    // Created a simpler claim object to reduce parsing later
    this.claims = {
      tenantId: decoded.tenantId,
      tenantName: tokenResponse.tenant.name,
      userId: decoded.userId,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      fullName: `${decoded.given_name} ${decoded.family_name}`,
      impersonating: decoded.impersonatedUserId && decoded.impersonatedUserId > 0,
      lbtIframeUrl: tokenResponse.lbtIframeUrl,
      hasFactorCloudUser: tokenResponse.hasFactoringUser,
      permissions: decoded.permissions,
      roles: decoded.roles,
      exp: decoded.exp,
      featureFlags: tokenResponse.featureFlag,
    };

    // set factoring flag based on token's tenant
    this.tenantFactoringEnabled.next(this.hasPermission('FactoringEnabled'));
  }
}
