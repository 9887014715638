<p-panel
  *ngFor="let driver of drivers"
  [toggleable]="true"
  [collapsed]="true"
  expandIcon="fa fa-chevron-down"
  collapseIcon="fa fa-chevron-up"
  [style]="{ width: 'calc(100% - 18px)', margin: '0px 0px 5px' }"
  styleClass="transparent-panel-titlebar"
>
  <p-header>
    <div class="ui-g-1 pad4">
      <i class="fas fa-truck {{ getDriverIconColor(driver) }} "></i>
    </div>
    <div class="ui-g-2 pad4">
      <b>{{ driver.truckNumber }}</b>
    </div>
    <div class="ui-g-6 pad4 no-bold">
      {{ driver.name }}
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-1 pad4left">
        <i class="fas fa-mobile-alt silver"></i>
      </div>
      <div class="ui-g-11 pad4left pfsblue">
        {{ driver.phone | formatPhone }}
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1 pad4left">
        <i class="fa fa-map-marker-alt silver"></i>
      </div>
      <div class="ui-g-11 pad4left">
        {{ driver.location }}
        <span *ngIf="!driver.location"><i class="red">TBD</i></span>
      </div>
      <div *ngIf="driver.lastTrackingUpdatedUtc" class="ui-g-1 pad4left"> &nbsp; </div>
      <div *ngIf="driver.lastTrackingUpdatedUtc" class="ui-g-11 pad4left gray">
        {{ driver.lastTrackingUpdatedUtc ? getFormatedDateLong(driver.lastTrackingUpdatedUtc) : '' }}
      </div>
    </div>

    <div class="ui-g-12" *ngFor="let load of driver.loads">
      <div class="ui-g-1 pad4left">
        <i class="fas fa-clock {{ getLoadStatusClass(load.lastTrackingStatus) }}"></i>
      </div>
      <div class="ui-g-11 pad4left {{ getLoadColor(load) }}">
        <b class="fauxlink" id="fauxlink{{ load.loadId }}" (click)="loadNumClicked(load.loadId)">
          {{ load.loadNumber }}
        </b>
      </div>
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <div class="ui-g-11 pad4left">
        <div class="ui-g-4 ui-g-nopad">
          {{ load.shipDate || 'TBD' }}
        </div>
        <div class="ui-g-8 ui-g-nopad">
          {{ load.origin }}
        </div>
      </div>
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <div class="ui-g-11 pad4left">
        <div class="ui-g-4 ui-g-nopad">
          {{ load.deliveryDate || 'TBD' }}
        </div>
        <div class="ui-g-8 ui-g-nopad">
          {{ load.destination }}
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g-12 mapDriverCard">
    <div id="mapDriverCard{{ driver.userId }}">
      <div class="ui-g-12" style="overflow: hidden">
        <div class="ui-g-12">
          <div class="ui-g-2 pad4">
            <b>{{ driver.truckNumber }}</b>
          </div>
          <div class="ui-g-8 pad4">
            {{ driver.name }}
          </div>
          <div class="ui-g-1 pad4">
            <i
              *ngIf="!showDriverAlert(driver)"
              class="fa fa-exclamation-triangle warning tooltip"
              tittle="You Shall Not Pass!!"
            >
              <span class="tooltiptext">{{ showDriverAlertMessage(driver) }}</span>
            </i>
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-1 pad4left">
            <i class="fas fa-mobile-alt silver"></i>
          </div>
          <div class="ui-g-11 pad4left pfsblue">
            {{ driver.phone | formatPhone }}
          </div>
        </div>

        <div class="ui-g-12" *ngFor="let load of driver.loads">
          <div class="ui-g-1 pad4left">
            <i class="fas fa-clock {{ getLoadStatusClass(load.lastTrackingStatus) }}"></i>
          </div>
          <div class="ui-g-11 pad4left pfsblue {{ getLoadColor(load) }}" id="fauxlinkBody{{ load.loadId }}">
            <b>{{ load.loadNumber }}</b>
          </div>
          <div class="ui-g-1 pad4left"> &nbsp; </div>
          <div class="ui-g-11 pad4left">
            <div class="ui-g-4 ui-g-nopad">
              {{ load.shipDate || 'TBD' }}
            </div>
            <div class="ui-g-8 ui-g-nopad">
              {{ load.origin }}
            </div>
          </div>
          <div class="ui-g-1 pad4left"> &nbsp; </div>
          <div class="ui-g-11 pad4left">
            <div class="ui-g-4 ui-g-nopad">
              {{ load.deliveryDate || 'TBD' }}
            </div>
            <div class="ui-g-8 ui-g-nopad">
              {{ load.destination }}
            </div>
          </div>
          <div class="ui-g-1 pad4left"> &nbsp; </div>
          <div class="ui-g-11 pad4left pfsblue" id="updateTrackingBody{{ load.loadId }}"> </div>

          <div>
            <app-leg-tracking
              class="ui-g-11 pad4left"
              [driverName]="driver.name"
              [loadId]="load.loadId"
              [loadNumber]="load.loadNumber"
              [loadLegId]="load.loadLegId"
              [viewMode]="trackingViewMode"
              (journalChanged)="updateTracking(load.loadId)"
            ></app-leg-tracking>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-panel>
