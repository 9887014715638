import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, ControlValueAccessor } from '@angular/forms';
import { DocumentOnFileDTO, TenantUserAttachmentDTO } from '../../../../../apiclient/models';
import { AttachmentTypeName } from '../../../../data/static-data';
@Component({
  selector: 'app-records-checklist',
  templateUrl: './records-checklist.component.html',
  styleUrls: ['./records-checklist.component.css'],
})
export class RecordsChecklistComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() viewMode?: string;
  @Input() formGroup: FormGroup;
  @Input() documentsOnFile: Array<DocumentOnFileDTO> = new Array<DocumentOnFileDTO>();
  @Input() attachments: Array<TenantUserAttachmentDTO> = new Array<TenantUserAttachmentDTO>();
  documents: any[];

  fields = [
    {
      label: 'Copy of License',
      type: AttachmentTypeName.License,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Pre-Employment Drug Test',
      type: AttachmentTypeName.PreEmploymentDrugTest,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Long Medical Form',
      type: AttachmentTypeName.LongMedicalForm,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Copy of Road Test',
      type: AttachmentTypeName.RoadTest,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Enrolled in Drug Testing Program',
      type: AttachmentTypeName.DrugTestingEnrollment,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Checklist for Casuals',
      type: AttachmentTypeName.ChecklistForCasuals,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Certificate of Compliance',
      type: AttachmentTypeName.CertificateOfCompliance,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Inquiry to Previous Employers',
      type: AttachmentTypeName.InquiryToPreviousEmployers,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Non Motor Carrier Activity Form',
      type: AttachmentTypeName.NonMotorCarrierActivityForm,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Inquiry to State Agencies (State MVR)',
      type: AttachmentTypeName.InquiryToStateAgencies,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Proof of Receiving FMCSR Handbook',
      type: AttachmentTypeName.ProofOfReceivingFMCSRHandbook,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Annual Certification of Violations',
      type: AttachmentTypeName.AnnualCertificationOfViolations,
      showExpiresOn: true,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Employment Application',
      type: AttachmentTypeName.EmploymentApplication,
      showExpiresOn: false,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
    {
      label: 'Annual MVR Review',
      type: AttachmentTypeName.AnnualMVRReview,
      showExpiresOn: true,
      uploadButton: false,
      downloadButton: false,
      checked: false,
    },
  ];

  constructor() {}

  ngOnInit() {
    this.initFields();
  }

  initFields() {
    this.documents = [];
    this.fields.forEach((field) => {
      const newDoc = { type: field.type, checked: false };
      this.documents.push(newDoc);
    });
  }

  writeValue(documents) {
    this.documentsOnFile = documents;
  }

  registerOnChange() {}

  registerOnTouched() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documentsOnFile) {
      this.initFields();
      this.attachmentsMarkAsChecked();
    }
  }
  attachmentsMarkAsChecked() {
    if (!this.documentsOnFile) {
      return;
    }
    if (this.documentsOnFile.length > 0) {
      this.documentsOnFile.forEach((doc) => {
        const found = this.documents.find((x) => x.type === doc.type);
        if (found) {
          found.checked = true;
          found.expiresOn = doc.expiresOn;
        }
      });
    }
  }

  handleSelectionChange(event) {
    const document = this.documentsOnFile.find((x) => x.type === event.field.type);
    const index = this.documentsOnFile.indexOf(document);
    if (event.checked) {
      const newDoc = event.document as DocumentOnFileDTO;
      if (event.document.expiresOn) {
        newDoc.expiresOn = event.document.expiresOn;
      }
      this.documentsOnFile.push(newDoc);
    } else {
      if (index !== -1) {
        this.documentsOnFile.splice(index, 1);
      }
    }
  }

  handleExpiresChange(event) {
    const document = this.documentsOnFile.find((x) => x.type === event.field.type);

    if (!document) {
      return;
    }

    document.expiresOn = event.field.expiresOn;
  }
}
