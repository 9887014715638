import {
  Component,
  OnInit,
  forwardRef,
  EventEmitter,
  Output,
  Injector,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdNamePairDTO } from '../../../apiclient/models';
import { UserService } from '../../../apiclient/services';
import { HandleErrorBase } from '../../shared/HandleErrorBase';

/**
 * Reusable component for selecting a user.
 * Set the role filter to limit users to a role. Use * to show all.
 * NOTE: Component is set to OnPush - meaning it will be checked once.
 *  This is a preformance tune b/c user is usually not updated outside of this component. Will need to retest if that changes.
 */
@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectorComponent extends HandleErrorBase implements OnInit, ControlValueAccessor {
  /**
   * Accepts wildcard (*) to get all users
   */
  @Input()
  roleFilter: string;

  @Input()
  readonly?: boolean = false;

  @Input()
  showClear: boolean = false;

  @Input()
  label: string = null;

  /**
   * This property is bound to the ngModel
   */
  selected: IdNamePairDTO = <IdNamePairDTO>{};

  options: IdNamePairDTO[] = null;

  /**
   * When userId changes
   */
  @Output()
  changed: EventEmitter<IdNamePairDTO> = new EventEmitter();

  /**
   * Options:
   *   - display: Only shows content of field
   *   - readonly: Shows input(control) on read only mode
   *   - blank/not defined: Regular Input/Control mode
   * Default: blank
   */
  @Input() viewMode?: string;

  constructor(private users: UserService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {}

  async writeValue(val: IdNamePairDTO): Promise<void> {
    // Init values
    this.options = [val];
    this.selected = val;

    // Get options and select based on input parameter
    this.getOptions().then((data) => {
      if (this.selected && this.selected.id && this.selected.id > 0) {
        this.selected = data.filter((x) => x.id === this.selected.id)[0];
      }
      this.options = data;
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }

  private propagateChange = (_: any) => {};

  public onChange() {
    this.propagateChange(this.selected);
    this.changed.emit(this.selected);
  }

  private async getOptions(): Promise<IdNamePairDTO[]> {
    try {
      const roles = this.roleFilter === '*' ? [] : [this.roleFilter];
      return await this.users
        .ApiUserNamesGet({
          IncludeRoles: roles,
          IncludeDisabled: false,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    } catch (error) {
      this.handleBasicError(error);
    }
  }

  public onClear() {
    this.selected = {} as IdNamePairDTO;
    this.onChange();
  }
}
