<div class="container">
  <div class="progress-container">
    <div class="progress-element">
      <app-funding-status-icon class="funding-status-icon" [color]="getStageOneIcon()"></app-funding-status-icon>
      <i class="fas fa-caret-up" *ngIf="getCurrentStage() === 1"></i>
    </div>
    <img class="funding-spacer-icon" [src]="spacer" />
    <div class="progress-element">
      <app-funding-status-icon class="funding-status-icon" [color]="getStageTwoIcon()"></app-funding-status-icon>
      <i class="fas fa-caret-up" *ngIf="getCurrentStage() === 2"></i>
    </div>
    <img class="funding-spacer-icon" [src]="spacer" />
    <div class="progress-element">
      <app-funding-status-icon class="funding-status-icon" [color]="getStageThreeIcon()"></app-funding-status-icon>
      <i class="fas fa-caret-up" *ngIf="getCurrentStage() === 3"></i>
    </div>
  </div>
  <div class="status-container">
    <img class="check-icon" *ngIf="showStatusCheck()" [src]="check" />
    <p class="status-message">{{ getStatusText() }}</p>
  </div>
  <div class="account-executive-container" *ngIf="status === fundingStatus.OnHold">
    <div *ngIf="accountExecutive$ | async as accountExecutive">
      <p class="item">{{ accountExecutive.firstName }} {{ accountExecutive.lastName }}</p>
      <a class="item" [href]="'mailto:' + accountExecutive.email">{{ accountExecutive.email }}</a>
      <p class="item">{{ accountExecutive.phoneNumber }}</p>
    </div>
    <a class="account-executive-link" [routerLink]="['/factoring/buy-no-buy']"> View All Account Executives </a>
  </div>
</div>
