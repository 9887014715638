import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HandleErrorBase } from 'src/app/shared/HandleErrorBase';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService extends HandleErrorBase {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      case 400:
        this.logging.logBadRequest(error);
        this.handleBadRequest(error.error);
        break;
      default:
        this.handleBasicError(error);
        break;
    }
    return throwError(error);
  }

  private handleBadRequest(error: any) {
    const problems = typeof error === 'string' ? JSON.parse(error) : error;
    if (problems) {
      Object.keys(problems).forEach((key) => {
        this._NS.error('Validation Error', `${key}: ${problems[key]}`);
      });
    }
  }
}
