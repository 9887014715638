import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AppConfig } from 'src/app/config/app.config';
import { LogisticsConfig } from 'src/app/config/logistics.config';

@Injectable()
export class BreadcrumbService {
  public itemsHandler: Observable<MenuItem[]>;
  private itemsSource = new Subject<MenuItem[]>();
  private itemBreadcrumbs: MenuItem[];
  private readonly systemAdminLabel = 'System Administration';

  constructor() {
    this.itemsHandler = this.itemsSource.asObservable();
  }

  public setItems(page: string, currentLabel: string = null, prevParam: string = null, prevLabel: string = null): void {
    const refList: MenuItem[] = this.getBreadcrumbsLink(page, currentLabel, prevParam, prevLabel);
    this.itemsSource.next(refList);
  }

  private getBreadcrumbsLink(page: string, currentLabel: string, prevParam: string, prevLabel: string): MenuItem[] {
    this.itemBreadcrumbs = [];
    switch (page) {
      case 'admin':
        this.itemBreadcrumbs.push({ label: this.systemAdminLabel });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'admin-tenant':
        this.itemBreadcrumbs = this.getSystemAdminTenantBreadcrumbs(currentLabel, prevLabel);
        break;
      case 'dashboard':
        this.itemBreadcrumbs.push({ label: '' });
        break;
      case 'loads':
        this.itemBreadcrumbs.push({ label: 'Loads' });
        break;
      case 'customers':
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'customers-new':
        this.itemBreadcrumbs.push({ label: prevLabel, routerLink: ['/company'], queryParams: { type: prevParam } });
        this.itemBreadcrumbs.push({ label: 'New Customer' });
        break;
      case 'customers-edit':
        this.itemBreadcrumbs.push({ label: prevLabel, routerLink: ['/company'], queryParams: { type: prevParam } });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'settings':
        this.itemBreadcrumbs.push({ label: 'Settings' });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'invoicing':
        this.itemBreadcrumbs.push({ label: 'Invoicing' });
        break;
      case 'driverpay':
        this.itemBreadcrumbs.push({ label: 'Driver Pay' });
        break;
      case 'driverprofile':
        this.itemBreadcrumbs.push({ label: 'Drivers' });
        break;
      case 'workplace':
        this.itemBreadcrumbs.push({ label: 'Workplace' });
        break;
      case 'privacy-policy':
        this.itemBreadcrumbs.push({ label: 'Terms and Privacy' });
        break;
      case 'marketplace':
        this.itemBreadcrumbs.push({ label: 'Marketplace' });
        break;
      case 'equipment':
        this.itemBreadcrumbs.push({ label: 'Equipment' });
        break;
      case 'factoring':
        this.itemBreadcrumbs.push({ label: 'Factoring' });
        break;
      case 'buy-no-buy':
        this.itemBreadcrumbs.push({ label: 'Buy/No Buy' });
        break;
      case 'new-tenant':
        this.itemBreadcrumbs.push({ label: 'New Tenant' });
        break;
      case 'document-viewer':
        this.itemBreadcrumbs.push({ label: 'Document Viewer' });
        break;
      case 'users':
        this.itemBreadcrumbs.push({ label: 'Users' });
        break;
      case 'carriers':
        this.itemBreadcrumbs.push({ label: 'Carriers' });
        break;
      case 'carriers-view':
        this.itemBreadcrumbs.push({
          label: prevLabel,
          routerLink: [AppConfig.routes.logistics, LogisticsConfig.routes.carriers.carriers],
          queryParams: {},
        });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'carrier':
        this.itemBreadcrumbs.push({
          label: prevLabel,
          routerLink: [AppConfig.routes.logistics, LogisticsConfig.routes.carriers.carriers],
          queryParams: {},
        });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      case 'new-logistics-load':
        this.itemBreadcrumbs.push({
          label: prevLabel,
          routerLink: [AppConfig.routes.logistics, LogisticsConfig.routes.loads.loads],
          queryParams: {},
        });
        this.itemBreadcrumbs.push({ label: currentLabel });
        break;
      default:
        this.itemBreadcrumbs = [];
    }
    return this.itemBreadcrumbs;
  }

  private getSystemAdminTenantBreadcrumbs(currentLabel: string, previousLabel: string): MenuItem[] {
    const breadcrumbs = [{ label: this.systemAdminLabel }, { label: 'Tenants' }];
    if (previousLabel) {
      breadcrumbs.push({ label: previousLabel });
      breadcrumbs.push({ label: currentLabel });
    }

    return breadcrumbs;
  }
}
