import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { UserService } from '../../../../apiclient/v1.1/services';
import { VerifyEmailDTO } from '../../../../apiclient/v1.1/models/verify-email-dto';
import { DtoFormBase } from '../../../shared/FormBase';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.css'],
})
export class VerifyemailComponent extends DtoFormBase<VerifyEmailDTO> implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('VerifyEmailDTO');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public verifyEmail(): void {
    if (!this.formValid()) {
      return;
    }

    this.showProgressOverlay = true;
    this.userService
      .ConfirmVerifyEmail({ body: this.model, Authorization: this.clientToken.auth() })
      .pipe(
        tap(() => {
          this._NS.success('Success!', 'Your email has been verified, thank you!');
          this.navigationService.navigateToDefaultDashboard();
        }),
        catchError(this.handleError),
        finalize(() => (this.showProgressOverlay = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public resendCode(): void {
    this.showProgressOverlay = true;
    this.userService
      .VerifyEmail(this.clientToken.auth())
      .pipe(
        tap(() => this._NS.success('Success!', 'A new confirmation code has been sent to your email.')),
        catchError(this.handleError),
        finalize(() => (this.showProgressOverlay = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
