import { Component, Input, ViewChild, OnInit, OnChanges, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AddressDTO } from '../../../apiclient/models';
import { AutoComplete } from 'primeng/autocomplete';
import { ApiValidators } from '../../../utils/api-validators';
import { DtoFormBase } from '../../shared/FormBase';
import { TitleCasePipe } from '@angular/common';
import { NotificationsService } from '../notifications/notifications.service';
import { StatesService, State } from '../../../services/states-service';

@Component({
  selector: 'app-address-v2',
  templateUrl: './address-v2.component.html',
  styleUrls: ['./address-v2.component.css'],
  providers: [TitleCasePipe],
})
export class AddressV2Component extends DtoFormBase<AddressDTO> implements OnInit, OnChanges {
  @ViewChild(AutoComplete, { static: true }) autoCompleteComponent: AutoComplete;

  addressTitle: string;
  addressValidators: any;
  initComplete: boolean = false;

  addrFormErrorsTemplate = {
    line1: '',
    city: '',
    state: '',
    zip: '',
  };

  constructor(_NS: NotificationsService, private statesService: StatesService, protected injector: Injector) {
    super(injector);
  }

  @Input() formErrors: any;
  filteredStatesSingle: State[] = [];
  @Input() formGroup: FormGroup;
  @Input() displayMode: string;
  /**
   * Only display content or form controls
   * values:
   *    'display': Show the content in html
   */
  @Input() viewMode: string;
  @Input() requiredLabel: string;
  @Input() noTittle: boolean = false;
  @Input() title: string = 'Address';
  @Input() prefix: string = 'main';
  @Input() Address: AddressDTO;
  @Input() validationMessages: any;
  @Input() collapsed: boolean = false; // if this is changed to true, validators will be turned off for this address
  @Input() formSubmitted: boolean = false;
  @Input() config: any = {
    boldLabel: false,
  };

  @Output() addressUpdated: EventEmitter<AddressDTO> = new EventEmitter<AddressDTO>();

  ngOnInit() {
    this.addressValidators = this.validatorsService.getByName('AddressDTO');

    this.initFormFor('AddressDTO');

    this.addValidationMessages();

    this.setZipValidator('US');
    this.setValidators();
    this.initComplete = true;
  }

  private addrChanged() {
    this.propagateChange(this.Address);
    this.addressUpdated.emit(this.Address);
  }
  private propagateChange = (_: any) => {};

  ngOnChanges(changes) {
    if (this.initComplete) {
      if (changes['collapsed']) {
        this.setValidators();
        this.addrChanged();
      }
      if (changes['formSubmitted']) {
        this.showValidations();
      }
    }
  }

  onStateChange(event: any) {
    if (event) {
      this.Address.state = event.abbr;
      this.setZipValidator(event.country);
    } else {
      this.Address.state = null;
    }
    this.addrChanged();
  }

  setValidators() {
    if (this.collapsed) {
      this.formGroup.controls[this.prefix + '-city'].clearValidators();
      this.formGroup.controls[this.prefix + '-line1'].clearValidators();
      this.formGroup.controls[this.prefix + '-line2'].clearValidators();
      this.formGroup.controls[this.prefix + '-state'].clearValidators();
    } else {
      this.formGroup.controls[this.prefix + '-city'].setValidators(
        ApiValidators.getValidatorsForFormField('city', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-line1'].setValidators(
        ApiValidators.getValidatorsForFormField('line1', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-line2'].setValidators(
        ApiValidators.getValidatorsForFormField('line2', this.addressValidators)
      );
      this.formGroup.controls[this.prefix + '-state'].setValidators(
        ApiValidators.getValidatorsForFormField('state', this.addressValidators)
      );
    }

    this.formGroup.controls[this.prefix + '-city'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-line1'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-line2'].updateValueAndValidity();
    this.formGroup.controls[this.prefix + '-state'].updateValueAndValidity();
    this.initFormErrors();
  }

  setZipValidator(country: String) {
    this.formGroup.controls[this.prefix + '-zip'].clearValidators();
    let zipPattern;
    let zipPatternMessage;
    if (country === 'CA') {
      zipPatternMessage = 'Zip must be in the formats: T2X 1V4 or T2X1V4';
      zipPattern = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
    } else {
      zipPatternMessage = 'Zip must be in the formats: 12345, 12345-1234, or 12345 1234';
      zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    }
    const theValidator = Validators.pattern(zipPattern);

    this.validationMessages[this.prefix + '-zip'] = {
      required: 'Zip is required.',
      pattern: zipPatternMessage,
    };

    this.formGroup.controls[this.prefix + '-zip'].setValidators([theValidator, Validators.required]);
    this.formGroup.controls[this.prefix + '-zip'].updateValueAndValidity();
  }

  addValidationMessages() {
    const addrValidationMessages = ApiValidators.getValidationMessagesForFormGroup(this.addressValidators);
    for (const messageKey of Object.keys(addrValidationMessages)) {
      if (messageKey !== 'zip') {
        this.validationMessages[this.prefix + '-' + messageKey] = addrValidationMessages[messageKey];
      }
    }
  }
  initFormErrors() {
    for (const errorKey of Object.keys(this.addrFormErrorsTemplate)) {
      this.formErrors[this.prefix + '-' + errorKey] = '';
    }
  }

  showValidations() {
    if (!this.Address.state) {
      this.formErrors[this.prefix + '-state'] = this.validationMessages[this.prefix + '-state'].required;
    }
    // tslint:disable-next-line:forin
    for (const name in this.formGroup.controls) {
      this.formGroup.controls[name].markAsTouched();
    }
  }
}
