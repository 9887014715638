import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AddressDTO, DebtorAddressMatchingDTO } from '../../../../apiclient/v1.1/models';
import { CompanyAddressService } from '../../../../apiclient/v1.1/services';
import { ClientTokenService } from '../../../../services/client-token-service';

@Component({
  selector: 'app-company-address-matching',
  templateUrl: './company-address-matching.component.html',
  styleUrls: ['./company-address-matching.component.scss'],
})
export class CompanyAddressMatchingComponent implements OnInit, OnDestroy {
  @Input() address: AddressDTO;
  @Output() noMatchesFound = new EventEmitter();
  @Output() actionTaken = new EventEmitter<boolean>();
  @Output() matchSelected = new EventEmitter<DebtorAddressMatchingDTO>();

  public matchingResults: DebtorAddressMatchingDTO[];

  private destroy$ = new Subject<void>();

  constructor(private clientTokenService: ClientTokenService, private companyAddressService: CompanyAddressService) {}

  public ngOnInit(): void {
    this.checkAddressForMatches();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public actionTakenEvent(action: boolean): void {
    this.actionTaken.emit(action);
  }

  public matchSelectedEvent(match: DebtorAddressMatchingDTO): void {
    this.matchSelected.emit(match);
  }

  private checkAddressForMatches(): void {
    if (!this.address) {
      this.noMatchesFound.emit();
    } else {
      this.companyAddressService
        .CheckForMatchingRecords({
          Authorization: this.clientTokenService.auth(),
          Line1: this.address.line1,
          Line2: this.address.line2,
          City: this.address.city,
          State: this.address.state,
          Zip: this.address.zip,
        })
        .pipe(
          tap((result) => this.handleAddressMatchingResult(result)),
          catchError((error) => this.handleError(error)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  private handleAddressMatchingResult(result: DebtorAddressMatchingDTO[]): void {
    if (result && result.length) {
      this.matchingResults = result;
    } else {
      this.noMatchesFound.emit();
    }
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.noMatchesFound.emit();
    return of(error);
  }
}
