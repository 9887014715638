import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TenantDTO } from 'src/apiclient/v1.1/models';
import { ContactAddressDTO } from 'src/apiclient/v1.1/models/contact-address-dto';
import { LogisticsTenantAccountingService } from 'src/apiclient/v1.1/services';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { ClientTokenService } from 'src/services/client-token-service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';

@Component({
  selector: 'app-tenant-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
})
export class TenantAccountingComponent implements OnDestroy {
  @Input() set tenant(tenant: TenantDTO) {
    this._tenant = tenant;
    this.accountsPayable = tenant.logisticsTenant.accountsPayableContactAddress;
    this.accountsReceivable = tenant.logisticsTenant.accountsReceivableContactAddress;
  }
  get tenant(): TenantDTO {
    return this._tenant;
  }

  public accountsPayable: ContactAddressDTO;
  public accountsReceivable: ContactAddressDTO;

  private _tenant: TenantDTO;
  private destroy$ = new Subject<void>();

  constructor(
    private clientTokenService: ClientTokenService,
    private logisticsTenantAccountingService: LogisticsTenantAccountingService,
    private errorHandlingService: ErrorHandlingService,
    private notificationsService: NotificationsService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveAccountsPayable(updatedContactAddress: ContactAddressDTO): void {
    this.saveContactAddress(updatedContactAddress)
      .pipe(
        tap((result) => (this.accountsPayable = result)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public saveAccountsReceivable(updatedContactAddress: ContactAddressDTO): void {
    this.saveContactAddress(updatedContactAddress)
      .pipe(
        tap((result) => (this.accountsReceivable = result)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private saveContactAddress(contactAddress: ContactAddressDTO): Observable<ContactAddressDTO> {
    return this.logisticsTenantAccountingService
      .UpdateAccountingContactAddressById({
        Authorization: this.clientTokenService.auth(),
        body: contactAddress,
      })
      .pipe(
        tap(() => this.notificationsService.success('Info Updated.', 'Your updates were saved successfully.')),
        catchError((error) => this.errorHandlingService.handleError(error))
      );
  }
}
