<p-dialog
  #buyNoBuyDialog
  visible="true"
  [style]="{ width: buyNoBuyDialogWidth, height: buyNoBuyDialogHeight, top: '0px !important', left: '0px' }"
  [contentStyle]="contentDialogStyle"
  [modal]="true"
  blockScroll="true"
  [focusOnShow]="false"
  styleClass="full-size-dialog"
  [closable]="false"
  (onShow)="displayDialog()"
  appendTo="body"
>
  <app-buy-no-buy [dialogView]="true"></app-buy-no-buy>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="closeDialog()"
      label="Close"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
