<p-dialog
  appendTo="body"
  header="Confirm Bill To Company Details"
  [(visible)]="visible"
  [closeOnEscape]="true"
  [closable]="true"
  [style]="{ width: '31.25rem' }"
  (onHide)="onClose()"
  [modal]="true"
  [focusTrap]="visible"
  [focusOnShow]="false"
  id="confirm-bill-to-dialog"
>
  <div *ngIf="masterDebtor">
    <div class="company-info-section grid grid-nogutter col-12 p-1">
      <div class="grid grid-nogutter col-12 p-2">
        <div class="col-4 font-bold">Company Name</div>
        <div class="col-8" id="company-name">{{ masterDebtor.name }}</div>
      </div>
      <div class="grid grid-nogutter col-12 p-2">
        <div class="col-4 font-bold">Physical Address</div>
        <div class="col-8" id="address-1">{{ masterDebtor.address1 }}</div>
        <div *ngIf="masterDebtor.address2" class="grid grid-nogutter p-0">
          <div class="col-4"></div>
          <div class="col-8" id="address-2">{{ masterDebtor.address2 }}</div>
        </div>
        <div class="col-4"></div>
        <div class="col-8" id="address-location"
          >{{ masterDebtor.city }}, {{ masterDebtor.state }} {{ masterDebtor.zipCode }}</div
        >
      </div>
      <div class="col-12"><hr /></div>
    </div>
    <div [formGroup]="formGroup" class="grid grid-nogutter col-12 p-2">
      <app-form-field
        class="col-12"
        id="first-name"
        label="First Name"
        type="text"
        labelClass="col-4 p-1"
        controlClass="col-8"
        errorClass="col-12 p-1"
        [formGroup]="formGroup"
        formControlName="contactFirstName"
        [formErrors]="formErrors"
      ></app-form-field>
      <app-form-field
        class="col-12"
        id="last-name"
        label="Last Name"
        type="text"
        labelClass="col-4 p-1"
        controlClass="col-8"
        errorClass="col-12 p-1"
        [formGroup]="formGroup"
        formControlName="contactLastName"
        [formErrors]="formErrors"
      ></app-form-field>
      <app-form-field
        class="col-12"
        id="phone-number"
        label="Phone Number"
        type="text"
        labelClass="col-4 p-1"
        controlClass="col-8"
        errorClass="col-12 p-1"
        [formGroup]="formGroup"
        formControlName="phoneNumber"
        [formErrors]="formErrors"
      ></app-form-field>
      <app-form-field
        class="col-12"
        id="email"
        label="Email"
        type="text"
        labelClass="col-4 p-1"
        controlClass="col-8"
        errorClass="col-12 p-1"
        [formGroup]="formGroup"
        formControlName="email"
        [formErrors]="formErrors"
      ></app-form-field>

      <div class="grid grid-nogutter col-12 p-2">
        <div class="col-4 p-0">
          <div class="grid col-12 p-0 flex flex-wrap align-items-center">
            <div class="col-4 px-0 text-right">
              <p-checkbox formControlName="isBillTo" binary="true" id="bill-to"></p-checkbox>
            </div>
            <div class="col-8">Bill To</div>
          </div>
        </div>

        <div class="col-4">
          <div class="grid col-12 p-0 flex flex-wrap align-items-center">
            <div class="col-4 px-0 text-right">
              <p-checkbox formControlName="isShipper" binary="true" id="shipper"></p-checkbox>
            </div>
            <div class="col-8">Shipper</div>
          </div>
        </div>

        <div class="col-4">
          <div class="grid col-12 p-0 flex flex-wrap align-items-center">
            <div class="col-4 px-0 text-right">
              <p-checkbox formControlName="isConsignee" binary="true" id="consignee"></p-checkbox>
            </div>
            <div class="col-8">Consignee</div>
          </div>
        </div>

        <div *ngIf="!isBillToRoleValid() && showBillToRoleValidation()" class="formerrors col-12 p-1">
          At least one role must be selected.
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="onClose()"
      label="Cancel"
      class="ui-button-secondary"
      id="close-button"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      (click)="onSave()"
      label="Save"
      class="ui-button-primary"
      id="save-button"
      [disabled]="saveInProgress"
    ></button>
  </p-footer>
</p-dialog>
