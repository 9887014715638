/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDTO } from '../models/user-dto';
import { ServiceResponseDTO } from '../models/service-response-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
@Injectable({
  providedIn: 'root',
})
class AllUserService extends __BaseService {
  static readonly ApiAllUserByTenantIdGetPath = '/api/AllUser/{tenantId}';
  static readonly ApiAllUserByTenantIdPostPath = '/api/AllUser/{tenantId}';
  static readonly ApiAllUserByTenantIdByUserIdGetPath = '/api/AllUser/{tenantId}/{userId}';
  static readonly ApiAllUserByTenantIdByUserIdPutPath = '/api/AllUser/{tenantId}/{userId}';
  static readonly ApiAllUserEnableByTenantIdByUserIdPostPath = '/api/AllUser/Enable/{tenantId}/{userId}';
  static readonly ApiAllUserDisableByTenantIdByUserIdPostPath = '/api/AllUser/Disable/{tenantId}/{userId}';
  static readonly ApiAllUserResetLockoutByUserIdPostPath = '/api/AllUser/ResetLockout/{userId}';
  static readonly ApiAllUserNamesByTenantIdGetPath = '/api/AllUser/Names/{tenantId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of users
   * @param params The `AllUserService.ApiAllUserByTenantIdGetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllUserByTenantIdGetResponse(params: AllUserService.ApiAllUserByTenantIdGetParams): __Observable<__StrictHttpResponse<Array<UserDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDTO>>;
      })
    );
  }
  /**
   * Summary list of users
   * @param params The `AllUserService.ApiAllUserByTenantIdGetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllUserByTenantIdGet(params: AllUserService.ApiAllUserByTenantIdGetParams): __Observable<Array<UserDTO>> {
    return this.ApiAllUserByTenantIdGetResponse(params).pipe(
      __map(_r => _r.body as Array<UserDTO>)
    );
  }

  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `AllUserService.ApiAllUserByTenantIdPostParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiAllUserByTenantIdPostResponse(params: AllUserService.ApiAllUserByTenantIdPostParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `AllUserService.ApiAllUserByTenantIdPostParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiAllUserByTenantIdPost(params: AllUserService.ApiAllUserByTenantIdPostParams): __Observable<UserDTO> {
    return this.ApiAllUserByTenantIdPostResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * User detail
   * @param params The `AllUserService.ApiAllUserByTenantIdByUserIdGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllUserByTenantIdByUserIdGetResponse(params: AllUserService.ApiAllUserByTenantIdByUserIdGetParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * User detail
   * @param params The `AllUserService.ApiAllUserByTenantIdByUserIdGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllUserByTenantIdByUserIdGet(params: AllUserService.ApiAllUserByTenantIdByUserIdGetParams): __Observable<UserDTO> {
    return this.ApiAllUserByTenantIdByUserIdGetResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Update an existing user.
   * @param params The `AllUserService.ApiAllUserByTenantIdByUserIdPutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  ApiAllUserByTenantIdByUserIdPutResponse(params: AllUserService.ApiAllUserByTenantIdByUserIdPutParams): __Observable<__StrictHttpResponse<ServiceResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceResponseDTO>;
      })
    );
  }
  /**
   * Update an existing user.
   * @param params The `AllUserService.ApiAllUserByTenantIdByUserIdPutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  ApiAllUserByTenantIdByUserIdPut(params: AllUserService.ApiAllUserByTenantIdByUserIdPutParams): __Observable<ServiceResponseDTO> {
    return this.ApiAllUserByTenantIdByUserIdPutResponse(params).pipe(
      __map(_r => _r.body as ServiceResponseDTO)
    );
  }

  /**
   * Enables a user for the tenant
   * @param params The `AllUserService.ApiAllUserEnableByTenantIdByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserEnableByTenantIdByUserIdPostResponse(params: AllUserService.ApiAllUserEnableByTenantIdByUserIdPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/Enable/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables a user for the tenant
   * @param params The `AllUserService.ApiAllUserEnableByTenantIdByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserEnableByTenantIdByUserIdPost(params: AllUserService.ApiAllUserEnableByTenantIdByUserIdPostParams): __Observable<null> {
    return this.ApiAllUserEnableByTenantIdByUserIdPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable a user for the tenant
   * @param params The `AllUserService.ApiAllUserDisableByTenantIdByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserDisableByTenantIdByUserIdPostResponse(params: AllUserService.ApiAllUserDisableByTenantIdByUserIdPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/Disable/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable a user for the tenant
   * @param params The `AllUserService.ApiAllUserDisableByTenantIdByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserDisableByTenantIdByUserIdPost(params: AllUserService.ApiAllUserDisableByTenantIdByUserIdPostParams): __Observable<null> {
    return this.ApiAllUserDisableByTenantIdByUserIdPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `AllUserService.ApiAllUserResetLockoutByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserResetLockoutByUserIdPostResponse(params: AllUserService.ApiAllUserResetLockoutByUserIdPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/ResetLockout/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `AllUserService.ApiAllUserResetLockoutByUserIdPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiAllUserResetLockoutByUserIdPost(params: AllUserService.ApiAllUserResetLockoutByUserIdPostParams): __Observable<null> {
    return this.ApiAllUserResetLockoutByUserIdPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `AllUserService.ApiAllUserNamesByTenantIdGetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  ApiAllUserNamesByTenantIdGetResponse(params: AllUserService.ApiAllUserNamesByTenantIdGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.IncludeRoles || []).forEach(val => {if (val != null) __params = __params.append('IncludeRoles', val.toString())});
    if (params.IncludeDisabled != null) __params = __params.set('IncludeDisabled', params.IncludeDisabled.toString());
    (params.ExcludeRoles || []).forEach(val => {if (val != null) __params = __params.append('ExcludeRoles', val.toString())});
    if (params.ExcludeDriverOnly != null) __params = __params.set('ExcludeDriverOnly', params.ExcludeDriverOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/Names/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `AllUserService.ApiAllUserNamesByTenantIdGetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  ApiAllUserNamesByTenantIdGet(params: AllUserService.ApiAllUserNamesByTenantIdGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiAllUserNamesByTenantIdGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }
}

module AllUserService {

  /**
   * Parameters for ApiAllUserByTenantIdGet
   */
  export interface ApiAllUserByTenantIdGetParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllUserByTenantIdPost
   */
  export interface ApiAllUserByTenantIdPostParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: UserDTO;
  }

  /**
   * Parameters for ApiAllUserByTenantIdByUserIdGet
   */
  export interface ApiAllUserByTenantIdByUserIdGetParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllUserByTenantIdByUserIdPut
   */
  export interface ApiAllUserByTenantIdByUserIdPutParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: UserDTO;
  }

  /**
   * Parameters for ApiAllUserEnableByTenantIdByUserIdPost
   */
  export interface ApiAllUserEnableByTenantIdByUserIdPostParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllUserDisableByTenantIdByUserIdPost
   */
  export interface ApiAllUserDisableByTenantIdByUserIdPostParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllUserResetLockoutByUserIdPost
   */
  export interface ApiAllUserResetLockoutByUserIdPostParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllUserNamesByTenantIdGet
   */
  export interface ApiAllUserNamesByTenantIdGetParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    IncludeRoles?: Array<string>;
    IncludeDisabled?: boolean;
    ExcludeRoles?: Array<string>;
    ExcludeDriverOnly?: boolean;
  }
}

export { AllUserService }
