import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationsService } from './notifications.service';
import { Subscription } from 'rxjs';
import { NotificationData } from './notification-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.subscribeToNotifications();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public subscribeToNotifications(): void {
    this.subscription = this.notificationsService.notificationChange.subscribe((notification) => {
      this.messageService.add(notification);
    });
  }

  public clicked(data: NotificationData): void {
    if (data.callback) {
      data.callback();
    }
    if (data.linkRoute) {
      this.router.navigate([data.linkRoute]);
      this.messageService.clear();
    }
  }

  public getIcon(severity: string): string {
    switch (severity) {
      case 'success':
        return 'pi-check-circle';
      case 'info':
        return 'pi-info-circle';
      case 'warn':
        return 'pi-exclamation-triangle';
      case 'error':
        return 'pi-times-circle';
      default:
        return '';
    }
  }
}
