import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';

@Pipe({
  name: 'formatDateUtc',
})
export class FormatDateUtc implements PipeTransform {
  transform(value: any, format?: any): any {
    if (!value || value === '') {
      return value;
    }
    return moment.utc(value).utcOffset(moment().utcOffset()).format('LL HH:mm');
  }
}
