<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<div class="login-boxes w-full">
  <div class="grid m-0 col-12 lg:col-8 p-0 parent-white-login-box">
    <div class="col-12 lg:col-8 lg:col-offset-2 white-login-box">
      <form [formGroup]="formGroup" class="flex flex-column px-8">
        <p class="please-log-in px-1">Log In</p>
        <app-form-field
          class="pb-2"
          placeholder="Email Address"
          type="text"
          labelClass="col-12 p-0"
          controlClass="col-12"
          errorClass="col-12"
          [(ngModel)]="model.username"
          [formGroup]="formGroup"
          [formErrors]="formErrors"
          formControlName="username"
          data-test="username"
        ></app-form-field>
        <app-password
          class="pb-2"
          id="login-password"
          [(ngModel)]="model.password"
          formControlName="password"
          placeholder="Password"
          data-test="password"
          [formErrors]="formErrors"
          [touched]="formGroup.controls['password'].touched"
          [dirty]="formGroup.controls['password'].dirty"
        ></app-password>
        <div class="p-1 my-3">
          <button
            pButton
            type="submit"
            label="Log In"
            (click)="loginUser()"
            class="login-button w-full"
            data-test="login"
          ></button>
          <div class="flex justify-content-between pt-4">
            <p-checkbox
              label="Remember me"
              labelStyleClass="remember-me-label"
              [(ngModel)]="rememberUser"
              binary="true"
              formControlName="rememberUser"
              (onChange)="rememberMeChanged()"
            ></p-checkbox>
            <a class="fauxlink" (click)="forgotPassword()">Forgot password?</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="grid grid-nogutter col-12 lg:col-4 px-0 pb-0 blue-login-box">
    <div class="col-12 child-box">
      <div class="col-12">
        <h2 class="bold-text">Welcome to Truxio</h2>
      </div>
      <div class="col-12"> Everything you need under one Platform: </div>
      <div class="col-12">
        <ul>
          <li>Dispatch</li>
          <li>Invoicing & Driver Settlements</li>
          <li>Fleet Maps</li>
          <li>Track and Trace</li>
          <li>Driver App</li>
          <li>ELD/IFTA</li>
          <li>Factoring</li>
          <li>Freight Partnership</li>
        </ul>
      </div>
      <div class="register">
        <div class="col-12 title">
          <h2>Register Today</h2>
        </div>
        <div class="col-12 button-container">
          <button
            pButton
            type="submit"
            label="Start 30 Day Free Trial"
            (click)="navigateToRegister()"
            class="register-button w-full"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
