<div [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'display'">
    <div class="label-black">
      {{ selected ? selected.name : '-' }}
    </div>
  </ng-container>
  <div class="auto-complete-wrapper">
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="label; else userDropdown">
        <div class="grid grid-nogutter col-12 p-0">
          <div class="md:col-4 sm:col-12 p-1 font-bold" id="label">{{ label }}</div>
          <div class="md:col-8 sm:col-12 p-1 px-0">
            <ng-container [ngTemplateOutlet]="userDropdown"></ng-container>
          </div>
        </div>
      </ng-container>
      <div class="formerrors col-8 col-offset-4 p-0">
        {{ showErrors() | formatErrorMessage }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #userDropdown>
  <p-dropdown
    styleClass="col-12 p-0"
    [options]="options"
    [(ngModel)]="selected"
    optionLabel="name"
    (onChange)="onChange()"
    placeholder="Select a User"
    [showClear]="showClear"
    [disabled]="readonly"
  ></p-dropdown>
</ng-template>
