<p-dialog
  header="Subscription is Required"
  [visible]="true"
  [style]="{ width: '500px' }"
  [focusTrap]="false"
  [closable]="false"
  [focusOnShow]="false"
>
  <p [innerHTML]="message"></p>
  <p-footer *ngIf="showSubscribe || showPortal">
    <p-button
      *ngIf="showSubscribe"
      type="button"
      (click)="subscribe()"
      label="Subscribe"
      [disabled]="disabled"
    ></p-button>
    <p-button
      *ngIf="showPortal"
      type="button"
      (click)="portal()"
      label="Manage Subscription"
      [disabled]="disabled"
    ></p-button>
  </p-footer>
</p-dialog>
