<hr />
<div class="grid grid-nogutter col-12 p-2 editSection">
  <div class="col-2 p-2 font-bold">Factoring </div>
  <div class="col-9 sectionContent pt-0">
    <div read>
      <app-edit-section-row label="Status">
        {{ model.factoringStatus ? 'Enabled' : 'Disabled' }}
      </app-edit-section-row>
      <app-edit-section-row label="Progress" *ngIf="isSuperAdmin">
        <div class="factoring-status">
          <app-factoring-status-indicator
            statusIndicator="fa-cloud"
            [condition]="!!model.factorCloudClientUuid"
            toolTipMessage="Factoring Client"
          ></app-factoring-status-indicator>
          <button
            pButton
            *ngIf="isSuperAdmin && !model.factorCloudClientUuid"
            label="Create Client"
            type="button"
            (click)="createFactoringClient()"
            [disabled]="loading"
          ></button>
        </div>
      </app-edit-section-row>
      <div *ngIf="factoringCompanyOptions$ | async as factoringCompanyOptions" id="factoringCompanyOptions">
        <app-edit-section-row label="Company Name" id="factoringCompanyName">
          {{ factoringCompanyOptions.companyName }}
        </app-edit-section-row>
        <app-edit-section-row label="Physical Address">
          {{ factoringCompanyOptions.addressLine1 }}, {{ factoringCompanyOptions.addressLine2 }},
          {{ factoringCompanyOptions.city }}, {{ factoringCompanyOptions.state }} {{ factoringCompanyOptions.zip }}
        </app-edit-section-row>
        <app-edit-section-row label="Company Phone">
          {{ factoringCompanyOptions.phone }}
        </app-edit-section-row>
        <app-edit-section-row label="Company Email">
          {{ factoringCompanyOptions.email }}
        </app-edit-section-row>
        <app-edit-section-row label="Remit To Address">
          {{ factoringCompanyOptions.remitTo.remitToName }}, {{ factoringCompanyOptions.remitTo.remitToAddress }},
          {{ factoringCompanyOptions.remitTo.remitToCity }}, {{ factoringCompanyOptions.remitTo.remitToState }}
          {{ factoringCompanyOptions.remitTo.remitToZip }}
        </app-edit-section-row>
        <app-edit-section-row label="Remit To ACH">
          {{ factoringCompanyOptions.remitTo.remitToBankName }}, ABA/Routing #{{
            factoringCompanyOptions.remitTo.remitToABA
          }}, Acct #{{ factoringCompanyOptions.remitTo.remitToAccount }} -
          {{ factoringCompanyOptions.remitTo.remitToEmail }}
        </app-edit-section-row>
      </div>
      <app-edit-section-row label="Account Executives" *ngIf="model.factoringStatus">
        <app-account-executives
          *ngIf="model.factorCloudClientUuid"
          [tenantId]="model.tenantId"
        ></app-account-executives>
      </app-edit-section-row>
      <app-edit-section-row label="Notice of Assignment" *ngIf="isSuperAdmin">
        <app-notice-of-assignment [tenantId]="model.tenantId"></app-notice-of-assignment>
      </app-edit-section-row>
    </div>
  </div>
  <div class="col-1">
    <button
      pButton
      *ngIf="isSuperAdmin"
      [label]="model.factoringStatus ? 'Disable' : 'Enable'"
      type="button"
      (click)="toggleFactoring()"
      [disabled]="loading || !model.factorCloudClientUuid"
    ></button>
  </div>
</div>
<p-confirmDialog appendTo="body" header="Confirm Factoring Status Change" key="FactoringConfirmation">
</p-confirmDialog>
<p-confirmDialog
  appendTo="body"
  header="Factoring Not Enabled"
  message="Unable to enable factoring because conflicting debtor records were found.  Please contact engineering support."
  key="TenantBusinessAssociateWarningDialog"
  #cd
>
  <p-footer>
    <button type="button" pButton label="Force" class="ui-button-secondary" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-times" label="Dismiss" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
