import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtDecodeService {
  public decode<T>(token: string) {
    return jwtDecode<T>(token);
  }
}
