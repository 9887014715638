import {
  Component,
  Output,
  EventEmitter,
  Injector,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { FocusTrapService } from '../../../services/focus-trap.service';
import { DtoFormBase } from '../../shared/FormBase';
import { SelectItem } from 'primeng/api';
import { Role, RoleDisplayName } from 'src/app/data/static-data';
import { AllUserService, RoleService, UserService } from 'src/apiclient/v1.1/services';
import {
  AvailableUserRolesDTO,
  PhoneDTO,
  ServiceResponseDTO,
  UserCommissionDTO,
  UserDTO,
} from 'src/apiclient/v1.1/models';
import { PercentageFlatRate } from '../percentage-flat-rate/percentage-flat-rate';
import { PercentageFlatRateComponent } from '../percentage-flat-rate/percentage-flat-rate.component';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserEditComponent extends DtoFormBase<UserDTO> implements OnInit, OnDestroy {
  @Input() tenantId: number;
  @Input() isLogisticsTenant: number;
  @Output() userEdited = new EventEmitter();

  @ViewChildren('userCommission') userCommissions: QueryList<PercentageFlatRateComponent>;

  public dialogVisible = false;
  public passwordForm: FormGroup;
  public standardRoles: SelectItem[] = [];
  public commissionedRoles: SelectItem[] = [];
  public isSuperAdmin: boolean;
  public disableSave: boolean = false;
  public showRoles: boolean = false;

  private destroy$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private focusTrap: FocusTrapService,
    private userService: UserService,
    private allUserService: AllUserService,
    private roleService: RoleService,
    protected injector: Injector
  ) {
    super(injector);
    this.model.phone = {} as PhoneDTO;
  }

  public ngOnInit(): void {
    this.resetState();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public initSelf(): void {
    this.init(this.clientToken.userId());
  }

  public init(id: number): void {
    this.resetState();
    this.model.userId = id;

    if (id === this.clientToken.userId()) {
      // Editing own record
      this.showRoles = false;
    } else {
      // Editing another user's record
      if (!this.clientToken.hasPermission('UserEdit')) {
        // Permission denied
        this.closeDialog();
        return;
      } else {
        this.isSuperAdmin = this.clientToken.hasRole('SuperAdmin');
        this.showRoles = true;
      }
    }

    this.setRoles()
      .pipe(
        tap(() => this.initUserCommissionsFormControls()),
        tap(() => this.initFormForUser()),
        tap(() => this.openDialog())
      )
      .subscribe();
  }

  private initFormForUser(): void {
    this.disableSave = true;
    // Adding a user
    // this.model.isEditable comes from API
    if (this.model.userId === 0) {
      this.model.isEditable = true;
      this.enableUserFields();
      this.disableSave = false;
      this.initUserCommissionsFormControls();
    } else {
      // Edit user, loads data
      this.subscriptions.push(
        this.getUserFromApi(this.model.userId)
          .pipe(
            take(1),
            tap((user) => {
              this.model = user;
              this.formGroup.controls.firstName.setValue(user.firstName);
              this.formGroup.controls.lastName.setValue(user.lastName);
              this.formGroup.controls.email.setValue(user.email);
              /**
               * `phone-number` and `phone-ext` are closely tied to the form field controls in the phone number component.
               * @see PhoneNumberComponent ('src/app/components/phone-number')
               */
              this.formGroup.controls['phone-number'].setValue(user.phone.number);
              this.formGroup.controls['phone-ext'].setValue(user.phone.ext);
              this.setUserCommissionFormModel();
              if (!this.model.isEditable) {
                this.disableUserFields();
              } else {
                this.enableUserFields();
              }
              this.disableSave = false;
            }),
            catchError((error) => this.handleError(error))
          )
          .subscribe()
      );
    }
  }

  public isRoleSelected(role: string): boolean {
    return this.model.roles.includes(role);
  }

  public saveUser(): void {
    // tslint:disable-next-line:forin
    for (const name in this.formGroup.controls) {
      this.formGroup.controls[name].markAsTouched();
    }

    // Do not save when the form is invalid.
    const areUserCommissionsValid = this.areUserCommissionsValid();
    if (!this.formValid() || !areUserCommissionsValid) {
      return;
    }

    // Needs to read disabled form values when `this.model.isEditable` is false as well.
    const formValue = this.formGroup.getRawValue();

    const user: UserDTO = {
      ...this.model,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      phone: {
        number: formValue['phone-number'],
        ext: formValue['phone-ext'],
      },
    };

    this.setUserCommissionsOnUser(user);

    this.disableSave = true;
    let saveMethod: Observable<void>;

    if (user.userId === 0) {
      saveMethod = this.postUserToApi(user).pipe(
        tap(() => {
          this._NS.success('User created!', 'A new User was created successfully.');
          this.userEdited.emit({ dataChanged: true });
        }),
        map(() => null as void)
      );
    } else {
      saveMethod = this.putUserToApi(user).pipe(
        tap((response) => {
          if (response.statusCode === 200) {
            this._NS.success('Info updated!', 'Your changes were saved successfully.');
            this.userEdited.emit({ dataChanged: true });
          }
          if (response.statusCode === 202) {
            this._NS.success('Email updated!', 'You must log in with your new email address.');
            this.clientToken.revokeToken();
          }
        }),
        map(() => null as void)
      );
    }

    this.subscriptions.push(
      saveMethod
        .pipe(
          take(1),
          tap(() => this.closeDialog()),
          catchError((error) => {
            this.disableSave = false;
            return this.handleError(error);
          }),
          tap(() => (this.disableSave = false))
        )
        .subscribe()
    );
  }

  private setUserCommissionsOnUser(user: UserDTO) {
    const userCommissions: UserCommissionDTO[] = [];

    this.commissionedRoles.forEach((role) => {
      if (user.roles.includes(role.value)) {
        const formValue: PercentageFlatRate = this.formGroup.controls[role.value].value;
        userCommissions.push({
          flatRate: formValue.percentage ? null : formValue.amount,
          percentage: formValue.percentage ? formValue.amount : null,
          role: role.value,
          tenantUserId: user.tenantUserId,
          type: formValue.percentage ? 'Percentage' : 'Flat',
        });
      }
    });

    user.userCommissions = userCommissions;
  }

  private getUserFromApi(userId: number): Observable<UserDTO> {
    if (userId === this.clientToken.userId()) {
      return this.userService.Me(this.clientToken.auth());
    } else if (this.tenantId) {
      return this.allUserService.Get({
        userId: userId,
        tenantId: this.tenantId,
        Authorization: this.clientToken.auth(),
      });
    } else {
      return this.userService.GetUserById({ id: userId, Authorization: this.clientToken.auth() });
    }
  }

  private setRoles(): Observable<void> {
    if (this.standardRoles.length > 0) {
      return of(null);
    }

    return this.roleService
      .GetUserRoles({
        tenantId: this.tenantId ?? this.clientToken.tenantId(),
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        tap((availableRoles) => this.mapRolesToSelectItemArrays(availableRoles)),
        map(() => null),
        takeUntil(this.destroy$),
        catchError((error) => this.handleError(error))
      );
  }

  private initUserCommissionsFormControls(): void {
    this.commissionedRoles.forEach((role) => {
      const percentageFlatRate: PercentageFlatRate = {
        percentage: true,
        amount: 0,
      };

      this.formGroup.addControl(role.value, new FormControl(percentageFlatRate));
    });
  }

  private setUserCommissionFormModel() {
    this.commissionedRoles.forEach((role) => {
      const userCommission = this.model?.userCommissions.find((c) => c.role === role.value);
      if (userCommission) {
        const isPercentage = userCommission.type === 'Percentage';
        this.formGroup.controls[role.value].setValue({
          percentage: isPercentage,
          amount: isPercentage ? userCommission.percentage : userCommission.flatRate,
        });
      }
    });
  }

  private mapRolesToSelectItemArrays(availableUserRoles: AvailableUserRolesDTO): void {
    this.standardRoles = availableUserRoles.standardRoles
      ? this.mapRoleToSelectItemWithDisplayName(availableUserRoles.standardRoles)
      : [];

    this.commissionedRoles = availableUserRoles.commissionedRoles
      ? this.mapRoleToSelectItemWithDisplayName(availableUserRoles.commissionedRoles)
      : [];
  }

  private mapRoleToSelectItemWithDisplayName(roles: string[]): SelectItem[] {
    return roles.map((role) => ({
      label: this.getDisplayNameForRole(role),
      value: role,
      disabled: this.shouldDisableRole(role),
    }));
  }

  private shouldDisableRole(role: string): boolean {
    const isSuperAdmin = this.clientToken.hasRole(Role.SuperAdmin);
    const isAdmin = this.clientToken.hasRole(Role.SuperAdmin) || this.clientToken.hasRole(Role.Admin);
    const isHumanResources = this.clientToken.hasRole(Role.LogisticsHumanResources);

    switch (role) {
      case Role.SuperAdmin:
        return !isSuperAdmin;

      case Role.Admin:
        return isHumanResources && !isAdmin;

      default:
        return false;
    }
  }

  private enableUserFields(): void {
    const fields = ['firstName', 'lastName', 'phone-number', 'phone-ext', 'email'];
    for (const field of fields) {
      this.formGroup.get(field).enable();
    }
  }

  public disableUserFields(): void {
    const fields = ['firstName', 'lastName', 'phone-number', 'phone-ext', 'email'];
    for (const field of fields) {
      this.formGroup.get(field).disable();
    }
  }

  public openDialog(): void {
    this.dialogVisible = true;
    this.focusTrap.onDialog(true);
  }

  public closeDialog(): void {
    this.dialogVisible = false;
    this.focusTrap.onDialog(false);
    // Form needs manually reset since lifecycle events do not occur on dialog open/close.
    // Converting this component to a DynamicDialog would give the expected component lifecycle behavior.
    this.formGroup.reset();
    this.ngOnDestroy();
  }

  private postUserToApi(user: UserDTO): Observable<UserDTO> {
    if (user.userId !== 0) {
      throw Error('UserId must be 0');
    } else if (this.tenantId) {
      return this.allUserService.Post({
        tenantId: this.tenantId,
        body: user,
        Authorization: this.clientToken.auth(),
      });
    } else {
      return this.userService.Post({
        body: user,
        Authorization: this.clientToken.auth(),
      });
    }
  }

  private putUserToApi(user: UserDTO): Observable<ServiceResponseDTO> {
    if (!user.userId || user.userId < 1) {
      throw Error('UserId must be > 0');
    } else if (this.tenantId) {
      return this.allUserService.Put({
        tenantId: this.tenantId,
        userId: user.userId,
        body: user,
        Authorization: this.clientToken.auth(),
      });
    } else {
      return this.userService.Put({
        id: user.userId,
        body: user,
        Authorization: this.clientToken.auth(),
      });
    }
  }

  private getDisplayNameForRole(role: string): string {
    return RoleDisplayName[role] ?? role;
  }

  private areUserCommissionsValid(): boolean {
    return !this.userCommissions.some((u) => u.validateForm() === false);
  }

  private resetState(): void {
    this.model = {
      userId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: undefined,
      roles: [],
    };

    /**
     * The `phone` property does not need to be added to the form because the fields are created/managed in the phone number component.
     * The fields `phone-number` and `phone-ext` value/validation are also from the phone number component.
     * @see PhoneNumberComponent ('src/app/components/phone-number')
     * Phone number component should be refactored to implement `ControlValueAccessor` to avoid confusion or unexpected behavior.
     */
    this.initFormFor('UserDTO', ['phone-number', 'phone-ext']);
    this.formGroup.removeControl('phone');

    this.formErrors.roles = '';
  }
}
