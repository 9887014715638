import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {
  constructor() {}

  public saveAs(data: string | Blob, fileName?: string): void {
    saveAs(data, fileName);
  }
}
