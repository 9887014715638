import { Injectable, OnDestroy } from '@angular/core';
import { SuperAdminService } from '../apiclient/services';
import { BillingService } from '../apiclient/v1.1/services';
import { BehaviorSubject, defer, Observable, of, Subject } from 'rxjs';
import { ClientTokenService } from './client-token-service';
import { NotificationsService } from '../app/components/notifications/notifications.service';
import { AppConfig } from '../app/config/app.config';
import { BillingStatus } from '../app/data/static-data';
import { StripeService } from './stripe/stripe.service';
import { catchError, filter, switchMap, map, tap, takeUntil } from 'rxjs/operators';
import { LoggingService } from '../app/core/logging.service';
import { TargetUrlService } from './target-url-service/target-url-service';

/**
 * loading - Status is not available yet
 * no_user - User is not logged in
 * exempt - Subscription not required.
 * required - added by API indicating no subscription status
 * the rest defined by Stripe
 */

@Injectable()
export class TenantBillingService implements OnDestroy {
  private status = new BehaviorSubject<BillingStatus>(BillingStatus.Loading);
  private destroy$ = new Subject<void>();

  constructor(
    private clientToken: ClientTokenService,
    private billing: BillingService,
    private superAdmin: SuperAdminService,
    private notificationsService: NotificationsService,
    private loggingService: LoggingService,
    private stripeService: StripeService,
    private targetUrlService: TargetUrlService
  ) {}

  public loadBillingStatus(): void {
    // Refresh status on login status change
    this.clientToken
      .isLoggedIn()
      .pipe(
        tap((isLoggedIn) => {
          if (!isLoggedIn) {
            this.status.next(BillingStatus.NoUser);
          }
        }),
        filter((isLoggedIn) => isLoggedIn),
        switchMap(() => this.initStripe()),
        switchMap(() => this.checkBillingStatus()),
        tap((status) => {
          this.status.next(status);
          if (![BillingStatus.Active, BillingStatus.Exempt].includes(status)) {
            this.targetUrlService.navigateWithTargetUrl(AppConfig.routes.subscription.required);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public billingStatus(): Observable<BillingStatus> {
    return this.status.asObservable();
  }

  public startSubscribe(): Promise<void> {
    return this.billing
      .Subscribe(this.clientToken.auth())
      .pipe(
        switchMap((response) =>
          defer(() =>
            this.stripeService.redirectToCheckout({
              sessionId: response.sessionId,
            })
          )
        ),
        tap((result) => {
          if (result.error) {
            throw result.error;
          }
        }),
        map(() => null),
        catchError((error) => this.handleError(error))
      )
      .toPromise();
  }

  public checkout(sessionId: string): Promise<void> {
    return this.billing
      .Checkout({
        Authorization: this.clientToken.auth(),
        body: { sessionId },
      })
      .pipe(
        switchMap(() => this.checkBillingStatus()),
        map(() => null),
        catchError((error) => this.handleError(error))
      )
      .toPromise();
  }

  public openPortal(): Promise<void> {
    return this.billing
      .Portal(this.clientToken.auth())
      .pipe(
        tap((data) => {
          if (data.url) {
            window.location.href = data.url;
          } else {
            throw new Error('API did not return a url');
          }
        }),
        map(() => null),
        catchError((error) => this.handleError(error))
      )
      .toPromise();
  }

  public async updateExemptStatus(tenantId: number, isExempt: boolean): Promise<void> {
    try {
      if (!this.clientToken.hasRole('SuperAdmin')) {
        throw new Error('Only SuperAdmin can update Exempt Billing Status');
      }
      await this.superAdmin
        .ApiSuperAdminTenantExemptGet({
          Authorization: this.clientToken.auth(),
          tenantId: tenantId,
          isExempt: isExempt,
        })
        .toPromise();
    } catch (e) {
      this.handleError(e);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initStripe(): Observable<void> {
    if (!this.clientToken.hasPermission('TenantEdit')) {
      return of(null);
    }

    return this.billing.Config(this.clientToken.auth()).pipe(
      switchMap((response) => defer(() => this.stripeService.loadStripe(response.publicKey))),
      catchError((error) => this.handleError(error))
    );
  }

  private checkBillingStatus(): Observable<BillingStatus> {
    return this.billing.Status(this.clientToken.auth()).pipe(
      map((response) => response.billingStatus as BillingStatus),
      tap((status) => this.status.next(status)),
      catchError((error) => this.handleError(error))
    );
  }

  private handleError(error: any): Observable<null> {
    this.notificationsService.apiError(error);
    this.loggingService.logError(error);
    return of(null);
  }
}
