/// <reference types="@types/googlemaps" />

import { Component, OnInit, HostListener, ViewChild, Injector } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { MapService } from '../../../apiclient/services';
import { ClientTokenService } from '../../../services/client-token-service';
import { MapViewDTO, MapViewDriverDTO, MapViewLoadDTO } from '../../../apiclient/models';
import { HandleErrorBase } from '../../shared/HandleErrorBase';
import * as moment from 'moment';
import { Router } from '@angular/router';

import { LoadStatusName, Permission, Role } from '../../data/static-data';
// import * as MarkerClusterer from '@google/markerclustererplus';
import { DrivercardsComponent } from './drivercards/drivercards.component';
import { LoadcardsComponent } from './loadcards/loadcards.component';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { Utils } from '../../../utils/utils';
import { NavigationService } from 'src/services/navigation/navigation.service';

declare var google: any;

@Component({
  selector: 'app-workplace',
  templateUrl: './workplace.component.html',
  styleUrls: ['./workplace.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkplaceComponent extends HandleErrorBase implements OnInit {
  thresholdHours: number = 8;
  maxHoursToDelivery: number = 24;
  showProgressOverlay: boolean = false;

  map: any;
  geocoder: any;
  overlays: any[];
  mapOptions: any;
  mapFullSize: boolean = false;

  driversScrollStyle: any;
  loadsScrollStyle: any;
  ourMap: any;
  infoWindow: any;
  spiderOptions = { spiralFootSeparation: 146, spiralLengthStart: 122, spiranLenthFactor: 18, keepSpiderfied: true };
  oms: any;
  loadsowner = 'allloads';
  bounds = new google.maps.LatLngBounds();
  totalDriverIcons: number = 0;
  placedDriverIcons: number = 0;
  loadFilters = [
    {
      label: 'All',
      value: 'allloads',
      active: true,
    },
    {
      label: 'My Loads',
      value: 'myloads',
      active: false,
    },
  ];

  mapdata: MapViewDTO;
  myloaddata: Array<MapViewLoadDTO>;

  trackableStatuses: string[] = [LoadStatusName.Dispatched, LoadStatusName.InTransit, LoadStatusName.InYard];

  now: Date = new Date();
  today = moment(
    new Date(
      this.now.getUTCFullYear(),
      this.now.getUTCMonth(),
      this.now.getUTCDate(),
      this.now.getUTCHours(),
      this.now.getUTCMinutes(),
      this.now.getUTCSeconds(),
      this.now.getUTCMilliseconds()
    )
  );
  public showLoadDialog = false;
  public selectedLoadId: number;

  @ViewChild('driverCards', { static: true }) driverCards: DrivercardsComponent;
  @ViewChild('loadCards', { static: true }) loadCards: LoadcardsComponent;
  @HostListener('window:resize') onResize() {
    this.setScrollHeights();
  }

  constructor(
    protected injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private mapService: MapService,
    private clientTokenService: ClientTokenService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    super(injector);
  }

  ngOnInit() {
    const allowedRoles = [
      Role.SuperAdmin,
      Role.Admin,
      Role.Dispatch,
      Role.LogisticsCarrierAgent,
      Role.LogisticsHumanResources,
      Role.LogisticsAdmin,
    ];
    const allowedPermissions = [Permission.LoadRead];
    if (
      !allowedPermissions.some((permission) => this.clientToken.hasPermission(permission)) ||
      !allowedRoles.some((role) => this.clientToken.hasRole(role))
    ) {
      this.navigationService.navigateToDefaultDashboard();
    }
    this.setScrollHeights();
    this.infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
    this.breadcrumbService.setItems('workplace');
    this.overlays = new Array<google.maps.Marker>();
    this.mapOptions = {
      center: { lat: 39, lng: -95 },
      zoom: 4,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      styles: Utils.GMAP_OPTIONS,
    };
  }

  setMap(event) {
    this.map = event.map;
    this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);
    this.getMapData().then((_) => {
      this.showProgressOverlay = true;
      this.setDriverIcons().then((x) => {
        this.oms.addListener('format', function (marker, status) {
          marker.setLabel(null);
          const plusIcon =
            status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
              ? false
              : status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
              ? true
              : status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
              ? false
              : null;
          if (plusIcon) {
            marker.setLabel({
              color: 'white',
              fontWeight: 'bold',
              fontSize: '14px',
              text: '+',
            });
          }
        });
        setTimeout((_y) => {
          this.showProgressOverlay = false;
        });
      });
    });
  }

  setScrollHeights() {
    const driverScrollHeight = document.getElementsByClassName('layout-content')[0].clientHeight - 180 + 'px';
    this.driversScrollStyle = {
      width: '100%',
      'max-height': driverScrollHeight,
      height: driverScrollHeight,
      background: 'transparent',
      border: 'none',
      position: 'relative',
      'z-index': '1',
    };

    const loadScrollHeight = document.getElementsByClassName('layout-content')[0].clientHeight - 180 + 'px';
    this.loadsScrollStyle = {
      width: '100%',
      'max-height': loadScrollHeight,
      height: loadScrollHeight,
      background: 'transparent',
      border: 'none',
    };
  }

  handleLoadEdited(data) {
    if (data.dataChanged) {
      this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);
      this.infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
      this.removeMarkers();
      this.showProgressOverlay = true;
      this.getMapData().then((_) => {
        this.setDriverIcons().then((x) => {
          this.oms.addListener('format', function (marker, status) {
            marker.setLabel(null);
            const plusIcon =
              status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
                ? false
                : status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
                ? true
                : status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
                ? false
                : null;
            if (plusIcon) {
              marker.setLabel({
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px',
                text: '+',
              });
            }
          });

          setTimeout((_y) => {
            this.showProgressOverlay = false;
          });
        });
      });
    }
  }

  switchLoadList(owner) {
    this.loadFilters.map((i) => (i.active = false));
    owner.active = true;
    this.loadsowner = owner.value;
  }

  async getMapData(): Promise<void> {
    // setTimeout(_ => this.showProgressOverlay = true);
    // this.showProgressOverlay = true;
    this.myloaddata = new Array<MapViewLoadDTO>();
    this.mapdata = await this.mapService.ApiMapGet(this.clientTokenService.auth()).toPromise();
    this.mapdata.loads.forEach((l) => {
      if (l.dispatcher.id === this.clientTokenService.userId()) {
        this.myloaddata.push(l);
      }
    });
  }

  updateCardLists() {
    this.driverCards.doChangeDetection();
    this.loadCards.doChangeDetection();
  }

  async setDriverIcons(): Promise<void> {
    if (!this.oms) {
      this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);
    }
    this.infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
    // setTimeout(_ => {
    // this.showProgressOverlay = true;
    this.mapdata.drivers.forEach(async (drv) => {
      if (drv && (drv.location || (drv.latitude && drv.longitude))) {
        this.totalDriverIcons++;
        await this.addMapIcon(drv.loads[0], drv);
      }
    });
    // });
  }
  isActiveLoadStatus(load: MapViewLoadDTO): boolean {
    if (load.loadStatus && this.trackableStatuses.includes(load.loadStatus)) {
      return true;
    }
    return false;
  }
  getTruckColor(driver: MapViewDriverDTO): string {
    const now = new Date();
    const today = moment(
      new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
    const loadLastUpdate = moment(driver.lastTrackingUpdatedUtc);

    if (driver.loads.length === 0) {
      return '#eb5652';
    } else if (!this.isActiveLoadStatus(driver.loads[0])) {
      return '#eb5652';
    } else if (today.diff(loadLastUpdate, 'hours') > this.thresholdHours || !driver.loads[0].lastTrackingUpdatedUtc) {
      return '#797979';
    } else if (
      driver.loads[0].deliveryDate &&
      moment(new Date(driver.loads[0].deliveryDate)).diff(today, 'hours') <= this.maxHoursToDelivery
    ) {
      return '#fdc629';
    } else {
      return '#6EBC3B';
    }
  }

  async addMapIcon(load: MapViewLoadDTO, driver: MapViewDriverDTO) {
    const geocodeParams: any = {};
    if (driver.latitude !== 0 && driver.longitude !== 0) {
      geocodeParams.location = new google.maps.LatLng(driver.latitude, driver.longitude);
    } else {
      geocodeParams.address = driver.location;
    }

    const geocoder = new google.maps.Geocoder();
    if (geocodeParams.location) {
      this.addMarker(geocodeParams.location, driver, load);
    } else {
      geocoder.geocode(geocodeParams, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.addMarker(results[0].geometry.location, driver, load);
        }
      });
    }
  }

  addMarker(location, driver, load) {
    const fillColor = this.getTruckColor(driver);
    const marker = new google.maps.Marker({
      icon: Utils.buildMarkerSymbol(fillColor),
      map: this.map,
      position: location,
      title: driver.name,
      truckColor: fillColor,
      driverName: driver.name,
      loadId: load ? load.loadId : 0,
      loadNumber: load ? load.loadNumber : 0,
      truckNumber: driver.truckNumber,
      driverId: driver.userId,
      loads: driver.loads,
    });
    this.bounds.extend(location);

    const thisRef = this;
    const docRef = document;
    this.overlays.push(marker);
    google.maps.event.addListener(marker, 'spider_click', function (e) {
      thisRef.handleSpiderClick(marker, thisRef, thisRef.infoWindow, docRef);
    });

    this.oms.addMarker(marker);
    this.placedDriverIcons++;
    if (this.totalDriverIcons === 1) {
      this.map.setZoom(18);
    } else {
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
    }
    this.map.setCenter(this.bounds.getCenter());
  }

  handleOverlayClick(event) {
    this.handleSpiderClick(event.overlay, event, this.infoWindow, document);
  }

  handleSpiderClick(marker, wpRef, infoWin, docRef) {
    const isMarker = marker.getTitle !== undefined;
    if (isMarker) {
      const driverId = marker.driverId;
      const loads = marker.loads;
      loads.forEach((load) => {
        const linkBody = docRef.getElementById('fauxlinkBody' + load.loadId);
        linkBody.innerHTML = this.getMarkerLoadNumberButton(load.loadId, load.loadNumber);
        if (wpRef.trackableStatuses.includes(load.loadStatus) && load.lastTrackingUpdatedUtc) {
          const updateTrackingBody = docRef.getElementById('updateTrackingBody' + load.loadId);
          updateTrackingBody.innerHTML = this.getMarkerTrackingTemplate(load.loadId);
        }
      });
      const mapdriver = docRef.getElementById('mapDriverCard' + driverId);
      infoWin.setContent('' + mapdriver.innerHTML + '');
      infoWin.open(wpRef.map, marker);
      wpRef.map.setCenter(marker.getPosition());
    }
  }

  public openEditLoadDialog($event: number): void {
    this.openLoadDialog($event);
  }

  public openCreateLoadDialog(): void {
    this.openLoadDialog(0);
  }

  showDriverOnMap($event) {}

  trackingUpdated($event) {
    this.infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
    this.showProgressOverlay = true;
    this.getMapData().then((_) => {
      this.removeMarkers();
      this.setDriverIcons().then((x) => {
        this.oms.addListener('format', function (marker, status) {
          marker.setLabel(null);
          const plusIcon =
            status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
              ? false
              : status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
              ? true
              : status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
              ? false
              : null;
          if (plusIcon) {
            marker.setLabel({
              color: 'white',
              fontWeight: 'bold',
              fontSize: '14px',
              text: '+',
            });
          }
        });
        setTimeout((_x) => {
          this.showProgressOverlay = false;
        }, 1000);
      });
      // this.showProgressOverlay = false;
    });
  }
  removeMarkers() {
    this.oms.forgetAllMarkers();
    this.oms.removeAllMarkers();
    this.overlays.forEach((element) => {
      element.setMap(null);
    });
  }

  getLoadStatusClass(loadStatus: string) {
    switch (loadStatus) {
      // TODO: Get rest of the stati
      case 'Delayed':
        return 'delayed';
      default: {
        return 'gray';
      }
    }
  }

  private getMarkerLoadNumberButton(loadId: string, loadNumber: string): string {
    return `<b class="fauxlink" onclick="document.getElementById('fauxlink${loadId}').click()">${loadNumber}</b>`;
  }

  private getMarkerTrackingTemplate(loadId: string): string {
    return `<div>
            <span id="updateTracking{{load.loadId}}" class="pfsblue fauxlink" onclick="document.getElementById('updateTracking${loadId}').click()">
              Update Tracking
            </span>
            <span class="pad4left"> | </span>
            <span  id="viewHistory{{load.loadId}}" class="pad4left pfsblue fauxlink" onclick="document.getElementById('viewHistory${loadId}').click()">
              View History
            </span>
            </div>`;
  }

  private openLoadDialog(loadId: number): void {
    this.selectedLoadId = loadId;
    this.showLoadDialog = true;
  }
}
