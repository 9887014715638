import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactAddressDTO } from 'src/apiclient/v1.1/models/contact-address-dto';
import { AddressViewMode } from 'src/app/components/address-v3/models/address-view-mode';
import { AccountingContactAddressFormFields } from './accounting-contact-address-form-fields';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AddressV3Validator } from 'src/app/components/address-v3/validator/address-v3.validator';

@Component({
  selector: 'app-accounting-contact-address',
  templateUrl: './accounting-contact-address.component.html',
  styleUrls: ['./accounting-contact-address.component.scss'],
})
export class AccountingContactAddressComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() contactAddress: ContactAddressDTO;

  @Output() save = new EventEmitter<ContactAddressDTO>();

  public disableSave$: Observable<boolean>;
  public editing: boolean = false;
  public contactAddressForm: FormGroup;
  public formErrors: any = {};
  public addressFormErrors: any = {};
  public addressValidationMessages = [];
  public readonly addressViewMode = AddressViewMode;
  public readonly formFields = AccountingContactAddressFormFields;

  private destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder, private formValidationErrorsService: FormValidationErrorsService) {}

  public ngOnInit(): void {
    this.contactAddressForm = this.formBuilder.group({
      [AccountingContactAddressFormFields.FirstName]: ['', [Validators.required]],
      [AccountingContactAddressFormFields.LastName]: ['', [Validators.required]],
      [AccountingContactAddressFormFields.Email]: ['', [Validators.required]],
      [AccountingContactAddressFormFields.PhoneNumber]: ['', [Validators.required]],
      [AccountingContactAddressFormFields.Address]: [{}, [Validators.required, AddressV3Validator.required]],
    });

    this.contactAddressForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.setCurrentFormErrors());
    this.disableSave$ = this.contactAddressForm.valueChanges.pipe(
      startWith(false),
      map(() => !this.contactAddressForm.valid),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public startEditing(): void {
    this.initFormFromContactAddress();
  }

  public saveContactAddress(): void {
    if (this.contactAddressForm.valid) {
      this.editing = false;
      this.save.emit(this.buildNewContactAddress());
    }
  }

  public cancelEdit(): void {
    this.editing = false;
  }

  public getContactName(): string {
    return `${this.contactAddress.contact.firstName} ${
      this.contactAddress.contact.lastName ? this.contactAddress.contact.lastName : ''
    }`.trim();
  }

  public getAddress(): string {
    const addressPieces = [];
    addressPieces.push(
      `${this.contactAddress.address.line1} ${
        this.contactAddress.address.line2 ? this.contactAddress.address.line2 : ''
      }`.trim()
    );
    addressPieces.push(`${this.contactAddress.address.city}`);
    addressPieces.push(`${this.contactAddress.address.stateOrTerritory} ${this.contactAddress.address.postalCode}`);
    return addressPieces.join(', ').trim();
  }

  private initFormFromContactAddress(): void {
    this.contactAddressForm.reset();

    this.contactAddressForm.setValue({
      [AccountingContactAddressFormFields.FirstName]: this.contactAddress.contact.firstName,
      [AccountingContactAddressFormFields.LastName]: this.contactAddress.contact.lastName,
      [AccountingContactAddressFormFields.Email]: this.contactAddress.contact.email,
      [AccountingContactAddressFormFields.PhoneNumber]: this.contactAddress.contact.phoneNumber,
      [AccountingContactAddressFormFields.Address]: this.contactAddress.address,
    });
  }

  private buildNewContactAddress(): ContactAddressDTO {
    const rawFormValue = this.contactAddressForm.getRawValue();
    const rawAddress = this.contactAddressForm.controls.address.value;

    const newContactAddress: ContactAddressDTO = {
      ...this.contactAddress,
      contact: {
        contactId: this.contactAddress.contact.contactId,
        firstName: rawFormValue[AccountingContactAddressFormFields.FirstName],
        lastName: rawFormValue[AccountingContactAddressFormFields.LastName],
        email: rawFormValue[AccountingContactAddressFormFields.Email],
        phoneNumber: rawFormValue[AccountingContactAddressFormFields.PhoneNumber],
      },
      address: {
        addressId: this.contactAddress.address.addressId,
        ...rawAddress,
      },
    };

    return newContactAddress;
  }

  private setCurrentFormErrors() {
    this.formErrors = this.formValidationErrorsService.getFormErrors(this.contactAddressForm);
  }

  private setCurrentAddressFormErrors() {
    this.addressFormErrors = this.formValidationErrorsService.getFormErrors(
      this.contactAddressForm.controls.address as FormGroup,
      this.addressValidationMessages
    );
  }
}
