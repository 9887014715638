import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/modules/shared.module';
import { CoreModule } from './core/core.module';

import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';

import { AppBreadcrumbComponent } from './components/breadcrumb/app.breadcrumb.component';
import { AppFooterComponent } from './components/footer/app.footer.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { PrimeNgModule } from './shared/modules/primeng.module';

import { LoginModule } from './modules/login/login.module';
import { DriverProfileModule } from './modules/driver-profile/driver-profile.module';
import { TenantModule } from './modules/tenant/tenant.module';
import { SettingsModule } from './modules/settings/settings.module';
import { AdminModule } from './modules/admin/admin.module';
import { CompanyModule } from './shared/company/company.module';
import { LoadModule } from './modules/load/load.module';
import { WorkplaceModule } from './modules/workplace/workplace.module';

import { ApiConfiguration } from '../apiclient/api-configuration';
import { ApiModule } from '../apiclient/api.module';

import { ApiConfiguration as ApiConfiguration_v1_1 } from '../apiclient/v1.1/api-configuration';
import { ApiModule as ApiModule_v1_1 } from '../apiclient/v1.1/api.module';

import { environment } from '../environments/environment';

import { LOCAL_STORAGE, StorageService, StorageServiceModule } from 'ngx-webstorage-service';

import { ClientTokenService } from '../services/client-token-service';

import { MessageService } from 'primeng/api';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { AppUpdateService } from '../services/app-update-service';
import { ScrollManagerService } from '../services/scroll-manager.service';
import { StatesService } from '../services/states-service';
import { ValidatorsService } from './core/validators-service';
import { TmsErrorHandler } from './shared/tms-error-handler';
import { LoggingService } from '../app/core/logging.service';
import { RequestCacheService } from '../services/request-cache.service';
import { CachingInterceptor } from './shared/interceptors/caching.interceptor';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MarketplaceModule } from './modules/marketplace/marketplace.module';
import { LbtIntegrationModule } from './modules/lbt-integration/lbt-integration.module';
import { LbtIntegrationComponent } from './modules/lbt-integration/lbt-integration.component';
import { TenantBillingService } from '../services/tenant-billing.service';
import { SubscriptionModule } from './modules/subscription/subscription.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FactoringModule } from './modules/factoring/factoring.module';
import { ConfirmationService } from 'primeng/api';
import { UserRegistrationModule } from './modules/user-registration/user-registration.module';
import { EnvironmentService } from 'src/services/environment/environment.service';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { UsersComponent } from './modules/users/users.component';
import { UsersRoutingModule } from './modules/users/users-routing.module';
import { CognitoClientTokenService } from 'src/services/cognito-client-token-service';

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = EnvironmentService.environment.apiURL;
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    PrimeNgModule,
    UserRegistrationModule,
    LoginModule,
    TenantModule,
    SettingsModule,
    AdminModule,
    CompanyModule,
    DriverProfileModule,
    LoadModule,
    WorkplaceModule,
    MarketplaceModule,
    ApiModule,
    ApiModule_v1_1,
    StorageServiceModule,
    FormsModule,
    ReactiveFormsModule,
    PrivacyPolicyModule,
    LbtIntegrationModule,
    SubscriptionModule,
    // Currently our app does not consistently reach a 'registerWhenStable' state on the login page.
    // In Angular 10, the default option is 'registerWhenStable:30000' which is documented at https://v10.angular.io/api/service-worker/SwRegistrationOptions
    // Until app is using Angular 10+, 'registerWithDelay:30000' will consistently register the service worker after 30 seconds.
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWithDelay:30000',
    }),
    FactoringModule,
    DashboardModule,
    UsersRoutingModule,
  ],
  declarations: [
    AppComponent,
    MenuComponent,
    MenuItemComponent,
    AppBreadcrumbComponent,
    AppFooterComponent,
    PrivacyPolicyComponent,
    LbtIntegrationComponent,
    UsersComponent,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initApiConfiguration, deps: [ApiConfiguration_v1_1], multi: true },
    { provide: APP_INITIALIZER, useFactory: initApiConfiguration, deps: [ApiConfiguration], multi: true },
    MessageService,
    {
      provide: ClientTokenService,
      useClass: CognitoClientTokenService,
    },
    TenantBillingService,
    StatesService,
    BreadcrumbService,
    AppUpdateService,
    ScrollManagerService,
    ValidatorsService,
    LoggingService,
    {
      provide: ErrorHandler,
      useClass: TmsErrorHandler,
    },
    RequestCacheService,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
