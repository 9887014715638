import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantBillingService } from '../../../../services/tenant-billing.service';
import { NotificationsService } from '../../../components/notifications/notifications.service';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'app-success',
  template: '',
})
export class SuccessComponent implements OnInit {
  sessionId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: NotificationsService,
    private billing: TenantBillingService
  ) {}

  ngOnInit() {
    const sessionId = this.route.snapshot.queryParamMap.get('sessionId');
    if (!sessionId) {
      this.notify.error('Missing URL Parameter', 'sessionId parameter was expected');
      return;
    }

    this.billing.checkout(sessionId).then(() => {
      this.router.navigate(['/' + AppConfig.routes.dashboard]);
    });
  }
}
