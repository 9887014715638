import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from '../../shared/modules/primeng.module';
import { SharedModule } from '../../shared/modules/shared.module';

import { CompanyRoutingModule } from './company-routing.module';
import { CompanyEditComponent } from './edit/edit.component';
import { AddressListComponent } from './address-list/address-list.component';
import { CompanyListComponent } from './list/list.component';
import { BulkimportComponent } from './bulkimport/bulkimport.component';
import { AddressComponent } from './address/address.component';
import { CompanyAddressMatchingComponent } from './company-address-matching/company-address-matching.component';
import { CompanyAddressMatchingDisplayComponent } from './company-address-matching/company-address-matching-display/company-address-matching-display.component';
import { OperatingHoursComponent } from './operating-hours/operating-hours.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PrimeNgModule, SharedModule, CompanyRoutingModule],
  declarations: [
    CompanyListComponent,
    CompanyEditComponent,
    AddressListComponent,
    AddressComponent,
    BulkimportComponent,
    CompanyAddressMatchingComponent,
    CompanyAddressMatchingDisplayComponent,
    OperatingHoursComponent,
  ],
})
export class CompanyModule {}
