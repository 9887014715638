/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoadSummaryDTO } from '../models/load-summary-dto';
import { LoadDTO } from '../models/load-dto';
import { LoadDriverSummaryDTO } from '../models/load-driver-summary-dto';
import { LoadAttachmentDTO } from '../models/load-attachment-dto';
import { UploadFileDTO } from '../models/upload-file-dto';
import { SendFileDTO } from '../models/send-file-dto';
@Injectable({
  providedIn: 'root',
})
class LoadService extends __BaseService {
  static readonly ApiLoadGetPath = '/api/Load';
  static readonly ApiLoadPostPath = '/api/Load';
  static readonly ApiLoadFilterGetPath = '/api/Load/filter';
  static readonly ApiLoadByDriverByDriverIdGetPath = '/api/Load/ByDriver/{driverId}';
  static readonly ApiLoadExportGetPath = '/api/Load/export';
  static readonly ApiLoadByIdGetPath = '/api/Load/{id}';
  static readonly ApiLoadByIdPutPath = '/api/Load/{id}';
  static readonly ApiLoadByIdDeletePath = '/api/Load/{id}';
  static readonly ApiLoadGetForDriverByIdByDriverIdGetPath = '/api/Load/GetForDriver/{id}/{driverId}';
  static readonly ApiLoadByLoadIdAttachmentsGetPath = '/api/Load/{loadId}/attachments';
  static readonly ApiLoadByLoadIdAttachmentsPostPath = '/api/Load/{loadId}/attachments';
  static readonly ApiLoadAttachmentsByIdGetPath = '/api/Load/attachments/{id}';
  static readonly ApiLoadAttachmentsByIdDeletePath = '/api/Load/attachments/{id}';
  static readonly ApiLoadByLoadIdPdfattachmentsByFileTypePostPath = '/api/Load/{loadId}/pdfattachments/{fileType}';
  static readonly ApiLoadAttachmentsSendPostPath = '/api/Load/attachments/send';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of loads
   * @param Authorization Bearer {token}
   * @return Collection of loads for tenant
   */
  ApiLoadGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<LoadSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadSummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of loads
   * @param Authorization Bearer {token}
   * @return Collection of loads for tenant
   */
  ApiLoadGet(Authorization: string): __Observable<Array<LoadSummaryDTO>> {
    return this.ApiLoadGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<LoadSummaryDTO>)
    );
  }

  /**
   * Create a new load
   * @param params The `LoadService.ApiLoadPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiLoadPostResponse(params: LoadService.ApiLoadPostParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Create a new load
   * @param params The `LoadService.ApiLoadPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiLoadPost(params: LoadService.ApiLoadPostParams): __Observable<LoadDTO> {
    return this.ApiLoadPostResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Filtered Summary list of loads
   * - Blank or null values will be excluded from filter.
   * @param params The `LoadService.ApiLoadFilterGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return Filtered Collection of loads for tenant
   */
  ApiLoadFilterGetResponse(params: LoadService.ApiLoadFilterGetParams): __Observable<__StrictHttpResponse<Array<LoadSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.workOrder != null) __params = __params.set('workOrder', params.workOrder.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.shipperContains != null) __params = __params.set('shipperContains', params.shipperContains.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    if (params.needsAttention != null) __params = __params.set('needsAttention', params.needsAttention.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    if (params.invoiceUtcEnd != null) __params = __params.set('invoiceUtcEnd', params.invoiceUtcEnd.toString());
    if (params.invoiceUtcBegin != null) __params = __params.set('invoiceUtcBegin', params.invoiceUtcBegin.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    (params.driverIdEquals || []).forEach(val => {if (val != null) __params = __params.append('driverIdEquals', val.toString())});
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.dispatcherContains != null) __params = __params.set('dispatcherContains', params.dispatcherContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    if (params.customerContains != null) __params = __params.set('customerContains', params.customerContains.toString());
    if (params.consigneeContains != null) __params = __params.set('consigneeContains', params.consigneeContains.toString());
    if (params.accountingExported != null) __params = __params.set('accountingExported', params.accountingExported.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadSummaryDTO>>;
      })
    );
  }
  /**
   * Filtered Summary list of loads
   * - Blank or null values will be excluded from filter.
   * @param params The `LoadService.ApiLoadFilterGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return Filtered Collection of loads for tenant
   */
  ApiLoadFilterGet(params: LoadService.ApiLoadFilterGetParams): __Observable<Array<LoadSummaryDTO>> {
    return this.ApiLoadFilterGetResponse(params).pipe(
      __map(_r => _r.body as Array<LoadSummaryDTO>)
    );
  }

  /**
   * Driver summary list of loads
   * @param params The `LoadService.ApiLoadByDriverByDriverIdGetParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Collection of loads for driver
   */
  ApiLoadByDriverByDriverIdGetResponse(params: LoadService.ApiLoadByDriverByDriverIdGetParams): __Observable<__StrictHttpResponse<Array<LoadDriverSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/ByDriver/${encodeURIComponent(String(params.driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadDriverSummaryDTO>>;
      })
    );
  }
  /**
   * Driver summary list of loads
   * @param params The `LoadService.ApiLoadByDriverByDriverIdGetParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Collection of loads for driver
   */
  ApiLoadByDriverByDriverIdGet(params: LoadService.ApiLoadByDriverByDriverIdGetParams): __Observable<Array<LoadDriverSummaryDTO>> {
    return this.ApiLoadByDriverByDriverIdGetResponse(params).pipe(
      __map(_r => _r.body as Array<LoadDriverSummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /load/filter
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `LoadService.ApiLoadExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return A CSV file
   */
  ApiLoadExportGetResponse(params: LoadService.ApiLoadExportGetParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.workOrder != null) __params = __params.set('workOrder', params.workOrder.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.shipperContains != null) __params = __params.set('shipperContains', params.shipperContains.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    if (params.needsAttention != null) __params = __params.set('needsAttention', params.needsAttention.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    if (params.invoiceUtcEnd != null) __params = __params.set('invoiceUtcEnd', params.invoiceUtcEnd.toString());
    if (params.invoiceUtcBegin != null) __params = __params.set('invoiceUtcBegin', params.invoiceUtcBegin.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    (params.driverIdEquals || []).forEach(val => {if (val != null) __params = __params.append('driverIdEquals', val.toString())});
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.dispatcherContains != null) __params = __params.set('dispatcherContains', params.dispatcherContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    if (params.customerContains != null) __params = __params.set('customerContains', params.customerContains.toString());
    if (params.consigneeContains != null) __params = __params.set('consigneeContains', params.consigneeContains.toString());
    if (params.accountingExported != null) __params = __params.set('accountingExported', params.accountingExported.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /load/filter
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `LoadService.ApiLoadExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return A CSV file
   */
  ApiLoadExportGet(params: LoadService.ApiLoadExportGetParams): __Observable<Blob> {
    return this.ApiLoadExportGetResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Load detail
   * @param params The `LoadService.ApiLoadByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  ApiLoadByIdGetResponse(params: LoadService.ApiLoadByIdGetParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Load detail
   * @param params The `LoadService.ApiLoadByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  ApiLoadByIdGet(params: LoadService.ApiLoadByIdGetParams): __Observable<LoadDTO> {
    return this.ApiLoadByIdGetResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Update an existing load
   * @param params The `LoadService.ApiLoadByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiLoadByIdPutResponse(params: LoadService.ApiLoadByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing load
   * @param params The `LoadService.ApiLoadByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiLoadByIdPut(params: LoadService.ApiLoadByIdPutParams): __Observable<null> {
    return this.ApiLoadByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `LoadService.ApiLoadByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiLoadByIdDeleteResponse(params: LoadService.ApiLoadByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `LoadService.ApiLoadByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiLoadByIdDelete(params: LoadService.ApiLoadByIdDeleteParams): __Observable<null> {
    return this.ApiLoadByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Load detail for a driver
   * @param params The `LoadService.ApiLoadGetForDriverByIdByDriverIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  ApiLoadGetForDriverByIdByDriverIdGetResponse(params: LoadService.ApiLoadGetForDriverByIdByDriverIdGetParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/GetForDriver/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Load detail for a driver
   * @param params The `LoadService.ApiLoadGetForDriverByIdByDriverIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  ApiLoadGetForDriverByIdByDriverIdGet(params: LoadService.ApiLoadGetForDriverByIdByDriverIdGetParams): __Observable<LoadDTO> {
    return this.ApiLoadGetForDriverByIdByDriverIdGetResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Get a list of attachments for the load
   * @param params The `LoadService.ApiLoadByLoadIdAttachmentsGetParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiLoadByLoadIdAttachmentsGetResponse(params: LoadService.ApiLoadByLoadIdAttachmentsGetParams): __Observable<__StrictHttpResponse<Array<LoadAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadAttachmentDTO>>;
      })
    );
  }
  /**
   * Get a list of attachments for the load
   * @param params The `LoadService.ApiLoadByLoadIdAttachmentsGetParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiLoadByLoadIdAttachmentsGet(params: LoadService.ApiLoadByLoadIdAttachmentsGetParams): __Observable<Array<LoadAttachmentDTO>> {
    return this.ApiLoadByLoadIdAttachmentsGetResponse(params).pipe(
      __map(_r => _r.body as Array<LoadAttachmentDTO>)
    );
  }

  /**
   * Upload a load attachment
   * @param params The `LoadService.ApiLoadByLoadIdAttachmentsPostParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * @return Success
   */
  ApiLoadByLoadIdAttachmentsPostResponse(params: LoadService.ApiLoadByLoadIdAttachmentsPostParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a load attachment
   * @param params The `LoadService.ApiLoadByLoadIdAttachmentsPostParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * @return Success
   */
  ApiLoadByLoadIdAttachmentsPost(params: LoadService.ApiLoadByLoadIdAttachmentsPostParams): __Observable<LoadAttachmentDTO> {
    return this.ApiLoadByLoadIdAttachmentsPostResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Load attachment detail
   * @param params The `LoadService.ApiLoadAttachmentsByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load attachment
   */
  ApiLoadAttachmentsByIdGetResponse(params: LoadService.ApiLoadAttachmentsByIdGetParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Load attachment detail
   * @param params The `LoadService.ApiLoadAttachmentsByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load attachment
   */
  ApiLoadAttachmentsByIdGet(params: LoadService.ApiLoadAttachmentsByIdGetParams): __Observable<Blob> {
    return this.ApiLoadAttachmentsByIdGetResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `LoadService.ApiLoadAttachmentsByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiLoadAttachmentsByIdDeleteResponse(params: LoadService.ApiLoadAttachmentsByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Load/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `LoadService.ApiLoadAttachmentsByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiLoadAttachmentsByIdDelete(params: LoadService.ApiLoadAttachmentsByIdDeleteParams): __Observable<null> {
    return this.ApiLoadAttachmentsByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upload a load attachment as PDF by providing a list of images to be stitched together
   * @param params The `LoadService.ApiLoadByLoadIdPdfattachmentsByFileTypePostParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `Authorization`: Bearer {token}
   *
   * - `files`: The uploaded files
   *
   * @return Success
   */
  ApiLoadByLoadIdPdfattachmentsByFileTypePostResponse(params: LoadService.ApiLoadByLoadIdPdfattachmentsByFileTypePostParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.files;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/pdfattachments/${encodeURIComponent(String(params.fileType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a load attachment as PDF by providing a list of images to be stitched together
   * @param params The `LoadService.ApiLoadByLoadIdPdfattachmentsByFileTypePostParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `Authorization`: Bearer {token}
   *
   * - `files`: The uploaded files
   *
   * @return Success
   */
  ApiLoadByLoadIdPdfattachmentsByFileTypePost(params: LoadService.ApiLoadByLoadIdPdfattachmentsByFileTypePostParams): __Observable<LoadAttachmentDTO> {
    return this.ApiLoadByLoadIdPdfattachmentsByFileTypePostResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Sends attachments to email recipients
   * @param params The `LoadService.ApiLoadAttachmentsSendPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiLoadAttachmentsSendPostResponse(params: LoadService.ApiLoadAttachmentsSendPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/attachments/send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends attachments to email recipients
   * @param params The `LoadService.ApiLoadAttachmentsSendPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiLoadAttachmentsSendPost(params: LoadService.ApiLoadAttachmentsSendPostParams): __Observable<null> {
    return this.ApiLoadAttachmentsSendPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LoadService {

  /**
   * Parameters for ApiLoadPost
   */
  export interface ApiLoadPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: LoadDTO;
  }

  /**
   * Parameters for ApiLoadFilterGet
   */
  export interface ApiLoadFilterGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    workOrder?: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive'>;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingEnd?: string;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingBegin?: string;
    shipperContains?: string;
    poNumber?: string;
    needsAttention?: boolean;
    loadIdEquals?: Array<number>;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcEnd?: string;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcBegin?: string;
    invoiceNumber?: number;
    driverIdEquals?: Array<number>;
    driverContains?: string;
    dispatcherContains?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryEnd?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryBegin?: string;
    customerContains?: string;
    consigneeContains?: string;
    accountingExported?: boolean;
  }

  /**
   * Parameters for ApiLoadByDriverByDriverIdGet
   */
  export interface ApiLoadByDriverByDriverIdGetParams {
    driverId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadExportGet
   */
  export interface ApiLoadExportGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    workOrder?: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive'>;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingEnd?: string;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingBegin?: string;
    shipperContains?: string;
    poNumber?: string;
    needsAttention?: boolean;
    loadIdEquals?: Array<number>;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcEnd?: string;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcBegin?: string;
    invoiceNumber?: number;
    driverIdEquals?: Array<number>;
    driverContains?: string;
    dispatcherContains?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryEnd?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryBegin?: string;
    customerContains?: string;
    consigneeContains?: string;
    accountingExported?: boolean;
  }

  /**
   * Parameters for ApiLoadByIdGet
   */
  export interface ApiLoadByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadByIdPut
   */
  export interface ApiLoadByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: LoadDTO;
  }

  /**
   * Parameters for ApiLoadByIdDelete
   */
  export interface ApiLoadByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadGetForDriverByIdByDriverIdGet
   */
  export interface ApiLoadGetForDriverByIdByDriverIdGetParams {
    id: number;
    driverId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadByLoadIdAttachmentsGet
   */
  export interface ApiLoadByLoadIdAttachmentsGetParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadByLoadIdAttachmentsPost
   */
  export interface ApiLoadByLoadIdAttachmentsPostParams {

    /**
     * Valid load id
     */
    loadId: number;

    /**
     * Upload File
     */
    file: Blob;

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
     */
    fileType?: 'Other' | 'Invoice' | 'InvoicePreview' | 'Dispatch' | 'WorkOrder' | 'BOL' | 'POD' | 'RCS' | 'SignedRCS' | 'Claim';
  }

  /**
   * Parameters for ApiLoadAttachmentsByIdGet
   */
  export interface ApiLoadAttachmentsByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadAttachmentsByIdDelete
   */
  export interface ApiLoadAttachmentsByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiLoadByLoadIdPdfattachmentsByFileTypePost
   */
  export interface ApiLoadByLoadIdPdfattachmentsByFileTypePostParams {

    /**
     * Valid load id
     */
    loadId: number;

    /**
     * Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
     */
    fileType: 'Other' | 'Invoice' | 'InvoicePreview' | 'Dispatch' | 'WorkOrder' | 'BOL' | 'POD' | 'RCS' | 'SignedRCS' | 'Claim';

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * The uploaded files
     */
    files?: Array<UploadFileDTO>;
  }

  /**
   * Parameters for ApiLoadAttachmentsSendPost
   */
  export interface ApiLoadAttachmentsSendPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: SendFileDTO;
  }
}

export { LoadService }
