import { Component, forwardRef, EventEmitter, Output, Injector, Input, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HandleErrorBase } from '../../shared/HandleErrorBase';
import { IdNamePairDTO } from 'src/apiclient/v1.1/models';

/**
 * Uses ChangeDetectionStrategy.OnPush b/c change is propagated via event rather than change detection
 */
@Component({
  selector: 'app-old-equipment-type-selector',
  templateUrl: './equipment-type-selector.component.html',
  styleUrls: ['./equipment-type-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OLDEquipmentTypeSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OLDEquipmentTypeSelectorComponent extends HandleErrorBase implements ControlValueAccessor {
  @Input() formControlName: string;
  @Input() viewMode: string;
  @Input() readonly: boolean = false;
  @Input() set equipmentOptions(equipmentOptions: IdNamePairDTO[]) {
    this._equipmentOptions = equipmentOptions;

    this.writeValue(this.selectedId);
  }
  get equipmentOptions(): IdNamePairDTO[] {
    return this._equipmentOptions;
  }

  @Output() equipmentChanged = new EventEmitter<IdNamePairDTO>();
  @Output() equipmentBlur = new EventEmitter<IdNamePairDTO>();

  public selectedEquipment: IdNamePairDTO = <IdNamePairDTO>{};

  private selectedId: number;
  private _equipmentOptions: IdNamePairDTO[] = [];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public writeValue(equipmentId: number): void {
    this.selectedId = equipmentId;

    if (this.isSelectedIdValid() && this.equipmentOptions) {
      const item = this.equipmentOptions.find((x) => x.id === this.selectedId);

      if (item) {
        this.selectedEquipment = item;
        return;
      }
    }

    this.selectedEquipment = {} as IdNamePairDTO;
  }

  public onChange(event: any): void {
    this.selectedEquipment = event.value;
    this.valueChanged();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  public onBlur(): void {
    this.equipmentBlur.emit(this.selectedEquipment);
  }

  public displayValue(): string {
    const found = this.equipmentOptions.find((x) => x.id === this.selectedEquipment.id);
    return found ? found.name : '-';
  }

  private propagateChange = (_: any) => {};

  private valueChanged(): void {
    this.propagateChange(this.selectedId);
    this.equipmentChanged.emit(this.selectedEquipment);
  }

  private isSelectedIdValid(): boolean {
    return !!this.selectedId && this.selectedId > 0;
  }
}
