import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'titleCase' })
export class TitleCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input || input === '') {
      return input;
    } else {
      return _.startCase(input);
    }
  }
}
