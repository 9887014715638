/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TenantSummaryDTO } from '../models/tenant-summary-dto';
import { TenantDTO } from '../models/tenant-dto';
import { NoteDTO } from '../models/note-dto';
@Injectable({
  providedIn: 'root',
})
class AllTenantService extends __BaseService {
  static readonly ApiAllTenantGetPath = '/api/AllTenant';
  static readonly ApiAllTenantExportGetPath = '/api/AllTenant/export';
  static readonly ApiAllTenantByIdGetPath = '/api/AllTenant/{id}';
  static readonly ApiAllTenantByIdPutPath = '/api/AllTenant/{id}';
  static readonly ApiAllTenantSetStatusByStatusPostPath = '/api/AllTenant/SetStatus/{status}';
  static readonly ApiAllTenantByIdNotesByNoteTypeGetPath = '/api/AllTenant/{id}/notes/{noteType}';
  static readonly ApiAllTenantByIdNotesPostPath = '/api/AllTenant/{id}/notes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Filtered list of Tenants
   * - Blank or null values will be excluded from filter.
   * @param params The `AllTenantService.ApiAllTenantGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return Success
   */
  ApiAllTenantGetResponse(params: AllTenantService.ApiAllTenantGetParams): __Observable<__StrictHttpResponse<Array<TenantSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.nameContains != null) __params = __params.set('nameContains', params.nameContains.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantSummaryDTO>>;
      })
    );
  }
  /**
   * Filtered list of Tenants
   * - Blank or null values will be excluded from filter.
   * @param params The `AllTenantService.ApiAllTenantGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return Success
   */
  ApiAllTenantGet(params: AllTenantService.ApiAllTenantGetParams): __Observable<Array<TenantSummaryDTO>> {
    return this.ApiAllTenantGetResponse(params).pipe(
      __map(_r => _r.body as Array<TenantSummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /AllTenant
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `AllTenantService.ApiAllTenantExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return A CSV file
   */
  ApiAllTenantExportGetResponse(params: AllTenantService.ApiAllTenantExportGetParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.nameContains != null) __params = __params.set('nameContains', params.nameContains.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /AllTenant
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `AllTenantService.ApiAllTenantExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return A CSV file
   */
  ApiAllTenantExportGet(params: AllTenantService.ApiAllTenantExportGetParams): __Observable<Blob> {
    return this.ApiAllTenantExportGetResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get tenant by Id
   * @param params The `AllTenantService.ApiAllTenantByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllTenantByIdGetResponse(params: AllTenantService.ApiAllTenantByIdGetParams): __Observable<__StrictHttpResponse<TenantDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantDTO>;
      })
    );
  }
  /**
   * Get tenant by Id
   * @param params The `AllTenantService.ApiAllTenantByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllTenantByIdGet(params: AllTenantService.ApiAllTenantByIdGetParams): __Observable<TenantDTO> {
    return this.ApiAllTenantByIdGetResponse(params).pipe(
      __map(_r => _r.body as TenantDTO)
    );
  }

  /**
   * Update a tenant
   * @param params The `AllTenantService.ApiAllTenantByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiAllTenantByIdPutResponse(params: AllTenantService.ApiAllTenantByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update a tenant
   * @param params The `AllTenantService.ApiAllTenantByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiAllTenantByIdPut(params: AllTenantService.ApiAllTenantByIdPutParams): __Observable<null> {
    return this.ApiAllTenantByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Set Tenant Status
   * @param params The `AllTenantService.ApiAllTenantSetStatusByStatusPostParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ApiAllTenantSetStatusByStatusPostResponse(params: AllTenantService.ApiAllTenantSetStatusByStatusPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.id || []).forEach(val => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllTenant/SetStatus/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set Tenant Status
   * @param params The `AllTenantService.ApiAllTenantSetStatusByStatusPostParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ApiAllTenantSetStatusByStatusPost(params: AllTenantService.ApiAllTenantSetStatusByStatusPostParams): __Observable<null> {
    return this.ApiAllTenantSetStatusByStatusPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get tenant notes by id
   * @param params The `AllTenantService.ApiAllTenantByIdNotesByNoteTypeGetParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllTenantByIdNotesByNoteTypeGetResponse(params: AllTenantService.ApiAllTenantByIdNotesByNoteTypeGetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}/notes/${encodeURIComponent(String(params.noteType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get tenant notes by id
   * @param params The `AllTenantService.ApiAllTenantByIdNotesByNoteTypeGetParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiAllTenantByIdNotesByNoteTypeGet(params: AllTenantService.ApiAllTenantByIdNotesByNoteTypeGetParams): __Observable<string> {
    return this.ApiAllTenantByIdNotesByNoteTypeGetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Set notes for any tenant
   * @param params The `AllTenantService.ApiAllTenantByIdNotesPostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `notes`:
   */
  ApiAllTenantByIdNotesPostResponse(params: AllTenantService.ApiAllTenantByIdNotesPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.notes;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set notes for any tenant
   * @param params The `AllTenantService.ApiAllTenantByIdNotesPostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `notes`:
   */
  ApiAllTenantByIdNotesPost(params: AllTenantService.ApiAllTenantByIdNotesPostParams): __Observable<null> {
    return this.ApiAllTenantByIdNotesPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AllTenantService {

  /**
   * Parameters for ApiAllTenantGet
   */
  export interface ApiAllTenantGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    statusEquals?: Array<'Pending' | 'Active' | 'Inactive' | 'Deleted'>;
    nameContains?: string;
    isDeleted?: boolean;
  }

  /**
   * Parameters for ApiAllTenantExportGet
   */
  export interface ApiAllTenantExportGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    statusEquals?: Array<'Pending' | 'Active' | 'Inactive' | 'Deleted'>;
    nameContains?: string;
    isDeleted?: boolean;
  }

  /**
   * Parameters for ApiAllTenantByIdGet
   */
  export interface ApiAllTenantByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllTenantByIdPut
   */
  export interface ApiAllTenantByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: TenantDTO;
  }

  /**
   * Parameters for ApiAllTenantSetStatusByStatusPost
   */
  export interface ApiAllTenantSetStatusByStatusPostParams {
    status: 'Pending' | 'Active' | 'Inactive' | 'Deleted';

    /**
     * Bearer {token}
     */
    Authorization: string;
    id?: Array<number>;
  }

  /**
   * Parameters for ApiAllTenantByIdNotesByNoteTypeGet
   */
  export interface ApiAllTenantByIdNotesByNoteTypeGetParams {
    noteType: string;
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiAllTenantByIdNotesPost
   */
  export interface ApiAllTenantByIdNotesPostParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    notes?: NoteDTO;
  }
}

export { AllTenantService }
