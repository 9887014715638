import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ClientTokenService } from '../../../../services/client-token-service';
import { NoteType } from '../../../data/static-data';
import { AllTenantService, TenantService } from 'src/apiclient/v1.1/services';
import { EMPTY, Observable } from 'rxjs';
import { TenantDTO } from 'src/apiclient/v1.1/models';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';

@Component({
  selector: 'app-main-settings',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Input() isSystemAdminView: boolean = false;
  @Input() tenantId: number;
  @Input() isSuperAdmin: boolean = false;
  public readonly noteTypes = NoteType;
  public tabIndex: number = 0;
  public tenant$: Observable<TenantDTO>;
  private tenantName: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public clientToken: ClientTokenService,
    private tenantService: TenantService,
    private allTenantService: AllTenantService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.tenant$ = this.getTenant();
  }

  public handleTabChange(event: { index: number }, tenant: TenantDTO): void {
    this.setBreadCrumb(event.index, tenant);
  }

  private setBreadCrumb(index: number, tenant: TenantDTO): void {
    const labels = this.getTabsForTenant(tenant);
    const page = this.isSystemAdminView ? 'admin-tenant' : 'settings';
    const currentLabel = labels[index];
    if (currentLabel) {
      this.breadcrumbService.setItems(page, labels[index], undefined, this.tenantName);
    }
  }

  private getTenant(): Observable<TenantDTO> {
    const params: AllTenantService.GetTenantByIdParams = {
      id: this.tenantId,
      Authorization: this.clientToken.auth(),
    };
    const getTenant$ = this.isSuperAdmin
      ? this.allTenantService.GetTenantById(params)
      : this.tenantService.Get(params.Authorization);
    return getTenant$.pipe(
      tap((tenant) => {
        this.tenantName = tenant.name;
        this.setBreadCrumb(this.tabIndex, tenant);
      }),
      catchError(() => this.handleError())
    );
  }

  private handleError(): Observable<never> {
    this.notificationsService.error('Tenant could not be loaded', 'Tenant could not be loaded or does not exist');
    const route = this.isSystemAdminView ? ['admin/tenants'] : ['/'];
    this.router.navigate(route);
    return EMPTY;
  }

  private getTabsForTenant(tenant: TenantDTO): string[] {
    const logisticsTabs = ['Accounting'];
    const labels = ['Company', 'Accounting', 'Users', 'Notes', 'Tenant Notes'];

    if (!tenant.isLogistics) {
      logisticsTabs.forEach((tab) => {
        const accountingIndex = labels.indexOf(tab, 0);
        labels.splice(accountingIndex, 1);
      });
    }

    return labels;
  }
}
