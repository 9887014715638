import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AddressDTO, LinkDebtorDTO, MasterDebtor, SubDebtorLinkedDTO } from 'src/apiclient/v1.1/models';
import { SubDebtorService } from 'src/apiclient/v1.1/services';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { DisplayBillToCompanyDTO } from 'src/app/shared/interfaces/DisplayBillToCompanyDTO';

@Component({
  selector: 'app-bill-to-link-debtor',
  templateUrl: './bill-to-link-debtor.component.html',
  styleUrls: ['./bill-to-link-debtor.component.scss'],
})
export class BillToLinkDebtorComponent extends DtoFormBase<LinkDebtorDTO> implements OnInit, OnDestroy {
  @Input() masterDebtor: MasterDebtor;
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() newBillToCreated = new EventEmitter<DisplayBillToCompanyDTO>();

  public saveInProgress: boolean = false;

  private destroy$ = new Subject();

  constructor(protected injector: Injector, private subDebtorService: SubDebtorService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('LinkDebtorDTO');
    // At the time of writing this, initFormFor does not support 'ignoring' dto fields.
    this.formGroup.removeControl('masterDebtorId');
    this.initializeFormValues();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onClose(): void {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  public onSave(): void {
    if (this.isFormValidForSave()) {
      this.buildModel();
      this.saveInProgress = true;
      this.subDebtorService
        .Link({
          Authorization: this.clientToken.auth(),
          body: this.model,
        })
        .pipe(
          catchError((error) => this.handleError(error)),
          takeUntil(this.destroy$)
        )
        .subscribe((result) => this.handleSubDebtorLinkResult(result));
    }
  }

  public isBillToRoleValid(): boolean {
    return (
      this.formGroup.get('isBillTo').value ||
      this.formGroup.get('isShipper').value ||
      this.formGroup.get('isConsignee').value
    );
  }

  public showBillToRoleValidation(): boolean {
    return (
      this.formGroup.controls['isBillTo'].touched ||
      this.formGroup.controls['isShipper'].touched ||
      this.formGroup.controls['isConsignee'].touched
    );
  }

  private initializeFormValues(): void {
    this.formGroup.patchValue({
      phoneNumber: this.masterDebtor.phone,
      email: this.masterDebtor.email,
      isBillTo: true,
    });
  }

  private buildModel(): void {
    this.model = this.formGroup.value as LinkDebtorDTO;
    this.model.masterDebtorId = this.masterDebtor.masterDebtorId;
    this.model.isBillTo = !!this.model.isBillTo;
    this.model.isConsignee = !!this.model.isConsignee;
    this.model.isShipper = !!this.model.isShipper;
  }

  private handleSubDebtorLinkResult(subDebtorLinked: SubDebtorLinkedDTO): void {
    this.saveInProgress = false;
    const newBillTo: DisplayBillToCompanyDTO = {
      address: {
        line1: subDebtorLinked.subDebtor.address1,
        line2: subDebtorLinked.subDebtor.address2,
        city: subDebtorLinked.subDebtor.city,
        state: subDebtorLinked.subDebtor.state,
        zip: subDebtorLinked.subDebtor.zipCode,
      } as AddressDTO,
      buyNoBuy: !!subDebtorLinked.subDebtor.buyStatus,
      masterDebtorId: subDebtorLinked.subDebtor.masterDebtorId,
      companyAddressId: subDebtorLinked.companyAddress.companyAddressId,
      companyName: subDebtorLinked.companyAddress.name,
      companyDisplayName: `${subDebtorLinked.subDebtor.name} - ${subDebtorLinked.subDebtor.city}, ${subDebtorLinked.subDebtor.state}`,
    };

    this.onClose();
    this.newBillToCreated.emit(newBillTo);
  }

  private isFormValidForSave(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formValid() && this.isBillToRoleValid();
  }
}
