<p-dialog
  header="Matching Records Found"
  [visible]="true"
  [style]="{ width: '35vw', heigh: '50vh' }"
  [closable]="false"
  [focusOnShow]="false"
>
  <p class="dialog-message"
    >Please select an existing record you would like to use or continue to create a new record</p
  >
  <div>
    <div *ngFor="let match of matchingResults" class="matching-record" (click)="matchSelectedEvent(match)">
      <div class="status-icon-container">
        <img class="status-icon" [src]="match.buyStatus ? buyIconPath : noBuyIconPath" />
      </div>
      <div class="ui-g-12">
        <div class="match-header ui-g-12 ui-g-nopad">
          <div class="ui-g-8">
            <p class="match-header-text"
              ><strong>{{ match.companyName }} - {{ match.address.city }}, {{ match.address.state }}</strong></p
            >
          </div>
          <div class="ui-g-4 match-role">
            <ul>
              <li *ngIf="match.isBillTo"><strong>Bill To</strong></li>
              <li *ngIf="match.isConsignee"><strong>Consignee</strong></li>
              <li *ngIf="match.isShipper"><strong>Shipper</strong></li>
            </ul>
          </div>
        </div>
        <div class="match-content ui-g-12 ui-g-nopad">
          <div class="ui-g-6 ui-g-nopad">
            <p class="match-content-text">{{ getMatchStreetAddress(match) }}</p>
            <p class="match-content-text"
              >{{ match.address.city }}, {{ match.address.state }} {{ match.address.zip }}</p
            >
          </div>
          <div class="ui-g-6 ui-g-nopad match-content-mc-dot">
            <p class="match-content-text">{{ match.mcNumber }}</p>
            <p class="match-content-text">{{ match.dotNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      label="Cancel"
      class="ui-button-secondary"
      icon="fa fa-times"
      (click)="cancel()"
    ></button>
    <button type="button" pButton icon="fa fa-check" label="Continue" (click)="create()"></button>
  </p-footer>
</p-dialog>
