/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoadAttachmentDTO } from '../models/load-attachment-dto';
import { AttachmentDTO } from '../models/attachment-dto';
@Injectable({
  providedIn: 'root',
})
class DocumentsService extends __BaseService {
  static readonly ApiDocumentsDispatchSheetsByLoadIdPostPath = '/api/Documents/DispatchSheets/{loadId}';
  static readonly ApiDocumentsInvoiceByLoadIdPostPath = '/api/Documents/Invoice/{loadId}';
  static readonly ApiDocumentsInvoiceAcceptByLoadIdPostPath = '/api/Documents/Invoice/Accept/{loadId}';
  static readonly ApiDocumentsDriverPayPostPath = '/api/Documents/DriverPay';
  static readonly ApiDocumentsDriverPayAcceptByAttachmentIdPostPath = '/api/Documents/DriverPay/Accept/{attachmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Generates a dispatch sheets for each load leg. Attaches sheets to load and returns the LoadAttachmentDTO.
   * Use LoadAttachmentDTO download the PDF or email it.
   * NOTE: Only the latest version of the dispatch sheet is kept
   * @param params The `DocumentsService.ApiDocumentsDispatchSheetsByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTOs describing PDF
   */
  ApiDocumentsDispatchSheetsByLoadIdPostResponse(params: DocumentsService.ApiDocumentsDispatchSheetsByLoadIdPostParams): __Observable<__StrictHttpResponse<Array<LoadAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DispatchSheets/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadAttachmentDTO>>;
      })
    );
  }
  /**
   * Generates a dispatch sheets for each load leg. Attaches sheets to load and returns the LoadAttachmentDTO.
   * Use LoadAttachmentDTO download the PDF or email it.
   * NOTE: Only the latest version of the dispatch sheet is kept
   * @param params The `DocumentsService.ApiDocumentsDispatchSheetsByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTOs describing PDF
   */
  ApiDocumentsDispatchSheetsByLoadIdPost(params: DocumentsService.ApiDocumentsDispatchSheetsByLoadIdPostParams): __Observable<Array<LoadAttachmentDTO>> {
    return this.ApiDocumentsDispatchSheetsByLoadIdPostResponse(params).pipe(
      __map(_r => _r.body as Array<LoadAttachmentDTO>)
    );
  }

  /**
   * Generates an invoice preview for a load. Attaches PDF to load and returns the LoadAttachmentDTO.
   * Preview is not visible in the load until it has been accepted
   * Use LoadAttachmentDTO download the PDF or email it.
   * @param params The `DocumentsService.ApiDocumentsInvoiceByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  ApiDocumentsInvoiceByLoadIdPostResponse(params: DocumentsService.ApiDocumentsInvoiceByLoadIdPostParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/Invoice/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Generates an invoice preview for a load. Attaches PDF to load and returns the LoadAttachmentDTO.
   * Preview is not visible in the load until it has been accepted
   * Use LoadAttachmentDTO download the PDF or email it.
   * @param params The `DocumentsService.ApiDocumentsInvoiceByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  ApiDocumentsInvoiceByLoadIdPost(params: DocumentsService.ApiDocumentsInvoiceByLoadIdPostParams): __Observable<LoadAttachmentDTO> {
    return this.ApiDocumentsInvoiceByLoadIdPostResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Accepts an invoice preview. Makes invoice visible in attachments, sets load status as Invoiced.
   * Returns list of attachments to be used for sending to user.
   * @param params The `DocumentsService.ApiDocumentsInvoiceAcceptByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  ApiDocumentsInvoiceAcceptByLoadIdPostResponse(params: DocumentsService.ApiDocumentsInvoiceAcceptByLoadIdPostParams): __Observable<__StrictHttpResponse<Array<LoadAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/Invoice/Accept/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadAttachmentDTO>>;
      })
    );
  }
  /**
   * Accepts an invoice preview. Makes invoice visible in attachments, sets load status as Invoiced.
   * Returns list of attachments to be used for sending to user.
   * @param params The `DocumentsService.ApiDocumentsInvoiceAcceptByLoadIdPostParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  ApiDocumentsInvoiceAcceptByLoadIdPost(params: DocumentsService.ApiDocumentsInvoiceAcceptByLoadIdPostParams): __Observable<Array<LoadAttachmentDTO>> {
    return this.ApiDocumentsInvoiceAcceptByLoadIdPostResponse(params).pipe(
      __map(_r => _r.body as Array<LoadAttachmentDTO>)
    );
  }

  /**
   * Generates an DriverPay preview for a driver.
   * Attaches PDF to driver and returns the TenantUserAttachmentDTO.
   * Use TenantUserAttachmentDTO to download the PDF or email it. Use id to call DriverPay/Accept
   * @param params The `DocumentsService.ApiDocumentsDriverPayPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `periodEnding`:
   *
   * - `loadLegs`:
   *
   * - `fuelDeductionDescription`:
   *
   * - `fuelDeductionAmount`:
   *
   * - `driverId`:
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  ApiDocumentsDriverPayPostResponse(params: DocumentsService.ApiDocumentsDriverPayPostParams): __Observable<__StrictHttpResponse<AttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.periodEnding != null) __params = __params.set('periodEnding', params.periodEnding.toString());
    (params.loadLegs || []).forEach(val => {if (val != null) __params = __params.append('loadLegs', val.toString())});
    if (params.fuelDeductionDescription != null) __params = __params.set('fuelDeductionDescription', params.fuelDeductionDescription.toString());
    if (params.fuelDeductionAmount != null) __params = __params.set('fuelDeductionAmount', params.fuelDeductionAmount.toString());
    if (params.driverId != null) __params = __params.set('driverId', params.driverId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DriverPay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttachmentDTO>;
      })
    );
  }
  /**
   * Generates an DriverPay preview for a driver.
   * Attaches PDF to driver and returns the TenantUserAttachmentDTO.
   * Use TenantUserAttachmentDTO to download the PDF or email it. Use id to call DriverPay/Accept
   * @param params The `DocumentsService.ApiDocumentsDriverPayPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `periodEnding`:
   *
   * - `loadLegs`:
   *
   * - `fuelDeductionDescription`:
   *
   * - `fuelDeductionAmount`:
   *
   * - `driverId`:
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  ApiDocumentsDriverPayPost(params: DocumentsService.ApiDocumentsDriverPayPostParams): __Observable<AttachmentDTO> {
    return this.ApiDocumentsDriverPayPostResponse(params).pipe(
      __map(_r => _r.body as AttachmentDTO)
    );
  }

  /**
   * Accepts the DriverPay preview. Sets driver pay status as Invoiced.
   * Returns attachment to be used for sending to user.
   * @param params The `DocumentsService.ApiDocumentsDriverPayAcceptByAttachmentIdPostParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  ApiDocumentsDriverPayAcceptByAttachmentIdPostResponse(params: DocumentsService.ApiDocumentsDriverPayAcceptByAttachmentIdPostParams): __Observable<__StrictHttpResponse<AttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DriverPay/Accept/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttachmentDTO>;
      })
    );
  }
  /**
   * Accepts the DriverPay preview. Sets driver pay status as Invoiced.
   * Returns attachment to be used for sending to user.
   * @param params The `DocumentsService.ApiDocumentsDriverPayAcceptByAttachmentIdPostParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  ApiDocumentsDriverPayAcceptByAttachmentIdPost(params: DocumentsService.ApiDocumentsDriverPayAcceptByAttachmentIdPostParams): __Observable<AttachmentDTO> {
    return this.ApiDocumentsDriverPayAcceptByAttachmentIdPostResponse(params).pipe(
      __map(_r => _r.body as AttachmentDTO)
    );
  }
}

module DocumentsService {

  /**
   * Parameters for ApiDocumentsDispatchSheetsByLoadIdPost
   */
  export interface ApiDocumentsDispatchSheetsByLoadIdPostParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiDocumentsInvoiceByLoadIdPost
   */
  export interface ApiDocumentsInvoiceByLoadIdPostParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiDocumentsInvoiceAcceptByLoadIdPost
   */
  export interface ApiDocumentsInvoiceAcceptByLoadIdPostParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiDocumentsDriverPayPost
   */
  export interface ApiDocumentsDriverPayPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    periodEnding?: string;
    loadLegs?: Array<number>;
    fuelDeductionDescription?: string;
    fuelDeductionAmount?: number;
    driverId?: number;
  }

  /**
   * Parameters for ApiDocumentsDriverPayAcceptByAttachmentIdPost
   */
  export interface ApiDocumentsDriverPayAcceptByAttachmentIdPostParams {
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { DocumentsService }
