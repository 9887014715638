<p-button
  label="Upload"
  type="button"
  icon="fa fa-upload"
  (onClick)="openDialog()"
  iconPos="left"
  [disabled]="disabled"
></p-button>

<app-document-add-dialog
  *ngIf="dialogVisible"
  [dialogVisible]="dialogVisible"
  [loadId]="loadId"
  (fileUploaded)="fileUploaded()"
  (dialogClosed)="closeDialog()"
>
</app-document-add-dialog>
