<div class="col-12 p-0 flex flex-wrap align-items-center" [formGroup]="formGroup">
  <div class="col-6 p-0 pr-2">
    <input
      #description
      type="text"
      class="col-12"
      pInputText
      placeholder="Description"
      formControlName="description"
      [(ngModel)]="tallyItem.description"
      [readonly]="readonly"
      [tabindex]="readonly ? '-1' : null"
    />
  </div>

  <div class="col-2 p-0">
    <p-calendar
      #date
      placeholder="Date"
      appendTo="body"
      formControlName="date"
      [ngModel]="tallyItem.date | formatDateFromDBString"
      placeholder="mm/dd/yyyy"
      (onSelect)="tallyDateChanged($event)"
      (onInput)="tallyDateChangedByInput($event)"
      (onBlur)="tallyDateBlur($event)"
      showButtonBar="true"
      [showIcon]="true"
      [showOnFocus]="false"
    >
    </p-calendar>
  </div>

  <div class="col-3 p-0">
    <app-form-field
      #amount
      [(formGroup)]="formGroup"
      [config]="{ textIcon: '$' }"
      formControlName="amount"
      [ngModel]="tallyItem.amount | formatCurrency"
      [formErrors]="formErrors"
      (inputChanged)="tallyAmountChanged($event)"
    >
    </app-form-field>
  </div>

  <div class="col-1 p-0">
    <i class="trashcan fas fa-trash-alt fauxlink" (click)="removeTallyItem(tallyItem)" *ngIf="!readonly"></i>
  </div>
</div>
