/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MarketplaceCarrierSearchResultDTO } from '../models/marketplace-carrier-search-result-dto';
import { CarrierQueryDTO } from '../models/carrier-query-dto';
@Injectable({
  providedIn: 'root',
})
class MarketplaceService extends __BaseService {
  static readonly GetCarriersPath = '/api/Marketplace/Carriers';
  static readonly GetCarriersExportPath = '/api/Marketplace/Carriers/export/{offsetHours}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Allows you to query the marketplace for carriers.
   * Returns load information based on criteria with contact information about the carrier
   * @param params The `MarketplaceService.GetCarriersParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Collection of carriers
   */
  GetCarriersResponse(params: MarketplaceService.GetCarriersParams): __Observable<__StrictHttpResponse<MarketplaceCarrierSearchResultDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Marketplace/Carriers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketplaceCarrierSearchResultDTO>;
      })
    );
  }
  /**
   * Allows you to query the marketplace for carriers.
   * Returns load information based on criteria with contact information about the carrier
   * @param params The `MarketplaceService.GetCarriersParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Collection of carriers
   */
  GetCarriers(params: MarketplaceService.GetCarriersParams): __Observable<MarketplaceCarrierSearchResultDTO> {
    return this.GetCarriersResponse(params).pipe(
      __map(_r => _r.body as MarketplaceCarrierSearchResultDTO)
    );
  }

  /**
   * Returns CSV file of data from GET /Marketplace/Carriers
   * Pass in the UTC offset hours so the CSV timestamps will be in local time (-06:00, etc)
   * @param params The `MarketplaceService.GetCarriersExportParams` containing the following parameters:
   *
   * - `offsetHours`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return A CSV file
   */
  GetCarriersExportResponse(params: MarketplaceService.GetCarriersExportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Marketplace/Carriers/export/${encodeURIComponent(String(params.offsetHours))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /Marketplace/Carriers
   * Pass in the UTC offset hours so the CSV timestamps will be in local time (-06:00, etc)
   * @param params The `MarketplaceService.GetCarriersExportParams` containing the following parameters:
   *
   * - `offsetHours`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return A CSV file
   */
  GetCarriersExport(params: MarketplaceService.GetCarriersExportParams): __Observable<Blob> {
    return this.GetCarriersExportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module MarketplaceService {

  /**
   * Parameters for GetCarriers
   */
  export interface GetCarriersParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CarrierQueryDTO;
  }

  /**
   * Parameters for GetCarriersExport
   */
  export interface GetCarriersExportParams {
    offsetHours: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CarrierQueryDTO;
  }
}

export { MarketplaceService }
