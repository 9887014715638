<div [formGroup]="wizardStep.stepForm">
  <div class="grid" *ngIf="!existingUser">
    <h1 class="col-12 form-header">Create Your Account</h1>
    <app-form-field
      class="col-12"
      label="Your Email Address"
      type="text"
      tooltip="This email has already been confirmed and cannot be updated until after the registration is complete."
      labelClass="col-12"
      controlClass="col-12"
      errorClass="col-12"
      [formGroup]="wizardStep.stepForm"
      [formErrors]="wizardStep.formErrors"
      formControlName="email"
    ></app-form-field>
    <app-password-confirmation class="col-12" [formGroup]="wizardStep.stepForm"></app-password-confirmation>
  </div>

  <div class="grid" *ngIf="existingUser" id="existing-user">
    <h1 class="col-12 form-header">Complete Registration</h1>
    <span class="col-12">
      Since you already have an existing account you will continue to sign in with your existing email,
      <strong>{{ tempUser.email }}</strong
      >, and password.
    </span>
    <span class="col-12">
      After you have <span *ngIf="!tempUser.confirmationCode">re-</span>logged in you will be able to switch between
      tenants using the tenant selector in the top right corner of your screen.
    </span>
  </div>
</div>

<app-wizard-navigation
  nextButtonText="Finish"
  [disableNext]="disableFinish()"
  (previousStep)="previous()"
  (nextStep)="next()"
></app-wizard-navigation>
