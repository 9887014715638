import { Component, OnInit, Injector } from '@angular/core';
import { SystemSettingService } from '../../../../apiclient/services';
import { SystemSettingDTO } from '../../../../apiclient/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { ScrollManagerService } from '../../../../services/scroll-manager.service';

@Component({
  selector: 'app-admin-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [SystemSettingService],
})
export class AdminEditComponent extends DtoFormBase<SystemSettingDTO> implements OnInit {
  loading: boolean = true;
  modelOriginal: SystemSettingDTO;
  paymentTermsData: any = [];
  disableSave: boolean = false;

  editing = {
    system: false,
  };

  addressesSame = false;

  constructor(
    private scrollManagerService: ScrollManagerService,
    private systemSettingService: SystemSettingService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.clientToken.hasPermission('SystemSettingEdit') || !this.clientToken.getToken()) {
      this.router.navigate(['/']);
    }

    this.model = {} as SystemSettingDTO;
    this.initFormFor('SystemSettingDTO');
    this.getSystemSettings();

    /*
    this.activatedRoute.params.subscribe(params => {
      this.modelId = +params['id'];
    });
    */
  }

  async getSystemSettings() {
    try {
      this.disableSave = true;
      const data = await this.systemSettingService.ApiSystemSettingGet(this.clientToken.auth()).toPromise();
      this.model = data;
      this.loading = false;
      this.modelOriginal = JSON.parse(JSON.stringify(this.model));
      this.setPaymentTerms();
    } catch (e) {
      this.loading = false;
      this.handleError(e);
    } finally {
      this.disableSave = false;
    }
  }

  setPaymentTerms() {
    for (const paymentTerm of this.model.paymentTermOptions) {
      const paymentTermObj = { label: paymentTerm, value: paymentTerm };
      this.paymentTermsData.push(paymentTermObj);
    }
  }

  reset(section) {
    this.editing[section] = false;
    this.model = JSON.parse(JSON.stringify(this.modelOriginal));
    this.scrollManagerService.resetScreenScroll();
  }

  async saveSystemSettings(section) {
    try {
      this.disableSave = true;
      const data = await this.systemSettingService
        .ApiSystemSettingPut({
          dto: this.model,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();

      this._NS.success('Info updated.', 'Your updates were saved successfully.');
      this.scrollManagerService.resetScreenScroll();
      this.editing[section] = false;
      this.modelOriginal = JSON.parse(JSON.stringify(this.model));
    } catch (e) {
      this.scrollManagerService.resetScreenScroll();
      this.handleError(e);
    } finally {
      this.disableSave = false;
    }
  }
}
