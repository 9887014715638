import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { throwIfAlreadyLoaded } from './module-import-guard';

import { SharedModule } from '../shared/modules/shared.module';
import { RouterModule } from '@angular/router';
import { Error404Component } from './error404/error-404.component';
import { HeaderComponent } from './header/header.component';

import { ValidatorsService } from './validators-service';
import { LoggingService } from './logging.service';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, SharedModule, ReactiveFormsModule],
  exports: [HeaderComponent],
  declarations: [Error404Component, HeaderComponent],
  providers: [LoggingService, ValidatorsService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
