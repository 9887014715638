import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, startWith } from 'rxjs/operators';
import { FactoringAccountExecutiveDTO } from '../../../../apiclient/v1.1/models';
import { AccountExecutivesService } from '../../../../services/account-executives/account-executives.service';
import { TableColumn } from '../../../shared/models/TableColumn';

@Component({
  selector: 'app-account-executives-table',
  templateUrl: './account-executives-table.component.html',
  styleUrls: ['./account-executives-table.component.scss'],
})
export class AccountExecutivesTableComponent implements OnInit {
  public accountExecutives$: Observable<FactoringAccountExecutiveDTO[]>;
  public loading = false;
  public readonly columns: TableColumn[] = [
    {
      header: 'Name',
      field: 'fullName',
      sortable: false,
    },
    {
      header: 'Phone Number',
      field: 'phoneNumber',
      sortable: false,
    },
    {
      header: 'Email',
      field: 'email',
      sortable: false,
    },
  ];

  constructor(private accountExecutivesService: AccountExecutivesService) {}

  public ngOnInit(): void {
    this.accountExecutives$ = this.getAccountExecutives().pipe(startWith([]));
  }

  private getAccountExecutives(): Observable<FactoringAccountExecutiveDTO[]> {
    this.loading = true;
    return this.accountExecutivesService.getAccountExecutives().pipe(finalize(() => (this.loading = false)));
  }
}
