import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantRoutingModule } from './tenant-routing.module';

import { SharedModule } from '../../shared/modules/shared.module';

@NgModule({
  imports: [CommonModule, TenantRoutingModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class TenantModule {}
