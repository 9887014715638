import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio-boolean-input',
  templateUrl: './radio-boolean-input.component.html',
  styleUrls: ['./radio-boolean-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioBooleanInputComponent),
      multi: true,
    },
  ],
})
export class RadioBooleanInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() falseLabel: string = 'No';
  @Input() trueLabel: string = 'Yes';

  public radioValue: boolean = false;
  public disabled: boolean = false;

  public radioClicked(): void {
    this.onChange(this.radioValue);
  }

  public writeValue(value: boolean): void {
    this.radioValue = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange = (value: boolean | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
