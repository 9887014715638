import {
  Component,
  forwardRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injector,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { OtherChargeDTO } from 'src/apiclient/models';

@Component({
  selector: 'app-tallyitem',
  templateUrl: './tallyitem.component.html',
  styleUrls: ['./tallyitem.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TallyitemComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TallyitemComponent extends DtoFormBase<OtherChargeDTO> implements OnInit, ControlValueAccessor, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() tallyItem: OtherChargeDTO;
  @Input() readonly: boolean = false;
  @Input() suppressWarningsForElements: ElementRef[] = [];

  @Output() tallyItemChanged: EventEmitter<OtherChargeDTO> = new EventEmitter<OtherChargeDTO>();
  @Output() tallyItemRemoved: EventEmitter<OtherChargeDTO> = new EventEmitter<OtherChargeDTO>();

  @ViewChild('date', { static: true }) datePicker: any;
  isTyping: boolean;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.initFormFor('OtherChargeDTO', ['date', 'tallyTotalAmount']);
    this.checkAndSetViewState();
  }

  checkAndSetViewState() {
    for (const field in this.formGroup.controls) {
      if (this.readonly) {
        this.formGroup.get(field).disable();
      } else {
        this.formGroup.get(field).enable();
      }
    }
  }

  writeValue(tallyItemObj: OtherChargeDTO): void {
    this.tallyItem = tallyItemObj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.tallyItemModelChanged();
  }

  private propagateChange = (_: any) => {};

  private tallyItemModelChanged() {
    this.propagateChange(this.tallyItem);
    this.tallyItemChanged.emit(this.tallyItem);
  }

  tallyAmountChanged($event) {
    setTimeout((_) => {
      this.tallyItem.amount = $event;
      this.propagateChange(this.tallyItem);
      this.tallyItemChanged.emit(this.tallyItem);
    });
  }

  tallyDateChanged($event) {
    setTimeout((_) => {
      this.tallyItem.date = $event;
      this.propagateChange(this.tallyItem);
      this.tallyItemChanged.emit(this.tallyItem);
    });
  }

  tallyDateChangedByInput($event) {
    if ($event.target.value) {
      /* this.logging.logTrace('*** byInput ***');
      this.logging.logTrace($event.target.value);
      this.logging.logTrace(isNaN(Date.parse($event.target.value)) + '');
      this.logging.logTrace(moment($event.target.value, 'MM/DD/YYYY', true).isValid() + '');
      if (moment($event.target.value, 'MM/DD/YYYY', true).isValid()) {
        this.tallyDateChanged(new Date($event.target.value));
      }*/
      this.isTyping = true;
    }
  }

  tallyDateBlur($event) {
    // prevent the datepicker warnings from displaying focus is still in the calendar widget
    // eg: when the user clicks the calendar button while the input is focused.
    if (
      !this.datePicker.el.nativeElement.contains($event.relatedTarget) &&
      !this.suppressWarningsForElements.some((ref) => ref.nativeElement.contains($event.relatedTarget))
    ) {
      setTimeout((_) => {
        this.tallyItem.date = $event.target.value;
        if ($event.target.value) {
          if (isNaN(Date.parse($event.target.value))) {
            this._NS.warn('Invalid Date', 'Please check the date you are typing.');
          }
          if (!this.tallyItem.date) {
            this._NS.warn('Invalid Date', 'Please check the date you are typing.');
          }
        } else {
          this._NS.warn('Invalid Date', 'Please check the date you are typing.');
          this.tallyDateChanged(null);
        }
      }, 250);
    }
  }

  removeTallyItem(tallyItem) {
    this.tallyItemRemoved.emit(tallyItem);
  }
}
