<div class="grid grid-nogutter col-12 p-2 editSection">
  <div class="col-2 p-2 font-bold"> Subscription </div>
  <div class="col-9 sectionContent pt-0">
    <div read>
      <app-edit-section-row label="Status">
        {{ model.billingStatus | titlecase }}
      </app-edit-section-row>
      <app-edit-section-row label="Billing Period Ends" *ngIf="model.billingPeriodEnds">
        {{ model.billingPeriodEnds ? (model.billingPeriodEnds | date : 'M/d/y') : '' }}
      </app-edit-section-row>
    </div>
  </div>
  <div class="col-1">
    <p-button
      *ngIf="!isSuperAdmin"
      label="Manage"
      type="button"
      (click)="openPortal()"
      [disabled]="loading || isExempt"
    ></p-button>
    <p-button
      *ngIf="isSuperAdmin"
      [label]="isExempt ? 'Un-Exempt' : 'Exempt'"
      type="button"
      (click)="toggleExempt()"
      [disabled]="loading"
    ></p-button>
  </div>
</div>
<p-confirmDialog appendTo="body" key="BillingConfirmation"></p-confirmDialog>
