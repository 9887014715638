import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormSanitizationService {
  constructor() {}

  public sanitizeForm(formGroup: FormGroup, controlsToIgnore: string[] = []): void {
    const rawFormData = formGroup.value;

    Object.keys(rawFormData)
      .filter((controlName) => !controlsToIgnore.includes(controlName))
      .forEach((controlName) => {
        const currentValue = rawFormData[controlName];

        if (currentValue) {
          switch (typeof currentValue) {
            case 'string':
              formGroup.controls[controlName].setValue(currentValue.trim());
              break;
            case 'object':
              formGroup.controls[controlName].setValue(this.sanitizeObject(currentValue));
              break;
          }
        }
      });
  }

  private sanitizeObject(rawObject: object): object {
    Object.keys(rawObject).forEach((propertyName) => {
      const currentValue = rawObject[propertyName];
      if (currentValue && this.isObjectString(currentValue)) {
        rawObject[propertyName] = currentValue.trim();
      }
    });

    return rawObject;
  }

  private isObjectString(value: Object): boolean {
    return typeof value === 'string';
  }
}
