<div [formGroup]="wizardStep.stepForm" class="grid">
  <h1 class="col-12 form-header">Company Set Up</h1>
  <app-form-field
    class="col-12"
    label="Company Legal Name *"
    type="text"
    labelClass="col-12"
    controlClass="col-12"
    errorClass="col-12"
    [formGroup]="wizardStep.stepForm"
    [formErrors]="wizardStep.formErrors"
    formControlName="legalName"
  ></app-form-field>
  <app-phone-number-v2
    class="col-6"
    label="Phone Number *"
    labelClass="col-12"
    controlClass="col-12 p-1"
    errorClass="col-12"
    formControlName="phone"
    [formErrors]="wizardStep.formErrors"
    [touched]="wizardStep.stepForm.controls['phone'].touched"
    [dirty]="wizardStep.stepForm.controls['phone'].dirty"
  ></app-phone-number-v2>
  <app-form-field
    class="col-3"
    label="Extension"
    type="text"
    keyFilter="number"
    labelClass="col-12"
    controlClass="col-12"
    errorClass="col-12"
    [formGroup]="wizardStep.stepForm"
    [formErrors]="wizardStep.formErrors"
    formControlName="phoneExt"
  ></app-form-field>
  <app-form-field
    class="col-3"
    label="Number Of Trucks *"
    type="text"
    keyFilter="number"
    labelClass="col-12"
    controlClass="col-12"
    errorClass="col-12"
    [formGroup]="wizardStep.stepForm"
    [formErrors]="wizardStep.formErrors"
    formControlName="numberOfTrucks"
  ></app-form-field>
  <app-form-field
    class="col-6"
    label="US DOT Number or Equivalent *"
    type="text"
    keyFilter="number"
    labelClass="col-12"
    controlClass="col-12"
    errorClass="col-12"
    [formGroup]="wizardStep.stepForm"
    [formErrors]="wizardStep.formErrors"
    formControlName="usDOTNumber"
  ></app-form-field>
  <app-form-field
    class="col-6"
    label="MC Number"
    type="text"
    keyFilter="number"
    labelClass="col-12"
    controlClass="col-12"
    errorClass="col-12"
    [formGroup]="wizardStep.stepForm"
    [formErrors]="wizardStep.formErrors"
    formControlName="mcNumber"
  ></app-form-field>
</div>

<app-wizard-navigation
  [displayPrevious]="false"
  (previousStep)="previous()"
  (nextStep)="next()"
></app-wizard-navigation>
