import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkplaceRoutingModule } from './workplace-routing.module';
import { WorkplaceComponent } from './workplace.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { LoadModule } from '../load/load.module';
import { DrivercardsComponent } from './drivercards/drivercards.component';
import { LoadcardsComponent } from './loadcards/loadcards.component';

@NgModule({
  imports: [CommonModule, SharedModule, WorkplaceRoutingModule, LoadModule],
  declarations: [WorkplaceComponent, DrivercardsComponent, LoadcardsComponent],
})
export class WorkplaceModule {}
