import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { PdfUtilityComponent } from '../../../../components/pdf-utility/pdf-utility.component';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css'],
})
export class SettlementComponent implements OnInit {
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pdfutil', { static: true }) pdfUtil: PdfUtilityComponent;

  driverId: number;
  legIds: Array<number>;
  showSettlementUI: boolean;
  driverFuelExpenses: number;
  driverFuelExpenseDescription: string;
  periodEnding: string;
  showProgressOverlay: any;

  constructor() {}

  ngOnInit() {}

  doSettlement() {
    this.showSettlementUI = false;
    this.pdfUtil.generateSettlementPDF(
      this.driverId,
      this.legIds,
      this.driverFuelExpenseDescription,
      this.driverFuelExpenses,
      this.periodEnding
    );
  }
  onCloseDialog() {
    this.dialogClosed.emit();
  }

  init(driverID: number, loadLegIds: Array<number>, periodEnding: string) {
    this.driverFuelExpenseDescription = '';
    this.driverFuelExpenses = null;
    this.driverId = driverID;
    this.legIds = loadLegIds;
    this.periodEnding = periodEnding;
    this.showSettlementUI = true;
    setTimeout((_) => {
      const focussedButton = <HTMLElement>document.activeElement;
      focussedButton.blur();
    });
  }
}
