/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AvailableUserRolesDTO } from '../models/available-user-roles-dto';
@Injectable({
  providedIn: 'root',
})
class RoleService extends __BaseService {
  static readonly GetPath = '/api/Role';
  static readonly GetUserRolesPath = '/api/Role/{tenantId}/User';
  static readonly GetTenantRolesPath = '/api/Role/Tenant';
  static readonly GetPermissionsByRolePath = '/api/Role/{role}/Permissions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns names of all the defined roles
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Returns names of all the defined roles
   * @param Authorization Bearer {token}
   * @return Success
   */
  Get(Authorization: string): __Observable<Array<string>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Returns names of all the defined user roles available for a given tenant
   * @param params The `RoleService.GetUserRolesParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetUserRolesResponse(params: RoleService.GetUserRolesParams): __Observable<__StrictHttpResponse<AvailableUserRolesDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/${encodeURIComponent(String(params.tenantId))}/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvailableUserRolesDTO>;
      })
    );
  }
  /**
   * Returns names of all the defined user roles available for a given tenant
   * @param params The `RoleService.GetUserRolesParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetUserRoles(params: RoleService.GetUserRolesParams): __Observable<AvailableUserRolesDTO> {
    return this.GetUserRolesResponse(params).pipe(
      __map(_r => _r.body as AvailableUserRolesDTO)
    );
  }

  /**
   * Returns names of all the defined tenant roles
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetTenantRolesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/Tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Returns names of all the defined tenant roles
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetTenantRoles(Authorization: string): __Observable<Array<string>> {
    return this.GetTenantRolesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Returns list of permissions for a specific role
   * @param params The `RoleService.GetPermissionsByRoleParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetPermissionsByRoleResponse(params: RoleService.GetPermissionsByRoleParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/${encodeURIComponent(String(params.role))}/Permissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Returns list of permissions for a specific role
   * @param params The `RoleService.GetPermissionsByRoleParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetPermissionsByRole(params: RoleService.GetPermissionsByRoleParams): __Observable<Array<string>> {
    return this.GetPermissionsByRoleResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }
}

module RoleService {

  /**
   * Parameters for GetUserRoles
   */
  export interface GetUserRolesParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetPermissionsByRole
   */
  export interface GetPermissionsByRoleParams {
    role: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { RoleService }
