export enum FundingStatusIconColors {
  Green = 'Green',
  GreenOutline = 'GreenOutline',
  Red = 'Red',
  Blue = 'Blue',
  DarkGrey = 'DarkGrey',
  DarkGreyOutline = 'DarkGreyOutline',
  LightGrey = 'LightGrey',
  LightGreyOutline = 'LightGreyOutline',
  Black = 'Black',
}
