/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoadAttachmentDTO } from '../models/load-attachment-dto';
import { InvoiceAcceptedDTO } from '../models/invoice-accepted-dto';
import { DriverPayPreviewDTO } from '../models/driver-pay-preview-dto';
import { AttachmentDTO } from '../models/attachment-dto';
@Injectable({
  providedIn: 'root',
})
class DocumentsService extends __BaseService {
  static readonly DispatchSheetsPath = '/api/Documents/DispatchSheets/{loadId}';
  static readonly InvoicePath = '/api/Documents/Invoice/{loadId}';
  static readonly InvoiceAcceptPath = '/api/Documents/Invoice/Accept/{loadId}';
  static readonly DriverPayPath = '/api/Documents/DriverPay';
  static readonly DriverPayAcceptPath = '/api/Documents/DriverPay/Accept';
  static readonly DriverPayCanceledPath = '/api/Documents/DriverPay/Cancel/{driverSettlementId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Generates a dispatch sheets for each load leg. Attaches sheets to load and returns the LoadAttachmentDTO.
   * Use LoadAttachmentDTO download the PDF or email it.
   * NOTE: Only the latest version of the dispatch sheet is kept
   * @param params The `DocumentsService.DispatchSheetsParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTOs describing PDF
   */
  DispatchSheetsResponse(params: DocumentsService.DispatchSheetsParams): __Observable<__StrictHttpResponse<Array<LoadAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DispatchSheets/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadAttachmentDTO>>;
      })
    );
  }
  /**
   * Generates a dispatch sheets for each load leg. Attaches sheets to load and returns the LoadAttachmentDTO.
   * Use LoadAttachmentDTO download the PDF or email it.
   * NOTE: Only the latest version of the dispatch sheet is kept
   * @param params The `DocumentsService.DispatchSheetsParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTOs describing PDF
   */
  DispatchSheets(params: DocumentsService.DispatchSheetsParams): __Observable<Array<LoadAttachmentDTO>> {
    return this.DispatchSheetsResponse(params).pipe(
      __map(_r => _r.body as Array<LoadAttachmentDTO>)
    );
  }

  /**
   * Generates an invoice preview for a load. Attaches PDF to load and returns the LoadAttachmentDTO.
   * Preview is not visible in the load until it has been accepted
   * Use LoadAttachmentDTO download the PDF or email it.
   * @param params The `DocumentsService.InvoiceParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  InvoiceResponse(params: DocumentsService.InvoiceParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/Invoice/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Generates an invoice preview for a load. Attaches PDF to load and returns the LoadAttachmentDTO.
   * Preview is not visible in the load until it has been accepted
   * Use LoadAttachmentDTO download the PDF or email it.
   * @param params The `DocumentsService.InvoiceParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return LoadAttachmentDTO describing PDF
   */
  Invoice(params: DocumentsService.InvoiceParams): __Observable<LoadAttachmentDTO> {
    return this.InvoiceResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Accepts an invoice preview. Makes invoice visible in attachments, sets load status as Invoiced.
   * Returns list of attachments to be used for sending to user.
   * @param params The `DocumentsService.InvoiceAcceptParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return InvoiceAcceptedDTO describing PDF
   */
  InvoiceAcceptResponse(params: DocumentsService.InvoiceAcceptParams): __Observable<__StrictHttpResponse<InvoiceAcceptedDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/Invoice/Accept/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InvoiceAcceptedDTO>;
      })
    );
  }
  /**
   * Accepts an invoice preview. Makes invoice visible in attachments, sets load status as Invoiced.
   * Returns list of attachments to be used for sending to user.
   * @param params The `DocumentsService.InvoiceAcceptParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return InvoiceAcceptedDTO describing PDF
   */
  InvoiceAccept(params: DocumentsService.InvoiceAcceptParams): __Observable<InvoiceAcceptedDTO> {
    return this.InvoiceAcceptResponse(params).pipe(
      __map(_r => _r.body as InvoiceAcceptedDTO)
    );
  }

  /**
   * Generates an DriverPay preview for a driver.
   * Attaches PDF to driver.
   * Use TenantUserAttachmentDTO to download the PDF or email it. Use id to call DriverPay/Accept
   * @param params The `DocumentsService.DriverPayParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `periodEnding`:
   *
   * - `loadLegs`:
   *
   * - `fuelDeductionDescription`:
   *
   * - `fuelDeductionAmount`:
   *
   * - `driverId`:
   *
   * @return DriverPayPreviewDTO describing PDF and including Driver Settlement ID
   */
  DriverPayResponse(params: DocumentsService.DriverPayParams): __Observable<__StrictHttpResponse<DriverPayPreviewDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.periodEnding != null) __params = __params.set('periodEnding', params.periodEnding.toString());
    (params.loadLegs || []).forEach(val => {if (val != null) __params = __params.append('loadLegs', val.toString())});
    if (params.fuelDeductionDescription != null) __params = __params.set('fuelDeductionDescription', params.fuelDeductionDescription.toString());
    if (params.fuelDeductionAmount != null) __params = __params.set('fuelDeductionAmount', params.fuelDeductionAmount.toString());
    if (params.driverId != null) __params = __params.set('driverId', params.driverId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DriverPay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverPayPreviewDTO>;
      })
    );
  }
  /**
   * Generates an DriverPay preview for a driver.
   * Attaches PDF to driver.
   * Use TenantUserAttachmentDTO to download the PDF or email it. Use id to call DriverPay/Accept
   * @param params The `DocumentsService.DriverPayParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `periodEnding`:
   *
   * - `loadLegs`:
   *
   * - `fuelDeductionDescription`:
   *
   * - `fuelDeductionAmount`:
   *
   * - `driverId`:
   *
   * @return DriverPayPreviewDTO describing PDF and including Driver Settlement ID
   */
  DriverPay(params: DocumentsService.DriverPayParams): __Observable<DriverPayPreviewDTO> {
    return this.DriverPayResponse(params).pipe(
      __map(_r => _r.body as DriverPayPreviewDTO)
    );
  }

  /**
   * Accepts the DriverPay preview. Sets driver pay status as Invoiced.
   * Returns attachment to be used for sending to user.
   * @param params The `DocumentsService.DriverPayAcceptParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `DriverSettlementId`:
   *
   * - `AttachmentId`:
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  DriverPayAcceptResponse(params: DocumentsService.DriverPayAcceptParams): __Observable<__StrictHttpResponse<AttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.DriverSettlementId != null) __params = __params.set('DriverSettlementId', params.DriverSettlementId.toString());
    if (params.AttachmentId != null) __params = __params.set('AttachmentId', params.AttachmentId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DriverPay/Accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttachmentDTO>;
      })
    );
  }
  /**
   * Accepts the DriverPay preview. Sets driver pay status as Invoiced.
   * Returns attachment to be used for sending to user.
   * @param params The `DocumentsService.DriverPayAcceptParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `DriverSettlementId`:
   *
   * - `AttachmentId`:
   *
   * @return TenantUserAttachmentDTO describing PDF
   */
  DriverPayAccept(params: DocumentsService.DriverPayAcceptParams): __Observable<AttachmentDTO> {
    return this.DriverPayAcceptResponse(params).pipe(
      __map(_r => _r.body as AttachmentDTO)
    );
  }

  /**
   * Processes a cancellation of the Driver Pay preview. Removes pending Driver Settlement record.
   * @param params The `DocumentsService.DriverPayCanceledParams` containing the following parameters:
   *
   * - `driverSettlementId`:
   *
   * - `Authorization`: Bearer {token}
   */
  DriverPayCanceledResponse(params: DocumentsService.DriverPayCanceledParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Documents/DriverPay/Cancel/${encodeURIComponent(String(params.driverSettlementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Processes a cancellation of the Driver Pay preview. Removes pending Driver Settlement record.
   * @param params The `DocumentsService.DriverPayCanceledParams` containing the following parameters:
   *
   * - `driverSettlementId`:
   *
   * - `Authorization`: Bearer {token}
   */
  DriverPayCanceled(params: DocumentsService.DriverPayCanceledParams): __Observable<null> {
    return this.DriverPayCanceledResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DocumentsService {

  /**
   * Parameters for DispatchSheets
   */
  export interface DispatchSheetsParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Invoice
   */
  export interface InvoiceParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for InvoiceAccept
   */
  export interface InvoiceAcceptParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DriverPay
   */
  export interface DriverPayParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    periodEnding?: string;
    loadLegs?: Array<number>;
    fuelDeductionDescription?: string;
    fuelDeductionAmount?: number;
    driverId?: number;
  }

  /**
   * Parameters for DriverPayAccept
   */
  export interface DriverPayAcceptParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    DriverSettlementId?: number;
    AttachmentId?: number;
  }

  /**
   * Parameters for DriverPayCanceled
   */
  export interface DriverPayCanceledParams {
    driverSettlementId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { DocumentsService }
