<app-load-edit
  *ngIf="showLoadDialog"
  [loadId]="selectedLoadId"
  [disabled]="disabled"
  [(dialogVisible)]="showLoadDialog"
  (modelEdited)="handleModelEdit($event)"
>
</app-load-edit>

<p-card styleClass="ui-card-shadow">
  <div class="layout-content-container table-container">
    <div class="grid grid-nogutter col-12 p-0 table-inner">
      <div class="grid grid-nogutter col-12 p-2">
        <div class="col-2 p-0">
          <h1>Invoicing</h1>
        </div>
        <div class="col-4 col-offset-6 p-0 flex flex-wrap justify-content-end">
          <div class="flex align-items-center">
            <p-button
              (onClick)="goToFundingDetails()"
              label="Funding Details"
              icon="fas fa-share-square"
              iconPos="right"
              *ngIf="tenantFactoringEnabled"
              class="ui-button-secondary pr-2"
            ></p-button>
            <p-button
              (onClick)="export()"
              label="Export to Excel"
              icon="fa fa-download"
              iconPos="right"
              class="ui-button-secondary"
            ></p-button>
          </div>
        </div>
      </div>
      <div class="col-12 p-2">
        <span
          class="filter-menu"
          *ngFor="let status of statusFilters"
          [ngClass]="{ active: status.active }"
          (click)="filterDataByStatus(status)"
        >
          {{ status.label }}
        </span>
      </div>
      <div class="grid grid-nogutter xl:col-5 lg:col-6 md:col-9 col-12 p-0">
        <app-bulk-action
          class="col-5 p-0"
          [actionOptions]="actionOptions"
          [disableApply]="disableApply"
          [selectedItems]="getSelectedItems()"
          (actionEvent)="getDataFiltered()"
        ></app-bulk-action>
        <div class="flex flex-wrap align-items-center">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon ui-inputgroup-addon-inside"><i class="fa fa-search"></i></span>
            <input [(ngModel)]="filter.search" (keyup)="filterTable()" placeholder="Search loads" pInputText />
          </div>
        </div>
        <div class="col-3 flex flex-wrap align-items-center px-2">
          <span class="filter col-12 pl-0" (click)="filterPanel.toggle($event)"
            >Filter <i class="fa fa-caret-down"></i>
          </span>
          <p-overlayPanel #filterPanel [style]="{ width: '31.25rem' }" styleClass="filter-overlay" appendTo="body">
            <div class="grid grid-nogutter col-12 p-0">
              <div class="col-6 p-2">
                <div class="grid grid-nogutter col-12 p-1">
                  <div class="col-12 px-2 py-1 font-bold"> Filter by </div>
                  <div class="col-12 px-2 py-1">
                    <p-dropdown
                      [style]="{ width: '100%' }"
                      [options]="filter.byOptions"
                      [(ngModel)]="filter.by"
                      placeholder="Select"
                    >
                    </p-dropdown>
                  </div>
                </div>

                <div class="grid grid-nogutter col-12 p-1">
                  <div class="col-12 px-2 py-1 font-bold"> Contains </div>
                  <div class="col-12 px-2 py-1">
                    <input type="text" class="col-12" [(ngModel)]="filter.contains" pInputText />
                  </div>
                </div>

                <div class="grid grid-nogutter col-12 p-1">
                  <div class="col-12 px-2 py-1 font-bold"> Work Order/PO/BOL </div>
                  <div class="col-12 px-2 py-1">
                    <input type="text" class="col-12" [(ngModel)]="filter.workOrder" pInputText />
                  </div>
                </div>
              </div>

              <div class="col-6 p-2">
                <div class="grid grid-nogutter col-12 p-1">
                  <div class="col-12 px-2 py-1 font-bold"> Dates </div>
                  <div class="grid grid-nogutter col-12 px-2 py-0 flex flex-wrap justify-content-between">
                    <div class="col-6 px-0 pr-1 py-1">
                      <p-calendar
                        [(ngModel)]="filter.dateBegin"
                        [showIcon]="true"
                        [maxDate]="filter.dateEnd"
                        [style]="{ width: '79%' }"
                        placeholder="Start"
                        styleClass="dateRangeCalendar"
                        showButtonBar="true"
                        (onInput)="filterDateRangeChange()"
                        (onSelect)="filterDateRangeChange()"
                      ></p-calendar>
                    </div>
                    <div class="col-6 px-0 pl-1 py-1">
                      <p-calendar
                        [(ngModel)]="filter.dateEnd"
                        [showIcon]="true"
                        [minDate]="filter.dateBegin"
                        [style]="{ width: '79%' }"
                        placeholder="End"
                        showButtonBar="true"
                        styleClass="dateRangeCalendar"
                        (onInput)="filterDateRangeChange()"
                        (onSelect)="filterDateRangeChange()"
                      ></p-calendar>
                    </div>

                    <div class="col-12 px-0 py-2">
                      <p-dropdown
                        [style]="{ width: '100%' }"
                        [options]="filter.datesOptions"
                        [(ngModel)]="filter.dateType"
                        (onChange)="filterDateRangeChange()"
                        placeholder="Select"
                      >
                      </p-dropdown>
                    </div>
                  </div>
                </div>

                <div class="grid grid-nogutter col-12 p-1 invoice-number-label">
                  <div class="col-12 px-2 py-1 font-bold"> Invoice Number </div>
                  <div class="col-12 px-2 py-1">
                    <input type="text" class="col-12" [(ngModel)]="filter.invoiceNumber" pInputText />
                  </div>
                </div>
              </div>

              <div class="col-12 p-4 py-2">
                <div class="main-actions ui-right">
                  <span class="link-btn" (click)="filterClear()">Clear</span>
                  <button pButton type="button" class="applyButton" (click)="getDataFiltered()" label="Apply"></button>
                </div>
              </div>
            </div>
          </p-overlayPanel>
        </div>
      </div>
      <div class="col-12 p-2">
        <p-table
          #dataTable
          [columns]="cols"
          [value]="loads"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="70vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [ngSwitch]="col.format"
                [pSortableColumn]="col.sortable ? col.field : null"
                pResizableColumn
              >
                <ng-container *ngSwitchCase="'checkbox'">
                  <p-checkbox [(ngModel)]="bulkCheckAll" (onChange)="bulkToggleCheckAll()" binary="true"></p-checkbox>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ col.header }}
                  <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              #pfsPaymentTable
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>
<div *ngIf="showDialog">
  <app-load-action-dialog
    [(showDialog)]="showDialog"
    [actionType]="actionType"
    [selectedLoads]="selectedForAction"
    (actionComplete)="handleBulkActionComplete()"
  >
  </app-load-action-dialog>
</div>
