<form [formGroup]="formGroup">
  <app-form-field
    [formGroup]="formGroup"
    label="Category"
    type="select"
    [config]="{ selectOptions: loadCategories }"
    [formControlName]="controlName"
    placeholder="Select a Category"
    labelClass="md:col-4 sm:col-12 p-2"
    controlClass="md:col-8 sm:col-12 px-0 py-1"
  ></app-form-field>
</form>
