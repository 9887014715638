import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { LoadDriverSummaryDTO, MapViewDriverDTO, MapViewLoadDTO } from '../../../../apiclient/models';
import { ViewModes } from 'src/app/shared/load/components/edit/legs/leg/leg-tracking/leg-tracking.component';
@Component({
  selector: 'app-drivercards',
  templateUrl: './drivercards.component.html',
  styleUrls: ['./drivercards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrivercardsComponent implements OnInit {
  @Input() drivers: MapViewDriverDTO[];
  @Output() loadClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() trackingUpdated: EventEmitter<string> = new EventEmitter<string>();

  thresholdHours: number = 8;
  maxHoursToDelivery: number = 24;
  trackingViewMode: string = ViewModes.DriverCard;

  showWarning: boolean = false;
  warningMessage: string;

  now: Date = new Date();
  // Changed this to only date because the api is returning date without time.
  today = moment(
    new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    )
  );

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  doChangeDetection() {
    this.cdr.detectChanges();
  }

  loadNumClicked(loadId) {
    this.loadClicked.emit(loadId);
  }

  getLoadStatusClass(loadStatus: string): string {
    switch (loadStatus) {
      // TODO: Get rest of the stati
      case 'Delayed':
        return 'delayed';
      case 'OnTime':
        return 'ontime';
      default: {
        return 'silver';
      }
    }
  }
  getFormatedDateLong(loadDate) {
    return moment.utc(loadDate).local().format('MM/DD/YYYY h:mm:ss A');
  }
  getFormatedDate(loadDate) {
    return moment.utc(loadDate).local().format('MMMM DD, YYYY h:mm:ss A');
  }

  getDriverIconColor(driver: MapViewDriverDTO): string {
    const loadLastUpdate = moment(driver.lastTrackingUpdatedUtc);
    if (driver.loads.length === 0) {
      return 'delayed';
    } else if (!this.isActiveLoadStatus(driver.loads[0])) {
      return 'noload';
    } else if (
      this.today.diff(loadLastUpdate, 'hours') > this.thresholdHours ||
      !driver.loads[0].lastTrackingUpdatedUtc
    ) {
      return 'empty';
    } else if (
      driver.loads[0].deliveryDate &&
      moment(new Date(driver.loads[0].deliveryDate)).diff(this.today, 'hours') <= this.maxHoursToDelivery
    ) {
      return 'warning';
    } else {
      return 'ontime';
    }
  }

  isActiveLoadStatus(load: MapViewLoadDTO): boolean {
    if (
      load.loadStatus &&
      (load.loadStatus === 'InTransit' || load.loadStatus === 'InYard' || load.loadStatus === 'Dispatched')
    ) {
      return true;
    }
    return false;
  }

  showDriverAlert(driver: MapViewDriverDTO): boolean {
    return (
      driver.lastTrackingUpdatedUtc &&
      this.today.diff(moment(driver.lastTrackingUpdatedUtc), 'hours') <= this.thresholdHours
    );
  }

  showDriverAlertMessage(driver: MapViewDriverDTO): string {
    if (
      driver.lastTrackingUpdatedUtc &&
      this.today.diff(moment(driver.lastTrackingUpdatedUtc), 'hours') > this.thresholdHours
    ) {
      return `Driver tracking status has not been updated in ${this.today.diff(
        moment(driver.lastTrackingUpdatedUtc),
        'hours'
      )} hours`;
    } else {
      return `Driver tracking status has not been updated`;
    }
  }

  getLoadColor(load: LoadDriverSummaryDTO): string {
    const shipDate = moment(load.shipDate);
    if (shipDate.diff(this.today, 'hours') >= this.maxHoursToDelivery || !load.shipDate) {
      return 'gray';
    }
    return 'pfsblue';
  }

  getTime(date?: Date): number {
    return date != null && date ? new Date(date).getTime() : 0;
  }

  public updateTracking(loadId): void {
    this.trackingUpdated.emit(loadId);
  }
}
