<p-dialog
  *ngIf="dialogVisible"
  header="User"
  [(visible)]="dialogVisible"
  [modal]="true"
  [style]="{ width: '50rem' }"
  appendTo="body"
  [focusOnShow]="false"
>
  <div class="grid grid-nogutter p-0">
    <div class="col-12">
      <form [formGroup]="formGroup" (ngSubmit)="saveUser()">
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="First Name *"
          formControlName="firstName"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="col-4 p-2"
          controlClass="col-8"
        >
        </app-form-field>
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="Last Name *"
          formControlName="lastName"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="col-4 p-2"
          controlClass="col-8"
        >
        </app-form-field>
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="Email *"
          formControlName="email"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="col-4 p-2"
          controlClass="col-8"
        >
        </app-form-field>
        <app-phone-number
          class="col-12 p-0"
          prefix="phone"
          [(formGroup)]="formGroup"
          [(formErrors)]="formErrors"
          [(validationMessages)]="validationMessages"
          labelClass="label-black col-4 p-2"
          controlClass="col-8 p-1"
          inputClass="col-12"
          errorClass="col-12 p-0"
          editMode="true"
        >
        </app-phone-number>
        <div [hidden]="!showRoles">
          <div class="grid col-12 pl-2 flex justify-content-between">
            <div class="col-12">
              <div class="label-black"> Role * </div>
            </div>
            <div class="grid col-6 mr-4" *ngIf="commissionedRoles && commissionedRoles.length > 0">
              <div class="grid col-12 flex align-items-center" *ngFor="let roleOption of commissionedRoles">
                <p-checkbox
                  class="col-8"
                  name="roles"
                  formControlName="roles"
                  value="{{ roleOption.value }}"
                  label="{{ roleOption.label }}"
                  [(ngModel)]="model.roles"
                ></p-checkbox>
                <app-percentage-flat-rate
                  #userCommission
                  class="col-4"
                  formControlName="{{ roleOption.value }}"
                  [isRequired]="isRoleSelected(roleOption.value)"
                  [touched]="formGroup.touched"
                  [dirty]="formGroup.dirty"
                ></app-percentage-flat-rate>
              </div>
            </div>

            <div class="grid col">
              <p-checkbox
                *ngFor="let roleOption of standardRoles"
                class="col-6"
                [id]="roleOption.value"
                name="roles"
                formControlName="roles"
                value="{{ roleOption.value }}"
                label="{{ roleOption.label }}"
                [(ngModel)]="model.roles"
                [disabled]="roleOption.disabled"
              ></p-checkbox>
            </div>
            <div class="col-12">
              <span class="formerrors">{{ formErrors.roles }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="closeDialog()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      form="htmlformGroup"
      (click)="saveUser()"
      label="{{ model.userId == 0 ? 'Create' : 'Update' }}"
      [disabled]="disableSave"
    ></button>
  </p-footer>
</p-dialog>
