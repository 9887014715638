import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent {
  @Input() primaryActionLabel: string = 'Save';
  @Input() primaryActionIcon: string = 'fa fa-check';
  @Input() disablePrimary: boolean;
  @Output() primaryActionExecute: EventEmitter<any> = new EventEmitter<any>();

  @Input() secondaryActionLabel: string = 'Cancel';
  @Input() secondaryActionIcon: string = 'fa fa-times';
  @Output() secondaryActionExecute: EventEmitter<any> = new EventEmitter<any>();

  public primaryAction(): void {
    this.primaryActionExecute.emit();
  }

  public secondaryAction(): void {
    this.secondaryActionExecute.emit();
  }
}
