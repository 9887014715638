import { Component, Input } from '@angular/core';
import { WizardStep } from '../wizard-step';

@Component({
  selector: 'app-wizard-progress',
  templateUrl: './wizard-progress.component.html',
  styleUrls: ['./wizard-progress.component.scss'],
})
export class WizardProgressComponent {
  @Input() wizardSteps: WizardStep[];
  @Input() currentStepIndex: number;

  public stepsToDisplay(): WizardStep[] {
    return this.wizardSteps.filter((s) => !s.hideFromProgress);
  }

  public isStepComplete(progressNumber: number): boolean {
    const index = this.getIndexByProgressNumber(progressNumber);
    return !this.isStepSelected(progressNumber) && this.currentStepIndex > index;
  }

  public isStepSelected(progressNumber: number) {
    const index = this.getIndexByProgressNumber(progressNumber);
    return this.currentStepIndex === index || this.isSelectedForHiddenStep(index);
  }

  private isSelectedForHiddenStep(index: number) {
    return this.wizardSteps[this.currentStepIndex].hideFromProgress && this.findLastValidStepIndex() === index;
  }

  private findLastValidStepIndex(): number {
    for (let index = this.currentStepIndex - 1; index >= 0; index--) {
      if (!this.wizardSteps[index].hideFromProgress) {
        return index;
      }
    }
  }

  private getIndexByProgressNumber(progressNumber: number): number {
    return this.wizardSteps.findIndex((s) => s.stepProgressNumber === progressNumber);
  }
}
