import { Injectable } from '@angular/core';
import { loadStripe, RedirectToCheckoutOptions, Stripe, StripeError } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripe: Stripe;

  public async loadStripe(key: string): Promise<void> {
    this.stripe = await loadStripe(key);
  }

  public redirectToCheckout(options: RedirectToCheckoutOptions): Promise<never | { error: StripeError }> {
    return this.stripe.redirectToCheckout(options);
  }
}
