import { Component, Input, forwardRef } from '@angular/core';
import { StatusFilter } from './models/status-filter';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusFilterComponent),
      multi: true,
    },
  ],
})
export class StatusFilterComponent implements ControlValueAccessor {
  @Input() statuses: StatusFilter[];
  private onChange: (status: StatusFilter) => {};

  public filterSelected(status: StatusFilter, shouldTriggerOnChange = true): void {
    if (status.active) {
      return;
    }

    this.statuses.forEach((s) => (s.active = false));
    status.active = true;

    if (shouldTriggerOnChange) {
      this.onChange(status);
    }
  }

  public writeValue(value: string): void {
    const status = this.statuses.find((s) => s.value === value);
    if (status) {
      this.filterSelected(status, false);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}
}
