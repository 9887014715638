<div [(formGroup)]="formGroup">
  <div class="grid grid-nogutter col-12 p-0">
    <div class="col-6" *ngFor="let document of documents; let idx = index">
      <app-record
        [document]="document"
        [checked]="document.checked"
        [field]="fields[idx]"
        [viewMode]="viewMode"
        (checkChanged)="handleSelectionChange($event)"
        (expiresChanged)="handleExpiresChange($event)"
      >
      </app-record>
    </div>
  </div>
</div>
