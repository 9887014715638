import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TenantDTO } from '../../../../apiclient/models';
import { ClientTokenService } from '../../../../services/client-token-service';
import { TenantBillingService } from '../../../../services/tenant-billing.service';
import { BillingStatus } from '../../../data/static-data';

@Component({
  selector: 'app-billing-status',
  templateUrl: './billing-status.component.html',
})
export class TenantBillingStatusComponent implements OnInit {
  loading = false;
  isSuperAdmin = false;
  showManage = false;
  isExempt = false;

  private _model: TenantDTO;
  @Input() set model(value: TenantDTO) {
    this._model = value;
    this.isSuperAdmin = this.clientToken.hasRole('SuperAdmin');
    this.showManage = this.model.tenantId === this.clientToken.tenantId();
    this.isExempt = this._model.billingStatus === BillingStatus.Exempt;
    if (this.isExempt) {
      this.showManage = false;
    }
  }

  get model(): TenantDTO {
    return this._model;
  }

  constructor(
    private billing: TenantBillingService,
    private clientToken: ClientTokenService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {}

  openPortal() {
    if (this.isExempt) {
      return;
    }
    this.loading = true;
    this.billing.openPortal().catch((e) => {
      this.loading = false;
    });
  }

  toggleExempt() {
    let message = this.isExempt
      ? 'Are you sure you want to remove this billing exemption?'
      : 'Are you sure you want to make this tenant Exempt from billing?';

    if (this._model.billingStatus === BillingStatus.Active) {
      message += ' This operation will cancel the current subscription.';
    }

    this.confirmationService.confirm({
      header: 'Confirm Exempt Status Change',
      message: message,
      key: 'BillingConfirmation',
      accept: async () => {
        try {
          this.loading = true;
          await this.billing.updateExemptStatus(this._model.tenantId, !this.isExempt);
          // Update UI
          this._model.billingPeriodEnds = null;
          this._model.billingStatus = this.isExempt ? BillingStatus.None : BillingStatus.Exempt;
          this.isExempt = !this.isExempt;
        } finally {
          this.loading = false;
        }
      },
    });
  }
}
