import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent {
  @Output() backEvent = new EventEmitter<void>();

  public back(): void {
    this.backEvent.emit();
  }
}
