import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeFormProperties } from './models/date-range-form-properties';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateRange } from './models/date-range';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateRangeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public readonly dateRangeFormProperties = DateRangeFormProperties;

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [DateRangeFormProperties.StartDate]: [],
      [DateRangeFormProperties.EndDate]: [],
    });

    this.formGroup.valueChanges
      .pipe(
        map(() => ({
          startDate: this.formGroup.controls[DateRangeFormProperties.StartDate].value?.toString(),
          endDate: this.formGroup.controls[DateRangeFormProperties.EndDate].value?.toString(),
        })),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: DateRange): void {
    this.formGroup.setValue({
      [DateRangeFormProperties.StartDate]: this.convertStingToDateOrNull(value.startDate),
      [DateRangeFormProperties.EndDate]: this.convertStingToDateOrNull(value.endDate),
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private onChange = (value: DateRange | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private convertStingToDateOrNull(dateString: string): Date {
    return dateString ? new Date(dateString) : null;
  }
}
