import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ClientTokenService } from '../../../../services/client-token-service';
import { AuthorizationDataProperties } from './authorization-data-properties';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class TenantTypeAuthorizationGuard implements CanActivate {
  constructor(private clientTokenService: ClientTokenService, private navigationService: NavigationService) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean {
    if (this.clientTokenService.isTenantType(next.data[AuthorizationDataProperties.TenantType])) {
      return true;
    } else {
      this.navigationService.navigateToDefaultDashboard();
      return false;
    }
  }

  public canLoad(next: ActivatedRouteSnapshot): boolean {
    if (this.clientTokenService.isTenantType(next.data[AuthorizationDataProperties.TenantType])) {
      return true;
    } else {
      this.navigationService.navigateToDefaultDashboard();
      return false;
    }
  }
}
