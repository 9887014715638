import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * PrimeNG 7 introduced a FocusStrap to dialogs by default.
 * Since we are using a dialog for the load edit screen, it gets focus trap.
 * When we spawn another dialog for the tally-items, it gets another focus trap, but the load gets priority.
 * This service prevents us from having to prop-drill info between components.
 */
@Injectable()
export class FocusTrapService {
  private focusStatus = new BehaviorSubject<boolean>(false);

  constructor() {}

  public focus(): Observable<boolean> {
    return this.focusStatus.asObservable();
  }

  public onDialog(open: boolean) {
    this.focusStatus.next(!open);
  }
}
