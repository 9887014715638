import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdNamePairDTO } from 'src/apiclient/v1.1/models';
import { HandleErrorBase } from '../../HandleErrorBase';
import { UserService } from 'src/apiclient/v1.1/services/user.service';
import { Permission } from 'src/app/data/static-data';

@Component({
  selector: 'app-tenant-user-selector',
  templateUrl: './tenant-user-selector.component.html',
  styleUrls: ['./tenant-user-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TenantUserSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantUserSelectorComponent extends HandleErrorBase implements ControlValueAccessor {
  @Input() roleFilter: string;
  @Input() readonly?: boolean = false;
  @Input() showClear: boolean = false;
  @Input() label: string = null;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;

  @Output() changed: EventEmitter<IdNamePairDTO> = new EventEmitter();

  /**
   * Options:
   *   - display: Only shows content of field
   *   - readonly: Shows input(control) on read only mode
   *   - blank/not defined: Regular Input/Control mode
   * Default: blank
   */
  @Input() viewMode?: string;

  public selected: IdNamePairDTO = <IdNamePairDTO>{};
  public options: IdNamePairDTO[] = null;

  constructor(private users: UserService, protected injector: Injector) {
    super(injector);
  }

  public async writeValue(val: IdNamePairDTO): Promise<void> {
    this.options = [val];
    this.selected = val;

    if (this.clientToken.hasPermission(Permission.UserRead)) {
      this.getOptions().then((data) => {
        if (this.selected && this.selected.id && this.selected.id > 0) {
          this.selected = data.filter((x) => x.id === this.selected.id)[0];
        }
        this.options = data;
      });
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  private propagateChange = (_: any) => {};

  public onChange(): void {
    this.propagateChange(this.selected);
    this.changed.emit(this.selected);
  }

  private async getOptions(): Promise<IdNamePairDTO[]> {
    try {
      const roles = this.roleFilter === '*' ? [] : [this.roleFilter];
      return await this.users
        .NamesWithTenantUserId({
          IncludeRoles: roles,
          IncludeDisabled: false,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    } catch (error) {
      this.handleBasicError(error);
    }
  }

  public onClear(): void {
    this.selected = {} as IdNamePairDTO;
    this.onChange();
  }

  public showErrors(): string {
    const control = this.formGroup.controls[this.formControlName];
    if (control && control.errors?.required) {
      return this.label + ' is required.';
    }
    return '';
  }
}
