import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FocusTrapService } from '../../../../services/focus-trap.service';

@Component({
  selector: 'app-document-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class DocumentAddComponent {
  @Input() loadId: number;
  @Input() disabled: boolean = false;
  @Output() uploadedFile = new EventEmitter<void>();
  dialogVisible = false;

  constructor(private focusTrap: FocusTrapService) {}

  openDialog() {
    this.dialogVisible = true;
    this.focusTrap.onDialog(true);
  }

  closeDialog() {
    this.dialogVisible = false;
    this.focusTrap.onDialog(false);
  }

  public fileUploaded(): void {
    this.uploadedFile.emit();
  }
}
