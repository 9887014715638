import { Component, OnInit } from '@angular/core';
import { ClientTokenService } from '../../../services/client-token-service';
import { AppConfig } from '../../config/app.config';
import { LogisticsConfig } from '../../config/logistics.config';
import { TenantBillingService } from '../../../services/tenant-billing.service';
import { MenuItem } from '../../shared/models/MenuItem';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Permission, Role } from '../../data/static-data';
import { BillingStatus } from '../../data/static-data';
import { FactoringLoginService } from '../../../services/factoring-login/factoring-login.service';
import { Utils } from '../../../utils/utils';
import { TenantType } from 'src/app/data/tenant-type';

/**
 * Adapted from Apollo v9 `app.menu.component.ts`.
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  public menuItems$: Observable<MenuItem[]>;
  private readonly eldLabel = 'ELD/HOS/IFTA';

  constructor(
    private clientTokenService: ClientTokenService,
    private billing: TenantBillingService,
    private factoringLoginService: FactoringLoginService,
    private utils: Utils
  ) {}

  public ngOnInit(): void {
    const items: MenuItem[] = [
      {
        label: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: `${AppConfig.routes.logistics}/${LogisticsConfig.routes.dashboard}`,
          },
          {
            type: TenantType.Carrier,
            routerLink: AppConfig.routes.dashboard,
          },
        ],
      },
      {
        label: 'Workplace',
        icon: 'fa fa-fw fa-briefcase',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: AppConfig.routes.workplace,
            permission: Permission.LoadRead,
          },
          {
            type: TenantType.Carrier,
            routerLink: AppConfig.routes.workplace,
            permission: Permission.LoadRead,
            roles: [Role.SuperAdmin, Role.Admin, Role.Dispatch],
          },
        ],
      },
      {
        label: 'Loads',
        icon: 'fa fa-fw fa-truck',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: `${AppConfig.routes.logistics}/${LogisticsConfig.routes.loads.loads}`,
            permission: Permission.LoadRead,
          },
          {
            type: TenantType.Carrier,
            routerLink: AppConfig.routes.load,
            permission: Permission.LoadRead,
            roles: [Role.SuperAdmin, Role.Admin, Role.Dispatch],
          },
        ],
      },
      {
        label: 'Marketplace',
        icon: 'fa fa-fw fa-briefcase',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: AppConfig.routes.marketplace,
            permission: Permission.Marketplace,
          },
        ],
      },
      {
        label: this.eldLabel,
        icon: 'fa fa-fw fa-briefcase',
        allowedTenants: [
          {
            type: TenantType.Carrier,
            routerLink: '/lbt/',
            permission: Permission.LoadRead,
          },
        ],
        onExternalLinkClick: () =>
          this.utils.openExternalLinkInNewTab(this.clientTokenService.getClaims().lbtIframeUrl),
      },
      {
        label: 'Business Associates',
        icon: 'fa fa-fw fa-user-circle',
        subItems: [
          {
            label: 'Customers',
            queryParams: { type: 'billto' },
            allowedTenants: [
              {
                type: TenantType.Logistics,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
              },
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
                roles: [Role.SuperAdmin, Role.Admin, Role.Dispatch, Role.Accounting],
              },
            ],
          },
          {
            label: 'Shippers',
            queryParams: { type: 'shipper' },
            allowedTenants: [
              {
                type: TenantType.Logistics,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
              },
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
                roles: [Role.SuperAdmin, Role.Admin, Role.Dispatch, Role.Accounting],
              },
            ],
          },
          {
            label: 'Consignees',
            queryParams: { type: 'consignee' },
            allowedTenants: [
              {
                type: TenantType.Logistics,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
              },
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.company,
                permission: Permission.CompanyRead,
                roles: [Role.SuperAdmin, Role.Admin, Role.Dispatch, Role.Accounting],
              },
            ],
          },
          {
            label: 'Carriers',
            allowedTenants: [
              {
                type: TenantType.Logistics,
                routerLink: `${AppConfig.routes.logistics}/${LogisticsConfig.routes.carriers.carriers}`,
                permission: Permission.CarrierRead,
              },
            ],
          },
        ],
      },
      {
        label: 'Back Office',
        icon: 'fa fa-fw fa-calculator',
        subItems: [
          {
            label: 'Invoicing',
            allowedTenants: [
              {
                type: TenantType.Logistics,
                routerLink: AppConfig.routes.backOffice.invoicing,
                permission: Permission.LoadAccounting,
              },
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.backOffice.invoicing,
                permission: Permission.LoadAccounting,
                roles: [Role.SuperAdmin, Role.Admin, Role.Accounting],
              },
            ],
          },
          {
            label: 'Driver Pay',
            allowedTenants: [
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.backOffice.driverPay,
                permission: Permission.DriverPay,
                roles: [Role.SuperAdmin, Role.Admin, Role.Accounting],
              },
            ],
          },
          {
            label: 'Drivers',
            allowedTenants: [
              {
                type: TenantType.Carrier,
                routerLink: AppConfig.routes.backOffice.drivers,
                permission: Permission.DriverProfileRead,
                roles: [Role.SuperAdmin, Role.Admin, Role.Accounting],
              },
            ],
          },
        ],
      },
      {
        label: 'Users',
        icon: 'fa fa-fw fa-users',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: AppConfig.routes.users,
            permission: Permission.UserEdit,
          },
        ],
      },
      {
        label: 'Settings',
        icon: 'fa fa-fw fa-sliders-h',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: AppConfig.routes.settings.company,
            permission: Permission.UserEdit,
          },
          {
            type: TenantType.Carrier,
            routerLink: AppConfig.routes.settings.company,
            permission: Permission.UserEdit,
            roles: [Role.SuperAdmin, Role.Admin],
          },
        ],
      },
      {
        label: 'System Administration',
        icon: 'fa fa-fw fa-wrench',
        allowedTenants: [
          {
            type: TenantType.Logistics,
            routerLink: AppConfig.routes.admin.systemDefaults,
            permission: Permission.SystemSettingEdit,
          },
          {
            type: TenantType.Carrier,
            routerLink: AppConfig.routes.admin.systemDefaults,
            permission: Permission.SystemSettingEdit,
          },
        ],
      },
      this.getSupportMenuItem(),
    ];

    this.menuItems$ = combineLatest([
      this.clientTokenService.isLoggedIn(),
      this.billing.billingStatus(),
      this.clientTokenService
        .isFactoringEnabledForTenant()
        .pipe(map((isFactoringEnabledForTenant) => this.addFactoringMenuItem(items, isFactoringEnabledForTenant))),
    ]).pipe(
      map((p) => ({ isLoggedIn: p[0], billingStatus: p[1], menuItems: p[2] })),
      filter((p) => p.isLoggedIn && p.billingStatus !== BillingStatus.NoUser),
      map((p) => this.filterMenu(p.billingStatus, p.menuItems))
    );
  }

  private filterMenu(billingStatus: BillingStatus, items: MenuItem[]): MenuItem[] {
    const activeBillingStatuses: BillingStatus[] = [BillingStatus.Exempt, BillingStatus.Active, BillingStatus.Trialing];

    if (!activeBillingStatuses.includes(billingStatus)) {
      return [
        {
          label: 'Dashboard',
          icon: 'fas fa-tachometer-alt',
          routerLink: ['/' + AppConfig.routes.subscription.required],
        },
        this.getSupportMenuItem(),
      ];
    }

    const tenantType = this.clientTokenService.getTenantType();
    const userPermissions = this.clientTokenService.permissions();
    return items.filter(
      (item) =>
        this.doesMenuItemMeetPermissions(item, tenantType, userPermissions) &&
        this.doesMenuItemMeetAdditionalConditionalLogic(item)
    );
  }

  private doesMenuItemMeetPermissions(item: MenuItem, tenantType: string, userPermissions: string[]): boolean {
    const hasAllowedTenants = item.allowedTenants && item.allowedTenants.length > 0;

    // allowedTenants is not empty, check permissions and set routerLink
    if (hasAllowedTenants) {
      const tenantRoutingDefinition = item.allowedTenants.find((tenant) => tenant.type === tenantType);

      if (tenantRoutingDefinition) {
        if (
          this.meetsPermissionRequirement(userPermissions, tenantRoutingDefinition.permission) &&
          this.meetsRolesRequirement(tenantRoutingDefinition.roles)
        ) {
          item.routerLink = tenantRoutingDefinition.routerLink;
          return true;
        }

        return false;
      }
    } else if (item.subItems) {
      // if allowedTenants is empty, we check if there are subItems
      item.items = item.subItems
        .filter((subItem) => {
          const subItemAllowedTenants = subItem.allowedTenants;
          return subItemAllowedTenants?.some((tenant) => tenant.type === tenantType);
        })
        .filter((subItem) => {
          const tenantRoutingDefinition = subItem.allowedTenants.find((tenant) => tenant.type === tenantType);

          if (
            this.meetsPermissionRequirement(userPermissions, tenantRoutingDefinition.permission) &&
            this.meetsRolesRequirement(tenantRoutingDefinition.roles)
          ) {
            subItem.routerLink = subItem.allowedTenants.find((tenant) => tenant.type === tenantType)?.routerLink;
            return true;
          }
        });

      return item.items.length > 0;
    }

    // If allowed tenants is empty and there are no subitems, the item should be accessible by anyone
    return !hasAllowedTenants && !item.subItems;
  }

  private meetsRolesRequirement(allowedRoles: string[]): boolean {
    return !allowedRoles || allowedRoles.some((role) => this.clientTokenService.roles().includes(role));
  }

  private meetsPermissionRequirement(userPermissions: string[], permission: string): boolean {
    return !permission || userPermissions.includes(permission);
  }

  private doesMenuItemMeetAdditionalConditionalLogic(item: MenuItem): boolean {
    if (item.conditionalDisplayLogic !== undefined && item.conditionalDisplayLogic()) {
      return true;
    }

    return item.conditionalDisplayLogic === undefined;
  }

  private addFactoringMenuItem(items: MenuItem[], isFactoringEnabledForTenant: boolean): MenuItem[] {
    const factoringItem: MenuItem = {
      label: 'Factoring',
      icon: 'fa fa-fw fa-money-bill',
      disabled: !isFactoringEnabledForTenant,
      onDisabledClick: () => {
        this.utils.openExternalLinkInNewTab('https://truxio.com/trucking-solutions/freightfactoring/');
      },
      subItems: [
        {
          label: 'Buy/No Buy',
          allowedTenants: [
            {
              type: TenantType.Carrier,
              routerLink: AppConfig.routes.factoring.buyNoBuy,
              permission: Permission.DriverProfileAccounting,
              roles: [Role.Admin, Role.Accounting, Role.Dispatch],
            },
          ],
        },
        {
          label: 'Invoicing',
          allowedTenants: [
            {
              type: TenantType.Carrier,
              routerLink: AppConfig.routes.backOffice.invoicing,
              permission: Permission.LoadAccounting,
              roles: [Role.Admin, Role.Accounting, Role.Dispatch],
            },
          ],
        },
        {
          label: 'Funding Details',
          allowedTenants: [
            {
              type: TenantType.Carrier,
              permission: Permission.LoadAccounting,
              roles: [Role.Admin, Role.Accounting, Role.Dispatch],
            },
          ],
          onExternalLinkClick: () => this.factoringLoginService.logInToFactorCloud(),
        },
      ],
    };
    const eldIndex = items.findIndex((item) => item.label === this.eldLabel);
    // Add the 'Factoring' menu item after the 'ELD' menu item.
    const newMenuItems = Object.assign([], items);
    newMenuItems.splice(eldIndex + 1, 0, factoringItem);

    return newMenuItems;
  }

  private getSupportMenuItem(): MenuItem {
    return {
      label: 'Support',
      icon: 'fa fa-fw fa-life-ring',
      onExternalLinkClick: () => this.utils.openExternalLinkInNewTab('https://truxio.com/faq/'),
    };
  }
}
