<div class="layout-content-container p-0" *ngIf="factoringCompanyOptions$ | async as factoringCompanyOptions">
  <form>
    <app-edit-section
      *ngIf="factoringRemitToOptions$ | async as factoringRemitToOptions"
      title="Factoring Company"
      (saved)="save()"
      (cancelled)="reset()"
      [(editMode)]="editing"
      [disableSave]="disableSave"
    >
      <div read>
        <app-edit-section-row label="Company Name">
          <span class="font-bold"> {{ factoringCompanyOptions.companyName }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Physical Address">
          <span class="font-bold"> {{ getFormattedAddress() }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Company Phone">
          <span class="font-bold"> {{ factoringCompanyOptions.phone }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Company Email">
          <span class="font-bold">{{ factoringCompanyOptions.email }}</span>
        </app-edit-section-row>
        <app-edit-section-row label="Remit To Address">
          <span class="font-bold"> {{ getFormattedRemitToAddress() }} </span>
        </app-edit-section-row>
        <app-edit-section-row label="Remit To ACH">
          <span class="font-bold">
            {{ factoringRemitToOptions.remitToBankName }}, ABA/Routing #{{ factoringRemitToOptions.remitToABA }}, Acct
            #{{ factoringRemitToOptions.remitToAccount }}</span
          >
        </app-edit-section-row>
      </div>
      <div edit [formGroup]="formGroup">
        <app-edit-section-row label="Company Name">
          <app-form-field
            [(formGroup)]="formGroup"
            formControlName="companyName"
            [formErrors]="formErrors"
            [viewMode]="'readonly'"
            [(ngModel)]="factoringCompanyOptions.companyName"
            labelClass="nolabel"
            controlClass="col-11 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Physical Address">
          <app-form-field
            [(formGroup)]="formGroup"
            formControlName="companyAddress"
            [formErrors]="formErrors"
            [viewMode]="'readonly'"
            [ngModel]="getFormattedAddress()"
            labelClass="nolabel"
            controlClass="col-11 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Company Phone">
          <app-form-field
            [(formGroup)]="formGroup"
            formControlName="companyPhone"
            [formErrors]="formErrors"
            [viewMode]="'readonly'"
            [(ngModel)]="factoringCompanyOptions.phone"
            labelClass="nolabel"
            controlClass="col-11 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <app-edit-section-row label="Company Email">
          <app-form-field
            [(formGroup)]="formGroup"
            formControlName="companyEmail"
            [formErrors]="formErrors"
            [viewMode]="'readonly'"
            [(ngModel)]="factoringCompanyOptions.email"
            labelClass="nolabel"
            controlClass="col-11 p-0"
          >
          </app-form-field>
        </app-edit-section-row>
        <div>
          <app-edit-section-row label="Remit To Address">
            <app-form-field
              placeholder="Name"
              [(formGroup)]="formGroup"
              formControlName="remitToName"
              [formErrors]="formErrors"
              [(ngModel)]="factoringRemitToOptions.remitToName"
              labelClass="nolabel"
              controlClass="col-11 p-0"
            >
            </app-form-field>
            <app-form-field
              placeholder="Address"
              [(formGroup)]="formGroup"
              formControlName="remitToAddress"
              [formErrors]="formErrors"
              [(ngModel)]="factoringRemitToOptions.remitToAddress"
              labelClass="nolabel"
              controlClass="col-11 px-0 py-2"
            >
            </app-form-field>
            <div class="grid grid-nogutter col-12 p-0">
              <div class="col-5 p-0">
                <div class="grid grid-nogutter col-12 p-0">
                  <app-form-field
                    class="w-full"
                    placeholder="City"
                    [(formGroup)]="formGroup"
                    formControlName="remitToCity"
                    [formErrors]="formErrors"
                    [(ngModel)]="factoringRemitToOptions.remitToCity"
                    labelClass="nolabel"
                    controlClass="col-12 p-0"
                    errorClass="py-1"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="col-1 p-0 pl-2">
                <app-form-field
                  type="state-selector"
                  placeholder="State"
                  [(formGroup)]="formGroup"
                  formControlName="remitToState"
                  [formErrors]="formErrors"
                  [(ngModel)]="factoringRemitToOptions.remitToState"
                  labelClass="nolabel"
                  controlClass="col-12 p-0"
                  controlClassLevel1="p-0"
                  errorClass="py-1"
                >
                </app-form-field>
              </div>
              <div class="col-5 p-0 pl-2">
                <div class="grid grid-nogutter col-12 p-0">
                  <app-form-field
                    class="w-full"
                    placeholder="Zip"
                    [(formGroup)]="formGroup"
                    formControlName="remitToZip"
                    [formErrors]="formErrors"
                    [(ngModel)]="factoringRemitToOptions.remitToZip"
                    labelClass="nolabel"
                    controlClass="col-12 p-0"
                    errorClass="py-1"
                  >
                  </app-form-field>
                </div>
              </div>
            </div>
          </app-edit-section-row>
          <app-edit-section-row label="Remit To ACH">
            <app-form-field
              placeholder="Remit To Bank Name"
              [(formGroup)]="formGroup"
              formControlName="remitToBankName"
              [formErrors]="formErrors"
              [(ngModel)]="factoringRemitToOptions.remitToBankName"
              labelClass="nolabel"
              controlClass="col-11 py-1 px-0"
              errorClass="py-1"
            >
            </app-form-field>
            <app-form-field
              placeholder="Remit To ABA"
              [(formGroup)]="formGroup"
              formControlName="remitToABA"
              [formErrors]="formErrors"
              [(ngModel)]="factoringRemitToOptions.remitToABA"
              labelClass="nolabel"
              controlClass="col-11 py-1 px-0"
              errorClass="py-1"
            >
            </app-form-field>
            <app-form-field
              placeholder="Remit To Account"
              [(formGroup)]="formGroup"
              formControlName="remitToAccount"
              [formErrors]="formErrors"
              [(ngModel)]="factoringRemitToOptions.remitToAccount"
              labelClass="nolabel"
              controlClass="col-11 py-1 px-0"
              errorClass="py-1"
            >
            </app-form-field>
          </app-edit-section-row>
        </div>
      </div>
    </app-edit-section>
  </form>
</div>
