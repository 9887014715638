import { Component, OnInit, ViewChild, Injector, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../apiclient/services/company.service';
import { HandleErrorBase } from '../../../shared/HandleErrorBase';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { saveAs } from 'file-saver/FileSaver';
import { Utils } from '../../../../utils/utils';
import { CompanyEditComponent } from '../edit/edit.component';
import { BulkimportComponent } from '../bulkimport/bulkimport.component';
import { Role } from 'src/app/data/static-data';
import { TableColumn } from 'src/app/shared/models/TableColumn';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyListComponent extends HandleErrorBase implements OnInit, OnDestroy {
  /**
   * All, BillTo, Customer, Shipper
   */
  type: string;
  typeTitle = 'Customers';
  typeItems = [
    {
      label: 'Customers',
      routerLink: ['/company'],
      queryParams: { type: 'billto' },
      colHeader: 'Customer',
    },
    {
      label: 'Shippers',
      routerLink: ['/company'],
      queryParams: { type: 'shipper' },
      colHeader: 'Shipper',
    },
    {
      label: 'Consignees',
      routerLink: ['/company'],
      queryParams: { type: 'consignee' },
      colHeader: 'Consignee',
    },
  ];

  filter: any = {
    applied: false,
    status: null,
    search: '',
  };
  public isEditDialogVisible = false;
  public isSuperAdmin = false;
  public isImpersonating = false;

  private sub: any;

  loading: boolean;

  cols: TableColumn[];

  companies: any[];
  companiesOriginal: any[];
  @ViewChild('companyEditDialog') companyEditDialog: CompanyEditComponent;
  @ViewChild('bulkimportDialog', { static: true }) bulkimportDialog: BulkimportComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    protected injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(injector);
    this.cols = [
      {
        header: 'Customer',
      },
    ];
  }

  ngOnInit() {
    this.breadcrumbService.setItems('customers');
    if (!this.clientToken.hasPermission('CompanyRead')) {
      this.router.navigate(['/']);
    }

    this.sub = this.route.queryParams.subscribe((params) => {
      this.type = params['type'];
      if (this.type === undefined) {
        this.type = '';
      }
      this.getData();
    });

    this.cols = [
      {
        header: this.getColHeaderForType(),
        field: 'name',
        format: 'actionCell',
        sortable: true,
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditModal',
          params: {
            row: 'row',
          },
        },
        width: '15em',
      },
      {
        header: 'Address',
        field: 'address',
        sortable: true,
        width: '15em',
      },
      {
        header: 'Contact',
        field: 'contact',
        sortable: true,
        width: '15em',
      },
      {
        header: 'Phone',
        field: 'phoneNumber',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Email',
        field: 'email',
        sortable: true,
        format: 'mailto',
        width: '15em',
      },
      {
        header: 'Status',
        field: 'status',
        sortable: true,
        width: '7em',
      },
    ];

    this.isSuperAdmin = this.clientToken.hasRole(Role.SuperAdmin);
    this.isImpersonating = this.clientToken.isImpersonating();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getData() {
    this.loading = true;
    let call = this.companyService.ApiCompanyAllGet(this.clientToken.auth());
    switch (this.type.toLowerCase()) {
      case 'all':
        break;
      case 'billto':
        this.typeTitle = 'Customers';
        call = this.companyService.ApiCompanyBilltoGet(this.clientToken.auth());
        break;
      case 'shipper':
        this.typeTitle = 'Shippers';
        call = this.companyService.ApiCompanyShipperGet(this.clientToken.auth());
        break;
      case 'consignee':
        this.typeTitle = 'Consignees';
        call = this.companyService.ApiCompanyConsigneeGet(this.clientToken.auth());
        break;
    }
    this.breadcrumbService.setItems('customers', this.typeTitle);
    this.cols[0].header = this.getColHeaderForType();

    call.subscribe(
      (data) => {
        this.companies = data;
        this.companiesOriginal = data;
      },
      (error) => {
        this.handleBasicError(error);
      },
      () => (this.loading = false)
    );
  }

  private getColHeaderForType(): string {
    const typeItem = this.typeItems.find((t) => t.label === this.typeTitle);

    return typeItem.colHeader;
  }

  actionHandler = (actionConfig) => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  handleModelEdit(data) {
    if (data.dataChanged) {
      this.getData();
    }
  }

  public openEditModal = (params): void => {
    this.setEditDialogToBeVisible();
    this.companyEditDialog.init(params.row.companyId, params.row.companyAddressId);
  };

  public editDialogClosed(): void {
    // Timeout used to account for time of close animation.
    setTimeout(() => (this.isEditDialogVisible = false), 100);
  }

  public addCompany(): void {
    this.setEditDialogToBeVisible();
    this.companyEditDialog.selectedIndex = 0;
    this.companyEditDialog.init(0, 0);
  }

  export() {
    let call = this.companyService.ApiCompanyExportAllGet(this.clientToken.auth());
    switch (this.type.toLowerCase()) {
      case 'all':
        break;
      case 'billto':
        call = this.companyService.ApiCompanyExportBilltoGet(this.clientToken.auth());
        break;
      case 'shipper':
        call = this.companyService.ApiCompanyExportShipperGet(this.clientToken.auth());
        break;
      case 'consignee':
        call = this.companyService.ApiCompanyExportConsigneeGet(this.clientToken.auth());
        break;
    }

    call.subscribe(
      (data) => {
        saveAs(data, 'Business-Associates-' + this.typeTitle + '.csv');
      },
      (error) => {
        this.handleBasicError(error);
      }
    );
  }

  public filterTable(): void {
    if (this.filter.search !== '') {
      const searchTerm = this.filter.search.toLowerCase();
      this.companies = this.companiesOriginal.filter(
        (item) =>
          (item.companyId && this.isSearchTermInString(item.companyId.toString(), searchTerm)) ||
          this.isSearchTermInString(item.status, searchTerm) ||
          this.isSearchTermInString(item.name, searchTerm) ||
          this.isSearchTermInString(item.address, searchTerm)
      );
    } else {
      this.companies = this.companiesOriginal;
    }
  }

  private isSearchTermInString(item: string, searchTerm: string) {
    return item ? item.toLocaleLowerCase().includes(searchTerm) : false;
  }

  sortTable(event: any) {
    Utils.sortData(event, this.companies);
  }

  onCloseDialog() {
    this.getData();
    return false;
  }

  bulkImportProcess() {
    this.bulkimportDialog.init();
  }

  private setEditDialogToBeVisible(): void {
    this.isEditDialogVisible = true;
    this.changeDetectorRef.detectChanges();
  }
}
