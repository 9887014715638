<div class="grid grid-nogutter col-12 p-0"
  ><div class="font-bold {{ labelClass }}">
    {{ tallyType }}
  </div>
  <div class="input-box {{ controlClass }}" [formGroup]="formGroup">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-10 p-0">
        <div class="grid grid-nogutter col-12 p-0">
          <div class="p-0 col-12 ui-inputgroup">
            <span class="ui-inputgroup-addon">$</span>
            <input
              class="w-full text-right"
              type="text"
              pInputText
              placeholder="0.00"
              [value]="tallyTotalAmount | number : '1.2-2'"
              readonly
              tabindex="-1"
            />
          </div>
        </div>
      </div>
      <div class="col-2 p-0 flex flex-wrap justify-content-end align-items-center">
        <button
          class="col-8 m-0"
          pButton
          type="button"
          (click)="openDialog()"
          icon="fa fa-calculator"
          [disabled]="readonly"
        ></button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  appendTo="body"
  [(visible)]="dialogVisible"
  [modal]="true"
  [style]="{ width: '900px' }"
  [closable]="false"
  [focusOnShow]="false"
>
  <p-header>
    <div class="grid grid-nogutter col-12 p-0 flex flex-wrap justify-content-between">
      <div class="text-2xl font-normal">
        {{ tallyType | titleCase }}
      </div>
      <div>
        <button
          pButton
          icon="pi pi-times"
          (click)="cancelTally()"
          class="close-button"
          style="background-color: transparent; color: #424242"
        >
        </button>
      </div>
    </div>
  </p-header>

  <div class="grid grid-nogutter col-12 p-1">
    <div class="col-6 font-bold">Description</div>
    <div class="col-2 font-bold">Date</div>
    <div class="col-3 pl-1 font-bold">Amount</div>
    <div class="col-1"></div>
  </div>

  <div class="col-12 p-1" *ngFor="let tallyItem of tallyItems; index as idx">
    <app-tallyitem
      [suppressWarningsForElements]="suppressWarningsForElements"
      [formGroup]="formGroup"
      [tallyItem]="tallyItem"
      (tallyItemChanged)="tallyItemChanged($event, idx)"
      (tallyItemRemoved)="removeTallyItem(tallyItem)"
      [readonly]="readonly"
    >
    </app-tallyitem>
  </div>

  <div class="grid grid-nogutter col-12 p-1" *ngIf="!readonly">
    <div class="col-12 p-2 additembox fauxlink" (click)="addTallyItem()">
      <i class="fa fa-plus"></i>
      Add {{ tallyType | titleCase }}
    </div>
  </div>

  <p-footer>
    <button
      #cancelButton
      type="button"
      pButton
      icon="fas fa-times"
      class="ui-button-secondary"
      (click)="cancelTally()"
      label="{{ readonly ? 'Close' : 'Cancel' }}"
    ></button>
    <button type="button" pButton icon="fa fa-check" (click)="saveTally()" label="Save" *ngIf="!readonly"></button>
  </p-footer>
</p-dialog>
