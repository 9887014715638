import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConfig } from '../config/app.config';
import { PrivacyPolicyComponent } from './privacy-policy.component';

const routes: Routes = [{ path: AppConfig.routes.privacyPolicy, component: PrivacyPolicyComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivacyPolicyRoutingModule {}
