/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DriverProfileSummaryDTO } from '../models/driver-profile-summary-dto';
import { DriverProfileDTO } from '../models/driver-profile-dto';
import { IonicLocationDTO } from '../models/ionic-location-dto';
@Injectable({
  providedIn: 'root',
})
class DriverProfileService extends __BaseService {
  static readonly ApiDriverProfileGetPath = '/api/DriverProfile';
  static readonly ApiDriverProfilePostPath = '/api/DriverProfile';
  static readonly ApiDriverProfileByIdGetPath = '/api/DriverProfile/{id}';
  static readonly ApiDriverProfileByIdPutPath = '/api/DriverProfile/{id}';
  static readonly ApiDriverProfileByUserIdByUserIdGetPath = '/api/DriverProfile/ByUserId/{userId}';
  static readonly ApiDriverProfileTrackGpsPostPath = '/api/DriverProfile/TrackGps';
  static readonly ApiDriverProfileTrackGpsIonicPostPath = '/api/DriverProfile/TrackGpsIonic';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of all driver profile records.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiDriverProfileGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DriverProfileSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DriverProfileSummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of all driver profile records.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiDriverProfileGet(Authorization: string): __Observable<Array<DriverProfileSummaryDTO>> {
    return this.ApiDriverProfileGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DriverProfileSummaryDTO>)
    );
  }

  /**
   * Creates a new user with the driver role and given profile.
   * @param params The `DriverProfileService.ApiDriverProfilePostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiDriverProfilePostResponse(params: DriverProfileService.ApiDriverProfilePostParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Creates a new user with the driver role and given profile.
   * @param params The `DriverProfileService.ApiDriverProfilePostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiDriverProfilePost(params: DriverProfileService.ApiDriverProfilePostParams): __Observable<DriverProfileDTO> {
    return this.ApiDriverProfilePostResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Get Profile By DriverProfileId
   * @param params The `DriverProfileService.ApiDriverProfileByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiDriverProfileByIdGetResponse(params: DriverProfileService.ApiDriverProfileByIdGetParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Get Profile By DriverProfileId
   * @param params The `DriverProfileService.ApiDriverProfileByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiDriverProfileByIdGet(params: DriverProfileService.ApiDriverProfileByIdGetParams): __Observable<DriverProfileDTO> {
    return this.ApiDriverProfileByIdGetResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Update an existing DriverProfile
   * @param params The `DriverProfileService.ApiDriverProfileByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiDriverProfileByIdPutResponse(params: DriverProfileService.ApiDriverProfileByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DriverProfile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing DriverProfile
   * @param params The `DriverProfileService.ApiDriverProfileByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiDriverProfileByIdPut(params: DriverProfileService.ApiDriverProfileByIdPutParams): __Observable<null> {
    return this.ApiDriverProfileByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Driver Profile by User Id
   * @param params The `DriverProfileService.ApiDriverProfileByUserIdByUserIdGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiDriverProfileByUserIdByUserIdGetResponse(params: DriverProfileService.ApiDriverProfileByUserIdByUserIdGetParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile/ByUserId/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Get Driver Profile by User Id
   * @param params The `DriverProfileService.ApiDriverProfileByUserIdByUserIdGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiDriverProfileByUserIdByUserIdGet(params: DriverProfileService.ApiDriverProfileByUserIdByUserIdGetParams): __Observable<DriverProfileDTO> {
    return this.ApiDriverProfileByUserIdByUserIdGetResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Receives GPS data for a driver. Pass in city/state if available also
   * @param params The `DriverProfileService.ApiDriverProfileTrackGpsPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `state`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `city`:
   */
  ApiDriverProfileTrackGpsPostResponse(params: DriverProfileService.ApiDriverProfileTrackGpsPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile/TrackGps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Receives GPS data for a driver. Pass in city/state if available also
   * @param params The `DriverProfileService.ApiDriverProfileTrackGpsPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `state`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `city`:
   */
  ApiDriverProfileTrackGpsPost(params: DriverProfileService.ApiDriverProfileTrackGpsPostParams): __Observable<null> {
    return this.ApiDriverProfileTrackGpsPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Receives GPS data for a driver (cordova http post)
   * @param params The `DriverProfileService.ApiDriverProfileTrackGpsIonicPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `locations`:
   */
  ApiDriverProfileTrackGpsIonicPostResponse(params: DriverProfileService.ApiDriverProfileTrackGpsIonicPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.locations;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile/TrackGpsIonic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Receives GPS data for a driver (cordova http post)
   * @param params The `DriverProfileService.ApiDriverProfileTrackGpsIonicPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `locations`:
   */
  ApiDriverProfileTrackGpsIonicPost(params: DriverProfileService.ApiDriverProfileTrackGpsIonicPostParams): __Observable<null> {
    return this.ApiDriverProfileTrackGpsIonicPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DriverProfileService {

  /**
   * Parameters for ApiDriverProfilePost
   */
  export interface ApiDriverProfilePostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: DriverProfileDTO;
  }

  /**
   * Parameters for ApiDriverProfileByIdGet
   */
  export interface ApiDriverProfileByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiDriverProfileByIdPut
   */
  export interface ApiDriverProfileByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: DriverProfileDTO;
  }

  /**
   * Parameters for ApiDriverProfileByUserIdByUserIdGet
   */
  export interface ApiDriverProfileByUserIdByUserIdGetParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiDriverProfileTrackGpsPost
   */
  export interface ApiDriverProfileTrackGpsPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    state?: string;
    longitude?: number;
    latitude?: number;
    city?: string;
  }

  /**
   * Parameters for ApiDriverProfileTrackGpsIonicPost
   */
  export interface ApiDriverProfileTrackGpsIonicPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    locations?: Array<IonicLocationDTO>;
  }
}

export { DriverProfileService }
