import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { MainComponent } from './main/main.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { AdminFactoringEditComponent } from './factoring/factoring-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRegistrationsComponent } from './admin-registrations/admin-registrations.component';
import { PlatformNotificationsComponent } from './platform-notifications/platform-notifications.component';
import { EditNotificationsComponent } from './platform-notifications/edit-notifications/edit-notifications.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { NotificationActionDialogComponent } from './platform-notifications/notification-action-dialog/notification-action-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TabMenuModule,
    EditorModule,
    ButtonModule,
  ],
  declarations: [
    MainComponent,
    AdminFactoringEditComponent,
    AdminRegistrationsComponent,
    PlatformNotificationsComponent,
    EditNotificationsComponent,
    NotificationActionDialogComponent,
  ],
})
export class AdminModule {}
