import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ClientTokenService } from '../../../../services/client-token-service';
import { LoadService } from '../../../../apiclient/services/load.service';
import { Utils } from '../../../../utils/utils';
import { BulkActionName, LoadStatusName, Permission, Role } from '../../../data/static-data';
import { LoadSummaryDTO } from '../../../../apiclient/models';
import { Observable, Subject, defer, merge, of } from 'rxjs';
import { BuyNoBuyDialogComponent } from '../../factoring/buy-no-buy-dialog/buy-no-buy-dialog.component';
import { TableColumn } from 'src/app/shared/models/TableColumn';
import { BulkActionItem } from 'src/app/components/bulk-action/bulk-action-item';
import { ListLoadSummaryDTO } from '../models/list-load-summary-dto';
import { PfsTableBodyComponent } from 'src/app/components/table/pfs-table-body/pfs-table-body.component';
import { BulkActionService } from 'src/services/bulk-action/bulk-action-service';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { AppConfig } from 'src/app/config/app.config';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { LoadFilter } from 'src/app/shared/load/components/list/load-filter/models/load-filter';
import { FileSaverService } from 'src/services/file-saver/file-saver.service';
import { StatusFilter } from 'src/app/shared/components/status-filter/models/status-filter';
import { NavigationService } from 'src/services/navigation/navigation.service';
import { SelectedLoad } from 'src/app/shared/load/components/list/load-action-dialog/models/selected-load';
import { FormControl } from '@angular/forms';
import { StatusFiltersService } from 'src/app/shared/load/services/status-filters/status-filters.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadListComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public cols: TableColumn[];
  public loads: ListLoadSummaryDTO[];
  public showBuyNoBuyDialog = false;
  public showLoadDialog = false;
  public showActionDialog = false;
  public disableApply: boolean = false;
  public bulkCheckAll: boolean = false;
  public isFactoringEnabledForTenant$: Observable<boolean>;
  public actionOptions: BulkActionItem[];
  public actionType: string;
  public selectedLoadId: number;
  public disabled: boolean = false;
  public readonly statuses = this.statusFiltersService.getLoadStatusFilters();
  public readonly statusFormControl = new FormControl();
  public readonly searchFormControl = new FormControl();
  public readonly filterFormControl = new FormControl();

  // Do not display these statuses
  private statusNotEquals: Array<any> = [
    LoadStatusName.Audited,
    LoadStatusName.Invoiced,
    LoadStatusName.Paid,
    LoadStatusName.Archive,
  ];
  private loadsOriginal: LoadSummaryDTO[];
  private searchTerm: string;
  private filter: LoadFilter;
  private statusFilter: StatusFilter;
  private destroy$ = new Subject<void>();

  @ViewChild('buyNoBuyDialog', { static: true }) buyNoBuyDialog: BuyNoBuyDialogComponent;
  @ViewChildren('pfsLoadTable') pfsPaymentTable: QueryList<PfsTableBodyComponent>;

  constructor(
    private router: Router,
    private loadService: LoadService,
    private bulkActionService: BulkActionService,
    private clientToken: ClientTokenService,
    private breadcrumbService: BreadcrumbService,
    private fileSaver: FileSaverService,
    private navigationService: NavigationService,
    private statusFiltersService: StatusFiltersService
  ) {}

  public ngOnInit(): void {
    this.breadcrumbService.setItems('loads');
    this.validateUserRole();
    this.getData();
    this.initCols();
    this.initBulkActionOptions();
    this.listenToFilterChanges();
    this.isFactoringEnabledForTenant$ = this.clientToken.isFactoringEnabledForTenant();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getDataFiltered(): void {
    this.loading = true;

    let filterStatus = null;
    let needsAttention = null;
    if (this.statusFilter) {
      filterStatus = this.statusFilter.value;
    }
    if (filterStatus === LoadStatusName.NeedingAttention) {
      needsAttention = true;
      filterStatus = null;
    }

    const params: LoadService.ApiLoadFilterGetParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: filterStatus ? [filterStatus] : [],
      statusNotEquals: this.statusNotEquals,
      shippingBegin: this.filter?.shippingDate.startDate,
      shippingEnd: this.filter?.shippingDate.endDate,
      deliveryBegin: this.filter?.deliveryDate.startDate,
      deliveryEnd: this.filter?.deliveryDate.endDate,
      needsAttention: needsAttention,
      workOrder: this.filter?.workOrder,
      poNumber: this.filter?.poNumber,
    };

    if (this.filter?.filterBy) {
      params[`${this.filter.filterBy}Contains`] = this.filter.contains;
    }

    this.loadService
      .ApiLoadFilterGet(params)
      .pipe(
        tap((data) => {
          this.setNewLoadData(data);
          this.formatLoadData();
          this.filterTable(this.searchTerm);
        }),
        finalize(() => (this.loading = false)),
        catchError(() => defer(() => this.router.navigate([AppConfig.routes.dashboard]))),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public actionHandler = (actionConfig): void => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  public handleModelEdit(data): void {
    if (data.dataChanged) {
      this.getDataFiltered();
    }
  }

  public openEditLoadDialog(params: { row: ListLoadSummaryDTO }): void {
    this.openLoadDialog(params.row);
  }

  public openCreateLoadDialog(): void {
    this.openLoadDialog(null);
  }

  public openBuyNoBuyDialog(): void {
    this.showBuyNoBuyDialog = true;
  }

  public closeBuyNoBuyDialog(): void {
    this.showBuyNoBuyDialog = false;
  }

  public sortTable(event: any): void {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
    }
    Utils.sortData(event, this.loads);
  }

  public export(): void {
    let filterStatus = null;
    if (this.statusFilter) {
      filterStatus = this.statusFilter.value;
    }
    if (filterStatus === LoadStatusName.NeedingAttention) {
      filterStatus = null;
    }

    const params: LoadService.ApiLoadExportGetParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: filterStatus ? [filterStatus] : [],
      shippingBegin: this.filter?.shippingDate.startDate,
      shippingEnd: this.filter?.shippingDate.endDate,
      deliveryBegin: this.filter?.deliveryDate.startDate,
      deliveryEnd: this.filter?.deliveryDate.endDate,
    };

    if (this.filter?.filterBy) {
      params[`${this.filter.filterBy}Contains`] = this.filter.contains;
    }

    this.loadService
      .ApiLoadExportGet(params)
      .pipe(
        tap((data) => this.fileSaver.saveAs(data, 'Load-list.csv')),
        catchError(() => of(null)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public getSelectedItems(): ListLoadSummaryDTO[] {
    if (this.loads) {
      return this.loads.filter((item) => item.isChecked === true);
    }
    return [];
  }

  public bulkToggleCheckAll() {
    this.loads.forEach((load) => {
      load.isChecked = this.bulkCheckAll;
    });

    this.pfsPaymentTable.forEach((component) => component.updateValues());
  }

  public copyLoadAction(): Promise<void> {
    this.showActionDialog = true;
    this.actionType = BulkActionName.CopyLoad;
    return Promise.resolve();
  }

  public deleteLoadAction(): Promise<void> {
    this.showActionDialog = true;
    this.actionType = BulkActionName.DeleteLoad;
    return Promise.resolve();
  }

  public undeleteLoadAction(): Promise<void> {
    this.showActionDialog = true;
    this.actionType = BulkActionName.UndeleteLoad;
    return Promise.resolve();
  }

  public getSelectedLoads(): SelectedLoad[] {
    const selectedLoads = this.getSelectedItems().map((l) => ({ loadId: l.loadId, loadNumber: l.loadNumber }));
    return selectedLoads;
  }

  public handleBulkActionComplete() {
    this.bulkCheckAll = false;
    this.bulkToggleCheckAll();

    this.getDataFiltered();
  }

  private getData(): void {
    this.loading = true;
    const params: LoadService.ApiLoadFilterGetParams = {
      Authorization: this.clientToken.auth(),
      statusNotEquals: this.statusNotEquals,
    };
    this.loadService.ApiLoadFilterGet(params).subscribe(
      (data) => {
        this.setNewLoadData(data);
        this.formatLoadData();
      },
      (error) => {
        this.router.navigate([AppConfig.routes.dashboard]);
      },
      () => (this.loading = false)
    );
  }

  private setNewLoadData(loadData: LoadSummaryDTO[]): void {
    this.loads = [...loadData];
    this.loadsOriginal = [...loadData];
  }

  private formatLoadData(): void {
    for (const load of this.loads) {
      if (!load.lastTrackingUpdatedUtc) {
        load.lastTrackingUpdatedUtc = ' ';
      }
    }
  }

  private validateUserRole(): void {
    const allowedRoles = [
      Role.SuperAdmin,
      Role.Admin,
      Role.Dispatch,
      Role.LogisticsCarrierAgent,
      Role.LogisticsAccountManager,
      Role.LogisticsSalesRep,
      Role.LogisticsHumanResources,
      Role.LogisticsAdmin,
    ];
    const allowedPermissions = [Permission.LoadRead];
    if (
      !allowedPermissions.some((permission) => this.clientToken.hasPermission(permission)) ||
      !allowedRoles.some((role) => this.clientToken.hasRole(role))
    ) {
      this.navigationService.navigateToDefaultDashboard();
    }
  }

  private listenToFilterChanges(): void {
    merge(
      this.statusFormControl.valueChanges.pipe(tap((status) => this.filterDataByStatus(status))),
      this.searchFormControl.valueChanges.pipe(tap((search) => this.filterTable(search))),
      this.filterFormControl.valueChanges.pipe(tap((filters) => this.applyFilter(filters)))
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private filterDataByStatus(status: StatusFilter): void {
    this.statusFilter = status;

    this.bulkCheckAll = false;
    this.actionOptions = this.bulkActionService.setActions(this.actionOptions, status);
    this.getDataFiltered();
  }

  private filterTable(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if (!!searchTerm) {
      this.loads = this.loadsOriginal.filter(
        (item) =>
          item.loadNumber.toString().includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.workOrder && item.workOrder.toLocaleLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.poNumbers && item.poNumbers.find((p) => p.toLowerCase().includes(searchTerm.toLowerCase()))) ||
          item.customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.loads = this.loadsOriginal;
    }
  }

  private applyFilter(loadFilter: LoadFilter): void {
    this.filter = loadFilter;
    this.getDataFiltered();
  }

  private initCols(): void {
    this.cols = [
      {
        header: '-',
        format: 'checkbox',
        field: 'isChecked',
        width: '3em',
      },
      {
        header: 'Load',
        field: 'loadNumber',
        format: 'actionCellLoad',
        sortable: true,
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditLoadDialog',
          params: {
            row: 'row',
          },
        },
        width: '6em',
      },
      {
        header: 'Status',
        field: 'status',
        sortable: true,
        width: '7em',
      },
      {
        header: 'Work Order/PO/BOL',
        field: 'workOrder',
        sortable: true,
        width: '13em',
      },
      {
        header: 'Tracking',
        field: 'lastTrackingStatus', // changed latestTrackingTimestamp by lastTrackingUpdatedUtc
        format: 'Tracking',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Driver',
        field: 'driver',
        format: 'UserName',
        sortableField: 'driver.name',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Ship Date',
        field: 'shipDate',
        format: 'Date',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Delivery Date',
        field: 'deliveryDate',
        format: 'Date',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Customer',
        field: 'customer',
        sortableField: 'customer.name',
        format: 'LocationName',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Shipper',
        field: 'shipper',
        sortableField: 'shipper.name',
        format: 'LocationName',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Consignee',
        field: 'consignee',
        sortableField: 'consignee.name',
        format: 'LocationName',
        sortable: true,
        width: '10em',
      },
    ];
  }

  private initBulkActionOptions(): void {
    const activeStatus: StatusFilter = {
      label: 'Active',
      value: null,
      active: true,
    };

    this.actionOptions = [
      {
        label: 'Copy Load',
        value: BulkActionName.CopyLoad,
        maximumItemsAllowed: 1,
        action: () => this.copyLoadAction(),
      },
      {
        label: 'Delete Load',
        value: BulkActionName.DeleteLoad,
        maximumItemsAllowed: 5,
        action: () => this.deleteLoadAction(),
      },
      {
        label: 'Undelete Load',
        value: BulkActionName.UndeleteLoad,
        maximumItemsAllowed: null,
        action: () => this.undeleteLoadAction(),
      },
    ];

    this.actionOptions = this.bulkActionService.setActions(this.actionOptions, activeStatus);
  }

  private openLoadDialog(load: ListLoadSummaryDTO): void {
    this.selectedLoadId = load === null ? 0 : load.loadId;
    this.disabled = load?.isDeleted;
    this.showLoadDialog = true;
  }
}
