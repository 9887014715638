<div class="autoCompleteWrapper">
  <p-autoComplete
    styleClass="col-12 p-0"
    [(ngModel)]="selected"
    field="name"
    [suggestions]="results"
    (completeMethod)="search($event)"
    (onSelect)="onSelect()"
    (onClear)="onClear()"
    [forceSelection]="true"
    [dropdown]="true"
    minLength="1"
    emptyMessage="No Results"
    [disabled]="disabled"
  >
  </p-autoComplete>
  <span
    class="autoCompleteClear"
    *ngIf="selected && selected.id && selected.id !== null && !readonly"
    (click)="onClear()"
  >
    <i class="ui-dropdown-clear-icon pi pi-times"></i>
  </span>
</div>

<app-company-address-quick-add
  *ngIf="dialogVisible"
  [(visible)]="dialogVisible"
  [quickAddType]="companyAddressType"
  [companyName]="searchValue"
  (companySelectedEvent)="newAddressCreated($event)"
  (dialogClosedEvent)="dialogClosed()"
></app-company-address-quick-add>
