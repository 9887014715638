/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tenant } from '../models/tenant';
import { RolePermissionsDTO } from '../models/role-permissions-dto';
import { LoadLegStop } from '../models/load-leg-stop';
import { TokenDTO } from '../models/token-dto';
@Injectable({
  providedIn: 'root',
})
class SuperAdminService extends __BaseService {
  static readonly ApiSuperAdminUserVerifyEmailPostPath = '/api/SuperAdmin/User/VerifyEmail';
  static readonly ApiSuperAdminUserResetLockoutPostPath = '/api/SuperAdmin/User/ResetLockout';
  static readonly ApiSuperAdminUserAllUsersPostPath = '/api/SuperAdmin/User/AllUsers';
  static readonly ApiSuperAdminRoleAllPostPath = '/api/SuperAdmin/Role/All';
  static readonly ApiSuperAdminUserDeletePostPath = '/api/SuperAdmin/User/Delete';
  static readonly ApiSuperAdminGeoCodeStopsGetPath = '/api/SuperAdmin/GeoCodeStops';
  static readonly ApiSuperAdminUserImpersonateGetPath = '/api/SuperAdmin/User/Impersonate';
  static readonly ApiSuperAdminTenantExemptGetPath = '/api/SuperAdmin/Tenant/Exempt';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Set a user's email status to confirmed
   * @param params The `SuperAdminService.ApiSuperAdminUserVerifyEmailPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserVerifyEmailPostResponse(params: SuperAdminService.ApiSuperAdminUserVerifyEmailPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/VerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set a user's email status to confirmed
   * @param params The `SuperAdminService.ApiSuperAdminUserVerifyEmailPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserVerifyEmailPost(params: SuperAdminService.ApiSuperAdminUserVerifyEmailPostParams): __Observable<null> {
    return this.ApiSuperAdminUserVerifyEmailPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Unlocks a user
   * @param params The `SuperAdminService.ApiSuperAdminUserResetLockoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserResetLockoutPostResponse(params: SuperAdminService.ApiSuperAdminUserResetLockoutPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/ResetLockout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unlocks a user
   * @param params The `SuperAdminService.ApiSuperAdminUserResetLockoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserResetLockoutPost(params: SuperAdminService.ApiSuperAdminUserResetLockoutPostParams): __Observable<null> {
    return this.ApiSuperAdminUserResetLockoutPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all tenants and the users associated with them
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiSuperAdminUserAllUsersPostResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<Tenant>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/AllUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Tenant>>;
      })
    );
  }
  /**
   * Returns all tenants and the users associated with them
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiSuperAdminUserAllUsersPost(Authorization: string): __Observable<Array<Tenant>> {
    return this.ApiSuperAdminUserAllUsersPostResponse(Authorization).pipe(
      __map(_r => _r.body as Array<Tenant>)
    );
  }

  /**
   * Returns all roles with their IDs and permissions
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiSuperAdminRoleAllPostResponse(Authorization: string): __Observable<__StrictHttpResponse<RolePermissionsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/Role/All`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RolePermissionsDTO>;
      })
    );
  }
  /**
   * Returns all roles with their IDs and permissions
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiSuperAdminRoleAllPost(Authorization: string): __Observable<RolePermissionsDTO> {
    return this.ApiSuperAdminRoleAllPostResponse(Authorization).pipe(
      __map(_r => _r.body as RolePermissionsDTO)
    );
  }

  /**
   * Deletes a user from database and cognito.
   * This is a testing operation that is not intended for production.
   * @param params The `SuperAdminService.ApiSuperAdminUserDeletePostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserDeletePostResponse(params: SuperAdminService.ApiSuperAdminUserDeletePostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/Delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a user from database and cognito.
   * This is a testing operation that is not intended for production.
   * @param params The `SuperAdminService.ApiSuperAdminUserDeletePostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  ApiSuperAdminUserDeletePost(params: SuperAdminService.ApiSuperAdminUserDeletePostParams): __Observable<null> {
    return this.ApiSuperAdminUserDeletePostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Utility function to geocode all stops. Used for local development
   * Will only geocode if stop las city/state or zip AND lat/long is 0
   * @param params The `SuperAdminService.ApiSuperAdminGeoCodeStopsGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `overwrite`:
   *
   * @return Success
   */
  ApiSuperAdminGeoCodeStopsGetResponse(params: SuperAdminService.ApiSuperAdminGeoCodeStopsGetParams): __Observable<__StrictHttpResponse<Array<LoadLegStop>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.overwrite != null) __params = __params.set('overwrite', params.overwrite.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SuperAdmin/GeoCodeStops`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadLegStop>>;
      })
    );
  }
  /**
   * Utility function to geocode all stops. Used for local development
   * Will only geocode if stop las city/state or zip AND lat/long is 0
   * @param params The `SuperAdminService.ApiSuperAdminGeoCodeStopsGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `overwrite`:
   *
   * @return Success
   */
  ApiSuperAdminGeoCodeStopsGet(params: SuperAdminService.ApiSuperAdminGeoCodeStopsGetParams): __Observable<Array<LoadLegStop>> {
    return this.ApiSuperAdminGeoCodeStopsGetResponse(params).pipe(
      __map(_r => _r.body as Array<LoadLegStop>)
    );
  }

  /**
   * Generates a special token for the super admin to log into another tenant.
   * Will copy the requested user's roles
   * @param params The `SuperAdminService.ApiSuperAdminUserImpersonateGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * @return The JWT access token
   */
  ApiSuperAdminUserImpersonateGetResponse(params: SuperAdminService.ApiSuperAdminUserImpersonateGetParams): __Observable<__StrictHttpResponse<TokenDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SuperAdmin/User/Impersonate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenDTO>;
      })
    );
  }
  /**
   * Generates a special token for the super admin to log into another tenant.
   * Will copy the requested user's roles
   * @param params The `SuperAdminService.ApiSuperAdminUserImpersonateGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * @return The JWT access token
   */
  ApiSuperAdminUserImpersonateGet(params: SuperAdminService.ApiSuperAdminUserImpersonateGetParams): __Observable<TokenDTO> {
    return this.ApiSuperAdminUserImpersonateGetResponse(params).pipe(
      __map(_r => _r.body as TokenDTO)
    );
  }

  /**
   * Allows super admin to make a tenant exempt from subscription billing.
   * @param params The `SuperAdminService.ApiSuperAdminTenantExemptGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `isExempt`:
   *
   * @return Success
   */
  ApiSuperAdminTenantExemptGetResponse(params: SuperAdminService.ApiSuperAdminTenantExemptGetParams): __Observable<__StrictHttpResponse<TokenDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.isExempt != null) __params = __params.set('isExempt', params.isExempt.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SuperAdmin/Tenant/Exempt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenDTO>;
      })
    );
  }
  /**
   * Allows super admin to make a tenant exempt from subscription billing.
   * @param params The `SuperAdminService.ApiSuperAdminTenantExemptGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `isExempt`:
   *
   * @return Success
   */
  ApiSuperAdminTenantExemptGet(params: SuperAdminService.ApiSuperAdminTenantExemptGetParams): __Observable<TokenDTO> {
    return this.ApiSuperAdminTenantExemptGetResponse(params).pipe(
      __map(_r => _r.body as TokenDTO)
    );
  }
}

module SuperAdminService {

  /**
   * Parameters for ApiSuperAdminUserVerifyEmailPost
   */
  export interface ApiSuperAdminUserVerifyEmailPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for ApiSuperAdminUserResetLockoutPost
   */
  export interface ApiSuperAdminUserResetLockoutPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for ApiSuperAdminUserDeletePost
   */
  export interface ApiSuperAdminUserDeletePostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for ApiSuperAdminGeoCodeStopsGet
   */
  export interface ApiSuperAdminGeoCodeStopsGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    overwrite?: boolean;
  }

  /**
   * Parameters for ApiSuperAdminUserImpersonateGet
   */
  export interface ApiSuperAdminUserImpersonateGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    userId?: number;
    tenantId?: number;
  }

  /**
   * Parameters for ApiSuperAdminTenantExemptGet
   */
  export interface ApiSuperAdminTenantExemptGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    tenantId?: number;
    isExempt?: boolean;
  }
}

export { SuperAdminService }
