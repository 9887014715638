/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyDTO } from '../models/company-dto';
import { CompanySummaryDTO } from '../models/company-summary-dto';
import { CompanyQuickAddDTO } from '../models/company-quick-add-dto';
import { ContactDTO } from '../models/contact-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { CompanyImportCsvDTO } from '../models/company-import-csv-dto';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly ApiCompanyGetPath = '/api/Company';
  static readonly ApiCompanyPostPath = '/api/Company';
  static readonly ApiCompanyAllGetPath = '/api/Company/all';
  static readonly ApiCompanyExportAllGetPath = '/api/Company/export/all';
  static readonly ApiCompanyBilltoGetPath = '/api/Company/billto';
  static readonly ApiCompanyExportBilltoGetPath = '/api/Company/export/billto';
  static readonly ApiCompanyShipperGetPath = '/api/Company/shipper';
  static readonly ApiCompanyExportShipperGetPath = '/api/Company/export/shipper';
  static readonly ApiCompanyConsigneeGetPath = '/api/Company/consignee';
  static readonly ApiCompanyExportConsigneeGetPath = '/api/Company/export/consignee';
  static readonly ApiCompanyByIdGetPath = '/api/Company/{id}';
  static readonly ApiCompanyByIdPutPath = '/api/Company/{id}';
  static readonly ApiCompanyByIdDeletePath = '/api/Company/{id}';
  static readonly ApiCompanyQuickAddPostPath = '/api/Company/QuickAdd';
  static readonly ApiCompanyByIdContactsGetPath = '/api/Company/{id}/contacts';
  static readonly ApiCompanySearchByTermGetPath = '/api/Company/search/{term}';
  static readonly ApiCompanyImportPostPath = '/api/Company/Import';
  static readonly ApiCompanyImportTemplateGetPath = '/api/Company/ImportTemplate';
  static readonly ApiCompanyImportTemplateModelGetPath = '/api/Company/ImportTemplateModel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of all company record. This is the MASTER record only, meaning no address/location information is returned.
   * use /all to get the company list with the first address
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanyDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyDTO>>;
      })
    );
  }
  /**
   * Summary list of all company record. This is the MASTER record only, meaning no address/location information is returned.
   * use /all to get the company list with the first address
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyGet(Authorization: string): __Observable<Array<CompanyDTO>> {
    return this.ApiCompanyGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanyDTO>)
    );
  }

  /**
   * Create a new company
   * @param params The `CompanyService.ApiCompanyPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyPostResponse(params: CompanyService.ApiCompanyPostParams): __Observable<__StrictHttpResponse<CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO>;
      })
    );
  }
  /**
   * Create a new company
   * @param params The `CompanyService.ApiCompanyPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyPost(params: CompanyService.ApiCompanyPostParams): __Observable<CompanyDTO> {
    return this.ApiCompanyPostResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO)
    );
  }

  /**
   * Summary list of ALL companies.
   * Returns address/contact info from one of the following in precedence order: BillTo, Shipper, Consignee
   * Type and CompanyAddressId will contain info on which address was used. Master indicates that no addresses were found
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyAllGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of ALL companies.
   * Returns address/contact info from one of the following in precedence order: BillTo, Shipper, Consignee
   * Type and CompanyAddressId will contain info on which address was used. Master indicates that no addresses were found
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyAllGet(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.ApiCompanyAllGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /all
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportAllGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /all
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportAllGet(Authorization: string): __Observable<Blob> {
    return this.ApiCompanyExportAllGetResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of billto companies.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyBilltoGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/billto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of billto companies.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyBilltoGet(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.ApiCompanyBilltoGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /billto
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportBilltoGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/billto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /billto
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportBilltoGet(Authorization: string): __Observable<Blob> {
    return this.ApiCompanyExportBilltoGetResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of shipper companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyShipperGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/shipper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of shipper companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyShipperGet(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.ApiCompanyShipperGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /shipper
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportShipperGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/shipper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /shipper
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportShipperGet(Authorization: string): __Observable<Blob> {
    return this.ApiCompanyExportShipperGetResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of consignee companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyConsigneeGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/consignee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of consignee companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyConsigneeGet(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.ApiCompanyConsigneeGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /consignee
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportConsigneeGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/consignee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /consignee
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyExportConsigneeGet(Authorization: string): __Observable<Blob> {
    return this.ApiCompanyExportConsigneeGetResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Company detail
   * @param params The `CompanyService.ApiCompanyByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyByIdGetResponse(params: CompanyService.ApiCompanyByIdGetParams): __Observable<__StrictHttpResponse<CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO>;
      })
    );
  }
  /**
   * Company detail
   * @param params The `CompanyService.ApiCompanyByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyByIdGet(params: CompanyService.ApiCompanyByIdGetParams): __Observable<CompanyDTO> {
    return this.ApiCompanyByIdGetResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO)
    );
  }

  /**
   * Update an existing company
   * @param params The `CompanyService.ApiCompanyByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiCompanyByIdPutResponse(params: CompanyService.ApiCompanyByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing company
   * @param params The `CompanyService.ApiCompanyByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiCompanyByIdPut(params: CompanyService.ApiCompanyByIdPutParams): __Observable<null> {
    return this.ApiCompanyByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `CompanyService.ApiCompanyByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyByIdDeleteResponse(params: CompanyService.ApiCompanyByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `CompanyService.ApiCompanyByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyByIdDelete(params: CompanyService.ApiCompanyByIdDeleteParams): __Observable<null> {
    return this.ApiCompanyByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a new minimum company record
   * @param params The `CompanyService.ApiCompanyQuickAddPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyQuickAddPostResponse(params: CompanyService.ApiCompanyQuickAddPostParams): __Observable<__StrictHttpResponse<CompanyQuickAddDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/QuickAdd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyQuickAddDTO>;
      })
    );
  }
  /**
   * Create a new minimum company record
   * @param params The `CompanyService.ApiCompanyQuickAddPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyQuickAddPost(params: CompanyService.ApiCompanyQuickAddPostParams): __Observable<CompanyQuickAddDTO> {
    return this.ApiCompanyQuickAddPostResponse(params).pipe(
      __map(_r => _r.body as CompanyQuickAddDTO)
    );
  }

  /**
   * Get contacts for an company
   * @param params The `CompanyService.ApiCompanyByIdContactsGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyByIdContactsGetResponse(params: CompanyService.ApiCompanyByIdContactsGetParams): __Observable<__StrictHttpResponse<Array<ContactDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}/contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContactDTO>>;
      })
    );
  }
  /**
   * Get contacts for an company
   * @param params The `CompanyService.ApiCompanyByIdContactsGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyByIdContactsGet(params: CompanyService.ApiCompanyByIdContactsGetParams): __Observable<Array<ContactDTO>> {
    return this.ApiCompanyByIdContactsGetResponse(params).pipe(
      __map(_r => _r.body as Array<ContactDTO>)
    );
  }

  /**
   * Searches for companies by name. Returns CompanyId and Name
   * @param params The `CompanyService.ApiCompanySearchByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanySearchByTermGetResponse(params: CompanyService.ApiCompanySearchByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/search/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for companies by name. Returns CompanyId and Name
   * @param params The `CompanyService.ApiCompanySearchByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanySearchByTermGet(params: CompanyService.ApiCompanySearchByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanySearchByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Import a company CSV
   * @param params The `CompanyService.ApiCompanyImportPostParams` containing the following parameters:
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyImportPostResponse(params: CompanyService.ApiCompanyImportPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/Import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Import a company CSV
   * @param params The `CompanyService.ApiCompanyImportPostParams` containing the following parameters:
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyImportPost(params: CompanyService.ApiCompanyImportPostParams): __Observable<null> {
    return this.ApiCompanyImportPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns CSV file template for the import operation
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyImportTemplateGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/ImportTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file template for the import operation
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ApiCompanyImportTemplateGet(Authorization: string): __Observable<Blob> {
    return this.ApiCompanyImportTemplateGetResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * DO NOT USE - this is simply a way to show the csv template validation in swagger
   * @param params The `CompanyService.ApiCompanyImportTemplateModelGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `model`:
   */
  ApiCompanyImportTemplateModelGetResponse(params: CompanyService.ApiCompanyImportTemplateModelGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.model;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/ImportTemplateModel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * DO NOT USE - this is simply a way to show the csv template validation in swagger
   * @param params The `CompanyService.ApiCompanyImportTemplateModelGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `model`:
   */
  ApiCompanyImportTemplateModelGet(params: CompanyService.ApiCompanyImportTemplateModelGetParams): __Observable<null> {
    return this.ApiCompanyImportTemplateModelGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CompanyService {

  /**
   * Parameters for ApiCompanyPost
   */
  export interface ApiCompanyPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyDTO;
  }

  /**
   * Parameters for ApiCompanyByIdGet
   */
  export interface ApiCompanyByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyByIdPut
   */
  export interface ApiCompanyByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyDTO;
  }

  /**
   * Parameters for ApiCompanyByIdDelete
   */
  export interface ApiCompanyByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyQuickAddPost
   */
  export interface ApiCompanyQuickAddPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyQuickAddDTO;
  }

  /**
   * Parameters for ApiCompanyByIdContactsGet
   */
  export interface ApiCompanyByIdContactsGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanySearchByTermGet
   */
  export interface ApiCompanySearchByTermGetParams {
    term: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyImportPost
   */
  export interface ApiCompanyImportPostParams {

    /**
     * Upload File
     */
    file: Blob;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyImportTemplateModelGet
   */
  export interface ApiCompanyImportTemplateModelGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    model?: CompanyImportCsvDTO;
  }
}

export { CompanyService }
