<ng-template #loading>
  <p-blockUI [blocked]="true">
    <div class="middle">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </p-blockUI>
</ng-template>
<div class="grid grid-nogutter">
  <div class="col-12 p-0">
    <p-card styleClass="shadow-none">
      <div class="layout-content-container" *ngIf="tenant$ | async as tenant; else loading">
        <div class="flex flex-column px-0 pb-3">
          <div *ngIf="isSystemAdminView" class="pb-2" data-test="back-button">
            <a class="py-2 pr-2" [routerLink]="['..']">
              <i class="fas fa-angle-left"></i>
              Back
            </a>
          </div>
          <h1>
            {{ isSystemAdminView ? tenant.name : 'Settings' }}
          </h1>
        </div>
        <p-tabView [activeIndex]="tabIndex" (onChange)="handleTabChange($event, tenant)">
          <p-tabPanel header="Company Profile">
            <app-tenant-edit [tenant]="tenant"></app-tenant-edit>
          </p-tabPanel>
          <p-tabPanel header="Accounting" *ngIf="tenant.isLogistics">
            <app-tenant-accounting [tenant]="tenant"></app-tenant-accounting>
          </p-tabPanel>
          <p-tabPanel header="Users">
            <app-user-list [tenantId]="tenantId"></app-user-list>
          </p-tabPanel>
          <p-tabPanel header="Notes">
            <app-settings-notes [tenant]="tenant" [isSuperAdmin]="isSuperAdmin"></app-settings-notes>
          </p-tabPanel>
          <p-tabPanel
            header="Tenant Notes"
            *ngIf="clientToken.hasPermission('TenantEditAll') && clientToken.hasPermission('SystemSettingEdit')"
          >
            <app-tenant-notes [tenant]="tenant"></app-tenant-notes>
          </p-tabPanel>
        </p-tabView>
      </div>
    </p-card>
  </div>
</div>
