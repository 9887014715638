import { Component, OnDestroy, Input, Output, EventEmitter, Injector } from '@angular/core';
import { LoadService, LogisticsLoadService } from 'src/apiclient/v1.1/services';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { ClientTokenService } from 'src/services/client-token-service';
import { HttpErrorResponse } from '@angular/common/http';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { BulkActionName } from 'src/app/data/static-data';
import { CopyLoadService } from 'src/services/copy/copy-load-service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoadDTO, LogisticsLoadDTO } from 'src/apiclient/v1.1/models';
import { SelectedLoad } from './models/selected-load';
import { CopyLogisticsLoadService } from 'src/services/copy/copy-logistics-load.service';

@Component({
  selector: 'app-load-action-dialog',
  templateUrl: './load-action-dialog.component.html',
  styleUrls: ['./load-action-dialog.component.scss'],
})
export class LoadActionDialogComponent extends DtoFormBase<any> implements OnDestroy {
  @Input() selectedLoads: SelectedLoad[];
  @Input() showDialog: boolean;
  @Input() actionType: string;
  @Output() showDialogChange = new EventEmitter<boolean>();
  @Output() actionComplete = new EventEmitter<void>();

  public readonly BulkActionName = BulkActionName;
  public dialogHeaderText: string;
  public dialogButtonText: string;
  public dialogButtonIcon: string;
  public dialogProgressText: string;
  public actionInProgress: boolean;

  private destroy$ = new Subject<void>();

  public loadCopyForm = this.formBuilder.group({
    numberOfCopies: [1, [Validators.required, Validators.max(10)]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private loadService: LoadService,
    private logisticsLoadService: LogisticsLoadService,
    private clientTokenService: ClientTokenService,
    private copyService: CopyLoadService,
    private copyLogisticsLoadService: CopyLogisticsLoadService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initDialog();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleShowDialog(): void {
    this.showDialog = !this.showDialog;
    this.showDialogChange.emit(this.showDialog);
  }

  public handleAction(): void {
    if (this.selectedLoads && this.selectedLoads.length) {
      switch (this.actionType) {
        case BulkActionName.CopyLoad: {
          this.handleCopyAction();
          break;
        }
        case BulkActionName.DeleteLoad: {
          this.handleDeleteAction();
          break;
        }
        case BulkActionName.UndeleteLoad: {
          this.handleUndeleteAction();
          break;
        }
      }
    }
  }

  private initDialog() {
    switch (this.actionType) {
      case BulkActionName.CopyLoad: {
        this.dialogHeaderText = 'Confirm Copy Load';
        this.dialogButtonText = 'Copy';
        this.dialogButtonIcon = 'pi pi-copy';
        this.dialogProgressText = 'Copying Load(s), Please Wait...';
        break;
      }
      case BulkActionName.DeleteLoad: {
        this.dialogHeaderText = 'Confirm Delete Load(s)';
        this.dialogButtonText = 'Delete';
        this.dialogButtonIcon = 'pi pi-trash';
        this.dialogProgressText = 'Deleting Load(s), Please Wait...';
        break;
      }
      case BulkActionName.UndeleteLoad: {
        this.dialogHeaderText = 'Confirm Undelete Load(s)';
        this.dialogButtonText = 'Undelete';
        this.dialogButtonIcon = 'pi pi-undo';
        this.dialogProgressText = 'Undeleting Load(s), Please Wait...';
        break;
      }
    }
  }

  private handleDeleteAction(): void {
    this.actionInProgress = true;

    combineLatest(this.selectedLoads.map((selectedLoad) => this.deleteLoad(selectedLoad.loadId)))
      .pipe(
        tap(() => {
          this._NS.success('Load(s) deleted!', 'The selected loads were deleted successfully.');
        }),
        catchError((e) => this.handleActionError(e)),
        takeUntil(this.destroy$),
        finalize(() => {
          this.actionInProgress = false;
          this.toggleShowDialog();
          this.actionComplete.emit();
        })
      )
      .subscribe();
  }

  private handleUndeleteAction(): void {
    this.actionInProgress = true;

    combineLatest(this.selectedLoads.map((selectedLoad) => this.undeleteLoad(selectedLoad.loadId)))
      .pipe(
        tap(() => {
          this._NS.success('Load(s) undeleted!', 'The selected loads were undeleted successfully.');
        }),
        catchError((e) => this.handleActionError(e)),
        takeUntil(this.destroy$),
        finalize(() => {
          this.actionInProgress = false;
          this.toggleShowDialog();
          this.actionComplete.emit();
        })
      )
      .subscribe();
  }

  private handleCopyAction(): void {
    if (this.loadCopyForm.valid) {
      this.actionInProgress = true;

      of(this.clientToken.isLogisticsTenant())
        .pipe(
          switchMap((isLogistics) => (isLogistics ? this.handleLogisticsLoadCopy() : this.handleCarrierLoadCopy())),
          catchError((e) => this.handleError(e)),
          takeUntil(this.destroy$),
          finalize(() => {
            this.actionInProgress = false;
            this.toggleShowDialog();
            this.actionComplete.emit();
          })
        )
        .subscribe();
    }
  }

  private handleCarrierLoadCopy(): Observable<void> {
    return this.loadService
      .GetLoadById({
        id: this.getSingleSelectedLoadId(),
        Authorization: this.clientTokenService.auth(),
      })
      .pipe(switchMap((load) => this.copyCarrierLoad(load)));
  }

  private copyCarrierLoad(load: LoadDTO): Observable<any> {
    const loadCopy = this.copyService.copyLoad(load);
    const numberOfCopies = this.loadCopyForm.get('numberOfCopies').value;

    const saveLoadOperations$ = Array.from({ length: numberOfCopies }, () => this.saveCarrierLoadCopy(loadCopy));

    return combineLatest(saveLoadOperations$);
  }

  private saveCarrierLoadCopy(newLoad: LoadDTO): Observable<LoadDTO> {
    return this.loadService.Post({
      Authorization: this.clientTokenService.auth(),
      body: newLoad,
    });
  }

  private handleLogisticsLoadCopy(): Observable<void> {
    return this.logisticsLoadService
      .GetLoad({
        id: this.getSingleSelectedLoadId(),
        Authorization: this.clientTokenService.auth(),
      })
      .pipe(switchMap((load) => this.copyLogisticsLoad(load)));
  }

  private copyLogisticsLoad(load: LogisticsLoadDTO): Observable<any> {
    const loadCopy = this.copyLogisticsLoadService.copyLogisticsLoad(load);
    const numberOfCopies = this.loadCopyForm.get('numberOfCopies').value;

    const saveLoadOperations$ = Array.from({ length: numberOfCopies }, () => this.saveLogisticsLoadCopy(loadCopy));

    return combineLatest(saveLoadOperations$);
  }

  private saveLogisticsLoadCopy(newLoad: LogisticsLoadDTO): Observable<LogisticsLoadDTO> {
    return this.logisticsLoadService.CreateLoad({
      Authorization: this.clientTokenService.auth(),
      body: newLoad,
    });
  }

  private getSingleSelectedLoadId(): number {
    return this.selectedLoads[0].loadId;
  }

  private deleteLoad(loadId: number): Observable<void> {
    return of(this.clientTokenService.isLogisticsTenant()).pipe(
      switchMap((isLogistics) => {
        if (isLogistics) {
          return this.logisticsLoadService.Delete({
            id: loadId,
            Authorization: this.clientTokenService.auth(),
          });
        } else {
          return this.loadService.Delete({
            id: loadId,
            Authorization: this.clientTokenService.auth(),
          });
        }
      })
    );
  }

  private undeleteLoad(loadId: number): Observable<void> {
    return of(this.clientTokenService.isLogisticsTenant()).pipe(
      switchMap((isLogistics) => {
        if (isLogistics) {
          return this.logisticsLoadService.UnDelete({
            id: loadId,
            Authorization: this.clientTokenService.auth(),
          });
        } else {
          this.loadService.Undelete({
            loadId: loadId,
            Authorization: this.clientTokenService.auth(),
          });
        }
      })
    );
  }

  private handleActionError(error: HttpErrorResponse): Observable<void> {
    const deleteErrorKey = 'DeleteError';
    const undeleteErrorKey = 'UndeleteError';

    if (error.status === 400 && error.error) {
      const problems = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;

      if (problems && problems[deleteErrorKey]) {
        const deleteErrorMessage = problems[deleteErrorKey];
        return of(this._NS.error('Unable To Delete Loads', deleteErrorMessage));
      }

      if (problems && problems[undeleteErrorKey]) {
        const undeleteErrorMessage = problems[undeleteErrorKey];
        return of(this._NS.error('Unable To Undelete Loads', undeleteErrorMessage));
      }
    }

    return of(this._NS.error('Action failed', 'Please select the loads and try again.'));
  }
}
