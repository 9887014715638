<div class="col-12 action-bar fixed left-0 w-full py-3 px-5 flex justify-content-end">
  <div class="action-buttons flex align-content-center">
    <button
      id="secondary-action-button"
      type="button"
      pButton
      [icon]="secondaryActionIcon"
      [label]="secondaryActionLabel"
      (click)="secondaryAction()"
      class="ui-button-secondary mr-2 w-6rem"
    ></button>
    <button
      id="primary-action-button"
      type="button"
      pButton
      [disabled]="disablePrimary"
      [icon]="primaryActionIcon"
      [label]="primaryActionLabel"
      (click)="primaryAction()"
      class="ui-button-primary w-6rem"
    ></button>
  </div>
</div>
