<p-panel
  *ngFor="let load of loads"
  [toggleable]="true"
  [collapsed]="true"
  expandIcon="fa fa-chevron-down"
  collapseIcon="fa fa-chevron-up"
  [style]="{ width: 'calc(100% - 18px)', margin: '0px 0px 5px' }"
  styleClass="transparent-panel-titlebar"
  class="{{ load.drivers[0].name === null ? 'nodriver' : '' }}"
>
  <p-header>
    <div class="ui-g-1 pad4">
      <i
        class="fas fa-clock {{
          load.lastTrackingStatus === 'Delayed' ? 'delayed' : load.lastTrackingStatus === 'OnTime' ? 'ontime' : 'silver'
        }}"
      ></i>
    </div>
    <div class="ui-g-2 pfsblue pad4">
      <b class="fauxlink" (click)="loadNumClicked(load.loadId)">
        {{ load.loadNumber }}
      </b>
    </div>
    <div class="ui-g-7 pad4 no-bold">
      {{ load.destination }}
    </div>
    <div class="ui-g-1 pad4">
      <i
        *ngIf="load.alerts && load.alerts.length > 0"
        class="fa fa-exclamation-triangle warning"
        pTooltip="{{ load.alerts.join(', ') }}"
      ></i>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-1 pad4left">
        <i class="fas fa-truck silver"></i>
      </div>
      <div class="ui-g-11 pad4left">
        <div class="fauxlink" (click)="driverNameClicked(load.drivers[0].truckNumber)">{{ load.drivers[0].name }}</div>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1 pad4left">
        <i class="fas fa-mobile-alt silver"></i>
      </div>
      <div class="ui-g-11 pad4left pfsblue">
        {{ load.drivers[0].phone | formatPhone }}
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <div class="ui-g-11 pad4left">
        <div class="ui-g-4 ui-g-nopad">
          {{ load.shipDate || 'TBD' }}
        </div>
        <div class="ui-g-8 ui-g-nopad">
          {{ load.origin || 'TBD' }}
        </div>
      </div>
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <div class="ui-g-11 pad4left">
        <div class="ui-g-4 ui-g-nopad">
          {{ load.deliveryDate || 'TBD' }}
        </div>
        <div class="ui-g-8 ui-g-nopad">
          {{ load.destination || 'TBD' }}
        </div>
      </div>
    </div>

    <div class="ui-g-12" *ngIf="load.lastTrackingStatus">
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <div class="ui-g-4 pad4left">
        {{ load.lastTrackingStatus }}
      </div>
      <div class="ui-g-7 pad4left gray">
        {{ getFormatedDateLong(load.lastTrackingUpdatedUtc) }}
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1 pad4left"> &nbsp; </div>
      <app-leg-tracking
        class="ui-g-11 pad4left fauxlink"
        [driverName]="load.drivers[0].name"
        [loadId]="load.loadId"
        [loadNumber]="load.loadNumber"
        [loadLegId]="load.loadLegId"
        [viewMode]="trackingViewMode"
        (journalChanged)="updateTracking(load.loadId)"
      >
      </app-leg-tracking>
    </div>
  </div>
</p-panel>
