<div [formGroup]="wizardStep.stepForm" class="grid">
  <h1 class="col-12 form-header">Mailing Address</h1>

  <app-address-v3
    class="col-12"
    [formControlName]="formFields.MailingAddress"
    [validationMessages]="wizardStep.customValidationMessages"
    [touched]="wizardStep.stepForm.controls[formFields.MailingAddress].touched"
    [dirty]="wizardStep.stepForm.controls[formFields.MailingAddress].dirty"
  ></app-address-v3>
  <p-checkbox
    class="col-12 flex justify-content-center p-2"
    formControlName="sameAsMailing"
    binary="true"
    label="Same as Physical Address"
  ></p-checkbox>
</div>

<app-wizard-navigation (previousStep)="previous()" (nextStep)="next()"></app-wizard-navigation>
