import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceDTO } from '../apiclient/v1.1/models';
import { InvoiceFactoringService } from '../apiclient/v1.1/services';
import { FundingStatus } from '../app/components/funding-status-icon/funding-status';
import { DisplayLoadSummaryDTO } from '../app/shared/interfaces/DisplayLoadSummaryDTO';
import { ClientTokenService } from './client-token-service';

@Injectable({ providedIn: 'root' })
export class LoadFundingStatusService {
  constructor(private invoiceService: InvoiceFactoringService, private clientTokenService: ClientTokenService) {}

  public setFundingStatusesForLoads(loads: DisplayLoadSummaryDTO[]): Observable<DisplayLoadSummaryDTO[]> {
    const invoiceUuids = loads.map((l) => l.factorCloudInvoiceIntegrationId);

    return this.invoiceService
      .Get({
        Authorization: this.clientTokenService.auth(),
        InvoiceUuids: invoiceUuids,
      })
      .pipe(map((invoices) => this.mapFundingStatusesToLoad(invoices, loads)));
  }

  private mapFundingStatusesToLoad(invoices: InvoiceDTO[], loads: DisplayLoadSummaryDTO[]): DisplayLoadSummaryDTO[] {
    loads.forEach((load) => {
      const invoice = invoices.find((i) => i.uuid === load.factorCloudInvoiceIntegrationId);
      const fundingStatus = this.getFundingStatusForLoad(load, invoice);

      load.fundingStatus = fundingStatus;
    });

    return loads;
  }

  private getFundingStatusForLoad(load: DisplayLoadSummaryDTO, invoice: InvoiceDTO): string {
    let fundingStatus;

    if (load.invoiceDate) {
      fundingStatus = FundingStatus.Queued;
    }

    if (load.factorCloudInvoiceIntegrationId) {
      fundingStatus = FundingStatus.Submitted;
    }

    if (invoice) {
      switch (invoice.status) {
        case FundingStatus.OnHold:
        case FundingStatus.Funded:
        case FundingStatus.Canceled:
          fundingStatus = invoice.status;
      }

      if (!invoice.hasAdvance) {
        fundingStatus = FundingStatus.NonFunded;
      }
    }

    return fundingStatus;
  }
}
