import { Component, Input, OnInit, Injector, forwardRef, OnDestroy } from '@angular/core';
import { DtoFormBase } from '../../shared/FormBase';
import { SimpleContactDTO } from '../../../apiclient/v1.1/models/simple-contact-dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { delay, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
  ],
})
export class ContactComponent extends DtoFormBase<SimpleContactDTO> implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() needsHighlight = false;
  @Input() enabledEmailAddress = true;
  @Input() emailAddressDisabledTooltip: string;
  private destroy$ = new Subject<void>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  public writeValue(model: SimpleContactDTO): void {
    if (!model || !Object.keys(model).length) {
      return;
    }
    this.formGroup.patchValue({ ...model }, { emitEvent: false });
  }

  public registerOnChange(fn: any): void {
    this.formGroup.valueChanges
      .pipe(
        delay(0),
        map(() => this.formGroup.getRawValue()),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => fn(value));
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.changeFormStatus(!isDisabled, this.enabledEmailAddress);
  }

  public ngOnInit(): void {
    this.initFormFor('SimpleContactDTO');
    this.changeFormStatus(this.formGroup.enabled, this.enabledEmailAddress);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private changeFormStatus(enabledForm: boolean, enabledEmailAddress: boolean): void {
    const fieldNamesToChange: (keyof SimpleContactDTO)[] = [
      'firstName',
      'lastName',
      'mobileNumber',
      'phoneExt',
      'phoneNumber',
    ];

    fieldNamesToChange.forEach((fieldName) => {
      const control = this.formGroup.controls[fieldName];
      enabledForm ? control.enable() : control.disable();
    });

    const emailControl = this.formGroup.controls.email;
    enabledForm && enabledEmailAddress ? emailControl.enable() : emailControl.disable();
  }
}
