import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ClientTokenService } from '../../../../services/client-token-service';
import { TableColumn } from '../../../shared/models/TableColumn';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { DebtorSearchDTO } from '../../../../apiclient/v1.1/models';
import { CompanyBuyStatusesService } from '../../../../apiclient/v1.1/services';

@Component({
  selector: 'app-buy-no-buy',
  templateUrl: './buy-no-buy.component.html',
  styleUrls: ['./buy-no-buy.component.scss'],
})
export class BuyNoBuyComponent implements OnInit {
  @Input() dialogView: boolean = false;

  debtorCols: TableColumn[];
  debtors$: Observable<DebtorSearchDTO[]>;
  debtorSearch: string;
  public truncatedResultsLimit = 15;

  searchFormGroup: FormGroup;
  public isDebtorDataLoaded$: Observable<boolean>;
  private debtorLoadingStream = new BehaviorSubject(false);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private companyBuyStatusesService: CompanyBuyStatusesService,
    private clientToken: ClientTokenService,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    if (!this.dialogView) {
      this.breadcrumbService.setItems('buy-no-buy');
    }

    this.initTableColumns();

    this.searchFormGroup = this.fb.group({});
    this.searchFormGroup.addControl('buy-no-buy-search', new FormControl());
    this.isDebtorDataLoaded$ = this.debtorLoadingStream.asObservable();
    this.debtors$ = this.searchFormGroup.get('buy-no-buy-search').valueChanges.pipe(
      startWith(''),
      switchMap((searchTerm) => this.getDebtorTableData(searchTerm)),
      tap(() => this.debtorLoadingStream.next(false))
    );
  }

  private initTableColumns(): void {
    this.debtorCols = [
      {
        header: 'Customer',
        field: 'companyName',
        sortable: false,
      },
      {
        header: 'Address',
        field: 'address',
        sortable: false,
        format: 'AddressOneLine',
      },
      {
        header: 'Phone',
        field: 'phoneNumber',
        sortable: false,
      },
      {
        header: 'Email',
        field: 'email',
        sortable: false,
      },
      {
        header: 'Status',
        field: 'buyNoBuy',
        sortable: false,
        format: 'buyStatusWithIcon',
      },
    ];
  }

  private getDebtorTableData(searchTerm: string): Observable<DebtorSearchDTO[]> {
    this.debtorLoadingStream.next(true);
    return this.companyBuyStatusesService.SearchWithBillToCompanyAddresses({
      searchTerm: searchTerm ? searchTerm : null,
      Authorization: this.clientToken.auth(),
    });
  }
}
