import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EditorSanitizationService {
  constructor() {}

  public stripTagsForNotification(text: string): string {
    return text?.replace(/(<\/?(?:a|p|br)[^>]*>)|<[^>]+>/gi, '$1');
  }

  public stripAllTags(text: string): string {
    return text?.replace(/<[^>]*>/g, '');
  }
}
