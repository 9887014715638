import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Renderer2,
  Output,
  Injector,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  CompanyAddressDTO,
  DebtorAddressMatchingDTO,
  FlexibleAddressDTO,
  SimpleContactDTO,
} from '../../../../apiclient/v1.1/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../../../apiclient/services/tenant.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ClientTokenService } from '../../../../services/client-token-service';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-company-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressComponent extends DtoFormBase<CompanyAddressDTO> implements OnInit, OnDestroy {
  @ViewChild('inputControlcreditLimit', { static: true }) inputEl: ElementRef;

  @Input() companyId: number;
  @Input() model: CompanyAddressDTO;
  @Input() set primaryContact(value: SimpleContactDTO) {
    this.primaryCompanyContactChanged$.next(value);
  }
  @Input() creditLimitAccess: boolean;
  @Input() addressId: number;
  @Input() formSubmitted: boolean;
  @Input() parentVisible: boolean;
  @Output() creditLimitChanged = new EventEmitter<any>();
  @Output() creditLimitError = new EventEmitter<any>();
  @Output() doneUpdatesEvent = new EventEmitter<boolean>();
  @Output() debtorSelectedEvent = new EventEmitter<DebtorAddressMatchingDTO>();
  @Output() companyAddressMergeEvent = new EventEmitter<DebtorAddressMatchingDTO>();

  public showError: boolean = false;
  public creditLimitCheck: boolean = false;
  public shipperAppointmentRequired: string = 'true';
  public consigneeAppointmentRequired: string = 'true';
  public creditLimit: string = '';
  public paymentTermsData: any = [];
  public paymentTermsLoaded: boolean = false;
  public factoringStatus$: Observable<boolean>;
  public checkingForMatchingCompanyAddressRecords: boolean;
  public isBillToEmailAddressEnabled = true;
  public readonly debtorFieldTooltip = 'This field is protected. To update it, contact your factoring Account Manager.';

  private type: string;
  private primaryCompanyContactChanged$ = new BehaviorSubject<SimpleContactDTO>(undefined);
  private destroy$ = new Subject<void>();

  constructor(
    private tenantService: TenantService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private clientTokenService: ClientTokenService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('CompanyAddressDTO');
    this.formGroup.addControl('creditLimitCheck', new FormControl());
    this.formGroup.addControl('creditLimit', new FormControl());
    this.factoringStatus$ = this.clientTokenService.isFactoringEnabledForTenant();

    this.trackCompanyContactChanges();
    this.trackPrimaryContactChanges();

    if (!this.model.name) {
      this.model.billToPaymentTerms = 'NET30';
    } else {
      this.shipperAppointmentRequired = this.model.shipperAppointmentRequired.toString();
      this.consigneeAppointmentRequired = this.model.consigneeAppointmentRequired.toString();
      if (this.model.primaryContactIsSame) {
        this.formGroup.controls.primaryContact.disable();
      }
      this.assignContactData();
    }
    this.getTenantPaymentTerms();

    if (this.companyId) {
      this.model.companyId = this.companyId;
      this.formGroup.controls['companyId'].patchValue(this.companyId, { onlySelf: true });
      this.factoringStatus$
        .pipe(
          take(1),
          tap((factoringEnabled) => {
            if (factoringEnabled && this.model.factorCloudSubDebtorId) {
              this.disableSubDebtorFields();
            }
          })
        )
        .subscribe();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.type = params['type'];
      if (!this.companyId) {
        this.model.isBillTo = this.type === 'billto';
        this.model.isShipper = this.type === 'shipper';
        this.model.isConsignee = this.type === 'consignee';
      }
    });
    this.appointmentChanged();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public appointmentChanged(): void {
    setTimeout((_) => {
      this.model.shipperAppointmentRequired = this.shipperAppointmentRequired === 'true' ? true : false;
      this.model.consigneeAppointmentRequired = this.consigneeAppointmentRequired === 'true' ? true : false;
      this.onDoneUpdates();
    });
  }

  public billToContactIsSameChanged(): void {
    const primaryContact: SimpleContactDTO = this.formGroup.controls.primaryContact.value;
    const isChecked: boolean = this.formGroup.controls.billToContactIsSame.value;
    const billToContact = this.formGroup.controls.billToContact;
    isChecked ? billToContact.disable() : billToContact.enable();
    if (isChecked) {
      this.setBillToContact(primaryContact);
    }
  }

  public shipperContactIsSameChanged(): void {
    this.setContactAsPrimaryContact('shipperContact');
  }

  public consigneeContactIsSameChanged(): void {
    this.setContactAsPrimaryContact('consigneeContact');
  }

  public primaryContactIsSameChanged(): void {
    const isChecked: boolean = this.formGroup.controls.primaryContactIsSame.value;
    const formControl = this.formGroup.controls.primaryContact;
    isChecked ? formControl.disable() : formControl.enable();
  }

  public checkCreditLimit(): void {
    setTimeout(() => {
      const element = this.renderer.selectRootElement('#creditLimit');
      element.focus();
    }, 0);
    setTimeout((_) => {
      this.creditLimitChanged.emit({ creditLimitCheck: this.creditLimitCheck, creditLimit: this.creditLimit });
    });
  }

  public checkCreditLimitInput(): void {
    setTimeout((_) => {
      if (!this.creditLimit) {
        this.showError = true;
      } else {
        this.showError = false;
      }
      this.creditLimitError.emit({ creditLimitCheck: this.creditLimitCheck, creditLimitError: this.showError });
    });
  }

  public getAddress(): FlexibleAddressDTO {
    return {
      line1: this.model.address.line1,
      line2: this.model.address.line2,
      city: this.model.address.city,
      state: this.model.address.state,
      zip: this.model.address.zip,
    } as FlexibleAddressDTO;
  }

  public suggestedAddressSelected(debtor: DebtorAddressMatchingDTO): void {
    if (!debtor.subDebtorId) {
      this.masterDebtorAddressSuggestionSelected(debtor);
    }

    this.debtorSelectedEvent.emit(debtor);
  }

  public addBillToToggled(value): void {
    this.checkingForMatchingCompanyAddressRecords = false;

    if (value) {
      this.factoringStatus$
        .pipe(
          take(1),
          tap((factoringEnabled) => {
            if (factoringEnabled && !this.model.factorCloudSubDebtorId) {
              this.checkingForMatchingCompanyAddressRecords = true;
            }
          })
        )
        .subscribe();
    }
  }

  public billToFormVisible = (): Observable<boolean> => {
    return this.factoringStatus$.pipe(
      take(1),
      map((factoringEnabled) => {
        if (factoringEnabled) {
          return this.model.isBillTo && !this.checkingForMatchingCompanyAddressRecords;
        } else {
          return this.model.isBillTo;
        }
      })
    );
  };

  public noCompanyAddressMatchesFound(): void {
    this.checkingForMatchingCompanyAddressRecords = false;
  }

  public companyAddressMatchingActionTaken(addBillTo: boolean): void {
    this.checkingForMatchingCompanyAddressRecords = false;

    this.model.isBillTo = addBillTo;
  }

  public companyAddressMatchSelected(match: DebtorAddressMatchingDTO): void {
    this.checkingForMatchingCompanyAddressRecords = false;

    if (match.companyId) {
      this.companyAddressMergeEvent.emit(match);
    } else {
      this.masterDebtorAddressSuggestionSelected(match);
    }
  }

  private masterDebtorAddressSuggestionSelected(debtor: DebtorAddressMatchingDTO): void {
    this.formGroup.patchValue({
      'address-line1': debtor.address.line1,
      'address-line2': debtor.address.line2,
      'address-city': debtor.address.city,
      'address-state': debtor.address.state,
      'address-zip': debtor.address.zip,
      mcNumber: debtor.mcNumber,
      dotNumber: debtor.dotNumber,
    });

    this.onLocationChanged(`${this.model.address.city}, ${this.model.address.state}`);
  }

  public onLocationChanged(event): void {
    this.model.name = event;
  }

  public onDoneUpdates(): void {
    this.doneUpdatesEvent.emit(true);
  }

  private getTenantPaymentTerms(): void {
    this.tenantService.ApiTenantPaymentTermOptionsGetResponse(this.clientToken.auth()).subscribe((data) => {
      for (const paymentTerm of data.body) {
        const paymentTermObj = { label: paymentTerm, value: paymentTerm };
        this.paymentTermsData.push(paymentTermObj);
      }
      this.paymentTermsLoaded = true;
      setTimeout((_) => {
        this.formGroup.get('name').disable();
      });
    }, this.handleError);
  }

  private assignContactData(): void {
    if (this.model.shipperContactIsSame) {
      this.model.shipperContact = this.model.primaryContact;
      this.formGroup.controls.shipperContact.disable();
    }
    if (this.model.consigneeContactIsSame) {
      this.model.consigneeContact = this.model.primaryContact;
      this.formGroup.controls.consigneeContact.disable();
    }
    if (this.model.billToContactIsSame) {
      this.setBillToContact(this.model.primaryContact);
      this.formGroup.controls.billToContact.disable();
    }
  }

  private disableSubDebtorFields(): void {
    const readonlyFields: (keyof CompanyAddressDTO)[] = ['mcNumber', 'dotNumber', 'isBillTo'];

    readonlyFields.forEach((fieldName) => {
      const field = this.formGroup.get(fieldName);
      field.disable();
    });
    this.isBillToEmailAddressEnabled = false;
  }

  private setContactAsPrimaryContact(formControlName: keyof CompanyAddressDTO): void {
    const primaryContact: SimpleContactDTO = this.formGroup.controls.primaryContact.value;
    const isChecked: boolean = this.formGroup.controls[`${formControlName}IsSame`].value;
    const formControl = this.formGroup.controls[formControlName];
    isChecked ? formControl.disable() : formControl.enable();
    formControl.setValue(primaryContact);
  }

  private trackCompanyContactChanges(): void {
    combineLatest([this.primaryCompanyContactChanged$, this.formGroup.valueChanges])
      .pipe(
        map((p) => ({ primaryCompanyContact: p[0], valueChanges: p[1] as CompanyAddressDTO })),
        map((p) => ({
          primaryCompanyContact: p.primaryCompanyContact,
          primaryContactIsSame: p.valueChanges.primaryContactIsSame,
        })),
        distinctUntilChanged(
          (prev, curr) =>
            prev.primaryCompanyContact === curr.primaryCompanyContact &&
            prev.primaryContactIsSame === curr.primaryContactIsSame
        ),
        tap((p) => {
          if (this.formGroup.controls.primaryContactIsSame.value) {
            this.formGroup.controls.primaryContact.setValue(p.primaryCompanyContact);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private trackPrimaryContactChanges(): void {
    const contacts: (keyof CompanyAddressDTO)[] = ['shipperContact', 'consigneeContact'];
    this.formGroup.controls.primaryContact.valueChanges
      .pipe(
        filter((primaryContact) => !!primaryContact),
        tap((primaryContact: SimpleContactDTO) => {
          contacts.forEach((fieldName) => {
            if (this.formGroup.controls[`${fieldName}IsSame`].value) {
              this.formGroup.controls[fieldName].setValue(primaryContact);
            }
          });

          if (this.formGroup.controls.billToContactIsSame.value) {
            this.setBillToContact(primaryContact);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setBillToContact(primaryContact: SimpleContactDTO): void {
    const formValue: CompanyAddressDTO = this.formGroup.getRawValue();
    const billToContactEmail = formValue.billToContact.email;
    const contact: SimpleContactDTO = {
      ...primaryContact,
      email: this.isBillToEmailAddressEnabled ? primaryContact.email : billToContactEmail,
    };
    this.formGroup.controls.billToContact.setValue(contact);
  }
}
