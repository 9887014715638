import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, mergeAll, takeUntil, tap } from 'rxjs/operators';
import { RateType } from 'src/app/data/static-data';
import { LoadRateProperties } from './load-rate-properties';
import { Subject, of } from 'rxjs';
import { TempLoadRateDTO } from './models/temp-load-rate-dto';

@Component({
  selector: 'app-load-rate',
  templateUrl: './load-rate.component.html',
  styleUrls: ['./load-rate.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadRateComponent),
      multi: true,
    },
  ],
})
export class LoadRateComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() totalMiles: FormControl;
  @Input() set category(category: RateType) {
    this._category = category;
    this.calculateCharges();
  }
  get category(): RateType {
    return this._category;
  }
  @Input() formErrors: any = {};
  @Input() set isReadOnly(value: boolean) {
    this._isReadOnly = value;
    if (this.formGroup) {
      this.disableComponentFormFields();
    }
  }
  get isReadOnly(): boolean {
    return this._isReadOnly;
  }

  public formGroup: FormGroup;
  public readonly rateType = RateType;
  public readonly loadRateProperties = LoadRateProperties;
  public get calcRateType(): RateType {
    return this.category ?? RateType.LineHaul;
  }

  private _isReadOnly: boolean;
  private _category: RateType;
  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [LoadRateProperties.Rate]: [null],
      [LoadRateProperties.RateUnits]: [null],
      [LoadRateProperties.FuelSurcharge]: [null],
      [LoadRateProperties.FuelSurchargePercent]: [null],
      [LoadRateProperties.FuelSurchargeAmount]: [null],
      [LoadRateProperties.FuelSurchargeUnits]: [null],
      [LoadRateProperties.OtherCharges]: [[]],
      [LoadRateProperties.OtherChargesTotal]: [null],
      [LoadRateProperties.Advances]: [[]],
      [LoadRateProperties.AdvancesTotal]: [null],
      [LoadRateProperties.ShowFuelSurchargeAsPercentage]: [false],
      [LoadRateProperties.TotalRate]: [null],
    });

    this.setupFieldSubscriptions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(loadRate: TempLoadRateDTO): void {
    if (!this.formGroup) {
      return;
    }
    this.formGroup.patchValue(loadRate);
  }

  private onChange = (value: TempLoadRateDTO | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private conditionallyDisableShowFuelSurcharge(): void {
    const fuelSurchargeUnits = this.getValueFromForm(LoadRateProperties.FuelSurchargeUnits);
    const fuelSurchargeAmount = this.getValueFromForm(LoadRateProperties.FuelSurchargeAmount);
    const fuelSurchargePercent = this.getValueFromForm(LoadRateProperties.FuelSurchargePercent);
    const showFuelSurchargeAsPercentage = this.getValueFromForm(LoadRateProperties.ShowFuelSurchargeAsPercentage);

    if (!this.isReadOnly) {
      let disableButton = false;

      if (showFuelSurchargeAsPercentage) {
        disableButton = this.isNumberValueDefined(fuelSurchargePercent);
      } else {
        disableButton = this.isNumberValueDefined(fuelSurchargeUnits) || this.isNumberValueDefined(fuelSurchargeAmount);
      }

      if (disableButton) {
        this.formGroup.controls.showFuelSurchargeAsPercentage.disable();
      } else {
        this.formGroup.controls.showFuelSurchargeAsPercentage.enable();
      }
    }
  }

  public calculateCharges(): void {
    if (!this.formGroup) {
      return;
    }

    let calcTotal = 0;
    const rateTotal = this.getCalculatedRate();
    let fuelTotal = 0;

    if (this.getValueFromForm(LoadRateProperties.ShowFuelSurchargeAsPercentage)) {
      const fuelSurchargePercent = this.getNonNullNumberFromForm(LoadRateProperties.FuelSurchargePercent);
      fuelTotal = fuelSurchargePercent && rateTotal ? (fuelSurchargePercent / 100) * rateTotal : 0;
    } else {
      const fuelSurchargeAmount = this.getNonNullNumberFromForm(LoadRateProperties.FuelSurchargeAmount);
      const fuelSurchargeUnits = this.getValueFromForm(LoadRateProperties.FuelSurchargeUnits);
      fuelTotal = fuelSurchargeAmount && fuelSurchargeUnits ? fuelSurchargeAmount * fuelSurchargeUnits : 0;
    }

    const otherChargesTotal = this.getValueFromForm(LoadRateProperties.OtherChargesTotal);
    const advancesTotal = this.getValueFromForm(LoadRateProperties.AdvancesTotal);

    calcTotal += rateTotal * 1;
    calcTotal += fuelTotal * 1;
    calcTotal += otherChargesTotal ? otherChargesTotal * 1 : 0;
    calcTotal -= advancesTotal ? advancesTotal * 1 : 0;

    if (fuelTotal !== this.getNonNullNumberFromForm(LoadRateProperties.FuelSurcharge)) {
      this.formGroup.controls[LoadRateProperties.FuelSurcharge].setValue(fuelTotal);
    }
    if (calcTotal !== this.getValueFromForm(LoadRateProperties.TotalRate)) {
      this.formGroup.controls[LoadRateProperties.TotalRate].setValue(calcTotal);
    }
  }

  public switchFuelCalcType(): void {
    this.formGroup.controls.fuelSurchargeUnits.setValue(null);
    this.calculateCharges();
  }

  public otherChargesChanged($event): void {
    this.formGroup.controls.otherCharges.setValue($event);
  }

  public otherChargesTotalAmountChanged($event): void {
    if (this.getValueFromForm(LoadRateProperties.OtherChargesTotal) !== $event) {
      this.formGroup.controls.otherChargesTotal.setValue($event);
      this.calculateCharges();
    }
  }

  public advanceChargesChanged($event): void {
    this.formGroup.controls.advances.setValue($event);
  }

  public advanceChargesTotalAmountChanged($event): void {
    if (this.getValueFromForm(LoadRateProperties.AdvancesTotal) !== $event) {
      this.formGroup.controls.advancesTotal.setValue($event);
      this.calculateCharges();
    }
  }

  public getCalculatedRate(): number {
    const rate = this.getNonNullNumberFromForm(LoadRateProperties.Rate);
    switch (this.calcRateType) {
      case RateType.RateXUnits:
        return rate * this.getNonNullNumberFromForm(LoadRateProperties.RateUnits);
      case RateType.RateXMiles:
        return rate * this.totalMiles.value;
      case RateType.LineHaul:
        return rate;
      default:
        throw new Error('Unknown Rate Type: ' + this.calcRateType);
    }
  }

  private getNonNullNumberFromForm(controlName: LoadRateProperties): number {
    const value = this.getValueFromForm(controlName);
    if (!!value) {
      return +value;
    }
    return 0;
  }

  private getValueFromForm(controlName: LoadRateProperties) {
    return this.formGroup.controls[controlName].value;
  }

  private disableComponentFormFields(): void {
    const conditionalFields = [
      LoadRateProperties.Rate,
      LoadRateProperties.RateUnits,
      LoadRateProperties.FuelSurchargePercent,
      LoadRateProperties.FuelSurchargeAmount,
      LoadRateProperties.FuelSurchargeUnits,
      LoadRateProperties.OtherCharges,
      LoadRateProperties.Advances,
      LoadRateProperties.ShowFuelSurchargeAsPercentage,
    ];
    conditionalFields.forEach((property) => {
      this.isReadOnly ? this.formGroup.controls[property].disable() : this.formGroup.controls[property].enable();
    });
  }

  private isNumberValueDefined(value: number): boolean {
    return !!value || value === 0;
  }

  private setupFieldSubscriptions(): void {
    this.formGroup.valueChanges
      .pipe(
        map(() => this.mapFormToLoadRateModel()),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));

    this.setupCalculateChargesFieldSubscriptions();

    this.setupFuelSurchargeFieldSubscriptions();
  }

  private mapFormToLoadRateModel(): TempLoadRateDTO {
    return {
      rate: this.getNonNullNumberFromForm(LoadRateProperties.Rate),
      rateUnits: this.getNonNullNumberFromForm(LoadRateProperties.RateUnits),
      fuelSurcharge: this.getNonNullNumberFromForm(LoadRateProperties.FuelSurcharge),
      fuelSurchargeAmount: this.getNonNullNumberFromForm(LoadRateProperties.FuelSurchargeAmount),
      fuelSurchargePercent: this.getNonNullNumberFromForm(LoadRateProperties.FuelSurchargePercent),
      fuelSurchargeUnits: this.getNonNullNumberFromForm(LoadRateProperties.FuelSurchargeUnits),
      showFuelSurchargeAsPercentage: !!this.getValueFromForm(LoadRateProperties.ShowFuelSurchargeAsPercentage),
      totalRate: this.getNonNullNumberFromForm(LoadRateProperties.TotalRate),
      advances: this.getValueFromForm(LoadRateProperties.Advances),
      otherCharges: this.getValueFromForm(LoadRateProperties.OtherCharges),
    };
  }

  private setupCalculateChargesFieldSubscriptions(): void {
    const calculateChargesProperties = of(
      LoadRateProperties.Rate,
      LoadRateProperties.RateUnits,
      LoadRateProperties.FuelSurchargeUnits,
      LoadRateProperties.FuelSurchargeAmount,
      LoadRateProperties.FuelSurchargePercent
    );

    calculateChargesProperties
      .pipe(
        map((property) => this.formGroup.controls[property].valueChanges),
        mergeAll(),
        tap(() => this.calculateCharges()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.totalMiles.valueChanges
      .pipe(
        tap(() => this.calculateCharges()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setupFuelSurchargeFieldSubscriptions(): void {
    const fuelSurchargeProperties = of(
      LoadRateProperties.FuelSurchargeUnits,
      LoadRateProperties.FuelSurchargeAmount,
      LoadRateProperties.FuelSurchargePercent
    );

    fuelSurchargeProperties
      .pipe(
        map((property) => this.formGroup.controls[property].valueChanges),
        mergeAll(),
        tap(() => this.conditionallyDisableShowFuelSurcharge()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
