import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlatformNotificationActionType } from '../enums/platform-notification-action-type';

@Component({
  selector: 'app-notification-action-dialog',
  templateUrl: './notification-action-dialog.component.html',
  styleUrls: ['./notification-action-dialog.component.scss'],
})
export class NotificationActionDialogComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() notificationId: number;
  @Input() actionType: PlatformNotificationActionType;
  @Output() cancel = new EventEmitter<void>();
  @Output() delete = new EventEmitter<number>();

  public dialogHeaderText: string;
  public dialogButtonText: string;
  public dialogButtonIcon: string;

  public readonly PlatformNotificationActionType = PlatformNotificationActionType;

  ngOnInit(): void {
    this.initDialog();
  }

  public handleAction(): void {
    switch (this.actionType) {
      case PlatformNotificationActionType.Delete: {
        this.delete.emit(this.notificationId);
        break;
      }
    }
  }

  private initDialog(): void {
    switch (this.actionType) {
      case PlatformNotificationActionType.Delete: {
        this.dialogHeaderText = 'Delete Notification';
        this.dialogButtonText = 'Delete Notification';
        this.dialogButtonIcon = 'fas fa-trash';
        break;
      }
    }
  }
}
