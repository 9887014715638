<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>
<app-pdf-utility #pdfutil appendTo="body" (dialogClosed)="onDialogClosed($event)"></app-pdf-utility>

<p-dialog
  [(visible)]="dialogVisible"
  [style]="{ width: dialogWidth, height: dialogHeight, top: '0px !important', left: '0px' }"
  [contentStyle]="contentDialogStyle"
  [modal]="true"
  blockScroll="true"
  [focusOnShow]="false"
  [closable]="false"
  styleClass="full-size-dialog"
  [focusTrap]="dialogFocusTrap"
  appendTo="body"
>
  <p-header>
    <div class="grid grid-nogutter col-12 p-0">
      <div class="text-2xl font-normal col-8 p-0">
        {{ title }}
      </div>
      <div class="col-4 p-0 button-header">
        <p-splitButton
          styleClass="splitBtn"
          label="Create Document"
          icon="fa fa-file-text-o"
          [model]="documentMenuItems"
          [disabled]="disabled"
        >
        </p-splitButton>
      </div>
    </div>
  </p-header>
  <form [formGroup]="formGroup" #focusable>
    <p-tabView [activeIndex]="tabIndex" (onChange)="handleTabChange($event)">
      <p-tabPanel header="Load Info">
        <div class="grid grid-nogutter col-12" *ngIf="false">
          {{ model | json }}
        </div>
        <div class="grid grid-nogutter col-12">
          <div class="lg:col-4 md:col-6 sm:col-12 p-2">
            <app-load-status-dropdown formControlName="status"></app-load-status-dropdown>

            <div class="grid grid-nogutter col-12 p-0">
              <div class="md:col-4 sm:col-12 p-2 font-bold">Dispatcher</div>
              <div class="md:col-8 sm:col-12 p-1 px-0">
                <app-user-selector
                  roleFilter="Dispatch"
                  [(ngModel)]="model.dispatcher"
                  formControlName="dispatcher"
                  [readonly]="isReadOnly"
                  (changed)="dispatcherSelected($event)"
                  [showClear]="model.dispatcher && model.dispatcher.id && model.dispatcher.id !== null"
                >
                </app-user-selector>
              </div>
            </div>

            <app-form-field
              [formGroup]="formGroup"
              label="Work Order/PO/BOL"
              type="text"
              formControlName="workOrder"
              [(ngModel)]="model.workOrder"
              [formErrors]="formErrors"
              labelClass="md:col-4 sm:col-12 p-2"
              controlClass="md:col-8 sm:col-12 px-0"
            >
            </app-form-field>

            <app-form-field
              [formGroup]="formGroup"
              label="Category"
              type="select"
              [config]="{ selectOptions: loadCategories }"
              formControlName="category"
              [(ngModel)]="model.category"
              [formErrors]="formErrors"
              placeholder="Select a Category"
              labelClass="md:col-4 sm:col-12 p-2"
              controlClass="md:col-8 sm:col-12 px-0 py-1"
            >
            </app-form-field>

            <div class="grid grid-nogutter col-12 p-0">
              <div class="md:col-4 sm:col-12 p-2 font-bold">Equipment Type</div>
              <div class="md:col-8 sm:col-12 p-1 px-0">
                <app-old-equipment-type-selector
                  [equipmentOptions]="equipmentOptions$ | async"
                  [(ngModel)]="model.requestedEquipmentId"
                  formControlName="requestedEquipmentId"
                  autoDisplayFirst="false"
                  (equipmentChanged)="onEquipmentChanged($event)"
                  (equipmentBlur)="onEquipmentBlur($event)"
                  [readonly]="isReadOnly"
                >
                </app-old-equipment-type-selector>
              </div>
              <div [hidden]="!formSubmitted" class="col-12 pt-0 pl-2 formerrors">
                {{ formErrors.requestedEquipmentId }}
              </div>
            </div>

            <div class="grid grid-nogutter col-12 p-0">
              <div class="col-4 p-2 font-bold"> Total Loaded Miles </div>
              <div class="col-8 p-1 px-0">
                <div class="ui-inputgroup p-0">
                  <input
                    type="text"
                    pKeyFilter="num"
                    pInputText
                    class="col-6"
                    formControlName="totalMiles"
                    [value]="formGroup.controls.totalMiles.value"
                  />
                  <span class="ui-inputgroup-addon col-6">
                    <p-checkbox
                      binary="true"
                      label="Override"
                      formControlName="overrideTotalMiles"
                      [(ngModel)]="model.overrideTotalMiles"
                      (onChange)="onOverrideTotalLoadedMilesChange()"
                    ></p-checkbox>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:col-4 md:col-6 sm:col-12 p-2">
            <app-bill-to-company-address-selector
              class="col-12 p-0"
              [(ngModel)]="model.billToCompanyAddress"
              formControlName="billToCompanyAddress"
              (changed)="onBillToCompanyChange($event)"
              [readonly]="isReadOnly"
            ></app-bill-to-company-address-selector>
            <div [hidden]="!formSubmitted" class="col-12 pt-0 pl-2 formerrors">
              {{ formErrors.billToCompanyAddress | formatErrorMessage }}
            </div>

            <app-load-rate
              [isReadOnly]="isReadOnly"
              formControlName="loadRate"
              [category]="formGroup.controls.category.value"
              [totalMiles]="formGroup.controls.totalMiles"
              [formErrors]="formErrors"
            ></app-load-rate>
          </div>

          <div class="lg:col-4 md:col-6 sm:col-12 p-2">
            <app-form-field
              [formGroup]="formGroup"
              label="Customer Notes"
              secondaryLabel="Internal use only."
              type="textarea"
              formControlName="billToNotesInternal"
              [(ngModel)]="model.billToNotesInternal"
              [formErrors]="formErrors"
              labelClass="md:col-4 sm:col-12 p-2"
              controlClass="md:col-8 sm:col-12 p-1 px-0"
            >
            </app-form-field>

            <app-form-field
              [formGroup]="formGroup"
              label="Customer Notes"
              secondaryLabel="Shown on documents."
              type="textarea"
              formControlName="billToNotesReports"
              [(ngModel)]="model.billToNotesReports"
              [formErrors]="formErrors"
              labelClass="md:col-4 sm:col-12 p-2"
              controlClass="md:col-8 sm:col-12 p-1 px-0"
            >
            </app-form-field>
          </div>
        </div>

        <hr class="col-12 p-0" />

        <app-legs
          [formGroup]="formGroup"
          formControlName="legs"
          class="col-12 px-0"
          [loadId]="model.loadId"
          [loadNumber]="model.loadNumber"
          [loadStatus]="model.status"
          [readonly]="isReadOnly"
          [disabled]="disabled"
        >
        </app-legs>
      </p-tabPanel>
      <p-tabPanel header="Documents">
        <app-document #documentList *ngIf="model.loadId" [loadId]="model.loadId" [disabled]="disabled"></app-document>
      </p-tabPanel>
    </p-tabView>
  </form>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="cancel()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      form="htmlformGroup"
      (click)="saveModel()"
      [disabled]="showProgressOverlay || disabled"
      label="{{ isNewRecord ? 'Create' : 'Update' }}"
    ></button>
  </p-footer>
</p-dialog>
