import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TenantDTO, FactoringCompanyOptionsDTO } from '../../../../apiclient/v1.1/models';
import { ClientTokenService } from '../../../../services/client-token-service';
import { NotificationsService } from '../../../../app/components/notifications/notifications.service';
import { FactoringCompanyOptionsService, TenantFactoringService } from '../../../../apiclient/v1.1/services';
import { catchError, take, tap, switchMap, map, finalize } from 'rxjs/operators';
import { Observable, of, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { Role } from '../../../data/static-data';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-factoring-status',
  templateUrl: './factoring-status.component.html',
  styleUrls: ['./factoring-status.component.scss'],
})
export class TenantFactoringStatusComponent implements OnInit, OnDestroy {
  @Input() public model: TenantDTO;
  public loading: boolean = false;
  public isSuperAdmin: boolean = false;
  public factoringCompanyOptions$: Observable<FactoringCompanyOptionsDTO>;
  private factoringStatusChanged$ = new BehaviorSubject<void>(null);
  private subscriptions: Subscription[] = [];

  constructor(
    private factoringCompanyOptionsService: FactoringCompanyOptionsService,
    private tenantFactoringService: TenantFactoringService,
    private clientToken: ClientTokenService,
    private confirmationService: ConfirmationService,
    private notify: NotificationsService
  ) {}

  public ngOnInit(): void {
    this.isSuperAdmin = this.clientToken.hasRole(Role.SuperAdmin);

    this.factoringCompanyOptions$ = combineLatest([
      this.clientToken.isFactoringEnabledForTenant(),
      this.factoringStatusChanged$,
    ]).pipe(
      map(([enabled, _]) => enabled && this.model.factoringStatus),
      switchMap((isFactoringEnabled) => (isFactoringEnabled ? this.getFactoringCompanyInfo() : of(undefined)))
    );
  }

  public toggleFactoring(): void {
    const message = this.model.factoringStatus
      ? 'Are you sure you want to disable factoring for this tenant?'
      : 'Are you sure you want to enable factoring for this tenant?';

    this.confirmationService.confirm({
      message: message,
      key: 'FactoringConfirmation',
      accept: () => this.tryUpdateTenantFactoringStatus(),
    });
  }

  public forceEnable(): void {
    const subscription = this.updateTenantFactoringStatus().subscribe();
    this.subscriptions.push(subscription);
    this.factoringStatusChanged$.next();
  }

  public tryUpdateTenantFactoringStatus(): void {
    this.loading = true;

    if (this.isSuperAdmin) {
      const subscription = of(this.model.factoringStatus)
        .pipe(
          switchMap((factoringStatus) =>
            factoringStatus ? this.updateTenantFactoringStatus() : this.validateAndUpdateTenantFactoringStatus()
          ),
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe();
      this.subscriptions.push(subscription);
    } else {
      this.notify.error('Only SuperAdmin can update Factoring Status', null);
    }
  }

  public createFactoringClient(): void {
    if (this.isSuperAdmin && !this.model.factorCloudClientUuid) {
      this.loading = true;
      const subscription = this.tenantFactoringService
        .CreateFactoringClient({
          Authorization: this.clientToken.auth(),
          id: this.model.tenantId,
        })
        .pipe(
          tap((client) => (this.model.factorCloudClientUuid = client.uuid)),
          catchError((error) => {
            this.notify.apiError(error);
            throw error;
          }),
          finalize(() => (this.loading = false))
        )
        .subscribe();
      this.subscriptions.push(subscription);
    }
  }

  private getFactoringCompanyInfo(): Observable<FactoringCompanyOptionsDTO> {
    return this.factoringCompanyOptionsService.Get(this.clientToken.auth()).pipe(
      take(1),
      catchError((error) => {
        this.notify.apiError(error);
        return of(undefined);
      })
    );
  }

  private updateTenantFactoringStatus(): Observable<void> {
    return this.tenantFactoringService
      .SetFactoringStatus({
        Authorization: this.clientToken.auth(),
        tenantId: this.model.tenantId,
        enabled: !this.model.factoringStatus,
      })
      .pipe(
        tap(() => {
          this.model.factoringStatus = !this.model.factoringStatus;
          this.factoringStatusChanged$.next();
        }),
        map(() => null),
        catchError((error) => {
          this.notify.apiError(error);
          return of(undefined);
        })
      );
  }

  private validateAndUpdateTenantFactoringStatus(): Observable<void> {
    return this.validateTenantBusinessAssociates().pipe(
      switchMap((result) => (result ? this.updateTenantFactoringStatus() : of(null)))
    );
  }

  private validateTenantBusinessAssociates(): Observable<boolean> {
    return this.tenantFactoringService
      .AreTenantBusinessAssociatesReadyForFactoring({
        Authorization: this.clientToken.auth(),
        tenantId: this.model.tenantId,
      })
      .pipe(
        switchMap(() => of(true)),
        catchError((error) => of(this.handleValidationError(error)))
      );
  }

  private handleValidationError(error: HttpErrorResponse): boolean {
    if (error.status === 409) {
      this.displayTenantBusinessAssociatesWarningDialog();
    } else {
      this.notify.apiError(error);
    }
    return false;
  }

  private displayTenantBusinessAssociatesWarningDialog() {
    this.confirmationService.confirm({
      key: 'TenantBusinessAssociateWarningDialog',
      reject: () => this.forceEnable(),
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.factoringStatusChanged$.complete();
  }
}
