import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConfig } from 'src/app/config/app.config';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserAuthenticationGuard } from 'src/app/shared/guards/authorization/user-authentication.guard';

const dashboardRoute = AppConfig.routes.dashboard;
const routes: Routes = [
  { path: '', redirectTo: `/${dashboardRoute}`, pathMatch: 'full' },
  { path: dashboardRoute, component: DashboardComponent, canActivate: [UserAuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
