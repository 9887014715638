import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PlatformNotificationsComponent } from './platform-notifications/platform-notifications.component';
import { AdminEditComponent } from './edit/edit.component';
import { TenantListComponent } from '../tenant/list/list.component';
import { AdminFactoringEditComponent } from './factoring/factoring-edit.component';
import { AdminRegistrationsComponent } from './admin-registrations/admin-registrations.component';
import { TenantSettingsComponent } from '../tenant/settings/settings.component';
import { PermissionAuthorizationGuard } from 'src/app/shared/guards/authorization/permission-authorization.guard';
import { Permission } from 'src/app/data/static-data';
import { AuthorizationDataProperties } from 'src/app/shared/guards/authorization/authorization-data-properties';

const routes: Routes = [
  {
    path: 'admin',
    component: MainComponent,
    children: [
      {
        path: 'system-defaults',
        component: AdminEditComponent,
      },
      {
        path: 'tenants',
        component: TenantListComponent,
      },
      {
        path: 'registrations',
        component: AdminRegistrationsComponent,
      },
      {
        path: 'factoring-company',
        component: AdminFactoringEditComponent,
      },
      {
        path: 'platform-notifications',
        component: PlatformNotificationsComponent,
      },
      {
        path: 'platform-notifications/:id',
        component: PlatformNotificationsComponent,
      },
    ],
  },
  {
    path: 'admin/tenants/:id',
    component: TenantSettingsComponent,
    canActivate: [PermissionAuthorizationGuard],
    data: {
      [AuthorizationDataProperties.Permission]: Permission.TenantEditAll,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
