import { Injectable } from '@angular/core';
import { ReportingQueryDTO } from 'src/apiclient/v1.1/models';
import { ReportingFilter } from '../../models/reporting-filter';
import { ReportingWizardOptions } from '../../reporting-wizard-options/reporting-wizard-options';

@Injectable({ providedIn: 'root' })
export class ReportingQueryService {
  public buildQueryObject(columns: ReportingWizardOptions[], filter: ReportingFilter): ReportingQueryDTO {
    filter?.fromDate?.setUTCHours(0, 0, 0, 0);
    filter?.toDate?.setUTCHours(23, 59, 59, 59);

    return {
      columns: columns,
      filter: {
        category: filter?.category,
        dateType: filter?.dateType,
        fromDate: filter?.fromDate?.toISOString(),
        toDate: filter?.toDate?.toISOString(),
        subcategoryRecordId: filter?.subcategory?.value ? filter.subcategory.value : null,
        includeMultipleShippersConsignees: filter?.includeMultipleShippersConsignees,
      },
    };
  }
}
