<p-autoComplete
  styleClass="col-12 p-0"
  [(ngModel)]="selected"
  field="name"
  [suggestions]="results"
  (completeMethod)="search($event)"
  (onSelect)="onSelect()"
  (onClear)="onClear()"
  [forceSelection]="forceSelection"
  [dropdown]="showDropdown"
  minLength="1"
  emptyMessage="No Results"
  [disabled]="disabled || companyId == undefined || companyId == null || companyId < 1"
>
  <ng-template let-company pTemplate="item">
    <div
      ><strong>{{ company.name }}</strong></div
    >
    <div>{{ company.details }}</div>
  </ng-template>
</p-autoComplete>
