<div [formGroup]="formGroup" *ngIf="layout == 'vertical'" [hidden]="!editMode">
  <app-form-field
    [(formGroup)]="formGroup"
    label="Phone Number *"
    formControlName="{{ prefix }}-number"
    [formErrors]="formErrors"
    [(ngModel)]="vm.number"
    placeholder="Required"
    [config]="config"
    labelClass="col-4 {{ labelClass }}"
    controlClass="col-8 {{ controlClass }}"
    keyFilter="number"
    errorClass="col-8 col-offset-4"
  >
  </app-form-field>

  <app-form-field
    [(formGroup)]="formGroup"
    label="Phone Ext"
    formControlName="{{ prefix }}-ext"
    [formErrors]="formErrors"
    [(ngModel)]="vm.ext"
    placeholder=""
    [config]="config"
    labelClass="col-4 {{ labelClass }}"
    controlClass="col-8 {{ controlClass }}"
    keyFilter="number"
    errorClass="col-8 col-offset-4"
  >
  </app-form-field>
</div>

<div [hidden]="editMode">
  <div class="grid grid-nogutter col-12">
    <div class="{{ labelClass }}"> Phone Number </div>
    <div class="{{ controlClass }}">
      <span class="bold-text">
        {{ vm.number }}
      </span>
    </div>
  </div>
  <div class="grid grid-nogutter col-12">
    <div class="{{ labelClass }}"> Phone Ext </div>
    <div class="{{ controlClass }}">
      <span class="bold-text">
        {{ vm.ext }}
      </span>
    </div>
  </div>
</div>

<div [formGroup]="formGroup" class="grid grid-nogutter col-12 p-0" *ngIf="layout == 'horizontal'">
  <div class="grid col-6 p-0">
    <span class="grid col-12 p-0" *ngIf="showFloatLabels">
      <input
        class="col-12"
        id="reg-{{ prefix }}-number"
        pInputText
        type="text"
        pInputText
        pKeyFilter="num"
        [(ngModel)]="vm.number"
        formControlName="{{ prefix }}-number"
        (blur)="validateOnBlur($event)"
      />
      <label for="reg-{{ prefix }}-number">Phone *</label>
    </span>

    <span class="grid col-12 p-0" *ngIf="!showFloatLabels">
      <input
        placeholder="Phone"
        class="col-12"
        id="reg-{{ prefix }}-number"
        pInputText
        type="text"
        pInputText
        pKeyFilter="num"
        [(ngModel)]="vm.number"
        formControlName="{{ prefix }}-number"
        (blur)="validateOnBlur($event)"
      />
    </span>

    <span class="formerrors">{{ formErrors[this.prefix + '-number'] }}</span>
  </div>

  <div class="grid col-6 p-0">
    <span class="grid col-12 p-0" *ngIf="showFloatLabels">
      <input
        class="col-12"
        style="margin-left: 5px"
        id="reg-{{ prefix }}-ext"
        pInputText
        type="text"
        pInputText
        pKeyFilter="num"
        [(ngModel)]="vm.ext"
        formControlName="{{ prefix }}-ext"
      />
      <label for="reg-{{ prefix }}-ext">Extension</label>
    </span>

    <span class="grid col-12 p-0" *ngIf="!showFloatLabels">
      <input
        placeholder="Extension"
        class="col-12"
        id="reg-{{ prefix }}-ext"
        pInputText
        type="text"
        pInputText
        pKeyFilter="num"
        [(ngModel)]="vm.ext"
        formControlName="{{ prefix }}-ext"
      />
    </span>

    <span class="formerrors">{{ formErrors[this.prefix + '-ext'] }}</span>
  </div>
</div>
