import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { BuyNoBuyDialogComponent } from './buy-no-buy-dialog/buy-no-buy-dialog.component';
import { BuyNoBuyComponent } from './buy-no-buy/buy-no-buy.component';
import { FactoringRoutingModule } from './factoring-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from '../../shared/modules/primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountExecutivesTableComponent } from './account-executives-table/account-executives-table.component';

@NgModule({
  imports: [CommonModule, SharedModule, FactoringRoutingModule, FormsModule, ReactiveFormsModule, PrimeNgModule],
  declarations: [BuyNoBuyComponent, BuyNoBuyDialogComponent, AccountExecutivesTableComponent],
  exports: [BuyNoBuyComponent, BuyNoBuyDialogComponent],
})
export class FactoringModule {}
