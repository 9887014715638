import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-factoring-status-indicator',
  templateUrl: './factoring-status-indicator.component.html',
  styleUrls: ['./factoring-status-indicator.component.scss'],
})
export class FactoringStatusIndicatorComponent {
  @Input() public condition: boolean;
  @Input() public toolTipMessage: string;
  @Input() public statusIndicator: string;
}
