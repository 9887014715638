<app-edit-section
  title="{{ title }}"
  offsetClass="col-offset-0"
  (saved)="saveNotes()"
  (cancelled)="cancel()"
  [(editMode)]="editing"
  [disableEdit]="disableEdit"
  [disableSave]="disableSave$ | async"
>
  <div read>
    <app-edit-section-row label="" labelClass="nolabel" bodyClass="col-12">
      <span class="no-bold static-section"> {{ notes }} </span>
    </app-edit-section-row>
  </div>
  <div edit>
    <app-edit-section-row label="" labelClass="nolabel" bodyClass="col-12">
      <textarea
        pInputTextarea
        class="textArea"
        [rows]="5"
        [cols]="60"
        type="text"
        [formControl]="notesControl"
        placeholder=""
      >
      </textarea>
      <div
        class="text-sm character-counter flex justify-content-start"
        *ngIf="maxLength"
        [class.formerrors]="notesControl.invalid"
      >
        {{ notesControl.value?.length || 0 }}/{{ maxLength }}
      </div>
    </app-edit-section-row>
  </div>
</app-edit-section>
