import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from '../../shared/modules/shared.module';

@NgModule({
  imports: [CommonModule, SettingsRoutingModule, SharedModule],
  declarations: [],
})
export class SettingsModule {}
