import { catchError, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { Observable, Subject } from 'rxjs';
import { ConfirmPasswordFormFields } from './confirm-password-form-fields';
import { AppConfig } from 'src/app/config/app.config';
import { AuthenticationService } from 'src/apiclient/v1.1/services';

@Component({
  selector: 'app-confirmpassword',
  templateUrl: './confirmpassword.component.html',
  styleUrls: ['./confirmpassword.component.scss'],
})
export class ConfirmpasswordComponent implements OnInit, OnDestroy {
  public confirmPasswordForm: FormGroup;
  public showProgressOverlay: boolean;
  public formErrors: object;
  public isFormValid$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private errorHandlingService: ErrorHandlingService,
    private formValidationService: FormValidationService,
    private notificationService: NotificationsService,
    private formValidationErrorsService: FormValidationErrorsService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.confirmPasswordForm = this.formBuilder.group({
      [ConfirmPasswordFormFields.Username]: ['', [Validators.required]],
      [ConfirmPasswordFormFields.Code]: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      [ConfirmPasswordFormFields.Password]: ['', [Validators.required]],
      [ConfirmPasswordFormFields.ConfirmPassword]: ['', [Validators.required]],
      [ConfirmPasswordFormFields.PasswordsMatch]: [false, [Validators.requiredTrue]],
    });

    this.initValuesFromUrl();

    this.confirmPasswordForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.formErrors = this.formValidationErrorsService.getFormErrors(this.confirmPasswordForm)));

    this.isFormValid$ = this.confirmPasswordForm.valueChanges.pipe(
      startWith(false),
      map(() => this.confirmPasswordForm.valid),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetPassword(): void {
    if (this.formValidationService.validateForm(this.confirmPasswordForm)) {
      this.showProgressOverlay = true;

      const formValue = this.confirmPasswordForm.getRawValue();

      this.authenticationService
        .ConfirmForgotPassword(formValue)
        .pipe(
          tap(() => {
            this.notificationService.success('Success!', 'Password has been changed.');
            this.router.navigate([AppConfig.routes.login.default]);
          }),
          catchError((error) => this.errorHandlingService.handleError(error)),
          finalize(() => (this.showProgressOverlay = false)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  private initValuesFromUrl(): void {
    const username = this.activatedRoute.snapshot.paramMap.get('username');
    this.confirmPasswordForm.controls[ConfirmPasswordFormFields.Username].setValue(username);

    const confirmationCode = this.activatedRoute.snapshot.queryParamMap.get('confirmationCode');
    this.confirmPasswordForm.controls[ConfirmPasswordFormFields.Code].setValue(confirmationCode);
  }
}
