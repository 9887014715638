<p-accordion [multiple]="true" class="p-0 accordion-nopad">
  <p-accordionTab
    *ngFor="let leg of legs; let idx = index"
    dnd-droppable
    (onDropSuccess)="dropStop($event, idx)"
    [selected]="true"
    class="{{ legs.length > 1 ? '' : 'hideAccordionHead' }}"
  >
    <p-header>
      <span class="grid grid-nogutter flex-row inline p-0 col-12 absolute ml-2">
        <div class="grid grid-nogutter col-12 p-0">
          <div class="col-1 p-0">
            <b>Leg {{ idx + 1 }}</b>
          </div>
          <div class="col-3 p-0"> Driver: {{ leg.driverName }} </div>
          <div class="col-5 p-0">
            {{ getLegStopLocations(leg) }}
          </div>
          <div class="col-1 p-0">
            {{ leg.journal && leg.journal.length ? leg.journal[leg.journal.length - 1].status : ('' | titleCase) }}
          </div>
          <span class="col-1 p-0 trashcan">
            <i
              class="fas fa-trash-alt cursor-pointer"
              appClickPreventDefault
              appClickStopPropogation
              (click)="removeLeg(leg)"
              *ngIf="!readonly"
            ></i>
          </span>
        </div>
      </span>
    </p-header>

    <app-leg
      [leg]="leg"
      (legChanged)="legChanged($event, idx)"
      [loadId]="loadId"
      [loadNumber]="loadNumber"
      [loadStatus]="loadStatus"
      [readonly]="readonly"
      [disabled]="disabled"
    >
    </app-leg>
  </p-accordionTab>
</p-accordion>

<div class="grid grid-nogutter col-12 p-0" *ngIf="!readonly">
  <div class="col-12 p-2 mt-1 cursor-pointer text-center additembox" (click)="addLeg()">
    <i class="fa fa-plus"></i>
    Add a Leg
  </div>
</div>
