import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

// 30 second cache on some trivial operations
const maxAge = 30000;

/**
 * Manages the cached items for the caching interceptor
 * Currently items are cached for 30 seconds if shouldCache returns true
 * Some pages have multiple components that load in parallel, this results in multiple calls.
 *  There may be a way to hold an observable to avoid those extra calls.
 * source: https://fullstack-developer.academy/caching-http-requests-with-angular/
 */
@Injectable()
export class RequestCacheService {
  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - maxAge;
    return isExpired ? undefined : cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    if (!this.shouldCache(req)) {
      return;
    }

    const url = req.urlWithParams;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  private shouldCache(req: HttpRequest<any>): boolean {
    if (req.method !== 'GET') {
      return false;
    }

    let path = req.urlWithParams.toLowerCase();
    if (path.startsWith('http')) {
      path = path.split('//')[1];
      path = path.substr(path.indexOf('/') + 1);
    }

    return (
      path.indexOf('api/user/byrole/') > -1 ||
      path.indexOf('api/tenant/equipmentoptions') > -1 ||
      path.indexOf('api/tenant/paymenttermoptions') > -1 ||
      path.indexOf('api/role/user') > -1 ||
      path.indexOf('assets/json/states.json') > -1
    );
  }
}
