/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class AccountingService extends __BaseService {
  static readonly ApiAccountingExportGetPath = '/api/Accounting/export';
  static readonly ApiAccountingSetStatusByStatusPostPath = '/api/Accounting/SetStatus/{status}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns CSV file for invoiced loads that have not been exported yet.
   * Method sets the AccountingExported flag which will prevent duplicate exports.
   * UnInvoiceing the load will reset the flag
   * loadIdEquals is an optional parameter
   * @param params The `AccountingService.ApiAccountingExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadIdEquals`:
   *
   * @return A CSV file
   */
  ApiAccountingExportGetResponse(params: AccountingService.ApiAccountingExportGetParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Accounting/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file for invoiced loads that have not been exported yet.
   * Method sets the AccountingExported flag which will prevent duplicate exports.
   * UnInvoiceing the load will reset the flag
   * loadIdEquals is an optional parameter
   * @param params The `AccountingService.ApiAccountingExportGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadIdEquals`:
   *
   * @return A CSV file
   */
  ApiAccountingExportGet(params: AccountingService.ApiAccountingExportGetParams): __Observable<Blob> {
    return this.ApiAccountingExportGetResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Allows you to perform bulk status changes.
   * Allowed transitions:
   * - Audited: [Delivered, Claim, Invoiced, Paid, Archive]
   * - Invoiced (Un-Paid): from [Paid, Archive]
   * - Paid: from [Invoiced, Archive]
   * @param params The `AccountingService.ApiAccountingSetStatusByStatusPostParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadIds`:
   */
  ApiAccountingSetStatusByStatusPostResponse(params: AccountingService.ApiAccountingSetStatusByStatusPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.loadIds || []).forEach(val => {if (val != null) __params = __params.append('loadIds', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Accounting/SetStatus/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Allows you to perform bulk status changes.
   * Allowed transitions:
   * - Audited: [Delivered, Claim, Invoiced, Paid, Archive]
   * - Invoiced (Un-Paid): from [Paid, Archive]
   * - Paid: from [Invoiced, Archive]
   * @param params The `AccountingService.ApiAccountingSetStatusByStatusPostParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `loadIds`:
   */
  ApiAccountingSetStatusByStatusPost(params: AccountingService.ApiAccountingSetStatusByStatusPostParams): __Observable<null> {
    return this.ApiAccountingSetStatusByStatusPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AccountingService {

  /**
   * Parameters for ApiAccountingExportGet
   */
  export interface ApiAccountingExportGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    loadIdEquals?: Array<number>;
  }

  /**
   * Parameters for ApiAccountingSetStatusByStatusPost
   */
  export interface ApiAccountingSetStatusByStatusPostParams {
    status: 'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive';

    /**
     * Bearer {token}
     */
    Authorization: string;
    loadIds?: Array<number>;
  }
}

export { AccountingService }
