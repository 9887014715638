<div class="{{ labelClass }}" *ngIf="label !== ''">
  <div class="col-12 p-0">
    {{ label }}
  </div>
</div>

<p-autoComplete
  styleClass="w-full"
  [(ngModel)]="selected"
  [suggestions]="options"
  (completeMethod)="filterStateSingle($event)"
  field="abbr"
  [placeholder]="placeholder"
  (onSelect)="onStateChange($event)"
  (onBlur)="stateCheck($event)"
  [minLength]="1"
  emptyMessage="No Matching State"
  [dropdown]="true"
  appendTo="body"
  [readonly]="readonly"
  [disabled]="readonly"
  [tabindex]="readonly ? '-1' : undefined"
>
</p-autoComplete>
