import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LbtIntegrationComponent } from './lbt-integration.component';

const routes: Routes = [{ path: 'lbt', component: LbtIntegrationComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LbtIntegrationRoutingModule {}
