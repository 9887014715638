import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TargetUrlService {
  private targetUrlKey = 'targetUrl';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {}

  public navigateWithTargetUrl(baseRoute: string): void {
    const currentTargetUrl = this.tryGetTargetUrlOffCurrentRoute();
    const encodedUrl = currentTargetUrl ? currentTargetUrl : this.encodeTargetUrl();
    this.router.navigate(['/' + baseRoute], { queryParams: { [this.targetUrlKey]: encodedUrl } });
  }

  public tryNavigateToTargetUrl(): boolean {
    const encodedTargetUrl = this.tryGetTargetUrlOffCurrentRoute();
    if (!encodedTargetUrl) {
      return false;
    }

    const targetUrl = decodeURIComponent(encodedTargetUrl);

    const baseRoute = targetUrl.split('?')[0];
    const queryParams = targetUrl.split('?')[1];

    this.router.navigate([baseRoute], { queryParams: this.buildQueryParams(queryParams) });
    return true;
  }

  private encodeTargetUrl(): string {
    return encodeURIComponent(this.location.path());
  }

  private buildQueryParams(rawQueryParams: string): object {
    const result = {};
    const queryParams = rawQueryParams?.split('&');

    queryParams?.forEach((param) => {
      const splitParam = param?.split('=');
      if (splitParam) {
        result[splitParam[0]] = splitParam[1];
      }
    });

    return result;
  }

  private tryGetTargetUrlOffCurrentRoute(): string {
    return this.activatedRoute.snapshot.queryParamMap.get(this.targetUrlKey);
  }
}
