<div class="grid grid-nogutter col-12 px-2 py-1">
  <p-accordion [activeIndex]="index">
    <p-accordionTab *ngFor="let address of addresses" [selected]="index === 0">
      <p-header class="accordion-header">
        <div class="label-black accordion-header-left">
          {{ address.name }}
        </div>
        <div class="accordion-header-right">
          <ul>
            <li *ngIf="address.isBillTo">Bill To</li>
            <li *ngIf="address.isConsignee">Consignee</li>
            <li *ngIf="address.isShipper">Shipper</li>
          </ul>
        </div>
      </p-header>
      <app-company-address
        [companyId]="companyId"
        [parentVisible]="parentVisible"
        [model]="address"
        [primaryContact]="primaryContact"
        [creditLimitAccess]="creditLimitAccess"
        [formSubmitted]="formSubmitted"
        (creditLimitChanged)="onCreditLimitChanged($event)"
        (creditLimitError)="onCreditLimitError($event)"
        (doneUpdatesEvent)="doneUpdatesHandler()"
        (debtorSelectedEvent)="onDebtorSelected($event)"
        (companyAddressMergeEvent)="onCompanyAddressMerge($event)"
      ></app-company-address>
    </p-accordionTab>
  </p-accordion>
</div>

<div class="grid grid-nogutter col-12">
  <div class="col-12 p-2 additembox-gray fauxlink" (click)="addAddress()">
    <i class="fa fa-plus"></i> Add another location
  </div>
</div>
