<div [formGroup]="wizardStep.stepForm" class="grid" *ngIf="!sameAsMailing">
  <h1 class="col-12 form-header">Physical Address</h1>
  <app-address-v3
    class="col-12"
    [formControlName]="formFields.PhysicalAddress"
    [validationMessages]="wizardStep.customValidationMessages"
    [touched]="wizardStep.stepForm.controls[formFields.PhysicalAddress].touched"
    [dirty]="wizardStep.stepForm.controls[formFields.PhysicalAddress].dirty"
  ></app-address-v3>
</div>

<app-wizard-navigation (previousStep)="previous()" (nextStep)="next()"></app-wizard-navigation>
