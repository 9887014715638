import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  OnChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ChargeDTO } from '../../../../apiclient/models';

@Component({
  selector: 'app-form-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormChargesComponent implements ControlValueAccessor {
  /**
   * Options:
   *   - display: Only shows content of field
   *   - readonly: Shows input(control) on read only mode
   *   - blank/not defined: Regular Input/Control mode
   * Default: blank
   */
  @Input() viewMode?: string;
  @Input() formGroup: FormGroup;
  @Input() charges: Array<ChargeDTO> = new Array<ChargeDTO>();
  @Input() chargeLabel: String;

  constructor() {
    this.charges = [];
  }

  writeValue(charges) {
    this.charges = charges;
  }

  registerOnChange() {}

  registerOnTouched() {}

  addCharge() {
    const newCharge = {} as ChargeDTO;
    this.charges.push(newCharge);
  }

  removeCharge(item) {
    const index = this.charges.indexOf(item);
    this.charges.splice(index, 1);
  }
}
