<div class="grid grid-nogutter justify-content-center text-center font-bold p-3">
  END-USER LICENSE AGREEMENT and PRIVACY POLICY
</div>
<div class="grid grid-nogutter bg-white">
  <div class="col-12 col-offset-1 p-4 text-justify line-height-3">
    <b>THIS END-USER LICENSE AGREEMENT and PRIVACY POLICY</b> (this <b class="font-italic">"Agreement"</b>) is a binding
    agreement made by and between you (the <b class="font-italic">“End User”</b>) and Truxio, LLC, a Delaware limited
    liability company (the <b class="font-italic">“Company”</b>).
  </div>
  <div class="col-12 text-center font-bold"> RECITALS: </div>
  <div class="col-10 col-offset-1 p-4 text-justify">
    WHEREAS, the Company is the sole owner of all rights, title and interest in and to a software program commonly known
    as Truxio (the <b class="font-italic">“Software”</b>), which provides certain data, information and other services
    to companies operating in the transportation, trucking and/or freight businesses, whether online or through mobile
    applications or other devices; and
    <br />
    <br />
    WHEREAS, the Company wishes to grant End User, and End User wishes to obtain from the Company, a subscription,
    limited, non-exclusive and non-transferable license to access and use the Software subject to the terms and
    conditions of this Agreement.
    <br />
    <br />
    NOW, THEREFORE, for and in exchange of the mutual promises and covenants contained herein and for other good and
    valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties, intending to be
    legally bound, agree as follows:
    <ul class="list-none line-height-3">
      <li>
        1. <u>Grant of License.</u> Subject to the terms and conditions of this Agreement, the Company hereby grants to
        End User a subscription, limited, non-exclusive and non-transferable license to access and use the Software
        solely in connection with the operation of the End User’s business. </li
      ><li>
        2. <u>Restrictions on Use.</u> End User shall not: (i) sublicense, transfer, rent, assign, distribute, publish,
        display or otherwise make the Software or any aspects of the Software available to any third party for any
        reason; (ii) copy, modify, adapt or otherwise create derivative works from the Software; or (iii) reverse
        engineer, disassemble, decode, or otherwise attempt to translate or gain access to the source code of the
        Software. </li
      ><li>
        3. <u>Ownership and Proprietary Rights.</u> End User acknowledges and agrees that all rights, title and interest
        in and to the Software, including any enhancements, corrections and modifications to the Software and any
        copyrights, patents, trade secrets, trademarks, service marks or any other intellectual property rights
        protecting or pertaining to any aspect of the Software, shall at all times remain the sole and exclusive
        property of the Company. </li
      ><li>
        4. <u>Collection and Use of End-User Information.</u> End User acknowledges and agrees that in exchange for the
        Company providing End User with a subscription license to access and use the Software, the Company will have the
        right to collect, maintain, evaluate and use all personal and non-personal information and data obtained in
        connection with the End User’s use of the Software (the “End User Data”), subject to the terms of Section 5. The
        Company shall be the sole owner of any and all information, including, without limitation, data, analyses,
        statistics, reports, and trends generated or derived from, or otherwise related to, the End User Data. The
        Company shall have no obligation to retain any End User Data during or after the term of this Agreement. The
        Company will, however, provide any personal information of End User contained in the End User Data it has
        retained to the End User upon request. The End User hereby authorizes the tracking and/or monitoring of all
        devices on which the Software is installed or through which the Software or End User’s account is otherwise
        accessed and/or used. The End User shall be solely responsible for disclosing all terms and conditions regarding
        the use of the Software to its employees, contractors, agents, or any of its authorized users of the Software
        and the End User represents and warrants to the Company that End User will obtain all necessary legal consents
        from its employees, contractors, agents, or such of its authorized users of the Software.
      </li>
      <li>
        5. <u>Privacy Notice.</u>
        <ol class="list-none">
          <p
            >The Company will not sell, rent, license, transfer, share or otherwise disclose any personally identifiable
            information of the End User to any third party, except when such disclosure has been authorized by the End
            User, is required by applicable law, or is made to a party that controls, is controlled by or under common
            control with the Company or its successors.</p
          >
          <p>Data collected from Truxio mobile app End Users may include and is limited to:</p>
          <p>A. End User Profile data such as the first name, last name, email and phone number.</p>
          <p>B. Images uploaded by the End User.</p>
          <p
            >C. Background and foreground geo-location data may be collected in both precise (fine) and course
            (approximate) data types. Geo-location permissions are able to be enabled, changed and disabled by the End
            User either within the mobile application itself and/or on the device settings. Location data is shared with
            a 3rd party for the sole purpose of supporting basic app functionality. Specifically, we use a 3rd party API
            to geocode location data. No personally identifiable information is sent to 3rd parties, only location
            data.</p
          >
          <p
            >All collected data is transmitted securely to our systems using encryption. Data is stored securely in our
            Virtual Private Cloud (VPC) environment and is encrypted when transmitted to or from their browser or mobile
            device. If transmission to 3rd parties is required, as in the case of location data geocoding, all data is
            encrypted in transit.</p
          >
          <p
            >All End User data is retained indefinitely. In addition, End Users may request a copy of their personal
            data, or deletion of said data, by emailing <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a
            >.</p
          >
        </ol></li
      ><li>
        6. <u>Disclaimer of Warranties.</u> THE SOFTWARE IS LICENSED TO END USER “AS IS” WITHOUT ANY REPRESENTATIONS OR
        WARRANTIES OF ANY KIND. THE COMPANY HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR
        IMPLIED, WITH RESPECT TO THE SOFTWARE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF NON-INFRINGEMENT,
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. </li
      ><li>
        7. <u>Limitation of Liability.</u> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE
        COMPANY OR ANY OF ITS OFFICERS, DIRECTORS, AGENTS, RESELLERS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
        CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, WHETHER IN CONTRACT, IN TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, OR FOR ANY LOSS OF PROFITS, LOSS OF SAVINGS, LOSS OF DATA OR LOSS OF USE
        DAMAGES, ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE USE OF (OR INABILLITY TO USE) THE SOFTWARE EVEN IF
        THE COMPANY OR ITS OFFICERS, DIRECTORS, AGENTS, RESELLERS, SUPPLIERS OR LICENSORS WERE AWARE OF THE POSSIBILITY
        OF SUCH POTENTIAL LOSS OR DAMAGE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
        CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. </li
      ><li>
        8. <u>Indemnification.</u> End User shall indemnify, defend and hold harmless the Company and its officers,
        stockholders, employees, representatives and agents, and their respective successors and assigns, from and
        against any and all losses, claims, demands, damages, liabilities, costs or expenses of any kind (including
        attorneys’ fees), arising out of or relating to End User’s use or misuse of the Software or breach of this
        Agreement. </li
      ><li>
        9. <u>Termination.</u> The Company may in its sole discretion terminate this Agreement at any time upon
        providing notice of termination to the End User. Upon termination of this Agreement, End User shall have no
        further rights to access or use of the Software after the termination date.
      </li>
      <li>
        10. <u>Miscellaneous.</u>
        <ol class="list-none">
          <li>
            <u>(a) Governing Law;</u> Venue. This Agreement is governed by and shall be construed in accordance with the
            internal laws of the state of Delaware without regard to any choice or conflict of law provision or rule.
          </li>
          <li>
            <u>(b) Entire Agreement.</u> This Agreement constitutes the entire agreement and understanding between the
            parties pertaining to the subject matter hereof and supersedes all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral, with respect to such subject matter.
          </li>
          <li>
            <u>(c) Severability.</u> If any provision of this Agreement is held to be illegal, invalid or unenforceable
            under present or future laws effective during the term of this Agreement, such provision shall be fully
            severable and this Agreement shall be construed and enforced as if such illegal, invalid, or unenforceable
            provision had never comprised a part of this Agreement; and the remaining provisions of this Agreement shall
            remain in full force and effect and shall not be affected by the illegal, invalid or unenforceable provision
            or by its severance from this Agreement.
          </li>
          <li>
            <u>(d) No Waiver.</u> No consent or waiver, express or implied, by any party to or of any breach or default
            by any other party in the performance by such other party of its obligations hereunder shall be deemed or
            construed to be a consent or waiver to or of any other breach or default in the performance by such other
            party of the same or any other obligation of such party hereunder. Failure on the part of any party to
            complain of any act or failure to act of the other party or to declare such other party in default,
            irrespective of how long such failure continues, shall not constitute a waiver by such party of its rights
            hereunder. No consent or waiver shall, except as otherwise specifically provided in this Agreement, be
            effective unless it is in writing and is signed by or on behalf of the party asserted to have granted such
            consent or waiver.
          </li>
          <li>
            <u>(e) Amendments.</u> This Agreement may be amended only by a written instrument signed by all of the
            parties.
          </li>
          <li>
            <u>(f) Assignment.</u> End User may not assign this Agreement or any of the rights, interests and
            obligations of End User hereunder without the prior written consent of the Company.
          </li>
          <li>
            <u>(g) Captions.</u> The captions and headings of the Sections and paragraphs provided in this Agreement are
            intended for descriptive and reference purposes only and are not intended to limit the applicability of the
            terms of any such Section or paragraph.
          </li>
          <li>
            <u>(h) Inquiries.</u> All inquiries regarding developer information, End User Agreement and Privacy Policy,
            or any other general information may be made by emailing Truxio at
            <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a
            >.
          </li>
        </ol>
      </li></ul
    >
  </div>
</div>
