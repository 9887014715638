<app-company-edit
  *ngIf="isEditDialogVisible"
  #companyEditDialog
  (modelEdited)="handleModelEdit($event)"
  (dialogClosed)="editDialogClosed()"
>
</app-company-edit>
<app-bulkimport #bulkimportDialog (dialogClosed)="onCloseDialog()"> </app-bulkimport>
<p-card styleClass="ui-card-shadow">
  <div class="layout-content-container table-container">
    <div class="grid grid-nogutter table-inner">
      <div class="grid grid-nogutter col-12 p-2 flex justify-content-between align-items-center">
        <div class="col-2 pl-2">
          <h2 (click)="typeMenu.toggle($event)">{{ typeTitle }} <i class="fa fa-angle-down ml-15"></i></h2>
          <p-menu
            #typeMenu
            [popup]="true"
            styleClass="company-list"
            [model]="typeItems"
            appendTo="body"
            [style]="{ width: '10rem' }"
          ></p-menu>
        </div>
        <div class="col-2">
          <p-button
            (onClick)="export()"
            label="Export to Excel"
            icon="fa fa-download"
            iconPos="right"
            class="flex justify-content-end ui-button-secondary actionButtons"
          ></p-button>
        </div>
      </div>
      <div class="grid grid-nogutter col-12 p-2">
        <div class="col-2">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon ui-inputgroup-addon-inside"><i class="fa fa-search"></i></span>
            <input
              class="col-12"
              [(ngModel)]="filter.search"
              (keyup)="filterTable()"
              placeholder="Search {{ typeTitle }}"
              pInputText
            />
          </div>
        </div>
        <div class="col-4 col-offset-6">
          <div class="main-actions ui-right">
            <p-button
              *ngIf="isSuperAdmin || isImpersonating"
              (onClick)="bulkImportProcess()"
              label="Bulk Import"
              class="ui-button-link"
            ></p-button>
            <p-button
              (onClick)="addCompany()"
              icon="fa fa-fw fa-plus"
              label="New Company"
              class="ui-button-secondary"
            ></p-button>
          </div>
        </div>
      </div>
      <div class="grid grid-nogutter col-12 p-2">
        <p-table
          [columns]="cols"
          [value]="companies"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="70vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : null" pResizableColumn>
                {{ col.header }}
                <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>
