import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() disableEdit: boolean = false;
  @Input() set notes(value: string) {
    this._notes = value;
    this.notesControl.setValue(value);
  }
  get notes(): string {
    return this._notes;
  }
  @Input() set maxLength(value: number) {
    this._maxLength = value;
    this.conditionallyAddMaxLengthValidation();
  }
  get maxLength(): number {
    return this._maxLength;
  }

  @Output() save = new EventEmitter<string>();

  public notesControl = new FormControl('');
  public editing: boolean = false;
  public disableSave$: Observable<boolean>;

  private destroy$ = new Subject<void>();
  private _notes: string;
  private _maxLength: number = null;

  public ngOnInit(): void {
    this.disableSave$ = this.notesControl.valueChanges.pipe(
      startWith(false),
      map(() => !this.notesControl.valid),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveNotes(): void {
    if (this.notesControl.valid) {
      this.save.emit(this.notesControl.value);
      this.editing = false;
    }
  }

  public cancel(): void {
    this.notesControl.setValue(this.notes);
    this.editing = false;
  }

  private conditionallyAddMaxLengthValidation(): void {
    if (this.maxLength) {
      this.notesControl.setValidators(Validators.maxLength(this.maxLength));
    } else {
      this.notesControl.clearValidators();
    }
  }
}
