import {
  Component,
  forwardRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injector,
  OnChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { AddressDTO, CompanyAddressDTO, IdNamePairDTO, LoadLegStopDTO, SimpleContactDTO } from 'src/apiclient/models';
import { CompanyAddressService } from 'src/apiclient/services';
import { ContactComponent } from 'src/app/components/contact/contact.component';
import { ConfirmationService } from 'primeng/api';
import { CompanyAddressType } from '../../../../company-address-quick-add/company-quick-add-type';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stop',
  templateUrl: './stop.component.html',
  styleUrls: ['./stop.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StopComponent),
      multi: true,
    },
    ConfirmationService,
  ],
})
export class StopComponent extends DtoFormBase<LoadLegStopDTO> implements OnInit, ControlValueAccessor, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() stop: LoadLegStopDTO;
  @Input() stops: LoadLegStopDTO[];
  @Input() index: number;
  @Input() readonly: boolean = false;

  @Output() sortStops: EventEmitter<any> = new EventEmitter<any>();
  @Output() stopChanged: EventEmitter<LoadLegStopDTO> = new EventEmitter<LoadLegStopDTO>();

  @ViewChild(ContactComponent, { static: true }) contactForm;
  @ViewChild('focusable', { static: true }) focusable: ElementRef;
  @ViewChild('freightDescription', { static: true }) freightDescription: ElementRef;

  public stopDateTime: Date;
  public receivingHours: string;
  public needsHighlight: boolean;

  private shippingAppointmentRequired: boolean;
  private consigneeAppointmentRequired: boolean;
  private shippingHours: string;
  private shippingNotesInternal: string;
  private shippingNotesReports: string;
  private receivingNotesInternal: string;
  private receivingNotesReports: string;
  private stopContact: any;
  private stopType: string;
  private stopCompanyAddressId: number;
  private destroy$ = new Subject();

  constructor(
    private companyAddressService: CompanyAddressService,
    private confirmationService: ConfirmationService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('LoadLegStopDTO', ['companyShipperId']);
    this.showProgressOverlay = true;

    if (this.stop.scheduledTime === '0001-01-01T00:00:00') {
      this.stop.scheduledTime = null;
    } else if (this.stop.scheduledTime) {
      const incomingDateTime = new Date(this.stop.scheduledTime);
      this.stopDateTime = new Date(this.getFormattedDate(incomingDateTime));
    }
    if (this.stop.companyAddress) {
      this.stopContact = this.stop.contact;
      this.stopType = this.stop.stopType;
      this.stopCompanyAddressId = this.stop.companyAddress.id;
    }
    this.updateLoadUIViewMode();
  }

  public ngOnChanges(): void {
    this.stopModelChanged();
  }

  public writeValue(stopObj: LoadLegStopDTO): void {
    this.stop = stopObj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }

  public updateStopScheduledTime($event: Date): void {
    if ($event) {
      setTimeout((_) => {
        this.stop.scheduledTime = this.getFormattedDate($event);
        this.stopModelChanged();
        if (this.checkPriorScheduledTime($event)) {
          this.confirmationService.confirm({
            message: 'Your selected date is prior/later to an existing pick/drop, Would you like to reorder?',
            accept: () => {
              this.sortStops.emit();
              setTimeout(() => {
                this.focusable.nativeElement.focus();
              });
            },
            reject: () => {},
          });
        }
      });
    } else {
      this.stop.scheduledTime = null;
    }
  }

  public onCompanyAddressSelectorChange($event: IdNamePairDTO): void {
    if ($event.id) {
      this.updateContactInfo($event.id);
    } else {
      this.stop.address = {} as AddressDTO;
      this.stop.contact = {} as SimpleContactDTO;
      this.needsHighlight = false;
    }
  }

  public onStopTypeChanged(): void {
    if (this.stop.companyAddress && this.stop.companyAddress.id) {
      this.updateContactInfo(this.stop.companyAddress.id);
    }
  }

  public onLocationChanged(event: string): void {
    if (!this.stop.companyAddress.name) {
      this.stop.companyAddress.name = '';
    }
    this.stop.companyAddress.name =
      this.stop.companyAddress.name.substring(0, this.stop.companyAddress.name.indexOf('-') + 1) + ' ' + event;
  }

  public onDateTimeBlur(): void {
    this.freightDescription.nativeElement.focus();
  }

  public setStopCompanyAddressType(stop: LoadLegStopDTO): CompanyAddressType {
    return stop.stopType === 'Pick' ? CompanyAddressType.Shipper : CompanyAddressType.Consignee;
  }

  private updateLoadUIViewMode(): void {
    if (this.readonly) {
      setTimeout((_) => this.formGroup.disable());
    } else {
      setTimeout((_) => this.formGroup.enable());
    }
    setTimeout((_) => (this.showProgressOverlay = false));
  }

  private checkPriorScheduledTime(currentDate: Date): boolean {
    let key = 0;
    for (const stop of this.stops) {
      if (stop.scheduledTime) {
        if (currentDate.getTime() - new Date(stop.scheduledTime).getTime() < 0 && key < this.index) {
          return true;
        }
        if (currentDate.getTime() - new Date(stop.scheduledTime).getTime() > 0 && key > this.index) {
          return true;
        }
      }
      key++;
    }
    return false;
  }

  private stopModelChanged() {
    this.propagateChange(this.stop);
    this.stopChanged.emit(this.stop);
  }

  private propagateChange = (_: any) => {};

  private updateContactInfo(companyAddressId: number): void {
    this.showProgressOverlay = true;

    this.getCompanyAddress(companyAddressId)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => this.handleError(error)),
        finalize(() => (this.showProgressOverlay = false))
      )
      .subscribe((companyAddress) => {
        this.needsHighlight = false;

        let addressContact = companyAddress.primaryContact;

        addressContact = this.setStopType(companyAddress, addressContact);

        this.stop.address = companyAddress.address;

        this.updateShippingDetails(companyAddress);

        this.updateStopAppointmentsAndNotes();

        this.updateContactDetails(addressContact);
      });
  }

  private setStopType(companyAddress: CompanyAddressDTO, addressContact: SimpleContactDTO): SimpleContactDTO {
    if (this.stop.stopType === 'Pick' && companyAddress.isShipper) {
      if (!companyAddress.shipperContactIsSame) {
        addressContact = companyAddress.shipperContact;
      }
    } else if (this.stop.stopType === 'Drop' && companyAddress.isConsignee) {
      if (!companyAddress.consigneeContactIsSame) {
        addressContact = companyAddress.consigneeContact;
      }
    } else {
      // Something weird happened in API, wrong data type returned. Just let the user fill it in.
      this.needsHighlight = true;
      addressContact = {} as SimpleContactDTO;
    }

    return addressContact;
  }

  private updateShippingDetails(companyAddress: CompanyAddressDTO): void {
    this.shippingAppointmentRequired = companyAddress.shipperAppointmentRequired;
    this.consigneeAppointmentRequired = companyAddress.consigneeAppointmentRequired;
    this.shippingHours = companyAddress.shipperOperatingHours;
    this.receivingHours = companyAddress.consigneeOperatingHours;

    this.shippingNotesInternal = companyAddress.shipperNotesInternal;
    this.shippingNotesReports = companyAddress.shipperNotesReports;
    this.receivingNotesInternal = companyAddress.consigneeNotesInternal;
    this.receivingNotesReports = companyAddress.consigneeNotesReports;
  }

  private updateStopAppointmentsAndNotes(): void {
    if (this.stop.stopType === 'Pick') {
      this.stop.appointmentRequired = this.shippingAppointmentRequired;
      this.stop.shippingReceivingHours = this.shippingHours;
      this.stop.notesInternal = this.shippingNotesInternal;
      this.stop.notesReports = this.shippingNotesReports;
    } else {
      this.stop.appointmentRequired = this.consigneeAppointmentRequired;
      this.stop.shippingReceivingHours = this.receivingHours;
      this.stop.notesInternal = this.receivingNotesInternal;
      this.stop.notesReports = this.receivingNotesReports;
    }
  }

  private updateContactDetails(addressContact: SimpleContactDTO): void {
    if (
      addressContact &&
      addressContact.firstName !== '' &&
      addressContact.firstName !== undefined &&
      addressContact.firstName !== null
    ) {
      this.stop.contact = addressContact;
    } else {
      if (!this.stop.customContactDetails) {
        this.cleanStopContactDetails();
      } else {
        if (this.stop.stopType === this.stopType && this.stopCompanyAddressId === this.stop.companyAddress.id) {
          this.stop.contact = this.stopContact;
        } else {
          this.cleanStopContactDetails();
        }
      }
    }
  }

  private cleanStopContactDetails(): void {
    this.stop.contact = {
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      phoneExt: '',
      phoneNumber: '',
    } as SimpleContactDTO;
    this.needsHighlight = true;
  }

  private getFormattedDate(date: Date): string {
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.toLocaleTimeString().replace(/\u200E/g, '')
    );
  }

  private getCompanyAddress(companyAddressId: number): Observable<CompanyAddressDTO> {
    return this.companyAddressService.ApiCompanyAddressByIdGet({
      id: companyAddressId,
      Authorization: this.clientToken.auth(),
    });
  }
}
