import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordFormFields } from './change-password-form-fields';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';
import { NotificationsService } from '../../notifications/notifications.service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
})
export class UserChangePasswordComponent implements OnInit {
  @Input() username: string;
  @Output() closeDialogEvent = new EventEmitter<void>();

  public changePasswordForm: FormGroup;
  public isFormValid$: Observable<boolean>;
  public dialogVisible: boolean = false;
  public resetInProgress: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private clientTokenService: ClientTokenService,
    private notificationsService: NotificationsService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  public ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      [ChangePasswordFormFields.Username]: [this.username, [Validators.required]],
      [ChangePasswordFormFields.Password]: ['', [Validators.required]],
      [ChangePasswordFormFields.ConfirmPassword]: ['', [Validators.required]],
      [ChangePasswordFormFields.PasswordsMatch]: [false, [Validators.requiredTrue]],
      [ChangePasswordFormFields.Permanent]: [true, [Validators.required]],
    });
    this.changePasswordForm.controls[ChangePasswordFormFields.Username].disable();

    this.isFormValid$ = this.changePasswordForm.valueChanges.pipe(
      startWith(false),
      map(() => this.changePasswordForm.valid),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetPassword(): void {
    this.resetInProgress = true;

    this.authService
      .AdminResetPassword({
        Authorization: this.clientTokenService.auth(),
        body: this.changePasswordForm.getRawValue(),
      })
      .pipe(
        tap(() => {
          this.notificationsService.success('Success!', 'Password successfully updated.');
          this.closeDialog();
        }),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.resetInProgress = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public closeDialog(): void {
    this.closeDialogEvent.emit();
  }
}
