import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConfig } from '../../config/app.config';
import { CancelledComponent } from './cancelled/cancelled.component';
import { RequiredComponent } from './required/required.component';
import { SuccessComponent } from './success/success.component';

const routes: Routes = [
  { path: AppConfig.routes.subscription.required, component: RequiredComponent },
  { path: AppConfig.routes.subscription.success, component: SuccessComponent },
  { path: AppConfig.routes.subscription.cancelled, component: CancelledComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
