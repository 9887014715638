/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EquipmentSummaryDTO } from '../models/equipment-summary-dto';
import { EquipmentDTO } from '../models/equipment-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { AlertIdNamePairDTO } from '../models/alert-id-name-pair-dto';
import { EquipmentQueryDTO } from '../models/equipment-query-dto';
@Injectable({
  providedIn: 'root',
})
class EquipmentService extends __BaseService {
  static readonly ApiEquipmentGetPath = '/api/Equipment';
  static readonly ApiEquipmentPostPath = '/api/Equipment';
  static readonly ApiEquipmentByIdGetPath = '/api/Equipment/{id}';
  static readonly ApiEquipmentByIdPutPath = '/api/Equipment/{id}';
  static readonly ApiEquipmentTypesGetPath = '/api/Equipment/Types';
  static readonly ApiEquipmentOptionsGetPath = '/api/Equipment/Options';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of all equipment records.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiEquipmentGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<EquipmentSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Equipment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EquipmentSummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of all equipment records.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiEquipmentGet(Authorization: string): __Observable<Array<EquipmentSummaryDTO>> {
    return this.ApiEquipmentGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<EquipmentSummaryDTO>)
    );
  }

  /**
   * Creates a new equipment record.
   * @param params The `EquipmentService.ApiEquipmentPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiEquipmentPostResponse(params: EquipmentService.ApiEquipmentPostParams): __Observable<__StrictHttpResponse<EquipmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Equipment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EquipmentDTO>;
      })
    );
  }
  /**
   * Creates a new equipment record.
   * @param params The `EquipmentService.ApiEquipmentPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiEquipmentPost(params: EquipmentService.ApiEquipmentPostParams): __Observable<EquipmentDTO> {
    return this.ApiEquipmentPostResponse(params).pipe(
      __map(_r => _r.body as EquipmentDTO)
    );
  }

  /**
   * Get equipment by EquipmentId
   * @param params The `EquipmentService.ApiEquipmentByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiEquipmentByIdGetResponse(params: EquipmentService.ApiEquipmentByIdGetParams): __Observable<__StrictHttpResponse<EquipmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Equipment/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EquipmentDTO>;
      })
    );
  }
  /**
   * Get equipment by EquipmentId
   * @param params The `EquipmentService.ApiEquipmentByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiEquipmentByIdGet(params: EquipmentService.ApiEquipmentByIdGetParams): __Observable<EquipmentDTO> {
    return this.ApiEquipmentByIdGetResponse(params).pipe(
      __map(_r => _r.body as EquipmentDTO)
    );
  }

  /**
   * Update an existing Equipment
   * @param params The `EquipmentService.ApiEquipmentByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiEquipmentByIdPutResponse(params: EquipmentService.ApiEquipmentByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Equipment/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing Equipment
   * @param params The `EquipmentService.ApiEquipmentByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiEquipmentByIdPut(params: EquipmentService.ApiEquipmentByIdPutParams): __Observable<null> {
    return this.ApiEquipmentByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the equipment type options used by the tenant/carrier
   * NOTE: This currently returns the full list, same as SystemSettings. We will add a tenant manage screen for this in a later sprint.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiEquipmentTypesGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Equipment/Types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Returns the equipment type options used by the tenant/carrier
   * NOTE: This currently returns the full list, same as SystemSettings. We will add a tenant manage screen for this in a later sprint.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiEquipmentTypesGet(Authorization: string): __Observable<Array<IdNamePairDTO>> {
    return this.ApiEquipmentTypesGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Returns the Truck/Trailer options configured by the tenant. For use in dropdowns
   * @param params The `EquipmentService.ApiEquipmentOptionsGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `query`:
   *
   * @return Success
   */
  ApiEquipmentOptionsGetResponse(params: EquipmentService.ApiEquipmentOptionsGetParams): __Observable<__StrictHttpResponse<Array<AlertIdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.query;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Equipment/Options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertIdNamePairDTO>>;
      })
    );
  }
  /**
   * Returns the Truck/Trailer options configured by the tenant. For use in dropdowns
   * @param params The `EquipmentService.ApiEquipmentOptionsGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `query`:
   *
   * @return Success
   */
  ApiEquipmentOptionsGet(params: EquipmentService.ApiEquipmentOptionsGetParams): __Observable<Array<AlertIdNamePairDTO>> {
    return this.ApiEquipmentOptionsGetResponse(params).pipe(
      __map(_r => _r.body as Array<AlertIdNamePairDTO>)
    );
  }
}

module EquipmentService {

  /**
   * Parameters for ApiEquipmentPost
   */
  export interface ApiEquipmentPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: EquipmentDTO;
  }

  /**
   * Parameters for ApiEquipmentByIdGet
   */
  export interface ApiEquipmentByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiEquipmentByIdPut
   */
  export interface ApiEquipmentByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: EquipmentDTO;
  }

  /**
   * Parameters for ApiEquipmentOptionsGet
   */
  export interface ApiEquipmentOptionsGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    query?: EquipmentQueryDTO;
  }
}

export { EquipmentService }
