import {
  Component,
  OnInit,
  Input,
  Injector,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoadLegJournalDTO } from 'src/apiclient/models';
import { LoadJournalService } from 'src/apiclient/services';
import { LoadStatusName } from 'src/app/data/static-data';
import { Utils } from 'src/utils/utils';
import { FocusTrapService } from 'src/services/focus-trap.service';
import { DtoFormBase } from 'src/app/shared/FormBase';

export enum ViewModes {
  Load = 'Load',
  LoadCard = 'LoadCard',
  DriverCard = 'DriverCard',
}

@Component({
  selector: 'app-leg-tracking',
  templateUrl: './leg-tracking.component.html',
  styleUrls: ['./leg-tracking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegTrackingComponent extends DtoFormBase<undefined> implements OnInit {
  latestJournalEntry: LoadLegJournalDTO;
  newJournalEntry: LoadLegJournalDTO;
  showTrackingUpdate = false;
  showTrackingHistory: boolean = false;
  cols: any[];
  journals: any[];
  loading: boolean = false;

  @Input() trackingJournal: Array<LoadLegJournalDTO> = new Array<LoadLegJournalDTO>();
  @Input() formGroup: FormGroup;
  @Input() loadId: number;
  @Input() loadNumber: number;
  @Input() loadLegId: number;
  @Input() driverName: string;
  @Input() readonly: boolean = false;
  @Input() needsUpdate: boolean = false;
  @Input() viewMode: string = ViewModes.Load;
  @Input() loadStatus: string;

  @Output() journalChanged = new EventEmitter<void>();

  showTrackingIcons: boolean = false;

  constructor(
    private focusTrap: FocusTrapService,
    protected injector: Injector,
    private loadJournalService: LoadJournalService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() {
    this.addJournalEntry();
    this.sortJournal();
    const notTrackableStatuses: string[] = [
      LoadStatusName.Delivered,
      LoadStatusName.Audited,
      LoadStatusName.Invoiced,
      LoadStatusName.Paid,
      LoadStatusName.Archive,
    ];
    if (notTrackableStatuses.includes(this.loadStatus)) {
      this.showTrackingIcons = false;
    } else {
      this.showTrackingIcons = true;
    }
    this.cols = [
      {
        header: 'Tracking',
        field: 'status',
        format: 'TrackingV2',
      },
      {
        header: 'Location',
        field: 'city',
      },
      {
        header: 'Date',
        field: 'createdOn',
        format: 'DateLocale',
        sortable: true,
      },
      {
        header: 'By',
        field: 'createdBy',
        sortableField: 'createdBy.name',
        format: 'UserName',
      },
      {
        header: 'Method',
        field: 'captureMethod',
      },
      {
        header: 'Notes',
        field: 'notes',
      },
    ];
  }

  public showTrackingUpdateScreen(): void {
    this.addJournalEntry();
    this.showTrackingUpdate = true;
    this.focusTrap.onDialog(true);
    this.cdr.detectChanges();
  }

  async showTrackingHistoryScreen() {
    await this.loadLegJournal();
    this.showTrackingHistory = true;
    this.cdr.detectChanges();
  }

  sortJournal() {
    if (this.trackingJournal.length > 0) {
      const sortedJournal = this.trackingJournal.sort((jeA, jeB) => {
        return jeB.loadLegJournalId - jeA.loadLegJournalId;
      });
      setTimeout((_) => {
        this.trackingJournal = sortedJournal;
        this.latestJournalEntry = this.trackingJournal[0];
      });
    } else {
      setTimeout((_) => {
        this.latestJournalEntry = null;
      });
    }
  }

  public journalModelChanged(): void {
    this.journalChanged.emit();
    this.sortJournal();
    this.focusTrap.onDialog(false);
    this.cdr.detectChanges();
  }

  addJournalEntry() {
    if (!this.trackingJournal) {
      this.trackingJournal = new Array();
    }
  }

  async loadLegJournal() {
    this.loading = true;
    this.cdr.detectChanges();
    await this.loadJournalService
      .ApiLoadJournalLegByLegIdGet({ legId: this.loadLegId, Authorization: this.clientToken.auth() })
      .toPromise()
      .then(async (data) => {
        this.journals = data;
        this.formatData();
        this.loading = false;
        this.cdr.detectChanges();
      }, this.handleError);
  }
  sortTable(event: any) {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
    }
    Utils.sortData(event, this.journals);
  }
  formatData() {
    this.journals.forEach((x) => {
      x.city = [x.city, x.state].filter((v) => v).join(', ');
      x.captureMethod = x.captureMethod === 'GPS' ? 'Automatic' : 'Manual';
    });
    this.journals = this.journals.sort((a, b) => {
      return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
    });
  }

  getLegStatusClass() {
    let statusClass = '';
    switch (this.latestJournalEntry.status) {
      case 'OnTime':
        statusClass = 'greenclock';
        break;
      case 'Delayed':
        statusClass = 'redclock';
        break;
      default:
        statusClass = 'grayclock';
        break;
    }

    return statusClass;
  }
}
