<p-dialog
  [(visible)]="displayDialog"
  [contentStyle]="contentDialogStyle"
  [style]="{ height: dialogHeight, top: '0px', left: '0px' }"
  blockScroll="true"
  [modal]="true"
  (onHide)="cancel()"
  [focusTrap]="false"
  appendTo="body"
  styleClass="full-size-dialog"
  [closable]="false"
  [draggable]="false"
  [focusOnShow]="false"
>
  <p-header>
    <div class="col-12 p-0">
      <div class="text-2xl font-normal col-8 p-0">
        {{ isNewRecord ? 'New Driver' : model.firstName + ' ' + model.lastName }}
      </div>
    </div>
  </p-header>
  <div class="grid grid-nogutter col-12 p-2" id="driverProfileContainer">
    <form [formGroup]="formGroup" (ngSubmit)="saveModel()">
      <div class="col-12 p-0">
        <div class="grid grid-nogutter col-12 p-2 form-section">
          <div class="grid grid-nogutter p-1 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Personal Info </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-8 p-0">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Name *"
                    formControlName="firstName"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.firstName"
                    placeholder="First Name"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    labelClass="col-6 p-0"
                    controlClass="col-6 px-1"
                  >
                  </app-form-field>
                </div>
                <div class="col-4 p-0">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label=""
                    formControlName="lastName"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.lastName"
                    placeholder="Last Name"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    labelClass=""
                    controlClass="col-12"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Date Of Birth"
                    type="calendar"
                    formControlName="dateOfBirth"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.dateOfBirth"
                    placeholder="mm/dd/yyyy"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Hire Date"
                    type="calendar"
                    formControlName="hireDate"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.hireDate"
                    placeholder=""
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Termination Date"
                    type="calendar"
                    formControlName="terminationDate"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.terminationDate"
                    placeholder=""
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Category Type *"
                    type="select"
                    placeholder="Select"
                    formControlName="category"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.category"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    [config]="categoryConfig"
                    [autoDisplayFirst]="false"
                    labelClass="col-4 p-0"
                    controlClass="col-8 py-1"
                    [controlClassLevel1]="viewOnly || viewAccounting ? '' : 'px-1'"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Status *"
                    type="radio"
                    formControlName="status"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.status"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    [config]="statusConfig"
                    labelClass="col-4 p-0"
                    controlClass="col-8 py-1"
                  >
                  </app-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter p-1 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Contact Info </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Email *"
                    formControlName="email"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.email"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    placeholder="Email"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Mobile Phone *"
                    formControlName="phone"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.phone"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    placeholder="Mobile Phone"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                    keyFilter="number"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Alternate Phone"
                    formControlName="alternatePhone"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.alternatePhone"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    placeholder="Alternate Phone"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                    keyFilter="number"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-address-v2
                    displayMode="twocol"
                    [collapsed]="false"
                    [(Address)]="model.address"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    [validationMessages]="validationMessages"
                  >
                  </app-address-v2> </div></div
            ></div>
          </div>
          <div class="grid grid-nogutter p-1 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Emergency contact </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="grid grid-nogutter col-12 p-0">
                  <div class="col-8 p-0">
                    <app-form-field
                      [(formGroup)]="formGroup"
                      label="Name"
                      formControlName="emergencyContactName"
                      [formErrors]="formErrors"
                      [(ngModel)]="model.emergencyContactName"
                      [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                      placeholder="First Name"
                      labelClass="col-6 p-0"
                      controlClass="col-6 px-1"
                    >
                    </app-form-field>
                  </div>
                  <div class="col-4 p-0">
                    <app-form-field
                      [(formGroup)]="formGroup"
                      formControlName="emergencyContactLastName"
                      [formErrors]="formErrors"
                      [(ngModel)]="model.emergencyContactLastName"
                      [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                      placeholder="Last Name"
                      labelClass="nolabel"
                      controlClass="col-12"
                    >
                    </app-form-field>
                  </div>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Relation"
                    formControlName="emergencyContactRelation"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.emergencyContactRelation"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    placeholder="Relation"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                  >
                  </app-form-field>
                </div>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-12">
                  <app-form-field
                    [(formGroup)]="formGroup"
                    label="Phone"
                    formControlName="emergencyContactPhone"
                    [formErrors]="formErrors"
                    [(ngModel)]="model.emergencyContactPhone"
                    [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                    placeholder="Phone"
                    labelClass="col-4 p-0"
                    controlClass="col-8"
                    keyFilter="number"
                  >
                  </app-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-nogutter col-12 p-2 form-section">
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-0 py-1 text-xl"> Pay Settings </div>
            <div class="col-12 p-1">
              <app-form-field
                [(formGroup)]="formGroup"
                label="Pay Type"
                type="select"
                formControlName="payType"
                [formErrors]="formErrors"
                [(ngModel)]="model.payType"
                placeholder=""
                [viewMode]="viewOnly ? 'display' : ''"
                [config]="payTypeConfig"
                labelClass="col-4 p-2"
                controlClass="col-8"
              >
              </app-form-field>
            </div>
            <div class="col-12 p-1" *ngIf="model.payType === 'PerMile'">
              <app-form-field
                [(formGroup)]="formGroup"
                label="Loaded Miles"
                [config]="{ textIcon: '$', displayTextIcon: false }"
                keyFilter="currency"
                formControlName="loadedMiles"
                [formErrors]="formErrors"
                [(ngModel)]="model.loadedMiles"
                placeholder=""
                [viewMode]="viewOnly ? 'display' : ''"
                labelClass="col-4 p-2"
                controlClass="col-8 p-0"
              >
              </app-form-field>
            </div>
            <div class="col-12 p-1" *ngIf="model.payType === 'PerMile'">
              <app-form-field
                [(formGroup)]="formGroup"
                label="Empty Miles"
                [config]="{ textIcon: '$', displayTextIcon: false }"
                keyFilter="currency"
                formControlName="emptyMiles"
                [formErrors]="formErrors"
                [(ngModel)]="model.emptyMiles"
                placeholder=""
                [viewMode]="viewOnly ? 'display' : ''"
                labelClass="col-4 p-2"
                controlClass="col-8 p-0"
              >
              </app-form-field>
            </div>
            <div class="col-12 p-1" *ngIf="model.payType === 'PercentOfRate'">
              <app-form-field
                [(formGroup)]="formGroup"
                label="Standard %"
                [config]="{ textIcon: '%', displayTextIcon: false }"
                formControlName="percentOfRate"
                [formErrors]="formErrors"
                [(ngModel)]="model.percentOfRate"
                placeholder=""
                [viewMode]="viewOnly ? 'display' : ''"
                labelClass="col-4 p-2"
                controlClass="col-8 p-0"
              >
              </app-form-field>
            </div>
          </div>
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="p-0 py-1 text-xl"> Recurring Payments </div>
              <app-form-charges
                [(formGroup)]="formGroup"
                [(charges)]="model.recurringPayments"
                [viewMode]="viewOnly ? 'display' : ''"
                chargeLabel="Recurring Payment"
              ></app-form-charges>
            </div>
          </div>
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="p-0 py-1 text-xl"> Recurring Deductions</div>
              <app-form-charges
                [(formGroup)]="formGroup"
                [(charges)]="model.recurringDeductions"
                [viewMode]="viewOnly ? 'display' : ''"
                chargeLabel="Recurring Deduction"
              ></app-form-charges>
            </div>
          </div>
        </div>
        <div class="grid grid-nogutter col-12 p-2 form-section" [hidden]="viewAccounting">
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Licensing Info </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="License Type"
                  type="select"
                  formControlName="licenseType"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.licenseType"
                  [config]="licenseTypeConfig"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8 p-0"
                  [controlClassLevel1]="viewOnly || viewAccounting ? '' : 'p-1'"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="License State"
                  type="state-selector"
                  placeholder="State"
                  formControlName="licenseState"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.licenseState"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8 p-0"
                  [controlClassLevel1]="viewOnly || viewAccounting ? '' : 'p-1'"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="License Number"
                  formControlName="licenseNumber"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.licenseNumber"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="License Expires"
                  type="calendar"
                  formControlName="licenseExpires"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.licenseExpires"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="grid grid-nogutter col-12 p-1">
                <div class="col-4">
                  <div class="{{ viewOnly || viewAccounting ? '' : 'label-black p-0' }} col-12">Endorsements</div>
                </div>
                <div class="col-8 p-1">
                  <div class="grid grid-nogutter col-12 p-0">
                    <div class="endorsementCheck">
                      <app-form-field
                        [(formGroup)]="formGroup"
                        type="check"
                        formControlName="endorsementT"
                        [formErrors]="formErrors"
                        [(ngModel)]="model.endorsementT"
                        [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                        [config]="{ checkLabel: 'T', checkLabelClass: 'checkMargin p-0' }"
                        labelClass="nolabel"
                        controlClass="col-12 p-0"
                      >
                      </app-form-field>
                    </div>
                    <div class="endorsementCheck">
                      <app-form-field
                        [(formGroup)]="formGroup"
                        type="check"
                        formControlName="endorsementP"
                        [formErrors]="formErrors"
                        [(ngModel)]="model.endorsementP"
                        [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                        [config]="{ checkLabel: 'P', checkLabelClass: 'checkMargin p-0' }"
                        labelClass="nolabel"
                        controlClass="col-12 p-0"
                      >
                      </app-form-field>
                    </div>
                    <div class="endorsementCheck">
                      <app-form-field
                        [(formGroup)]="formGroup"
                        type="check"
                        formControlName="endorsementN"
                        [formErrors]="formErrors"
                        [(ngModel)]="model.endorsementN"
                        [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                        [config]="{ checkLabel: 'N', checkLabelClass: 'checkMargin p-0' }"
                        labelClass="nolabel"
                        controlClass="col-12 p-0"
                      >
                      </app-form-field>
                    </div>
                    <div class="endorsementCheck">
                      <app-form-field
                        [(formGroup)]="formGroup"
                        type="check"
                        formControlName="endorsementH"
                        [formErrors]="formErrors"
                        [(ngModel)]="model.endorsementH"
                        [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                        [config]="{ checkLabel: 'H', checkLabelClass: 'checkMargin p-0' }"
                        labelClass="nolabel"
                        controlClass="col-12 p-0"
                      >
                      </app-form-field>
                    </div>
                    <div class="endorsementCheck">
                      <app-form-field
                        [(formGroup)]="formGroup"
                        type="check"
                        formControlName="endorsementX"
                        [formErrors]="formErrors"
                        [(ngModel)]="model.endorsementX"
                        [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                        [config]="{ checkLabel: 'X', checkLabelClass: 'checkMargin p-0' }"
                        labelClass="nolabel"
                        controlClass="col-12 p-0"
                      >
                      </app-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Hazmat Expires"
                  type="calendar"
                  formControlName="hazmatExpires"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.hazmatExpires"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Passport Expires"
                  type="calendar"
                  formControlName="passportExpires"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.passportExpires"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Medical Info </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Last Medical"
                  type="calendar"
                  formControlName="lastMedical"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.lastMedical"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Next Medical"
                  type="calendar"
                  formControlName="nextMedical"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.nextMedical"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Last Drug Test"
                  type="calendar"
                  formControlName="lastDrugTest"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.lastDrugTest"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Last Alcohol Test"
                  type="calendar"
                  formControlName="lastAlcoholTest"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.lastAlcoholTest"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter p-2 lg:col-4 md:col-6 sm:col-12">
            <div class="col-12 p-1">
              <div class="col-4 p-0 py-1 text-xl"> Corporate Info </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Fuel Card Number"
                  formControlName="fuelCardNumber"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.fuelCardNumber"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Fuel Card Expires"
                  type="calendar"
                  formControlName="fuelCardExpires"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.fuelCardExpires"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Fleet / Division"
                  formControlName="fleetOrDivision"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.fleetOrDivision"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Truck Number"
                  formControlName="truckNumber"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.truckNumber"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
              <div class="col-12 p-1">
                <app-form-field
                  [(formGroup)]="formGroup"
                  label="Trailer Number"
                  formControlName="trailerNumber"
                  [formErrors]="formErrors"
                  [(ngModel)]="model.trailerNumber"
                  placeholder=""
                  [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                  labelClass="col-4 p-0"
                  controlClass="col-8"
                >
                </app-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-nogutter col-12 p-2 form-section">
          <div class="col-8" [hidden]="viewAccounting">
            <div class="col-4 p-0 py-1 text-xl"> Employee Records Checklist </div>
            <div class="col-12">
              <app-records-checklist
                [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
                [(formGroup)]="formGroup"
                [(documentsOnFile)]="model.documentsOnFile"
              ></app-records-checklist>
            </div>
          </div>
          <div class="{{ viewAccounting ? 'col-12' : 'col-4' }}">
            <div class="col-4 p-0 py-1 text-xl"> Internal Notes </div>
            <app-form-field
              [(formGroup)]="formGroup"
              type="textarea"
              label=""
              formControlName="internalNotes"
              [formErrors]="formErrors"
              [(ngModel)]="model.internalNotes"
              placeholder=""
              [config]="{ displayBold: false }"
              [viewMode]="viewOnly || viewAccounting ? 'display' : ''"
              labelClass=""
              controlClass="col-12 p-2"
            >
            </app-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button
      *ngIf="viewOnly"
      type="button"
      pButton
      icon="fa fa-times"
      (click)="cancel()"
      label="Close"
      class="ui-button-secondary"
    ></button>

    <button
      *ngIf="viewOnly"
      type="button"
      pButton
      icon="fa fa-pencil-alt"
      form="htmlformGroup"
      (click)="viewOnly = false"
      [disabled]="disableSave"
      label="{{ 'Edit' }}"
    ></button>

    <button
      *ngIf="!viewOnly"
      type="button"
      pButton
      icon="fa fa-times"
      (click)="isNewRecord ? cancel() : cancelEditing()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>

    <button
      *ngIf="!viewOnly"
      type="button"
      pButton
      icon="fa fa-check"
      form="htmlformGroup"
      (click)="saveModel()"
      [disabled]="disableSave"
      label="Save"
    ></button>
    <!-- [disabled]="!formGroup.valid" -->
  </p-footer>
</p-dialog>
