import { Injectable } from '@angular/core';
import { LoadDTO, LoadLegDTO } from 'src/apiclient/v1.1/models';

@Injectable({
  providedIn: 'root',
})
export class CopyLoadService {
  public copyLoad(load: LoadDTO): LoadDTO {
    const newLoad = { ...load };

    newLoad.loadId = 0;
    newLoad.loadNumber = 0;
    newLoad.workOrder = null;
    newLoad.lastTrackingStatus = null;
    newLoad.alerts = null;
    newLoad.lastTrackingUpdatedUtc = null;
    newLoad.status = 'Open';

    this.clearLoadLegSpecificProperties(newLoad);

    return newLoad;
  }

  private clearLoadLegSpecificProperties(newLoad: LoadDTO): void {
    newLoad.legs?.forEach((leg) => {
      leg.loadLegId = 0;
      leg.needsUpdate = false;
      leg.payStatus = null;
      leg.journal = null;

      this.clearLoadLegStopSpecificProperties(leg);
    });
  }

  private clearLoadLegStopSpecificProperties(loadLeg: LoadLegDTO): void {
    loadLeg.stops?.forEach((stop) => {
      stop.loadLegStopId = 0;
      stop.scheduledTime = null;
      stop.poNumbers = null;
    });
  }
}
