import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/services/breadcrumb.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  constructor(private breadcrumbService: BreadcrumbService) {}

  public ngOnInit(): void {
    this.breadcrumbService.setItems('users');
  }
}
