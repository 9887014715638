import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClientTokenService } from '../client-token-service';
import { AppConfig } from 'src/app/config/app.config';
import { LogisticsConfig } from 'src/app/config/logistics.config';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router, private clientTokenService: ClientTokenService) {}

  public navigateToDefaultDashboard(): void {
    const dashboardRoute = this.clientTokenService.isLogisticsTenant()
      ? [AppConfig.routes.logistics, LogisticsConfig.routes.dashboard]
      : [AppConfig.routes.dashboard];

    this.router.navigate(dashboardRoute);
  }

  public getDefaultDashboardRoute(): string {
    return !this.clientTokenService.isTokenExpired() && this.clientTokenService.isLogisticsTenant()
      ? `${AppConfig.routes.logistics}/${LogisticsConfig.routes.dashboard}`
      : AppConfig.routes.dashboard;
  }
}
