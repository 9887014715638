<form [formGroup]="formGroup">
  <app-form-field
    [formGroup]="formGroup"
    label="Status"
    type="select"
    [config]="{ selectOptions: filteredLoadStatuses }"
    formControlName="status"
    placeholder="Select a status"
    labelClass="md:col-4 sm:col-12 p-2"
    controlClass="md:col-8 sm:col-12 p-1 px-0"
  ></app-form-field>
</form>
