<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<form class="grid w-full sm:w-10 md:w-8 xl:w-5 m-auto" [formGroup]="setNewPasswordForm">
  <div class="col-12 pb-4">
    <h1>Set New Password</h1>
  </div>

  <app-password-confirmation class="col-12" [formGroup]="setNewPasswordForm"></app-password-confirmation>

  <div class="col-12">
    <div class="setnewpassbuttons">
      <button
        pButton
        class="w-full"
        type="submit"
        label="Update Password"
        icon="fa fa-check"
        (click)="setNewPassword()"
        id="set-password-button"
        [disabled]="!(isFormValid$ | async) || showProgressOverlay"
      ></button>
    </div>
  </div>
</form>
