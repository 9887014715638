<form [formGroup]="formGroup">
  <div class="grid grid-nogutter col-12 p-0">
    <div class="md:col-4 sm:col-12 p-2 font-bold">Rate</div>
    <div *ngIf="calcRateType === rateType.LineHaul" class="md:col-8 sm:col-12 p-1">
      <div class="grid grid-nogutter">
        <app-currency class="col-10" [formControlName]="loadRateProperties.Rate" [alignTextRight]="true"></app-currency>
      </div>
    </div>

    <div
      class="md:col-8 sm:col-12 p-1"
      *ngIf="calcRateType === rateType.RateXUnits || calcRateType === rateType.RateXMiles"
    >
      <div class="grid grid-nogutter col-10 p-0 flex flex-wrap justify-content-between">
        <div class="col-8 p-0">
          <div class="grid grid-nogutter col-12 p-0">
            <div class="col-5 p-0">
              <app-currency [formControlName]="loadRateProperties.Rate" [alignTextRight]="true"></app-currency>
            </div>

            <div class="col-1 px-3 flex flex-wrap justify-content-center align-items-center">x</div>

            <div class="col-4 p-0">
              <input
                *ngIf="calcRateType === rateType.RateXUnits"
                class="w-full"
                type="text"
                pKeyFilter="num"
                pInputText
                placeholder="Units"
                [formGroup]="formGroup"
                [formControlName]="loadRateProperties.RateUnits"
              />
              <input
                *ngIf="calcRateType === rateType.RateXMiles"
                class="w-full"
                type="text"
                pKeyFilter="num"
                pInputText
                placeholder="Miles"
                [formControl]="totalMiles"
                [value]="totalMiles.value"
              />
            </div>
          </div>
        </div>
        <div class="col-4 p-0">
          <app-currency
            class="w-auto"
            [disabled]="true"
            [value]="getCalculatedRate()"
            [alignTextRight]="true"
            [showCurrency]="false"
          ></app-currency>
        </div>
      </div>
    </div>
  </div>

  <div class="grid grid-nogutter col-12 p-0">
    <div class="md:col-4 sm:col-12 p-2 font-bold">Fuel Surcharge</div>
    <div class="md:col-8 sm:col-12 p-1">
      <div class="grid grid-nogutter col-12 p-0">
        <div class="col-10 px-0">
          <div
            *ngIf="formGroup.controls[loadRateProperties.ShowFuelSurchargeAsPercentage].value"
            class="grid grid-nogutter col-12 p-0"
          >
            <div class="col-5 p-0">
              <div class="col-12 p-0 ui-inputgroup">
                <input
                  class="col-8"
                  type="text"
                  pKeyFilter="num"
                  pInputText
                  [formGroup]="formGroup"
                  [formControlName]="loadRateProperties.FuelSurchargePercent"
                />
                <div class="col-3 ui-inputgroup-addon">%</div>
              </div>
            </div>
            <div class="col-7 p-0">
              <app-currency
                [disabled]="true"
                [formControlName]="loadRateProperties.FuelSurcharge"
                [alignTextRight]="true"
                [showCurrency]="false"
              ></app-currency>
            </div>
          </div>

          <div
            *ngIf="!formGroup.controls[loadRateProperties.ShowFuelSurchargeAsPercentage].value"
            class="grid grid-nogutter col-12 p-0 flex flex-wrap justify-content-between"
          >
            <div class="col-8 p-0">
              <div class="grid grid-nogutter col-12 p-0">
                <div class="col-5 p-0">
                  <app-currency
                    [formControlName]="loadRateProperties.FuelSurchargeAmount"
                    [alignTextRight]="true"
                  ></app-currency>
                </div>

                <div class="col-1 px-3 flex flex-wrap justify-content-center align-items-center">x</div>

                <div class="col-4 p-0">
                  <input
                    class="col-12"
                    type="text"
                    pKeyFilter="num"
                    pInputText
                    placeholder="{{ calcRateType === rateType.RateXUnits ? 'Units' : 'Miles' }}"
                    [formGroup]="formGroup"
                    [formControlName]="loadRateProperties.FuelSurchargeUnits"
                  />
                </div>
              </div>
            </div>
            <div class="col-4 p-0">
              <app-currency
                [disabled]="true"
                [formControlName]="loadRateProperties.FuelSurcharge"
                [alignTextRight]="true"
                [showCurrency]="false"
              ></app-currency>
            </div>
          </div>
        </div>

        <div class="col-2 p-0 flex flex-wrap justify-content-end align-items-center">
          <p-toggleButton
            [formControlName]="loadRateProperties.ShowFuelSurchargeAsPercentage"
            class="w-8"
            onLabel=""
            offLabel=""
            onIcon="fa fa-percentage"
            offIcon="fa fa-percentage"
            (onChange)="switchFuelCalcType()"
            [style]="{ width: '100%' }"
          >
          </p-toggleButton>
        </div>
      </div>
    </div>
  </div>

  <app-tallybox
    [formGroup]="formGroup"
    tallyType="Other Charges"
    labelClass="md:col-4 sm:col-12 p-2"
    controlClass="md:col-8 sm:col-12 p-1"
    [tallyItems]="formGroup.controls[loadRateProperties.OtherCharges].value"
    [tallyTotalAmount]="formGroup.controls[loadRateProperties.OtherChargesTotal].value"
    (otherChargesChanged)="otherChargesChanged($event)"
    (tallyTotalAmountChanged)="otherChargesTotalAmountChanged($event)"
    [readonly]="isReadOnly"
  >
  </app-tallybox>

  <app-tallybox
    [formGroup]="formGroup"
    tallyType="Advances"
    labelClass="md:col-4 sm:col-12 p-2"
    controlClass="md:col-8 sm:col-12 p-1"
    [tallyItems]="formGroup.controls[loadRateProperties.Advances].value"
    [tallyTotalAmount]="formGroup.controls[loadRateProperties.AdvancesTotal].value"
    (otherChargesChanged)="advanceChargesChanged($event)"
    (tallyTotalAmountChanged)="advanceChargesTotalAmountChanged($event)"
    [readonly]="isReadOnly"
  >
  </app-tallybox>

  <app-currency
    label="Total Rate"
    [disabled]="true"
    [formControlName]="loadRateProperties.TotalRate"
    [alignTextRight]="true"
    labelClass="col-4 p-2"
    controlClass="col-10 p-1"
    inputClass="col-8 p-0"
  ></app-currency>
</form>
