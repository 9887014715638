export const LogisticsConfig = {
  routes: {
    dashboard: 'dashboard',
    carriers: {
      carriers: 'carriers',
      create: 'create',
    },
    loads: {
      loads: 'loads',
      create: 'create',
    },
  },
} as const;
