<p-dialog
  header="{{ dialogHeaderText }}"
  [(visible)]="showDialog"
  [modal]="true"
  [style]="{ width: '25vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [focusOnShow]="false"
>
  <div *ngIf="showDialog && actionType === PlatformNotificationActionType.Delete">
    <div> Please confirm that you want to delete notification {{ notificationId }}. </div>
    <div class="pt-3"> This action cannot be undone. </div>
  </div>

  <p-footer>
    <button
      id="cancel-button"
      type="button"
      pButton
      label="Cancel"
      class="ui-button-secondary"
      (click)="cancel.emit()"
    ></button>
    <button
      id="action-button"
      type="button"
      pButton
      icon="{{ dialogButtonIcon }}"
      label="{{ dialogButtonText }}"
      (click)="handleAction()"
    ></button>
  </p-footer>
</p-dialog>
