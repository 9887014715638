<div class="grid grid-nogutter col-12 p-2">
  <div class="{{ labelClass }}" [class.font-bold]="editMode == true">
    {{ label }}
    <ng-container *ngIf="description">
      <br />
      <span class="label-secondary">{{ description }}</span>
    </ng-container>
  </div>
  <div class="{{ bodyClass }}" [class.font-bold]="editMode != true" data-test="row-value">
    <ng-content></ng-content>
  </div>
</div>
