import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { Utils } from '../../../../utils/utils';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[pfs-table-body]',
  templateUrl: './pfs-table-body.component.html',
  styleUrls: ['./pfs-table-body.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PfsTableBodyComponent implements OnInit {
  @Input() columns: any[];
  @Input() row: any;
  @Input() isResizable = false;
  alertText: string = '';
  buyIconPath = '../../../../assets/images/factoring/buy.png';
  noBuyIconPath = '../../../../assets/images/factoring/nobuy.png';

  @Output() oneAction: EventEmitter<any> = new EventEmitter();

  cache: {
    cellFormat: {};
    cellClass: {};
  } = {
    cellClass: {},
    cellFormat: {},
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    for (const col of this.columns) {
      this.cache.cellFormat[col.field] = this.computeCellFormat(col, this.row);
      this.cache.cellClass[col.field] = this.computeCellClass(col, this.row);
    }
  }

  updateValues() {
    this.cdr.detectChanges();
  }

  actionHandler(row, actionConfig) {
    actionConfig.paramsParsed = {};
    Object.keys(actionConfig.params).forEach(function (key, index) {
      if (actionConfig.params[key]) {
        if (actionConfig.params[key] === 'row') {
          actionConfig.paramsParsed[key] = row;
        } else {
          switch (typeof actionConfig.params[key]) {
            case 'number':
            case 'function':
              break;
            default:
              if (actionConfig.params[key].startsWith('row.')) {
                const field = actionConfig.params[key].slice(4);
                actionConfig.paramsParsed[key] = row[field] ? row[field] : null;
              }
              break;
          }
        }
      }
    });

    actionConfig.paramsParsed.callBack = () => {
      this.cdr.detectChanges();
    };
    this.oneAction.emit(actionConfig);
  }

  showButton(actionButton, row) {
    if (!actionButton.isTenant) {
      if (actionButton.params?.conditionallyHideAction !== undefined) {
        return !actionButton.params.conditionallyHideAction(actionButton.func, row);
      }
    } else {
      if (row.isDeleted && actionButton.func === 'deleteTenant') {
        return false;
      }
    }
    return true;
  }

  showTooltip(actionButtton, row) {
    if (actionButtton.tooltip !== null) {
      return actionButtton.tooltip;
    }
    return '';
  }

  iconClass(actionButton, row) {
    actionButton.toggle = actionButton.toggle || false;
    if (actionButton.toggle === false) {
      return actionButton.icon;
    }
    if (actionButton.isTenant) {
      return actionButton.icon[row.status !== 'Inactive' ? 0 : 1];
    }
    return actionButton.icon[row.isDisabled ? 0 : 1];
  }
  iconColor(actionButton, row) {
    if (actionButton.iconColor) {
      if (actionButton.isTenant) {
        return actionButton.iconColor[row.status !== 'Inactive' ? 1 : 0];
      }
      return actionButton.iconColor[row.isDisabled ? 0 : 1];
    }
    return '';
  }

  fieldValue(obj, prop) {
    let value;

    if (prop.indexOf('+') > -1) {
      value = '';
      prop.split('+').forEach((field) => {
        value += ' ';
        value += field.split('.').reduce((r, val) => (r ? r[val] : undefined), obj);
      });
    } else {
      value = prop.split('.').reduce((r, val) => (r ? r[val] : undefined), obj);
    }

    if (!value && typeof value !== typeof true && !(value === 0)) {
      return 'TBD';
    }

    if (Array.isArray(value) && value.length === 0) {
      return 'TBD';
    }

    return value;
  }

  fieldValueWithNulls(obj, prop) {
    const value = this.fieldValue(obj, prop);

    return value === 'TBD' ? null : value;
  }

  cellClick(column, row) {
    if (column.action) {
      this.actionHandler(row, column.action);
    }
  }

  computeCellClass(column, row) {
    const fieldValue = this.fieldValue(row, column.field);

    let classes = '';

    if (column.format === 'actionCell' || column.format === 'actionCellLoad') {
      classes += 'cell-action ';
    }

    if (column.format === 'LockedOut' && fieldValue) {
      classes += 'cell-tbd ';
    }

    const value = this.cellFormat(column, row);
    if (value) {
      if (value.toString().startsWith('TBD')) {
        classes += 'cell-tbd ';
      }
    }

    if (column.format === 'cssCell') {
      for (const format of column.formatByValue) {
        if (row[format.field] === format.value) {
          classes += format.class;
        }
      }
    }

    if (column.format === 'limitText') {
      classes += 'limit-text ';
    }

    return classes;
  }

  cellClass(column, row): string {
    return this.cache.cellClass[column.field];
  }

  computeCellFormat(column, row) {
    const fieldValue = this.fieldValue(row, column.field);
    let tbdValue = 'TBD';
    if (column.noTbd) {
      tbdValue = ' ';
    }
    if (!fieldValue && typeof fieldValue !== typeof true && !(fieldValue === 0)) {
      return tbdValue;
    }

    if (column.format === 'raw') {
      return fieldValue;
    }

    if (column.format === 'mailto') {
      if (!fieldValue) {
        return '';
      }
      if (fieldValue.indexOf('@') > -1) {
        return `<a href="mailto:${fieldValue}">${fieldValue}</a>`;
      } else {
        return fieldValue;
      }
    }

    if (column.format === 'BooleanYesNo') {
      return fieldValue === true ? 'Yes' : 'No';
    }

    if (column.format === 'LockedOut') {
      return fieldValue === true ? 'Locked Out' : 'No';
    }

    if (column.format === 'BooleanNoYes') {
      return fieldValue === true ? 'No' : 'Yes';
    }

    if (column.format === 'BooleanEnabledDisabled') {
      return fieldValue === true ? 'Enabled' : 'Disabled';
    }

    if (column.format === 'BooleanDisabledEnabled') {
      return fieldValue === true ? 'Disabled' : 'Enabled';
    }

    if (column.format === 'ArrayJoin' && fieldValue && Array.isArray(fieldValue)) {
      return fieldValue.join(', ');
    }

    if (column.format === 'Date' || column.format === 'dateWithAttachmentIcon') {
      if (fieldValue === '0001-01-01T00:00:00' || fieldValue === 'TBD') {
        return tbdValue;
      }
      return moment(fieldValue).format('MM/DD/YY');
    }

    if (column.format === 'DateLocale') {
      if (fieldValue === '0001-01-01T00:00:00' || fieldValue === tbdValue) {
        return tbdValue;
      }
      return moment.utc(fieldValue).utcOffset(moment().utcOffset()).format('LL HH:mm');
    }

    if (column.format === 'AddressOneLine') {
      let address = fieldValue.line1 ? fieldValue.line1 : '';
      address += fieldValue.line2 ? ', ' + fieldValue.line2 : '';
      address += fieldValue.city ? ', ' + fieldValue.city : '';
      address += fieldValue.state ? ', ' + fieldValue.state : '';
      address += fieldValue.zip ? ', ' + fieldValue.zip : '';

      if (!address || address === '') {
        address = tbdValue;
      }
      return address;
    }

    if (column.format === 'UserName') {
      return fieldValue.name ? fieldValue.name : tbdValue;
    }

    if (column.format === 'LocationName') {
      if (fieldValue.name) {
        if (fieldValue.name.indexOf('-') > -1) {
          const locNameArr = fieldValue.name.split('-');
          if (locNameArr.length > 2) {
            return locNameArr.splice(0, locNameArr.length - 1).join('-');
          } else {
            return locNameArr.join('-');
          }
        } else {
          return fieldValue.name;
        }
      } else {
        return tbdValue;
      }
    }

    if (column.format === 'PhoneJoin') {
      return Utils.formatPhoneJoin(fieldValue);
    }

    if (column.format === 'checkbox') {
      return '<input />';
    }
    if (column.format === 'conditionalCheckbox') {
      return '<input />';
    }
    if (column.format === 'Tracking') {
      if (fieldValue !== 'TBD') {
        return moment(fieldValue).format('MM/DD/YY');
      }
    }
    // default / raw
    return fieldValue;
  }

  cellFormat(column, row) {
    return this.cache.cellFormat[column.field];
  }

  needsAlert(row) {
    const alert = this.fieldValue(row, 'alerts');
    if (alert !== 'TBD') {
      this.alertText = alert.join('<br/>');
      return true;
    }
    return false;
  }

  getLegstatus(row) {
    const fieldValue = this.fieldValue(row, 'lastTrackingUpdatedUtc');
    let onTime = '';
    if (fieldValue !== 'TBD' && fieldValue !== ' ') {
      onTime = this.fieldValue(row, 'lastTrackingStatus');
    }
    return onTime;
  }

  getLegStatusClass(row) {
    const fieldValue = this.fieldValue(row, 'lastTrackingUpdatedUtc');
    let onTime = '';
    if (fieldValue !== 'TBD' && fieldValue !== ' ') {
      onTime = this.fieldValue(row, 'lastTrackingStatus');
    }
    if (onTime === 'OnTime') {
      return 'greenclock';
    } else {
      if (onTime === 'Delayed') {
        return 'redclock';
      }
    }
    return 'grayclock';
  }
}
