<div class="notification">
  <div class="icon-box"><i class="icon text-2xl {{ notification.platformNotificationType?.icon }}"></i></div>
  <div class="header text-lg">{{ notification.title }}</div>
  <div
    #notificationBody
    class="line-height-3 text-base notification-body"
    [class.truncated-notification]="!showTruncatedNotification"
    [innerHTML]="notification.text"
  >
  </div>
  <div
    *ngIf="isElementTruncated(notificationBody) || showTruncatedNotification"
    (click)="toggleShowTruncatedNotification()"
    class="expand-button line-height-3 py-1 m-0"
  >
    {{ showTruncatedNotification ? 'Collapse' : 'Expand' }}
  </div>
  <div class="text-base line-height-2">{{ notification.startDate | date : 'M/d/yyyy @ h:mma' }}</div>
</div>
