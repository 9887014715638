import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateFromDBString',
})
@Injectable({
  providedIn: 'root',
})
export class FormatDateFromDBString implements PipeTransform {
  transform(value: any, format?: any): any {
    if (!value || value === '') {
      return value;
    }
    return new Date(value);
  }
}
