import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ReportSummaryDTO } from 'src/apiclient/v1.1/models';
import { LoggingService } from 'src/app/core/logging.service';

@Component({
  selector: 'app-dashboard-bar-graph',
  templateUrl: './dashboard-bar-graph.component.html',
  styleUrls: ['./dashboard-bar-graph.component.scss'],
})
export class DashboardBarGraphComponent implements OnInit {
  @Input() header: string;
  @Input() summaryData$: Observable<ReportSummaryDTO>;
  @Input() isCurrency = false;
  @Input() tooltip: string;

  public chartData$: Observable<ChartData>;
  public options: ChartOptions;
  public isError = false;
  public readonly labels = this.getLabels();
  private readonly refreshStream$ = new BehaviorSubject<void>(undefined);

  constructor(private loggingService: LoggingService) {}

  public ngOnInit(): void {
    this.chartData$ = this.getChartData();
    this.options = this.getOptions();
  }

  public refresh(): void {
    this.refreshStream$.next();
  }

  private getChartData(): Observable<ChartData> {
    return this.refreshStream$.asObservable().pipe(
      tap(() => (this.isError = false)),
      switchMap(() =>
        this.summaryData$.pipe(
          map((s) => this.getData(s)),
          catchError((error) => {
            this.isError = true;
            this.loggingService.logError(error);
            return of(undefined);
          })
        )
      )
    );
  }

  private generateTooltipLabel(tooltipItem: ChartTooltipItem): string {
    return this.formatLabel(tooltipItem.yLabel, false);
  }

  private getFormatter(isCompactForm: boolean): Intl.NumberFormat {
    const currencyOptions: Intl.NumberFormatOptions = this.isCurrency
      ? { style: 'currency', currency: 'USD' }
      : undefined;
    const notation = isCompactForm ? 'compact' : 'standard';
    // Using `any` because TypeScript versions below 4.x identifies `notation` as an unknown property even though it is valid in ES2020.
    const options: Intl.NumberFormatOptions = { notation, ...currencyOptions } as Intl.NumberFormatOptions as any;
    return Intl.NumberFormat('en', options);
  }

  private formatLabel(value: number | string, isCompactForm: boolean): string {
    const formatter = this.getFormatter(isCompactForm);

    if (typeof value === 'number') {
      return formatter.format(value);
    }

    return value;
  }

  private formatAxesTick(value: number | string): string {
    return this.formatLabel(value, true);
  }

  private getLabels(): { label: string; color: string }[] {
    return [
      {
        label: 'Average',
        color: '#9371f0',
      },
      {
        label: 'Month To Date',
        color: '#0099ff',
      },
      {
        label: 'Monthly Prediction',
        color: '#39dbff',
      },
    ];
  }

  private getData(summaryData: ReportSummaryDTO): ChartData {
    return {
      labels: this.labels.map((l) => l.label),
      datasets: [
        {
          data: [summaryData.average, summaryData.month, summaryData.monthPrediction],
          backgroundColor: this.labels.map((l) => l.color),
        },
      ],
    };
  }

  private getOptions(): ChartOptions {
    return {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: false,
            gridLines: {
              offsetGridLines: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 3,
              callback: this.formatAxesTick.bind(this),
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: this.generateTooltipLabel.bind(this),
        },
        displayColors: false,
        titleFontSize: 0,
      },
    };
  }
}
