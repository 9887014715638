import { Injectable } from '@angular/core';
import { StatusFilter } from 'src/app/shared/components/status-filter/models/status-filter';
import { LoadStatusName } from 'src/app/data/static-data';

@Injectable({
  providedIn: 'root',
})
export class StatusFiltersService {
  public getLoadStatusFilters(isLogistics?: boolean): StatusFilter[] {
    return isLogistics ? this.getStatusesForLogistics() : this.getAllStatuses();
  }

  private getAllStatuses(): StatusFilter[] {
    return [
      {
        label: 'Active',
        value: null,
        active: true,
      },
      {
        label: 'Open',
        value: LoadStatusName.Open,
        active: false,
      },
      {
        label: 'Assigned',
        value: LoadStatusName.Assigned,
        active: false,
      },
      {
        label: 'Dispatched',
        value: LoadStatusName.Dispatched,
        active: false,
      },
      {
        label: 'In Transit',
        value: LoadStatusName.InTransit,
        active: false,
      },
      {
        label: 'In Yard',
        value: LoadStatusName.InYard,
        active: false,
      },
      {
        label: 'Delivered',
        value: LoadStatusName.Delivered,
        active: false,
      },
      {
        label: 'Needing Update',
        value: LoadStatusName.NeedingAttention,
        active: false,
      },
      {
        label: 'Deleted',
        value: LoadStatusName.Deleted,
        active: false,
      },
    ];
  }

  private getStatusesForLogistics(): StatusFilter[] {
    const statuses = this.getAllStatuses();
    const statusesToRemove = [LoadStatusName.NeedingAttention];
    statusesToRemove.forEach((status) => {
      const index = statuses.findIndex((s) => s.value === status);
      if (index > -1) {
        statuses.splice(index, 1);
      }
    });

    return statuses;
  }
}
