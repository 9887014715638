import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { of } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { FundingStatus } from '../funding-status-icon/funding-status';

@Component({
  selector: 'app-load-status',
  templateUrl: './load-status.component.html',
  styleUrls: ['./load-status.component.scss'],
})
export class LoadStatusComponent {
  @ViewChild('overlay') overlay: OverlayPanel;

  @Input() status: string;

  private overlayVisible: boolean;

  constructor(private ref: ChangeDetectorRef) {}

  public displayOverlay(): boolean {
    switch (this.status) {
      case FundingStatus.Queued:
      case FundingStatus.Submitted:
      case FundingStatus.Funded:
      case FundingStatus.OnHold:
        return true;
      default:
        return false;
    }
  }

  public showOverlay(event: any): void {
    this.overlay.show(event);
    this.overlayVisible = true;
  }

  public hideOverlayWithDelay(): void {
    this.overlayVisible = false;

    of({})
      .pipe(
        delay(100),
        filter(() => this.overlayVisible !== true),
        tap(() => {
          this.hideOverlay();
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }

  public hideOverlay(): void {
    this.overlay.hide();
    this.overlayVisible = false;
  }
}
