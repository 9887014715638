import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { JwtDecodeService } from './jwt-decode/jwt-decode.service';
import { AppConfig } from 'src/app/config/app.config';
import { AuthenticationService } from 'src/apiclient/v1.1/services';
import { IdNamePairDTO, TokenDTO, TokenResponseDTO } from 'src/apiclient/v1.1/models';
import { Role, TenantRole } from 'src/app/data/static-data';
import { TenantType } from 'src/app/data/tenant-type';
import { UserClaims } from 'src/app/shared/models/user-claims';

/**
 * Provides common utilities for interacting with the Authentication token
 */
@Injectable()
export abstract class ClientTokenService {
  protected token: TokenDTO;
  protected claims: UserClaims;

  protected loggedIn = new BehaviorSubject(false);
  protected timerSubscription: Subscription;
  private timer: Observable<number>;
  public timeoutExpired: Subject<number> = new Subject<number>();
  private _count: number = 0;
  protected tenantFactoringEnabled = new BehaviorSubject(false);

  constructor(
    @Inject(LOCAL_STORAGE) protected storage: StorageService,
    protected router: Router,
    protected location: Location,
    protected jwtDecodeService: JwtDecodeService,
    protected authenticationService: AuthenticationService
  ) {
    this.token = this.getToken();
  }

  public abstract getToken(): TokenDTO | TokenResponseDTO;

  public abstract setToken(token: TokenDTO | TokenResponseDTO): void;

  public isFeatureFlagEnabled(feature: string): boolean {
    return this.claims?.featureFlags?.includes(feature);
  }

  protected abstract decodeToken(token: TokenDTO | TokenResponseDTO): void;

  public abstract revokeToken(): void;

  protected isPathValidForUnAuthorizedUsers(path: string): boolean {
    const unAuthorizedPaths = [
      AppConfig.routes.registration,
      AppConfig.routes.userRegistration,
      AppConfig.routes.privacyPolicy,
      AppConfig.routes.login.signUp,
      AppConfig.routes.login.forgotPassword,
      AppConfig.routes.login.forgotPasswordWithUser,
      AppConfig.routes.login.confirmForgotPassword,
      AppConfig.routes.login.confirmForgotPasswordWithUser,
      AppConfig.routes.login.verifyEmail,
      AppConfig.routes.login.setNewPassword,
    ];

    return unAuthorizedPaths.some((p) => path.includes(p));
  }

  /**
   * Check if token is expired.
   * If so, automatically revokes/deletes it
   */
  public isTokenExpired(): boolean {
    const exp = this.claims?.exp ?? 0;
    const isExpired = Date.now() >= exp * 1000;
    if (isExpired) {
      this.revokeToken();
    }
    return isExpired;
  }

  public isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  public getClaims(): UserClaims {
    this.isTokenExpired();
    return this.claims;
  }

  /**
   * @deprecated The method should not be used, use has permission instead
   */
  public roles(): string[] {
    return this.getClaims()?.roles ?? [];
  }

  public permissions(): string[] {
    return this.getClaims()?.permissions ?? [];
  }

  /**
   * @deprecated The method should not be used, use has permission instead
   */
  public hasRole(role: string): boolean {
    return this.roles().includes(role);
  }

  public isImpersonating(): boolean {
    return this.getClaims()?.impersonating ?? false;
  }

  public hasPermission(permission: string): boolean {
    const permissions = this.permissions();
    return permissions && permissions.includes(permission);
  }

  public tenantId(): number {
    return this.getClaims()?.tenantId;
  }

  public userId(): number {
    return this.getClaims()?.userId;
  }

  public abstract auth(): string;

  public abstract logOut(): void;

  protected checkAllowedRoles(): boolean {
    // LogisticsTenant role is applied to logistics users. We should ignore that for this test
    const notAllowedRoles = [Role.Driver.toString(), TenantRole.LogisticsTenant.toString()];
    const validRoles = this.roles().filter((role) => notAllowedRoles.indexOf(role) === -1);
    return validRoles.length > 0;
  }

  public abstract getTenants(): TokenDTO[] | IdNamePairDTO[];

  public abstract getRefreshToken(): string;

  public startTimer(n: number) {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    const fiveMinutesTilSessionEnds = (n - Date.now() / 1000 - 300) * 1000;

    // prevent dialog from showing when opening the app with an expired token (negative timestamp) from previous session
    if (fiveMinutesTilSessionEnds > 0) {
      this.timer = timer(fiveMinutesTilSessionEnds);
      this.timerSubscription = this.timer.subscribe(() => {
        this.timerComplete();
      });
    }
  }

  private timerComplete() {
    this.timeoutExpired.next(++this._count);
  }

  public isFactoringEnabledForTenant(): Observable<boolean> {
    return this.tenantFactoringEnabled.asObservable();
  }

  public isLogisticsTenant(): boolean {
    return this.hasRole(TenantRole.LogisticsTenant);
  }

  public isTenantType(tenantType: TenantType): boolean {
    switch (tenantType) {
      case TenantType.Logistics:
        return this.isLogisticsTenant();
      case TenantType.Carrier:
        return !this.isLogisticsTenant();
      default:
        return false;
    }
  }

  public getTenantType(): TenantType {
    return this.isLogisticsTenant() ? TenantType.Logistics : TenantType.Carrier;
  }
}
