<span class="filter" (click)="filterPanel.toggle($event)"
  >{{ getFormattedFilterLabel() }}<i class="fa fa-caret-down"></i>
</span>
<p-overlayPanel #filterPanel [style]="{ width: '34.375rem' }" appendTo="body">
  <form [formGroup]="formGroup" class="grid m-2">
    <div class="col-6">
      <div class="font-bold pb-1">Filter By</div>
      <p-dropdown
        [style]="{ width: '100%' }"
        [options]="filterByOptions"
        [formControlName]="loadFilterFormProperties.FilterBy"
        placeholder="Select"
      ></p-dropdown>
    </div>
    <div class="col-6">
      <div class="font-bold pb-1">Contains</div>
      <input type="text" class="col-12" [formControlName]="loadFilterFormProperties.Contains" pInputText />
    </div>
    <div class="col-6">
      <div class="font-bold pb-1">Work Order</div>
      <input type="text" class="col-12" [formControlName]="loadFilterFormProperties.WorkOrder" pInputText />
    </div>
    <div class="col-6">
      <div class="font-bold pb-1">PO Number</div>
      <input type="text" class="col-12" [formControlName]="loadFilterFormProperties.PONumber" pInputText />
    </div>
    <div class="col-6">
      <div class="font-bold pb-1">Shipping Date</div>
      <app-filter-date-selector [formControlName]="loadFilterFormProperties.ShippingDate"></app-filter-date-selector>
    </div>
    <div class="col-6">
      <div class="font-bold pb-1">Delivery Date</div>
      <app-filter-date-selector [formControlName]="loadFilterFormProperties.DeliveryDate"></app-filter-date-selector>
    </div>
    <div class="col-12 px-2 flex justify-content-between align-items-center">
      <div>
        <div *ngIf="logistics" class="flex align-items-center">
          <p-checkbox label="Hot Only" [formControlName]="loadFilterFormProperties.Hot" binary="true"></p-checkbox>
          <i
            class="fas fa-question-circle pl-2 tooltip"
            pTooltip="Filters records to display only those loads that have been marked as Hot"
            tooltipPosition="right"
          ></i>
        </div>
      </div>
      <div>
        <span class="link-btn mgn3" (click)="clear()">Clear</span>
        <button pButton type="button" (click)="filter()" label="Apply"></button>
      </div>
    </div>
  </form>
</p-overlayPanel>
