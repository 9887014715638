<div class="grid grid-nogutter" [(formGroup)]="formGroup">
  <div class="col-6 pl-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      formControlName="firstName"
      [formErrors]="formErrors"
      placeholder="First name"
      labelClass="nolabel"
      controlClass="col-12 pl-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
    >
    </app-form-field
  ></div>

  <div class="col-6 pr-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      formControlName="lastName"
      [formErrors]="formErrors"
      placeholder="Last name"
      labelClass="nolabel"
      controlClass="col-12 pr-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
    >
    </app-form-field
  ></div>

  <div class="col-7 p-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      formControlName="phoneNumber"
      [formErrors]="formErrors"
      placeholder="Phone"
      labelClass="nolabel"
      controlClass="col-12 pl-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
      keyFilter="number"
    >
    </app-form-field
  ></div>

  <div class="col-5 p-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      formControlName="phoneExt"
      [formErrors]="formErrors"
      placeholder="Ext."
      labelClass="nolabel"
      controlClass="col-12 pr-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
      keyFilter="number"
    >
    </app-form-field>
  </div>

  <div class="col-12 p-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      [config]="{ faIcon: 'fa-mobile-alt' }"
      formControlName="mobileNumber"
      [formErrors]="formErrors"
      placeholder="Mobile"
      labelClass="nolabel"
      controlClass="col-12 px-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
      keyFilter="number"
    >
    </app-form-field>
  </div>

  <div class="col-12 p-0">
    <app-form-field
      [(formGroup)]="formGroup"
      label=""
      [tooltip]="!enabledEmailAddress ? emailAddressDisabledTooltip : ''"
      formControlName="email"
      [formErrors]="formErrors"
      placeholder="Email"
      labelClass="nolabel"
      controlClass="col-12 px-0 {{ this.needsHighlight ? 'highlighted' : '' }}"
    >
    </app-form-field>
  </div>
</div>
