import {
  Component,
  OnInit,
  forwardRef,
  EventEmitter,
  Output,
  Input,
  Injector,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyAddressService } from 'src/apiclient/v1.1/services';
import { IdNamePairDTO } from 'src/apiclient/v1.1/models';
import { FocusTrapService } from 'src/services/focus-trap.service';
import { HandleErrorBase } from 'src/app/shared/HandleErrorBase';
import { DisplayBillToCompanyDTO } from 'src/app/shared/interfaces/DisplayBillToCompanyDTO';
import { CompanyAddressType } from '../company-address-quick-add/company-quick-add-type';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CompanyStatus } from 'src/app/data/static-data';

@Component({
  selector: 'app-company-address-selector-v2',
  templateUrl: './company-address-selector-v2.component.html',
  styleUrls: ['./company-address-selector-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyAddressSelectorV2Component),
      multi: true,
    },
  ],
})
// tslint:disable:one-line
export class CompanyAddressSelectorV2Component
  extends HandleErrorBase
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
  @Input() readonly: boolean = false;
  // tslint:enable:one-line
  @Input() companyAddressType: CompanyAddressType;
  /**
   * When companyAddress changes
   */
  @Output() changed = new EventEmitter<IdNamePairDTO>();

  /**
   * Auto-Complete properties
   */
  selected: IdNamePairDTO = {};
  results: IdNamePairDTO[];

  /**
   * Search value
   */
  searchValue: string;

  disabled: boolean = false;
  dialogVisible = false;
  private destroy$ = new Subject<void>();

  constructor(
    private companyAddressService: CompanyAddressService,
    private focusTrap: FocusTrapService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setMode();
  }

  /**
   * Reset the component if the company changes after a address was selected.
   * Sets the model to null and propagates the change
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyAddressType.currentValue !== changes.companyAddressType.previousValue) {
      this.setMode();
    }
  }

  /**
   * Writes a new companyId to the element.
   * Must query API to get the name.
   */
  writeValue(val: IdNamePairDTO): void {
    this.selected = val;
    this.results = [val];
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  public onSelect(): void {
    if (this.selected !== undefined || this.selected != null) {
      if (this.selected.id === -1) {
        // Add new
        this.selected = {} as IdNamePairDTO;
        this.openDialog();
      } else {
        this.modelChanged();
      }
    }
  }

  public onClear(): void {
    this.selected = {} as IdNamePairDTO;
    this.modelChanged();
  }

  public search(event): void {
    this.searchValue = event.query;
    const term = !event.query ? null : event.query;
    this.searchLocation(term)
      .pipe(
        take(1),
        tap((data) => {
          // -1 is a control id which triggers the add customer dialog
          data.unshift({ id: -1, name: ' + Add New' });
          this.results = data;
        }),
        catchError((error) => this.handleBasicError(error)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public newAddressCreated(company: DisplayBillToCompanyDTO): void {
    const selected: IdNamePairDTO = {
      id: company.companyAddressId,
      name: company.companyDisplayName,
    };
    this.selected = selected;
    this.results = [selected];
    this.modelChanged();
  }

  public dialogClosed(): void {
    this.setDialogVisibility(false);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openDialog(): void {
    this.setDialogVisibility(true);
  }

  private modelChanged(): void {
    this.propagateChange(this.selected);
    this.changed.emit(this.selected);
  }

  private setMode(): void {
    switch (this.companyAddressType) {
      case CompanyAddressType.Shipper:
        this.searchLocation = this.searchShipper;
        break;
      case CompanyAddressType.Consignee:
        this.searchLocation = this.searchConsignee;
        break;
      default:
        throw new Error(`Mode ${this.companyAddressType} is not known`);
    }
  }

  // Assigned based on mode

  private searchLocation = (term: string): Observable<IdNamePairDTO[]> => {
    throw new Error('searchLocation not initialized');
  };

  // Location type-specific implementations

  private searchShipper(term: string): Observable<IdNamePairDTO[]> {
    return this.searchAddresses(term, 'Shipper');
  }

  private searchConsignee(term: string): Observable<IdNamePairDTO[]> {
    return this.searchAddresses(term, 'Consignee');
  }

  private searchAddresses(
    term: string,
    addressType: CompanyAddressService.GetV2Params['AddressType']
  ): Observable<IdNamePairDTO[]> {
    return this.companyAddressService.GetV2({
      Authorization: this.clientToken.auth(),
      Status: CompanyStatus.Active,
      AddressType: addressType,
      Name: term,
    });
  }

  private setDialogVisibility(isVisible: boolean): void {
    this.dialogVisible = isVisible;
    this.focusTrap.onDialog(isVisible);
  }
}
