import { Injectable } from '@angular/core';
import { CompanyAddressDTO } from '../apiclient/models';
import { NotificationsService } from '../app/components/notifications/notifications.service';
import { CustomNotification } from '../app/shared/models/custom-notification';

@Injectable({ providedIn: 'root' })
export class CompanyAddressConflictService {
  constructor(private notificationService: NotificationsService) {}

  public displayConflictingAddresses(addresses: CompanyAddressDTO[]): void {
    const notification: CustomNotification = {
      type: 'error',
      summary: 'Address Conflict',
      detail: 'Your input matched an existing company record. Your current changes were not saved.',
      sticky: true,
    };

    addresses.forEach((address) => {
      this.notificationService.notifyWithLink(notification, `company/edit/${address.companyId}`, address.displayName);
    });
  }
}
