import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverProfileListComponent } from './list/driver-profile-list.component';

const routes: Routes = [{ path: 'backoffice/drivers', component: DriverProfileListComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DriverProfileRoutingModule {}
