import { Component, OnInit, Input } from '@angular/core';
import { ClientTokenService } from '../../../services/client-token-service';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../config/app.config';
import { TokenDTO, IdNamePairDTO } from 'src/apiclient/v1.1/models';
import { AuthenticationService } from 'src/apiclient/v1.1/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  appConfig = AppConfig;
  tenantName: string;
  nickName: string;
  isloggedIn: boolean = false;
  isImpersonating: boolean = false;
  tenants: TokenDTO[] | IdNamePairDTO[];
  visibleTenants: boolean = false;

  @Input() showMenu: boolean;

  constructor(
    public app: AppComponent,
    protected clientToken: ClientTokenService,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.clientToken.isLoggedIn().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.isloggedIn = true;
        const claims = this.clientToken.getClaims();
        this.nickName = claims.fullName;
        this.tenantName = claims.tenantName;
        setTimeout((_) => {
          this.tenants = this.clientToken.getTenants();
        });
        if (claims.impersonating) {
          this.isImpersonating = true;
          this.nickName += ' (Impersonating)';
        } else {
          this.isImpersonating = false;
        }
      } else {
        this.isloggedIn = false;
      }
    });
  }

  public themeChange(e): void {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    const href = themeLink.href;
    const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
    const themeTokens = themeFile.split('-');
    const themeName = themeTokens[1];
    const themeMode = themeTokens[2];
    const newThemeMode = themeMode === 'dark' ? 'light' : 'dark';
    this.app.changeTheme(themeName + '-' + newThemeMode);
  }

  public logOut(): void {
    this.clientToken.logOut();
  }

  public switchToTenant(idNamePair: IdNamePairDTO) {
    const switchTenantRequest: AuthenticationService.SwitchTenantParams = {
      Authorization: this.clientToken.auth(),
      body: {
        tenantId: idNamePair.id,
        refreshToken: this.clientToken.getRefreshToken(),
      },
    };

    this.authenticationService.SwitchTenant(switchTenantRequest).subscribe((response) => {
      this.clientToken.setToken(response);
      this.reloadPage();
    });
  }

  public showTenants($e): void {
    (document.querySelector('.tenant-' + this.clientToken.getToken().tenant.tenantId) as HTMLElement).style.fontWeight =
      'bold';
    $e.preventDefault();
    this.visibleTenants = !this.visibleTenants;
  }

  // Since we don't have a way of cleanly injecting and mocking the Window object, we can spy on and stub this method
  public reloadPage(): void {
    location.reload();
  }
}
