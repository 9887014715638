import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ClientTokenService } from 'src/services/client-token-service';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  constructor(private clientTokenService: ClientTokenService, private navigationService: NavigationService) {}

  public canActivate(): Observable<boolean> {
    return this.clientTokenService.isLoggedIn().pipe(
      tap((isLoggedIn) => this.navigateIfLoggedIn(isLoggedIn)),
      map((isLoggedIn) => !isLoggedIn),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private navigateIfLoggedIn(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.navigationService.navigateToDefaultDashboard();
    }
  }
}
