/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BillingConfigDTO } from '../models/billing-config-dto';
import { BillingStatusDTO } from '../models/billing-status-dto';
import { BillingPortalDTO } from '../models/billing-portal-dto';
import { BillingSessionDTO } from '../models/billing-session-dto';
@Injectable({
  providedIn: 'root',
})
class BillingService extends __BaseService {
  static readonly ApiBillingConfigGetPath = '/api/Billing/Config';
  static readonly ApiBillingStatusGetPath = '/api/Billing/Status';
  static readonly ApiBillingPortalGetPath = '/api/Billing/Portal';
  static readonly ApiBillingSubscribePostPath = '/api/Billing/Subscribe';
  static readonly ApiBillingCheckoutPostPath = '/api/Billing/Checkout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets Stripe information
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingConfigGetResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingConfigDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingConfigDTO>;
      })
    );
  }
  /**
   * Gets Stripe information
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingConfigGet(Authorization: string): __Observable<BillingConfigDTO> {
    return this.ApiBillingConfigGetResponse(Authorization).pipe(
      __map(_r => _r.body as BillingConfigDTO)
    );
  }

  /**
   * Gets tenant account subscription status
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingStatusGetResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingStatusDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingStatusDTO>;
      })
    );
  }
  /**
   * Gets tenant account subscription status
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingStatusGet(Authorization: string): __Observable<BillingStatusDTO> {
    return this.ApiBillingStatusGetResponse(Authorization).pipe(
      __map(_r => _r.body as BillingStatusDTO)
    );
  }

  /**
   * Gets signed link to the Stripe portal
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingPortalGetResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingPortalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Portal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingPortalDTO>;
      })
    );
  }
  /**
   * Gets signed link to the Stripe portal
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingPortalGet(Authorization: string): __Observable<BillingPortalDTO> {
    return this.ApiBillingPortalGetResponse(Authorization).pipe(
      __map(_r => _r.body as BillingPortalDTO)
    );
  }

  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingSubscribePostResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingSessionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Billing/Subscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingSessionDTO>;
      })
    );
  }
  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiBillingSubscribePost(Authorization: string): __Observable<BillingSessionDTO> {
    return this.ApiBillingSubscribePostResponse(Authorization).pipe(
      __map(_r => _r.body as BillingSessionDTO)
    );
  }

  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param params The `BillingService.ApiBillingCheckoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiBillingCheckoutPostResponse(params: BillingService.ApiBillingCheckoutPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Billing/Checkout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param params The `BillingService.ApiBillingCheckoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiBillingCheckoutPost(params: BillingService.ApiBillingCheckoutPostParams): __Observable<null> {
    return this.ApiBillingCheckoutPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BillingService {

  /**
   * Parameters for ApiBillingCheckoutPost
   */
  export interface ApiBillingCheckoutPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: BillingSessionDTO;
  }
}

export { BillingService }
