import { AfterViewInit, Component, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
export class SearchComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string = 'Search';
  public searchControl = new FormControl('');

  private destroy$ = new Subject<void>();
  private onChange: (value: string) => {};

  public ngAfterViewInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => this.onChange(this.searchControl.value)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public writeValue(value: string): void {
    this.searchControl.setValue(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
