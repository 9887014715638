import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RegistrationFields } from '../../registration-fields';
import { WizardNavigationDirection } from '../../wizard-navigation-direction';
import { WizardFormBase } from '../wizard-form-base';
import { AddressV3Validator } from 'src/app/components/address-v3/validator/address-v3.validator';

@Component({
  selector: 'app-physical-address-form',
  templateUrl: './physical-address-form.component.html',
  styleUrls: ['./physical-address-form.component.scss', '../../user-registration-wizard.component.scss'],
})
export class PhysicalAddressFormComponent extends WizardFormBase implements OnInit {
  public sameAsMailing: boolean;

  @Input() mailingForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.initFormGroup(
      this.formBuilder.group({
        [RegistrationFields.PhysicalAddress]: [{}, [Validators.required, AddressV3Validator.required]],
      })
    );

    this.sameAsMailing = this.mailingForm.controls[RegistrationFields.SameAsMailing].value;
    this.conditionallyTransition();
  }

  private conditionallyTransition(): void {
    if (this.sameAsMailing) {
      switch (this.navigationDirection) {
        case WizardNavigationDirection.Next:
          this.setValidators(null);
          this.next();
          break;
        case WizardNavigationDirection.Previous:
          this.previous();
          break;
      }
    } else {
      this.setValidators([Validators.required, AddressV3Validator.required]);
    }
  }

  private setValidators(validators: ValidatorFn[]) {
    this.wizardStep.stepForm.controls[RegistrationFields.PhysicalAddress].setErrors(null);
    this.wizardStep.stepForm.controls[RegistrationFields.PhysicalAddress].setValidators(validators);
    this.wizardStep.stepForm.controls[RegistrationFields.PhysicalAddress].markAsUntouched();
  }
}
