/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccountingService } from './services/accounting.service';
import { AllCompanyService } from './services/all-company.service';
import { AllTenantService } from './services/all-tenant.service';
import { AllUserService } from './services/all-user.service';
import { BillingService } from './services/billing.service';
import { CompanyService } from './services/company.service';
import { CompanyAddressService } from './services/company-address.service';
import { ContactService } from './services/contact.service';
import { DocumentsService } from './services/documents.service';
import { DriverPayService } from './services/driver-pay.service';
import { DriverProfileService } from './services/driver-profile.service';
import { EquipmentService } from './services/equipment.service';
import { HealthService } from './services/health.service';
import { LambdaCallbackService } from './services/lambda-callback.service';
import { LoadService } from './services/load.service';
import { LoadJournalService } from './services/load-journal.service';
import { MapService } from './services/map.service';
import { MarketplaceService } from './services/marketplace.service';
import { RegisterService } from './services/register.service';
import { RoleService } from './services/role.service';
import { SuperAdminService } from './services/super-admin.service';
import { SystemSettingService } from './services/system-setting.service';
import { TenantService } from './services/tenant.service';
import { TenantFactoringService } from './services/tenant-factoring.service';
import { UserService } from './services/user.service';
import { UserFactoringService } from './services/user-factoring.service';
import { ValidationService } from './services/validation.service';
import { WebhookService } from './services/webhook.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccountingService,
    AllCompanyService,
    AllTenantService,
    AllUserService,
    BillingService,
    CompanyService,
    CompanyAddressService,
    ContactService,
    DocumentsService,
    DriverPayService,
    DriverProfileService,
    EquipmentService,
    HealthService,
    LambdaCallbackService,
    LoadService,
    LoadJournalService,
    MapService,
    MarketplaceService,
    RegisterService,
    RoleService,
    SuperAdminService,
    SystemSettingService,
    TenantService,
    TenantFactoringService,
    UserService,
    UserFactoringService,
    ValidationService,
    WebhookService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
