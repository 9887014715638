import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Injector,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { LoadLegDTO, IdNamePairDTO } from '../../../../apiclient/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { FormControl } from '@angular/forms';
import { StaticData, LoadCategory, RateType, LoadStatusName } from '../../../data/static-data';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { CompanyAddressService } from '../../../../apiclient/services';
import { MenuItem } from 'primeng/api';
import { PdfUtilityComponent } from '../../../components/pdf-utility/pdf-utility.component';
import { DocumentComponent } from '../../../components/document/document.component';
import { ScrollManagerService } from '../../../../services/scroll-manager.service';
import { FocusTrapService } from '../../../../services/focus-trap.service';
import { LoadDTO } from '../../../../apiclient/v1.1/models';
import { EquipmentService, LoadService } from '../../../../apiclient/v1.1/services';
import { catchError, filter, map, mergeMap, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadFormFields } from './load-form-fields';
import { TempLoadRateDTO } from 'src/app/shared/load/components/edit/load-rate/models/temp-load-rate-dto';
import { DisplayBillToCompanyDTO } from 'src/app/shared/interfaces/DisplayBillToCompanyDTO';

@Component({
  selector: 'app-load-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class LoadEditComponent extends DtoFormBase<LoadDTO> implements OnInit, OnDestroy {
  @Input() loadId = 0;
  @Input() disabled: boolean = false;
  @Input() dialogVisible: boolean;
  @Output() dialogVisibleChange = new EventEmitter<boolean>();
  @Output() modelEdited = new EventEmitter<{ dataChanged: boolean }>();

  @ViewChild('focusable') focusInput: ElementRef;
  @ViewChild('documentList') documentList: DocumentComponent;
  @ViewChild('pdfutil') pdfUtil: PdfUtilityComponent;

  public dialogFocusTrap: boolean = true;
  public dialogWidth: number;
  public dialogHeight: number;
  public contentDialogStyle: any;
  public isNewRecord: boolean = false;
  public title: string = 'Load';
  public loadCategories: LoadCategory[] = StaticData.loadCategories;
  public calcRateType: RateType;
  public documentMenuItems: MenuItem[];
  public tabIndex: number = 0;
  public isReadOnly: boolean = false;
  public totalRate: number = 0;
  public formSubmitted: boolean = false;
  public equipmentOptions$: Observable<IdNamePairDTO[]>;

  private modelId: number;
  private loadEligibleForInvoicing: boolean = false;
  private billToEmailAddress: string;
  private auditedChangeNeedsSave: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private loadService: LoadService,
    protected injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private companyAddressService: CompanyAddressService,
    private scrollManagerService: ScrollManagerService,
    private focusTrap: FocusTrapService,
    private equipmentService: EquipmentService
  ) {
    super(injector);
    this.model = {} as LoadDTO;
  }

  @HostListener('window:resize') onResize() {
    this.setWindowSize();
  }

  private setWindowSize() {
    this.dialogHeight = window.innerHeight;
    this.dialogWidth = window.innerWidth;
    this.contentDialogStyle = { minHeight: window.innerHeight - 106 + 'px' };
  }

  public ngOnInit(): void {
    if (this.router.url === '/load') {
      this.breadcrumbService.setItems('loads');
    }
    this.initFormFor('LoadDTO');
    this.formGroup.addControl('customerInputDisabled', new FormControl());
    this.formGroup.addControl('totalRate', new FormControl());
    this.formGroup.addControl('loadRate', new FormControl({}));

    this.initFormSubscriptions();

    this.calcRateType = RateType.LineHaul;

    this.focusTrap.focus().subscribe((next) => {
      this.dialogFocusTrap = next;
    });

    this.setEquipmentOptions();
    this.setWindowSize();
    this.init(this.loadId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onOverrideTotalLoadedMilesChange(): void {
    this.conditionallyDisableTotalMilesBasedOnOverride();
    this.conditionallySetTotalMilesFromLegs();
  }

  public onLoadStatusChange($event: string): void {
    this.auditedChangeNeedsSave = $event === LoadStatusName.Audited;
    this.setDocumentMenuItems();
  }

  public onEquipmentChanged($event: IdNamePairDTO): void {
    if ($event.id) {
      this.model.requestedEquipmentId = $event.id;
      this.formGroup.patchValue(this.model);
      this.formErrors.requestedEquipmentId = '';
    }
  }

  public onEquipmentBlur($event: IdNamePairDTO): void {
    if ($event.id) {
      this.formErrors.requestedEquipmentId = '';
    }
  }

  public async onBillToCompanyChange($event: DisplayBillToCompanyDTO): Promise<void> {
    if ($event) {
      this.formErrors.billToCompanyAddress = '';
      const companyAddress = await this.companyAddressService
        .ApiCompanyAddressByIdGet({
          id: $event.companyAddressId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();

      setTimeout((_) => {
        this.model.billToNotesInternal = companyAddress.billToNotesInternal;
        this.model.billToNotesReports = companyAddress.billToNotesReports;
      });
    } else {
      this.model.billToNotesInternal = null;
      this.model.billToNotesReports = null;
    }
  }

  public cancel(): void {
    this.modelEdited.emit({ dataChanged: true });
    this.showProgressOverlay = false;
    this.closeDialog();
  }

  private mergeFormIntoModel(): void {
    this.combineModelAndRateFormProperties();

    this.model.status = this.formGroup.controls.status.value;
    this.model.legs = this.formGroup.controls.legs.value;
  }

  private combineModelAndRateFormProperties(): void {
    const loadRate: TempLoadRateDTO = this.formGroup.controls.loadRate.value;

    this.model.totalMiles = this.formGroup.controls.totalMiles.value;
    this.model.rate = loadRate.rate;
    this.model.rateUnits = loadRate.rateUnits;
    this.model.fuelSurcharge = loadRate.fuelSurcharge;
    this.model.fuelSurchargeAmount = loadRate.fuelSurchargeAmount;
    this.model.fuelSurchargePercent = loadRate.fuelSurchargePercent;
    this.model.fuelSurchargeUnits = loadRate.fuelSurchargeUnits;
    this.model.showFuelSurchargeAsPercentage = loadRate.showFuelSurchargeAsPercentage;
    this.model.total = loadRate.totalRate;
    this.model.advances = loadRate.advances;
    this.model.otherCharges = loadRate.otherCharges;
    this.model.otherChargesTotal = this.sumArrayOfNumbers(loadRate.otherCharges.map((a) => +a.amount));
    this.model.advancesTotal = this.sumArrayOfNumbers(loadRate.advances.map((a) => +a.amount));
  }

  private sumArrayOfNumbers(numbers: number[]): number {
    return numbers.reduce((sum, current) => sum + current, 0);
  }

  public async saveModel(): Promise<boolean> {
    this.showProgressOverlay = true;
    try {
      this.mergeFormIntoModel();

      if (this.isNewRecord) {
        const data = await this.loadService
          .Post({ Authorization: this.clientToken.auth(), body: this.model })
          .toPromise();

        this._NS.success('Load created!', 'A new Load was created successfully.');
        this.modelEdited.emit({ dataChanged: true });
        if (!this.auditedChangeNeedsSave) {
          this.closeDialog();
        }
        this.setReadOnlyFromLoadStatus();
        this.setDocumentMenuItems();

        return true;
      } else {
        const data = await this.loadService
          .Put({ id: this.model.loadId, Authorization: this.clientToken.auth(), body: this.model })
          .toPromise();

        this._NS.success('Load updated!', 'Load was updated successfully.');
        this.modelEdited.emit({ dataChanged: true });
        if (!this.auditedChangeNeedsSave) {
          this.closeDialog();
        }
        this.setReadOnlyFromLoadStatus();
        this.setDocumentMenuItems();
        return true;
      }
    } catch (e) {
      this.formSubmitted = true;
      this.handleError(e);
    } finally {
      this.showProgressOverlay = false;
    }
  }

  public handleTabChange($event): void {
    this.tabIndex = $event.index;

    if ($event.originalEvent.target.innerText === 'Documents') {
      this.documentList.getData();
    }
  }

  public onDialogClosed(event: { viewMode: string }): void {
    this.scrollManagerService.resetDialogScroll();
    if (event.viewMode === 'Invoice') {
      this.formGroup.controls.status.setValue(LoadStatusName.Invoiced);
      this.onLoadStatusChange(LoadStatusName.Invoiced);
    }
  }

  public dispatcherSelected($event: IdNamePairDTO): void {
    if (!$event || !$event.id) {
      this.model.dispatcher = {
        id: null,
        name: null,
      };
    } else {
      this.model.dispatcher.id = $event.id;
      this.model.dispatcher.name = $event.name;
    }
  }

  public async loadInvoiceDoc(): Promise<void> {
    this.canInvoiceLoad().subscribe(async (canInvoice) => {
      if (canInvoice) {
        if (
          !this.billToEmailAddress &&
          this.model.billToCompanyAddress &&
          this.model.billToCompanyAddress.companyAddressId > 0
        ) {
          const billTo = await this.companyAddressService
            .ApiCompanyAddressByIdGet({
              id: this.model.billToCompanyAddress.companyAddressId,
              Authorization: this.clientToken.auth(),
            })
            .toPromise();
          this.billToEmailAddress = billTo.billToContact.email;
        }
        const recipients =
          this.billToEmailAddress && this.billToEmailAddress.length > 0 ? [this.billToEmailAddress] : [];
        this.pdfUtil
          .generateLoadInvoicePDF(this.model.loadId, this.model.loadNumber, recipients)
          .then(() => this.documentList.getData());
      }
    });
  }

  public setEquipmentOptions(): void {
    this.equipmentOptions$ = this.equipmentService.Types(this.clientToken.auth()).pipe(startWith([]));
  }

  private loadDispatchDoc(): void {
    const firstLeg = this.formGroup.controls.legs.value[0];

    this.pdfUtil
      .generateLoadDispatchPDF(this.model.loadId, this.model.loadNumber, firstLeg.driverUser.id)
      .then(() => this.documentList.getData());
  }

  private canInvoiceLoad(): Observable<boolean> {
    return this.loadService
      .ValidateLoadForInvoicing({
        Authorization: this.clientToken.auth(),
        loadId: this.model.loadId,
      })
      .pipe(
        take(1),
        mergeMap(async () => (this.auditedChangeNeedsSave ? this.saveModel() : true)),
        catchError((error) => this.handleInvoiceValidationError(error))
      );
  }

  private handleInvoiceValidationError(error: HttpErrorResponse): Observable<boolean> {
    const noaErrorKey = 'notice Of Assignment';

    let errorMessage = 'Please try again later.';

    if (error.status === 400 && error.error) {
      const problems = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;

      if (problems && problems[noaErrorKey]) {
        const noaErrorMessage = problems[noaErrorKey];
        errorMessage = noaErrorMessage;
      }
    }

    this._NS.error('Unable to Create Invoice', errorMessage);
    return of(false);
  }

  private async getModel(): Promise<void> {
    try {
      const claims = this.clientToken.getClaims();
      if (this.modelId === 0 || !this.modelId) {
        this.isNewRecord = true;
        this.title = 'New Load';
        this.formGroup.controls.status.setValue(LoadStatusName.Open);
        if (this.clientToken.hasRole('Dispatch')) {
          this.model.dispatcher.id = claims.userId;
          this.model.dispatcher.name = claims.fullName;
        }
        this.model.legs.push({
          driverUser: {},
          otherCharges: [],
          advances: [],
        } as LoadLegDTO);
        this.calcRateType = RateType.LineHaul;
        this.model.category = RateType.LineHaul;
        this.model.fuelSurcharge = 0;
        this.model.showFuelSurchargeAsPercentage = false;
        this.model.overrideTotalMiles = false;

        this.formGroup.patchValue(this.model);
        this.initLoadRate(this.model);

        this.openDialog();
        this.conditionallyDisableTotalMilesBasedOnOverride();
      } else {
        const data = await this.GetLoadById(this.disabled);
        setTimeout((_) => {
          setTimeout(() => this.focusInput.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' }));

          this.isNewRecord = false;
          this.model = data;

          if (!this.model.dispatcher) {
            this.model.dispatcher = {} as IdNamePairDTO;
          }
          if (!this.model.dispatcher.id && this.clientToken.hasRole('Dispatch')) {
            this.model.dispatcher.id = claims.userId;
            this.model.dispatcher.name = claims.fullName;
          }

          if (this.model.showFuelSurchargeAsPercentage === null) {
            this.model.showFuelSurchargeAsPercentage = this.model.category === RateType.LineHaul;
          }
          this.calcRateType = RateType[this.model.category];
          this.title = 'Load ' + this.model.loadNumber;
          this.setReadOnlyFromLoadStatus();
          this.setDocumentMenuItems();

          this.formGroup.patchValue(this.model);
          this.initLoadRate(this.model);

          this.openDialog();
          if (!this.isReadOnly) {
            this.conditionallyDisableTotalMilesBasedOnOverride();
          }
        });
      }
    } catch (e) {
      this.scrollManagerService.resetDialogScroll();
      this.handleError(e);
    } finally {
      this.scrollManagerService.resetDialogScroll();
      this.showProgressOverlay = false;
    }
  }

  private initLoadRate(model: LoadDTO): void {
    const loadRate: TempLoadRateDTO = {
      rate: model.rate,
      rateUnits: model.rateUnits,
      fuelSurcharge: model.fuelSurcharge,
      fuelSurchargeAmount: model.fuelSurchargeAmount,
      fuelSurchargePercent: model.fuelSurchargePercent,
      fuelSurchargeUnits: model.fuelSurchargeUnits,
      showFuelSurchargeAsPercentage: model.showFuelSurchargeAsPercentage,
      totalRate: model.total,
      advances: model.advances,
      otherCharges: model.otherCharges,
    };

    this.formGroup.controls.loadRate.setValue(loadRate);
  }

  private initFormSubscriptions(): void {
    this.formGroup.controls.status.valueChanges
      .pipe(
        tap((value) => this.onLoadStatusChange(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.formGroup.controls.legs.valueChanges
      .pipe(
        tap(() => this.conditionallySetTotalMilesFromLegs()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setDocumentMenuItems(): void {
    const loadStatus = this.formGroup.controls.status.value;
    this.loadEligibleForInvoicing = loadStatus === LoadStatusName.Audited;
    const notDispatchedStatuses: string[] = [
      LoadStatusName.Delivered,
      LoadStatusName.Audited,
      LoadStatusName.Invoiced,
      LoadStatusName.Paid,
      LoadStatusName.Claim,
      LoadStatusName.Archive,
    ];
    const loadEligibleforDispatch = !notDispatchedStatuses.includes(loadStatus);
    this.documentMenuItems = [
      {
        label: 'Dispatch Sheet',
        icon: 'fas fa-truck',
        disabled: !loadEligibleforDispatch,
        command: () => {
          this.loadDispatchDoc();
        },
      },
      {
        label: 'Invoice',
        icon: 'fa fa-file-text-o',
        disabled: !this.loadEligibleForInvoicing,
        command: () => {
          this.loadInvoiceDoc();
        },
      },
    ];
  }

  private openDialog(): void {
    // Reset focus trap
    this.scrollManagerService.resetDialogScroll();
    this.focusTrap.onDialog(false);
    this.dialogVisible = true;
    this.dialogVisibleChange.emit(this.dialogVisible);
  }

  private closeDialog(): void {
    this.scrollManagerService.resetDialogScroll();
    this.dialogVisible = false;
    // Timeout used to account for time of close animation.
    setTimeout(() => this.dialogVisibleChange.emit(this.dialogVisible), 100);
  }

  private setReadOnlyFromLoadStatus(): void {
    const loadStatus = this.formGroup.controls.status.value;
    const readOnlyStatuses = [
      LoadStatusName.Audited,
      LoadStatusName.Invoiced,
      LoadStatusName.Paid,
      LoadStatusName.Archive,
    ];
    this.isReadOnly = readOnlyStatuses.findIndex((x) => x === loadStatus) >= 0 || this.disabled;
    this.updateLoadUIViewMode();
  }

  private updateLoadUIViewMode(): void {
    if (this.disabled) {
      this.formGroup.disable();
      return;
    }

    for (const field in this.formGroup.controls) {
      if (this.isReadOnly) {
        if (field !== 'status' && field !== 'billToNotesInternal' && field !== 'billToNotesReports') {
          this.formGroup.get(field).disable();
        }
      } else {
        this.formGroup.get(field).enable();
      }
    }
  }

  private conditionallyDisableTotalMilesBasedOnOverride(): void {
    this.model.overrideTotalMiles
      ? this.formGroup.controls[LoadFormFields.TotalMiles].enable()
      : this.formGroup.controls[LoadFormFields.TotalMiles].disable();
  }

  private conditionallySetTotalMilesFromLegs(): void {
    if (!this.formGroup.controls[LoadFormFields.OverrideTotalMiles].value) {
      let calculatedTotalMiles = 0;
      const legs = this.formGroup.controls.legs.value ?? [];
      legs.forEach((leg) => {
        const totalLegMiles = Number(leg.loadedMiles ? leg.loadedMiles : 0);
        calculatedTotalMiles = calculatedTotalMiles + totalLegMiles;
      });
      this.formGroup.controls[LoadFormFields.TotalMiles].setValue(calculatedTotalMiles);
    }
  }

  private init(id: number): void {
    this.showProgressOverlay = true;
    this.formSubmitted = false;
    setTimeout(() => this.focusInput.nativeElement.scrollIntoView());
    if (!this.clientToken.hasPermission('LoadEdit')) {
      this.closeDialog();
      return;
    }

    this.formGroup.get('customerInputDisabled').disable();
    this.modelId = id;
    this.model = {
      dispatcher: {},
      advances: [],
      otherCharges: [],
      legs: [],
    } as LoadDTO;

    this.getModel();
    this.tabIndex = 0;
    this.totalRate = 0;
    this.billToEmailAddress = null;
    this.formErrors.billToCompanyAddress = '';
    this.formErrors.requestedEquipmentId = '';
  }

  private async GetLoadById(isDeleted: boolean): Promise<LoadDTO> {
    const data = !isDeleted
      ? await this.loadService.GetLoadById({ id: this.modelId, Authorization: this.clientToken.auth() }).toPromise()
      : await this.loadService
          .GetDeletedLoadById({ id: this.modelId, Authorization: this.clientToken.auth() })
          .toPromise();

    return data;
  }
}
