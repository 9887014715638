/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SubDebtorLinkedDTO } from '../models/sub-debtor-linked-dto';
import { LinkDebtorDTO } from '../models/link-debtor-dto';
@Injectable({
  providedIn: 'root',
})
class SubDebtorService extends __BaseService {
  static readonly LinkPath = '/api/Debtor/Sub/Link';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a sub debtor from existing master debtor
   * @param params The `SubDebtorService.LinkParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  LinkResponse(params: SubDebtorService.LinkParams): __Observable<__StrictHttpResponse<SubDebtorLinkedDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Debtor/Sub/Link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubDebtorLinkedDTO>;
      })
    );
  }
  /**
   * Creates a sub debtor from existing master debtor
   * @param params The `SubDebtorService.LinkParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  Link(params: SubDebtorService.LinkParams): __Observable<SubDebtorLinkedDTO> {
    return this.LinkResponse(params).pipe(
      __map(_r => _r.body as SubDebtorLinkedDTO)
    );
  }
}

module SubDebtorService {

  /**
   * Parameters for Link
   */
  export interface LinkParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: LinkDebtorDTO;
  }
}

export { SubDebtorService }
