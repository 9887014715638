<p-confirmDialog
  appendTo="body"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '26.56rem' }"
></p-confirmDialog>

<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<div class="grid grid-nogutter col-12 accordionBody" [formGroup]="formGroup">
  <div class="col-12 md:col-6 p-2">
    <div class="grid col-12 p-1">
      <div class="col-8 col-offset-4 px-0 py-1">
        <p-selectButton
          [options]="[
            { label: 'Pick', value: 'Pick' },
            { label: 'Drop', value: 'Drop' }
          ]"
          formControlName="stopType"
          [(ngModel)]="stop.stopType"
          (onChange)="onStopTypeChanged()"
        >
        </p-selectButton>
      </div>
    </div>

    <div class="grid col-12 p-1">
      <div class="col-4 font-bold">
        {{ stop.stopType === 'Pick' ? 'Shipper' : 'Consignee' }}
      </div>
      <app-company-address-selector-v2
        class="col-8 px-0 py-1"
        [companyAddressType]="setStopCompanyAddressType(stop)"
        [(ngModel)]="stop.companyAddress"
        formControlName="companyAddress"
        (changed)="onCompanyAddressSelectorChange($event)"
        [readonly]="readonly"
      >
      </app-company-address-selector-v2>
    </div>

    <div class="grid col-12 p-1">
      <div class="col-4 font-bold"> Address </div>
      <div *ngIf="stop.address" class="col-8 p-0">
        <app-address
          [(formGroup)]="formGroup"
          [collapsed]="false"
          [prefix]="'address'"
          [(Address)]="stop.address"
          [(formErrors)]="formErrors"
          [(validationMessages)]="validationMessages"
          [includeCanadian]="true"
          [readonly]="readonly"
          [requireInput]="false"
          (locationChanged)="onLocationChanged($event)"
        >
        </app-address>
      </div>
    </div>

    <div class="grid col-12 p-1">
      <div class="col-4 font-bold"> Contact </div>
      <div *ngIf="stop.contact" class="col-8 p-0">
        <app-contact [(ngModel)]="stop.contact" formControlName="contact" [needsHighlight]="needsHighlight">
        </app-contact>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-6 p-2">
    <div class="grid col-12 px-0 py-1">
      <input #focusable class="focusable" />
      <div class="col-4 font-bold p-1">{{ stop.stopType === 'Pick' ? 'Ship' : 'Delivery' }} Date/Time</div>
      <app-date-time-selector
        class="col-8 p-0"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="stopDateTime"
        (dateChanged)="updateStopScheduledTime($event)"
        (blurEvent)="onDateTimeBlur()"
        [readonly]="readonly"
      >
      </app-date-time-selector>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 md:col-4 font-bold p-1">Appointment</div>
      <div class="col-12 md:col-8 px-0">
        {{
          stop.appointmentRequired === null
            ? '&nbsp;'
            : stop.appointmentRequired
            ? 'Appointment required.'
            : 'Appointment not required.'
        }}
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-4 md:col-4 font-bold p-1">{{ stop.stopType === 'Pick' ? 'Shipping' : 'Receiving' }} Hours</div>
      <div class="col-8 md:col-8 px-0">
        {{ stop.shippingReceivingHours ? stop.shippingReceivingHours : '&nbsp;' }}
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <input #freightDescription class="focusable" />
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="Freight Description"
          type="text"
          formControlName="description"
          [(ngModel)]="stop.description"
          [formErrors]="formErrors"
          labelClass="col-4"
          controlClass="col-8 p-0"
        >
        </app-form-field>
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="Quantity"
          type="text"
          [nullable]="false"
          formControlName="quantity"
          [(ngModel)]="stop.quantity"
          [formErrors]="formErrors"
          labelClass="col-4"
          controlClass="col-8 p-0"
          keyFilter="number"
        >
        </app-form-field>
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="Weight"
          type="text"
          [nullable]="false"
          formControlName="weight"
          [(ngModel)]="stop.weight"
          [formErrors]="formErrors"
          labelClass="col-4"
          controlClass="col-8 p-0"
          keyFilter="number"
        >
        </app-form-field>
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="{{ stop.stopType === 'Pick' ? 'Shipping' : 'Delivery' }} Notes"
          secondaryLabel="Internal use only."
          type="textarea"
          formControlName="notesInternal"
          [(ngModel)]="stop.notesInternal"
          [formErrors]="formErrors"
          labelClass="col-4 p-0"
          controlClass="col-8 p-0"
        >
        </app-form-field>
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="{{ stop.stopType === 'Pick' ? 'Shipping' : 'Delivery' }} Notes"
          secondaryLabel="Shown on documents."
          type="textarea"
          formControlName="notesReports"
          [(ngModel)]="stop.notesReports"
          [formErrors]="formErrors"
          labelClass="col-4 p-0"
          controlClass="col-8 p-0"
        >
        </app-form-field>
      </div>
    </div>

    <div class="grid col-12 px-0 py-1">
      <div class="col-12 p-1">
        <app-form-field
          [formGroup]="formGroup"
          label="PO Number(s)"
          type="text"
          formControlName="poNumbers"
          [(ngModel)]="stop.poNumbers"
          [formErrors]="formErrors"
          labelClass="col-4"
          controlClass="col-8 p-0"
        >
        </app-form-field>
      </div>
    </div>
  </div>
</div>
