<form [formGroup]="formGroup" class="grid" autocomplete="off">
  <div class="grid col-6">
    <div class="col-12">
      <app-password
        label="Password *"
        labelClass="col-12"
        controlClass="col-12"
        formControlName="password"
      ></app-password>
      <p-message *ngIf="containsWhiteSpace()" severity="info" text=" Contains Leading or Trailing White Space">
      </p-message>
    </div>
    <app-password
      class="col-12"
      label="Confirm Password *"
      labelClass="col-12"
      controlClass="col-12"
      formControlName="confirm-password"
    ></app-password>
  </div>
  <div class="col-6 grid pt-2 pl-2">
    <span class="col-12 pb-0"><strong>Password Must Contain:</strong></span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.Length)"
    ></i>
    <span class="col-11 p-0">At least 8 characters</span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.LowerCase)"
    ></i>
    <span class="col-11 p-0">At least 1 lowercase letter</span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.UpperCase)"
    ></i>
    <span class="col-11 p-0">At least 1 uppercase letter</span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.Number)"
    ></i>
    <span class="col-11 p-0">At least 1 number</span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.SpecialCharacter)"
    ></i>
    <span class="col-11 p-0">At least 1 special character (!?$+@)</span>
    <i
      class="col-1 p-0 flex justify-content-center align-items-center"
      [ngClass]="getCurrentRequirementStatus(passwordRequirements.Match)"
    ></i>
    <span class="col-11 p-0">Must match</span>
  </div>
</form>
