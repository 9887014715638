import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { RequestCacheService } from '../../../services/request-cache.service';

/**
 * Intercepts HTTP requests and checks if they are cached or not.
 * Many calls change very rarely so this should improve speed
 * source: https://fullstack-developer.academy/caching-http-requests-with-angular/
 */
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    return cachedResponse ? observableOf(cachedResponse) : this.sendRequest(req, next, this.cache);
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCacheService): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
