import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { CustomNotification } from '../../shared/models/custom-notification';
import { NotificationData } from './notification-data';
import { NotificationSeverities } from './notification-severities';

export interface ApiError {
  ok: boolean;
  status: number;
  statusText: string;
  name: string;
  message: string;
  error: any;
}

export interface ApiBadRequestError extends ApiError {
  ok: false;
  status: 400;
  error: {
    [name: string]: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notificationChange = new Subject<Message>();

  private notifySimple(severity: NotificationSeverities, summary: string, detail: string) {
    const threeSeconds = 3000;

    const notification: CustomNotification = {
      type: severity,
      summary,
      detail,
      time: threeSeconds,
      sticky: false,
    };

    this.notify(notification);
  }

  notify(notification: CustomNotification) {
    const notificationData: NotificationData = {
      clickable: false,
    };
    this.notificationChange.next({
      severity: notification.type,
      summary: notification.summary,
      detail: notification.detail,
      life: notification.time,
      sticky: notification.sticky,
      data: notificationData,
    });
  }

  notifyWithCallback(notification: CustomNotification, callback: Function, linkText: string) {
    const notificationData: NotificationData = {
      clickable: true,
      callback: callback,
      linkText: linkText,
    };
    this.notificationChange.next({
      severity: notification.type,
      summary: notification.summary,
      detail: notification.detail,
      life: notification.time,
      sticky: notification.sticky,
      data: notificationData,
    });
  }

  notifyWithLink(notification: CustomNotification, route: string, linkText: string) {
    const notificationData: NotificationData = {
      clickable: true,
      linkRoute: route,
      linkText: linkText,
    };
    this.notificationChange.next({
      severity: notification.type,
      summary: notification.summary,
      detail: notification.detail,
      life: notification.time,
      sticky: notification.sticky,
      data: notificationData,
    });
  }

  success(summary: string, detail: string) {
    this.notifySimple('success', summary, detail);
  }

  info(summary: string, detail: string) {
    this.notifySimple('info', summary, detail);
  }

  warn(summary: string, detail: string) {
    this.notifySimple('warn', summary, detail);
  }

  error(summary: string, detail: string) {
    const notification: CustomNotification = {
      type: 'error',
      summary,
      detail,
      sticky: true,
    };

    this.notify(notification);
  }

  apiError(e: ApiError) {
    if (e.status === 400) {
      this.apiBadRequest(e as ApiBadRequestError);
    } else {
      this.error(`API Error: ${e.status}`, e.message);
    }
  }

  apiBadRequest(e: ApiBadRequestError) {
    const detail = Object.keys(e.error)
      .map((x) => e.error[x].join(', '))
      .join('\n');
    this.error('API Validation Error', detail);
  }
}
