import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadEditComponent } from './edit/edit.component';
import { LoadListComponent } from './list/list.component';
import { LoadRoutingModule } from './load-routing.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { LoadPaymentsComponent } from './payments/payments.component';
import { DriverPayComponent } from './driverpay/driverpay.component';
import { SettlementComponent } from './driverpay/settlement/settlement.component';
import { FocusTrapService } from '../../../services/focus-trap.service';
import { FactoringModule } from '../factoring/factoring.module';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, LoadRoutingModule, FactoringModule],
  exports: [LoadEditComponent],
  declarations: [LoadEditComponent, LoadListComponent, LoadPaymentsComponent, DriverPayComponent, SettlementComponent],
  providers: [FocusTrapService],
})
export class LoadModule {}
