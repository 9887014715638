<div class="dashboard pt-5 pl-5 pr-7">
  <div class="grid">
    <div class="col-12 md:col-12 lg:col-4">
      <app-loads-widget></app-loads-widget>
    </div>
    <div class="col-12 md:col-12 lg:col-4">
      <app-miles-widget></app-miles-widget>
    </div>
    <div class="col-12 md:col-12 lg:col-4">
      <app-revenue-widget></app-revenue-widget>
    </div>
  </div>
  <div class="grid mt-5" id="reporting-wizard">
    <div class="col-12">
      <app-reporting-wizard></app-reporting-wizard>
    </div>
  </div>
</div>
<div class="flex justify-content-center">
  <p class="disclaimer-text">
    Reported data provided via the Reporting Dashboard are based on the data provided as part of your tenant
    transactions conducted within the Truxio tool.
  </p>
</div>

<app-panel
  [headerText]="'Truxio Notifications'"
  [openPanelButtonIcon]="'fa fa-bell'"
  [closePanelButtonIcon]="'pi pi-arrow-right'"
  [contentType]="panelContentType.Notifications"
  [content]="notifications"
  [alertCount]="totalUnreadNotifications"
  (panelOpened)="notificationPanelOpened()"
>
</app-panel>
