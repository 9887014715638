import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ReportingService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';
import { ReportingWizardFilterCategoryValues } from '../reporting-wizard-filter/reporting-wizard-filter-category-values';
import { ReportingQueryService } from '../services/reporting-query/reporting-query-service';
import { FileSaverService } from 'src/services/file-saver/file-saver.service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { ReportingWizardFilterComponent } from '../reporting-wizard-filter/reporting-wizard-filter.component';

@Component({
  selector: 'app-reporting-wizard',
  templateUrl: './reporting-wizard.component.html',
  styleUrls: ['./reporting-wizard.component.scss'],
})
export class ReportingWizardComponent implements OnInit, OnDestroy, AfterViewChecked {
  public formGroup: FormGroup;
  public reportInProgress: boolean;

  private destroy$ = new Subject<void>();

  @ViewChild('filterForm') filterForm: ReportingWizardFilterComponent;

  constructor(
    private formBuilder: FormBuilder,
    private reportingService: ReportingService,
    private clientTokenService: ClientTokenService,
    private reportingQueryService: ReportingQueryService,
    private fileSaverService: FileSaverService,
    private errorHandlingService: ErrorHandlingService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      filter: [],
      columns: [],
    });

    this.formGroup.controls.filter.valueChanges
      .pipe(
        tap(() => this.conditionallyDisableOptions()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  public download(): void {
    if (!this.formGroup.valid || !this.filterForm.validateForm()) {
      return;
    }

    const columns = this.formGroup.controls.columns.value;
    const filter = this.formGroup.controls.filter.value;
    const reportingQuery = this.reportingQueryService.buildQueryObject(columns, filter);

    this.reportInProgress = true;
    this.reportingService
      .GetReportAsCSV({
        Authorization: this.clientTokenService.auth(),
        body: reportingQuery,
      })
      .pipe(
        tap((data) => this.fileSaverService.saveAs(data, 'report.csv')),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.reportInProgress = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private conditionallyDisableOptions(): void {
    const categoriesWithoutOptions = [ReportingWizardFilterCategoryValues.DriverSettlement];

    const selectedCategory = this.formGroup.controls.filter.value.category;
    categoriesWithoutOptions.includes(selectedCategory)
      ? this.formGroup.controls.columns.disable()
      : this.formGroup.controls.columns.enable();
  }
}
