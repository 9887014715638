import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { NoteDTO, TenantDTO } from 'src/apiclient/v1.1/models';
import { AllTenantService } from 'src/apiclient/v1.1/services';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { NoteType } from 'src/app/data/static-data';
import { ClientTokenService } from 'src/services/client-token-service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';

@Component({
  selector: 'app-tenant-notes',
  templateUrl: './tenant-notes.component.html',
  styleUrls: ['./tenant-notes.component.scss'],
})
export class TenantNotesComponent implements OnDestroy {
  @Input() set tenant(tenant: TenantDTO) {
    this._tenant = tenant;
    this.getSuperAdminNotes();
  }
  get tenant(): TenantDTO {
    return this._tenant;
  }

  public superAdminNotes$: Observable<string>;

  private _tenant: TenantDTO;
  private destroy$ = new Subject<void>();

  constructor(
    private clientTokenService: ClientTokenService,
    private allTenantService: AllTenantService,
    private errorHandlingService: ErrorHandlingService,
    private notificationsService: NotificationsService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveSuperAdminNotes(newNotes: string): void {
    const note: NoteDTO = {
      noteType: NoteType.SuperAdmin,
      text: newNotes,
    };

    this.saveAllTenantNotes(note)
      .pipe(
        tap(() => (this.superAdminNotes$ = of(newNotes))),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private saveAllTenantNotes(notes: NoteDTO): Observable<void> {
    return this.allTenantService
      .SetTenantNotes({
        id: this.tenant.tenantId,
        body: notes,
        Authorization: this.clientTokenService.auth(),
      })
      .pipe(
        tap(() => this.notificationsService.success('Info Updated.', 'Your updates were saved successfully.')),
        catchError((error) => this.errorHandlingService.handleError(error))
      );
  }

  private getSuperAdminNotes(): void {
    this.superAdminNotes$ = this.allTenantService
      .GetTenantNotes({
        id: this.tenant.tenantId,
        noteType: NoteType.SuperAdmin,
        Authorization: this.clientTokenService.auth(),
      })
      .pipe(map((notes) => (notes ? notes.replace(/^"(.*)"$/, '$1') : '')));
  }
}
