/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccountingService } from './services/accounting.service';
import { AllTenantService } from './services/all-tenant.service';
import { AllUserService } from './services/all-user.service';
import { AuthenticationService } from './services/authentication.service';
import { BillingService } from './services/billing.service';
import { CarrierService } from './services/carrier.service';
import { CompanyService } from './services/company.service';
import { CompanyAddressService } from './services/company-address.service';
import { CompanyBuyStatusesService } from './services/company-buy-statuses.service';
import { DebtorAddressService } from './services/debtor-address.service';
import { DocumentsService } from './services/documents.service';
import { DriverPayService } from './services/driver-pay.service';
import { DriverProfileService } from './services/driver-profile.service';
import { EquipmentService } from './services/equipment.service';
import { FactoringCompanyService } from './services/factoring-company.service';
import { FactoringCompanyOptionsService } from './services/factoring-company-options.service';
import { HealthService } from './services/health.service';
import { InvoiceFactoringService } from './services/invoice-factoring.service';
import { LoadService } from './services/load.service';
import { LoadDistanceService } from './services/load-distance.service';
import { LoadJournalService } from './services/load-journal.service';
import { LogisticsLoadService } from './services/logistics-load.service';
import { LogisticsTenantService } from './services/logistics-tenant.service';
import { LogisticsTenantAccountingService } from './services/logistics-tenant-accounting.service';
import { MapService } from './services/map.service';
import { MarketplaceService } from './services/marketplace.service';
import { MasterDebtorService } from './services/master-debtor.service';
import { PlatformNotificationService } from './services/platform-notification.service';
import { RegisterService } from './services/register.service';
import { ReportingService } from './services/reporting.service';
import { ReportSummaryService } from './services/report-summary.service';
import { RoleService } from './services/role.service';
import { SubDebtorService } from './services/sub-debtor.service';
import { SuperAdminService } from './services/super-admin.service';
import { SystemSettingService } from './services/system-setting.service';
import { TempUserService } from './services/temp-user.service';
import { TenantService } from './services/tenant.service';
import { TenantAttachmentService } from './services/tenant-attachment.service';
import { TenantFactoringService } from './services/tenant-factoring.service';
import { UserService } from './services/user.service';
import { UserFactoringService } from './services/user-factoring.service';
import { UserNotificationService } from './services/user-notification.service';
import { ValidationService } from './services/validation.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccountingService,
    AllTenantService,
    AllUserService,
    AuthenticationService,
    BillingService,
    CarrierService,
    CompanyService,
    CompanyAddressService,
    CompanyBuyStatusesService,
    DebtorAddressService,
    DocumentsService,
    DriverPayService,
    DriverProfileService,
    EquipmentService,
    FactoringCompanyService,
    FactoringCompanyOptionsService,
    HealthService,
    InvoiceFactoringService,
    LoadService,
    LoadDistanceService,
    LoadJournalService,
    LogisticsLoadService,
    LogisticsTenantService,
    LogisticsTenantAccountingService,
    MapService,
    MarketplaceService,
    MasterDebtorService,
    PlatformNotificationService,
    RegisterService,
    ReportingService,
    ReportSummaryService,
    RoleService,
    SubDebtorService,
    SuperAdminService,
    SystemSettingService,
    TempUserService,
    TenantService,
    TenantAttachmentService,
    TenantFactoringService,
    UserService,
    UserFactoringService,
    UserNotificationService,
    ValidationService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
