/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DebtorAddressMatchingDTO } from '../models/debtor-address-matching-dto';
@Injectable({
  providedIn: 'root',
})
class DebtorAddressService extends __BaseService {
  static readonly GetPotentialDebtorAddressMatchesPath = '/api/Debtor/Address/Matches';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Checks passes address to see if there are any debtors in the system with a similar address
   * @param params The `DebtorAddressService.GetPotentialDebtorAddressMatchesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Zip`:
   *
   * - `State`:
   *
   * - `Line2`:
   *
   * - `Line1`:
   *
   * - `City`:
   *
   * @return Success
   */
  GetPotentialDebtorAddressMatchesResponse(params: DebtorAddressService.GetPotentialDebtorAddressMatchesParams): __Observable<__StrictHttpResponse<Array<DebtorAddressMatchingDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Zip != null) __params = __params.set('Zip', params.Zip.toString());
    if (params.State != null) __params = __params.set('State', params.State.toString());
    if (params.Line2 != null) __params = __params.set('Line2', params.Line2.toString());
    if (params.Line1 != null) __params = __params.set('Line1', params.Line1.toString());
    if (params.City != null) __params = __params.set('City', params.City.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Debtor/Address/Matches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DebtorAddressMatchingDTO>>;
      })
    );
  }
  /**
   * Checks passes address to see if there are any debtors in the system with a similar address
   * @param params The `DebtorAddressService.GetPotentialDebtorAddressMatchesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Zip`:
   *
   * - `State`:
   *
   * - `Line2`:
   *
   * - `Line1`:
   *
   * - `City`:
   *
   * @return Success
   */
  GetPotentialDebtorAddressMatches(params: DebtorAddressService.GetPotentialDebtorAddressMatchesParams): __Observable<Array<DebtorAddressMatchingDTO>> {
    return this.GetPotentialDebtorAddressMatchesResponse(params).pipe(
      __map(_r => _r.body as Array<DebtorAddressMatchingDTO>)
    );
  }
}

module DebtorAddressService {

  /**
   * Parameters for GetPotentialDebtorAddressMatches
   */
  export interface GetPotentialDebtorAddressMatchesParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    Zip?: string;
    State?: string;
    Line2?: string;
    Line1?: string;
    City?: string;
  }
}

export { DebtorAddressService }
