<div class="centered-card-container-large">
  <p-card [style]="{ padding: '2%' }">
    <app-wizard-progress [wizardSteps]="wizardSteps" [currentStepIndex]="currentStepIndex"></app-wizard-progress>
    <div [ngSwitch]="currentStepIndex" class="form-container">
      <app-company-information-form
        *ngSwitchCase="0"
        [tempUser]="tempUser"
        [wizardStep]="wizardSteps[0]"
        (previousStep)="previousStep()"
        (nextStep)="nextStep()"
      ></app-company-information-form>

      <app-mailing-address-form
        *ngSwitchCase="1"
        [tempUser]="tempUser"
        [wizardStep]="wizardSteps[1]"
        (previousStep)="previousStep()"
        (nextStep)="nextStep()"
      ></app-mailing-address-form>

      <app-physical-address-form
        *ngSwitchCase="2"
        [tempUser]="tempUser"
        [wizardStep]="wizardSteps[2]"
        [mailingForm]="wizardSteps[1].stepForm"
        [navigationDirection]="navigationDirection"
        (previousStep)="previousStep()"
        (nextStep)="nextStep()"
      ></app-physical-address-form>

      <app-eula-form
        *ngSwitchCase="3"
        [tempUser]="tempUser"
        [wizardStep]="wizardSteps[3]"
        (previousStep)="previousStep()"
        (nextStep)="nextStep()"
      ></app-eula-form>

      <app-administrator-information-form
        *ngSwitchCase="4"
        [tempUser]="tempUser"
        [wizardStep]="wizardSteps[4]"
        [registrationInProgress]="registrationInProgress"
        (previousStep)="previousStep()"
        (nextStep)="nextStep()"
      ></app-administrator-information-form>
    </div>
  </p-card>
</div>
