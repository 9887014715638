<p-dialog
  appendTo="body"
  [header]="title"
  [(visible)]="visible"
  [closeOnEscape]="true"
  [closable]="true"
  [style]="{ width: '59.375rem' }"
  styleClass="quick-add-section"
  (onHide)="closeDialog()"
  [modal]="true"
  [focusTrap]="visible"
  [focusOnShow]="false"
  id="add-new-dialog"
>
  <form [formGroup]="formGroup" class="w-full flex flex-column">
    <div class="grid grid-nogutter col-12 p-1">
      <div class="col-6 p-1">
        <div class="grid grid-nogutter col-12 p-1">
          <div class="col-12 p-1 font-bold">Company Name</div>

          <div class="col-12 p-1 input-box">
            <input [hidden]="formGroup.get('name').enabled" class="col-12" pInputText formControlName="name" />

            <app-company-selector
              *ngIf="formGroup.get('name').enabled"
              #companySelector
              formControlName="companyId"
              [forceSelection]="false"
              [showDropdown]="false"
              (companyChanged)="companySelected($event)"
            ></app-company-selector>
          </div>
          <div *ngIf="formErrors.name" class="formerrors col-12 p-1">
            {{ formErrors.name }}
          </div>
        </div>

        <ng-container *ngIf="formGroup.get('companyId').value as companyId">
          <div class="grid grid-nogutter col-12 p-1">
            <div class="col-12 p-1 font-bold">Edit Existing Address</div>

            <div class="input-box col-12 p-1">
              <input
                *ngIf="formGroup.get('companyAddressId').disabled"
                disabled
                class="col-12"
                pInputText
                [value]="formGroup.get('city').value + ', ' + formGroup.get('state').value"
              />

              <app-company-address-selector
                *ngIf="formGroup.get('companyAddressId').enabled"
                [autoSelectOnlyResult]="false"
                [companyId]="companyId"
                formControlName="companyAddressId"
                [showDropdown]="true"
                (changed)="companyAddressSelected()"
              ></app-company-address-selector>
            </div>
            <div *ngIf="formErrors.companyAddressId" class="formerrors col-12 p-1">
              {{ formErrors.companyAddressId }}
            </div>
          </div>
        </ng-container>

        <div class="grid grid-nogutter col-12 p-1">
          <div class="col-12">
            <app-form-field
              label="Address Line 1"
              type="text"
              labelClass="col-12 p-1"
              controlClass="col-12"
              errorClass="col-12 p-1"
              [formGroup]="formGroup"
              formControlName="line1"
              [formErrors]="formErrors"
            ></app-form-field>
            <app-form-field
              label="Address Line 2"
              type="text"
              labelClass="col-12 p-1"
              controlClass="col-12"
              errorClass="col-12 p-1"
              [formGroup]="formGroup"
              formControlName="line2"
              [formErrors]="formErrors"
            ></app-form-field>

            <div class="grid grid-nogutter col-12 p-0">
              <div class="col-6 p-0">
                <app-form-field
                  label="City"
                  type="text"
                  labelClass="col-12 p-1"
                  controlClass="col-12 md:col-12"
                  errorClass="col-12 md:col-12 p-1"
                  [formGroup]="formGroup"
                  formControlName="city"
                  [formErrors]="formErrors"
                ></app-form-field>
              </div>
              <div class="col-2 p-0">
                <app-form-field
                  id="state-field"
                  label="State"
                  [type]="formGroup.get('state').disabled ? 'text' : 'state-selector'"
                  labelClass="col-12 p-1"
                  controlClass="col-12 p-0"
                  controlClassLevel1="p-1"
                  errorClass="col-12 md:col-12 sm:col-12 p-1"
                  [formGroup]="formGroup"
                  formControlName="state"
                  [formErrors]="formErrors"
                  (stateChanged)="onStateChange($event)"
                ></app-form-field>
              </div>
              <div class="col-4 p-0">
                <app-form-field
                  label="Zip"
                  type="text"
                  labelClass="col-12 p-1"
                  controlClass="col-12 md:col-12"
                  errorClass="col-12 md:col-12 p-1"
                  [formGroup]="formGroup"
                  formControlName="zip"
                  [formErrors]="formErrors"
                ></app-form-field>
              </div>
            </div>
          </div>
          <div>
            <div #target></div>
          </div>
        </div>

        <div class="grid grid-nogutter col-12 p-2">
          <div class="col-4 p-0">
            <div class="grid col-12 p-0 flex flex-wrap align-items-center">
              <div class="col-4 px-0 text-right">
                <p-checkbox formControlName="isBillTo" binary="true"></p-checkbox>
              </div>
              <div class="col-8">Bill To</div>
              <div class="formerrors col-12 p-1">
                {{ formErrors.isBillTo }}
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="grid col-12 p-0 flex flex-wrap align-items-center">
              <div class="col-4 px-0 text-right">
                <p-checkbox formControlName="isShipper" binary="true"></p-checkbox>
              </div>
              <div class="col-8">Shipper</div>
              <div class="formerrors col-12 p-1">
                {{ formErrors.isShipper }}
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="grid col-12 p-0 flex flex-wrap align-items-center">
              <div class="col-4 px-0 text-right">
                <p-checkbox formControlName="isConsignee" binary="true"></p-checkbox>
              </div>
              <div class="col-8">Consignee</div>
              <div class="formerrors col-12 p-1">
                {{ formErrors.isConsignee }}
              </div></div
            >
          </div>

          <div *ngIf="!isBillToRoleValid() && showBillToRoleValidation()" class="formerrors col-12 p-0">
            At least one role must be selected.
          </div>
        </div>
      </div>

      <div class="col-6 p-1">
        <div class="grid grid-nogutter col-12 p-1">
          <app-form-field
            class="col-12 p-0"
            label="Contact First Name"
            type="text"
            labelClass="col-12 p-0 px-1 pt-1"
            controlClass="col-12"
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="firstName"
            [formErrors]="formErrors"
          ></app-form-field>

          <app-form-field
            class="col-12 py-1"
            label="Contact Last Name"
            type="text"
            labelClass="col-12 p-1"
            controlClass="col-12"
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="lastName"
            [formErrors]="formErrors"
          ></app-form-field>

          <app-form-field
            class="col-12 p-0"
            label="Contact Phone Number"
            type="text"
            labelClass="col-12 p-0 px-1 pt-1"
            controlClass="col-12 "
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="phoneNumber"
            [formErrors]="formErrors"
            keyFilter="number"
          ></app-form-field>

          <app-form-field
            class="col-12 py-2"
            label="Contact Email"
            type="text"
            labelClass="col-12 p-1 py-0"
            controlClass="col-12"
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="email"
            [formErrors]="formErrors"
          ></app-form-field>

          <app-form-field
            class="col-12 p-0"
            label="MC# (If Applicable)"
            type="text"
            labelClass="col-12 p-1 py-0"
            controlClass="col-12"
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="mcNumber"
            [formErrors]="formErrors"
          ></app-form-field>

          <app-form-field
            class="col-12 py-2"
            label="DOT# (If Applicable)"
            type="text"
            labelClass="col-12 p-1 py-0"
            controlClass="col-12"
            errorClass="col-12 p-1"
            [formGroup]="formGroup"
            formControlName="dotNumber"
            [formErrors]="formErrors"
          ></app-form-field>
        </div>
      </div>
    </div>

    <app-address-matching-overlay
      *ngIf="shouldShowAddressSuggestions() && (factoringStatus$ | async)"
      [headerText]="'Bill To Suggestions'"
      [inputAddress]="getAddress()"
      [target]="target"
      [visible]="visible"
      [showSuggestionsOnFirstInput]="!!inputCompanyAddressId"
      (suggestionSelected)="suggestedAddressSelected($event)"
    ></app-address-matching-overlay>
  </form>
  <p-footer>
    <button type="text" (click)="save()" [disabled]="disableSave" pButton icon="fa fa-check" label="Save"></button>
  </p-footer>
</p-dialog>
