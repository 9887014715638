<div class="grid flex justify-content-center">
  <div class="col flex align-items-center justify-content-center">
    <div *ngIf="!isComplete" class="fa-stack fa-2x" [class.selected]="isSelected">
      <i class="fa fa-circle fa-stack-2x"></i>
      <i class="fa-stack-1x fa-inverse">
        <p id="step-number">{{ stepProgressNumber }}</p>
      </i>
    </div>
    <i *ngIf="isComplete" class="fa fa-check-circle"></i>
  </div>
  <p class="col step-name" [class.selected]="isSelected" id="step-name"> {{ stepName }} </p>
</div>
