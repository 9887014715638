import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'formatTimestampString',
})
export class FormatTimestampString implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return value;
    }

    return format(new Date(value), 'MM/dd/yy @ HH:mm');
  }
}
