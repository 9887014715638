<div class="p-0">
  <div class="{{ labelClass }}" *ngIf="labelClass !== 'nolabel'" id="label-container">
    <div class="{{ boldLabel === true ? 'label-black' : '' }} col-12 p-0" id="label">
      {{ label }}
    </div>
  </div>

  <div class="ui-inputgroup p-1 input-box {{ controlClass }}">
    <input
      [type]="showPassword ? 'text' : 'password'"
      pInputText
      class="col"
      [class.ng-invalid]="getFormError()"
      [id]="formControlName"
      [placeholder]="placeholder"
      [formControl]="passwordControl"
      (blur)="onTouched()"
      (input)="onTouched()"
    />
    <button
      pButton
      [id]="formControlName + '-password-visibility'"
      type="button"
      [icon]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
      class="ui-button-secondary"
      (click)="toggleShowPassword()"
    ></button>
  </div>

  <div class="formerrors {{ errorClass }} p-0" *ngIf="getFormError()">
    {{ getFormError() | formatErrorMessage }}
  </div>
</div>
