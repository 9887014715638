import { Component, OnInit, Input, Output, Injector, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DtoFormBase } from '../../../../../shared/FormBase';
import { DocumentOnFileDTO } from '../../../../../../apiclient/models';
import * as moment from 'moment';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecordComponent extends DtoFormBase<DocumentOnFileDTO> implements OnInit {
  @Input() viewMode?: string;
  @Input() checked: boolean;
  @Input() document;
  @Input() field;
  @Output() checkChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() expiresChanged: EventEmitter<any> = new EventEmitter<any>();

  private propagateChange = (_: any) => {};

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.model = {} as DocumentOnFileDTO;
    this.model.type = this.field.type;
    if (this.document.expiresOn) {
      this.model.expiresOn = new Date(this.document.expiresOn).toLocaleDateString();
    }

    this.initFormFor('DocumentOnFileDTO');

    this.formGroup.addControl('checked', new FormControl());
  }

  onCheckboxChange() {
    this.checkChanged.emit({
      checked: this.checked,
      document: this.model,
      field: this.field,
    });
  }

  onExpiresChange() {
    this.checkChanged.emit({
      checked: this.checked,
      document: this.model,
      field: this.field,
    });
  }

  showExpiresOn() {
    if (this.field.showExpiresOn && this.checked && this.viewMode === 'display') {
      return moment(this.model.expiresOn).format('MM/DD/YY') + ' - ';
    }

    return '';
  }
}
