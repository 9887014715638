<app-driver-profile-edit #driverEditDialog (modelEdited)="handleDriverEdit($event)"></app-driver-profile-edit>

<p-card styleClass="ui-card-shadow">
  <div class="layout-content-container table-container">
    <div class="grid grid-nogutter col-12 p-2 table-inner">
      <div class="col-12 p-0">
        <h1>Drivers</h1>
      </div>
      <div class="grid grid-nogutter col-12 p-0 flex flex-wrap justify-content-between align-items-center">
        <div class="col-8 p-0">
          <div class="col-3 px-0">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon ui-inputgroup-addon-inside"><i class="fa fa-search"></i></span>
              <input
                class="col-12"
                [(ngModel)]="filter"
                (keyup)="filterTable()"
                placeholder="Search Drivers"
                pInputText
              />
            </div>
          </div>
        </div>
        <div class="col-4 flex flex-wrap justify-content-end">
          <p-button
            *ngIf="!viewAccounting"
            (onClick)="driverEditDialog.init(0)"
            icon="fa fa-fw fa-plus"
            label="New Driver"
            class="ui-button-secondary"
          >
          </p-button>
        </div>
      </div>
      <div class="col-12 py-1">
        <p-table
          [columns]="cols"
          [value]="drivers"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="70vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : null" pResizableColumn>
                {{ col.header }}
                <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>
