import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Component, OnInit, Injector, Input, OnChanges, SimpleChanges, Inject, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DtoFormBase } from '../../../shared/FormBase';
import { SuperAdminService, AllTenantService, AllUserService } from '../../../../apiclient/v1.1/services';
import { TenantRoleDisplayName, TenantStatusName } from '../../../data/static-data';
import { Utils } from '../../../../utils/utils';
import {
  TenantSummaryDTO,
  IdNamePairDTO,
  TokenResponseDTO,
  ImpersonationRequestDTO,
} from '../../../../apiclient/v1.1/models';
import { TableColumn } from 'src/app/shared/models/TableColumn';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { FileSaverService } from 'src/services/file-saver/file-saver.service';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { TenantSummary } from '../models/tenant-summary';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class TenantListComponent extends DtoFormBase<null> implements OnInit, OnChanges, OnDestroy {
  // Impersonation Fields
  public impersonationDialogVisible: boolean = false;
  public impersonationUser: IdNamePairDTO = {};
  public impersonationOptions: IdNamePairDTO[] = [];
  private impersonationTenant: number = 0;
  // End Impersonation Fields

  public disableApply: boolean = false;
  public loading: boolean;
  public cols: TableColumn[];
  public tenants: TenantSummary[];
  @Input() public statusSelected?: Array<'Active' | 'Inactive'>;
  public statusFilters = [
    {
      label: 'All',
      value: null,
      active: true,
    },
    {
      label: 'Inactive',
      value: TenantStatusName.Inactive,
      active: false,
    },
    {
      label: 'Active',
      value: TenantStatusName.Active,
      active: false,
    },
    {
      label: 'Deleted',
      value: TenantStatusName.Deleted,
      active: false,
    },
  ];
  public actionSelected: any = '';
  public actionOptions = [
    {
      label: 'Active',
      value: TenantStatusName.Active,
    },
    {
      label: 'Inactive',
      value: TenantStatusName.Inactive,
    },
    {
      label: 'Delete',
      value: TenantStatusName.Deleted,
    },
  ];
  public filter: string;
  public bulkCheckAll: boolean = false;
  private tenantsOriginal: TenantSummary[];
  private isDeleted: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    protected injector: Injector,
    private allTenantService: AllTenantService,
    private superAdminService: SuperAdminService,
    private allUserService: AllUserService,
    private confirmationService: ConfirmationService,
    private notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private fileSaverService: FileSaverService
  ) {
    super(injector);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.statusSelected.length > 0) {
      this.statusFilters.map((i) => (i.active = false));
      this.statusFilters[1].active = true;
      this.getTenants();
    }
  }

  public toggleEnableTenant = (params): void => {
    const aTenant = params.row as TenantSummaryDTO;
    const nextStatus = this.handleTenantNextStatus(aTenant);
    try {
      this.allTenantService
        .SetTenantStatus({
          id: [aTenant.tenantId],
          status: nextStatus,
          Authorization: this.clientToken.auth(),
        })
        .subscribe(
          () => {
            this.getTenants();
            this.notificationsService.success(`Tenant is ${nextStatus}`, 'Your changes were saved successfully.');
          },
          this.handleError,
          () => (this.loading = false)
        );
    } catch (e) {
      this.handleError(e);
    }
  };

  public deleteTenant = (params): void => {
    const aTenant = params.row as TenantSummaryDTO;
    try {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Delete Tenant',
        icon: 'fa fa-question-circle',
        accept: () => {
          this.allTenantService
            .SetTenantStatus({
              id: [aTenant.tenantId],
              status: TenantStatusName.Deleted,
              Authorization: this.clientToken.auth(),
            })
            .subscribe(
              () => {
                aTenant.status = TenantStatusName.Deleted;
                this.getTenants();
                this.notificationsService.success(
                  `Tenant is ${TenantStatusName.Deleted}`,
                  'Your changes were saved successfully.'
                );
              },
              this.handleError,
              () => (this.loading = false)
            );
        },
        reject: () => {},
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  public openImpersonateDialog = async (params): Promise<void> => {
    const aTenant = params.row as TenantSummaryDTO;
    this.impersonationTenant = aTenant.tenantId;
    try {
      this.impersonationOptions = await this.allUserService
        .Names({
          tenantId: this.impersonationTenant,
          ExcludeDriverOnly: true,
          IncludeDisabled: false,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
      this.impersonationUser = this.impersonationOptions[0]; // Default to first entry
      this.impersonationDialogVisible = true;
    } catch (e) {
      this.handleError(e);
      this.impersonationDialogVisible = false;
    }
  };

  public impersonateUser = async (): Promise<void> => {
    try {
      let token: TokenResponseDTO;

      const impersonationRequest: ImpersonationRequestDTO = {
        impersonatedTenantId: this.impersonationTenant,
        impersonatedUserId: this.impersonationUser.id,
        refreshToken: this.clientToken.getRefreshToken(),
      };

      token = await this.superAdminService
        .Impersonate({
          body: impersonationRequest,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();

      this.storage.set('token', token);
      // Redirect browser window to dump state and load token from storage
      window.location.href = '/';
    } catch (e) {
      this.handleError(e);
    }
  };

  public openTenant(params): void {
    const aTenant = params.row as TenantSummaryDTO;
    this.router.navigate(['admin', 'tenants', aTenant.tenantId]);
  }

  public actionHandler = (actionConfig): void => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  public sortTable(event: any): void {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
      event.format = fieldInfo.format;
    }
    Utils.sortData(event, this.tenants);
  }

  public bulkToggleCheckAll(): void {
    this.tenants.forEach((tenant) => {
      tenant.isChecked = this.bulkCheckAll;
    });
  }

  public async bulkApply(): Promise<void> {
    const selected = this.getSelectedItems();
    const selectedIds = this.getSelectedTenantIDs();
    if (selected.length === 0) {
      this._NS.warn('Nothing selected', 'Please select at least one item.');
      return;
    }
    if (!this.actionSelected) {
      this._NS.warn('Nothing selected', 'Please select at least one action.');
      return;
    }

    try {
      this.disableApply = true;
      await this.allTenantService
        .SetTenantStatus({
          id: selectedIds,
          status: this.actionSelected,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
      this.getTenants();
      this._NS.success('Tenant status updated.', 'Your updates were saved successfully.');
    } catch (e) {
      this.handleError(e);
    } finally {
      setTimeout(() => {
        this.disableApply = false;
      }, 1500);
    }
  }

  public filterDataByStatus(status): void {
    this.statusFilters.map((i) => (i.active = false));
    status.active = true;
    this.statusSelected = [status.value];
    if (status.value === 'Deleted') {
      this.statusSelected = null;
      this.isDeleted = true;
    } else {
      this.isDeleted = false;
    }
    this.getTenants();
  }

  public filterTable(): void {
    if (this.filter) {
      this.tenants = this.tenantsOriginal.filter(
        (item) =>
          item.tenantId.toString().includes(this.filter.toLowerCase()) ||
          item.status.toLowerCase().includes(this.filter.toLowerCase()) ||
          item.name.toLowerCase().includes(this.filter.toLowerCase())
      );
    } else {
      this.tenants = this.tenantsOriginal;
    }
  }

  public ngOnInit(): void {
    if (!this.clientToken.hasPermission('UserRead')) {
      this.router.navigate(['/']);
    }

    this.checkQueryParams();
    this.getTenants();
    this.setCols();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public export(): void {
    const params: AllTenantService.ExportParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: this.statusSelected,
      nameContains: this.filter,
      isDeleted: this.isDeleted,
    };

    this.allTenantService.Export(params).subscribe(
      (data) => this.fileSaverService.saveAs(data, 'Tenant-list.csv'),
      (error) => {}
    );
  }

  private getTenants(): void {
    this.loading = true;
    this.allTenantService
      .GetTenants({
        statusEquals: this.statusSelected,
        nameContains: null,
        isDeleted: this.isDeleted,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        map((tenants) => this.mapTenants(tenants)),
        tap((data) => {
          this.tenants = data;
          this.tenantsOriginal = data;
          this.filterTable();
        }),
        catchError(this.handleError),
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private mapTenants(tenants: TenantSummaryDTO[]): TenantSummary[] {
    return tenants.map((t) => ({
      ...t,
      role: t.isLogistics ? TenantRoleDisplayName.Logistics : TenantRoleDisplayName.Carrier,
    }));
  }

  private handleTenantNextStatus(tenant: TenantSummaryDTO): TenantStatusName {
    if (tenant.status === 'Active') {
      return TenantStatusName.Inactive;
    }
    if (tenant.status === 'Inactive') {
      return TenantStatusName.Active;
    }
  }

  private getSelectedItems(): TenantSummary[] {
    return this.tenants.filter((item) => item.isChecked === true);
  }

  private getSelectedTenantIDs(): number[] {
    return this.tenants.filter((item) => item.isChecked === true).map((o) => o.tenantId);
  }

  private checkQueryParams(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap((params) => {
          const filterType = params['filter'];
          if (filterType && this.statusFilters.some((status) => status.label === filterType)) {
            this.statusFilters.forEach((status) => (status.active = status.label === filterType));
            this.statusSelected = [filterType];
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setCols(): void {
    this.cols = [
      {
        header: '-',
        format: 'checkbox',
        field: 'isChecked',
        width: '3em',
      },
      {
        field: 'name',
        header: 'Name',
        format: 'actionCell',
        sortable: true,
        action: {
          icon: 'fa-eye',
          class: 'icon-info ui-button-danger',
          func: 'openTenant',
          params: {
            row: 'row',
          },
        },
        width: '19em',
      },
      {
        field: 'status',
        header: 'Status',
        format: 'cssCell',
        formatByValue: [{ field: 'status', value: 'Inactive', class: 'cell-warning' }],
        sortable: true,
        width: '7em',
      },
      {
        field: 'billingStatus',
        header: 'Subscription',
        sortable: true,
        width: '10em',
      },
      {
        field: 'role',
        header: 'Tenant Role',
        sortable: true,
        width: '7em',
      },
      {
        field: 'factoringStatus',
        header: 'Factoring',
        sortable: true,
        format: 'BooleanEnabledDisabled',
        width: '8em',
      },
      {
        field: 'superAdminNotes',
        header: 'Notes',
        sortable: false,
        width: '7em',
      },
      {
        field: 'createdOn',
        header: 'Created',
        format: 'DateLocale',
        sortable: true,
        width: '10em',
      },
      {
        field: 'loadCount',
        header: 'Loads',
        sortable: true,
        width: '7em',
      },
      {
        field: 'userCount',
        header: 'Users',
        sortable: true,
        width: '7em',
      },
      {
        field: 'driverCount',
        header: 'Drivers',
        sortable: true,
        width: '7em',
      },
      {
        field: 'pfs_table_actions',
        header: 'Action',
        format: 'Actions',
        buttons: [
          {
            toggle: true,
            isTenant: true,
            icon: ['fas fa-unlock-alt', 'fas fa-lock'],
            iconColor: ['icon-danger', 'icon-info'],
            func: 'toggleEnableTenant',
            tooltip: 'Enable/Disable Tenant',
            class: 'icon-info icon-big ui-button ui-button-icon-only',
            params: {
              row: 'row',
            },
          },
          {
            icon: 'fas fa-trash',
            isTenant: true,
            class: 'icon-inactive icon-big',
            func: 'deleteTenant',
            tooltip: 'Delete Tenant',
            params: {
              row: 'row',
            },
          },
          {
            icon: 'fas fa-key',
            isTenant: false,
            class: 'icon-inactive icon-big',
            func: 'openImpersonateDialog',
            tooltip: 'Impersonate',
            params: {
              row: 'row',
            },
          },
        ],
        width: '10em',
      },
    ];
  }
}
