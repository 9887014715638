import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { IdNamePairDTO } from 'src/apiclient/models';
import { EquipmentService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';

@Component({
  selector: 'app-equipment-type-selector',
  templateUrl: './equipment-type-selector.component.html',
  styleUrls: ['./equipment-type-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentTypeSelectorComponent),
      multi: true,
    },
  ],
})
export class EquipmentTypeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public equipmentControl = new FormControl();
  public equipmentOptions$: Observable<IdNamePairDTO[]>;

  private writeValueStream$ = new BehaviorSubject<number>(null);
  private destroy$ = new Subject<void>();

  constructor(private equipmentService: EquipmentService, private clientTokenService: ClientTokenService) {}

  public ngOnInit(): void {
    this.setEquipmentOptions();
    this.equipmentControl.valueChanges
      .pipe(
        tap((value: IdNamePairDTO) => this.onChange(value?.id)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: number): void {
    this.writeValueStream$.next(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.equipmentControl.disable() : this.equipmentControl.enable();
  }

  private onChange = (value: number | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setEquipmentOptions(): void {
    this.equipmentOptions$ = combineLatest([
      this.equipmentService.Types(this.clientTokenService.auth()).pipe(startWith([])),
      this.writeValueStream$,
    ]).pipe(
      map((p) => ({ equipment: p[0], id: p[1] })),
      tap((p) => {
        if (p.id) {
          const selectedEquipment = p.equipment.find((x) => x.id === p.id);
          this.equipmentControl.setValue(selectedEquipment);
        }
      }),
      map((p) => p.equipment)
    );
  }
}
