import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ClientTokenService } from '../../../../services/client-token-service';
import { TenantStatusName } from '../../../data/static-data';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public readonly tabs = {
    system: 'System Defaults',
    tenants: 'Tenants',
    registrations: 'Registrations',
    factoring: 'Factoring Company',
    notifications: 'Platform Notifications',
  };
  public tabItems: MenuItem[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private clientToken: ClientTokenService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.setBreadcrumb(this.tabs.system);
    this.setTabItems();
  }

  private setBreadcrumb(path: string): void {
    this.breadcrumbService.setItems('admin', path);
  }

  private setTabItems(): void {
    this.tabItems = [
      {
        label: this.tabs.system,
        routerLink: 'system-defaults',
        visible: this.clientToken.hasPermission('SystemSettingEdit'),
      },
      {
        label: this.tabs.tenants,
        routerLink: 'tenants',
        visible: this.clientToken.hasPermission('TenantEditAll'),
      },
      {
        label: this.tabs.registrations,
        routerLink: 'registrations',
        visible: this.clientToken.hasPermission('SystemSettingEdit'),
      },
      {
        label: this.tabs.factoring,
        routerLink: 'factoring-company',
        visible: this.clientToken.hasPermission('SystemSettingEdit'),
      },
      {
        label: this.tabs.notifications,
        routerLink: 'platform-notifications',
        visible: this.clientToken.hasPermission('PlatformNotificationsEdit'),
      },
    ];

    this.tabItems.forEach((tab) => {
      tab.command = (event) => {
        this.setBreadcrumb(event.item.label);
      };
    });
  }
}
