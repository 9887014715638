/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TenantSummaryDTO } from '../models/tenant-summary-dto';
import { TenantDTO } from '../models/tenant-dto';
import { NoteDTO } from '../models/note-dto';
@Injectable({
  providedIn: 'root',
})
class AllTenantService extends __BaseService {
  static readonly GetTenantsPath = '/api/AllTenant';
  static readonly ExportPath = '/api/AllTenant/export';
  static readonly GetTenantByIdPath = '/api/AllTenant/{id}';
  static readonly PutTenantPath = '/api/AllTenant/{id}';
  static readonly SetTenantStatusPath = '/api/AllTenant/SetStatus/{status}';
  static readonly GetTenantNotesPath = '/api/AllTenant/{id}/notes/{noteType}';
  static readonly SetTenantNotesPath = '/api/AllTenant/{id}/notes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Filtered list of Tenants
   * - Blank or null values will be excluded from filter.
   * @param params The `AllTenantService.GetTenantsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return Success
   */
  GetTenantsResponse(params: AllTenantService.GetTenantsParams): __Observable<__StrictHttpResponse<Array<TenantSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.nameContains != null) __params = __params.set('nameContains', params.nameContains.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantSummaryDTO>>;
      })
    );
  }
  /**
   * Filtered list of Tenants
   * - Blank or null values will be excluded from filter.
   * @param params The `AllTenantService.GetTenantsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return Success
   */
  GetTenants(params: AllTenantService.GetTenantsParams): __Observable<Array<TenantSummaryDTO>> {
    return this.GetTenantsResponse(params).pipe(
      __map(_r => _r.body as Array<TenantSummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /AllTenant
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `AllTenantService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return A CSV file
   */
  ExportResponse(params: AllTenantService.ExportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.nameContains != null) __params = __params.set('nameContains', params.nameContains.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /AllTenant
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `AllTenantService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `statusEquals`:
   *
   * - `nameContains`:
   *
   * - `isDeleted`:
   *
   * @return A CSV file
   */
  Export(params: AllTenantService.ExportParams): __Observable<Blob> {
    return this.ExportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get tenant by Id
   * @param params The `AllTenantService.GetTenantByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantByIdResponse(params: AllTenantService.GetTenantByIdParams): __Observable<__StrictHttpResponse<TenantDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantDTO>;
      })
    );
  }
  /**
   * Get tenant by Id
   * @param params The `AllTenantService.GetTenantByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantById(params: AllTenantService.GetTenantByIdParams): __Observable<TenantDTO> {
    return this.GetTenantByIdResponse(params).pipe(
      __map(_r => _r.body as TenantDTO)
    );
  }

  /**
   * Update a tenant
   * @param params The `AllTenantService.PutTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutTenantResponse(params: AllTenantService.PutTenantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update a tenant
   * @param params The `AllTenantService.PutTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutTenant(params: AllTenantService.PutTenantParams): __Observable<null> {
    return this.PutTenantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Set Tenant Status
   * @param params The `AllTenantService.SetTenantStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  SetTenantStatusResponse(params: AllTenantService.SetTenantStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.id || []).forEach(val => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllTenant/SetStatus/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set Tenant Status
   * @param params The `AllTenantService.SetTenantStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  SetTenantStatus(params: AllTenantService.SetTenantStatusParams): __Observable<null> {
    return this.SetTenantStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get tenant notes by id
   * @param params The `AllTenantService.GetTenantNotesParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantNotesResponse(params: AllTenantService.GetTenantNotesParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}/notes/${encodeURIComponent(String(params.noteType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get tenant notes by id
   * @param params The `AllTenantService.GetTenantNotesParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantNotes(params: AllTenantService.GetTenantNotesParams): __Observable<string> {
    return this.GetTenantNotesResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Set notes for any tenant
   * @param params The `AllTenantService.SetTenantNotesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SetTenantNotesResponse(params: AllTenantService.SetTenantNotesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllTenant/${encodeURIComponent(String(params.id))}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set notes for any tenant
   * @param params The `AllTenantService.SetTenantNotesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SetTenantNotes(params: AllTenantService.SetTenantNotesParams): __Observable<null> {
    return this.SetTenantNotesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AllTenantService {

  /**
   * Parameters for GetTenants
   */
  export interface GetTenantsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    statusEquals?: Array<'Active' | 'Inactive' | 'Deleted'>;
    nameContains?: string;
    isDeleted?: boolean;
  }

  /**
   * Parameters for Export
   */
  export interface ExportParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    statusEquals?: Array<'Active' | 'Inactive' | 'Deleted'>;
    nameContains?: string;
    isDeleted?: boolean;
  }

  /**
   * Parameters for GetTenantById
   */
  export interface GetTenantByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for PutTenant
   */
  export interface PutTenantParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: TenantDTO;
  }

  /**
   * Parameters for SetTenantStatus
   */
  export interface SetTenantStatusParams {
    status: 'Active' | 'Inactive' | 'Deleted';

    /**
     * Bearer {token}
     */
    Authorization: string;
    id?: Array<number>;
  }

  /**
   * Parameters for GetTenantNotes
   */
  export interface GetTenantNotesParams {
    noteType: string;
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SetTenantNotes
   */
  export interface SetTenantNotesParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: NoteDTO;
  }
}

export { AllTenantService }
