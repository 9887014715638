import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadStatus } from './models/load-status';
import { LoadStatusName, Permission } from 'src/app/data/static-data';
import { ClientTokenService } from 'src/services/client-token-service';
import { LoadPageType } from './models/load-page-type';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-load-status-dropdown',
  templateUrl: './load-status-dropdown.component.html',
  styleUrls: ['./load-status-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadStatusDropdownComponent),
      multi: true,
    },
  ],
})
export class LoadStatusDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public filteredLoadStatuses: LoadStatus[];

  private allLoadStatuses: LoadStatus[];
  private canSelectInvoiced: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private clientTokenService: ClientTokenService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      status: [''],
    });

    this.initAllLoadStatuses();
    this.filterStatuses();

    this.formGroup.controls.status.valueChanges
      .pipe(
        tap(() => this.conditionallyDisableLoadStatuses()),
        map(() => this.formGroup.controls.status.value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string): void {
    this.conditionallyEnableInvoiceSelection(value);
    this.formGroup.controls.status.setValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.controls.status.disable() : this.formGroup.controls.status.enable();
  }

  private onChange = (value: string | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private filterStatuses(): void {
    this.filteredLoadStatuses = [...this.allLoadStatuses];
    this.filterStatusesForPermission();
    this.filterStatusesForView();
  }

  private filterStatusesForPermission(): void {
    this.filteredLoadStatuses = this.filteredLoadStatuses.filter((s) =>
      s.permissions.some((p) => this.clientTokenService.hasPermission(p))
    );
  }

  private filterStatusesForView(): void {
    let currentPageType: LoadPageType = LoadPageType.LoadScreen;

    if (this.router.url.includes(LoadPageType.BackOffice)) {
      currentPageType = LoadPageType.BackOffice;
    }

    this.filteredLoadStatuses = this.filteredLoadStatuses.filter((s) => s.availablePages.includes(currentPageType));
  }

  private conditionallyEnableInvoiceSelection(newStatus: string): void {
    // The invoice option should only be selectable if the component was initialized with one of these statuses
    this.canSelectInvoiced =
      newStatus === LoadStatusName.Invoiced ||
      newStatus === LoadStatusName.Paid ||
      newStatus === LoadStatusName.Claim ||
      newStatus === LoadStatusName.Archive;
  }

  private conditionallyDisableLoadStatuses(): void {
    const currentStatus = this.formGroup.controls.status.value;
    switch (currentStatus) {
      case LoadStatusName.Paid:
      case LoadStatusName.Archive:
      case LoadStatusName.Claim:
      case LoadStatusName.Invoiced:
        this.enableInvoicedStatus();
        break;

      default:
        this.disableInvoicedStatus();
        break;
    }
  }

  private enableInvoicedStatus(): void {
    if (this.canSelectInvoiced) {
      this.setDisabledPropertyOnStatusInFilteredLoadStatuses(LoadStatusName.Invoiced, false);
    }
  }

  private disableInvoicedStatus(): void {
    this.setDisabledPropertyOnStatusInFilteredLoadStatuses(LoadStatusName.Invoiced, true);
  }

  private setDisabledPropertyOnStatusInFilteredLoadStatuses(loadStatus: LoadStatusName, isDisabled: boolean): void {
    this.filteredLoadStatuses
      .filter((status) => status.value === loadStatus)
      .map((status) => (status.disabled = isDisabled));
  }

  private initAllLoadStatuses(): void {
    this.allLoadStatuses = [
      new LoadStatus(LoadStatusName.Open, [Permission.LoadEdit], [LoadPageType.LoadScreen]),
      new LoadStatus(LoadStatusName.Assigned, [Permission.LoadEdit], [LoadPageType.LoadScreen]),
      new LoadStatus(LoadStatusName.Dispatched, [Permission.LoadEdit], [LoadPageType.LoadScreen]),
      new LoadStatus(LoadStatusName.InTransit, [Permission.LoadEdit], [LoadPageType.LoadScreen], 'In Transit'),
      new LoadStatus(LoadStatusName.InYard, [Permission.LoadEdit], [LoadPageType.LoadScreen], 'In Yard'),
      new LoadStatus(
        LoadStatusName.Delivered,
        [Permission.LoadEdit, Permission.LoadAccounting],
        [LoadPageType.LoadScreen, LoadPageType.BackOffice]
      ),
      new LoadStatus(LoadStatusName.Audited, [Permission.LoadAccounting], [LoadPageType.BackOffice]),
      new LoadStatus(LoadStatusName.Invoiced, [Permission.LoadAccounting], [LoadPageType.BackOffice]),
      new LoadStatus(LoadStatusName.Paid, [Permission.LoadAccounting], [LoadPageType.BackOffice]),
      new LoadStatus(
        LoadStatusName.Archive,
        [Permission.LoadEdit, Permission.LoadAccounting],
        [LoadPageType.LoadScreen, LoadPageType.BackOffice]
      ),
      new LoadStatus(
        LoadStatusName.Claim,
        [Permission.LoadEdit, Permission.LoadAccounting],
        [LoadPageType.LoadScreen, LoadPageType.BackOffice]
      ),
    ];
  }
}
