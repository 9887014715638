import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ClientTokenService } from 'src/services/client-token-service';
import { PanelContentType } from 'src/app/data/static-data';
import { UserNotificationService } from 'src/apiclient/v1.1/services';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlatformNotificationDTO, UserNotificationsDTO } from 'src/apiclient/v1.1/models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public panelContentType = PanelContentType;
  public totalUnreadNotifications: number;
  public notifications: PlatformNotificationDTO[];

  private destroy$ = new Subject<void>();

  constructor(
    private userNotificationService: UserNotificationService,
    private clientTokenService: ClientTokenService,
    private breadcrumbService: BreadcrumbService
  ) {}

  public ngOnInit(): void {
    this.breadcrumbService.setItems('dashboard');
    this.getUserNotifications();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public notificationPanelOpened(): void {
    this.totalUnreadNotifications = 0;
    this.userNotificationService
      .NotificationsViewed(this.clientTokenService.auth())
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private getUserNotifications() {
    this.userNotificationService
      .GetAllNotifications(this.clientTokenService.auth())
      .pipe(
        tap((result) => this.setUserNotifications(result)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setUserNotifications(userNotification: UserNotificationsDTO): void {
    this.totalUnreadNotifications = userNotification.unReadNotifications.length;
    this.notifications = this.sortUserNotifications([
      ...userNotification.readNotifications,
      ...userNotification.unReadNotifications,
    ]);
  }

  private sortUserNotifications(notifications: PlatformNotificationDTO[]): PlatformNotificationDTO[] {
    notifications.sort((a, b) => (new Date(a.startDate).valueOf() > new Date(b.startDate).valueOf() ? -1 : 1));
    return notifications;
  }
}
