import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public static readonly environment = environment;

  public static isProd(): boolean {
    return this.environment.envName === 'prod';
  }
}
