/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyDTO } from '../models/company-dto';
import { CompanySummaryDTO } from '../models/company-summary-dto';
import { Operation } from '../models/operation';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { CompanyImportParseResultDTO } from '../models/company-import-parse-result-dto';
import { CompanyImportCsvDTO } from '../models/company-import-csv-dto';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly GetPath = '/api/Company';
  static readonly PostPath = '/api/Company';
  static readonly GetAllPath = '/api/Company/all';
  static readonly ExportAllPath = '/api/Company/export/all';
  static readonly GetBillToPath = '/api/Company/billto';
  static readonly ExportBillToPath = '/api/Company/export/billto';
  static readonly GetShipperPath = '/api/Company/shipper';
  static readonly ExportShipperPath = '/api/Company/export/shipper';
  static readonly GetConsigneePath = '/api/Company/consignee';
  static readonly ExportConsigneePath = '/api/Company/export/consignee';
  static readonly GetCompanyByIdPath = '/api/Company/{id}';
  static readonly PutPath = '/api/Company/{id}';
  static readonly PatchPath = '/api/Company/{id}';
  static readonly DeletePath = '/api/Company/{id}';
  static readonly SearchPath = '/api/Company/search/{term}';
  static readonly ParsePath = '/api/Company/Parse';
  static readonly ImportPath = '/api/Company/Import';
  static readonly ImportTemplatePath = '/api/Company/ImportTemplate';
  static readonly ImportTemplateModelPath = '/api/Company/ImportTemplateModel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of all company record. This is the MASTER record only, meaning no address/location information is returned.
   * use /all to get the company list with the first address
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanyDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyDTO>>;
      })
    );
  }
  /**
   * Summary list of all company record. This is the MASTER record only, meaning no address/location information is returned.
   * use /all to get the company list with the first address
   * @param Authorization Bearer {token}
   * @return Success
   */
  Get(Authorization: string): __Observable<Array<CompanyDTO>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanyDTO>)
    );
  }

  /**
   * Create a new company
   * @param params The `CompanyService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Company created or Multi-status (partial success)
   */
  PostResponse(params: CompanyService.PostParams): __Observable<__StrictHttpResponse<CompanyDTO | CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO | CompanyDTO>;
      })
    );
  }
  /**
   * Create a new company
   * @param params The `CompanyService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Company created or Multi-status (partial success)
   */
  Post(params: CompanyService.PostParams): __Observable<CompanyDTO | CompanyDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO | CompanyDTO)
    );
  }

  /**
   * Summary list of ALL companies.
   * Returns address/contact info from one of the following in precedence order: BillTo, Shipper, Consignee
   * Type and CompanyAddressId will contain info on which address was used. Master indicates that no addresses were found
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetAllResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of ALL companies.
   * Returns address/contact info from one of the following in precedence order: BillTo, Shipper, Consignee
   * Type and CompanyAddressId will contain info on which address was used. Master indicates that no addresses were found
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetAll(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.GetAllResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /all
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportAllResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /all
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportAll(Authorization: string): __Observable<Blob> {
    return this.ExportAllResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of billto companies.
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetBillToResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/billto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of billto companies.
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetBillTo(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.GetBillToResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /billto
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportBillToResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/billto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /billto
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportBillTo(Authorization: string): __Observable<Blob> {
    return this.ExportBillToResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of shipper companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetShipperResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/shipper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of shipper companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetShipper(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.GetShipperResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /shipper
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportShipperResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/shipper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /shipper
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportShipper(Authorization: string): __Observable<Blob> {
    return this.ExportShipperResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Summary list of consignee companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetConsigneeResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanySummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/consignee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanySummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of consignee companies
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetConsignee(Authorization: string): __Observable<Array<CompanySummaryDTO>> {
    return this.GetConsigneeResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanySummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /consignee
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportConsigneeResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/export/consignee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /consignee
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ExportConsignee(Authorization: string): __Observable<Blob> {
    return this.ExportConsigneeResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Company detail
   * @param params The `CompanyService.GetCompanyByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetCompanyByIdResponse(params: CompanyService.GetCompanyByIdParams): __Observable<__StrictHttpResponse<CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO>;
      })
    );
  }
  /**
   * Company detail
   * @param params The `CompanyService.GetCompanyByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetCompanyById(params: CompanyService.GetCompanyByIdParams): __Observable<CompanyDTO> {
    return this.GetCompanyByIdResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO)
    );
  }

  /**
   * Update an existing company
   * @param params The `CompanyService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Multi-status (partial success)
   */
  PutResponse(params: CompanyService.PutParams): __Observable<__StrictHttpResponse<CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO>;
      })
    );
  }
  /**
   * Update an existing company
   * @param params The `CompanyService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Multi-status (partial success)
   */
  Put(params: CompanyService.PutParams): __Observable<CompanyDTO> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO)
    );
  }

  /**
   * Update one or more properties of an existing Company
   * @param params The `CompanyService.PatchParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Multi-status (partial success)
   */
  PatchResponse(params: CompanyService.PatchParams): __Observable<__StrictHttpResponse<CompanyDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDTO>;
      })
    );
  }
  /**
   * Update one or more properties of an existing Company
   * @param params The `CompanyService.PatchParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Multi-status (partial success)
   */
  Patch(params: CompanyService.PatchParams): __Observable<CompanyDTO> {
    return this.PatchResponse(params).pipe(
      __map(_r => _r.body as CompanyDTO)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `CompanyService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteResponse(params: CompanyService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `CompanyService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  Delete(params: CompanyService.DeleteParams): __Observable<null> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Searches for companies by name. Returns CompanyId and Name
   * @param params The `CompanyService.SearchParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  SearchResponse(params: CompanyService.SearchParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/search/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for companies by name. Returns CompanyId and Name
   * @param params The `CompanyService.SearchParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  Search(params: CompanyService.SearchParams): __Observable<Array<IdNamePairDTO>> {
    return this.SearchResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Parse CSV of companies
   * @param params The `CompanyService.ParseParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   *
   * @return Success
   */
  ParseResponse(params: CompanyService.ParseParams): __Observable<__StrictHttpResponse<Array<CompanyImportParseResultDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/Parse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyImportParseResultDTO>>;
      })
    );
  }
  /**
   * Parse CSV of companies
   * @param params The `CompanyService.ParseParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   *
   * @return Success
   */
  Parse(params: CompanyService.ParseParams): __Observable<Array<CompanyImportParseResultDTO>> {
    return this.ParseResponse(params).pipe(
      __map(_r => _r.body as Array<CompanyImportParseResultDTO>)
    );
  }

  /**
   * Import parsed companies
   * @param params The `CompanyService.ImportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  ImportResponse(params: CompanyService.ImportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company/Import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Import parsed companies
   * @param params The `CompanyService.ImportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Import(params: CompanyService.ImportParams): __Observable<null> {
    return this.ImportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns CSV file template for the import operation
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ImportTemplateResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/ImportTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file template for the import operation
   * @param Authorization Bearer {token}
   * @return A CSV file
   */
  ImportTemplate(Authorization: string): __Observable<Blob> {
    return this.ImportTemplateResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * DO NOT USE - this is simply a way to show the csv template validation in swagger
   * @param params The `CompanyService.ImportTemplateModelParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  ImportTemplateModelResponse(params: CompanyService.ImportTemplateModelParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/ImportTemplateModel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * DO NOT USE - this is simply a way to show the csv template validation in swagger
   * @param params The `CompanyService.ImportTemplateModelParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  ImportTemplateModel(params: CompanyService.ImportTemplateModelParams): __Observable<null> {
    return this.ImportTemplateModelResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CompanyService {

  /**
   * Parameters for Post
   */
  export interface PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CompanyDTO;
  }

  /**
   * Parameters for GetCompanyById
   */
  export interface GetCompanyByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CompanyDTO;
  }

  /**
   * Parameters for Patch
   */
  export interface PatchParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<Operation>;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Search
   */
  export interface SearchParams {
    term: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Parse
   */
  export interface ParseParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for Import
   */
  export interface ImportParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<CompanyImportCsvDTO>;
  }

  /**
   * Parameters for ImportTemplateModel
   */
  export interface ImportTemplateModelParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CompanyImportCsvDTO;
  }
}

export { CompanyService }
