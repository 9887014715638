export enum LoadRateProperties {
  Rate = 'rate',
  TotalRate = 'totalRate',
  RateUnits = 'rateUnits',
  FuelSurchargePercent = 'fuelSurchargePercent',
  FuelSurcharge = 'fuelSurcharge',
  FuelSurchargeAmount = 'fuelSurchargeAmount',
  FuelSurchargeUnits = 'fuelSurchargeUnits',
  OtherCharges = 'otherCharges',
  OtherChargesTotal = 'otherChargesTotal',
  Advances = 'advances',
  AdvancesTotal = 'advancesTotal',
  ShowFuelSurchargeAsPercentage = 'showFuelSurchargeAsPercentage',
}
