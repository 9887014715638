/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RegisterTenantDTO } from '../models/register-tenant-dto';
@Injectable({
  providedIn: 'root',
})
class RegisterService extends __BaseService {
  static readonly ApiRegisterPostPath = '/api/Register';
  static readonly ApiRegisterResendConfirmationCodePostPath = '/api/Register/ResendConfirmationCode';
  static readonly ApiRegisterEmailExistsByEmailGetPath = '/api/Register/EmailExists/{email}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Register a new tenant, Email/Password are used to create the first Admin user
   * @param dto undefined
   */
  ApiRegisterPostResponse(dto?: RegisterTenantDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register a new tenant, Email/Password are used to create the first Admin user
   * @param dto undefined
   */
  ApiRegisterPost(dto?: RegisterTenantDTO): __Observable<null> {
    return this.ApiRegisterPostResponse(dto).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resends the email confirmation code.
   * @param username undefined
   */
  ApiRegisterResendConfirmationCodePostResponse(username?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (username != null) __params = __params.set('username', username.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Register/ResendConfirmationCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resends the email confirmation code.
   * @param username undefined
   */
  ApiRegisterResendConfirmationCodePost(username?: string): __Observable<null> {
    return this.ApiRegisterResendConfirmationCodePostResponse(username).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Checks to see if an email has already been registered.
   * @param email undefined
   * @return Success
   */
  ApiRegisterEmailExistsByEmailGetResponse(email: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Register/EmailExists/${encodeURIComponent(String(email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Checks to see if an email has already been registered.
   * @param email undefined
   * @return Success
   */
  ApiRegisterEmailExistsByEmailGet(email: string): __Observable<boolean> {
    return this.ApiRegisterEmailExistsByEmailGetResponse(email).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module RegisterService {
}

export { RegisterService }
