import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { LoadLegJournalDTO } from 'src/apiclient/v1.1/models';
import { LoadJournalService } from 'src/apiclient/v1.1/services';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { State } from 'src/services/states-service';

@Component({
  selector: 'app-update-leg-tracking-dialog',
  templateUrl: './update-leg-tracking-dialog.component.html',
  styleUrls: ['./update-leg-tracking-dialog.component.scss'],
})
export class UpdateLegTrackingDialogComponent extends DtoFormBase<LoadLegJournalDTO> implements OnInit {
  @Input() showDialog = true;
  @Input() loadLegId: number;
  @Input() loadId: number;
  @Output() showDialogChange = new EventEmitter<boolean>();
  @Output() journalChanged = new EventEmitter<LoadLegJournalDTO>();
  public disabledSave: boolean = false;
  public readonly statusValues = this.getStatusValues();
  public readonly validators = this.getValidators();
  private destroy$ = new Subject<void>();

  constructor(protected injector: Injector, private loadJournalService: LoadJournalService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('LoadLegJournalDTO');
  }

  public onStateChange(event: State): void {
    this.formGroup.controls.state.setValue(event.abbr);
  }

  public statusChanged(): void {
    this.formErrors['status'] = '';
  }

  public close(): void {
    this.showDialog = false;
    // Timeout used to account for time of close animation.
    setTimeout(() => this.showDialogChange.emit(this.showDialog), 100);
  }

  public save(): void {
    const journal = this.getJournal();
    const isStateInvalid = !journal.state;
    if (!this.formValid() || isStateInvalid) {
      if (isStateInvalid) {
        this.formErrors['state'] = 'State is required.';
      }
      this.formGroup.markAllAsTouched();
      return;
    }

    this.saveTrackingJournalEntry(journal).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getJournal(): LoadLegJournalDTO {
    const formValue: LoadLegJournalDTO = this.formGroup.value;
    const claims = this.clientToken.getClaims();
    return {
      city: formValue.city,
      state: formValue.state,
      status: formValue.status,
      notes: formValue.notes,
      createdBy: {
        id: claims.userId,
        name: claims.fullName,
      },
      captureMethod: 'Dispatcher',
      loadId: this.loadId,
    };
  }

  private saveTrackingJournalEntry(journal: LoadLegJournalDTO): Observable<void> {
    this.disabledSave = true;
    return this.loadJournalService
      .PostLoadLegJournal({
        legId: this.loadLegId,
        Authorization: this.clientToken.auth(),
        body: journal,
      })
      .pipe(
        tap(() => {
          this.close();
          this.journalChanged.emit(journal);
        }),
        map(() => null),
        catchError((error) => this.handleError(error)),
        finalize(() => (this.disabledSave = false))
      );
  }

  private getStatusValues(): { label: string; value: string }[] {
    return [
      { label: 'On time', value: 'OnTime' },
      { label: 'Delayed', value: 'Delayed' },
    ];
  }

  private getValidators() {
    return {
      city: { required: { isRequired: true, message: 'City is required.' } },
      status: { required: { isRequired: true, message: 'Tracking status is required.' } },
    };
  }
}
