<p-accordion [formGroup]="formGroup" [multiple]="true" class="accordion-pad3">
  <p-accordionTab *ngFor="let stop of stops; let idx = index">
    <p-header pDraggable="loadstop" dragEffect="move" (onDragStart)="dragAStop($event, stop)">
      <span class="grid grid-nogutter flex-row inline p-0 col-12 absolute ml-2">
        <div class="grid grid-nogutter col-12 p-0">
          <div class="col-1 p-0">
            <b>{{ stop.stopType | titleCase }} {{ getStopTypeCount(stop) }}</b>
          </div>
          <div class="col-6 p-0 stop-name">
            {{ stop.companyAddress.name }}
          </div>
          <div class="col-2 p-0">
            {{ stop.scheduledTime ? formatScheduledTimeDate(stop.scheduledTime) : '&nbsp;' }}
          </div>
          <div class="col-1 p-0">
            {{ stop.scheduledTime ? formatScheduledTimeTime(stop.scheduledTime) : '&nbsp;' }}
          </div>
          <span class="col-1 p-0 trashcan ui-right">
            <i
              class="fas fa-trash-alt cursor-pointer"
              appClickPreventDefault
              appClickStopPropogation
              (click)="removeStop(stop)"
              *ngIf="!readonly"
            ></i>
          </span>
        </div>
      </span>
    </p-header>

    <app-stop
      [formGroup]="formGroup"
      [stop]="stop"
      [stops]="stops"
      [index]="idx"
      (stopChanged)="stopChanged($event, idx)"
      (sortStops)="sortStops()"
      [readonly]="readonly"
    >
    </app-stop>
  </p-accordionTab>
</p-accordion>

<div class="col-12 p-0" *ngIf="!readonly">
  <div class="col-12 p-2 mt-1 cursor-pointer text-center additembox" (click)="addStop()">
    <i class="fa fa-plus"></i>
    Add a Pick/Drop
  </div>
</div>
