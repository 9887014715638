import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyComponent),
      multi: true,
    },
  ],
})
export class CurrencyComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() alignTextRight: boolean = false;
  @Input() showCurrency: boolean = true;
  @Input() label: string;
  @Input() boldLabel: boolean = true;
  @Input() labelClass: string;
  @Input() controlClass: string = 'col-12 p-0';
  @Input() errorClass: string = 'col-12';
  @Input() inputClass: string = '';
  @Input() formControlName: string;
  @Input() formErrors: object;
  @Input() set value(value: number) {
    this.writeValue(value);
  }
  @Input() set touched(value: boolean) {
    if (value) {
      this.currencyControl.markAsTouched();
    }
  }
  @Input() set dirty(value: boolean) {
    if (value) {
      this.currencyControl.markAsDirty();
    }
  }
  @Input() set required(required: boolean) {
    required ? this.currencyControl.setValidators(Validators.required) : this.currencyControl.clearValidators();
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    value ? this.currencyControl.disable() : this.currencyControl.enable();
  }
  get disabled(): boolean {
    return this._disabled;
  }

  public currencyControl = new FormControl('');

  private _disabled: boolean;
  private destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this.currencyControl.valueChanges
      .pipe(
        tap(() => this.currencyControl.value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string | number): void {
    this.currencyControl.setValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (!this.disabled) {
      isDisabled ? this.currencyControl.disable() : this.currencyControl.enable();
    }
  }

  private onChange = (value: string | number | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getFormError() {
    if (this.formErrors && this.currencyControl.touched && this.currencyControl.dirty) {
      return this.formErrors[this.formControlName];
    }
  }
}
