<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<p-dialog
  header="Bulk Import"
  modal="true"
  id="bulkImportUI"
  [closable]="false"
  [(visible)]="showBulkimportUI"
  [closable]="true"
  [style]="{ width: '31.25rem' }"
  [focusOnShow]="false"
  appendTo="body"
>
  <div class="grid grid-nogutter col-12 p-0 overflow-hidden">
    <div class="col-12 p-2" [hidden]="!uploadSuccess">
      <span class=""> Rows added successfully</span>
    </div>
    <div class="grid grid-nogutter col-12 p-0" [hidden]="uploadSuccess">
      <div class="col-12 p-2">
        <p-button
          (onClick)="downloadTemplate()"
          label="Download this template (requires Excel)"
          class="ui-button-link noPadding ui-button-link-import"
        ></p-button>
        <span class=""> and </span>
        <span class="ui-button-link">
          <a class="ui-link" href="/assets/files/Instructions for Import Template.pdf" download>read instructions.</a>
        </span>
      </div>

      <div class="col-12 p-2">Then, import the completed spreadsheet.</div>
      <div class="col-12 p-2">
        <p-fileUpload
          #uploader
          id="fileUploader"
          mode="basic"
          name="file"
          class="fileUploaderBulkImport"
          maxFileSize="1000000"
          chooseLabel="Select file"
          customUpload="true"
          (onSelect)="onFileSelect($event)"
        >
        </p-fileUpload>

        <button
          pButton
          *ngIf="file"
          (click)="uploader.clear(); onFileClear()"
          type="button"
          icon="fas fa-times"
        ></button>
      </div>
    </div>
    <div class="col-12 p-2" *ngIf="showAdditionalErrors">
      <span [innerHTML]="formErrors['file']" class="formerrors"></span>
      <br />
      <span class="formerrors"
        >And
        <p-button
          (onClick)="showBulkimportErrorModal()"
          label="{{ additionalErrors }} more errors."
          class="ui-button-link noPadding ui-button-link-import ui-button-link-import-error"
        >
        </p-button>
        Please fix and retry.
      </span>
    </div>
  </div>

  <p-footer>
    <button
      type="button"
      pButton
      *ngIf="!uploadSuccess"
      icon="fa fa-times"
      (click)="showBulkimportUI = false"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      *ngIf="uploadSuccess"
      icon="fa fa-check"
      (click)="showBulkimportUI = false"
      label="Done"
    ></button>
    <button
      type="button"
      pButton
      *ngIf="!uploadSuccess"
      [disabled]="disableSave"
      icon="fa fa-upload"
      (click)="saveModel()"
      label="Import"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog
  header="Bulk Import Error List"
  modal="true"
  id="bulkImporErrortUI"
  [(visible)]="showBulkimportErrorUI"
  [closable]="true"
  [style]="{ width: '31.25rem' }"
  [focusOnShow]="false"
>
  <div class="grid grid-nogutter col-12 p-0">
    <div class="col-12">
      <textarea class="errorText" [rows]="5" pInputTextarea [(ngModel)]="fullErrorList"></textarea>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa fa-check" (click)="showBulkimportErrorUI = false" label="Done"></button>
  </p-footer>
</p-dialog>
