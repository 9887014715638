import { Component, forwardRef, EventEmitter, Input, Output, Injector, ViewChild, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyService } from '../../../apiclient/services/company.service';
import { IdNamePairDTO } from '../../../apiclient/models';
import { DtoFormBase } from '../../shared/FormBase';
import { AutoComplete } from 'primeng/autocomplete';

/**
 * Reusable component for selecting a company.
 * Handles the search api and finds the name based on id.
 */
@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySelectorComponent),
      multi: true,
    },
  ],
})
export class CompanySelectorComponent extends DtoFormBase<undefined> implements ControlValueAccessor, OnChanges {
  /**
   * This property is bound to the ngModel
   */
  companyId: number;

  /**
   * Auto-Complete properties
   */
  selected: IdNamePairDTO = <IdNamePairDTO>{};
  results: IdNamePairDTO[] = [];
  showErrors: boolean;
  @Input() forceSelection: boolean = true;
  @Input() required: boolean = false;
  @Input() showDropdown: boolean = true;
  @Input() formSubmitted: boolean;

  /**
   * When forceSelection is:
   *    true:  Will emit when user selects an object
   *    false: Will emit when user selects an object or when auto-complete loses focus. In the latter, "id" will be 0, name will contain input text.
   */
  @Output() companyChanged: EventEmitter<IdNamePairDTO> = new EventEmitter<IdNamePairDTO>();

  disabled: boolean = false;

  @ViewChild('autoComplete', { static: true })
  public autoComplete: AutoComplete;

  constructor(private companies: CompanyService, protected injector: Injector) {
    super(injector);
  }

  /**
   * Writes a new companyId to the element.
   * Must query API to get the name.
   */
  writeValue(val: number): void {
    this.companyId = val;
    this.get(val);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  public setNewCompanyName(newCompanyName: string) {
    this.selected = { id: 0, name: newCompanyName, parentId: 0 } as IdNamePairDTO;
    this.companyId = this.selected.id;
    this.modelChanged();
  }

  public onSelect(): void {
    if (this.selected !== undefined || this.selected != null) {
      if (this.companyId !== this.selected.id) {
        this.companyId = this.selected.id;
        this.modelChanged();
      }
    }
  }

  public onEdited() {
    // Control lost focus
    if (!this.forceSelection) {
      // Selection is not required
      if (typeof this.selected === 'string') {
        // When this.selected is a string, user has not selected a valid object so we must emit an event

        // Selecting and tabbing out does not register OnSelect so we check if company name exists
        let tmp: IdNamePairDTO;
        if (this.results != null) {
          tmp = this.results.find((x) => {
            return x.name.toLowerCase() === (this.selected as string).toLowerCase();
          });
        }

        if (tmp !== undefined) {
          this.selected = tmp;
        } else {
          // Create a fake selected record to emit
          this.selected = { id: 0, name: this.selected, parentId: 0 } as IdNamePairDTO;
        }

        // Emit the change
        this.companyId = this.selected.id;
        this.modelChanged();
      } else {
        this.showValidations();
        // When this.selected is an object, user selected a valid object so onSelect already fired a change event.
      }
    } else {
      // Selection is required, p-autocomplete will automatically call OnClear
    }
  }

  public onClear() {
    this.selected = {} as IdNamePairDTO;
    if (this.companyId != null) {
      this.companyId = null;
      this.modelChanged();
    }
  }

  get(id: number) {
    if (id > 0) {
      this.companies.ApiCompanyByIdGet({ id: id, Authorization: this.clientToken.auth() }).subscribe((data) => {
        const tmp = <IdNamePairDTO>{};
        tmp.id = data.companyId;
        tmp.name = data.name;

        this.companyId = tmp.id;
        this.selected = tmp;
        this.results = [tmp];
      }, this.handleError);
    } else {
      this.companyId = null;
      this.selected = <IdNamePairDTO>{};
    }
  }

  search(event) {
    this.showErrors = false;
    const term = event.query === '' ? '*' : event.query;
    this.companies
      .ApiCompanySearchByTermGet({ term: term, Authorization: this.clientToken.auth() })
      .subscribe((data) => (this.results = <IdNamePairDTO[]>data), this.handleError);
  }

  resetForm() {
    this.formGroup.reset();
    this.showErrors = false;
  }

  private modelChanged() {
    this.propagateChange(this.companyId);
    this.companyChanged.emit(this.selected);
  }
  private showValidations() {
    if (Object.keys(this.selected).length === 0) {
      this.showErrors = true;
    }
  }
  ngOnChanges(changes) {
    if (changes.formSubmitted && changes.formSubmitted.currentValue === true) {
      this.showValidations();
    } else {
      this.showErrors = false;
    }
  }
}
