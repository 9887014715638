<form [formGroup]="formGroup">
  <div class="grid grid-nogutter col-12">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="lg:col-6 sm:col-12 p-0">
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="Location name"
          formControlName="name"
          [(ngModel)]="model.name"
          [formErrors]="formErrors"
          placeholder="Location"
          labelClass="lg:col-4 sm:col-12 p-2"
          controlClass="lg:col-8 sm:col-12"
        >
        </app-form-field>
        <div class="grid grid-nogutter col-12 p-0">
          <div class="lg:col-4 sm:col-12 p-2 font-bold"> Address </div>
          <div class="lg:col-8 sm:col-12 p-0 address-section px-1">
            <app-address
              class="col-12 p-0"
              [(formGroup)]="formGroup"
              [collapsed]="false"
              [prefix]="'address'"
              [(Address)]="model.address"
              [(formErrors)]="formErrors"
              [(validationMessages)]="validationMessages"
              [formSubmitted]="formSubmitted"
              [includeCanadian]="true"
              (locationChanged)="onLocationChanged($event)"
              (doneUpdates)="onDoneUpdates()"
            >
            </app-address>
            <div>
              <div #target></div>
              <app-address-matching-overlay
                *ngIf="parentVisible && (factoringStatus$ | async)"
                [headerText]="'Bill To Suggestions'"
                [inputAddress]="getAddress()"
                [target]="target"
                [visible]="parentVisible"
                (suggestionSelected)="suggestedAddressSelected($event)"
              ></app-address-matching-overlay>
            </div>
          </div>
        </div>
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="MC #"
          [tooltip]="formGroup.get('mcNumber').disabled ? debtorFieldTooltip : ''"
          formControlName="mcNumber"
          [(ngModel)]="model.mcNumber"
          [formErrors]="formErrors"
          placeholder="MC Number"
          labelClass="lg:col-4 sm:col-12 p-2"
          controlClass="lg:col-8 sm:col-12"
        >
        </app-form-field>
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="DOT #"
          [tooltip]="formGroup.get('dotNumber').disabled ? debtorFieldTooltip : ''"
          formControlName="dotNumber"
          [(ngModel)]="model.dotNumber"
          [formErrors]="formErrors"
          placeholder="DOT Number"
          labelClass="lg:col-4 sm:col-12 p-2"
          controlClass="lg:col-8 sm:col-12"
        >
        </app-form-field>
      </div>
      <div class="lg:col-6 sm:col-12 p-0">
        <app-form-field
          class="col-12 p-0"
          [(formGroup)]="formGroup"
          label="Primary contact"
          type="check"
          [config]="{ checkLabel: 'Same as company contact' }"
          formControlName="primaryContactIsSame"
          [(ngModel)]="model.primaryContactIsSame"
          [formErrors]="formErrors"
          (checkChanged)="primaryContactIsSameChanged()"
          placeholder="Required"
          labelClass="lg:col-4 sm:col-12 p-2"
          controlClass="lg:col-8 sm:col-12 p-2"
        >
        </app-form-field>
        <div class="lg:col-8 lg:col-offset-4 sm:col-12 p-0">
          <app-contact [(ngModel)]="model.primaryContact" formControlName="primaryContact"> </app-contact>
        </div>
      </div>
    </div>
    <hr />
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-12 p-0">
        <app-form-field
          [(formGroup)]="formGroup"
          (checkChanged)="addBillToToggled($event)"
          label=""
          [tooltip]="formGroup.get('isBillTo').disabled ? debtorFieldTooltip : ''"
          type="check"
          [config]="{ checkLabel: 'Add bill to' }"
          formControlName="isBillTo"
          [(ngModel)]="model.isBillTo"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="nolabel"
          controlClass="lg:col-8 sm:col-12 p-2"
        >
        </app-form-field>
      </div>
      <app-company-address-matching
        *ngIf="checkingForMatchingCompanyAddressRecords"
        id="company-address-matching-component"
        [address]="model.address"
        (noMatchesFound)="noCompanyAddressMatchesFound()"
        (actionTaken)="companyAddressMatchingActionTaken($event)"
        (matchSelected)="companyAddressMatchSelected($event)"
      ></app-company-address-matching>
      <div class="grid grid-nogutter col-12" *ngIf="billToFormVisible() | async" id="bill-to-form">
        <div class="lg:col-6 sm:col-12 p-0">
          <app-form-field
            [(formGroup)]="formGroup"
            label="Billing contact"
            type="check"
            [config]="{ checkLabel: 'Same as primary contact' }"
            formControlName="billToContactIsSame"
            [(ngModel)]="model.billToContactIsSame"
            [formErrors]="formErrors"
            (checkChanged)="billToContactIsSameChanged()"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-2"
          >
          </app-form-field>

          <div class="lg:col-8 lg:col-offset-4 sm:col-12 p-1">
            <app-contact
              [(ngModel)]="model.billToContact"
              formControlName="billToContact"
              [enabledEmailAddress]="isBillToEmailAddressEnabled"
              [emailAddressDisabledTooltip]="debtorFieldTooltip"
            >
            </app-contact>
          </div>
        </div>
        <div class="lg:col-6 sm:col-12 p-0">
          <div *ngIf="!creditLimitAccess" class="grid grid-nogutter col-12 p-0">
            <div class="lg:col-4 sm:col-12 p-2">
              <app-form-field
                [(formGroup)]="formGroup"
                label=""
                type="check"
                [config]="{ checkLabel: 'Add credit limit' }"
                formControlName="creditLimitCheck"
                [(ngModel)]="creditLimitCheck"
                [formErrors]="formErrors"
                placeholder=""
                labelClass="nolabel"
                controlClass="col-12"
                (checkChanged)="checkCreditLimit()"
              >
              </app-form-field>
            </div>
            <div class="lg:col-8 sm:col-12 p-0">
              <app-form-field
                *ngIf="creditLimitCheck"
                [(formGroup)]="formGroup"
                label=""
                [config]="{ textIcon: '$' }"
                formControlName="creditLimit"
                [(ngModel)]="creditLimit"
                [formErrors]="formErrors"
                placeholder=""
                labelClass="nolabel"
                keyFilter="currency"
                controlClass="col-12"
                (inputChanged)="checkCreditLimit()"
                (inputBlur)="checkCreditLimitInput()"
                [attr.autofocus]="creditLimitCheck ? '' : null"
              >
              </app-form-field>
              <div class="pl-1 formerrors" style="display: inline-block" *ngIf="showError && creditLimitCheck"
                >Credit Limit is required</div
              >
            </div>
          </div>
          <div class="col-12 p-0">
            <app-form-field
              *ngIf="paymentTermsLoaded"
              [(formGroup)]="formGroup"
              label="Payment terms"
              type="select"
              [config]="{ selectOptions: paymentTermsData }"
              formControlName="billToPaymentTerms"
              [(ngModel)]="model.billToPaymentTerms"
              [formErrors]="formErrors"
              placeholder=""
              labelClass="lg:col-4 sm:col-12 p-2"
              controlClass="lg:col-8 sm:col-12 p-1"
            >
            </app-form-field>
            <app-form-field
              [(formGroup)]="formGroup"
              label="Billing/service notes"
              secondaryLabel="Internal use only."
              type="textarea"
              formControlName="billToNotesInternal"
              [(ngModel)]="model.billToNotesInternal"
              [formErrors]="formErrors"
              placeholder="Required"
              labelClass="lg:col-4 sm:col-12 p-2"
              controlClass="lg:col-8 sm:col-12 p-1"
            >
            </app-form-field>
            <app-form-field
              [(formGroup)]="formGroup"
              label="Invoice notes"
              secondaryLabel="Shown on invoices."
              type="textarea"
              formControlName="billToNotesReports"
              [(ngModel)]="model.billToNotesReports"
              [formErrors]="formErrors"
              placeholder="Required"
              labelClass="lg:col-4 sm:col-12 p-2"
              controlClass="lg:col-8 sm:col-12 p-1"
            >
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-12 p-0">
        <app-form-field
          [(formGroup)]="formGroup"
          label=""
          type="check"
          [config]="{ checkLabel: 'Add shipper' }"
          formControlName="isShipper"
          [(ngModel)]="model.isShipper"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="nolabel"
          controlClass="lg:col-8 sm:col-12 p-2"
        >
        </app-form-field>
      </div>
      <div class="grid grid-nogutter col-12 p-0" *ngIf="model.isShipper">
        <div class="lg:col-6 sm:col-12 p-0">
          <app-form-field
            [(formGroup)]="formGroup"
            label="Shipping contact"
            type="check"
            [config]="{ checkLabel: 'Same as primary contact' }"
            formControlName="shipperContactIsSame"
            [(ngModel)]="model.shipperContactIsSame"
            [formErrors]="formErrors"
            (checkChanged)="shipperContactIsSameChanged()"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-2"
          >
          </app-form-field>
          <div class="lg:col-8 lg:col-offset-4 sm:col-12 p-1">
            <app-contact [(ngModel)]="model.shipperContact" formControlName="shipperContact"> </app-contact>
          </div>
        </div>
        <div class="lg:col-6 sm:col-12 p-0">
          <div class="grid grid-nogutter col-12 p-0">
            <div class="lg:col-4 sm:col-12 font-bold p-2"> Appointment </div>
            <div class="lg:col-8 sm:col-12 p-0">
              <div class="grid grid-nogutter col-12">
                <div class="lg:col-6 sm:col-12 p-0">
                  <p-radioButton
                    name="shipperAppointmentRequired"
                    value="true"
                    label="Required"
                    formControlName="shipperAppointmentRequired"
                    [(ngModel)]="shipperAppointmentRequired"
                    (onClick)="appointmentChanged()"
                  >
                  </p-radioButton>
                </div>
                <div class="lg:col-6 sm:col-12 p-0">
                  <p-radioButton
                    name="shipperAppointmentRequired"
                    value="false"
                    label="Not required"
                    formControlName="shipperAppointmentRequired"
                    [(ngModel)]="shipperAppointmentRequired"
                    (onClick)="appointmentChanged()"
                  >
                  </p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter col-12 p-0">
            <div class="lg:col-4 sm:col-12 p-2 font-bold"> Shipping hours </div>
            <div class="lg:col-8 sm:col-12 p-0">
              <app-operating-hours [(ngModel)]="model.shipperOperatingHours" formControlName="shipperOperatingHours">
              </app-operating-hours>
            </div>
          </div>
          <app-form-field
            class="col-12 p-0"
            [(formGroup)]="formGroup"
            label="Shipping notes"
            secondaryLabel="Internal use only."
            type="textarea"
            formControlName="shipperNotesInternal"
            [(ngModel)]="model.shipperNotesInternal"
            [formErrors]="formErrors"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-1"
          >
          </app-form-field>
          <app-form-field
            class="col-12 p-0"
            [(formGroup)]="formGroup"
            label="Shipping notes"
            secondaryLabel="Shown on dispatch sheets."
            type="textarea"
            formControlName="shipperNotesReports"
            [(ngModel)]="model.shipperNotesReports"
            [formErrors]="formErrors"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-1"
          >
          </app-form-field>
        </div>
      </div>
    </div>
    <hr />
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-12 p-0">
        <app-form-field
          [(formGroup)]="formGroup"
          label=""
          type="check"
          [config]="{ checkLabel: 'Add consignee' }"
          formControlName="isConsignee"
          [(ngModel)]="model.isConsignee"
          [formErrors]="formErrors"
          placeholder="Required"
          labelClass="nolabel"
          controlClass="lg:col-8 sm:col-12 p-2"
        >
        </app-form-field>
      </div>
      <div class="grid grid-nogutter col-12 p-0" *ngIf="model.isConsignee">
        <div class="lg:col-6 sm:col-12 p-0">
          <app-form-field
            [(formGroup)]="formGroup"
            label="Consignee contact"
            type="check"
            [config]="{ checkLabel: 'Same as primary contact' }"
            formControlName="consigneeContactIsSame"
            [(ngModel)]="model.consigneeContactIsSame"
            [formErrors]="formErrors"
            (checkChanged)="consigneeContactIsSameChanged()"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-2"
          >
          </app-form-field>
          <div class="lg:col-8 lg:col-offset-4 sm:col-12 p-1">
            <app-contact [(ngModel)]="model.consigneeContact" formControlName="consigneeContact"> </app-contact>
          </div>
        </div>
        <div class="lg:col-6 sm:col-12 p-0">
          <div class="grid grid-nogutter col-12 p-0">
            <div class="lg:col-4 sm:col-12 font-bold p-2"> Appointment </div>
            <div class="lg:col-8 sm:col-12 p-0">
              <div class="grid grid-nogutter col-12">
                <div class="lg:col-6 sm:col-12 p-0">
                  <p-radioButton
                    name="consigneeAppointmentRequired"
                    value="true"
                    label="Required"
                    formControlName="consigneeAppointmentRequired"
                    [(ngModel)]="consigneeAppointmentRequired"
                    (onClick)="appointmentChanged()"
                  >
                  </p-radioButton>
                </div>
                <div class="lg:col-6 sm:col-12 p-0">
                  <p-radioButton
                    name="consigneeAppointmentRequired"
                    value="false"
                    label="Not required"
                    formControlName="consigneeAppointmentRequired"
                    [(ngModel)]="consigneeAppointmentRequired"
                    (onClick)="appointmentChanged()"
                  >
                  </p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter col-12 p-0">
            <div class="lg:col-4 sm:col-12 p-2 font-bold"> Receiving hours </div>
            <div class="lg:col-8 sm:col-12 p-0">
              <app-operating-hours
                [(ngModel)]="model.consigneeOperatingHours"
                formControlName="consigneeOperatingHours"
              >
              </app-operating-hours>
            </div>
          </div>
          <app-form-field
            class="col-12 p-0"
            [(formGroup)]="formGroup"
            label="Consignee notes"
            secondaryLabel="Internal use only."
            type="textarea"
            formControlName="consigneeNotesInternal"
            [(ngModel)]="model.consigneeNotesInternal"
            [formErrors]="formErrors"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-1"
          >
          </app-form-field>
          <app-form-field
            class="col-12 p-0"
            [(formGroup)]="formGroup"
            label="Consignee notes"
            secondaryLabel="Shown on dispatch sheets."
            type="textarea"
            formControlName="consigneeNotesReports"
            [(ngModel)]="model.consigneeNotesReports"
            [formErrors]="formErrors"
            placeholder="Required"
            labelClass="lg:col-4 sm:col-12 p-2"
            controlClass="lg:col-8 sm:col-12 p-1"
          >
          </app-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
