import { Component, EventEmitter, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadFilterFormProperties } from './models/load-filter-field-properties';
import { OverlayPanel, SelectItem } from 'primeng-lts';
import { LoadFilter } from './models/load-filter';
import { LoadFilterByLabel } from './models/load-filter-by-label';
import { LoadFilterByValue } from './models/load-filter-by-value';
import { DateRange } from 'src/app/components/date-range-selector/models/date-range';
import { ClientTokenService } from 'src/services/client-token-service';
import { LogisticsLoadFilterByLabel } from './models/logistics-load-filter-by-label';
import { LogisticsLoadFilterByValue } from './models/logistics-load-filter-value';

@Component({
  selector: 'app-load-filter',
  templateUrl: './load-filter.component.html',
  styleUrls: ['./load-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadFilterComponent),
      multi: true,
    },
  ],
})
export class LoadFilterComponent implements OnInit, ControlValueAccessor {
  @ViewChild('filterPanel', { static: true }) filterPanel: OverlayPanel;

  public formGroup: FormGroup;
  public filterByOptions: SelectItem[];
  public logistics: boolean = false;

  public readonly loadFilterFormProperties = LoadFilterFormProperties;
  private onChange: (filter?: LoadFilter) => {};

  constructor(private formBuilder: FormBuilder, private clientTokenService: ClientTokenService) {}

  public ngOnInit(): void {
    const dateRange: DateRange = {
      startDate: null,
      endDate: null,
    };
    this.formGroup = this.formBuilder.group({
      [LoadFilterFormProperties.FilterBy]: [],
      [LoadFilterFormProperties.ShippingDate]: [dateRange],
      [LoadFilterFormProperties.Contains]: [],
      [LoadFilterFormProperties.DeliveryDate]: [dateRange],
      [LoadFilterFormProperties.WorkOrder]: [],
      [LoadFilterFormProperties.PONumber]: [],
      [LoadFilterFormProperties.Hot]: [false],
    });

    this.logistics = this.clientTokenService.isLogisticsTenant();

    this.initOptions();
  }

  public clear() {
    this.formGroup.reset();
    this.onChange();
  }

  public filter() {
    const filter = this.getCurrentFilter();
    this.onChange(filter);
    this.filterPanel.hide();
  }

  public getCurrentFilter(): LoadFilter {
    return {
      filterBy: this.formGroup.controls[LoadFilterFormProperties.FilterBy].value,
      shippingDate: this.formGroup.controls[LoadFilterFormProperties.ShippingDate].value,
      contains: this.formGroup.controls[LoadFilterFormProperties.Contains].value,
      deliveryDate: this.formGroup.controls[LoadFilterFormProperties.DeliveryDate].value,
      workOrder: this.formGroup.controls[LoadFilterFormProperties.WorkOrder].value,
      poNumber: this.formGroup.controls[LoadFilterFormProperties.PONumber].value,
      hot: this.formGroup.controls[LoadFilterFormProperties.Hot].value,
    };
  }

  public getFormattedFilterLabel(): string {
    let appliedFilters = 0;

    const basicFilters = [
      LoadFilterFormProperties.FilterBy,
      LoadFilterFormProperties.Contains,
      LoadFilterFormProperties.WorkOrder,
      LoadFilterFormProperties.PONumber,
      LoadFilterFormProperties.Hot,
    ];

    basicFilters.forEach((property) => {
      if (!!this.formGroup.controls[property].value) {
        appliedFilters++;
      }
    });

    const dateFilters = [LoadFilterFormProperties.ShippingDate, LoadFilterFormProperties.DeliveryDate];
    dateFilters.forEach((property) => {
      const startDate = this.formGroup.controls[property].value?.startDate;
      const endDate = this.formGroup.controls[property].value?.endDate;
      if (!!startDate || !!endDate) {
        appliedFilters++;
      }
    });

    return appliedFilters > 0 ? `Filter (${appliedFilters})` : 'Filter';
  }

  public writeValue(filter: LoadFilter): void {
    if (!filter) {
      return;
    }

    this.formGroup.patchValue(filter);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}

  private initOptions() {
    if (this.logistics) {
      this.filterByOptions = [
        {
          label: LogisticsLoadFilterByLabel.AccountManager,
          value: LogisticsLoadFilterByValue.AccountManager,
        },
        {
          label: LogisticsLoadFilterByLabel.Carrier,
          value: LogisticsLoadFilterByValue.Carrier,
        },
        {
          label: LogisticsLoadFilterByLabel.CarrierAgent,
          value: LogisticsLoadFilterByValue.CarrierAgent,
        },
        {
          label: LoadFilterByLabel.Consignee,
          value: LoadFilterByValue.Consignee,
        },
        {
          label: LoadFilterByLabel.Customer,
          value: LoadFilterByValue.Customer,
        },
        {
          label: LogisticsLoadFilterByLabel.SalesRepresentative,
          value: LogisticsLoadFilterByValue.SalesRepresentative,
        },
        {
          label: LoadFilterByLabel.Shipper,
          value: LoadFilterByValue.Shipper,
        },
      ];
    } else {
      this.filterByOptions = [
        {
          label: LoadFilterByLabel.Dispatcher,
          value: LoadFilterByValue.Dispatcher,
        },
        {
          label: LoadFilterByLabel.Driver,
          value: LoadFilterByValue.Driver,
        },
        {
          label: LoadFilterByLabel.Customer,
          value: LoadFilterByValue.Customer,
        },
        {
          label: LoadFilterByLabel.Shipper,
          value: LoadFilterByValue.Shipper,
        },
        {
          label: LoadFilterByLabel.Consignee,
          value: LoadFilterByValue.Consignee,
        },
      ];
    }
  }
}
