<app-load-edit
  *ngIf="showLoadDialog"
  [loadId]="selectedLoadId"
  [disabled]="disabled"
  [(dialogVisible)]="showLoadDialog"
  (modelEdited)="handleModelEdit($event)"
>
</app-load-edit>

<app-buy-no-buy-dialog *ngIf="showBuyNoBuyDialog" (dialogClosed)="closeBuyNoBuyDialog()"></app-buy-no-buy-dialog>

<p-card styleClass="ui-card-shadow">
  <div class="layout-content-container table-container">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="grid-nogutter col-12 p-2 flex flex-wrap justify-content-between">
        <div class="col-2">
          <h1>Loads</h1>
        </div>
        <div class="col-2 flex flex-wrap justify-content-end align-item-center">
          <p-button
            (onClick)="export()"
            label="Export to Excel"
            icon="fa fa-download"
            iconPos="right"
            class="ml-auto ui-button-secondary actionButtons"
          ></p-button>
        </div>
      </div>

      <app-status-filter class="col-12 p-2 py-0" [statuses]="statuses" [formControl]="statusFormControl">
      </app-status-filter>

      <div class="grid grid-nogutter col-12 p-2 pl-0">
        <div class="grid grid-nogutter col-6 p-0">
          <div class="grid grid-nogutter col-12 p-0">
            <app-bulk-action
              class="col-5 p-0"
              [actionOptions]="actionOptions"
              [disableApply]="disableApply"
              [selectedItems]="getSelectedItems()"
            ></app-bulk-action>
            <app-search
              class="col-4 p-2 pl-0"
              placeholder="Search Loads"
              [formControl]="searchFormControl"
            ></app-search>
            <app-load-filter
              #loadFilter
              [formControl]="filterFormControl"
              class="col-3 p-2 flex align-content-center flex-wrap"
            ></app-load-filter>
          </div>
        </div>
        <div class="col-6 p-0 flex flex-wrap justify-content-end align-items-center">
          <div class="p-0">
            <p-button
              icon="fa fa-check"
              *ngIf="isFactoringEnabledForTenant$ | async"
              label="Buy/No Buy"
              class="ui-button-secondary load-list-button"
              (onClick)="openBuyNoBuyDialog()"
            >
            </p-button>
            <p-button
              icon="fa fa-map-marker-alt"
              label="Track and Trace"
              class="ui-button-secondary load-list-button"
              routerLink="/workplace"
            >
            </p-button>
            <p-button
              (onClick)="openCreateLoadDialog()"
              icon="fa fa-fw fa-plus"
              label="New Load"
              class="ui-button-secondary load-list-button"
            ></p-button>
          </div>
        </div>
      </div>

      <div class="col-12 px-2 py-0">
        <p-table
          [columns]="cols"
          [value]="loads"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="70vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [ngSwitch]="col.format"
                [pSortableColumn]="col.sortable ? col.field : null"
                pResizableColumn
              >
                <ng-container *ngSwitchCase="'checkbox'">
                  <p-checkbox [(ngModel)]="bulkCheckAll" (onChange)="bulkToggleCheckAll()" binary="true"></p-checkbox>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ col.header }}
                  <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              #pfsLoadTable
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>
<div *ngIf="showActionDialog">
  <app-load-action-dialog
    [(showDialog)]="showActionDialog"
    [actionType]="actionType"
    [selectedLoads]="getSelectedLoads()"
    (actionComplete)="handleBulkActionComplete()"
  >
  </app-load-action-dialog>
</div>
