import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss'],
})
export class EditSectionComponent {
  @Input() disableSave: boolean = false;
  @Input() disableEdit: boolean = false;
  @Input() editMode: boolean;
  @Input() offsetClass: string = 'col-offset-4';

  @Output() editModeChange = new EventEmitter<boolean>();
  @Input() title: string;
  @Output() saved = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  public save(): void {
    this.saved.emit();
  }

  public cancel(): void {
    this.cancelled.emit();
  }

  public setEditMode(to: boolean): void {
    if (this.editMode !== to) {
      this.editMode = to;
      this.editModeChange.emit(this.editMode);
    }
  }
}
