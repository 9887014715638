<p-dialog
  #ImpersonationDialog
  [(visible)]="impersonationDialogVisible"
  [contentStyle]="{ overflow: 'visible' }"
  [focusTrap]="false"
  [focusOnShow]="false"
>
  <p-header> Impersonation </p-header>
  <p>
    Please select a user to impersonate. You will receive the user's roles and any edits will be tracked to your user
    account.
  </p>
  <p-dropdown [options]="impersonationOptions" [(ngModel)]="impersonationUser" optionLabel="name"></p-dropdown>
  <p-footer>
    <button pButton type="button" label="Confirm" (click)="impersonateUser()"></button>
    <button pButton type="button" label="Cancel" (click)="impersonationDialogVisible = false"></button>
  </p-footer>
</p-dialog>
<div class="layout-content-container table-container">
  <div class="grid grid-nogutter col-12 p-0 table-inner">
    <div class="col-12 px-2 pb-0">
      <span
        class="filter-menu"
        *ngFor="let status of statusFilters"
        [ngClass]="{ active: status.active }"
        (click)="filterDataByStatus(status)"
      >
        {{ status.label }}
      </span>

      <div class="export-button">
        <p-button
          (onClick)="export()"
          label="Export to Excel"
          icon="fa fa-download"
          iconPos="right"
          class="ui-button-secondary actionButtons"
        ></p-button>
      </div>
    </div>

    <div class="grid grid-nogutter lg:col-5 md:col-8 sm:col-12 p-2">
      <div class="col-7 p-0">
        <div class="grid grid-nogutter col-12 p-0">
          <div class="col-7 pr-2">
            <p-dropdown
              [style]="{ width: '100%' }"
              [options]="actionOptions"
              [(ngModel)]="actionSelected"
              placeholder="Actions"
            >
            </p-dropdown>
          </div>
          <div class="col-5 pr-2">
            <button
              pButton
              type="button"
              class="w-full"
              (click)="bulkApply()"
              [disabled]="disableApply"
              label="Apply"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <input class="col-12" [(ngModel)]="filter" (keyup)="filterTable()" placeholder="Search Tenants" pInputText />
      </div>
    </div>

    <div class="col-12 px-2">
      <p-table
        [columns]="cols"
        [value]="tenants"
        [lazy]="true"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="70vh"
        (onSort)="sortTable($event)"
        [customSort]="true"
        [resizableColumns]="true"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [ngSwitch]="col.format"
              [pSortableColumn]="col.sortable ? col.field : null"
              pResizableColumn
            >
              <ng-container *ngSwitchCase="'checkbox'">
                <p-checkbox [(ngModel)]="bulkCheckAll" (onChange)="bulkToggleCheckAll()" binary="true"></p-checkbox>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ col.header }}
                <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
              </ng-container>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr
            pfs-table-body
            [columns]="columns"
            [row]="rowData"
            [isResizable]="true"
            (oneAction)="actionHandler($event)"
          ></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog></p-confirmDialog>
