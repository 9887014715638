import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatErrorMessage',
})
export class FormatErrorMessage implements PipeTransform {
  transform(value: any, format?: any): string {
    if (!value || value === '' || value === 'TBD') {
      return value;
    }
    return value.replace(/([A-Z][0-9]|[A-Z]|[0-9]+)/g, ' $1').trim();
  }
}
