import { FormGroup, AbstractControl, FormControl, Validators, Validator, ValidatorFn } from '@angular/forms';
import { Utils } from './utils';

export class ApiValidators {
  static nonDTOValidationMessages = {
    passwordconfirm: {
      matchpassword: 'Password and Password Confirmation must match.',
    },
  };

  static passwordValidationMessages = {
    password: {
      required: 'Password is required.',
      pattern:
        'Password must contain the following:<ul><li>A lowercase letter</li><li>A capital (uppercase) letter</li><li>A number</li><li>A special character</li><li>Minimum 8 characters</li></ul>',
    },
    passwordconfirm: {
      matchpassword: 'Password and Password Confirmation must match.',
    },
  };
  static emailValidationMessages = {
    required: 'Email is required.',
    pattern: 'Email must be in a valid email format.',
  };
  static passwordResetConfirmationMessages = () => {
    return {
      confirmationcode: {
        required: 'Confirmation Code is required.',
      },
      password: ApiValidators.passwordValidationMessages.password,
      passwordconfirm: ApiValidators.passwordValidationMessages.passwordconfirm,
    };
  };

  static passwordConfirmation(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ matchpassword: true });
      }
    };
  }

  static validateForm(theForm: FormGroup, theValidationMessages: any, theFormErrors: any) {
    let formValid = true;
    const controls = theForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        theFormErrors[name] = '';
        formValid = false;
        for (const key of Object.keys(controls[name].errors)) {
          setTimeout((_) => (theFormErrors[name] += theValidationMessages[name][key] + ' '));
        }
      }
    }
    return formValid;
  }

  static getValidatorsForFormField = (fieldName, dtoValidators: any) => {
    const retValidators: any = [];

    if (dtoValidators[fieldName]) {
      for (const validator of Object.keys(dtoValidators[fieldName]['validators'])) {
        const validatorValue = dtoValidators[fieldName]['validators'][validator];
        switch (validator) {
          case 'min':
            retValidators.push(Validators.min(validatorValue));
            break;
          case 'max':
            retValidators.push(Validators.max(validatorValue));
            break;
          case 'required':
            if (fieldName === 'acceptEula') {
              // hackish, need to find out if API can do requiredTrue for checkbox bools
              if (validatorValue === true) {
                retValidators.push(Validators.requiredTrue);
              }
            } else {
              if (validatorValue === true) {
                retValidators.push(Validators.required);
              }
            }
            break;
          case 'requiredTrue':
            if (validatorValue === true) {
              retValidators.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            retValidators.push(Validators.email);
            break;
          case 'minLength':
            retValidators.push(Validators.minLength(validatorValue));
            break;
          case 'maxLength':
            retValidators.push(Validators.maxLength(validatorValue));
            break;
          case 'pattern':
            if (fieldName.toLowerCase() === 'email' || fieldName.toLowerCase() === 'username') {
              retValidators.push(Validators.pattern(Utils.EMAIL_REGEX));
            } else {
              // Regex returned from service using regex constructor syntax ('\' prefix and suffix)
              retValidators.push(Validators.pattern(validatorValue.slice(1, -1)));
            }
            break;
          case 'nullValidator':
            if (validatorValue === true) {
              retValidators.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
    }
    return retValidators;
  };

  static getValidationMessagesForFormGroup = (dtoValidators: any) => {
    const retValidatorMessages: Object = {};
    for (const field of Object.keys(dtoValidators)) {
      const validatorMessages = dtoValidators[field].validatorErrorMessages;
      retValidatorMessages[field] = {};
      for (const validator of Object.keys(validatorMessages)) {
        const validatorMessage = validatorMessages[validator];
        retValidatorMessages[field][validator.toLowerCase()] = validatorMessage;
      }
    }
    return retValidatorMessages;
  };
}
