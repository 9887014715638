export enum RateType {
  LineHaul = 'LineHaul',
  RateXUnits = 'RateXUnits',
  RateXMiles = 'RateXMiles',
}
export enum TenantStatusName {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}
export enum LoadStatusName {
  Open = 'Open',
  Assigned = 'Assigned',
  Dispatched = 'Dispatched',
  InTransit = 'InTransit',
  InYard = 'InYard',
  Delivered = 'Delivered',
  Audited = 'Audited',
  Invoiced = 'Invoiced',
  Paid = 'Paid',
  Archive = 'Archive',
  Claim = 'Claim',
  NeedingAttention = 'NeedingUpdate',
  Deleted = 'Deleted',
}

export enum BulkActionName {
  CopyLoad = 'CopyLoad',
  DeleteLoad = 'DeleteLoad',
  UndeleteLoad = 'UndeleteLoad',
  MarkAsAudited = 'MarkAsAudited',
  MarkAsPaid = 'MarkAsPaid',
  Export = 'Export',
}

export enum AttachmentTypeName {
  Other = 'Other',
  Invoice = 'Invoice',
  InvoicePreview = 'InvoicePreview',
  Dispatch = 'Dispatch',
  WorkOrder = 'WorkOrder',
  BOL = 'BOL',
  POD = 'POD',
  RCS = 'RCS',
  SignedRCS = 'SignedRCS',
  Claim = 'Claim',
  License = 'License',
  LongMedicalForm = 'LongMedicalForm',
  RoadTest = 'RoadTest',
  PreEmploymentDrugTest = 'PreEmploymentDrugTest',
  DrugTestingEnrollment = 'DrugTestingEnrollment',
  ChecklistForCasuals = 'ChecklistForCasuals',
  CertificateOfCompliance = 'CertificateOfCompliance',
  InquiryToPreviousEmployers = 'InquiryToPreviousEmployers',
  NonMotorCarrierActivityForm = 'NonMotorCarrierActivityForm',
  InquiryToStateAgencies = 'InquiryToStateAgencies',
  ProofOfReceivingFMCSRHandbook = 'ProofOfReceivingFMCSRHandbook',
  AnnualCertificationOfViolations = 'AnnualCertificationOfViolations',
  EmploymentApplication = 'EmploymentApplication',
  AnnualMVRReview = 'AnnualMVRReview',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Accounting = 'Accounting',
  Dispatch = 'Dispatch',
  LogisticsCarrierAgent = 'LogisticsCarrierAgent',
  ReadOnly = 'ReadOnly',
  LogisticsSalesRep = 'LogisticsSalesRep',
  Driver = 'Driver',
  LogisticsAccountManager = 'LogisticsAccountManager',
  LogisticsHumanResources = 'LogisticsHumanResources',
  LogisticsAccounting = 'LogisticsAccounting',
  LogisticsAdmin = 'LogisticsAdmin',
}

export enum RoleDisplayName {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Accounting = 'Accounting',
  Dispatch = 'Dispatch',
  LogisticsCarrierAgent = 'Carrier Agent',
  ReadOnly = 'Read Only',
  LogisticsSalesRep = 'Sales Representative',
  Driver = 'Driver',
  LogisticsAccountManager = 'Account Manager',
  LogisticsHumanResources = 'Human Resources',
  LogisticsAccounting = 'Accounting',
  LogisticsAdmin = 'Admin',
}

export enum TenantRole {
  LogisticsTenant = 'LogisticsTenant',
}

export enum TenantRoleDisplayName {
  Logistics = 'Logistics',
  Carrier = 'Carrier',
}

export enum Permission {
  LoadRead = 'LoadRead',
  LoadEdit = 'LoadEdit',
  LoadAccounting = 'LoadAccounting',
  DriverProfileAccounting = 'DriverProfileAccounting',
  CompanyRead = 'CompanyRead',
  CompanyEdit = 'CompanyEdit',
  TenantEdit = 'TenantEdit',
  TenantEditAll = 'TenantEditAll',
  UserRead = 'UserRead',
  UserEdit = 'UserEdit',
  DriverPay = 'DriverPay',
  DriverProfileRead = 'DriverProfileRead',
  SystemSettingEdit = 'SystemSettingEdit',
  Marketplace = 'Marketplace',
  CarrierRead = 'CarrierRead',
  CarrierEdit = 'CarrierEdit',
  CarrierCreate = 'CarrierCreate',
  ManagedAccountEdit = 'ManagedAccountEdit',
}

export enum NoteType {
  SuperAdmin = 'SuperAdmin',
  Dispatch = 'Dispatch',
  RateConfirmation = 'RateConfirmation',
  BillOfLading = 'BillOfLading',
  Invoice = 'Invoice',
}

export enum FuelType {
  Gasoline = 'Gasoline',
  SpecialDiesel = 'SpecialDiesel',
  Gasohol = 'Gasohol',
  Propane = 'Propane',
  LNG = 'LNG',
  CNG = 'CNG',
  Ethanol = 'Ethanol',
  Methanol = 'Methanol',
  E85 = 'E85',
  M85 = 'M85',
  A55 = 'A55',
  BioDiesel = 'BioDiesel',
  Hydrogen = 'Hydrogen',
}

export enum BillingStatus {
  None = '',
  Loading = 'loading',
  NoUser = 'no_user',
  Exempt = 'exempt',
  Required = 'required',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}

export enum CompanyStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum PanelContentType {
  Notifications = 'Notifications',
}

export enum PlatformNotificationTypeId {
  MaintenanceSystemDown = 1,
  BugsSmashed,
  NewVersionFunctionality,
  ComingSoon,
  GeneralInformation,
}

export enum TrackingStatus {
  Delayed = 'Delayed',
  OnTime = 'OnTime',
}

export class AttachmentType {
  public value: string;
  public label: string;

  constructor(public type: AttachmentTypeName, label: string = null) {
    this.value = type;
    this.label = label == null ? type : label;
  }
}

export class LoadCategory {
  public value: string;

  constructor(public label: string, public type: RateType) {
    this.value = type;
  }
}

export class FuelTypeClass {
  public value: string;
  public label: string;

  constructor(public type: FuelType, label: string = null) {
    this.value = type;
    this.label = label == null ? type : label;
  }
}

export class StaticData {
  public static loadAttachmentTypes: AttachmentType[] = [
    new AttachmentType(AttachmentTypeName.Other),
    new AttachmentType(AttachmentTypeName.Invoice),
    new AttachmentType(AttachmentTypeName.Dispatch),
    new AttachmentType(AttachmentTypeName.WorkOrder, 'Work Order'),
    new AttachmentType(AttachmentTypeName.BOL),
    new AttachmentType(AttachmentTypeName.POD),
    new AttachmentType(AttachmentTypeName.RCS),
    new AttachmentType(AttachmentTypeName.SignedRCS, 'Signed RCS'),
    new AttachmentType(AttachmentTypeName.Claim, 'Damage / Claim'),
  ];

  public static loadCategories: LoadCategory[] = [
    new LoadCategory('Line Haul', RateType.LineHaul),
    new LoadCategory('Rate x Units', RateType.RateXUnits),
    new LoadCategory('Rate x Miles', RateType.RateXMiles),
  ];

  public static fuelTypes: FuelTypeClass[] = [
    new FuelTypeClass(FuelType.Gasoline),
    new FuelTypeClass(FuelType.SpecialDiesel, 'Special Diesel'),
    new FuelTypeClass(FuelType.Gasohol),
    new FuelTypeClass(FuelType.Propane),
    new FuelTypeClass(FuelType.LNG),
    new FuelTypeClass(FuelType.CNG),
    new FuelTypeClass(FuelType.Ethanol),
    new FuelTypeClass(FuelType.Methanol),
    new FuelTypeClass(FuelType.E85),
    new FuelTypeClass(FuelType.M85),
    new FuelTypeClass(FuelType.A55),
    new FuelTypeClass(FuelType.BioDiesel),
    new FuelTypeClass(FuelType.Hydrogen),
    new FuelTypeClass(FuelType.E85),
  ];
}
