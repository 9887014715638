import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfig } from '../../config/app.config';
import { UserRegistrationComponent } from './user-registration.component';

const routes: Routes = [{ path: AppConfig.routes.userRegistration, component: UserRegistrationComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class UserRegistrationRoutingModule {}
