<div [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'display'">
    <div class="grid grid-nogutter col-12">
      <div class="{{ labelClass }}" *ngIf="labelClass !== 'nolabel'">
        <div class="col-12 p-0">
          {{ label }}
        </div>
        <div class="label-gray col-12 p-0">
          {{ secondaryLabel }}
        </div>
      </div>

      <div class="{{ config.displayBold === true ? 'font-bold' : '' }} {{ controlClass }}">
        <div class="p-0 {{ controlClassLevel1 }}">
          <div [ngSwitch]="type">
            <ng-container *ngSwitchCase="'calendar'">
              {{ value ? (value | date : 'M/d/y') : 'N/A' }}
            </ng-container>
            <ng-container *ngSwitchCase="'check'">
              {{ value ? checkLabel : '' }}
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              {{ selectLabel(value) }}
            </ng-container>
            <ng-container *ngSwitchCase="'radio'">
              {{ radioLabel(value) }}
            </ng-container>
            <ng-container *ngSwitchCase="'state-selector'">
              {{ value || '-' }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div [ngSwitch]="keyFilter">
                <ng-container *ngSwitchCase="'currency'">
                  {{ config.displayTextIcon ? textIcon : '' }}{{ value | currency }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ config.displayTextIcon ? textIcon : '' }}{{ value || '-' }}
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>
    </div>
  </ng-container>

  <div class="grid grid-nogutter col-12 p-0">
    <ng-container *ngSwitchDefault>
      <div class="{{ labelClass }}" *ngIf="labelClass !== 'nolabel'">
        <div class="{{ config.boldLabel === true ? 'font-bold' : '' }}">
          {{ label }}
        </div>
        <div class="label-gray">
          {{ secondaryLabel }}
        </div>
      </div>

      <div
        *ngIf="type == 'text'"
        class="input-box {{ controlClass }}"
        [formGroup]="formGroup"
        [pTooltip]="tooltip"
        tooltipPosition="top"
      >
        <div class="p-0 {{ controlClassLevel1 }}" [ngClass]="{ 'ui-inputgroup': isIcon }">
          <span *ngIf="isIcon" class="ui-inputgroup-addon"
            ><i *ngIf="faIcon != ''" class="fa {{ faIcon }}"></i>{{ textIcon }}</span
          >
          <input
            *ngIf="keyFilter == 'number'"
            #inputControl
            class="w-full"
            pInputText
            type="text"
            pKeyFilter="num"
            [formControlName]="formControlName"
            [(ngModel)]="value"
            (change)="onNumberChanged($event.target.value)"
            placeholder="{{ placeholder }}"
            [attr.pKeyFilter]="keyFilter ? keyFilter : null"
            [attr.readonly]="viewMode === 'readonly' ? '' : null"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
            [autocomplete]="autoComplete"
          />

          <input
            *ngIf="keyFilter == 'currency'"
            #inputControl
            id="{{ formControlName }}"
            class="w-full"
            pInputText
            type="text"
            pKeyFilter="num"
            [formControlName]="formControlName"
            [ngModel]="value"
            (change)="onTextChanged($event)"
            placeholder="{{ placeholder }}"
            (blur)="onBlur($event)"
            [attr.readonly]="viewMode === 'readonly' ? '' : null"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
            [autocomplete]="autoComplete"
          />

          <input
            *ngIf="keyFilter == 'text'"
            #inputControl
            class="w-full"
            pInputText
            type="text"
            [formControlName]="formControlName"
            [(ngModel)]="value"
            (change)="onTextChanged($event)"
            placeholder="{{ placeholder }}"
            [attr.pKeyFilter]="keyFilter ? keyFilter : null"
            [attr.readonly]="viewMode === 'readonly' ? '' : null"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
            [autocomplete]="autoComplete"
          />
        </div>

        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'select'" class="{{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <p-dropdown
            #dropdownControl
            [options]="selectOptions"
            [(ngModel)]="value"
            (onChange)="onChangeSelect($event)"
            [formControlName]="formControlName"
            [style]="{ width: '100%' }"
            [autoDisplayFirst]="autoDisplayFirst"
            appendTo="body"
            placeholder="{{ placeholder }}"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
          >
          </p-dropdown>
        </div>

        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'textarea'" class="{{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <textarea
            #textAreaControl
            pInputTextarea
            [(ngModel)]="value"
            [formControlName]="formControlName"
            style="width: 100%; padding: 2%"
            [rows]="3"
            class="ui-inputtextarea"
            [attr.readonly]="viewMode === 'readonly' ? '' : null"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
          ></textarea>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div
        *ngIf="type == 'check'"
        class="{{ controlClass }}"
        [formGroup]="formGroup"
        [pTooltip]="tooltip"
        tooltipPosition="left"
      >
        <div class="p-0 {{ controlClassLevel1 }}">
          <p-checkbox
            #checkboxControl
            [(ngModel)]="value"
            [formControlName]="formControlName"
            binary="true"
            [label]="checkLabel"
            [labelStyleClass]="checkLabelClass"
            (onChange)="onChangeCheck($event)"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
            [disabled]="viewMode === 'readonly'"
          ></p-checkbox>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'checkboxGroup'" class="{{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <p-checkbox
            *ngFor="let option of selectOptions"
            [formControlName]="formControlName"
            [name]="formControlName"
            label="{{ option.label }}"
            value="{{ option.value }}"
            [(ngModel)]="value"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
          ></p-checkbox>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'radio'" class="{{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <p-radioButton
            *ngFor="let option of selectOptions"
            [formControlName]="formControlName"
            [name]="formControlName"
            label="{{ option.label }}"
            value="{{ option.value }}"
            (onClick)="onRadioChange()"
            [(ngModel)]="value"
            class="p-1"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
          ></p-radioButton>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'calendar'" class="input-box {{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <p-calendar
            [(ngModel)]="value"
            [formControlName]="formControlName"
            [monthNavigator]="config.monthNavigator || true"
            [yearNavigator]="config.yearNavigator || true"
            [yearRange]="config.yearRange || '1900:2050'"
            [showIcon]="true"
            showButtonBar="true"
            placeholder="mm/dd/yyyy"
            (onSelect)="onDateChanged()"
            (onInput)="onDateChanged()"
            appendTo="body"
            [styleClass]="config.styleClass || 'w-full'"
            [tabindex]="viewMode === 'readonly' ? '-1' : null"
          ></p-calendar>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <div *ngIf="type == 'state-selector'" class="{{ controlClass }}" [formGroup]="formGroup">
        <div class="p-0 {{ controlClassLevel1 }}">
          <app-state-selector
            class="col-12 p-0"
            [formGroup]="formGroup"
            [placeholder]="placeholder"
            [formControlName]="formControlName"
            [(ngModel)]="value"
            [readonly]="viewMode === 'readonly' ? true : false"
            (stateChanged)="onStateChanged($event)"
          >
          </app-state-selector>
        </div>
        <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
      </div>

      <ng-container *ngIf="!errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #error>
  <div class="formerrors {{ errorClass }} p-0">
    {{ showErrors() | formatErrorMessage }}
  </div>
</ng-template>
