import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfig } from '../../config/app.config';
import { TenantListComponent } from './list/list.component';
import { TenantEditComponent } from './edit/edit.component';

const routes: Routes = [
  // { path: 'tenant', component: ListComponent },
  // { path: 'tenant/update', component: TenantEditComponent },
  // { path: 'tenant/:id', component: DetailComponent },
  // { path: 'tenant/edit/:id', component: EditComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class TenantRoutingModule {}
