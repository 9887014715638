import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { PlatformNotificationDTO } from 'src/apiclient/v1.1/models';

@Component({
  selector: 'app-platform-notification',
  templateUrl: './platform-notification.component.html',
  styleUrls: ['./platform-notification.component.scss'],
})
export class PlatformNotificationComponent {
  @ViewChild('notificationBody') notificationBody: ElementRef;

  @Input() notification: PlatformNotificationDTO;

  public showTruncatedNotification: boolean;

  public toggleShowTruncatedNotification() {
    this.showTruncatedNotification = !this.showTruncatedNotification;
  }

  public isElementTruncated(element: Element): boolean {
    return element.scrollHeight > element.clientHeight;
  }
}
