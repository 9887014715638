<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<p-dialog
  *ngIf="modal; else viewer"
  [style]="{ minWidth: '53.4rem' }"
  modal="true"
  [contentStyle]="contentDialogStyle"
  [closable]="viewMode === 'Dispatch' ? true : false"
  [closeOnEscape]="true"
  id="pdfPreview"
  [(visible)]="showPDFDialog"
  [focusTrap]="false"
  [draggable]="true"
  [focusOnShow]="false"
>
  <ng-container *ngTemplateOutlet="viewer"></ng-container>
  <p-footer *ngIf="viewMode == 'Invoice'">
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="closePDFDialog()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      (click)="acceptInvoice(false)"
      label="Save"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-envelope"
      form="htmlformGroup"
      (click)="acceptInvoice(true)"
      label="Send Invoice"
    ></button>
  </p-footer>

  <p-footer *ngIf="viewMode == 'Settlement'">
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="cancelSettlement()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      (click)="acceptSettlement()"
      label="Save"
      class="ui-button-primary"
    ></button>
  </p-footer>

  <p-footer *ngIf="viewMode == 'ReviewSettlementPDF'">
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="closePDFDialog()"
      label="Close"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>

<!-- Send PDF Email Dialog -->
<app-document-mail #documentMailer></app-document-mail>

<ng-template #viewer>
  <p-tabView *ngIf="pdfsLoaded" (onChange)="setPDFUrl($event)">
    <p-tabPanel
      *ngFor="let pdfurl of pdfURLs; let idx = index"
      [selected]="idx == 0 ? true : false"
      header="{{ tabHeaderPrefixText }} {{ viewMode == 'Dispatch' ? idx + 1 : '' }}"
    >
      <div class="grid grid-nogutter col-12 p-0">
        <div class="col-10 p-1">&nbsp;</div>
        <div
          class="col-2 p-1 flex flex-wrap justify-content-end"
          *ngIf="
            viewMode === 'Dispatch' ||
            viewMode === 'Settlement' ||
            viewMode === 'ReviewSettlementPDF' ||
            viewMode === 'Invoice'
          "
        >
          <i
            *ngIf="viewMode !== 'Invoice'"
            class="fa fa-envelope cursor-pointer buttonicon pad4"
            pTooltip="Email this {{
              viewMode === 'Settlement' || viewMode === 'ReviewSettlementPDF' ? 'Driver Pay Document' : 'Dispatch Sheet'
            }}"
            (click)="selectedPDFurl = pdfurl; showPDFEmailSender(idx)"
          ></i>
          <i
            class="fa fa-print cursor-pointer buttonicon pad4"
            pTooltip="Print or Save this {{
              viewMode === 'Settlement' || viewMode === 'ReviewSettlementPDF'
                ? 'Driver Pay Document'
                : viewMode === 'Invoice'
                ? 'Invoice'
                : 'Dispatch Sheet'
            }}"
            (click)="displayPDF(idx)"
          ></i>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <pdf-viewer
    *ngIf="pdfsLoaded"
    id="pdfViewer"
    class="col-12"
    [src]="selectedPDFurl"
    [render-text]="true"
    [show-borders]="true"
    style="display: block"
    [style.height]="!modal ? '80vh' : ''"
  >
  </pdf-viewer>
</ng-template>
