import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { FormFields } from './models/form-fields';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FullName } from './models/full-name';

@Component({
  selector: 'app-full-name',
  templateUrl: './full-name.component.html',
  styleUrls: ['./full-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FullNameComponent),
      multi: true,
    },
  ],
})
export class FullNameComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string = 'First/Last';
  @Input() labelClass: string = 'md:col-4 sm:col-12 p-2 pb-3';
  @Input() controlClass: string = 'md:col-8 sm:col-12 p-0';
  @Input() errorClass: string = 'col-12 pl-1';
  @Input() optional: boolean;
  @Input() set touched(value: boolean) {
    if (value) {
      this.formGroup.markAllAsTouched();
    }
  }
  @Input() set dirty(value: boolean) {
    if (value) {
      Object.keys(this.formGroup.controls).forEach((key) => this.formGroup.controls[key].markAsDirty());
    }
  }

  public formGroup: FormGroup;
  public readonly formFields = FormFields;

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [FormFields.FirstName]: [],
      [FormFields.LastName]: [],
    });

    this.setFormValidators();

    this.formGroup.valueChanges
      .pipe(
        tap((value) => this.onChange(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: FullName): void {
    value?.firstName
      ? this.formGroup.controls[FormFields.FirstName].setValue(value.firstName)
      : this.formGroup.controls[FormFields.FirstName].setValue(null);
    value?.lastName
      ? this.formGroup.controls[FormFields.LastName].setValue(value.lastName)
      : this.formGroup.controls[FormFields.LastName].setValue(null);
  }

  private onChange = (value: FullName | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private setFormValidators(): void {
    Object.keys(FormFields).forEach((field) => {
      this.optional
        ? this.formGroup.controls[FormFields[field]].clearValidators()
        : this.formGroup.controls[FormFields[field]].setValidators(Validators.required);
    });
  }
}
