import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './main/main.component';

const routes: Routes = [
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/company', component: SettingsComponent },
  { path: 'settings/accounting', component: SettingsComponent },
  { path: 'settings/users', component: SettingsComponent },
  { path: 'settings/notes', component: SettingsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
