<div
  class="layout-wrapper {{ layoutWrapperClass }} {{ mainBackground }}"
  (click)="onLayoutClick($event)"
  [ngClass]="{
    'layout-horizontal': isHorizontal(),
    'layout-overlay': isOverlay(),
    'layout-static': isStatic(),
    'layout-slim': isSlim(),
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-overlay-active': overlayMenuActive
  }"
>
  <app-notifications></app-notifications>
  <app-header *ngIf="showHeader" [showMenu]="showMenu"></app-header>
  <app-breadcrumb *ngIf="mainBackground === '' || showBreadcrumb"></app-breadcrumb>

  <div class="layout-menu-container" (click)="onMenuClick()" *ngIf="showMenu">
    <div class="layout-menu-content">
      <div class="layout-menu-title">Menu</div>
      <app-menu></app-menu>
      <div class="layout-menu-footer"></div>
    </div>
  </div>

  <div class="layout-content {{ layoutClass }}">
    <div class="layout-content-container main-content-container {{ layoutContainerClass }}">
      <router-outlet></router-outlet>
    </div>
    <app-footer [hidden]="noFooter"></app-footer>

    <div class="layout-mask" *ngIf="staticMenuMobileActive"></div>
  </div>

  <p-dialog
    id="updateDialog"
    [(visible)]="showNeedsUpdate"
    blockScroll="true"
    [modal]="true"
    [focusOnShow]="false"
    [closable]="false"
  >
    <p-header>
      <div class="bigtitle"> App Update Needed </div>
    </p-header>
    There is a new version of the application available. Please refresh.
    <br />
    <p-footer>
      <button
        type="button"
        pButton
        icon="fa fa-times"
        (click)="closeAppUpdateModal()"
        label="Continue Working"
        class="ui-button-secondary"
      ></button>
      <button type="button" pButton icon="fa fa-check" (click)="refreshNowClicked()" label="Refresh Now"></button>
    </p-footer>
  </p-dialog>

  <p-dialog
    id="sessionDialog"
    [(visible)]="showSessionWarning"
    blockScroll="true"
    [modal]="true"
    [focusOnShow]="false"
    [closable]="false"
  >
    <p-header>
      <div class="bigtitle"> Session Timeout Warning </div>
    </p-header>
    Your session will expire in 5 minutes. Please log out and log back in again to continue working.
    <br />
    <p-footer>
      <button
        type="button"
        pButton
        icon="fa fa-times"
        (click)="closeSessionWarningModal()"
        label="Dismiss"
        class="ui-button-secondary"
      ></button>
      <button type="button" pButton icon="fa fa-check" (click)="refreshNowClicked()" label="Refresh Now"></button>
    </p-footer>
  </p-dialog>
</div>
