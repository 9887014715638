import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Adapted from Apollo v9 `app.menu.service.ts`.
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public menuSource$: Observable<string>;
  public resetSource$: Observable<void>;

  private menuSource = new Subject<string>();
  private resetSource = new Subject<void>();

  constructor() {
    this.menuSource$ = this.menuSource.asObservable();
    this.resetSource$ = this.resetSource.asObservable();
  }

  public onMenuStateChange(key: string): void {
    this.menuSource.next(key);
  }

  public reset(): void {
    this.resetSource.next();
  }
}
