import { catchError } from 'rxjs/operators';
import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsernameDTO } from '../../../../apiclient/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { AuthenticationService } from 'src/apiclient/v1.1/services';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
  providers: [AuthenticationService],
})
export class ForgotPasswordComponent extends DtoFormBase<UsernameDTO> implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.model.username = params['username'];
    });

    this.initFormFor('UsernameDTO');
  }

  cancelForgotPassword() {
    this.router.navigate(['login/']);
  }

  startForgotPassword() {
    if (this.formValid()) {
      this.showProgressOverlay = true;
      this.authenticationService
        .ForgotPassword(this.model)
        .pipe(catchError(this.handleError))
        .subscribe((resp) => {
          this.showProgressOverlay = false;
          this.logging.logTrace(JSON.stringify(resp));
          if (resp === '"Code"') {
            this._NS.notify({
              type: 'success',
              summary: 'Code Sent',
              detail: 'Check your email for the Confirmation Code and Link to reset your Password',
              time: 6000,
            });
            this.router.navigate(['login/forgotpassword/confirm/' + this.model.username]);
          } else if (resp === '"Password"') {
            this._NS.notify({
              type: 'success',
              summary: 'Temporary Password Sent',
              detail: 'Check your email for the temporary password. You can use it to reset your password.',
              time: 6000,
            });
            this.router.navigate(['login']);
          }
        });
    }
  }
}
