import { Component } from '@angular/core';
import { EnvironmentService } from 'src/services/environment/environment.service';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-footer',
  template: `
    <div class="layout-footer">
      <div class="clearfix">
        <span class="footer-text-left">&copy; {{ currentDate.getFullYear() }} Truxio, LLC </span>
        <span [class.hide]="isProdBuild" class="footer-text-left">
          &nbsp;- Build Time: {{ buildTime | date : 'M/d/yy H:mm' }}
        </span>
        <span class="footer-text-right flex gap-2">
          <a [href]="supportLink" target="_blank">Contact Us</a>
          <div class="px-1">|</div>
          <a routerLink="/privacy-policy" target="_blank">Terms and Privacy</a>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./app.footer.component.scss'],
})
export class AppFooterComponent {
  public currentDate = new Date();
  public buildTime = new Date(EnvironmentService.environment.buildTime);
  public readonly isProdBuild = EnvironmentService.isProd();
  public readonly supportLink = Utils.supportLink;
}
