<div [formGroup]="formGroup" class="grid" *ngIf="viewMode === addressViewMode.TwoColumns" id="twoColumns">
  <app-form-field
    class="col-6"
    label="Line 1 *"
    type="text"
    labelClass="col-12 p-1"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line1"
  ></app-form-field>
  <app-form-field
    class="col-6"
    label="Line 2"
    type="text"
    labelClass="col-12 p-1"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line2"
  ></app-form-field>
  <app-form-field
    class="col-6"
    label="City *"
    type="text"
    labelClass="col-12 p-1"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.City"
  ></app-form-field>
  <app-form-field
    class="col-2"
    label="State *"
    [type]="formGroup.controls[addressFormFields.StateOrTerritory].disabled ? 'text' : 'state-selector'"
    labelClass="col-12 p-1"
    controlClass="col-12 p-1"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.StateOrTerritory"
    (stateChanged)="onStateChange($event)"
  ></app-form-field>
  <app-form-field
    class="col-4"
    label="Zip Code*"
    type="text"
    labelClass="col-12 p-1"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.PostalCode"
  ></app-form-field>
</div>

<div [formGroup]="formGroup" class="grid" *ngIf="viewMode === addressViewMode.OneColumn" id="oneColumn">
  <app-form-field
    class="col-12 p-1"
    placeholder="Line 1"
    type="text"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line1"
  ></app-form-field>
  <app-form-field
    class="col-12 p-1"
    placeholder="Line 2"
    type="text"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line2"
  ></app-form-field>
  <app-form-field
    class="col-12 p-1"
    placeholder="City"
    type="text"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.City"
  ></app-form-field>
  <app-form-field
    class="col-6 p-1"
    placeholder="State"
    [type]="formGroup.controls[addressFormFields.StateOrTerritory].disabled ? 'text' : 'state-selector'"
    controlClass="col-12 p-1' : 'col-12 p-1"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.StateOrTerritory"
    (stateChanged)="onStateChange($event)"
  ></app-form-field>
  <app-form-field
    class="col-6 p-1"
    placeholder="Zip Code"
    type="text"
    controlClass="col-12"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.PostalCode"
  ></app-form-field>
</div>

<div
  [formGroup]="formGroup"
  class="grid"
  *ngIf="viewMode === addressViewMode.OneColumnWithLabels"
  id="oneColumnWithLabels"
>
  <app-form-field
    class="col-12 p-0"
    label="Address Line 1 *"
    type="text"
    [labelClass]="labelClass"
    [controlClass]="controlClass"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line1"
  ></app-form-field>
  <app-form-field
    class="col-12 p-0"
    label="Address Line 2"
    type="text"
    [labelClass]="labelClass"
    [controlClass]="controlClass"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.Line2"
  ></app-form-field>
  <app-form-field
    class="col-12 p-0"
    label="City *"
    type="text"
    [labelClass]="labelClass"
    [controlClass]="controlClass"
    [errorClass]="errorClass"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [errorUnderInput]="errorUnderInput"
    [formControlName]="addressFormFields.City"
  ></app-form-field>
  <div class="grid grid-nogutter col-12 p-0">
    <div class="{{ labelClass }} font-bold">State/Zip *</div>
    <div class="{{ controlClass }} pt-1 grid grid-nogutter flex">
      <div class="col-6 pr-1">
        <app-form-field
          [type]="formGroup.controls[addressFormFields.StateOrTerritory].disabled ? 'text' : 'state-selector'"
          controlClass="w-full col-12"
          [errorClass]="errorClass"
          [formGroup]="formGroup"
          [formErrors]="formErrors"
          [errorUnderInput]="errorUnderInput"
          [formControlName]="addressFormFields.StateOrTerritory"
          (stateChanged)="onStateChange($event)"
        ></app-form-field>
      </div>
      <div class="col-6 pl-1">
        <app-form-field
          type="text"
          controlClass="w-full p-0"
          [errorClass]="errorClass"
          [formGroup]="formGroup"
          [formErrors]="formErrors"
          [errorUnderInput]="errorUnderInput"
          [formControlName]="addressFormFields.PostalCode"
        ></app-form-field>
      </div>
    </div>
  </div>
</div>
