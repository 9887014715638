export const AppConfig = {
  routes: {
    error404: '404',
    dashboard: 'dashboard',
    login: {
      default: 'login',
      forgotPassword: 'login/forgotpassword',
      forgotPasswordWithUser: 'login/forgotpassword/:username',
      confirmForgotPassword: 'login/forgotpassword/confirm',
      confirmForgotPasswordWithUser: 'login/forgotpassword/confirm/:username',
      verifyEmail: 'login/verifyemail',
      setNewPassword: 'login/setnewpassword/:userandtoken',
      signUp: 'login/signup',
    },
    registration: 'registration',
    userRegistration: 'userregistration',
    subscription: {
      required: 'subscription/required',
      success: 'subscription/success',
      cancelled: 'subscription/cancelled',
    },
    workplace: 'workplace',
    load: 'load',
    marketplace: 'marketplace',
    company: 'company',
    users: 'users',
    factoring: {
      buyNoBuy: 'factoring/buy-no-buy',
    },
    backOffice: {
      invoicing: 'backoffice/invoicing',
      driverPay: 'backoffice/driverpay',
      drivers: 'backoffice/drivers',
    },
    settings: {
      company: 'settings/company',
    },
    admin: {
      systemDefaults: 'admin/system-defaults',
      platformNotifications: 'admin/platform-notifications',
    },
    documentViewer: 'documentviewer/:documenttype/:documentid',
    privacyPolicy: 'privacy-policy',
    logistics: 'logistics',
  },
  endpoints: {},
  apptitle: 'Truxio',
} as const;
