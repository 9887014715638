<div class="grid grid-nogutter col-12 editSection" [class.editSectionActive]="editMode">
  <div class="col-2 p-2">
    <span class="bold-text">{{ title }}</span>
  </div>
  <div class="col-9 sectionContent pt-0">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-12">
        <ng-container *ngIf="!editMode">
          <ng-content select="[read]"></ng-content>
        </ng-container>
      </div>
      <div class="col-12">
        <ng-container *ngIf="editMode"> <ng-content select="[edit]"></ng-content> </ng-container
      ></div>
      <div class="col-12 p-2" [hidden]="!editMode">
        <div class="col-8 {{ offsetClass }} p-0">
          <button
            pButton
            icon="fa fa-times"
            type="button"
            label="Cancel"
            class="ui-button-secondary"
            (click)="cancel()"
          ></button>
          <button
            pButton
            class="ml-2"
            icon="fa fa-check"
            type="button"
            label="Save"
            (click)="save()"
            [disabled]="disableSave"
            data-test="save-section"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-1 p-2" *ngIf="!disableEdit">
    <span class="sectionEditAction" [hidden]="editMode" (click)="setEditMode(!editMode)" data-test="toggle-edit">
      <i class="fa fa-pencil-alt"></i>
    </span>
  </div>
</div>
