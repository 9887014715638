<div class="grid grid-nogutter col-12 p-0">
  <div class="{{ labelClass }} font-bold">{{ label }}</div>
  <form class="{{ controlClass }} py-1 grid grid-nogutter flex" [formGroup]="formGroup">
    <div class="col-6 pr-1" id="first-name">
      <input type="text" pInputText class="w-full" [formControlName]="formFields.FirstName" />
      <ng-container
        [ngTemplateOutlet]="required"
        *ngIf="formGroup.controls[formFields.FirstName].touched && formGroup.controls[formFields.FirstName].invalid"
      ></ng-container>
    </div>
    <div class="col-6 pl-1" id="last-name">
      <input type="text" pInputText class="w-full" [formControlName]="formFields.LastName" />
      <ng-container
        [ngTemplateOutlet]="required"
        *ngIf="formGroup.controls[formFields.LastName].touched && formGroup.controls[formFields.LastName].invalid"
      ></ng-container>
    </div>
  </form>
</div>

<ng-template #required>
  <div class="formerrors {{ errorClass }} p-0">
    {{ 'Required' | formatErrorMessage }}
  </div>
</ng-template>
