import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
  Input,
} from '@angular/core';
import { DtoFormBase } from '../../shared/FormBase';
import { UserService } from './../../../apiclient/v1.1/services/user.service';
import { DriverProfileService } from '../../../apiclient/services/driver-profile.service';
import { DocumentMailComponent } from '../document/mail/mail.component';
import { ClientTokenService } from '../../../services/client-token-service';
import { catchError, map, mergeMap, take, tap, switchMap, takeUntil } from 'rxjs/operators';
import { AttachmentDTO, DriverProfileDTO, SendFileDTO } from '../../../apiclient/v1.1/models';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { LoadService } from '../../../apiclient/v1.1/services/load.service';
import { DocumentsService } from '../../../apiclient/v1.1/services';
import { CustomNotification } from '../../shared/models/custom-notification';
import { HttpErrorResponse } from '@angular/common/http';
import { FactoringLoginService } from '../../../services/factoring-login/factoring-login.service';

@Component({
  selector: 'app-pdf-utility',
  templateUrl: './pdf-utility.component.html',
  styleUrls: ['./pdf-utility.component.scss'],
})
export class PdfUtilityComponent extends DtoFormBase<any> implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  viewMode: string;
  loadId: number;

  // SETTLEMENT
  driver: DriverProfileDTO;
  driverId: number;
  legIds: Array<number>;
  settlementAttachmentId: number;
  driverSettlementId: number;
  reviewSettlementAttachment: AttachmentDTO;
  fuelDeductionDescription: string;
  fuelDeductionAmount: number;
  periodEnding: string;

  pdfsLoaded: boolean = false;
  pdfDTOs: AttachmentDTO[];
  pdfURLs: Array<string>;
  selectedPDFurl: string;
  showPDFDialog: boolean;
  tabHeaderPrefixText: string;
  recipients: string[] = [];
  contentDialogStyle: any;
  @Input() modal: boolean = true;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('documentMailer', { static: true }) documentMailer: DocumentMailComponent;
  dialogHeight: any;

  constructor(
    protected injector: Injector,
    private documentsService: DocumentsService,
    private loadService: LoadService,
    private userService: UserService,
    private driverProfileService: DriverProfileService,
    private clientTokenService: ClientTokenService,
    private factoringLoginService: FactoringLoginService
  ) {
    super(injector);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize') onResize() {
    this.dialogHeight = window.innerHeight - 100;
    this.contentDialogStyle = { maxHeight: window.innerHeight - 156 + 'px' };
  }

  ngAfterViewInit() {
    setTimeout((_) => {
      this.dialogHeight = window.innerHeight - 100;
      this.contentDialogStyle = { maxHeight: window.innerHeight - 156 + 'px' };
    });
  }

  public async generateLoadDispatchPDF(loadId: number, loadNumber: number, driverId: number): Promise<void> {
    this.loadId = loadId;
    this.driverId = driverId;
    this.tabHeaderPrefixText = 'Load ' + loadNumber + ' - Leg ';
    this.viewMode = 'Dispatch';
    const retPromise = this.generatePDFs('Dispatch');
    return retPromise;
  }

  public async generateLoadInvoicePDF(loadId: number, loadNumber: number, recipients: string[]): Promise<void> {
    this.loadId = loadId;
    this.tabHeaderPrefixText = 'Load ' + loadNumber + ' Invoice';
    this.viewMode = 'Invoice';
    this.recipients = recipients;
    const retPromise = this.generatePDFs('Invoice');
    return retPromise;
  }

  public async generateSettlementPDF(
    driverId: number,
    legIds: number[],
    fuelDeductionDescription: string,
    fuelDeductionAmount: number,
    periodEnding: string
  ): Promise<void> {
    this.driverId = driverId;
    this.legIds = legIds;
    this.fuelDeductionDescription = fuelDeductionDescription;
    this.fuelDeductionAmount = fuelDeductionAmount;
    this.periodEnding = periodEnding;
    this.tabHeaderPrefixText = 'Driver Pay';
    this.viewMode = 'Settlement';
    const retPromise = this.generatePDFs('Settlement');
    return retPromise;
  }

  async reviewSettlementPDF(attachmentId: number, driverId: number) {
    this.driverId = driverId;
    this.showProgressOverlay = false;
    this.viewMode = 'ReviewSettlementPDF';
    this.tabHeaderPrefixText = 'Review Settlement';
    const attachmentDTO = {} as AttachmentDTO;
    attachmentDTO.attachmentId = attachmentId;
    attachmentDTO.fileName = 'Settlement Review Attached';
    this.reviewSettlementAttachment = attachmentDTO;
    this.pdfURLs = new Array();
    await this.loadAndPreviewPDF(attachmentDTO).then((d) => {
      this.pdfsLoaded = true;
      this.showProgressOverlay = false;
      this.showPDFDialog = true;
      if (this.pdfURLs && this.pdfURLs.length > 0) {
        this.selectedPDFurl = this.pdfURLs[0];
      }
    });
  }

  async generatePDFs(viewType: string): Promise<void> {
    // start anew
    this.pdfsLoaded = false;
    this.pdfDTOs = new Array<AttachmentDTO>();
    this.pdfURLs = new Array();
    this.selectedPDFurl = '';

    return new Promise<void>(async (resolve, reject) => {
      if (this.viewMode === 'Settlement') {
        if (!this.legIds) {
          reject('No Leg IDs');
          return;
        }
      } else if (!this.loadId) {
        reject('No Load');
        return;
      }

      this.showProgressOverlay = true;

      try {
        if (viewType === 'Dispatch') {
          this.pdfDTOs = await this.documentsService
            .DispatchSheets({
              loadId: this.loadId,
              Authorization: this.clientToken.auth(),
            })
            .toPromise();
        } else if (viewType === 'Invoice') {
          this.pdfDTOs = new Array<AttachmentDTO>();
          this.pdfDTOs.push(
            await this.documentsService
              .Invoice({
                loadId: this.loadId,
                Authorization: this.clientToken.auth(),
              })
              .toPromise()
          );
        } else if (viewType === 'Settlement') {
          this.pdfDTOs = new Array<AttachmentDTO>();

          const preview = await this.documentsService
            .DriverPay({
              driverId: this.driverId,
              loadLegs: this.legIds,
              fuelDeductionDescription: this.fuelDeductionDescription,
              fuelDeductionAmount: this.fuelDeductionAmount,
              periodEnding: this.periodEnding,
              Authorization: this.clientToken.auth(),
            })
            .toPromise();

          this.driverSettlementId = preview.driverSettlementId;
          this.pdfDTOs.push(preview.attachment);
        } else {
          this._NS.warn(
            'Unknown View Mode',
            'No viewMode or invalid viewMode parameter specified for PDF Generator call.'
          );
          reject('Error generating ' + this.viewMode + ' PDFs');
        }

        await Promise.all(
          this.pdfDTOs.map(async (dto) => {
            await this.loadAndPreviewPDF(dto);
          })
        ).then((d) => {
          this.pdfsLoaded = true;
          this.showProgressOverlay = false;
          this.showPDFDialog = true;
          if (this.pdfURLs && this.pdfURLs.length > 0) {
            this.selectedPDFurl = this.pdfURLs[0];
          }
        });

        resolve();
      } catch (errorResponse) {
        this.showProgressOverlay = false;
        if (errorResponse instanceof HttpErrorResponse && errorResponse.status === 400 && errorResponse.error) {
          this._NS.error(
            'Error generating ' + this.viewMode + ' PDFs',
            errorResponse.error[Object.keys(errorResponse.error)[0]]
          );
        }
        reject('Error generating ' + this.viewMode + ' PDFs');
      }
    });
  }

  async loadAndPreviewPDF(pdfDTO: AttachmentDTO) {
    return new Promise<void>(async (resolve, reject) => {
      if (!pdfDTO) {
        reject('No Attachment DTO');
        return;
      }

      try {
        let attachment;

        if (this.viewMode === 'Settlement' || this.viewMode === 'ReviewSettlementPDF') {
          this.settlementAttachmentId = pdfDTO.attachmentId;
          attachment = await this.userService
            .DownloadAttachment({
              id: pdfDTO.attachmentId,
              Authorization: this.clientToken.auth(),
            })
            .toPromise();
        } else {
          attachment = await this.loadService
            .DownloadAttachment({
              id: pdfDTO.attachmentId,
              Authorization: this.clientToken.auth(),
            })
            .toPromise();
        }

        const blob = new Blob([attachment], { type: 'application/pdf' });
        this.pdfURLs[this.pdfURLs.length] = URL.createObjectURL(blob);
        setTimeout((_) => {
          const focussedButton = <HTMLElement>document.activeElement;
          focussedButton.blur();
        });
        resolve();
      } catch (error) {
        this.handleError(error);
        reject('Error loading PDFs');
      }
    });
  }

  setPDFUrl(e) {
    this.selectedPDFurl = this.pdfURLs[e.index];
  }

  displayPDF(pdfIndex) {
    window.open(this.pdfURLs[pdfIndex]);
  }
  async getDriverProfile(id) {
    this.driver = await this.driverProfileService
      .ApiDriverProfileByUserIdByUserIdGet({ userId: id, Authorization: this.clientToken.auth() })
      .toPromise();
  }
  async showPDFEmailSender(pdfIndex) {
    const pdfAttachmentToSend = new Array();
    if (this.viewMode === 'ReviewSettlementPDF') {
      pdfAttachmentToSend.push(this.reviewSettlementAttachment);
    } else {
      pdfAttachmentToSend.push(this.pdfDTOs[pdfIndex]);
    }

    if (
      this.driverId > 0 &&
      (this.viewMode === 'Dispatch' || this.viewMode === 'ReviewSettlementPDF' || this.viewMode === 'Settlement')
    ) {
      try {
        await this.getDriverProfile(this.driverId);
        this.recipients = [];
        this.recipients.push(this.driver.email);
      } catch (error) {
        this.handleError(error);
        this.closePDFDialog(true);
      }
    }

    this.documentMailer.init({
      manualAttachments: pdfAttachmentToSend,
      manualOnly: true,
      chooseAttachments: null,
      recipients: this.recipients,
      onSend: (model) => this.sendAttachment(model),
      closeDialogOnSend: false,
    });
  }

  async acceptInvoice(sendInvoice: boolean) {
    this.clientToken
      .isFactoringEnabledForTenant()
      .pipe(
        take(1),
        mergeMap((isFactoringEnabled) => {
          if (isFactoringEnabled || sendInvoice) {
            return this.sendInvoice(isFactoringEnabled, sendInvoice);
          } else {
            return this.saveInvoiceAndClose();
          }
        }),
        catchError((error) => {
          this.handleError(error);
          this.closePDFDialog(true);
          return of();
        })
      )
      .subscribe();
  }

  async acceptSettlement() {
    try {
      await this.documentsService
        .DriverPayAccept({
          AttachmentId: this.settlementAttachmentId,
          DriverSettlementId: this.driverSettlementId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise()
        .then(() => {
          this._NS.success('Success!', 'Driver Settlement has been successfully saved.');
          this.closePDFDialog(true);
        });
    } catch (error) {
      this.handleError(error);
      this.closePDFDialog(true);
    }
  }

  public cancelSettlement() {
    this.documentsService
      .DriverPayCanceled({
        driverSettlementId: this.driverSettlementId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        catchError(() => EMPTY),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.closePDFDialog();
  }

  private sendInvoice(isFactoringEnabled: boolean, sendInvoice: boolean): Observable<void> {
    return this.loadService
      .GetAttachments({
        loadId: this.loadId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        take(1),
        mergeMap((attachments) => {
          const otherAttachments = attachments.filter(
            (attachment) => attachment.type !== 'Invoice' && attachment.type !== 'InvoicePacket'
          );
          const pdfIndex = this.pdfURLs.indexOf(this.selectedPDFurl);
          const invoice: AttachmentDTO = this.pdfDTOs[pdfIndex];

          if (sendInvoice) {
            return this.promptUserToSendEmailWithAttachments(isFactoringEnabled, [invoice, ...otherAttachments]);
          } else {
            const filteredAttachments = otherAttachments.filter(
              (attachment) =>
                attachment.type !== 'Other' && attachment.type !== 'Dispatch' && attachment.type !== 'Claim'
            );
            return this.sendEmailToLenderWithAttachments([invoice, ...filteredAttachments]);
          }
        }),
        map(() => null)
      );
  }

  private saveInvoiceAndClose(): Observable<void> {
    return this.saveInvoice().pipe(
      take(1),
      tap(() => this.closePDFDialog(true))
    );
  }

  private saveInvoice(): Observable<void> {
    return this.documentsService
      .InvoiceAccept({
        loadId: this.loadId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        take(1),
        tap((result) => {
          this._NS.success('Success!', 'Invoice has been successfully saved.');
          if (result.isLoadAlreadyInvoicedToFactorCloud) {
            const notification: CustomNotification = {
              type: 'warn',
              summary: 'Invoice Already Submitted to Factor',
              detail:
                'The Invoice Packet was saved successfully. However, the original invoice has already been submitted for factoring. Any updates to the invoice and supporting documents must also be made through our factoring portal.',
              sticky: true,
            };
            this._NS.notifyWithCallback(notification, this.openFactorCloudPortal.bind(this), 'Factor Cloud Portal');
          }
        }),
        map(() => null)
      );
  }

  private openFactorCloudPortal() {
    this.factoringLoginService.logInToFactorCloud();
  }

  private sendEmailToLenderWithAttachments(attachments: AttachmentDTO[]): Observable<void> {
    return this.saveInvoiceAndClose().pipe(
      take(1),
      mergeMap(() => {
        const attachmentIds: number[] = [];
        if (attachments && attachments.length > 0) {
          attachments = attachments.sort((b, a) => {
            return this.getTime(a.modifiedOn) - this.getTime(b.modifiedOn);
          });
          attachments.forEach((attachment) => {
            attachmentIds.push(attachment.attachmentId);
          });
          return this.loadService.SendInvoiceAttachmentToLender({
            loadId: this.loadId,
            Authorization: this.clientToken.auth(),
            body: attachmentIds,
          });
        }
      })
    );
  }

  private promptUserToSendEmailWithAttachments(
    isFactoringEnabled: boolean,
    attachments: AttachmentDTO[]
  ): Observable<void> {
    return of(
      this.documentMailer.init({
        manualAttachments: attachments,
        manualOnly: true,
        chooseAttachments: true,
        recipients: this.recipients,
        requireDocumentMerge: isFactoringEnabled,
        onSend: (sendEmailModel) => this.uploadAndEmailAttachments(sendEmailModel),
        closeDialogOnSend: true,
      })
    );
  }

  private uploadAndEmailAttachments(sendEmailModel: SendFileDTO): Observable<void> {
    return this.saveInvoiceAndClose().pipe(mergeMap(() => this.sendInvoiceAttachment(sendEmailModel)));
  }

  private getTime(date?: any) {
    return date != null && date ? new Date(date).getTime() : 0;
  }

  closePDFDialog(emitChanges: boolean = false) {
    if (emitChanges) {
      this.dialogClosed.emit({ viewMode: this.viewMode });
    }
    this.showPDFDialog = false;
  }

  private sendAttachment(body: SendFileDTO): Observable<void> {
    const params = { Authorization: this.clientTokenService.auth(), body };
    return of(this.viewMode === 'Settlement' || this.viewMode === 'ReviewSettlementPDF').pipe(
      switchMap((isSettlement) =>
        isSettlement ? this.userService.SendAttachment(params) : this.loadService.SendAttachment(params)
      ),
      tap(() => this._NS.success('Attachment(s) sent.', ''))
    );
  }

  private sendInvoiceAttachment(body: SendFileDTO): Observable<void> {
    return this.loadService
      .SendInvoiceAttachment({
        loadId: this.loadId,
        Authorization: this.clientToken.auth(),
        body,
      })
      .pipe(
        tap(() => this._NS.success('Attachment(s) sent.', '')),
        catchError((error) => this.handleInvoiceError(error))
      );
  }

  private handleInvoiceError(error: HttpErrorResponse): Observable<void> {
    const noaErrorKey = 'notice Of Assignment';

    if (error.status === 400 && error.error) {
      const problems = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;

      if (problems && problems[noaErrorKey]) {
        const noaErrorMessage = problems[noaErrorKey];
        return of(this._NS.error('Unable To Send Invoice', noaErrorMessage));
      }
    }

    return of(this._NS.error('Email Failed To Send', `Please try again using the Load 'Documents' tab.`));
  }
}
