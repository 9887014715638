<p-blockUI [blocked]="loading">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<app-load-edit
  *ngIf="showLoadDialog"
  [loadId]="selectedLoadId"
  [(dialogVisible)]="showLoadDialog"
  (modelEdited)="handleModelEdit($event)"
>
</app-load-edit>

<app-settlement #settlementDialog (dialogClosed)="onCloseDialog()"> </app-settlement>

<app-pdf-utility #pdfutil appendTo="body" (dialogClosed)="onCloseDialog()"></app-pdf-utility>

<p-card styleClass="ui-card-shadow">
  <div class="layout-content-container table-container">
    <div class="grid grid-nogutter col-12 p-2 table-inner">
      <div class="col-12 p-0">
        <h1>Driver Pay</h1>
      </div>
      <div class="grid grid-nogutter lg:col-12 sm:col-6 p-0 py-2 flex flex-wrap align-items-center">
        <div class="grid grid-nogutter lg:col-3">
          <div class="grid grid-nogutter col-6 pr-2">
            <div class="col-12 p-0 py-1 font-bold"> Driver </div>
            <div class="col-12">
              <app-user-selector roleFilter="Driver" [(ngModel)]="filter.driverUser"> </app-user-selector>
            </div>
          </div>
          <div class="grid grid-nogutter col-6 pr-2">
            <div class="col-12 p-0 py-1 font-bold"> Pay Status </div>
            <div class="col-12">
              <p-dropdown
                [style]="{ width: '100%' }"
                [options]="payStatuses"
                [(ngModel)]="filter.payStatus"
                placeholder="Status"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="grid grid-nogutter lg:col-9 px-0">
          <div class="col-12 p-0 py-1 font-bold"> Dates </div>
          <div class="grid grid-nogutter col-12 p-0 flex flex-wrap">
            <div class="py-1 pr-2">
              <p-calendar
                class="col-2 p-0"
                [(ngModel)]="filter.startDate"
                showButtonBar="true"
                showIcon="true"
                placeholder="Start"
              >
              </p-calendar>
            </div>
            <div class="py-1 pr-2">
              <p-calendar
                class="col-2 p-0"
                [(ngModel)]="filter.endDate"
                showButtonBar="true"
                showIcon="true"
                placeholder="End"
              >
              </p-calendar
            ></div>
            <div class="py-1 pr-2">
              <p-dropdown
                class="col-2 p-0"
                [options]="dateCriteria"
                [(ngModel)]="filter.dateCriteria"
                placeholder="Delivery Date"
              >
              </p-dropdown>
            </div>
            <div class="col-2 py-1">
              <button pButton class="w-full" type="button" label="Search" (click)="getData()"></button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="col-12 p-0" *ngIf="loads.length > 0">
        <div class="col-2 p-0 pb-2">
          <button
            pButton
            type="button"
            icon="fa fa-dollar-sign"
            label="Create Settlement"
            [disabled]="!rowsSelected"
            (click)="settlementProcess()"
          ></button>
        </div>
      </div>
      <div class="col-12" *ngIf="loads.length > 0">
        <p-table
          [columns]="cols"
          [value]="loads"
          [lazy]="true"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="70vh"
          (onSort)="sortTable($event)"
          [customSort]="true"
          [resizableColumns]="true"
        >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [ngSwitch]="col.format"
                [pSortableColumn]="col.sortable ? col.field : null"
                pResizableColumn
              >
                <ng-container *ngSwitchCase="'conditionalCheckbox'">
                  <p-checkbox
                    [(ngModel)]="bulkCheckAll"
                    (onChange)="bulkToggleCheckAll($event)"
                    binary="true"
                  ></p-checkbox>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ col.header }}
                  <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              #pfsPaymentTable
              pfs-table-body
              [columns]="columns"
              [row]="rowData"
              [isResizable]="true"
              (oneAction)="actionHandler($event)"
            ></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>
