export enum ReportingWizardFilterCategoryValues {
  Driver = 'Driver',
  BillTo = 'BillTo',
  Shipper = 'Shipper',
  Consignee = 'Consignee',
  Dispatcher = 'Dispatcher',
  TruckNumber = 'TruckNumber',
  EquipmentType = 'EquipmentType',
  TrailerNumber = 'TrailerNumber',
  Invoiced = 'Invoiced',
  Delivered = 'Delivered',
  DriverSettlement = 'DriverSettlement',
}
