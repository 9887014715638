import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from './primeng.module';
import { AddressComponent } from '../../components/address/address.component';
import { AddressV2Component } from '../../components/address-v2/address-v2.component';
import { AddressV3Component } from 'src/app/components/address-v3/address-v3.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';

import { DebugFormGroupComponent } from '../../components/app-debug/formgroup/formgroup.component';

import { ContactComponent } from '../../components/contact/contact.component';

import { UserListComponent } from '../../components/user-list/user-list.component';
import { UserEditComponent } from '../../components/user-edit/user-edit.component';
import { AdminEditComponent } from '../../modules/admin/edit/edit.component';

import { DriverProfileListComponent } from '../../modules/driver-profile/list/driver-profile-list.component';
import { DriverProfileEditComponent } from '../../modules/driver-profile/edit/driver-profile-edit.component';
import { RecordsChecklistComponent } from '../../modules/driver-profile/edit/records-checklist/records-checklist.component';
import { RecordComponent } from '../../modules/driver-profile/edit/records-checklist/record/record.component';

import { TenantEditComponent } from '../../modules/tenant/edit/edit.component';
import { TenantListComponent } from '../../modules/tenant/list/list.component';

import { PfsTableBodyComponent } from '../../components/table/pfs-table-body/pfs-table-body.component';

import { PhoneNumberComponent } from '../../components/phone-number/phone-number.component';
import { EditSectionComponent } from '../../components/edit-section/edit-section.component';
import { EditSectionRowComponent } from '../../components/edit-section-row/edit-section-row.component';
import { FormFieldComponent } from '../../components/form/field/field.component';
import { FormChargesComponent } from '../../components/form/charges/charges.component';
import { FormChargeComponent } from '../../components/form/charges/charge/charge.component';

import { ClickStopPropogationDirective } from '../../directives/click-stop-propogation.directive';
import { ClickPreventDefaultDirective } from '../../directives/click-prevent-default.directive';

import { CompanySelectorComponent } from '../../components/company-selector/company-selector.component';
import { UserSelectorComponent } from '../../components/user-selector/user-selector.component';
import { OLDEquipmentTypeSelectorComponent } from '../../components/equipment-type-selector/equipment-type-selector.component';
import { EquipmentTypeMultiselectComponent } from '../../components/equipment-type-multiselect/equipment-type-multiselect.component';
import { StateSelectorComponent } from '../../components/state-selector/state-selector.component';
import { DateTimeSelectorComponent } from '../../components/date-time-selector/date-time-selector.component';

import { DocumentComponent } from '../../components/document/document.component';
import { DocumentAddComponent } from '../../components/document/add/add.component';
import { DocumentAddDialogComponent } from '../../components/document/document-add-dialog/document-add-dialog.component';
import { PdfUtilityComponent } from '../../components/pdf-utility/pdf-utility.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocumentMailComponent } from '../../components/document/mail/mail.component';

// PIPES
import { TitleCasePipe } from '../pipes/title-case';
import { FormatDateUtc } from '../pipes/formatDateUtc';
import { FormatCurrency } from '../pipes/formatCurrency';
import { FormatDateFromDBString } from '../pipes/formatDateFromDBString';
import { FormatPhone } from '../pipes/formatPhone';
import { FormatErrorMessage } from '../pipes/formatErrorMessage';

// SETTINGS
import { SettingsComponent } from '../../modules/settings/main/main.component';
import { TenantSettingsComponent } from '../../modules/tenant/settings/settings.component';
import { TenantBillingStatusComponent } from '../../modules/tenant/billing-status/billing-status.component';
import { TenantFactoringStatusComponent } from '../../modules/tenant/factoring-status/factoring-status.component';
import { ConfirmationService } from 'primeng/api';
import { FormatBuyStatus } from '../pipes/formatBuyStatus';
import { AddressMatchingOverlayComponent } from '../../components/address-matching-overlay/address-matching-overlay.component';
import { FactoringStatusIndicatorComponent } from '../../modules/tenant/factoring-status-indicator/factoring-status-indicator.component';
import { LoadStatusComponent } from '../../components/load-status/load-status.component';
import { FundingStatusIconComponent } from '../../components/funding-status-icon/funding-status-icon.component';
import { LoadStatusOverlayComponent } from '../../components/load-status/load-status-overlay/load-status-overlay.component';
import { NoticeOfAssignmentDialogComponent } from '../../modules/tenant/notice-of-assignment-dialog/notice-of-assignment-dialog.component';
import { FileUploadComponent } from '../../components/file-upload/file-upload.component';
import { NoticeOfAssignmentComponent } from '../../modules/tenant/notice-of-assignment/notice-of-assignment.component';
import { AccountExecutivesComponent } from '../../modules/tenant/account-executives/account-executives.component';
import { BulkActionComponent } from 'src/app/components/bulk-action/bulk-action.component';
import { PasswordConfirmationComponent } from 'src/app/components/confirm-password/password-confirmation.component';
import { PasswordComponent } from 'src/app/components/password/password.component';
import { PhoneNumberV2Component } from 'src/app/components/phone-number-v2/phone-number-v2.component';
import { UserChangePasswordComponent } from 'src/app/components/user-list/user-change-password/user-change-password.component';
import { PlatformNotificationComponent } from 'src/app/components/platform-notification/platform-notification.component';
import { PercentageFlatRateComponent } from 'src/app/components/percentage-flat-rate/percentage-flat-rate.component';
import { TenantAccountingComponent } from 'src/app/modules/tenant/accounting/accounting.component';
import { AccountingContactAddressComponent } from 'src/app/modules/tenant/accounting/accounting-contact-address/accounting-contact-address.component';
import { SettingsNotesComponent } from 'src/app/modules/tenant/settings-notes/settings-notes.component';
import { TenantNotesComponent } from 'src/app/modules/tenant/tenant-notes/tenant-notes.component';
import { NotesComponent } from 'src/app/modules/tenant/notes/notes.component';
import { SearchComponent } from 'src/app/shared/components/search/search.component';
import { DateRangeSelectorComponent } from 'src/app/components/date-range-selector/date-range-selector.component';
import { CurrencyComponent } from 'src/app/components/currency/currency.component';
import { RenderRequiresPermissionDirective } from 'src/app/directives/authorization/render-requires-permission/render-requires-permission.directive';
import { StatusFilterComponent } from 'src/app/shared/components/status-filter/status-filter.component';
import { FormatTimestampString } from '../pipes/format-timestamp/formatTimestampString';
import { LoadActionDialogComponent } from '../load/components/list/load-action-dialog/load-action-dialog.component';
import { LoadFilterComponent } from '../load/components/list/load-filter/load-filter.component';
import { FilterDateSelectorComponent } from '../load/components/list/load-filter/filter-date-selector/filter-date-selector.component';
import { LoadStatusDropdownComponent } from '../load/components/edit/load-status-dropdown/load-status-dropdown.component';
import { LoadRateComponent } from '../load/components/edit/load-rate/load-rate.component';
import { TallyboxComponent } from '../load/components/edit/tallybox/tallybox.component';
import { TallyitemComponent } from '../load/components/edit/tallybox/tallyitem/tallyitem.component';
import { UpdateLegTrackingDialogComponent } from '../load/components/edit/legs/leg/update-leg-tracking-dialog/update-leg-tracking-dialog.component';
import { StopComponent } from '../load/components/edit/legs/leg/stops/stop/stop.component';
import { LegComponent } from '../load/components/edit/legs/leg/leg.component';
import { StopsComponent } from '../load/components/edit/legs/leg/stops/stops.component';
import { LegsComponent } from '../load/components/edit/legs/legs.component';
import { LegTrackingComponent } from '../load/components/edit/legs/leg/leg-tracking/leg-tracking.component';
import { CompanyAddressSelectorV2Component } from '../load/components/edit/company-address-selector-v2/company-address-selector-v2.component';
import { CompanyAddressQuickAddComponent } from '../load/components/edit/company-address-quick-add/company-address-quick-add.component';
import { BillToCompanyAddressSelectorComponent } from '../load/components/edit/bill-to-company-address-selector/bill-to-company-address-selector.component';
import { BillToLinkDebtorComponent } from '../load/components/edit/bill-to-company-address-selector/bill-to-link-debtor/bill-to-link-debtor.component';
import { CompanyAddressSelectorComponent } from '../load/components/edit/company-address-selector/company-address-selector.component';
import { FormatAddress } from '../pipes/format-address/formatAddress';
import { ActionBarComponent } from '../components/action-bar/action-bar.component';
import { BackComponent } from '../components/back/back.component';
import { RadioBooleanInputComponent } from '../components/radio-boolean-input/radio-boolean-input.component';
import { RateCategoryComponent } from '../load/components/edit/rate-category/rate-category.component';
import { EquipmentTypeSelectorComponent } from '../components/equipment-type-selector/equipment-type-selector.component';
import { FullNameComponent } from '../components/full-name/full-name.component';
import { TenantUserSelectorComponent } from '../components/tenant-user-selector/tenant-user-selector.component';
import { LoadingComponent } from '../components/loading/loading.component';

const componentsForDeclarationAndExport = [
  AddressComponent,
  AddressV2Component,
  AddressV3Component,
  ContactComponent,
  NotificationsComponent,
  PhoneNumberComponent,
  UserListComponent,
  UserEditComponent,
  DriverProfileListComponent,
  DriverProfileEditComponent,
  RecordsChecklistComponent,
  RecordComponent,
  TenantEditComponent,
  AdminEditComponent,
  TenantListComponent,
  TenantBillingStatusComponent,
  TenantFactoringStatusComponent,
  PfsTableBodyComponent,
  EditSectionComponent,
  EditSectionRowComponent,
  FormFieldComponent,
  FormChargesComponent,
  FormChargeComponent,
  TitleCasePipe,
  ClickStopPropogationDirective,
  ClickPreventDefaultDirective,
  CompanySelectorComponent,
  DebugFormGroupComponent,
  UserSelectorComponent,
  DocumentComponent,
  DocumentAddComponent,
  DocumentAddDialogComponent,
  OLDEquipmentTypeSelectorComponent,
  EquipmentTypeMultiselectComponent,
  StateSelectorComponent,
  AddressMatchingOverlayComponent,
  DocumentMailComponent,
  PdfUtilityComponent,
  FormatDateUtc,
  FormatCurrency,
  FormatPhone,
  FormatBuyStatus,
  FormatDateFromDBString,
  FormatErrorMessage,
  DateTimeSelectorComponent,
  SettingsComponent,
  TenantSettingsComponent,
  FactoringStatusIndicatorComponent,
  LoadStatusComponent,
  FundingStatusIconComponent,
  LoadStatusOverlayComponent,
  NoticeOfAssignmentDialogComponent,
  FileUploadComponent,
  NoticeOfAssignmentComponent,
  AccountExecutivesComponent,
  BulkActionComponent,
  PasswordConfirmationComponent,
  PasswordComponent,
  PhoneNumberV2Component,
  UserChangePasswordComponent,
  PlatformNotificationComponent,
  PercentageFlatRateComponent,
  TenantAccountingComponent,
  AccountingContactAddressComponent,
  SettingsNotesComponent,
  TenantNotesComponent,
  NotesComponent,
  SearchComponent,
  DateRangeSelectorComponent,
  CurrencyComponent,
  RenderRequiresPermissionDirective,
  StatusFilterComponent,
  FormatTimestampString,
  LoadActionDialogComponent,
  LoadFilterComponent,
  FilterDateSelectorComponent,
  LoadStatusDropdownComponent,
  LoadRateComponent,
  TallyboxComponent,
  TallyitemComponent,
  UpdateLegTrackingDialogComponent,
  StopComponent,
  LegComponent,
  StopsComponent,
  LegsComponent,
  LegTrackingComponent,
  CompanyAddressSelectorV2Component,
  CompanyAddressQuickAddComponent,
  BillToCompanyAddressSelectorComponent,
  BillToLinkDebtorComponent,
  CompanyAddressSelectorComponent,
  FormatAddress,
  ActionBarComponent,
  BackComponent,
  RadioBooleanInputComponent,
  RateCategoryComponent,
  EquipmentTypeSelectorComponent,
  FullNameComponent,
  TenantUserSelectorComponent,
  LoadingComponent,
];

@NgModule({
  imports: [PrimeNgModule, FormsModule, ReactiveFormsModule, CommonModule, PdfViewerModule],
  declarations: [...componentsForDeclarationAndExport],
  exports: [PrimeNgModule, ...componentsForDeclarationAndExport],
  providers: [ConfirmationService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
