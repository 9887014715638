import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  public getUTCDate(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  public getFormattedUTCDate(date: Date): string {
    const utcDate = this.getUTCDate(date);

    return (
      utcDate.getMonth() +
      1 +
      '/' +
      utcDate.getDate() +
      '/' +
      utcDate.getFullYear() +
      ' ' +
      utcDate.toLocaleTimeString().replace(/\u200E/g, '')
    );
  }
}
