<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<form class="grid w-full sm:w-10 md:w-8 xl:w-5 m-auto" [formGroup]="confirmPasswordForm">
  <div class="col-12 pb-4">
    <h1>Confirm Password</h1>
  </div>

  <div class="col-12">
    <app-form-field
      label="Confirmation Code"
      type="text"
      keyFilter="number"
      labelClass="col-12 p-0"
      controlClass="col-12"
      errorClass="col-12"
      [formGroup]="confirmPasswordForm"
      [formErrors]="formErrors"
      autoComplete="off"
      formControlName="code"
    ></app-form-field>
  </div>
  <app-password-confirmation class="col-12" [formGroup]="confirmPasswordForm"></app-password-confirmation>

  <div class="col-12">
    <div class="confirmforgotpassbuttons">
      <button
        pButton
        class="w-full"
        type="submit"
        label="Reset Password"
        icon="fa fa-check"
        (click)="resetPassword()"
        [disabled]="!(isFormValid$ | async) || showProgressOverlay"
      ></button>
    </div>
  </div>
</form>
