import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-edit-section-row',
  templateUrl: './edit-section-row.component.html',
  styleUrls: ['./edit-section-row.component.scss'],
})
export class EditSectionRowComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  description: string;

  @Input()
  editMode: boolean;

  @Input()
  labelClass: string = 'col-4';

  @Input()
  bodyClass: string = 'col-8';

  constructor() {}

  ngOnInit() {}
}
