<p-toast>
  <ng-template let-message pTemplate="message">
    <span class="ui-toast-icon pi" [ngClass]="getIcon(message.severity)"></span>
    <div
      class="ui-toast-message-text-content"
      [ngClass]="message.data.clickable ? 'clickable-toast-content' : ''"
      (click)="clicked(message.data)"
    >
      <div class="ui-toast-summary">{{ message.summary }}</div>
      <div class="ui-toast-detail address-info">
        {{ message.detail }}
        <p *ngIf="message.data.clickable && message.data.linkText" class="toast-link-text">{{
          message.data.linkText
        }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
