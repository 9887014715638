<div class="centered-card-container-small" *ngIf="gettingUser" id="processing-confirmation">
  <p-card [style]="{ padding: '10%' }">
    <div class="header-text">Processing Confirmation</div>
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  </p-card>
</div>

<app-user-registration-wizard
  *ngIf="!gettingUser && !unableToGetUser"
  [tempUser]="tempUser"
></app-user-registration-wizard>

<app-unable-to-get-user *ngIf="unableToGetUser"></app-unable-to-get-user>
