import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ReportingWizardOptions } from './reporting-wizard-options';
import { ClientTokenService } from 'src/services/client-token-service';
import { Role } from 'src/app/data/static-data';

@Component({
  selector: 'app-reporting-wizard-options',
  templateUrl: './reporting-wizard-options.component.html',
  styleUrls: ['./reporting-wizard-options.component.scss'],
})
export class ReportingWizardOptionsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public readonly checkboxFields: { formControlName: string; label: string; toolTip: string }[] = [
    { formControlName: ReportingWizardOptions.Driver, label: 'Driver', toolTip: null },
    { formControlName: ReportingWizardOptions.BillTo, label: 'Bill To/Customer', toolTip: null },
    { formControlName: ReportingWizardOptions.Shipper, label: 'Shipper', toolTip: null },
    { formControlName: ReportingWizardOptions.Consignee, label: 'Consignee', toolTip: null },
    { formControlName: ReportingWizardOptions.Dispatcher, label: 'Dispatcher', toolTip: null },
    { formControlName: ReportingWizardOptions.TruckNumber, label: 'Truck Number', toolTip: null },
    { formControlName: ReportingWizardOptions.TrailerNumber, label: 'Trailer Number', toolTip: null },
    { formControlName: ReportingWizardOptions.EquipmentType, label: 'Equipment Type', toolTip: null },
    { formControlName: ReportingWizardOptions.Invoiced, label: 'Invoiced', toolTip: null },
    { formControlName: ReportingWizardOptions.Delivered, label: 'Delivered', toolTip: null },
    {
      formControlName: ReportingWizardOptions.TotalLoadedMiles,
      label: 'Total Loaded Miles',
      toolTip:
        'Summary load-level reports will reflect the Total Loaded Miles; while driver-specific reports will use total of leg-level loaded miles for the driver',
    },
    {
      formControlName: ReportingWizardOptions.FuelSurcharge,
      label: 'Fuel Surcharge',
      toolTip: 'Fuel Surcharge is based on selection of calculation method (1) % of Rate or (2) Rate * Miles',
    },
    {
      formControlName: ReportingWizardOptions.OtherCharges,
      label: 'Other Charges',
      toolTip: 'Sum of the entered Other Charges line items',
    },
    { formControlName: ReportingWizardOptions.Rate, label: 'Rate', toolTip: 'Rate field on the load' },
    {
      formControlName: ReportingWizardOptions.TotalRevenue,
      label: 'Total Revenue',
      toolTip: 'Sum of Rate, Fuel Surcharge, Other Charges',
    },
    {
      formControlName: ReportingWizardOptions.RatePerMile,
      label: 'Rate Per Mile',
      toolTip: 'Total revenue divided by loaded miles',
    },
    {
      formControlName: ReportingWizardOptions.DriverGrossPay,
      label: 'Driver Gross Pay',
      toolTip: 'Total of gross pay field for a specific driver',
    },
    {
      formControlName: ReportingWizardOptions.Margin,
      label: 'Margin',
      toolTip: 'Total Revenue minus gross driver pay for all drivers on a load',
    },
  ];
  public noCheckboxesChecked$: Observable<boolean>;
  public allCheckboxesChecked$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();
  private onChange(selectedCheckboxes: string[]) {}

  constructor(private control: NgControl, private clientTokenService: ClientTokenService) {
    this.control.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.formGroup = this.createFormGroup();
    this.noCheckboxesChecked$ = this.checkboxesHaveSameValue(false);
    this.allCheckboxesChecked$ = this.checkboxesHaveSameValue(true);
    this.conditionallyDisableOptionsBasedOnRole();
    this.emitOnChange();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectAll(): void {
    this.setValueForAllCheckboxes(true);
  }

  public deselectAll(): void {
    this.setValueForAllCheckboxes(false);
  }

  public writeValue(obj: any): void {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
    isDisabled ? this.deselectAll() : this.conditionallyDisableOptionsBasedOnRole();
  }

  private createFormGroup(): FormGroup {
    const formGroup = new FormGroup({});
    this.checkboxFields.forEach((c) => formGroup.addControl(c.formControlName, new FormControl(false)));
    return formGroup;
  }

  private checkboxesHaveSameValue(value: boolean): Observable<boolean> {
    return this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map((formValue) => Object.keys(formValue).map<boolean>((k) => formValue[k])),
      map((checkboxValues) => checkboxValues.every((c) => c === value))
    );
  }

  private setValueForAllCheckboxes(value: boolean): void {
    const updatedFormValue: Record<string, boolean> = {};
    this.checkboxFields.forEach(
      (c) => (updatedFormValue[c.formControlName] = this.formGroup.controls[c.formControlName].enabled ? value : false)
    );
    this.formGroup.setValue(updatedFormValue);
  }

  private emitOnChange(): void {
    this.formGroup.valueChanges
      .pipe(
        map((formValue) => Object.keys(formValue).filter((k) => formValue[k])),
        takeUntil(this.destroy$)
      )
      .subscribe((checkedCheckboxes) => this.onChange(checkedCheckboxes));
  }

  private conditionallyDisableOptionsBasedOnRole(): void {
    const isUserAllowedToAccessDriverGrossPay =
      this.clientTokenService.hasRole(Role.SuperAdmin) ||
      this.clientTokenService.hasRole(Role.Admin) ||
      this.clientTokenService.hasRole(Role.Accounting);

    const conditionalOptions = [ReportingWizardOptions.DriverGrossPay, ReportingWizardOptions.Margin];
    conditionalOptions.forEach((o) =>
      isUserAllowedToAccessDriverGrossPay ? this.formGroup.controls[o].enable() : this.formGroup.controls[o].disable()
    );
  }
}
