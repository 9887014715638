/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TenantAttachmentDTO } from '../models/tenant-attachment-dto';
@Injectable({
  providedIn: 'root',
})
class TenantAttachmentService extends __BaseService {
  static readonly GetPath = '/api/TenantAttachment/{tenantId}/attachments';
  static readonly GetByIdPath = '/api/TenantAttachment/{attachmentId}';
  static readonly DeletePath = '/api/TenantAttachment/{attachmentId}';
  static readonly UploadPath = '/api/TenantAttachment/{tenantId}/attachments/{fileType}';
  static readonly UploadAndOverwritePath = '/api/TenantAttachment/{tenantId}/attachments/{attachmentId}/{fileType}';
  static readonly DownloadPath = '/api/TenantAttachment/{attachmentId}/download';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns tenant attachments
   * @param params The `TenantAttachmentService.GetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`:
   *
   * @return Success
   */
  GetResponse(params: TenantAttachmentService.GetParams): __Observable<__StrictHttpResponse<Array<TenantAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.tenantId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantAttachmentDTO>>;
      })
    );
  }
  /**
   * Returns tenant attachments
   * @param params The `TenantAttachmentService.GetParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`:
   *
   * @return Success
   */
  Get(params: TenantAttachmentService.GetParams): __Observable<Array<TenantAttachmentDTO>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<TenantAttachmentDTO>)
    );
  }

  /**
   * Returns a tenant attachment
   * @param params The `TenantAttachmentService.GetByIdParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetByIdResponse(params: TenantAttachmentService.GetByIdParams): __Observable<__StrictHttpResponse<TenantAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAttachmentDTO>;
      })
    );
  }
  /**
   * Returns a tenant attachment
   * @param params The `TenantAttachmentService.GetByIdParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetById(params: TenantAttachmentService.GetByIdParams): __Observable<TenantAttachmentDTO> {
    return this.GetByIdResponse(params).pipe(
      __map(_r => _r.body as TenantAttachmentDTO)
    );
  }

  /**
   * Deletes an attachment for a tenant
   * @param params The `TenantAttachmentService.DeleteParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteResponse(params: TenantAttachmentService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes an attachment for a tenant
   * @param params The `TenantAttachmentService.DeleteParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   */
  Delete(params: TenantAttachmentService.DeleteParams): __Observable<null> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Uploads an attachment to a tenant
   * @param params The `TenantAttachmentService.UploadParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `fileType`:
   *
   * - `file`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  UploadResponse(params: TenantAttachmentService.UploadParams): __Observable<__StrictHttpResponse<TenantAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.tenantId))}/attachments/${encodeURIComponent(String(params.fileType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAttachmentDTO>;
      })
    );
  }
  /**
   * Uploads an attachment to a tenant
   * @param params The `TenantAttachmentService.UploadParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `fileType`:
   *
   * - `file`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  Upload(params: TenantAttachmentService.UploadParams): __Observable<TenantAttachmentDTO> {
    return this.UploadResponse(params).pipe(
      __map(_r => _r.body as TenantAttachmentDTO)
    );
  }

  /**
   * Uploads a new version of an existing attachment to a tenant
   * @param params The `TenantAttachmentService.UploadAndOverwriteParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `fileType`:
   *
   * - `file`:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  UploadAndOverwriteResponse(params: TenantAttachmentService.UploadAndOverwriteParams): __Observable<__StrictHttpResponse<TenantAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.tenantId))}/attachments/${encodeURIComponent(String(params.attachmentId))}/${encodeURIComponent(String(params.fileType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAttachmentDTO>;
      })
    );
  }
  /**
   * Uploads a new version of an existing attachment to a tenant
   * @param params The `TenantAttachmentService.UploadAndOverwriteParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `fileType`:
   *
   * - `file`:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  UploadAndOverwrite(params: TenantAttachmentService.UploadAndOverwriteParams): __Observable<TenantAttachmentDTO> {
    return this.UploadAndOverwriteResponse(params).pipe(
      __map(_r => _r.body as TenantAttachmentDTO)
    );
  }

  /**
   * Downloads an attachment for a tenant
   * @param params The `TenantAttachmentService.DownloadParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  DownloadResponse(params: TenantAttachmentService.DownloadParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TenantAttachment/${encodeURIComponent(String(params.attachmentId))}/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Downloads an attachment for a tenant
   * @param params The `TenantAttachmentService.DownloadParams` containing the following parameters:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  Download(params: TenantAttachmentService.DownloadParams): __Observable<Blob> {
    return this.DownloadResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module TenantAttachmentService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    fileType?: 'NOA';
  }

  /**
   * Parameters for GetById
   */
  export interface GetByIdParams {
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Upload
   */
  export interface UploadParams {
    tenantId: number;
    fileType: 'NOA';
    file: Blob;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UploadAndOverwrite
   */
  export interface UploadAndOverwriteParams {
    tenantId: number;
    fileType: 'NOA';
    file: Blob;
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Download
   */
  export interface DownloadParams {
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { TenantAttachmentService }
