<div
  class="funding-status-container"
  (mouseenter)="displayOverlay() ? showOverlay($event) : ''"
  (mouseleave)="displayOverlay() ? hideOverlayWithDelay() : ''"
>
  <span>{{ status }}</span>
  <app-funding-status-icon class="funding-status-icon" [status]="status"></app-funding-status-icon>
</div>

<p-overlayPanel #overlay appendTo="body" [style]="{ 'min-width': '20vw' }">
  <app-load-status-overlay
    (mouseenter)="displayOverlay() ? showOverlay($event) : ''"
    (mouseleave)="displayOverlay() ? hideOverlay() : ''"
    [status]="status"
  ></app-load-status-overlay>
</p-overlayPanel>
