import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { State, StatesService } from 'src/services/states-service';
import { AddressViewMode } from './models/address-view-mode';
import { AddressFormFields } from './models/address-form-fields';
import { tap } from 'rxjs/operators';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { Subject } from 'rxjs';
import { FullAddressDTO } from 'src/apiclient/v1.1/models';
import { AddressV3Validator } from './validator/address-v3.validator';
import { ObjectService } from 'src/services/object-service/object.service';

@Component({
  selector: 'app-address-v3',
  templateUrl: './address-v3.component.html',
  styleUrls: ['./address-v3.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressV3Component),
      multi: true,
    },
  ],
})
export class AddressV3Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() viewMode: AddressViewMode = AddressViewMode.TwoColumns;
  @Input() labelClass: string = 'md:col-4 sm:col-12 p-2 pl-0';
  @Input() controlClass: string = 'md:col-8 sm:col-12 px-0';
  @Input() errorClass: string = 'col-12';
  @Input() formErrors: any = {};
  @Input() errorUnderInput: boolean = false;
  @Input() validationMessages: Object[] = [];
  @Input() set touched(value: boolean) {
    if (value && this.formGroup) {
      this.formGroup.markAllAsTouched();
    }
  }
  @Input() set dirty(value: boolean) {
    if (value && this.formGroup) {
      Object.keys(this.formGroup.controls).forEach((key) => this.formGroup.controls[key].markAsDirty());
    }
  }

  public formGroup: FormGroup;
  public readonly addressViewMode = AddressViewMode;
  public readonly addressFormFields = AddressFormFields;

  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StatesService,
    private formValidationErrorsService: FormValidationErrorsService
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [AddressFormFields.Line1]: ['', [Validators.required]],
      [AddressFormFields.Line2]: [''],
      [AddressFormFields.City]: ['', [Validators.required]],
      [AddressFormFields.StateOrTerritory]: ['', [Validators.required]],
      [AddressFormFields.PostalCode]: ['', [Validators.required]],
      [AddressFormFields.Country]: [],
    });
    this.initCountryValidation();
    this.initFormSubscriptions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: FullAddressDTO): void {
    this.formGroup.controls[AddressFormFields.Line1].setValue(ObjectService.getPropertyOrNull(value, 'line1'));
    this.formGroup.controls[AddressFormFields.Line2].setValue(ObjectService.getPropertyOrNull(value, 'line2'));
    this.formGroup.controls[AddressFormFields.City].setValue(ObjectService.getPropertyOrNull(value, 'city'));
    this.formGroup.controls[AddressFormFields.StateOrTerritory].setValue(
      ObjectService.getPropertyOrNull(value, 'stateOrTerritory')
    );
    this.formGroup.controls[AddressFormFields.PostalCode].setValue(
      ObjectService.getPropertyOrNull(value, 'postalCode')
    );
    this.formGroup.controls[AddressFormFields.Country].setValue(ObjectService.getPropertyOrNull(value, 'country'));

    this.initCountryValidation();
  }

  private onChange = (value: FullAddressDTO | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  public onStateChange(event: State) {
    if (event && event.country) {
      const country = event.country;

      this.setZipValidator(country);
    }
  }

  private initFormSubscriptions(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(() => this.setCurrentAddressFormErrors()),
        tap((value) => this.onChange(value))
      )
      .subscribe();
  }

  private setCurrentAddressFormErrors() {
    this.formErrors = this.formValidationErrorsService.getFormErrors(this.formGroup, this.validationMessages);
  }

  private async initCountryValidation(): Promise<void> {
    const currentState = this.formGroup?.controls[AddressFormFields.StateOrTerritory];

    if (currentState && currentState.value) {
      const state = await this.stateService.getStateByAbbreviation(currentState.value);
      this.onStateChange(state);
    } else {
      this.setZipValidator('US');
    }
  }

  private setZipValidator(country: string): void {
    this.formGroup.controls[AddressFormFields.Country].setValue(country);
    this.formGroup.controls[AddressFormFields.PostalCode].clearValidators();
    const zipPattern = AddressV3Validator.getCurrentZipPattern(country);
    let zipPatternMessage;
    if (country === 'CA') {
      zipPatternMessage = 'Must be in the formats: T2X 1V4 or T2X1V4';
    } else {
      zipPatternMessage = 'Must be in the formats: 12345, 12345-1234, or 12345 1234';
    }
    const theValidator = Validators.pattern(zipPattern);

    this.validationMessages[AddressFormFields.PostalCode] = {
      pattern: zipPatternMessage,
    };

    this.formGroup.controls[AddressFormFields.PostalCode].setValidators([theValidator, Validators.required]);
    this.formGroup.controls[AddressFormFields.PostalCode].updateValueAndValidity();
  }
}
