import { Injectable } from '@angular/core';

@Injectable()
export class ScrollManagerService {
  resetScreenScroll() {
    const screenScrollNodez = document.getElementsByTagName('html');
    for (let x = 0; x < screenScrollNodez.length; x++) {
      screenScrollNodez[x].scrollTop = 0;
    }
  }

  resetDialogScroll() {
    const dialogPanels = document.getElementsByClassName('ui-dialog-content');
    for (let x = 0; x < dialogPanels.length; x++) {
      dialogPanels[x].scrollTop = 0;
    }
  }
}
