import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneDTO } from '../../../apiclient/models/phone-dto';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidatorsService } from '../../core/validators-service';
import { ApiValidators } from '../../../utils/api-validators';
import { Utils } from '../../../utils/utils';
import { FormBase } from '../../shared/FormBase';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css'],
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  phoneValidators: any;
  phoneFormErrorsTemplate = {
    number: '',
    ext: '',
  };

  // Need these so we can share the same validation objects as the parent
  @Input() formGroup: FormGroup;
  @Input() formErrors: any;
  @Input() validationMessages: any;

  // Prefix is the property name on the parent. Need this to handle 400 responses from API
  @Input() prefix: string;

  // View model
  @Input() vm: PhoneDTO = { number: '', ext: '' };

  // UI sizing controls passed from parent template
  @Input() labelClass?: string = '';
  @Input() controlClass?: string = '';
  @Input() inputClass: string = '';
  @Input() errorClass?: string = '';
  @Input() layout?: string = 'vertical';
  @Input() editMode?: boolean;
  @Input() showFloatLabels?: boolean = true;
  @Input() config: any = {
    boldLabel: true,
  };
  @Input() labelOnTop?: boolean = false;
  @Input() shouldValidateOnBlur?: boolean = false;

  constructor(private validatorsService: ValidatorsService, fb: FormBuilder, _NS: NotificationsService) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formGroup.controls[this.prefix + '-number']) {
      this.init();
    }
  }

  init() {
    this.phoneValidators = this.validatorsService.getByName('PhoneDTO');
    this.initFormErrors();
    this.addValidationMessages();

    this.formGroup.addControl(this.prefix + '-number', new FormControl(this.vm.number));
    this.formGroup.addControl(this.prefix + '-ext', new FormControl(this.vm.ext));
    this.formGroup.controls[this.prefix + '-number'].setValidators(
      ApiValidators.getValidatorsForFormField('number', this.phoneValidators)
    );
    this.formGroup.controls[this.prefix + '-ext'].setValidators(
      ApiValidators.getValidatorsForFormField('ext', this.phoneValidators)
    );
  }

  addValidationMessages() {
    const addrValidationMessages = ApiValidators.getValidationMessagesForFormGroup(this.phoneValidators);
    for (const messageKey of Object.keys(addrValidationMessages)) {
      this.validationMessages[this.prefix + '-' + messageKey] = addrValidationMessages[messageKey];
    }
  }

  initFormErrors() {
    for (const errorKey of Object.keys(this.phoneFormErrorsTemplate)) {
      this.formErrors[this.prefix + '-' + errorKey] = '';
    }
  }

  validateOnBlur(event: any) {
    if (this.shouldValidateOnBlur) {
      this.formGroup.controls[event.srcElement.getAttribute('ng-reflect-name')].markAsDirty();
      this.formGroup.controls[event.srcElement.getAttribute('ng-reflect-name')].updateValueAndValidity();
    }
  }
}
