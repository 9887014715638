import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { AddressDTO, PhoneDTO, RegisterTenantDTO, TempUser } from 'src/apiclient/v1.1/models';
import { RegisterService } from 'src/apiclient/v1.1/services';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { AppConfig } from 'src/app/config/app.config';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { FormSanitizationService } from 'src/services/form-sanitization/form-sanitization.service';
import { RegistrationFields } from './registration-fields';
import { WizardNavigationDirection } from './wizard-navigation-direction';
import { WizardStep } from './wizard-step';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';

@Component({
  selector: 'app-user-registration-wizard',
  templateUrl: './user-registration-wizard.component.html',
  styleUrls: ['./user-registration-wizard.component.scss'],
})
export class UserRegistrationWizardComponent implements OnInit, OnDestroy {
  @Input() tempUser: TempUser;

  public wizardSteps: WizardStep[];
  public currentStepIndex: number = 0;
  public registrationInProgress = false;
  public navigationDirection: WizardNavigationDirection;

  private destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private formSanitizationService: FormSanitizationService,
    private registerService: RegisterService,
    private router: Router,
    private notificationService: NotificationsService,
    private errorHandlingService: ErrorHandlingService,
    private formValidationErrorsService: FormValidationErrorsService,
    private formValidationService: FormValidationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.setWizardSteps();
    this.transitionForm(0);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public previousStep() {
    this.navigationDirection = WizardNavigationDirection.Previous;
    this.transitionForm(this.currentStepIndex - 1);
  }

  public nextStep() {
    if (this.canFormTransition()) {
      if (this.currentStepIndex < this.wizardSteps.length - 1) {
        this.navigationDirection = WizardNavigationDirection.Next;
        this.transitionForm(this.currentStepIndex + 1);
      } else {
        this.completeRegistration();
      }
    }
  }

  public getStepForm(stepIndex: number): FormGroup {
    return this.wizardSteps[stepIndex]?.stepForm;
  }

  public getCurrentStep(): WizardStep {
    return this.wizardSteps[this.currentStepIndex];
  }

  public transitionForm(newIndex: number): void {
    const currentStep = this.wizardSteps[this.currentStepIndex];
    this.formSanitizationService.sanitizeForm(currentStep.stepForm, currentStep.fieldsNotToSanitize);

    this.currentStepIndex = newIndex;
    const newStep = this.wizardSteps[this.currentStepIndex];

    newStep.stepForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.setCurrentFormErrors());

    this.manuallyDetectChangesIncaseAChangeHasBeenMadeInAChildComponentDuringTransition();
  }

  private canFormTransition(): boolean {
    const currentForm = this.getStepForm(this.currentStepIndex);
    const isFormValid = this.formValidationService.validateForm(currentForm);
    this.setCurrentFormErrors();

    return isFormValid;
  }

  public completeRegistration() {
    this.registrationInProgress = true;
    const registrationObject = this.createRegistrationObject();

    this.registerService
      .Register(registrationObject)
      .pipe(
        tap(() => this.handleSuccessfulRegistration()),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.registrationInProgress = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleSuccessfulRegistration(): void {
    this.notificationService.success('Registration Complete', 'Login to get started with your new account.');
    const route = this.tempUser.confirmationCode ? AppConfig.routes.login.default : AppConfig.routes.dashboard;
    this.router.navigate([route]);
  }

  public createRegistrationObject() {
    let rawRegistrationObject = {};
    this.wizardSteps.forEach((step) => {
      const rawStepForm = step.stepForm.getRawValue();
      rawRegistrationObject = { ...rawStepForm, ...rawRegistrationObject };
    });

    const registerTenant: RegisterTenantDTO = {
      acceptEula: rawRegistrationObject[RegistrationFields.AcceptEula],
      companyLegalName: rawRegistrationObject[RegistrationFields.CompanyLegalName],
      email: rawRegistrationObject[RegistrationFields.Email],
      existingEmail: rawRegistrationObject[RegistrationFields.ExistingEmail],
      firstName: this.tempUser.firstName,
      lastName: this.tempUser.lastName,
      motorCarrierNumber: rawRegistrationObject[RegistrationFields.MotorCarrierNumber],
      usDotNumber: rawRegistrationObject[RegistrationFields.UsDotNumber],
      numberOfTrucks: rawRegistrationObject[RegistrationFields.NumberOfTrucks],
      password: rawRegistrationObject[RegistrationFields.Password],
      phone: {
        number: rawRegistrationObject[RegistrationFields.Phone],
        ext: rawRegistrationObject[RegistrationFields.PhoneExtension],
      } as PhoneDTO,
      mailing: {
        line1: rawRegistrationObject[RegistrationFields.MailingAddress]?.line1,
        line2: rawRegistrationObject[RegistrationFields.MailingAddress]?.line2,
        city: rawRegistrationObject[RegistrationFields.MailingAddress]?.city,
        state: rawRegistrationObject[RegistrationFields.MailingAddress]?.stateOrTerritory,
        zip: rawRegistrationObject[RegistrationFields.MailingAddress]?.postalCode,
      } as AddressDTO,
      physical: {
        line1: rawRegistrationObject[RegistrationFields.PhysicalAddress]?.line1,
        line2: rawRegistrationObject[RegistrationFields.PhysicalAddress]?.line2,
        city: rawRegistrationObject[RegistrationFields.PhysicalAddress]?.city,
        state: rawRegistrationObject[RegistrationFields.PhysicalAddress]?.stateOrTerritory,
        zip: rawRegistrationObject[RegistrationFields.PhysicalAddress]?.postalCode,
      } as AddressDTO,
    };

    this.tryUpdatePhysicalAddress(registerTenant, rawRegistrationObject);

    return registerTenant;
  }

  private tryUpdatePhysicalAddress(registerTenant: RegisterTenantDTO, rawRegistrationObject: Object): void {
    if (rawRegistrationObject[RegistrationFields.SameAsMailing]) {
      registerTenant.physical = {
        line1: rawRegistrationObject[RegistrationFields.MailingAddress]?.line1,
        line2: rawRegistrationObject[RegistrationFields.MailingAddress]?.line2,
        city: rawRegistrationObject[RegistrationFields.MailingAddress]?.city,
        state: rawRegistrationObject[RegistrationFields.MailingAddress]?.stateOrTerritory,
        zip: rawRegistrationObject[RegistrationFields.MailingAddress]?.postalCode,
      };
    }
  }

  private setCurrentFormErrors() {
    const currentStep = this.getCurrentStep();
    currentStep.formErrors = this.formValidationErrorsService.getFormErrors(
      currentStep.stepForm,
      currentStep.customValidationMessages
    );
  }

  private setWizardSteps(): void {
    this.wizardSteps = [
      {
        stepName: 'Company',
        stepProgressNumber: 1,
        stepForm: this.formBuilder.group({}),
        customValidationMessages: [],
        formErrors: {},
        fieldsNotToSanitize: [],
      },
      {
        stepName: 'Address',
        stepProgressNumber: 2,
        stepForm: this.formBuilder.group({}),
        customValidationMessages: [],
        formErrors: {},
        fieldsNotToSanitize: [],
      },
      {
        stepName: 'Address',
        stepForm: this.formBuilder.group({}),
        customValidationMessages: [],
        formErrors: {},
        fieldsNotToSanitize: [],
        hideFromProgress: true,
      },
      {
        stepName: 'EULA',
        stepProgressNumber: 3,
        stepForm: this.formBuilder.group({}),
        customValidationMessages: [],
        formErrors: {},
        fieldsNotToSanitize: [],
      },
      {
        stepName: 'Finalize',
        stepProgressNumber: 4,
        stepForm: this.formBuilder.group({}),
        customValidationMessages: [],
        formErrors: {},
        fieldsNotToSanitize: [],
      },
    ];
  }

  private manuallyDetectChangesIncaseAChangeHasBeenMadeInAChildComponentDuringTransition() {
    this.changeDetectorRef.detectChanges();
  }
}
