import { Component, OnInit, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CreateTokenDTO } from '../../../apiclient/models';
import { HttpErrorResponse } from '@angular/common/http';
import { DtoFormBase } from '../../shared/FormBase';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { UserFactoringService } from '../../../services/user-factoring/user-factoring.service';
import { AppConfig } from 'src/app/config/app.config';
import { MessageService } from 'primeng/api';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';
import { NavigationService } from 'src/services/navigation/navigation.service';
import { AuthenticationService } from 'src/apiclient/v1.1/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends DtoFormBase<CreateTokenDTO> implements OnInit {
  rememberUser: boolean;
  usernameBlur: boolean = false;
  passwordBlur: boolean = false;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    protected injector: Injector,
    private userFactoringService: UserFactoringService,
    private messageService: MessageService,
    private formValidationService: FormValidationService,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initFormFor('CreateTokenDTO');
    this.formGroup.addControl('rememberUser', new FormControl(this.rememberUser));
    this.formGroup.controls.username.markAsUntouched();
    this.getRememberMe();
  }

  async loginUser() {
    this.usernameBlur = true;
    this.passwordBlur = true;
    if (!this.validateForm()) {
      return;
    }

    this.setRememberMe();

    try {
      this.showProgressOverlay = true;
      const data = await this.authenticationService.Login(this.model).toPromise();
      this.showProgressOverlay = false;
      try {
        switch (data.message) {
          case 'NEW_PASSWORD_REQUIRED':
            this._NS.warn('New Password Required', 'You must change your password to continue logging in.');
            const userAndSession = this.model.username + ',' + data.session;
            this.router.navigate(['login/setnewpassword/' + userAndSession]);
            break;
          case 'USER_NOT_CONFIRMED':
            this._NS.warn('Account Not Confirmed', 'You must confirm your account to log in.');
            this.router.navigate(['registration/confirm/' + this.model.username]);
            break;
          case 'VERIFY_EMAIL':
            this._NS.warn(
              'Email Verification Required',
              'You have recently updated your email address and have not yet verified it. Please do so now.'
            );
            this.clientToken.setToken(data);
            this.router.navigate(['/login/verifyemail']);
            break;
          case null:
          case '':
            this.clientToken.setToken(data);

            // Create FactorCloud user
            if (!this.userFactoringService.hasFactorCloudUser()) {
              this.userFactoringService.createFactorCloudUser();
            }

            this.messageService.clear();
            this.navigationService.navigateToDefaultDashboard();
            break;
          default:
            this.logging.logError(new Error('Unknown challenge. Not logged in.'));
            break;
        }
      } catch (error) {
        // Probably driver or sales rep only role
        this.logging.logError(error);
        this._NS.error('Login Failed', error.message);
      }
    } catch (error) {
      this.customHandleError(error);
    }
  }

  private customHandleError = (error: HttpErrorResponse): void => {
    this.showProgressOverlay = false;
    switch (error.status) {
      case 403:
        this._NS.error('Login Failed', error.error);
        break;
      default:
        this.handleError(error);
    }
  };

  forgotPassword() {
    if (this.model.username) {
      this.router.navigate(['login/forgotpassword/' + this.model.username]);
    } else {
      this.router.navigate(['login/forgotpassword/']);
    }
  }

  public navigateToRegister(): void {
    this.router.navigate([AppConfig.routes.login.signUp]);
  }

  /**
   * If RememberMe is selected, puts username in local storage
   */
  setRememberMe(): void {
    if (this.rememberUser) {
      this.storage.set('login-remember-me', this.model.username);
    } else {
      this.storage.remove('login-remember-me');
    }
  }

  /**
   * Gets username from local storage (if exists)
   */
  getRememberMe(): void {
    this.model.username = this.storage.get('login-remember-me');
    if (this.model.username) {
      this.rememberUser = true;
    }
  }

  /**
   * Remove the user when checkbox un-checked, regardless of whether login was attempted.
   */
  rememberMeChanged(): void {
    if (!this.rememberUser) {
      this.storage.remove('login-remember-me');
    }
  }

  private validateForm(): boolean {
    return this.formValidationService.validateForm(this.formGroup) && this.formValid();
  }
}
