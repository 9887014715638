<div class="grid grid-nogutter col-12 p-0">
  <div class="md:col-4 sm:col-12 p-2 font-bold">Equipment Type</div>
  <div class="md:col-8 sm:col-12 p-1 px-0">
    <p-dropdown
      styleClass="col-12 p-0"
      [formControl]="equipmentControl"
      [options]="equipmentOptions$ | async"
      optionLabel="name"
      (autoDisplayFirst)="(false)"
      placeholder="Select Equipment"
    ></p-dropdown>
  </div>
</div>
