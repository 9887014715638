import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WizardStep } from '../wizard-step';

@Component({
  selector: 'app-wizard-navigation',
  templateUrl: './wizard-navigation.component.html',
  styleUrls: ['./wizard-navigation.component.scss'],
})
export class WizardNavigationComponent {
  @Input() disableNext: boolean = false;
  @Input() displayPrevious: boolean = true;
  @Input() nextButtonText: string = 'Next';

  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();

  constructor() {}

  public previous() {
    this.previousStep.emit();
  }

  public next() {
    this.nextStep.emit();
  }
}
