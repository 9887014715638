import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { format } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class Utils {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  // tslint:disable-next-line:max-line-length
  static EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
  static PASSWORD_MEDIUM_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  // tslint:disable-next-line:max-line-length
  static PHONE_REGEX =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

  // gmaps

  static GMAP_TRUCK_ICON =
    'M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z';
  static GMAP_MARKER =
    'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z';
  static GMAP_OPTIONS = [
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
  ];

  public static readonly supportLink = 'https://truxio.com/contact';

  static buildMarkerSymbol(fillColor: string): google.maps.Symbol {
    const symbol: google.maps.Symbol = {
      path: this.GMAP_TRUCK_ICON,
      fillColor: fillColor,
      fillOpacity: 1,
      strokeWeight: 1,
      strokeOpacity: 0.5,
      scale: 0.05,
      labelOrigin: new google.maps.Point(200, 200),
    };

    return symbol;
  }

  static fieldValue(obj, prop) {
    let value;

    if (prop.indexOf('+') > -1) {
      value = '';
      prop.split('+').forEach((field) => {
        value += ' ';
        value += field.split('.').reduce((r, val) => (r ? r[val] : undefined), obj);
      });
    } else {
      value = prop.split('.').reduce((r, val) => (r ? r[val] : undefined), obj);
    }

    return value;
  }

  public static sortData(event, data) {
    // event.data = Data to sort
    // event.mode = 'single' or 'multiple' sort mode
    // event.field = Sort field in single sort
    // event.order = Sort order in single sort
    // event.multiSortMeta = SortMeta array in multiple sort

    let field = event.field;

    if (event.sortableField) {
      field = event.sortableField;
    }

    data.sort((data1, data2) => {
      // const value1 = data1[field] this.fieldValue(1, 2);
      const value1 = this.fieldValue(data1, field);
      const value2 = this.fieldValue(data2, field);
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return event.order * result;
    });
  }

  public static formatPhoneJoin(value) {
    const number = value.number;
    const ext = value.ext;

    if (ext == null || ext === '') {
      return number;
    } else {
      return number + ', x' + ext;
    }
  }

  /**
   * Formats date for API
   * Does not use time-zone component
   */
  public static formatDate(date: Date): string {
    return date ? this.formatMoment(moment(date)) : null;
  }

  /**
   * Formats date for API
   * Use time zone component */
  public static formatDateUtc(date: Date): string {
    return date ? this.formatMomentUtc(moment(date)) : null;
  }

  /**
   * Formats date for API
   * Does not use time-zone component
   */
  public static formatMoment(date: moment.Moment): string {
    return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : null;
  }

  /** Formats date for API
   * Use time zone component
   */
  public static formatMomentUtc(date: moment.Moment): string {
    return date ? moment(date).format() : null;
  }

  public static formatDateWithoutTimeZone(date: Date): string {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  public openExternalLinkInNewTab(url: string): void {
    this.document.open(url, '_blank', 'popup=false');
  }
}
