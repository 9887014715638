<app-notes title="Dispatch Sheet" [notes]="tenant.dispatchNotes" (save)="saveDispatchNotes($event)"></app-notes>
<app-notes title="Invoice" [notes]="tenant.invoiceNotes" (save)="saveInvoiceNotes($event)"></app-notes>
<div *ngIf="tenant.isLogistics && tenant.logisticsTenant" id="logistics-notes">
  <app-notes
    title="Rate Confirmation Sheet"
    [notes]="tenant.logisticsTenant.rateConfirmationSheetNotes"
    [maxLength]="700"
    [disableEdit]="disableLogisticsNotes"
    (save)="saveRateConfirmationSheetNotes($event)"
  ></app-notes>
  <app-notes
    title="Bill of Lading"
    [notes]="tenant.logisticsTenant.billOfLadingNotes"
    [maxLength]="700"
    [disableEdit]="disableLogisticsNotes"
    (save)="saveBillOfLadingNotes($event)"
  ></app-notes>
</div>
