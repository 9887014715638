import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Injector,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { OtherChargeDTO } from 'src/apiclient/models';
import { FocusTrapService } from 'src/services/focus-trap.service';

@Component({
  selector: 'app-tallybox',
  templateUrl: './tallybox.component.html',
  styleUrls: ['./tallybox.component.scss'],
})
export class TallyboxComponent extends DtoFormBase<OtherChargeDTO> implements OnInit, OnChanges {
  dialogVisible = false;

  originalTallyItems: Array<OtherChargeDTO>;

  @Input() formGroup: FormGroup;
  @Input() tallyType: string;
  @Input() tallyItems: Array<OtherChargeDTO>;
  @Input() tallyTotalAmount;
  @Input() labelClass: any;
  @Input() controlClass: any;
  @Input() readonly: boolean = false;

  @Output() otherChargesChanged = new EventEmitter<Array<OtherChargeDTO>>();
  @Output() tallyTotalAmountChanged = new EventEmitter<number>();

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;
  suppressWarningsForElements: ElementRef[];

  constructor(private focusTrap: FocusTrapService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.suppressWarningsForElements = [this.cancelButton];
    this.originalTallyItems = this.tallyItems ? this.tallyItems.slice() : new Array<OtherChargeDTO>();
    this.formGroup.addControl('tallyTotalAmount', new FormControl({ disabled: true }));
  }

  private propagateChange = (_: any) => {};

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.tallyItemsModelChanged();
  }

  private tallyItemsModelChanged() {
    this.propagateChange(this.tallyItems);
    this.otherChargesChanged.emit(this.tallyItems);
  }

  writeValue(tallysObj: Array<OtherChargeDTO>): void {
    this.tallyItems = tallysObj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}

  addTallyItem() {
    setTimeout(() => {
      if (!this.tallyItems) {
        this.tallyItems = new Array();
      }
      const newTallyItem: OtherChargeDTO = {} as OtherChargeDTO;
      this.tallyItems.push(newTallyItem);
      this.tallyItemsModelChanged();
    });
  }

  removeTallyItem(tallyItem) {
    setTimeout(() => {
      const index = this.tallyItems.indexOf(tallyItem);
      this.tallyItems.splice(index, 1);
      this.tallyTotal();
      this.otherChargesChanged.emit(this.tallyItems);
    });
  }

  tallyItemChanged($event, idx) {
    setTimeout((_) => {
      this.tallyItems[idx] = $event;
      this.tallyTotal();
    });
  }

  tallyTotal() {
    setTimeout(() => {
      let tallyTotal = 0;
      this.tallyItems.forEach((t) => (tallyTotal += t.amount ? t.amount * 1 : 0));
      tallyTotal = parseFloat(tallyTotal.toFixed(2));
      if (this.tallyTotalAmount !== tallyTotal) {
        setTimeout((_) => {
          this.tallyTotalAmount = tallyTotal;
          this.tallyTotalAmountChanged.emit(this.tallyTotalAmount);
          this.tallyItemsModelChanged();
        });
      }
    });
  }

  openDialog() {
    this.originalTallyItems = this.tallyItems ? this.tallyItems.slice() : new Array<OtherChargeDTO>();

    this.dialogVisible = true;
    this.focusTrap.onDialog(true);

    setTimeout((_) => {
      const focusedButton = <HTMLElement>document.activeElement;
      focusedButton.blur();
    });
  }

  closeDialog() {
    this.dialogVisible = false;
    this.focusTrap.onDialog(false);
  }

  saveTally() {
    setTimeout((_) => {
      if (this.tallyItems.length > 0) {
        this.originalTallyItems = this.tallyItems.slice(); // in case we come back to edit again
      } else {
        this.originalTallyItems = new Array();
      }
      this.tallyTotal();
    });
    this.closeDialog();
  }

  cancelTally() {
    setTimeout((_) => {
      if (this.originalTallyItems.length > 0) {
        this.tallyItems = this.originalTallyItems.slice();
      } else {
        this.tallyItems = new Array();
      }
      this.tallyTotal();
    });
    this.closeDialog();
  }
}
