import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ClientTokenService } from 'src/services/client-token-service';

@Directive({
  selector: '[appRenderRequiresPermission]',
})
export class RenderRequiresPermissionDirective implements OnInit {
  @Input() appRenderRequiresPermission: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private clientTokenService: ClientTokenService
  ) {}

  public ngOnInit(): void {
    if (this.appRenderRequiresPermission && this.clientTokenService.hasPermission(this.appRenderRequiresPermission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
