import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  public validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    Object.keys(form.controls).forEach((key) => form.controls[key].markAsDirty());

    return form.valid;
  }
}
