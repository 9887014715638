<p-autoComplete
  #autoComplete
  styleClass="col-12 p-0"
  [(ngModel)]="selected"
  field="name"
  [suggestions]="results"
  (completeMethod)="search($event)"
  (onSelect)="onSelect()"
  (onBlur)="onEdited()"
  (onClear)="onClear()"
  [forceSelection]="forceSelection"
  [dropdown]="showDropdown"
  minLength="1"
  emptyMessage="No Results"
  [disabled]="disabled"
  required="true"
>
</p-autoComplete>
<div [hidden]="!showErrors || !required" class="formerrors col-12 p-0">Company name is required.</div>
