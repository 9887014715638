<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<div class="grid grid-nogutter col-12" [formGroup]="formGroup">
  <div class="grid grid-nogutter col-12 mb-1" pDroppable="loadstop" dropEffect="relocate" (onDrop)="dropStop($event)">
    <div class="lg:col-4 sm:col-12">
      <div class="grid grid-nogutter col-12 p-0 py-1">
        <div class="md:col-4 sm:col-12 p-2 font-bold"> Driver </div>
        <app-user-selector
          class="md:col-8 sm:col-12 p-0"
          roleFilter="Driver"
          [(ngModel)]="leg.driverUser"
          formControlName="driverUser"
          (changed)="driverSelected($event)"
          [showClear]="
            leg.driverUser.id && leg.driverUser.id !== 0 && leg.driverUser.name && leg.driverUser.name !== null
          "
          [readonly]="paidDriver || disabled"
        >
        </app-user-selector>
      </div>

      <div class="grid grid-nogutter col-12 p-0 py-1">
        <div class="md:col-4 sm:col-12 p-2 font-bold"> Phone </div>
        <div class="md:col-8 sm:col-12 p-0 phonegroup">
          <div class="ui-inputgroup p-0">
            <span class="ui-inputgroup-addon"><i class="fas fa-mobile-alt"></i></span>
            <input
              class="phone"
              type="text"
              [(formGroup)]="formGroup"
              [(ngModel)]="leg.driverPhone"
              formControlName="driverPhone"
              pInputText
              placeholder=""
              [disabled]="paidDriver || disabled"
              pKeyFilter="num"
            />
          </div>
          <span class="formerrors">{{ formErrors['driverPhone'] }}</span>
        </div>
      </div>

      <app-form-field
        [formGroup]="formGroup"
        label="Truck Number"
        type="text"
        formControlName="truckNumber"
        [(ngModel)]="leg.truckNumber"
        [formErrors]="formErrors"
        labelClass="md:col-4 sm:col-12 p-2"
        controlClass="md:col-8 sm:col-12 p-0 py-1"
      >
      </app-form-field>

      <app-form-field
        [formGroup]="formGroup"
        label="Trailer Number"
        type="text"
        formControlName="trailerNumber"
        [(ngModel)]="leg.trailerNumber"
        [formErrors]="formErrors"
        labelClass="md:col-4 sm:col-12 p-2"
        controlClass="md:col-8 sm:col-12 p-0 py-1"
      >
      </app-form-field>

      <app-tallybox
        [formGroup]="formGroup"
        tallyType="Other Pay"
        labelClass="md:col-4 sm:col-12 p-2"
        controlClass="md:col-8 sm:col-12 p-0 py-1"
        [tallyItems]="leg.otherCharges"
        [tallyTotalAmount]="leg.otherChargesTotal"
        (tallyTotalAmountChanged)="driverOtherPayTotalAmountChanged($event)"
        [readonly]="paidDriver || disabled"
      >
      </app-tallybox>

      <app-tallybox
        [formGroup]="formGroup"
        tallyType="Advances"
        labelClass="md:col-4 sm:col-12 p-2"
        controlClass="md:col-8 sm:col-12 p-0 py-1"
        [tallyItems]="leg.advances"
        [tallyTotalAmount]="leg.advancesTotal"
        (tallyTotalAmountChanged)="driverAdvancesTotalAmountChanged($event)"
        [readonly]="paidDriver || disabled"
      >
      </app-tallybox>

      <div class="grid grid-nogutter col-12 p-0" *ngIf="isAutomaticMilesCalculationFeatureEnabled">
        <div class="md:col-4 sm:col-12 p-2 font-bold"> Paid Loaded Miles </div>
        <div class="md:col-8 sm:col-12 p-0 py-1">
          <div class="ui-inputgroup col-12 p-0">
            <input
              type="text"
              pKeyFilter="num"
              pInputText
              class="col-6"
              formControlName="loadedMiles"
              [(ngModel)]="leg.loadedMiles"
            />
            <span class="col-6 ui-inputgroup-addon">
              <p-checkbox
                binary="true"
                label="Override"
                formControlName="overrideLoadedMiles"
                [(ngModel)]="leg.overrideLoadedMiles"
                (onChange)="onOverrideLoadedMilesChange()"
              ></p-checkbox>
            </span>
          </div>
        </div>
      </div>

      <app-form-field
        *ngIf="!isAutomaticMilesCalculationFeatureEnabled"
        [formGroup]="formGroup"
        label="Paid Loaded Miles"
        type="text"
        keyFilter="number"
        formControlName="loadedMiles"
        [(ngModel)]="leg.loadedMiles"
        [formErrors]="formErrors"
        labelClass="md:col-4 sm:col-12 p-2 font-bold"
        formControlName="loadedMiles"
        controlClass="md:col-8 sm:col-12 p-0 py-1 {{ loadMilesHighlight ? 'highlighted' : '' }}"
      >
      </app-form-field>

      <app-form-field
        [formGroup]="formGroup"
        label="Paid Empty Miles"
        type="text"
        keyFilter="number"
        formControlName="emptyMiles"
        [(ngModel)]="leg.emptyMiles"
        [formErrors]="formErrors"
        labelClass="md:col-4 sm:col-12 p-2 font-bold"
        controlClass="md:col-8 sm:col-12 p-0 py-1"
      >
      </app-form-field>

      <div class="grid grid-nogutter col-12 p-0">
        <div class="md:col-4 sm:col-12 p-2 font-bold" *ngIf="loadId && leg.loadLegId"> Tracking </div>
        <div class="md:col-8 sm:col-12 p-0 py-1 trackingInfo flex flex-wrap align-items-center">
          <div *ngIf="!(leg.journal && leg.journal.length > 0) && !loadStatusTrackable()"
            >Assign load to start tracking.</div
          >

          <app-leg-tracking
            *ngIf="(leg.journal && leg.journal.length > 0) || (loadId && leg.loadLegId && loadStatusTrackable())"
            [formGroup]="formGroup"
            class="md:col-8 sm:col-12 p-0 py-1"
            [driverName]="leg.driverUser.name"
            [loadId]="loadId"
            [loadNumber]="loadNumber"
            [loadLegId]="leg.loadLegId"
            [trackingJournal]="leg.journal"
            [needsUpdate]="leg.needsUpdate"
            [loadStatus]="loadStatus"
            [readonly]="paidDriver || disabled"
          >
          </app-leg-tracking>
        </div>
      </div>
    </div>
    <div class="lg:col-8 sm:col-12 pl-3">
      <app-stops
        [formGroup]="formGroup"
        [stops]="leg.stops"
        class="lg:col-8 sm:col-12 p-0"
        (stopsChanged)="stopsChanged($event)"
        (stopDragged)="updateDraggedStop($event)"
        [readonly]="disabled"
      >
      </app-stops>
    </div>
  </div>
</div>
