<div *ngIf="charges.length > 0" class="grid grid-nogutter col-12 p-1">
  <div class="grid grid-nogutter col-12 p-0">
    <div class="col-5 px-0 py-2 label-black" *ngIf="viewMode !== 'display'"> Description </div>
    <div class="col-7 p-2 pl-5 label-black" *ngIf="viewMode !== 'display'"> Amount </div>
  </div>
  <div *ngFor="let charge of charges; let idx = index" class="grid grid-nogutter col-12 p-0">
    <div class="col-11 p-0">
      <app-form-charge [formGroup]="formGroup" [viewMode]="viewMode" [charge]="charge"></app-form-charge>
    </div>
    <div
      class="col-1 padCenterIcon trashcan flex flex-wrap align-items-center justify-content-center"
      *ngIf="viewMode !== 'display'"
    >
      <i class="fas fa-trash-alt cursor-pointer" (click)="removeCharge(charge)"></i>
    </div>
  </div>
</div>

<div class="grid grid-nogutter col-12 p-1" *ngIf="viewMode !== 'display'">
  <div class="col-12 p-2 additembox cursor-pointer" (click)="addCharge()">
    <i class="fa fa-plus"></i>
    Add {{ chargeLabel }}
  </div>
</div>
