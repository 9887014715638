<div class="grid grid-nogutter col-12 p-1 flex flex-wrap justify-content-between">
  <div>
    <p-calendar
      [defaultDate]="defaultDate"
      inputStyleClass="w-full"
      [(ngModel)]="startOperatingHours"
      hourFormat="24"
      placeholder="hh:mm"
      timeOnly="true"
      [stepMinute]="15"
      showButtonBar="true"
      todayButtonStyleClass="hide"
      (onSelect)="updateOperatingHours()"
      (onBlur)="updateOperatingHours()"
    >
    </p-calendar>
  </div>
  <div class="flex flex-wrap align-content-center">-</div>
  <div>
    <p-calendar
      [defaultDate]="defaultDate"
      inputStyleClass="w-full"
      [(ngModel)]="endOperatingHours"
      hourFormat="24"
      placeholder="hh:mm"
      timeOnly="true"
      [stepMinute]="15"
      showButtonBar="true"
      todayButtonStyleClass="hide"
      (onSelect)="updateOperatingHours()"
      (onBlur)="updateOperatingHours()"
    >
    </p-calendar>
  </div>
</div>
