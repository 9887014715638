import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfig } from '../../config/app.config';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ConfirmpasswordComponent } from './forgotpassword/confirmpassword/confirmpassword.component';
import { SetnewpasswordComponent } from './setnewpassword/setnewpassword.component';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UnauthenticatedGuard } from 'src/app/shared/guards/unauthenticated/unauthenticated.guard';
import { UserAuthenticationGuard } from 'src/app/shared/guards/authorization/user-authentication.guard';

const routes: Routes = [
  { path: AppConfig.routes.login.default, component: LoginComponent, canActivate: [UnauthenticatedGuard] },
  { path: AppConfig.routes.login.forgotPassword, component: ForgotPasswordComponent },
  { path: AppConfig.routes.login.forgotPasswordWithUser, component: ForgotPasswordComponent },
  { path: AppConfig.routes.login.confirmForgotPassword, component: ConfirmpasswordComponent },
  { path: AppConfig.routes.login.confirmForgotPasswordWithUser, component: ConfirmpasswordComponent },
  { path: AppConfig.routes.login.setNewPassword, component: SetnewpasswordComponent },
  { path: AppConfig.routes.login.verifyEmail, component: VerifyemailComponent, canActivate: [UserAuthenticationGuard] },
  { path: AppConfig.routes.login.signUp, component: SignUpComponent },
  // The route `/registration` was the original registration url. To keep existing bookmarks, we should redirect to the new page.
  { path: AppConfig.routes.registration, redirectTo: AppConfig.routes.login.signUp },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
