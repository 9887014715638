<div class="col-12 latestjournal" *ngIf="latestJournalEntry && viewMode === 'Load'">
  <i class="fa fa-exclamation-triangle statusicon warn" *ngIf="needsUpdate && showTrackingIcons"></i>
  <i class="fas fa-clock statusicon {{ getLegStatusClass() }}"></i>
  {{ latestJournalEntry.status }} {{ latestJournalEntry.createdOn | formatDateUtc }}
</div>

<div class="grid grid-nogutter col-12 p-0" *ngIf="viewMode === 'Load'">
  <button
    type="button"
    class="col-5 p-0"
    pButton
    (click)="showTrackingUpdateScreen()"
    label="Update Tracking"
    *ngIf="!readonly"
  ></button>
  <div class="col-6 p-2 cursor-pointer">
    <a (click)="showTrackingHistoryScreen()">View tracking history</a>
  </div>
</div>

<div *ngIf="viewMode === 'LoadCard'">
  <span class="pfsblue cursor-pointer" (click)="showTrackingUpdateScreen()"> Update Tracking </span>
  <span class="pad4left"> | </span>
  <span class="pad4left pfsblue cursor-pointer" (click)="showTrackingHistoryScreen()"> View History </span>
</div>

<!-- This is needed so these functions can be invoked via the google maps markers -->
<div *ngIf="viewMode === 'DriverCard'" hidden>
  <span id="updateTracking{{ loadId }}" (click)="showTrackingUpdateScreen()"></span>
  <span id="viewHistory{{ loadId }}" (click)="showTrackingHistoryScreen()"></span>
</div>

<p-dialog
  header="Tracking History"
  [(visible)]="showTrackingHistory"
  [closeOnEscape]="true"
  [closable]="true"
  [style]="{ width: '80%' }"
  appendTo="body"
  [modal]="true"
  [focusTrap]="false"
  [focusOnShow]="false"
>
  <div class="layout-content-container">
    <div class="grid grid-nogutter">
      <div class="grid col-12 p-1">
        <div class="col-2 font-bold">Driver</div>
        <div class="col-10">
          {{ driverName }}
        </div>
      </div>
      <div class="grid col-12 p-1">
        <div class="col-2 font-bold">Load</div>
        <div class="col-10">
          {{ loadNumber }}
        </div>
      </div>

      <div class="grid col-12 p-1">
        <p-button class="col-2" (click)="showTrackingUpdateScreen()" label="Update Tracking" *ngIf="!readonly">
        </p-button>
      </div>

      <div class="col-12 p-1">
        <p-table
          [columns]="cols"
          [value]="journals"
          [lazy]="true"
          [loading]="loading"
          [autoLayout]="true"
          (onSort)="sortTable($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : null">
                {{ col.header }}
                <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr pfs-table-body [columns]="columns" [row]="rowData"></tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-dialog>

<app-update-leg-tracking-dialog
  *ngIf="showTrackingUpdate"
  [(showDialog)]="showTrackingUpdate"
  [loadId]="loadId"
  [loadLegId]="loadLegId"
  (journalChanged)="journalModelChanged()"
>
</app-update-leg-tracking-dialog>
