/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TokenResponseDTO } from '../models/token-response-dto';
import { CreateTokenDTO } from '../models/create-token-dto';
import { SwitchTenantRequestDTO } from '../models/switch-tenant-request-dto';
import { SetNewPasswordDTO } from '../models/set-new-password-dto';
import { RefreshTokenResponseDTO } from '../models/refresh-token-response-dto';
import { RevokeTokenRequestDTO } from '../models/revoke-token-request-dto';
import { UsernameDTO } from '../models/username-dto';
import { ConfirmDTO } from '../models/confirm-dto';
import { PasswordResetRequest } from '../models/password-reset-request';
@Injectable({
  providedIn: 'root',
})
class AuthenticationService extends __BaseService {
  static readonly LoginPath = '/api/Authentication/Login';
  static readonly SwitchTenantPath = '/api/Authentication/SwitchTenant';
  static readonly SetNewPasswordPath = '/api/Authentication/SetNewPassword';
  static readonly RefreshTokenPath = '/api/Authentication/RefreshToken';
  static readonly RevokeTokenPath = '/api/Authentication/RevokeToken';
  static readonly ForgotPasswordPath = '/api/Authentication/ForgotPassword';
  static readonly ConfirmForgotPasswordPath = '/api/Authentication/ConfirmForgotPassword';
  static readonly AdminResetPasswordPath = '/api/Authentication/AdminResetPassword';
  static readonly ProtectedPath = '/api/Authentication/Protected';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Login using Cognito JWT tokens
   * @param body Credentials and device ID
   * @return Success
   */
  LoginResponse(body?: CreateTokenDTO): __Observable<__StrictHttpResponse<TokenResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/Login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenResponseDTO>;
      })
    );
  }
  /**
   * Login using Cognito JWT tokens
   * @param body Credentials and device ID
   * @return Success
   */
  Login(body?: CreateTokenDTO): __Observable<TokenResponseDTO> {
    return this.LoginResponse(body).pipe(
      __map(_r => _r.body as TokenResponseDTO)
    );
  }

  /**
   * Switch the active tenant
   * @param params The `AuthenticationService.SwitchTenantParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`: Details of the switch tenant request
   *
   * @return Success
   */
  SwitchTenantResponse(params: AuthenticationService.SwitchTenantParams): __Observable<__StrictHttpResponse<TokenResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/SwitchTenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenResponseDTO>;
      })
    );
  }
  /**
   * Switch the active tenant
   * @param params The `AuthenticationService.SwitchTenantParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`: Details of the switch tenant request
   *
   * @return Success
   */
  SwitchTenant(params: AuthenticationService.SwitchTenantParams): __Observable<TokenResponseDTO> {
    return this.SwitchTenantResponse(params).pipe(
      __map(_r => _r.body as TokenResponseDTO)
    );
  }

  /**
   * Call this method to respond to NEW_PASSWORD_REQUIRED challenge
   * @param body undefined
   * @return See token/create
   */
  SetNewPasswordResponse(body?: SetNewPasswordDTO): __Observable<__StrictHttpResponse<TokenResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/SetNewPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenResponseDTO>;
      })
    );
  }
  /**
   * Call this method to respond to NEW_PASSWORD_REQUIRED challenge
   * @param body undefined
   * @return See token/create
   */
  SetNewPassword(body?: SetNewPasswordDTO): __Observable<TokenResponseDTO> {
    return this.SetNewPasswordResponse(body).pipe(
      __map(_r => _r.body as TokenResponseDTO)
    );
  }

  /**
   * Exchanges a refresh token for new access tokens. This is called by the mobile app background geo-location plugin
   * @param params The `AuthenticationService.RefreshTokenParams` containing the following parameters:
   *
   * - `RefreshToken`:
   *
   * - `ClientId`:
   *
   * @return Success
   */
  RefreshTokenResponse(params: AuthenticationService.RefreshTokenParams): __Observable<__StrictHttpResponse<RefreshTokenResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.RefreshToken != null) { __formData.append('RefreshToken', params.RefreshToken as string | Blob);}
    if (params.ClientId != null) { __formData.append('ClientId', params.ClientId as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/RefreshToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RefreshTokenResponseDTO>;
      })
    );
  }
  /**
   * Exchanges a refresh token for new access tokens. This is called by the mobile app background geo-location plugin
   * @param params The `AuthenticationService.RefreshTokenParams` containing the following parameters:
   *
   * - `RefreshToken`:
   *
   * - `ClientId`:
   *
   * @return Success
   */
  RefreshToken(params: AuthenticationService.RefreshTokenParams): __Observable<RefreshTokenResponseDTO> {
    return this.RefreshTokenResponse(params).pipe(
      __map(_r => _r.body as RefreshTokenResponseDTO)
    );
  }

  /**
   * Invalidates refresh token and all access tokens associated with it
   * @param body undefined
   */
  RevokeTokenResponse(body?: RevokeTokenRequestDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/RevokeToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Invalidates refresh token and all access tokens associated with it
   * @param body undefined
   */
  RevokeToken(body?: RevokeTokenRequestDTO): __Observable<null> {
    return this.RevokeTokenResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Starts the forgot password workflow.
   * If response is Code:
   *     - A confirmation code will be emailed to the user.
   *       They must use the confirmation code to finish the workflow with a call to ConfirmForgotPassword
   * If response is Password:
   *     - A temporary password was sent to the user (because they never logged in). Redirect them to the login page.
   * @param body undefined
   * @return Success
   */
  ForgotPasswordResponse(body?: UsernameDTO): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/ForgotPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Starts the forgot password workflow.
   * If response is Code:
   *     - A confirmation code will be emailed to the user.
   *       They must use the confirmation code to finish the workflow with a call to ConfirmForgotPassword
   * If response is Password:
   *     - A temporary password was sent to the user (because they never logged in). Redirect them to the login page.
   * @param body undefined
   * @return Success
   */
  ForgotPassword(body?: UsernameDTO): __Observable<string> {
    return this.ForgotPasswordResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Second step in the ForgotPassword workflow
   * @param body undefined
   */
  ConfirmForgotPasswordResponse(body?: ConfirmDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/ConfirmForgotPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Second step in the ForgotPassword workflow
   * @param body undefined
   */
  ConfirmForgotPassword(body?: ConfirmDTO): __Observable<null> {
    return this.ConfirmForgotPasswordResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Allows SuperAdmins and tenant Admins to reset the password for any user under their purview.
   * @param params The `AuthenticationService.AdminResetPasswordParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  AdminResetPasswordResponse(params: AuthenticationService.AdminResetPasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Authentication/AdminResetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Allows SuperAdmins and tenant Admins to reset the password for any user under their purview.
   * @param params The `AuthenticationService.AdminResetPasswordParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  AdminResetPassword(params: AuthenticationService.AdminResetPasswordParams): __Observable<null> {
    return this.AdminResetPasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Temporary action used to easily test authentication and authorization
   * @param Authorization Bearer {token}
   */
  ProtectedResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Authentication/Protected`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Temporary action used to easily test authentication and authorization
   * @param Authorization Bearer {token}
   */
  Protected(Authorization: string): __Observable<null> {
    return this.ProtectedResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AuthenticationService {

  /**
   * Parameters for SwitchTenant
   */
  export interface SwitchTenantParams {

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * Details of the switch tenant request
     */
    body?: SwitchTenantRequestDTO;
  }

  /**
   * Parameters for RefreshToken
   */
  export interface RefreshTokenParams {
    RefreshToken?: string;
    ClientId?: string;
  }

  /**
   * Parameters for AdminResetPassword
   */
  export interface AdminResetPasswordParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: PasswordResetRequest;
  }
}

export { AuthenticationService }
