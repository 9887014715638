<p-panel [style]="{ height: '100%' }">
  <p-header>
    <div class="flex align-items-center justify-content-between header">
      <div>{{ header }}</div>
      <i class="fas fa-question-circle tooltip" [pTooltip]="tooltip" tooltipPosition="left"></i>
    </div>
  </p-header>
  <div class="grid">
    <ng-container *ngIf="chartData$ | async as chartData; else loading">
      <div class="col-12">
        <p-chart type="bar" [data]="chartData" [options]="options"></p-chart>
      </div>
      <div class="col-12">
        <div class="grid legend">
          <div *ngFor="let label of labels" class="col-4 label">
            <div class="dot" [style.background-color]="label.color"></div>
            {{ label.label }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <div class="col-12">
        <div *ngIf="!isError" class="flex justify-content-center flex-column align-items-center loading">
          <p-progressSpinner [style]="{ width: '10vw', height: '10vw' }"></p-progressSpinner>
        </div>
        <div *ngIf="isError" class="error">
          <svg class="cloud-exclamation" viewBox="0 0 74 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M44.9781 10.9107C41.9719 6.61607 37 3.71429 31.45 3.71429C22.4312 3.71429 15.1469 10.7946 14.8 19.7321C14.6844 21.2411 13.7594 22.5179 12.2563 23.0982C7.28438 24.8393 3.7 29.7143 3.7 35.2857C3.7 42.4821 9.48125 48.2857 16.65 48.2857H59.2C65.3281 48.2857 70.3 43.2946 70.3 37.1429C70.3 32.9643 67.8719 29.25 64.2875 27.3929C62.7844 26.4643 61.975 24.7232 62.5531 22.9821C62.7844 22.2857 62.9 21.3571 62.9 20.4286C62.9 15.3214 58.7375 11.1429 53.65 11.1429C52.1469 11.1429 50.875 11.4911 49.6031 12.0714C47.9844 12.8839 46.0187 12.3036 44.9781 10.9107ZM31.45 0C38.2719 0 44.4 3.48214 48.1 8.70536C49.7188 7.89286 51.5688 7.42857 53.65 7.42857C60.7031 7.42857 66.6 13.3482 66.6 20.4286C66.6 21.7054 66.3688 22.8661 66.0219 24.0268C70.7625 26.5804 74 31.4554 74 37.1429C74 45.3839 67.2938 52 59.2 52H16.65C7.4 52 0 44.5714 0 35.2857C0 28.0893 4.625 21.8214 11.1 19.6161C11.5625 8.70536 20.4656 0 31.45 0ZM37 14.8571C37.925 14.8571 38.85 15.7857 38.85 16.7143V31.5714C38.85 32.6161 37.925 33.4286 37 33.4286C35.9594 33.4286 35.15 32.6161 35.15 31.5714V16.7143C35.15 15.7857 35.9594 14.8571 37 14.8571ZM34.225 40.8571C34.225 39.3482 35.3813 38.0714 37 38.0714C38.5031 38.0714 39.775 39.3482 39.775 40.8571C39.775 42.4821 38.5031 43.6429 37 43.6429C35.3813 43.6429 34.225 42.4821 34.225 40.8571Z"
            />
          </svg>
          <div class="message">Things are taking a little longer than expected. Please try loading again.</div>
          <button class="refresh" (click)="refresh()">Refresh</button>
        </div>
      </div>
    </ng-template>
  </div>
</p-panel>
