import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DriverProfileService } from '../../../../apiclient/services/driver-profile.service';
import { MessageService } from 'primeng/api';
import { DriverProfileEditComponent } from '../edit/driver-profile-edit.component';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ClientTokenService } from '../../../../services/client-token-service';
import { LoggingService } from '../../../../app/core/logging.service';
import { Utils } from '../../../../utils/utils';
import { DriverProfileSummaryDTO } from '../../../../apiclient/models';
import { TableColumn } from 'src/app/shared/models/TableColumn';
@Component({
  selector: 'app-driver-profile-list',
  templateUrl: './driver-profile-list.component.html',
})
export class DriverProfileListComponent implements OnInit {
  viewAccounting: boolean = true;
  loading: boolean;

  cols: TableColumn[];

  drivers: DriverProfileSummaryDTO[];
  driversOriginal: DriverProfileSummaryDTO[];

  msgs: any[];

  filter: string;

  @ViewChild('driverEditDialog', { static: true }) driverEditDialog: DriverProfileEditComponent;

  constructor(
    private router: Router,
    private driverProfileService: DriverProfileService,
    private messageService: MessageService,
    private clientToken: ClientTokenService,
    private breadcrumbService: BreadcrumbService,
    private logging: LoggingService
  ) {}

  ngOnInit() {
    const allowedRoles = ['SuperAdmin', 'Admin', 'Accounting'];
    const allowedPermissions = ['DriverProfileRead', 'DriverProfileAccounting'];
    if (
      !allowedPermissions.some((permission) => this.clientToken.permissions().includes(permission)) ||
      !allowedRoles.some((roles) => this.clientToken.roles().includes(roles))
    ) {
      this.router.navigate(['/']);
    }
    this.breadcrumbService.setItems('driverprofile');
    this.viewAccounting = false;
    if (
      !(
        this.clientToken.hasRole('SuperAdmin') ||
        this.clientToken.hasRole('Admin') ||
        this.clientToken.hasRole('Dispatch')
      )
    ) {
      this.viewAccounting = true;
    }
    this.cols = [
      {
        field: 'name',
        header: 'Name',
        format: 'actionCell',
        sortable: true,
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditModal',
          params: {
            row: 'row',
          },
        },
        width: '15em',
      },
      {
        field: 'address',
        header: 'Address',
        sortable: true,
        width: '15em',
      },
      {
        field: 'category',
        header: 'Category',
        sortable: true,
        width: '10em',
      },
      {
        field: 'phone',
        header: 'Phone',
        sortable: false,
        width: '15em',
      },
      {
        field: 'email',
        header: 'Email',
        sortable: true,
        width: '15em',
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true,
        width: '7em',
      },
    ];
    this.getData();
  }

  getData() {
    this.loading = true;
    this.driverProfileService.ApiDriverProfileGet(this.clientToken.auth()).subscribe(
      (data) => {
        this.drivers = data;
        this.driversOriginal = data;
        this.filterTable();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Invalid Session',
          detail: '',
        });
        // this.router.navigate(['/dashboard']);
      },
      () => (this.loading = false)
    );
  }

  openEditModal = (params) => {
    this.driverEditDialog.init(params.row.driverProfileId);
  };

  actionHandler = (actionConfig) => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  handleDriverEdit(data) {
    if (data.dataChanged) {
      this.getData();
    }
  }

  sortTable(event: any) {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
      event.format = fieldInfo.format;
    }
    Utils.sortData(event, this.drivers);
  }

  filterTable() {
    if (this.filter) {
      this.drivers = this.driversOriginal.filter((item) => item.name.toLowerCase().includes(this.filter.toLowerCase()));
    } else {
      this.drivers = this.driversOriginal;
    }
  }
}
