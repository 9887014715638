import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TenantAttachmentDTO } from '../../../../apiclient/v1.1/models';
import { TenantAttachmentService } from '../../../../apiclient/v1.1/services';
import { ClientTokenService } from '../../../../services/client-token-service';
import { DtoFormBase } from '../../../shared/FormBase';
import { NotificationsService } from '../../../components/notifications/notifications.service';

@Component({
  selector: 'app-notice-of-assignment-dialog',
  templateUrl: './notice-of-assignment-dialog.component.html',
  styleUrls: ['./notice-of-assignment-dialog.component.scss'],
})
export class NoticeOfAssignmentDialogComponent extends DtoFormBase<undefined> {
  @Input() tenantId: number;
  @Input() attachment?: TenantAttachmentDTO;
  @Output() dialogClosed = new EventEmitter<TenantAttachmentDTO>();
  public isVisible = true;
  public isFileSelected = true;
  public isFilePdf = true;
  public isFileInvalidSize = false;
  public isSaving = false;
  public readonly accept = 'application/pdf';
  private file: File;
  /**
   * Max file size is 10MB.
   */
  private readonly maxFileSize = 10485760;
  private destroy$ = new Subject<void>();

  constructor(
    protected injector: Injector,
    private clientTokenService: ClientTokenService,
    private tenantAttachmentService: TenantAttachmentService,
    private notificationsService: NotificationsService
  ) {
    super(injector);
  }

  public fileSelected(file: File) {
    this.file = file;
    this.updateValidationMessages();
  }

  public cancel(): void {
    this.closeDialog();
  }

  public save(): void {
    this.updateValidationMessages();
    if (!this.isFileSelected || !this.isFilePdf || this.isFileInvalidSize) {
      return;
    }

    this.isSaving = true;
    const save$ = this.attachment ? this.uploadAndOverwrite() : this.upload();
    save$
      .pipe(
        tap((attachment) => {
          this.notificationsService.success(
            'Notice Of Assignment Updated',
            'The Notice Of Assignment was uploaded successfully.'
          );
          this.closeDialog(attachment);
        }),
        catchError((error) => this.handleError(error)),
        finalize(() => (this.isSaving = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private closeDialog(tenantAttachment?: TenantAttachmentDTO) {
    this.isVisible = false;
    // Timeout used to account for time of close animation.
    setTimeout(() => this.dialogClosed.emit(tenantAttachment), 100);
  }

  private updateValidationMessages(): void {
    this.isFileSelected = !!this.file;
    this.isFilePdf = this.isFileSelected && this.file.type === this.accept;
    this.isFileInvalidSize = this.isFileSelected && this.file.size >= this.maxFileSize;
  }

  private upload(): Observable<TenantAttachmentDTO> {
    return this.tenantAttachmentService.Upload({
      Authorization: this.clientTokenService.auth(),
      fileType: 'NOA',
      tenantId: this.tenantId,
      file: this.file,
    });
  }

  private uploadAndOverwrite(): Observable<TenantAttachmentDTO> {
    return this.tenantAttachmentService.UploadAndOverwrite({
      Authorization: this.clientTokenService.auth(),
      fileType: 'NOA',
      tenantId: this.tenantId,
      file: this.file,
      attachmentId: this.attachment.attachmentId,
    });
  }
}
