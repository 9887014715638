import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardBarGraphComponent } from './dashboard-bar-graph/dashboard-bar-graph.component';
import { ChartModule } from 'primeng/chart';
import { LoadsWidgetComponent } from './loads-widget/loads-widget.component';
import { PrimeNgModule } from 'src/app/shared/modules/primeng.module';
import { PanelComponent } from '../../components/panel/panel.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';
import { MilesWidgetComponent } from './miles-widget/miles-widget.component';
import { RevenueWidgetComponent } from './revenue-widget/revenue-widget.component';
import { ReportingWizardComponent } from './reporting-wizard/reporting-wizard.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportingWizardFilterComponent } from './reporting-wizard-filter/reporting-wizard-filter.component';
import { ReportingWizardOptionsComponent } from './reporting-wizard-options/reporting-wizard-options.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardBarGraphComponent,
    LoadsWidgetComponent,
    PanelComponent,
    NotificationsPanelComponent,
    MilesWidgetComponent,
    RevenueWidgetComponent,
    ReportingWizardComponent,
    ReportingWizardFilterComponent,
    ReportingWizardOptionsComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    PrimeNgModule,
    ChartModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class DashboardModule {}
