import { Injectable } from '@angular/core';

import * as data from './validators-data.json';

@Injectable()
export class ValidatorsService {
  private validators: any = data;

  constructor() {}

  getByName(name: string): any {
    // After update to Angular V6 need to access via default.
    return this.validators[name] || this.validators.default[name];
  }
}
