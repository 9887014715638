import { Component, forwardRef, EventEmitter, Output, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyAddressService } from 'src/apiclient/services';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { CompanyAddressQuickAddDTO, IdNamePairDTO } from 'src/apiclient/models';

@Component({
  selector: 'app-company-address-selector',
  templateUrl: './company-address-selector.component.html',
  styleUrls: ['./company-address-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyAddressSelectorComponent),
      multi: true,
    },
  ],
})
// tslint:disable:one-line
export class CompanyAddressSelectorComponent
  extends DtoFormBase<CompanyAddressQuickAddDTO>
  implements ControlValueAccessor, OnChanges
{
  // tslint:enable:one-line
  /**
   * Control is dependent on this field being initialized. If not > 0, will always return no results.
   */
  @Input()
  companyId: number;

  @Input() forceSelection: boolean = true;
  @Input() showDropdown: boolean = true;
  @Input() autoSelectOnlyResult: boolean = true;

  /**
   * This property is bound to the ngModel
   */
  companyAddressId: number;

  /**
   * Auto-Complete properties
   */
  selected: IdNamePairDTO = <IdNamePairDTO>{};
  results: IdNamePairDTO[];

  /**
   * When companyAddressId changes
   */
  @Output()
  changed: EventEmitter<IdNamePairDTO> = new EventEmitter<IdNamePairDTO>();

  disabled: boolean = false;

  constructor(private companyAddressService: CompanyAddressService, protected injector: Injector) {
    super(injector);
  }

  /**
   * Writes a new companyId to the element.
   * Must query API to get the name.
   */
  writeValue(val: number): void {
    this.companyAddressId = val;
    this.get(val);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Reset the component if the company changes after a address was selected.
   * Sets the model to null and propagates the change
   */
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.companyId.currentValue !== changes.companyId.previousValue) {
      if (changes.companyId.currentValue > 0) {
        // Check if there is only one search result for company
        const data = await this.searchLocation(this.companyId, '*');
        if (this.autoSelectOnlyResult && data.length === 1) {
          this.selected = data[0];
          this.companyAddressId = data[0].id;
          this.modelChanged();
          return;
        }
        // Save results for later (in case user uses dropdown)
        // I assume this caches, but maybe not
        this.results = data;

        if (changes.companyId.firstChange) {
          return;
        }
      }
      this.onClear();
    }
  }

  private propagateChange = (_: any) => {};

  public onSelect(): void {
    if (this.selected !== undefined || this.selected != null) {
      if (this.companyAddressId !== this.selected.id) {
        this.companyAddressId = this.selected.id;
        this.modelChanged();
      }
    }
  }

  public onClear() {
    this.selected = {} as IdNamePairDTO;
    if (this.companyAddressId !== 0) {
      this.companyAddressId = 0;
      this.modelChanged();
    }
  }

  private modelChanged() {
    this.propagateChange(this.companyAddressId);
    this.changed.emit(this.selected);
  }

  async get(id: number) {
    if (id > 0) {
      try {
        const data = await this.getLocation(id);

        this.companyAddressId = data.id;
        this.selected = data;
        this.results = [data];
      } catch (error) {
        this.handleBasicError(error);
      }
    } else {
      this.companyAddressId = 0;
      this.selected = <IdNamePairDTO>{};
    }
  }

  async search(event) {
    if (this.companyId > 0) {
      const term = event.query === '' ? '*' : event.query;
      const data = await this.searchLocation(this.companyId, term);

      this.results = data;
    } else {
      console.log('Cannot search CompanyBillTo if companyId is blank');
      this.results = [<IdNamePairDTO>{ id: -99, name: 'CompanyId must > 0 to use this' }];
    }
  }

  private getLocation = async (id: number): Promise<IdNamePairDTO> => {
    try {
      const data = await this.companyAddressService
        .ApiCompanyAddressByIdGet({
          id: id,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();

      const tmp = <IdNamePairDTO>{};
      tmp.id = data.companyAddressId;
      tmp.name = data.name;

      return tmp;
    } catch (error) {
      this.handleBasicError(error);
    }
  };

  private searchLocation = async (companyId: number, term: string): Promise<IdNamePairDTO[]> => {
    try {
      const data = await this.companyAddressService
        .ApiCompanyAddressSearchByCompanyIdByTermGet({
          companyId: companyId,
          term: term,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();

      return data;
    } catch (error) {
      this.handleBasicError(error);
    }
  };
}
