import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ClientTokenService } from '../../../../services/client-token-service';
import { AppConfig } from '../../../config/app.config';
import { AuthorizationDataProperties } from './authorization-data-properties';

@Injectable({
  providedIn: 'root',
})
export class PermissionAuthorizationGuard implements CanActivate {
  constructor(private clientTokenService: ClientTokenService, private router: Router) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean {
    const requiredPermission: string = next.data[AuthorizationDataProperties.Permission];

    if (!requiredPermission) {
      console.warn(`No permission(s) were specified for ${next.url.join('/')}.`);
      return true;
    }

    if (this.clientTokenService.hasPermission(requiredPermission)) {
      return true;
    } else {
      this.router.navigate([AppConfig.routes.dashboard]);
      return false;
    }
  }
}
