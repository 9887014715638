import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ContentDialogStyle } from './content-dialog-style';

@Component({
  selector: 'app-buy-no-buy-dialog',
  templateUrl: './buy-no-buy-dialog.component.html',
  styleUrls: ['./buy-no-buy-dialog.component.scss'],
})
export class BuyNoBuyDialogComponent {
  public buyNoBuyDialogHeight: number;
  public buyNoBuyDialogWidth: number;
  public contentDialogStyle: ContentDialogStyle;
  @Output() dialogClosed = new EventEmitter();

  @HostListener('window:resize') onResize() {
    this.displayDialog();
  }

  constructor() {}

  public displayDialog() {
    this.buyNoBuyDialogHeight = window.innerHeight;
    this.buyNoBuyDialogWidth = window.innerWidth;
    this.contentDialogStyle = { minHeight: window.innerHeight + 'px !important;' };
  }

  public closeDialog() {
    this.dialogClosed.emit();
  }
}
