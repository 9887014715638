export enum LoadFormFields {
  Advances = 'advances',
  AdvancesTotal = 'advancesTotal',
  Alerts = 'alerts',
  BillToCompanyAddress = 'billToCompanyAddress',
  BillToNotesInternal = 'billToNotesInternal',
  BillToNotesReports = 'billToNotesReports',
  Category = 'category',
  Dispatcher = 'dispatcher',
  FuelSurcharge = 'fuelSurcharge',
  FuelSurchargeAmount = 'fuelSurchargeAmount',
  FuelSurchargePercent = 'fuelSurchargePercent',
  FuelSurchargeUnits = 'fuelSurchargeUnits',
  LastTrackingStatus = 'lastTrackingStatus',
  LastTrackingUpdatedUtc = 'lastTrackingUpdatedUtc',
  Legs = 'legs',
  LoadId = 'loadId',
  LoadNumber = 'loadNumber',
  OtherCharges = 'otherCharges',
  OtherChargesTotal = 'otherChargesTotal',
  OverrideTotalMiles = 'overrideTotalMiles',
  PicksDrops = 'picksDrops',
  Rate = 'rate',
  RateUnits = 'rateUnits',
  RequestedEquipmentId = 'requestedEquipmentId',
  ShowFuelSurchargeAsPercentage = 'showFuelSurchargeAsPercentage',
  Status = 'status',
  Total = 'total',
  TotalMiles = 'totalMiles',
  WorkOrder = 'workOrder',
}
