<div class="p-0">
  <div class="grid grid-nogutter col-12 p-0" *ngIf="labelLeft" id="label-left">
    <div class="{{ labelClass }} font-bold" id="label">{{ label }}</div>
    <div class="{{ controlClass }} px-0">
      <ng-container [ngTemplateOutlet]="phoneInput"></ng-container>
    </div>
  </div>

  <div class="p-0" *ngIf="!labelLeft" id="label-vertical">
    <div class="{{ labelClass }} p-0" *ngIf="labelClass !== 'nolabel'" id="label-container">
      <div class="{{ boldLabel === true ? 'label-black' : '' }} col-12 p-0" id="label">
        {{ label }}
      </div>
    </div>

    <div class="ui-inputgroup p-0 input-box {{ controlClass }}">
      <ng-container [ngTemplateOutlet]="phoneInput"></ng-container>
    </div>
  </div>

  <ng-container *ngIf="!errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
</div>

<ng-template #phoneInput>
  <p-inputMask
    [placeholder]="placeholder"
    id="phone-input"
    mask="(999) 999-9999"
    class="col-12 p-0"
    type="text"
    pKeyFilter="num"
    [style]="{ width: '100%' }"
    [formControl]="phoneControl"
    (onBlur)="onTouched()"
    (onInput)="onTouched()"
  ></p-inputMask>

  <ng-container *ngIf="errorUnderInput" [ngTemplateOutlet]="error"></ng-container>
</ng-template>

<ng-template #error>
  <div class="formerrors {{ errorClass }} p-0" *ngIf="getFormError()">
    {{ getFormError() | formatErrorMessage }}
  </div>
</ng-template>
