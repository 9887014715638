import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggingService } from '../../app/core/logging.service';

@Injectable()
export class TmsErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const monitoringService = this.injector.get(LoggingService);
    monitoringService.logError(error);
    super.handleError(error);
  }
}
