import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Injector,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { FormGroup } from '@angular/forms';
import { IdNamePairDTO } from 'src/apiclient/models';
import { StopsComponent } from './stops/stops.component';
import { LoadStatusName } from 'src/app/data/static-data';
import { DriverProfileService } from 'src/apiclient/services';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LoadLegDTO, LoadLegStopDTO } from 'src/apiclient/v1.1/models';
import { LegFormFields } from './leg-form-fields';
import { FeatureFlags } from 'src/app/data/feature-flags';

@Component({
  selector: 'app-leg',
  templateUrl: './leg.component.html',
  styleUrls: ['./leg.component.scss'],
})
export class LegComponent extends DtoFormBase<LoadLegDTO> implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() loadId: number;
  @Input() loadNumber: number;
  @Input() loadStatus: string;
  @Input() leg: LoadLegDTO;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() legChanged = new EventEmitter<LoadLegDTO>();

  public draggedStop: LoadLegStopDTO;
  public loadMilesHighlight: boolean = false;
  public paidDriver: boolean = false;
  public readonly isAutomaticMilesCalculationFeatureEnabled = this.clientToken.isFeatureFlagEnabled(
    FeatureFlags.AutomaticMilesCalculation
  );

  private destroy$ = new Subject<void>();

  @ViewChild(StopsComponent, { static: true }) stopsComp: StopsComponent;

  constructor(
    protected injector: Injector,
    private driverProfile: DriverProfileService,
    private changeDetection: ChangeDetectorRef
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.initFormFor('LoadLegDTO');
    if (!this.leg.stops) {
      this.stopsComp.addStop();
    }
    this.updateLoadUIViewMode();

    this.formGroup.valueChanges
      .pipe(
        tap(() => this.legChanged.emit(this.leg)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.updateLoadUIViewMode();
  }

  public onOverrideLoadedMilesChange(): void {
    this.conditionallyDisableLoadedMilesBasedOnOverride();
  }

  private conditionallyDisableLoadedMilesBasedOnOverride(): void {
    if (this.isAutomaticMilesCalculationFeatureEnabled) {
      this.leg.overrideLoadedMiles
        ? this.formGroup.controls[LegFormFields.LoadedMiles].enable()
        : this.formGroup.controls[LegFormFields.LoadedMiles].disable();
    }
  }

  private updateLoadUIViewMode() {
    this.paidDriver = this.leg.payStatus === 'Paid' || this.leg.payStatus === 'PaidWithAlert';

    if (this.paidDriver || this.disabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
      this.conditionallyDisableLoadedMilesBasedOnOverride();
    }

    this.changeDetection.detectChanges();
  }

  private propagateChange = (_: any) => {};

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.legModelChanged();
  }

  private legModelChanged() {
    this.propagateChange(this.leg);
    this.legChanged.emit(this.leg);
  }

  writeValue(legObj: LoadLegDTO): void {
    this.leg = legObj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }

  stopsChanged($event) {
    setTimeout((_) => {
      this.leg.stops = $event;
      // this.legModelChanged();
    });
  }

  driverSelected($event: IdNamePairDTO) {
    console.log($event);

    if ($event === null) {
      this.leg.driverUser = {};
      this.leg.trailerNumber = null;
      this.leg.truckNumber = null;
      this.leg.driverPhone = null;
    } else {
      this.driverProfile
        .ApiDriverProfileByUserIdByUserIdGet({ userId: $event.id, Authorization: this.clientToken.auth() })
        .subscribe((data) => {
          this.leg.trailerNumber = data.trailerNumber;
          this.leg.truckNumber = data.truckNumber;
          this.leg.driverPhone = data.phone;
        }, this.handleError);
    }
  }

  updateDraggedStop($event: any) {
    this.draggedStop = $event as LoadLegStopDTO;
  }

  dropStop($event) {
    this.draggedStop = null;
  }

  loadStatusTrackable() {
    const trackableStatuses: string[] = [
      LoadStatusName.Assigned,
      LoadStatusName.Dispatched,
      LoadStatusName.InTransit,
      LoadStatusName.InYard,
      LoadStatusName.Delivered,
      LoadStatusName.Audited,
      LoadStatusName.Invoiced,
      LoadStatusName.Paid,
      LoadStatusName.Archive,
    ];

    return this.loadStatus && trackableStatuses.includes(this.loadStatus);
  }

  driverOtherPayTotalAmountChanged($event) {
    if (this.leg.otherChargesTotal !== $event) {
      setTimeout((_) => {
        this.leg.otherChargesTotal = $event;
        // this.legModelChanged();
      });
    }
  }

  driverAdvancesTotalAmountChanged($event) {
    if (this.leg.advancesTotal !== $event) {
      setTimeout((_) => {
        this.leg.advancesTotal = $event;
        // this.legModelChanged();
      });
    }
  }

  setLoadMilesHighlight() {
    this.loadMilesHighlight = true;
  }
}
