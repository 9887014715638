import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { SelectItem } from 'primeng-lts';
import { DateOptionLabel } from './models/date-option-label';
import { DateOptionValue } from './models/date-option-value';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterDateSelectorFormProperties } from './models/filter-date-selector-form-properties';
import { DateRange } from 'src/app/components/date-range-selector/models/date-range';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { startOfDay, endOfDay, addDays } from 'date-fns';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-filter-date-selector',
  templateUrl: './filter-date-selector.component.html',
  styleUrls: ['./filter-date-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDateSelectorComponent),
      multi: true,
    },
  ],
})
export class FilterDateSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public dateOptions: SelectItem[];
  public readonly filterDateSelectorFormProperties = FilterDateSelectorFormProperties;

  private destroy$ = new Subject<void>();
  private readonly emptyDateRange: DateRange = {
    startDate: null,
    endDate: null,
  };

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [FilterDateSelectorFormProperties.DateType]: [],
      [FilterDateSelectorFormProperties.DateRange]: [this.emptyDateRange],
      [FilterDateSelectorFormProperties.CustomDateRange]: [this.emptyDateRange],
    });

    this.initDateOptions();

    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: DateRange): void {
    if (!value) {
      this.formGroup.controls[FilterDateSelectorFormProperties.DateType].setValue(null);
      this.formGroup.controls[FilterDateSelectorFormProperties.CustomDateRange].setValue(this.emptyDateRange);
      this.formGroup.controls[FilterDateSelectorFormProperties.DateRange].setValue(this.emptyDateRange);
      return;
    }

    this.formGroup.controls[FilterDateSelectorFormProperties.DateRange].setValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private onChange = (value: DateRange | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public showCustomDateSelector(): boolean {
    return this.formGroup.controls[FilterDateSelectorFormProperties.DateType].value === DateOptionValue.Custom;
  }

  private filterDateRangeChange(): void {
    const dateType = this.formGroup.controls[FilterDateSelectorFormProperties.DateType].value;

    const newDateRange: DateRange = {
      startDate: null,
      endDate: null,
    };

    switch (dateType) {
      case DateOptionValue.Today:
        newDateRange.startDate = Utils.formatDateWithoutTimeZone(startOfDay(new Date()));
        newDateRange.endDate = Utils.formatDateWithoutTimeZone(endOfDay(new Date()));
        break;
      case DateOptionValue.Tomorrow:
        newDateRange.startDate = Utils.formatDateWithoutTimeZone(startOfDay(addDays(new Date(), 1)));
        newDateRange.endDate = Utils.formatDateWithoutTimeZone(endOfDay(addDays(new Date(), 1)));
        break;
      case DateOptionValue.Next5Days:
        newDateRange.startDate = Utils.formatDateWithoutTimeZone(startOfDay(new Date()));
        newDateRange.endDate = Utils.formatDateWithoutTimeZone(endOfDay(addDays(new Date(), 5)));
        break;
      case DateOptionValue.Custom:
        newDateRange.startDate = null;
        newDateRange.endDate = null;
        break;
    }

    this.formGroup.controls[FilterDateSelectorFormProperties.DateRange].setValue(newDateRange);
  }

  private customDateRangeChanged(): void {
    const customDateRange: DateRange = this.formGroup.controls[FilterDateSelectorFormProperties.CustomDateRange].value;

    const newDateRange: DateRange = {
      startDate: customDateRange.startDate
        ? Utils.formatDateWithoutTimeZone(startOfDay(new Date(customDateRange.startDate)))
        : null,
      endDate: customDateRange.endDate
        ? Utils.formatDateWithoutTimeZone(endOfDay(new Date(customDateRange.endDate)))
        : null,
    };

    this.formGroup.controls[FilterDateSelectorFormProperties.DateRange].setValue(newDateRange);
  }

  private initSubscriptions(): void {
    this.formGroup.valueChanges
      .pipe(
        map(() => this.formGroup.controls[FilterDateSelectorFormProperties.DateRange].value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));

    this.formGroup.controls[FilterDateSelectorFormProperties.DateType].valueChanges
      .pipe(
        tap(() => this.filterDateRangeChange()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.formGroup.controls[FilterDateSelectorFormProperties.CustomDateRange].valueChanges
      .pipe(
        tap(() => this.customDateRangeChanged()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private initDateOptions() {
    this.dateOptions = [
      {
        label: DateOptionLabel.Today,
        value: DateOptionValue.Today,
      },
      {
        label: DateOptionLabel.Tomorrow,
        value: DateOptionValue.Tomorrow,
      },
      {
        label: DateOptionLabel.Next5Days,
        value: DateOptionValue.Next5Days,
      },
      {
        label: DateOptionLabel.Custom,
        value: DateOptionValue.Custom,
      },
    ];
  }
}
