<div class="notice-of-assignment">
  <div class="ui-button-link" *ngIf="noaAttachment$ | async as noaAttachment; else elseBlock">
    <a class="ui-link" (click)="downloadFile(noaAttachment)">
      {{ noaAttachment.fileName }}, Modified On: {{ noaAttachment.modifiedOn | date : 'short' }}
    </a>
  </div>
  <ng-template #elseBlock> No file has been uploaded. </ng-template>
  <p-button class="pt-2" icon="fa fa-upload" label="Upload" (click)="openNoaDialog()"></p-button>
</div>

<app-notice-of-assignment-dialog
  *ngIf="isNoticeOfAssignmentDialogVisible"
  (dialogClosed)="noaDialogClosed($event)"
  [tenantId]="tenantId"
  [attachment]="noaAttachment$ | async"
>
</app-notice-of-assignment-dialog>
