<div class="layout-content-container p-0" *ngIf="contactAddress">
  <app-edit-section
    [title]="label"
    (saved)="saveContactAddress()"
    (cancelled)="cancelEdit()"
    (editModeChange)="startEditing()"
    [(editMode)]="editing"
    [disableSave]="disableSave$ | async"
    [(formGroup)]="contactAddressForm"
  >
    <div read>
      <app-edit-section-row label="Contact Name">
        <span class="font-bold"> {{ getContactName() }} </span>
      </app-edit-section-row>
      <app-edit-section-row label="Email Address">
        <span class="font-bold"> {{ contactAddress.contact.email }} </span>
      </app-edit-section-row>
      <app-edit-section-row label="Phone">
        <span class="font-bold"> {{ contactAddress.contact.phoneNumber | formatPhone }} </span>
      </app-edit-section-row>
      <app-edit-section-row label="Address">
        <span class="font-bold"> {{ getAddress() }} </span>
      </app-edit-section-row>
    </div>
    <form edit [formGroup]="contactAddressForm">
      <app-edit-section-row label="First Name" [(editMode)]="editing">
        <app-form-field
          [(formGroup)]="contactAddressForm"
          [formErrors]="formErrors"
          [formControlName]="formFields.FirstName"
          labelClass="nolabel"
          controlClass="col-12 md:col-6 p-0"
        >
        </app-form-field>
      </app-edit-section-row>
      <app-edit-section-row label="Last Name" [(editMode)]="editing">
        <app-form-field
          [(formGroup)]="contactAddressForm"
          [formErrors]="formErrors"
          [formControlName]="formFields.LastName"
          labelClass="nolabel"
          controlClass="col-12 md:col-6 p-0"
        >
        </app-form-field>
      </app-edit-section-row>
      <app-edit-section-row label="Email Address" [(editMode)]="editing">
        <app-form-field
          [(formGroup)]="contactAddressForm"
          [formErrors]="formErrors"
          [formControlName]="formFields.Email"
          labelClass="nolabel"
          controlClass="col-12 md:col-6 p-0"
        >
        </app-form-field>
      </app-edit-section-row>
      <app-edit-section-row label="Phone" [(editMode)]="editing">
        <div class="col-6 p-0 flex align-content-start">
          <app-phone-number-v2
            class="col-12 p-0"
            placeholder="Phone Number"
            labelClass="col-12"
            errorClass="col-12"
            controlClass="col-12"
            [(formGroup)]="contactAddressForm"
            [formControlName]="formFields.PhoneNumber"
            [formErrors]="formErrors"
            [touched]="contactAddressForm.controls[formFields.PhoneNumber].touched"
            [dirty]="contactAddressForm.controls[formFields.PhoneNumber].dirty"
          ></app-phone-number-v2>
        </div>
      </app-edit-section-row>
      <app-edit-section-row label="Address" [(editMode)]="editing">
        <div class="col-6 p-0 flex align-content-start">
          <app-address-v3
            class="col-12 p-0"
            errorClass="col-12 pl-1"
            [(formGroup)]="contactAddressForm"
            [formControlName]="formFields.Address"
            [viewMode]="addressViewMode.OneColumn"
            [validationMessages]="addressValidationMessages"
          ></app-address-v3>
        </div>
      </app-edit-section-row>
    </form>
  </app-edit-section>
</div>
