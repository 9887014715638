import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormValidationErrors } from './form-validation-errors';

@Injectable({
  providedIn: 'root',
})
export class FormValidationErrorsService {
  public getFormErrors(formGroup: FormGroup, customValidationMessages?: object): object {
    const formErrors = {};

    Object.keys(formGroup.controls).forEach((key) => {
      const currentControl = formGroup.controls[key];

      if (currentControl.dirty && !currentControl.valid && currentControl.errors) {
        const currentErrorKey = Object.keys(currentControl.errors)[0];
        let errorMessage = 'Invalid';
        switch (currentErrorKey) {
          case FormValidationErrors.Required:
            const customRequiredMessage = this.tryGetCustomValidationMessage(
              key,
              FormValidationErrors.Required,
              customValidationMessages
            );
            errorMessage = customRequiredMessage ? customRequiredMessage : 'Required';
            break;
          case FormValidationErrors.MaxLength:
            const customMaxLengthMessage = this.tryGetCustomValidationMessage(
              key,
              FormValidationErrors.MaxLength,
              customValidationMessages
            );
            const maxLength = currentControl.errors[FormValidationErrors.MaxLength].requiredLength;
            errorMessage = customMaxLengthMessage ? customMaxLengthMessage : `Maximum of ${maxLength} characters`;
            break;
          case FormValidationErrors.MinLength:
            const customMinLengthMessage = this.tryGetCustomValidationMessage(
              key,
              FormValidationErrors.MinLength,
              customValidationMessages
            );
            const minLength = currentControl.errors[FormValidationErrors.MinLength].requiredLength;
            errorMessage = customMinLengthMessage ? customMinLengthMessage : `Minimum of ${minLength} characters`;
            break;
          case FormValidationErrors.Pattern:
            const customPatternMessage = this.tryGetCustomValidationMessage(
              key,
              FormValidationErrors.Pattern,
              customValidationMessages
            );
            errorMessage = customPatternMessage ? customPatternMessage : `Invalid format`;
            break;
        }
        formErrors[key] = errorMessage;
      } else {
        formErrors[key] = '';
      }
    });

    return formErrors;
  }

  private tryGetCustomValidationMessage(
    fieldKey: string,
    errorType: string,
    customValidationMessages?: object
  ): string {
    const customFieldMessages = customValidationMessages ? customValidationMessages[fieldKey] : '';
    return customFieldMessages && customFieldMessages[errorType] ? customFieldMessages[errorType] : '';
  }
}
