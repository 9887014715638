import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportSummaryDTO } from 'src/apiclient/v1.1/models';
import { ReportSummaryService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';

@Component({
  selector: 'app-miles-widget',
  templateUrl: './miles-widget.component.html',
  styleUrls: ['./miles-widget.component.scss'],
})
export class MilesWidgetComponent implements OnInit {
  public data$: Observable<ReportSummaryDTO>;

  constructor(private reportSummaryService: ReportSummaryService, private clientTokenService: ClientTokenService) {}

  ngOnInit(): void {
    this.data$ = this.reportSummaryService.GetMilesSummaryData(this.clientTokenService.auth());
  }
}
