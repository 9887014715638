/// <reference types="@types/googlemaps" />
import { Component, OnInit, Injector } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { MarketplaceService } from '../../../../apiclient/services/marketplace.service';
import { DtoFormBase } from '../../../shared/FormBase';
import { Utils } from '../../../../utils/utils';
import { FormControl } from '@angular/forms';
import {
  CarrierQueryDTO,
  MarketplaceCarrierDTO,
  MarketplaceCarrierSearchResultDTO,
  LocationDTO,
} from '../../../../apiclient/models';
import * as moment from 'moment';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { TableColumn } from 'src/app/shared/models/TableColumn';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FileSaverService } from 'src/services/file-saver/file-saver.service';
import { Role } from '../../../data/static-data';

declare var google: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class MarketplaceDashboardComponent extends DtoFormBase<CarrierQueryDTO> implements OnInit {
  filterHelper: any = {
    location: null,
    startDate: null,
    endDate: null,
    dateCriteria: 'delivery',
  };

  dateCriteria = [
    {
      label: 'Shipping Date',
      value: 'shipping',
    },
    {
      label: 'Delivery Date',
      value: 'delivery',
    },
  ];

  radiusOptions = [
    {
      label: '10',
      value: '10',
    },
    {
      label: '25',
      value: '25',
    },
    {
      label: '50',
      value: '50',
    },
  ];

  carriers: MarketplaceCarrierDTO[] = [];

  colsDelivery: TableColumn[] = [
    {
      header: 'Delivery Date',
      field: 'deliveryDate',
      format: 'Date',
      sortable: true,
    },
    {
      header: 'Delivery Location',
      field: 'destination.label',
      sortableField: 'location',
      sortable: true,
    },
  ];

  colsShipping: TableColumn[] = [
    {
      header: 'Shipping Date',
      field: 'shipDate',
      format: 'Date',
      sortable: true,
    },
    {
      header: 'Shipping Location',
      field: 'origin.label',
      sortableField: 'location',
      sortable: true,
    },
  ];

  colsDynamic: TableColumn[] = [];

  cols: TableColumn[] = [];

  map: any;
  geocoder: any;
  allMarkers: any = [];
  infoWindow: any;
  overlays: any[];
  oms: any;
  spiderOptions = { spiralFootSeparation: 146, spiralLengthStart: 122, spiranLenthFactor: 18, keepSpiderfied: true };
  bounds = new google.maps.LatLngBounds();
  locationAsked: boolean = false;

  mapOptions = {
    center: { lat: 39, lng: -95 },
    zoom: 4,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    styles: Utils.GMAP_OPTIONS,
  };
  mapData: any;
  dateType: any;

  constructor(
    protected injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private marketplaceService: MarketplaceService,
    private fileSaverService: FileSaverService
  ) {
    super(injector);
    this.model = { locationRadiusMiles: 50 } as CarrierQueryDTO;
    this.initFormFor('CarrierQueryDTO');
    this.formGroup.addControl('location', new FormControl());
    this.formGroup.addControl('startDate', new FormControl());
    this.formGroup.addControl('endDate', new FormControl());
    this.formGroup.addControl('dateCriteria', new FormControl());
  }

  ngOnInit() {
    const allowedRoles = [Role.SuperAdmin, Role.Admin, Role.Dispatch, Role.LogisticsCarrierAgent];
    const allowedPermissions = ['Marketplace'];
    if (
      !allowedPermissions.some((permission) => this.clientToken.permissions().includes(permission)) ||
      !allowedRoles.some((roles) => this.clientToken.roles().includes(roles))
    ) {
      this.router.navigate(['/']);
    }
    this.breadcrumbService.setItems('marketplace');
    this.model = {
      locationRadiusMiles: 50,
    } as CarrierQueryDTO;
    this.overlays = new Array<google.maps.Marker>();
    this.infoWindow = new google.maps.InfoWindow({ maxWidth: 320 });
    this.setCols();
    this.locationAsked = false;
    google.maps.visualRefresh = true;
  }

  setMap(event) {
    this.map = event.map;
    this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);
    if (this.carriers.length > 0) {
      this.mapResults(this.mapData, this.dateType);
    } else {
      this._NS.warn('Nothing found', 'We could not find results with this criteria');
    }
    if (!this.locationAsked) {
      if (this.overlays.length === 1) {
        this.map.setZoom(18);
      } else {
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      }
      if (this.bounds) {
        this.map.setCenter(this.bounds.getCenter());
      }
    }
  }

  setCols() {
    this.cols = [
      {
        header: 'Carrier',
        field: 'name',
        sortableField: 'name',
        sortable: true,
      },
      {
        header: 'Phone',
        field: 'phone',
        sortableField: 'phone',
        sortable: false,
      },
      ...this.colsDynamic,
      {
        header: 'Equipment Type',
        field: 'equipment',
        sortable: true,
      },
    ];
  }

  search() {
    this.removeMarkers();
    this.carriers = [];
    this.bounds = new google.maps.LatLngBounds();
    const type = this.filterHelper.dateCriteria;
    switch (type) {
      case 'delivery':
        this.colsDynamic = this.colsDelivery;
        if (!this.model.deliveryBegin && !this.model.deliveryEnd) {
          this._NS.info('Delivery Dates are required', 'Please select the start / end dates');
          return;
        }

        break;
      case 'shipping':
        this.colsDynamic = this.colsShipping;
        if (!this.model.shippingBegin && !this.model.shippingEnd) {
          this._NS.info('Shipping Dates are required', 'Please select the start / end dates');
          return;
        }
        break;
      default:
        break;
    }

    this.setCols();

    this.showProgressOverlay = true;
    this.marketplaceService
      .ApiMarketplaceCarriersPost({
        Authorization: this.clientToken.auth(),
        query: this.model,
      })
      .pipe(finalize(() => (this.showProgressOverlay = false)))
      .subscribe((data) => {
        this.carriers = data.results;
        if (!this.map) {
          this.mapData = data;
          this.dateType = type;
        } else {
          if (this.carriers.length > 0) {
            this.mapResults(data, type);
          } else {
            this._NS.warn('Nothing found', 'We could not find results with this criteria');
          }

          this.oms.addListener('format', function (marker, status) {
            marker.setLabel(null);
            const plusIcon =
              status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
                ? false
                : status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
                ? true
                : status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
                ? false
                : null;
            if (plusIcon) {
              marker.setLabel({
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px',
                text: '+',
              });
            }
          });
          if (this.bounds && !this.locationAsked) {
            this.map.setCenter(this.bounds.getCenter());
          }
        }
      });
  }
  removeMarkers(): any {
    this.overlays.forEach((overlay) => {
      overlay.setMap(null);
    });
    this.allMarkers.forEach((overlay) => {
      overlay.setMap(null);
    });
  }

  export() {
    this.showProgressOverlay = true;
    this.marketplaceService
      .ApiMarketplaceCarriersExportByOffsetHoursPost({
        Authorization: this.clientToken.auth(),
        query: this.model,
        offsetHours: moment().local().format('Z'),
      })
      .pipe(
        tap((data) => this.fileSaverService.saveAs(data, 'Carriers.csv')),
        catchError((error) => this.handleBasicError(error)),
        finalize(() => (this.showProgressOverlay = false))
      )
      .subscribe();
  }

  mapResults(data: MarketplaceCarrierSearchResultDTO, type): void {
    this.overlays = [];
    this.oms.forgetAllMarkers();
    this.oms.removeAllMarkers();
    if (data.latitude !== 0 && data.longitude !== 0) {
      this.locationAsked = true;
      const circleRadius = this.model.locationRadiusMiles * 1609.34;
      this.overlays.push(
        new google.maps.Circle(
          {
            clickable: false,
            center: { lat: data.latitude, lng: data.longitude },
            fillColor: '#1976D2',
            fillOpacity: 0.35,
            strokeWeight: 1,
            radius: circleRadius,
          },
          { clickable: false }
        )
      );
      this.map.setCenter({ lat: data.latitude, lng: data.longitude });
      this.map.setZoom(9);
    }

    let markersFailed = 0;
    this.removeMarkers();
    this.allMarkers = [];
    this.bounds = new google.maps.LatLngBounds();
    data.results.forEach((carrier) => {
      if (!this.addMapIcon(carrier, type)) {
        markersFailed++;
      }
    });
    if (markersFailed > 0) {
      this._NS.info('Missing results on map', markersFailed + ' result(s) are not being shown on map');
    }

    this.oms.addListener('format', function (marker, status) {
      marker.setLabel(null);
      const plusIcon =
        status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
          ? false
          : status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
          ? true
          : status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
          ? false
          : null;
      if (plusIcon) {
        marker.setLabel({
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          text: '+',
        });
      }
    });
    setTimeout((_) => {
      if (!this.locationAsked) {
        if (this.overlays.length === 1) {
          this.map.setZoom(18);
        } else {
          if (this.bounds) {
            this.map.fitBounds(this.bounds);
            this.map.panToBounds(this.bounds);
          }
        }
        this.map.setCenter(this.bounds.getCenter());
      }
    });
  }

  addMapIcon(carrier: MarketplaceCarrierDTO, type): any {
    const fillColor = '#0099ff';
    let location = {} as LocationDTO;

    switch (type) {
      case 'shipping':
        location = carrier.origin;
        break;
      case 'delivery':
        location = carrier.destination;
        break;
    }

    if (location.latitude === 0 && location.longitude === 0) {
      return false;
    }

    const marker = new google.maps.Marker({
      icon: Utils.buildMarkerSymbol(fillColor),
      map: this.map,
      position: new google.maps.LatLng(location.latitude, location.longitude),
      title: carrier.name,
      carrier: carrier,
      resultType: type,
    });
    this.bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
    const thisRef = this;
    const docRef = document;
    google.maps.event.addListener(marker, 'spider_click', function (e) {
      thisRef.handleSpiderClick(marker, thisRef, thisRef.infoWindow, docRef);
    });
    if (!this.oms) {
      this.oms = new OverlappingMarkerSpiderfier(this.map, this.spiderOptions);
    }
    this.oms.addMarker(marker);
    this.allMarkers.push(marker);
    return true;
  }

  handleSpiderClick(marker, wpRef, infoWin, docRef) {
    const isMarker = marker.getTitle !== undefined;
    if (isMarker) {
      infoWin.setContent(this.infoWindowContent(marker.carrier, marker.resultType));
      infoWin.open(wpRef.map, marker);
      wpRef.map.setCenter(marker.getPosition());
    }
  }

  handleOverlayClick(event) {
    const isMarker = event.overlay.getTitle !== undefined;
    if (isMarker) {
      this.infoWindow.setContent(this.infoWindowContent(event.overlay.carrier, event.overlay.resultType));
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());
    } else {
      this.oms.unspiderfy();
    }
  }

  infoWindowContent(carrier: MarketplaceCarrierDTO, type) {
    const date = type === 'shipping' ? carrier.shipDate : carrier.deliveryDate;

    return `
      <strong> ${carrier.equipment} </strong> <br>
      ${carrier.name}  <br>
      <a href="tel:${carrier.phone}">${carrier.phone}</a> <br>
      <br>
      <span style="text-transform: capitalize"> ${type} </span> Date <br>
      ${moment(date).format('MM/DD/YYYY hh:mm a')} <br>
    `;
  }

  sortTable(event: any) {
    Utils.sortData(event, this.carriers);
  }

  filterDateRangeChange() {
    switch (this.filterHelper.dateCriteria) {
      case 'shipping':
        this.model.shippingBegin = Utils.formatDate(this.filterHelper.startDate);
        this.model.shippingEnd = Utils.formatDate(this.filterHelper.endDate);
        this.model.deliveryBegin = null;
        this.model.deliveryEnd = null;
        break;
      case 'delivery':
        this.model.shippingBegin = null;
        this.model.shippingEnd = null;
        this.model.deliveryBegin = Utils.formatDate(this.filterHelper.startDate);
        this.model.deliveryEnd = Utils.formatDate(this.filterHelper.endDate);
        break;
      default:
        this.model.shippingBegin = null;
        this.model.shippingEnd = null;
        this.model.deliveryBegin = null;
        this.model.deliveryEnd = null;
        break;
    }
  }
}
