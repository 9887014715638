import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PlatformNotificationDTO, PlatformNotificationTypeDTO } from 'src/apiclient/v1.1/models';
import { SelectItem } from 'primeng-lts/api';
import { PlatformNotificationService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { DateService } from 'src/services/date/date.service';
import { EditorSanitizationService } from 'src/services/editor-sanitization/editor-sanitization.service';
import { PlatformNotificationEditMode } from '../enums/platform-notification-edit-mode';

@Component({
  selector: 'app-edit-notifications',
  templateUrl: './edit-notifications.component.html',
  styleUrls: ['./edit-notifications.component.scss'],
})
export class EditNotificationsComponent implements OnInit, OnDestroy {
  @Input() notificationId: number;
  @Input() editModeType: PlatformNotificationEditMode;
  @Input() notificationTypes: PlatformNotificationTypeDTO[];
  @Output() cancel = new EventEmitter<void>();
  @Output() create = new EventEmitter<PlatformNotificationDTO>();
  @Output() update = new EventEmitter<PlatformNotificationDTO>();

  public headerLabel: string;
  public model: PlatformNotificationDTO = {};
  public notificationTypeItems: SelectItem[] = [];

  public invalidStartDate: boolean = false;
  public invalidEndDate: boolean = false;
  public invalidCharacterCount: boolean = false;
  public readonly textMaxLength: number = 400;

  public notificationForm: FormGroup;
  private destroy$ = new Subject<void>();

  public strippedText: string;

  public readonly PlatformNotificationEditMode = PlatformNotificationEditMode;

  constructor(
    private platformNotificationService: PlatformNotificationService,
    private clientTokenService: ClientTokenService,
    private formBuilder: FormBuilder,
    private formValidationErrorsService: FormValidationErrorsService,
    private errorHandlingService: ErrorHandlingService,
    private dateService: DateService,
    private editorSanitizationService: EditorSanitizationService
  ) {}

  public ngOnInit(): void {
    this.setHeaderLabel();
    this.setNotificationTypeSelectItems();
    this.initForm();
    this.initValues();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setHeaderLabel(): void {
    this.headerLabel = this.isEditModeTypeAdd() ? 'Create New Notification' : 'Edit Notification';
  }

  public setButtonLabel(): string {
    return this.isEditModeTypeAdd() ? 'Add Notification' : 'Save Changes';
  }

  public isEditModeTypeAdd(): boolean {
    return this.editModeType === PlatformNotificationEditMode.Add;
  }

  public updateType($event: any): void {
    this.notificationForm.controls.platformNotificationTypeId.markAsTouched();

    if (!this.isEditModeTypeAdd()) {
      return;
    }

    this.notificationForm.controls.title.setValue(
      this.notificationTypes.find((x) => x.id === $event.value).titleDefault
    );
  }

  public validateStartDateTime($event: Date): void {
    this.notificationForm.controls.startDate.markAsTouched();

    if ($event && this.notificationForm.controls.endDate.value) {
      this.invalidStartDate = $event > this.notificationForm.controls.endDate.value;
    }
  }

  public validateEndDateTime($event: Date): void {
    this.notificationForm.controls.endDate.markAsTouched();

    if ($event && this.notificationForm.controls.startDate.value) {
      this.invalidEndDate = $event < this.notificationForm.controls.startDate.value;
    }
  }

  public createOrUpdateNotification(): void {
    this.setModel();
    this.editModeType === PlatformNotificationEditMode.Add
      ? this.create.emit(this.model)
      : this.update.emit(this.model);
  }

  public getFormErrors(formControlName: string): string {
    const formErrors = this.formValidationErrorsService.getFormErrors(this.notificationForm);
    return formErrors?.[formControlName];
  }

  public checkCharacterCount(): boolean {
    this.invalidCharacterCount = this.strippedText?.length > this.textMaxLength;
    return this.invalidCharacterCount;
  }

  public isFormValid(): boolean {
    return this.notificationForm.valid && this.notificationForm.touched;
  }

  private initForm(): void {
    this.notificationForm = this.formBuilder.group({
      platformNotificationTypeId: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.maxLength(60)]],
      text: ['', [Validators.required]],
      startDate: ['', []],
      endDate: ['', []],
    });

    this.notificationForm.valueChanges
      .pipe(
        tap(() => {
          this.model = { ...this.notificationForm.getRawValue() };
          this.model.text = this.editorSanitizationService.stripTagsForNotification(
            this.notificationForm.controls.text.value
          );
          this.strippedText = this.editorSanitizationService.stripAllTags(this.notificationForm.controls.text.value);
          this.model.platformNotificationType = this.notificationTypes.find(
            (type) => type.id === this.notificationForm.controls.platformNotificationTypeId.value
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setModel(): void {
    const { startDate, endDate } = this.notificationForm.controls;

    this.model.platformNotificationId = this.notificationId ?? 0;
    this.model.startDate = startDate.value ? this.dateService.getFormattedUTCDate(startDate.value) : null;
    this.model.endDate = endDate.value ? this.dateService.getFormattedUTCDate(endDate.value) : null;
  }

  private initValues(): void {
    if (!this.notificationId) {
      const defaultNotificationType = this.notificationTypes.find((type) => type.id === 1);

      this.model.platformNotificationType = defaultNotificationType;
      this.notificationForm.patchValue({
        platformNotificationTypeId: 1,
        title: defaultNotificationType.titleDefault,
      });
    } else {
      this.getNotificationById(this.notificationId)
        .pipe(
          takeUntil(this.destroy$),
          catchError((error) => this.errorHandlingService.handleError(error))
        )
        .subscribe((data) => {
          this.model.platformNotificationType = data.platformNotificationType;
          this.notificationForm.patchValue({
            platformNotificationTypeId: data.platformNotificationTypeId,
            title: data.title,
            text: data.text,
            startDate: data.startDate ? new Date(data.startDate) : '',
            endDate: data.endDate ? new Date(data.endDate) : '',
          });
        });
    }
  }

  private getNotificationById(notificationId: number): Observable<PlatformNotificationDTO> {
    const params: PlatformNotificationService.GetNotificationByIdParams = {
      Authorization: this.clientTokenService.auth(),
      id: notificationId,
    };

    return this.platformNotificationService.GetNotificationById(params);
  }

  private setNotificationTypeSelectItems(): void {
    this.notificationTypeItems = this.notificationTypes.map((type) => ({
      value: type.id,
      icon: type.icon,
    }));
  }
}
