import { Component, OnInit, Injector, Input } from '@angular/core';
import { TenantService, SystemSettingService, AllTenantService } from '../../../../apiclient/v1.1/services';
import { AddressDTO, PhoneDTO, TenantDTO } from '../../../../apiclient/v1.1/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { ConfirmationService } from 'primeng/api';
import { Permission, TenantRoleDisplayName } from 'src/app/data/static-data';

@Component({
  selector: 'app-tenant-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class TenantEditComponent extends DtoFormBase<TenantDTO> implements OnInit {
  @Input() public tenant: TenantDTO;
  public disableSave: boolean = false;
  public paymentTermOptions: any[];
  public nextLoadNumberLocked = false;
  public editing = {
    info: false,
    address: false,
    contact: false,
    system: false,
    security: false,
  };
  public addressesSame = false;
  public isRoleVisible = false;
  public roleName: string;
  public readonly superAdminPermissionRequired = Permission.TenantEditAll;
  private modelOriginal: TenantDTO;
  private seenNextLoadNumberWarning = false;
  private isSuperAdmin: boolean = false;

  constructor(
    private tenantService: TenantService,
    private systemSettingService: SystemSettingService,
    protected injector: Injector,
    private allTenantService: AllTenantService,
    private confirmationService: ConfirmationService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    if (!this.clientToken.hasPermission('TenantEdit')) {
      this.router.navigate(['/']);
    }

    // The child components that modify these objects do not implement `ControlValueAccessor`.
    // Since `formValid()` uses the form value but the component uses reactive and template forms, it's validating a reactive form that is not in sync with template form.
    this.model.phone = {} as PhoneDTO;
    this.model.mailing = {} as AddressDTO;
    this.model.physical = {} as AddressDTO;

    this.initFormFor('TenantDTO', ['addressesSame']);
    this.isSuperAdmin = this.clientToken.hasPermission(this.superAdminPermissionRequired);
    this.getTenant();
    this.getPaymentTermOptions();
  }

  public async systemSaveClick(): Promise<void> {
    if (this.model.nextLoadNumber !== this.modelOriginal.nextLoadNumber) {
      this.model.nextLoadNumberModifiedOn = new Date().toUTCString();
      this.model.nextLoadNumberModifiedBy = this.clientToken.userId();
      if (!this.isSuperAdmin) {
        const message =
          '<p>You may only set the invoice number sequence one time. After that, the \
        field will not be editable. In addition, the invoice number sequence you choose will only \
        apply to newly created invoices. </p><p> Do you wish to proceed?</p>';

        if (!this.seenNextLoadNumberWarning) {
          this.confirmationService.confirm({
            header: 'Confirm Next Invoice Number Sequence Change',
            message: message,
            key: 'NextLoadNumberConfirmation',
            accept: () => {
              this.acceptNextLoadNumberWarning();
            },
            reject: () => {
              this.rejectNextLoadNumberWarning();
            },
          });
        }
      } else {
        // super admins can always set, don't need to see warning
        await this.saveTenant('system');
      }
    } else {
      // no invoice number change, just save
      await this.saveTenant('system');
    }
  }

  public reset(section): void {
    this.editing[section] = false;
    this.model = JSON.parse(JSON.stringify(this.modelOriginal));
  }

  public async saveTenant(section): Promise<void> {
    if (this.addressesSame) {
      this.addressCopy();
    }
    if (this.formValid()) {
      try {
        this.disableSave = true;
        const params = {
          id: this.model.tenantId,
          body: this.model,
          Authorization: this.clientToken.auth(),
        };
        if (!this.isSuperAdmin) {
          await this.tenantService.Put(params).toPromise();
        } else {
          await this.allTenantService.PutTenant(params).toPromise();
        }

        this._NS.success('Info Updated.', 'Your updates were saved successfully.');
        this.editing[section] = false;
        this.modelOriginal = JSON.parse(JSON.stringify(this.model));
      } catch (e) {
        this.handleError(e);
      } finally {
        this.disableSave = false;
      }
    } else {
      // tslint:disable-next-line:forin
      for (const name in this.formGroup.controls) {
        this.formGroup.controls[name].markAsTouched();
      }
    }
  }

  public physicalAddressFormat(): string {
    let address = this.model.physical.line1;
    address += this.model.physical.line2 ? ', ' + this.model.physical.line2 : '';
    address += ', ' + this.model.physical.city;
    address += ', ' + this.model.physical.state;
    address += ', ' + this.model.physical.zip;

    return address;
  }

  public mailingAddressFormat(): string {
    let address = this.model.mailing.line1;
    address += this.model.mailing.line2 ? ', ' + this.model.mailing.line2 : '';
    address += ', ' + this.model.mailing.city;
    address += ', ' + this.model.mailing.state;
    address += ', ' + this.model.mailing.zip;

    return address;
  }

  private addressCopy(): void {
    this.model.physical = JSON.parse(JSON.stringify(this.model.mailing));
  }

  public isOneAddress(): boolean {
    return (
      this.model.physical.line1 === this.model.mailing.line1 &&
      this.model.physical.line2 === this.model.mailing.line2 &&
      this.model.physical.city === this.model.mailing.city &&
      this.model.physical.state === this.model.mailing.state &&
      this.model.physical.zip === this.model.mailing.zip
    );
  }

  private getTenant(): void {
    this.model = this.tenant;
    this.addressesSame = this.isOneAddress();
    this.modelOriginal = JSON.parse(JSON.stringify(this.model));
    this.nextLoadNumberLocked = !this.isSuperAdmin && !!this.model.nextLoadNumberModifiedBy;
    this.isRoleVisible = this.isTenantRoleVisible();
    this.roleName = this.model.isLogistics ? TenantRoleDisplayName.Logistics : TenantRoleDisplayName.Carrier;
  }

  private getPaymentTermOptions(): void {
    this.systemSettingService.PaymentTermOptions(this.clientToken.auth()).subscribe((data) => {
      this.paymentTermOptions = [];
      data.forEach((element) => {
        this.paymentTermOptions.push({ label: element, value: [element] });
      });
    }, this.handleError);
  }

  private async acceptNextLoadNumberWarning(): Promise<void> {
    this.seenNextLoadNumberWarning = true;

    await this.saveTenant('system');
    this.nextLoadNumberLocked = true;
  }

  private async rejectNextLoadNumberWarning(): Promise<void> {
    this.reset('system');
  }

  private isTenantRoleVisible(): boolean {
    const isLogisticsAdmin = this.clientToken.hasPermission(Permission.TenantEdit) && !!this.model.isLogistics;
    return this.isSuperAdmin || isLogisticsAdmin;
  }
}
