<div class="edit-notification-container grid">
  <div class="edit-form-container p-0">
    <div class="edit-form-header flex align-items-center">
      {{ headerLabel }}
      <span *ngIf="!isEditModeTypeAdd()" class="id-selected">&nbsp;#{{ notificationId }}</span></div
    >
    <div class="form-container grid justify-content-center m-5">
      <form [formGroup]="notificationForm" #focusable>
        <div class="action flex col-10 justify-content-between">
          <div class="w-6 col-2"> Notification Type<span class="required">*</span> </div>
          <div class="col-10 ml-1">
            <p-selectButton
              formControlName="platformNotificationTypeId"
              [options]="notificationTypeItems"
              (onChange)="updateType($event)"
            ></p-selectButton>
          </div>
        </div>
        <div class="action flex col-12 justify-content-between">
          <div class="w-5 col-2"> Title<span class="required">*</span> </div>
          <div class="col-8">
            <input
              pInputText
              type="text"
              placeholder="Headline Here"
              class="col-12"
              formControlName="title"
              maxlength="60"
            />
            <div class="form-error" *ngIf="getFormErrors('title')">
              {{ getFormErrors('title') | formatErrorMessage }}
            </div>
          </div>
        </div>
        <div class="action flex col-12 justify-content-between">
          <div class="w-5 col-2"> Body Text<span class="required">*</span> </div>
          <div class="col-8">
            <p-editor formControlName="text" placeholder="Message Here">
              <p-header>
                <span class="ql-formats">
                  <button class="ql-link" aria-label="Link"></button>
                </span>
              </p-header>
            </p-editor>
            <div class="character-count flex justify-content-end">
              {{ strippedText?.length || 0 }}/{{ textMaxLength }}
            </div>
            <div class="form-error" *ngIf="getFormErrors('text')">
              {{ getFormErrors('text') | formatErrorMessage }}
            </div>
            <div class="form-error" *ngIf="checkCharacterCount()"> Maximum of {{ textMaxLength }} characters. </div>
          </div>
        </div>
        <div class="action flex col-12 justify-content-between">
          <div class="w-5 col-2"> Start Date & Time </div>
          <div class="col-8">
            <app-date-time-selector formControlName="startDate" (dateChanged)="validateStartDateTime($event)">
            </app-date-time-selector>
            <div class="form-error date-error" *ngIf="invalidStartDate">
              Please provide a Start Date/Time that is earlier than End Date/Time
            </div>
          </div>
        </div>
        <div class="action flex col-12 justify-content-between">
          <div class="w-5 col-2"> End Date & Time</div>
          <div class="col-8">
            <app-date-time-selector formControlName="endDate" (dateChanged)="validateEndDateTime($event)">
            </app-date-time-selector>
            <div class="form-error date-error" *ngIf="invalidEndDate">
              Please provide an End Date/Time that is later than Start Date/Time
            </div>
          </div>
        </div>
        <div class="action-buttons flex justify-content-end col-12 p-0 pt-2">
          <button
            type="button"
            pButton
            icon="fa fa-times"
            (click)="cancel.emit()"
            label="Cancel"
            class="ui-button-secondary m-2"
          ></button>
          <button
            id="create-button"
            type="button"
            pButton
            icon="fa fa-check"
            (click)="createOrUpdateNotification()"
            [disabled]="!isFormValid() || invalidStartDate || invalidEndDate || invalidCharacterCount"
            [label]="setButtonLabel()"
            class="m-2 pl-1 pr-1 w-9rem"
          ></button>
        </div>
      </form>
    </div>
  </div>
  <div class="preview-container p-0">
    <div class="preview-header flex align-items-center"> Notification Preview </div>
    <div class="preview flex justify-content-center p-4">
      <app-platform-notification [notification]="model"> </app-platform-notification>
    </div>
  </div>
</div>
