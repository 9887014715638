import {
  Component,
  OnInit,
  Injector,
  HostListener,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  CompanyDTO,
  SimpleContactDTO,
  CompanyAddressDTO,
  AddressDTO,
  IdNamePairDTO,
  DebtorAddressMatchingDTO,
} from '../../../../apiclient/v1.1/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { FormControl, Validators } from '@angular/forms';
import { ScrollManagerService } from '../../../../services/scroll-manager.service';
import { CompanySelectorComponent } from '../../../components/company-selector/company-selector.component';
import { FormFieldComponent } from '../../../components/form/field/field.component';
import { catchError, finalize, map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { CompanyService } from '../../../../apiclient/v1.1/services';
import { Observable, of, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { StrictHttpResponse } from 'src/apiclient/v1.1/strict-http-response';
import { ActivatedRoute } from '@angular/router';
import { CompanyAddressConflictService } from '../../../../services/company-address-conflict-service';
import { BusinessAssociateMergeService } from '../../../../services/business-associate-merge-service';
import { Permission, Role } from 'src/app/data/static-data';

@Component({
  selector: 'app-company-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyEditComponent extends DtoFormBase<CompanyDTO> implements OnInit, OnDestroy {
  @ViewChild('companyNameSelector', { static: true }) companyNameSelector: CompanySelectorComponent;
  @ViewChild('companyStatusSelect', { static: true }) companyStatusSelect: FormFieldComponent;
  @ViewChild('legalNameCheck', { static: true }) legalNameCheck: FormFieldComponent;
  @ViewChild('managedAccountCheck', { static: true }) managedAccountCheck: FormFieldComponent;

  @Output() modelEdited;
  @Output() dialogClosed = new EventEmitter<void>();

  public isNewRecord = false;
  public dialogWidth: number;
  public dialogHeight: number;
  public contentDialogStyle: any;
  public emptyCompanyName: string = 'Company name';
  public locationId: number = -1;
  public displayDialog: boolean;
  public submitForm: boolean = false;
  public addressesCollapsed: boolean = false;
  public selectedIndex: number;
  public creditLimitCheck: boolean = false;
  public creditLimitAccess: boolean = false;
  public canViewManagedAccount: boolean = false;
  public canEditManagedAccount: boolean = false;

  private TAB_KEY: String = 'Tab';
  private creditLimitError: boolean = false;
  private creditLimitAddressCheck: boolean = false;
  private modelId: number;
  private mergedCompanyId: number;
  private destroy$ = new Subject<void>();

  public readonly role = Role;

  constructor(
    private scrollManagerService: ScrollManagerService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private companyAddressConflictService: CompanyAddressConflictService,
    private businessAssociateMergeService: BusinessAssociateMergeService,
    protected injector: Injector
  ) {
    super(injector);
    this.modelEdited = new EventEmitter();
    this.model = {} as CompanyDTO;
    this.model.primaryContact = {} as SimpleContactDTO;

    const newAddress = {} as CompanyAddressDTO;
    newAddress.address = {} as AddressDTO;
    newAddress.primaryContact = {} as SimpleContactDTO;
    newAddress.billToContact = {} as SimpleContactDTO;
    newAddress.shipperContact = {} as SimpleContactDTO;
    newAddress.consigneeContact = {} as SimpleContactDTO;
    newAddress.isBillTo = true;
    newAddress.isConsignee = false;
    newAddress.isShipper = false;

    this.model.addresses = [newAddress] as CompanyAddressDTO[];
    this.updateDialogSize();
  }

  @HostListener('window:resize') onResize() {
    this.updateDialogSize();
  }

  public ngOnInit(): void {
    this.initFormFor('CompanyDTO');
    this.formGroup.addControl('customerInputDisabled', new FormControl());
    this.formGroup.addControl('creditLimitCheck', new FormControl(this.creditLimitCheck));
    this.formGroup.get('customerInputDisabled').disable();
    this.initFormSubscriptions();
    this.canEditManagedAccount = this.clientToken.hasPermission(Permission.ManagedAccountEdit);

    const passedCompanyId = this.activatedRoute.snapshot.params.id;
    if (passedCompanyId) {
      this.init(Number(passedCompanyId));
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(id: number, locationId = 0): void {
    this.formGroup.enable();
    this.addressesCollapsed = false;

    if (!this.clientToken.hasPermission(Permission.CompanyEdit)) {
      this.displayDialog = false;
      return;
    }
    this.submitForm = false;
    this.modelId = id;
    this.model = {} as CompanyDTO;
    this.model.primaryContact = {} as SimpleContactDTO;

    const newAddress = {} as CompanyAddressDTO;
    newAddress.address = {} as AddressDTO;
    newAddress.primaryContact = {} as SimpleContactDTO;
    newAddress.billToContact = {} as SimpleContactDTO;
    newAddress.shipperContact = {} as SimpleContactDTO;
    newAddress.consigneeContact = {} as SimpleContactDTO;
    newAddress.isBillTo = true;
    newAddress.isConsignee = false;
    newAddress.isShipper = false;

    this.model.addresses = [newAddress] as CompanyAddressDTO[];
    this.locationId = locationId;
    this.getModel();

    if (id === 0 && locationId === 0) {
      this.selectedIndex = 0;
    }
  }

  public onCreditLimitError(event): void {
    this.creditLimitError = event.creditLimitError;
    this.creditLimitAddressCheck = event.creditLimitCheck;
  }

  public onCompanyChange(event: IdNamePairDTO): void {
    if (event.id > 0) {
      this.init(this.model.companyId);
      this.addressesCollapsed = true;
    }
    this.model.name = event.name;
    this.formGroup.patchValue({ name: event.name });
  }

  public onNewCreditLimitChanged(event): void {
    if (event.creditLimitCheck) {
      this.formGroup.controls['creditLimit'].setValidators(Validators.required);
    } else {
      this.formGroup.controls['creditLimit'].clearValidators();
    }
    // this.formGroup.controls['creditLimit'].updateValueAndValidity();
    this.model.creditLimit = event.creditLimit;
    this.model.isCreditLimitOpted = event.creditLimitCheck;
    this.creditLimitCheck = event.creditLimitCheck;
  }

  public onDebtorSelected(event: DebtorAddressMatchingDTO): void {
    const isSubDebtor = !!event.subDebtorId;

    isSubDebtor ? this.subDebtorSelected(event) : this.masterDebtorSelected(event);
  }

  public onManagedAccountCheckedChanged(event: any) {
    this.model.isManagedAccount = event.checked;
    this.formGroup.patchValue(this.model);
    this.formGroup.controls['salesRepresentativeTenantUser'].updateValueAndValidity();
    this.formGroup.controls['accountManagerTenantUser'].updateValueAndValidity();
  }

  public onSalesRepChange(event: IdNamePairDTO): void {
    this.model.salesRepresentativeTenantUser = event;
    this.formGroup.patchValue({ salesRepresentativeTenantUser: event });
  }

  public onAccountManagerChange(event: IdNamePairDTO): void {
    this.model.accountManagerTenantUser = event;
    this.formGroup.patchValue({ accountManagerTenantUser: event });
  }

  public doneUpdatesHandler(): void {
    if (this.displayDialog) {
      setTimeout((_) => {
        // this.companyNameSelector.autoComplete.inputEL.nativeElement.markAsUntouched();
        this.companyNameSelector.autoComplete.focusInput();
      });
    }
  }

  public legalNameCheckOnTab(event): void {
    if (!event.shiftKey && event.key === this.TAB_KEY) {
      setTimeout((_) => {
        this.companyStatusSelect.dropdownControl.applyFocus();
      });
    }
  }

  public onCompanyAddressMerge(recordToMergeWith: DebtorAddressMatchingDTO): void {
    this.businessAssociateMergeService
      .mergeBusinessAssociates(recordToMergeWith.companyId, this.model)
      .pipe(
        tap((mergedCompany) => this.handleCompanyMerge(mergedCompany, recordToMergeWith)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public saveModel(): void {
    if (
      (this.creditLimitAddressCheck && this.creditLimitError) ||
      !this.validateLocationTypes() ||
      !this.validateManagedAccount()
    ) {
      return;
    }

    this.submitForm = true;

    this.showProgressOverlay = true;

    if (this.model.companyId === 0 && !this.isNewRecord) {
      this.model.companyId = this.modelId;
    }
    if (this.creditLimitCheck !== true && this.model.creditLimit > 0) {
      this.model.creditLimit = 0;
    }

    of(this.isNewRecord)
      .pipe(
        switchMap((isNewCompany) => (isNewCompany ? this.createCompany(this.model) : this.updateCompany(this.model))),
        tap(() => {
          this.modelEdited.emit({ dataChanged: true });
          this.closeDialog();
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            this.companyAddressConflictService.displayConflictingAddresses(error.error);
            throw error;
          }

          return this.handleError(error);
        }),
        finalize(() => {
          this.scrollManagerService.resetDialogScroll();
          this.showProgressOverlay = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public cancel(): void {
    this.scrollManagerService.resetDialogScroll();
    this.selectedIndex = undefined;
    this.companyNameSelector.resetForm();
    this.closeDialog();
  }

  private closeDialog(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.router.navigate(['company']);
    }
    this.displayDialog = false;
    this.dialogClosed.emit();
  }

  private createCompany(company: CompanyDTO): Observable<void> {
    return this.companyService.PostResponse({ Authorization: this.clientToken.auth(), body: company }).pipe(
      tap((c) => this.notifySuccess(c, 'created')),
      map(() => null),
      catchError((error) => this.handleBasicError(error))
    );
  }

  private subDebtorSelected(subDebtor: DebtorAddressMatchingDTO): void {
    this.model.companyId = subDebtor.companyId;

    const subDebtorCompany: IdNamePairDTO = {
      id: subDebtor.companyId,
      name: subDebtor.companyName,
    };

    this.onCompanyChange(subDebtorCompany);
  }

  private masterDebtorSelected(masterDebtor: DebtorAddressMatchingDTO): void {
    this.formGroup.patchValue({
      name: masterDebtor.companyName,
    });
    this.formGroup.get('name').disable();
  }

  private handleCompanyMerge(mergedCompany: CompanyDTO, recordToMergeWith: DebtorAddressMatchingDTO): void {
    this.mergedCompanyId = this.modelId;

    const parentLocation = mergedCompany.addresses.find(
      (a) => a.companyAddressId === recordToMergeWith.companyAddressId
    );
    const childLocation = mergedCompany.addresses.find((a) => a.companyAddressId === this.locationId);

    const parentLocationIndex = mergedCompany.addresses.findIndex(
      (a) => a.companyAddressId === recordToMergeWith.companyAddressId
    );
    mergedCompany.addresses[parentLocationIndex] = this.businessAssociateMergeService.mergeBusinessAssociateLocations(
      parentLocation,
      childLocation
    );

    mergedCompany.addresses = mergedCompany.addresses.filter((a) => a.companyAddressId !== this.locationId);
    this.model = mergedCompany;
    this.modelId = mergedCompany.companyId;
    this.locationId = recordToMergeWith.companyAddressId;
  }

  private getModel(): void {
    if (this.modelId === 0 || !this.modelId) {
      this.isNewRecord = true;
      this.model.legalNameIsSame = true;
      this.model.status = 'Active';
      this.displayDialog = true;
      this.scrollManagerService.resetDialogScroll();
      this.creditLimitAccess = false;
      this.creditLimitCheck = false;
      return;
    }

    this.showProgressOverlay = true;
    this.companyService
      .GetCompanyById({ id: this.modelId, Authorization: this.clientToken.auth() })
      .pipe(
        tap((data) => {
          this.isNewRecord = false;
          this.model = data;
          this.creditLimitCheck = this.model.creditLimit > 0;
          if (this.model.creditLimitAccess === 'Read') {
            this.formGroup.get('creditLimitCheck').disable();
            this.formGroup.get('creditLimit').disable();
          }
          this.creditLimitAccess = this.model.creditLimitAccess !== 'none' && this.model.isCreditLimitOpted;
          if (this.model.salesRepresentativeTenantUser?.id == null) {
            this.formGroup.patchValue({ salesRepresentativeTenantUser: null });
          }
          if (this.model.accountManagerTenantUser?.id == null) {
            this.formGroup.patchValue({ accountManagerTenantUser: null });
          }
          setTimeout(() => {
            this.displayDialog = true;
            this.scrollManagerService.resetDialogScroll();
          });
        }),
        catchError((e) => this.handleError(e)),
        finalize(() => {
          this.showProgressOverlay = false;
          this.scrollManagerService.resetDialogScroll();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private updateCompany(company: CompanyDTO): Observable<void> {
    return this.companyService
      .PutResponse({
        id: company.companyId,
        Authorization: this.clientToken.auth(),
        body: company,
      })
      .pipe(
        tap((c) => this.notifySuccess(c, 'updated')),
        switchMap(() => {
          if (this.mergedCompanyId) {
            return this.markCompanyAsInactive(this.mergedCompanyId).pipe(tap(() => (this.mergedCompanyId = null)));
          }
          return of(void 0);
        })
      );
  }

  private markCompanyAsInactive(companyId: number): Observable<CompanyDTO> {
    return this.companyService.Patch({
      Authorization: this.clientToken.auth(),
      id: companyId,
      body: [
        {
          value: 1,
          path: '/status',
          op: 'replace',
        },
      ],
    });
  }

  private updateDialogSize(): void {
    this.dialogWidth = window.innerWidth;
    this.dialogHeight = window.innerHeight;
    this.contentDialogStyle = { minHeight: window.innerHeight - 106 + 'px' };
  }

  private validateLocationTypes(): boolean {
    const invalidLocations = this.model.addresses.filter(
      (address) => !address.isBillTo && !address.isShipper && !address.isConsignee
    );

    if (invalidLocations && invalidLocations.length > 0) {
      this.showValidationError(
        'All locations must have at least one specified type. Make sure to select any combination of Bill To, Shipper, and/or Consignee.'
      );
      return false;
    }

    return true;
  }

  private validateManagedAccount(): boolean {
    return !this.model.isManagedAccount || this.areSalesAndAccountManagerPresent();
  }

  private areSalesAndAccountManagerPresent(): boolean {
    return !!this.model.salesRepresentativeTenantUser && !!this.model.accountManagerTenantUser;
  }

  private notifySuccess<T>(response: StrictHttpResponse<T>, saveType: string): void {
    if (response.status === 207) {
      this._NS.notify({
        type: 'warn',
        summary: 'Company Partially Saved',
        detail: `Company was ${saveType} but one or more associated factoring records was not updated or created.`,
        sticky: true,
      });
    } else {
      this._NS.success('Company Saved', `Company was ${saveType} successfully.`);
    }
  }

  private initFormSubscriptions(): void {
    this.formGroup.get('addresses').valueChanges.subscribe((value) => {
      this.setManagedAccountAccess(value);
    });

    this.formGroup.get('isManagedAccount').valueChanges.subscribe((value) => {
      this.setManagedAccountValidators(value);
    });
  }

  private setManagedAccountAccess(addresses: CompanyAddressDTO[]): void {
    this.canViewManagedAccount = addresses.some((x) => x.isBillTo) && this.clientToken.isLogisticsTenant();
  }

  private setManagedAccountValidators(isManagedAccount: boolean): void {
    if (isManagedAccount) {
      this.formGroup.controls['salesRepresentativeTenantUser'].setValidators([Validators.required]);
      this.formGroup.controls['accountManagerTenantUser'].setValidators([Validators.required]);
    } else {
      this.formGroup.controls['salesRepresentativeTenantUser'].clearValidators();
      this.formGroup.controls['accountManagerTenantUser'].clearValidators();
    }
    this.formGroup.controls['salesRepresentativeTenantUser'].updateValueAndValidity();
    this.formGroup.controls['accountManagerTenantUser'].updateValueAndValidity();
  }

  private showValidationError(message: string): void {
    this._NS.error('Validation Error', message);
  }
}
