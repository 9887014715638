<p-dialog
  header="New Document"
  appendTo="body"
  [(visible)]="dialogVisible"
  [closable]="false"
  [modal]="true"
  [style]="{ width: '37.5rem' }"
  (onHide)="closeDialog()"
  [focusOnShow]="false"
>
  <div class="content">
    <form [formGroup]="formGroup">
      <div class="grid grid-nogutter col-12 p-1">
        <div class="grid col-12 p-1">
          <div class="col-4 font-bold"> File </div>
          <div class="col-8">
            <p-fileUpload
              #fileUpload
              mode="basic"
              name="file"
              chooseLabel="Select file..."
              [customUpload]="true"
              (onSelect)="onFileSelect($event)"
            ></p-fileUpload>
            <button
              pButton
              *ngIf="fileUpload.files.length > 0"
              (click)="fileUpload.clear()"
              type="button"
              icon="fas fa-times"
            ></button>
          </div>
          <div *ngIf="formErrors['file']" class="col-8 col-offset-4 p-0">
            <span class="formerrors p-1">{{ formErrors['file'] }}</span>
          </div>
        </div>
      </div>

      <app-form-field
        [(formGroup)]="formGroup"
        label="Type"
        type="select"
        [config]="fileTypeConfig"
        formControlName="type"
        [formErrors]="formErrors"
        placeholder="Required"
        labelClass="col-12 md:col-4 px-2"
        controlClass="col-12 md:col-8"
        errorClass="col-8 col-offset-4 p-0 py-1"
        (dropdownChanged)="typeChanged()"
      >
      </app-form-field>
    </form>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="closeDialog()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      [disabled]="disableSave"
      (click)="saveModel()"
      label="Upload"
    ></button>
  </p-footer>
</p-dialog>
