import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ClientTokenService } from '../../../../services/client-token-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAuthenticationGuard implements OnDestroy, CanActivate {
  private isLoggedIn: boolean;
  private destroy$ = new Subject<void>();

  constructor(private clientTokenService: ClientTokenService) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public canActivate(): boolean {
    this.clientTokenService
      .isLoggedIn()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });

    return this.isLoggedIn;
  }
}
