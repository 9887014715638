export enum LegFormFields {
  Advances = 'advances',
  AdvancesTotal = 'advancesTotal',
  DriverAcceptsSms = 'driverAcceptsSms',
  DriverPhone = 'driverPhone',
  DriverUser = 'driverUser',
  EmptyMiles = 'emptyMiles',
  Journal = 'journal',
  LoadLegId = 'loadLegId',
  LoadedMiles = 'loadedMiles',
  NeedsUpdate = 'needsUpdate',
  OtherCharges = 'otherCharges',
  OtherChargesTotal = 'otherChargesTotal',
  OverrideLoadedMiles = 'overrideLoadedMiles',
  PayStatus = 'payStatus',
  Stops = 'stops',
  TrailerNumber = 'trailerNumber',
  TruckNumber = 'truckNumber',
}
