<form class="grid flex align-items-center grid-nogutter" [formGroup]="formGroup">
  <div class="ui-inputgroup col-11">
    <span *ngIf="!formGroup.controls.percentage.value" class="col-4 ui-inputgroup-addon">$</span>
    <input
      [ngClass]="formGroup.controls.percentage.value ? 'col-12' : 'col-8'"
      type="text"
      pKeyFilter="num"
      pInputText
      formControlName="amount"
      (blur)="formatAmount()"
    />
  </div>
  <p-toggleButton
    formControlName="percentage"
    class="col-1 pl-1"
    [onLabel]="'%'"
    [offLabel]="'%'"
    (onChange)="formatAmount()"
  ></p-toggleButton>
</form>
