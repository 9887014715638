import { Component, OnDestroy, OnInit, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FactoringCompanyOptionsService } from '../../../../apiclient/v1.1/services';
import { NotificationsService } from '../../../../app/components/notifications/notifications.service';
import { FactoringCompanyOptionsDTO, FactoringRemitToOptionsDTO } from '../../../../apiclient/v1.1/models';
import { DtoFormBase } from '../../../shared/FormBase';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-factoring',
  templateUrl: './factoring-edit.component.html',
  styleUrls: ['./factoring-edit.component.scss'],
})
export class AdminFactoringEditComponent extends DtoFormBase<FactoringRemitToOptionsDTO> implements OnInit, OnDestroy {
  private factoringCompanyOptions = new BehaviorSubject<FactoringCompanyOptionsDTO>(null);
  public factoringCompanyOptions$: Observable<FactoringCompanyOptionsDTO>;
  private factoringRemitToOptions = new BehaviorSubject<FactoringRemitToOptionsDTO>(null);
  public factoringRemitToOptions$: Observable<FactoringRemitToOptionsDTO>;
  editing = false;
  disableSave = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private factoringCompanyOptionsService: FactoringCompanyOptionsService,
    private notify: NotificationsService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {} as FactoringRemitToOptionsDTO;

    this.factoringCompanyOptions$ = this.getFactoringCompanyInfo();
    this.factoringRemitToOptions$ = this.factoringRemitToOptions.asObservable();
  }

  private getFactoringCompanyInfo(): Observable<FactoringCompanyOptionsDTO> {
    return this.factoringCompanyOptionsService.Get(this.clientToken.auth()).pipe(
      take(1),
      tap((data) => {
        this.factoringCompanyOptions.next(data);
        this.factoringRemitToOptions.next(data.remitTo);
        this.initFormFor('FactoringRemitToOptionsDTO');
        this.formGroup.addControl('companyName', new FormControl());
        this.formGroup.addControl('companyAddress', new FormControl());
        this.formGroup.addControl('companyPhone', new FormControl());
        this.formGroup.addControl('companyEmail', new FormControl());
      }),
      catchError((error) => {
        this.notify.apiError(error);
        return of(undefined);
      })
    );
  }

  private updateFactoringCompanyInfo(): Observable<FactoringRemitToOptionsDTO> {
    return this.factoringCompanyOptionsService
      .Update({ Authorization: this.clientToken.auth(), body: this.factoringRemitToOptions.value })
      .pipe(
        take(1),
        tap((data) => {
          this.factoringRemitToOptions.next(data);
        }),
        catchError((error) => {
          this.notify.apiError(error);
          return of(undefined);
        })
      );
  }

  reset() {
    this.factoringCompanyOptions$ = this.getFactoringCompanyInfo();
    this.factoringRemitToOptions$ = this.factoringRemitToOptions.asObservable();
    this.editing = false;
  }

  save() {
    const subscription = this.updateFactoringCompanyInfo().subscribe();
    this.subscriptions.push(subscription);
    this.factoringCompanyOptions$ = this.getFactoringCompanyInfo();
    this.editing = false;
  }

  onStateChange($event: any) {
    setTimeout((_) => {
      this.setZipValidator($event.country);
      this.model.remitToState = $event && $event.abbr ? $event.abbr : null;
    });
  }

  setZipValidator(country: String) {
    this.formGroup.controls['remitToZip'].clearValidators();
    let zipPattern;
    let zipPatternMessage;
    if (country === 'CA') {
      zipPatternMessage = 'Zip must be in the formats: T2X 1V4 or T2X1V4';
      zipPattern = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
    } else {
      zipPatternMessage = 'Zip must be in the formats: 12345, 12345-1234, or 12345 1234';
      zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    }
    const theValidator = Validators.pattern(zipPattern);

    this.validationMessages['remitToZip'] = {
      required: 'Zip is required.',
      pattern: zipPatternMessage,
    };

    this.formGroup.controls['remitToZip'].setValidators([theValidator, Validators.required]);
    this.formGroup.controls['remitToZip'].updateValueAndValidity();
  }

  getFormattedAddress(): string {
    return `${this.factoringCompanyOptions.value.addressLine1}, ${this.factoringCompanyOptions.value.addressLine2}, ${this.factoringCompanyOptions.value.city}, ${this.factoringCompanyOptions.value.state} ${this.factoringCompanyOptions.value.zip}`;
  }

  getFormattedRemitToAddress(): string {
    return `${this.factoringRemitToOptions.value.remitToName}, ${this.factoringRemitToOptions.value.remitToAddress}, ${this.factoringRemitToOptions.value.remitToCity}, ${this.factoringRemitToOptions.value.remitToState} ${this.factoringRemitToOptions.value.remitToZip}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
