import { Component, OnInit, ViewChild, Input, Injector, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { UserService } from '../../../apiclient/services/user.service';
import { MessageService } from 'primeng/api';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { ClientTokenService } from '../../../services/client-token-service';
import { UserClaims } from 'src/app/shared/models/user-claims';
import { Utils } from '../../../utils/utils';
import { AllUserService, SuperAdminService } from '../../../apiclient/services';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NotificationsService } from '../notifications/notifications.service';
import { ActionParams } from './action-params';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UserDTO } from '../../../apiclient/v1.1/models';
import { LoggingService } from '../../core/logging.service';
import { TableColumn } from 'src/app/shared/models/TableColumn';
import { cloneDeep } from 'lodash';
import { Permission, Role, RoleDisplayName } from 'src/app/data/static-data';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class UserListComponent implements OnInit, OnDestroy {
  get tenantId() {
    return this._tenantId;
  }
  @Input()
  set tenantId(tenantId: number) {
    if (this._tenantId !== tenantId) {
      this._tenantId = tenantId;
      this.getUsers();
    }
  }

  public dialogVisible: boolean;
  public changePUsername: string;
  public myGroup: FormGroup;
  protected fb: FormBuilder;
  public changePPermanent: boolean;
  public loading: boolean;
  public cols: TableColumn[];
  public users: any[];

  private destroy$ = new Subject();
  private _tenantId: number;

  @ViewChild('userEditDialog', { static: true }) userEditDialog: UserEditComponent;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private userService: UserService,
    private allUserService: AllUserService,
    private superAdminService: SuperAdminService,
    private messageService: MessageService,
    private clientToken: ClientTokenService,
    private loggingService: LoggingService,
    private navigationService: NavigationService,
    protected _NS: NotificationsService,
    protected injector: Injector
  ) {
    this.fb = this.injector.get(FormBuilder);
  }

  public ngOnInit(): void {
    if (!this.clientToken.hasPermission(Permission.UserRead)) {
      this.navigationService.navigateToDefaultDashboard();
    }

    this.getUsers();
    this.dialogVisible = false;
    this.changePPermanent = true;
    this.myGroup = this.fb.group({});
    this.myGroup.addControl('username', new FormControl(''));
    this.myGroup.addControl('password', new FormControl(''));
    this.myGroup.addControl('passwordconfirm', new FormControl(''));
    this.myGroup.addControl('changePPermanent', new FormControl(''));

    this.initializeCols();

    this.configureActions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public actionHandler(actionConfig): void {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  }

  public handleUserEdit(data): void {
    if (data.dataChanged) {
      this.getUsers();
    }
  }

  public sortTable(event: any): void {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
      event.format = fieldInfo.format;
    }
    Utils.sortData(event, this.users);
  }

  public closeDialog(): void {
    this.dialogVisible = false;
  }

  public shouldHideAction(actionFunction: string, row: any): boolean {
    switch (actionFunction) {
      case 'toggleEnableUser': {
        return this.shouldHideUserEnableButton(row);
      }
      default:
        return false;
    }
  }

  private shouldHideUserEnableButton(row: any): boolean {
    if (row.userId === this.clientToken.userId()) {
      return true;
    }

    const isHumanResources = this.clientToken.hasRole(Role.LogisticsHumanResources);
    const isAdmin = this.clientToken.hasRole(Role.SuperAdmin) || this.clientToken.hasRole(Role.Admin);
    const onlyHasHRRole = isHumanResources && !isAdmin;

    if (onlyHasHRRole && (row.roles.includes(Role.SuperAdmin) || row.roles.includes(Role.Admin))) {
      return true;
    }

    return false;
  }

  private configureActions(): void {
    const actionsColumn = this.cols.find((col) => col.field === 'pfs_table_actions');

    if (this.clientToken.hasPermission(Permission.UserEdit)) {
      actionsColumn.buttons.push({
        icon: 'fas fa-key',
        class: 'icon-info-green',
        func: 'changePassword',
        tooltip: 'Change Password',
        params: {
          row: 'row',
        },
      });
    }

    if (this.clientToken.hasRole(Role.SuperAdmin)) {
      actionsColumn.buttons.push({
        icon: 'fas fa-sync',
        class: 'icon-info',
        func: 'resetLockedOutUser',
        tooltip: 'Reset Locked Out User',
        params: {
          row: 'row',
        },
      });
    }
  }

  private enableUser(userId): Promise<any> {
    if (this.tenantId) {
      return this.allUserService
        .ApiAllUserEnableByTenantIdByUserIdPost({
          userId: userId,
          tenantId: this.tenantId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    } else {
      return this.userService
        .ApiUserByIdEnablePost({
          id: userId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    }
  }

  private disableUser(userId): Promise<any> {
    if (this.tenantId) {
      return this.allUserService
        .ApiAllUserDisableByTenantIdByUserIdPost({
          userId: userId,
          tenantId: this.tenantId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    } else {
      return this.userService
        .ApiUserByIdDisablePost({
          id: userId,
          Authorization: this.clientToken.auth(),
        })
        .toPromise();
    }
  }

  private toggleEnableUser(params: ActionParams): void {
    const aUser = params.row;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: (aUser.isDisabled ? 'Enable' : 'Disable') + ' User',
      icon: 'fa fa-question-circle',
      accept: async () => {
        try {
          if (aUser.isDisabled) {
            await this.enableUser(aUser.userId);
            aUser.isDisabled = false;
            this.messageService.add({
              severity: 'success',
              summary: 'User Enabled',
              detail: 'Your changes were saved successfully.',
            });
          } else {
            await this.disableUser(aUser.userId);
            aUser.isDisabled = true;
            this.messageService.add({
              severity: 'warning',
              summary: 'User Disabled',
              detail: 'Your changes were saved successfully.',
            });
          }

          this.users = cloneDeep(this.users);

          if (params.callBack) {
            params.callBack();
          }
        } catch (error) {
          this.handleRequestError(error);
        } finally {
          this.loading = false;
        }
      },
      reject: () => {},
    });
  }

  private changePassword(params: ActionParams): void {
    const aUser = params.row;
    this.changePUsername = aUser.email;
    this.dialogVisible = true;
  }

  private resetLockedOutUser(params: ActionParams): void {
    this.superAdminService
      .ApiSuperAdminUserResetLockoutPost({
        email: params.row.email,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        catchError((err) => {
          this._NS.error('Reset Failed', 'Unable to reset locked out user.');
          this.loggingService.logError(err);
          return of(null);
        })
      )
      .subscribe(() => {
        this._NS.success('Success!', 'User has been reset.');
        this.getUsers();
      });
  }

  private openEditModal(params): void {
    this.userEditDialog.init(params.row.userId);
  }

  private getUsers(): void {
    of(this.tenantId)
      .pipe(
        switchMap((tenantId) => {
          this.loading = true;
          return tenantId ? this.getUsersForTenant() : this.getAllUsers();
        }),
        map((users) => this.mapUserRoleDisplayNames(users)),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Invalid Session',
            detail: '',
          });
          this.router.navigate(['/dashboard']);
          this.loggingService.logError(err);
          return of(null);
        }),
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe((users) => (this.users = users));
  }

  private mapUserRoleDisplayNames(users: UserDTO[]): UserDTO[] {
    users.forEach((user) => {
      for (let index = 0; index < user.roles.length; index++) {
        const currentRoleName = user.roles[index];
        const displayRole = RoleDisplayName[currentRoleName] ?? currentRoleName;
        user.roles[index] = displayRole;
      }
    });
    return users;
  }

  private getUsersForTenant(): Observable<UserDTO[]> {
    return this.allUserService.ApiAllUserByTenantIdGet({
      tenantId: this.tenantId,
      Authorization: this.clientToken.auth(),
    });
  }

  private getAllUsers(): Observable<UserDTO[]> {
    return this.userService.ApiUserGet(this.clientToken.auth());
  }

  private initializeCols(): void {
    this.cols = [
      {
        field: 'firstName+lastName',
        header: 'Name',
        format: 'actionCell',
        sortable: true,
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditModal',
          params: {
            row: 'row',
          },
        },
        width: '11.25em',
      },
      {
        field: 'email',
        format: 'mailto',
        header: 'Email',
        sortable: true,
        width: '18.75em',
      },
      {
        field: 'phone',
        header: 'Phone Number',
        format: 'PhoneJoin',
        sortable: false,
        width: '8.125em',
      },
      {
        field: 'roles',
        header: 'Role',
        format: 'ArrayJoin',
        sortable: true,
        width: '12.5em',
      },
      {
        field: 'isDisabled',
        header: 'Status',
        format: 'BooleanDisabledEnabled',
        sortable: true,
        width: '6.25em',
      },
      {
        field: 'lastLogon',
        header: 'Last Access',
        format: 'DateLocale',
        sortable: true,
        width: '12.5em',
      },
      {
        field: 'isLockedOut',
        header: 'Locked Out',
        format: 'LockedOut',
        sortable: true,
        width: '7.5em',
      },
      {
        field: 'pfs_table_actions',
        header: 'Action',
        format: 'Actions',
        buttons: [
          {
            toggle: true,
            icon: ['fas fa-lock', 'fas fa-unlock'],
            iconColor: ['icon-danger', 'icon-info'],
            func: 'toggleEnableUser',
            class: 'icon-info',
            tooltip: 'Enable/Disable User',
            params: {
              row: 'row',
              conditionallyHideAction: (functionName, row) => this.shouldHideAction(functionName, row),
            },
          },
        ],
        width: '12.5em',
      },
    ];
  }

  private handleRequestError(error) {
    this.messageService.add({
      severity: 'error',
      summary: 'Ooops!',
      detail: 'something went wrong!',
    });
  }
}
