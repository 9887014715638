import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketplaceDashboardComponent } from './dashboard/dashboard.component';
import { AppConfig } from 'src/app/config/app.config';

const routes: Routes = [{ path: AppConfig.routes.marketplace, component: MarketplaceDashboardComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MarketplaceRoutingModule {}
