export const environment = {
  production: true,
  buildTime: new Date().getTime(),
  envName: 'demo',
  apiURL: 'https://demo-api.truxiotest.com',
  googleAnalyticsKey: '',
  appInsightsConfig: {
    instrumentationKey: 'b0d1751c-08e8-4017-9dba-9a07ed6158ec',
    logTrace: true,
    logError: true,
    logEvent: true,
    logPageView: true,
  },
  consoleConfig: {
    logTrace: true,
    logError: true,
  },
  factorCloudLoginUrl: 'https://truxio.demo.factorcloud.com/login.php',
};
