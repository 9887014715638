import { Injectable } from '@angular/core';
import { BulkActionItem } from 'src/app/components/bulk-action/bulk-action-item';
import { BulkActionName, LoadStatusName, Role } from 'src/app/data/static-data';
import { ClientTokenService } from '../client-token-service';
import { StatusFilter } from 'src/app/shared/components/status-filter/models/status-filter';

@Injectable({
  providedIn: 'root',
})
export class BulkActionService {
  constructor(private clientToken: ClientTokenService) {}

  public setActions(actions: BulkActionItem[], status: StatusFilter): BulkActionItem[] {
    actions = this.setActionsBasedOnFilter(actions, status);
    actions = this.setActionsBasedOnRole(actions);

    return actions;
  }

  private setActionsBasedOnFilter(actions: BulkActionItem[], status: StatusFilter): BulkActionItem[] {
    const isDeletedStatus = status.value === LoadStatusName.Deleted;
    actions = actions.map((action) => {
      action.value === BulkActionName.UndeleteLoad
        ? (action.disableAction = !isDeletedStatus)
        : (action.disableAction = isDeletedStatus);

      return action;
    });

    return actions;
  }

  private setActionsBasedOnRole(actions: BulkActionItem[]): BulkActionItem[] {
    const canUndelete = this.clientToken.hasPermission('LoadUndelete');

    if (!canUndelete) {
      actions.forEach((action) => {
        if (action.value === BulkActionName.UndeleteLoad) {
          action.disableAction = true;
        }
      });
    }

    return actions;
  }
}
