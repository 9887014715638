import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/config/app.config';

@Component({
  selector: 'app-unable-to-get-user',
  templateUrl: './unable-to-get-user.component.html',
  styleUrls: ['./unable-to-get-user.component.scss'],
})
export class UnableToGetUserComponent {
  constructor(private router: Router) {}

  public routeToSignUp(): void {
    this.router.navigate([AppConfig.routes.login.signUp]);
  }
}
