/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IdNamePairDTO } from '../models/id-name-pair-dto';
@Injectable({
  providedIn: 'root',
})
class EquipmentService extends __BaseService {
  static readonly TypesPath = '/api/Equipment/Types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the equipment type options used by the tenant/carrier
   * NOTE: This currently returns the full list, same as SystemSettings. We will add a tenant manage screen for this in a later sprint.
   * @param Authorization Bearer {token}
   * @return Success
   */
  TypesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Equipment/Types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Returns the equipment type options used by the tenant/carrier
   * NOTE: This currently returns the full list, same as SystemSettings. We will add a tenant manage screen for this in a later sprint.
   * @param Authorization Bearer {token}
   * @return Success
   */
  Types(Authorization: string): __Observable<Array<IdNamePairDTO>> {
    return this.TypesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }
}

module EquipmentService {
}

export { EquipmentService }
