<div class="grid grid-nogutter col-12 p-0" *ngIf="label; else dateTimeSelector">
  <div class="md:col-4 sm:col-12 p-2 font-bold" id="label">{{ label }}</div>
  <div class="md:col-8 sm:col-12 p-1 px-0">
    <ng-container [ngTemplateOutlet]="dateTimeSelector"></ng-container>
  </div>
</div>

<ng-template #dateTimeSelector>
  <div>
    <p-calendar
      styleClass="col-6 p-0 pr-1"
      inputStyleClass="col-12"
      [(ngModel)]="dtDate"
      hourFormat="24"
      [stepMinute]="15"
      showButtonBar="true"
      placeholder="mm/dd/yyyy"
      (onSelect)="updateDate($event)"
      (onInput)="updateByInput()"
      (onClearClick)="clearDate()"
      (keydown)="onTabDateField($event)"
      [disabled]="readonly"
      #calendarDate
    >
    </p-calendar>
    <p-calendar
      [defaultDate]="defaultDate"
      styleClass="col-6 p-0 pl-1"
      inputStyleClass="col-12"
      [(ngModel)]="dtTime"
      [timeOnly]="true"
      showTime="true"
      hourFormat="24"
      [stepMinute]="15"
      showButtonBar="true"
      placeholder="hh:mm"
      todayButtonStyleClass="hide"
      [keepInvalid]="true"
      (onSelect)="updateTime($event)"
      (onInput)="updateTimeByInput($event)"
      (onClearClick)="clearDate()"
      (keydown)="onTabTimeField($event)"
      (onFocus)="onFocus($event)"
      [disabled]="readonly"
      #calendarTime
    >
    </p-calendar>
  </div>
</ng-template>
