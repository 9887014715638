<div [(formGroup)]="formGroup">
  <div class="col-12 p-3 record" [ngClass]="{ tinyPadBottom: field.showExpiresOn && viewMode !== 'display' }">
    <div class="checkbox">
      <p-checkbox
        formControlName="checked"
        [(ngModel)]="checked"
        binary="true"
        [disabled]="viewMode === 'display'"
        (onChange)="onCheckboxChange()"
      ></p-checkbox>
    </div>
    <div class="full-label">
      <div class="calendar" [hidden]="!field.showExpiresOn || viewMode === 'display'">
        <app-form-field
          [(formGroup)]="formGroup"
          type="calendar"
          formControlName="expiresOn"
          [formErrors]="formErrors"
          [(ngModel)]="model.expiresOn"
          labelClass="nolabel"
          controlClass="col-12 p-0 pr-1"
          (dateChanged)="onExpiresChange()"
        >
        </app-form-field>
      </div>
      <div class="label">
        <label>
          {{ showExpiresOn() }}
          {{ field.label }}
        </label>
      </div>
    </div>
  </div>
</div>
