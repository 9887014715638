<p-dialog
  header="Update Tracking"
  [(visible)]="showDialog"
  [closeOnEscape]="false"
  [closable]="false"
  [style]="{ width: '37.5rem' }"
  appendTo="body"
  [modal]="true"
  [focusOnShow]="false"
>
  <div class="layout-content-container" [formGroup]="formGroup">
    <div class="grid grid-nogutter p-2">
      <div class="grid grid-nogutter col-12">
        <div class="grid grid-nogutter col-12 p-1">
          <div class="lg:col-3 sm:col-12 font-bold">Current location</div>
          <div class="grid grid-nogutter lg:col-9 sm:col-12 p-0">
            <div class="grid grid-nogutter col-12 p-0">
              <div class="col-9 p-0">
                <app-form-field
                  [formGroup]="formGroup"
                  type="text"
                  [formErrors]="formErrors"
                  placeholder="City"
                  formControlName="city"
                  [additionalValidation]="validators.city"
                  label=""
                  controlClass="col-12 p-0"
                  errorClass="mt-1"
                >
                </app-form-field>
              </div>
              <div class="col-3 p-0 pl-1">
                <app-form-field
                  [formGroup]="formGroup"
                  type="state-selector"
                  placeholder="State"
                  formControlName="state"
                  [formErrors]="formErrors"
                  (stateChanged)="onStateChange($event)"
                  controlClass="col-12 p-0"
                  controlClassLevel1="p-0"
                  errorClass="mt-1"
                >
                </app-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-nogutter col-12 p-1">
          <app-form-field
            class="col-12 p-0"
            [formGroup]="formGroup"
            label="Tracking"
            type="select"
            placeholder="Select a status"
            [selectOptions]="statusValues"
            [formErrors]="formErrors"
            formControlName="status"
            [additionalValidation]="validators.status"
            labelClass="lg:col-3 sm:col-12"
            controlClass="lg:col-9 sm:col-12"
            errorClass="lg:col-9 lg:col-offset-3 sm:col-12 mt-1"
            (dropdownChanged)="statusChanged()"
          >
          </app-form-field>
        </div>

        <div class="grid grid-nogutter col-12 p-1">
          <app-form-field
            class="col-12 p-0"
            [formGroup]="formGroup"
            label="Notes"
            type="textarea"
            formControlName="notes"
            [formErrors]="formErrors"
            labelClass="lg:col-3 sm:col-12"
            controlClass="lg:col-9 sm:col-12"
          >
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="close()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button type="button" pButton icon="fa fa-check" (click)="save()" label="Save" [disabled]="disabledSave"></button>
  </p-footer>
</p-dialog>
