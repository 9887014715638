import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-operating-hours',
  templateUrl: './operating-hours.component.html',
  styleUrls: ['./operating-hours.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OperatingHoursComponent,
    },
  ],
})
export class OperatingHoursComponent implements ControlValueAccessor {
  public startOperatingHours: Date;
  public endOperatingHours: Date;
  public defaultDate = new Date(1900, 0, 1, 0, 0, 0);
  private readonly locale = 'en-US';
  private onChange: (operatingHours: string) => {};

  public writeValue(operatingHours: string): void {
    this.setOperatingHours(operatingHours);
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}

  public updateOperatingHours(): void {
    const operatingHours =
      this.startOperatingHours || this.endOperatingHours
        ? `${this.formatTime(this.startOperatingHours)}-${this.formatTime(this.endOperatingHours)}`
        : null;
    this.onChange(operatingHours);
  }

  private getOperatingHours(operatingHours: string): [Date, Date] {
    const formattedDate = this.defaultDate.toLocaleDateString(this.locale);
    const timeRangeSeparator = '-';
    if (operatingHours && operatingHours.includes(timeRangeSeparator)) {
      const times = operatingHours
        .split(timeRangeSeparator)
        .map((t) => t.trim())
        .map((t) => (t ? new Date(`${formattedDate} ${t}`) : null));

      return [times[0], times[1]];
    }

    return [null, null];
  }

  private setOperatingHours(operatingHours: string): void {
    [this.startOperatingHours, this.endOperatingHours] = this.getOperatingHours(operatingHours);
  }

  private formatTime(time: Date): string {
    return time ? time.toLocaleTimeString(this.locale, { hour: 'numeric', minute: 'numeric' }) : '';
  }
}
