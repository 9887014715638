import { Injectable } from '@angular/core';
import { CompanyAddressDTO, CompanyDTO } from '../apiclient/v1.1/models';
import { CompanyService } from '../apiclient/v1.1/services';
import { ClientTokenService } from './client-token-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BusinessAssociateMergeService {
  constructor(private companyService: CompanyService, private clientToken: ClientTokenService) {}

  public mergeBusinessAssociates(parentAssociateId: number, childAssociate: CompanyDTO): Observable<CompanyDTO> {
    return this.getBusinessAssociate(parentAssociateId).pipe(
      map((parentAssociate) => this.mergeAssociates(parentAssociate, childAssociate))
    );
  }

  public mergeBusinessAssociateLocations(
    parentLocation: CompanyAddressDTO,
    childLocation: CompanyAddressDTO
  ): CompanyAddressDTO {
    if (!parentLocation.isShipper && childLocation.isShipper) {
      parentLocation.isShipper = true;
      parentLocation.shipperAppointmentRequired = childLocation.shipperAppointmentRequired;
      parentLocation.shipperNotesInternal = childLocation.shipperNotesInternal;
      parentLocation.shipperNotesReports = childLocation.shipperNotesReports;
      parentLocation.shipperOperatingHours = childLocation.shipperOperatingHours;
      parentLocation.shipperContact = childLocation.shipperContact;
    }

    if (!parentLocation.isConsignee && childLocation.isConsignee) {
      parentLocation.isConsignee = true;
      parentLocation.consigneeAppointmentRequired = childLocation.consigneeAppointmentRequired;
      parentLocation.consigneeNotesInternal = childLocation.consigneeNotesInternal;
      parentLocation.consigneeNotesReports = childLocation.consigneeNotesReports;
      parentLocation.consigneeOperatingHours = childLocation.consigneeOperatingHours;
      parentLocation.consigneeContact = childLocation.consigneeContact;
    }

    return parentLocation;
  }

  private getBusinessAssociate(id: number): Observable<CompanyDTO> {
    return this.companyService.GetCompanyById({
      Authorization: this.clientToken.auth(),
      id: id,
    });
  }

  private mergeAssociates(parentAssociate: CompanyDTO, childAssociate: CompanyDTO): CompanyDTO {
    childAssociate.addresses.forEach((address) => {
      address.companyId = parentAssociate.companyId;
      parentAssociate.addresses.push(address);
    });

    return parentAssociate;
  }
}
