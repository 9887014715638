<app-accounting-contact-address
  id="accountsPayable"
  label="Accounts Payable"
  [contactAddress]="accountsPayable"
  (save)="saveAccountsPayable($event)"
></app-accounting-contact-address>
<app-accounting-contact-address
  id="accountsReceivable"
  label="Remit To"
  [contactAddress]="accountsReceivable"
  (save)="saveAccountsReceivable($event)"
></app-accounting-contact-address>
