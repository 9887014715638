import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap, finalize, takeUntil, switchMap, map } from 'rxjs/operators';
import { TempUser, UserDTO } from 'src/apiclient/v1.1/models';
import { TempUserService, UserService } from 'src/apiclient/v1.1/services';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { ClientTokenService } from 'src/services/client-token-service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  public gettingUser: boolean = false;
  public unableToGetUser: boolean = false;
  public tempUser: TempUser;

  private destroy$ = new Subject();

  constructor(
    private meta: Meta,
    private tempUserService: TempUserService,
    private route: ActivatedRoute,
    private clientToken: ClientTokenService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  public ngOnInit(): void {
    this.breadcrumbService.setItems('new-tenant');
    this.tryGetTempUser();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private tryGetTempUser(): void {
    this.gettingUser = true;

    of(this.clientToken.auth())
      .pipe(
        switchMap((isAuthorized) => (isAuthorized ? this.getExistingUser() : this.getTempUser())),
        tap((tempUser) => (this.tempUser = tempUser)),
        takeUntil(this.destroy$),
        catchError(() => {
          this.unableToGetUser = true;
          return of(undefined);
        }),
        finalize(() => (this.gettingUser = false))
      )
      .subscribe();
  }

  private getTempUser(): Observable<TempUser> {
    const confirmationCode = this.route.snapshot.queryParamMap.get('confirmationCode');

    return this.tempUserService.GetByConfirmationCode(confirmationCode).pipe(tap((user) => user));
  }

  private getExistingUser(): Observable<TempUser> {
    const claims = this.clientToken.getClaims();
    const userId = claims.userId;

    return this.userService
      .GetUserById({
        id: userId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        map<UserDTO, TempUser>((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone.number,
            email: user.email,
          };
        })
      );
  }
}
