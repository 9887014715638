<div *ngIf="isloggedIn" class="layout-breadcrumb">
  <ul>
    <li>
      <a [routerLink]="dashboardRoute">Dashboard</a>
    </li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <li>
        <a [routerLink]="item.routerLink" [queryParams]="{ type: item.queryParams.type }" *ngIf="item.routerLink">{{
          item.label
        }}</a>
        <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>
</div>
