export enum RegistrationFields {
  AcceptEula = 'acceptEula',
  CompanyLegalName = 'legalName',
  Email = 'email',
  Phone = 'phone',
  PhoneExtension = 'phoneExt',
  ExistingEmail = 'emailExists',
  FirstName = 'firstName',
  LastName = 'lastName',
  MotorCarrierNumber = 'mcNumber',
  UsDotNumber = 'usDOTNumber',
  NumberOfTrucks = 'numberOfTrucks',
  Password = 'password',
  MailingAddress = 'mailingAddress',
  SameAsMailing = 'sameAsMailing',
  PhysicalAddress = 'physicalAddress',
}
