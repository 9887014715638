import { Injectable, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CompanyAddressService, UserService } from 'src/apiclient/v1.1/services';
import { Role } from 'src/app/data/static-data';
import { ClientTokenService } from 'src/services/client-token-service';

@Injectable()
export class SubcategoryService implements OnDestroy {
  private readonly driversStream$ = new BehaviorSubject<SelectItem[]>(undefined);
  private readonly dispatchersStream$ = new BehaviorSubject<SelectItem[]>(undefined);
  private readonly destroy$ = new Subject<void>();

  constructor(
    private clientTokenService: ClientTokenService,
    private userService: UserService,
    private companyAddressService: CompanyAddressService
  ) {
    this.saveDrivers();
    this.saveDispatchers();
  }

  public getDrivers(filterText: string): Observable<SelectItem[]> {
    return this.driversStream$.pipe(map((drivers) => this.filterItems(drivers, filterText)));
  }

  public getBillToCustomers(filterText: string): Observable<SelectItem[]> {
    const name = this.getName(filterText);
    return this.companyAddressService
      .SearchBillToV2({
        Authorization: this.clientTokenService.auth(),
        Name: name,
      })
      .pipe(
        map((addresses) =>
          addresses.map<SelectItem>((a) => ({ label: a.companyDisplayName, value: a.companyAddressId }))
        )
      );
  }

  public getShippers(filterText: string): Observable<SelectItem[]> {
    return this.getAddresses(filterText, 'Shipper');
  }

  public getConsignees(filterText: string): Observable<SelectItem[]> {
    return this.getAddresses(filterText, 'Consignee');
  }

  public getDispatchers(filterText: string): Observable<SelectItem[]> {
    return this.dispatchersStream$.pipe(map((dispatchers) => this.filterItems(dispatchers, filterText)));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private filterItems(items: SelectItem[], filterText: string): SelectItem[] {
    if (!filterText) {
      return items;
    }
    return items.filter((i) => i.label.toLowerCase().includes(filterText.toLowerCase()));
  }

  private saveDrivers(): void {
    this.getUsers(Role.Driver)
      .pipe(
        tap((drivers) => this.driversStream$.next(drivers)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private saveDispatchers(): void {
    this.getUsers(Role.Dispatch)
      .pipe(
        tap((dispatchers) => this.dispatchersStream$.next(dispatchers)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getUsers(role: Role): Observable<SelectItem[]> {
    return this.userService
      .Names({
        Authorization: this.clientTokenService.auth(),
        IncludeRoles: [role],
      })
      .pipe(map((users) => users.map<SelectItem>((u) => ({ label: u.name, value: u.id }))));
  }

  private getName(filterText: string): string {
    return !!filterText ? filterText : null;
  }

  private getAddresses(
    filterText: string,
    addressType: CompanyAddressService.GetV2Params['AddressType']
  ): Observable<SelectItem[]> {
    const name = this.getName(filterText);
    return this.companyAddressService
      .GetV2({
        Authorization: this.clientTokenService.auth(),
        Name: name,
        AddressType: addressType,
      })
      .pipe(map((addresses) => addresses.map<SelectItem>((a) => ({ label: a.name, value: a.id }))));
  }
}
