import { Component, Input } from '@angular/core';
import { FundingStatus } from './funding-status';
import { FundingStatusIconColors } from './funding-status-icon-colors';

@Component({
  selector: 'app-funding-status-icon',
  templateUrl: './funding-status-icon.component.html',
  styleUrls: ['./funding-status-icon.component.scss'],
})
export class FundingStatusIconComponent {
  @Input() status: string;
  @Input() color: string;

  private iconPaths = {
    [FundingStatusIconColors.Green]: '../../../../assets/images/factoring/buy.png',
    [FundingStatusIconColors.GreenOutline]: '../../../../assets/images/factoring/money-bag-green-outline.png',
    [FundingStatusIconColors.Red]: '../../../../assets/images/factoring/money-bag-red.png',
    [FundingStatusIconColors.Blue]: '../../../../assets/images/factoring/money-bag-blue.png',
    [FundingStatusIconColors.DarkGrey]: '../../../../assets/images/factoring/money-bag-dark-grey.png',
    [FundingStatusIconColors.DarkGreyOutline]: '../../../../assets/images/factoring/money-bag-dark-grey-outline.png',
    [FundingStatusIconColors.LightGrey]: '../../../../assets/images/factoring/money-bag-light-grey.png',
    [FundingStatusIconColors.LightGreyOutline]: '../../../../assets/images/factoring/money-bag-light-grey-outline.png',
    [FundingStatusIconColors.Black]: '../../../../assets/images/factoring/money-bag-black.png',
  };

  public getIconPathForStatus(): string {
    if (this.status) {
      return this.getIconForStatus();
    }
    if (this.color) {
      return this.getIconForColor();
    }

    return this.iconPaths[FundingStatusIconColors.LightGreyOutline];
  }

  private getIconForStatus() {
    switch (this.status) {
      case FundingStatus.Queued:
        return this.iconPaths[FundingStatusIconColors.LightGreyOutline];

      case FundingStatus.Submitted:
        return this.iconPaths[FundingStatusIconColors.LightGrey];

      case FundingStatus.Funded:
        return this.iconPaths[FundingStatusIconColors.Green];

      case FundingStatus.OnHold:
        return this.iconPaths[FundingStatusIconColors.Red];

      case FundingStatus.Canceled:
        return this.iconPaths[FundingStatusIconColors.Black];

      case FundingStatus.NonFunded:
        return this.iconPaths[FundingStatusIconColors.Blue];

      default:
        return this.iconPaths[FundingStatusIconColors.LightGreyOutline];
    }
  }

  private getIconForColor() {
    return this.iconPaths[this.color]
      ? this.iconPaths[this.color]
      : this.iconPaths[FundingStatusIconColors.LightGreyOutline];
  }
}
