import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistrationFields } from '../../registration-fields';
import { WizardFormBase } from '../wizard-form-base';
import { AddressV3Validator } from 'src/app/components/address-v3/validator/address-v3.validator';

@Component({
  selector: 'app-mailing-address-form',
  templateUrl: './mailing-address-form.component.html',
  styleUrls: ['./mailing-address-form.component.scss', '../../user-registration-wizard.component.scss'],
})
export class AddressInformationFormComponent extends WizardFormBase implements OnInit {
  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.initFormGroup(
      this.formBuilder.group({
        [RegistrationFields.MailingAddress]: [{}, [Validators.required, AddressV3Validator.required]],
        [RegistrationFields.SameAsMailing]: [true],
      })
    );
  }
}
