import { formatDate } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { SuperAdminService } from 'src/apiclient/v1.1/services';
import { ClientTokenService } from 'src/services/client-token-service';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { FileSaverService } from 'src/services/file-saver/file-saver.service';

@Component({
  selector: 'app-admin-registrations',
  templateUrl: './admin-registrations.component.html',
  styleUrls: ['./admin-registrations.component.scss'],
})
export class AdminRegistrationsComponent implements OnDestroy {
  public incompleteRegistrationForm: FormGroup;
  public loadingResults = false;

  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private superAdminService: SuperAdminService,
    private clientToken: ClientTokenService,
    private fileSaverService: FileSaverService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.incompleteRegistrationForm = formBuilder.group({
      startDate: [],
      endDate: [],
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getResults(): void {
    this.loadingResults = true;
    const formValue = this.incompleteRegistrationForm.getRawValue();

    this.superAdminService
      .GetIncompleteTempUserRegistrations({
        Authorization: this.clientToken.auth(),
        startDate: formValue.startDate?.toDateString(),
        endDate: formValue.endDate?.toDateString(),
      })
      .pipe(
        tap((file) => this.fileSaverService.saveAs(file, this.getFileName(formValue.startDate, formValue.endDate))),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.loadingResults = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getFileName(startDate: Date, endDate: Date): string {
    const dates = [];

    if (startDate) {
      dates.push(`from-${this.getFormattedDate(startDate)}`);
    }

    if (endDate) {
      dates.push(`to-${this.getFormattedDate(endDate)}`);
    }

    if (!startDate && !endDate) {
      dates.push(`to-${this.getFormattedDate(Date.now())}`);
    }

    const dateRange = dates.join('-');

    return `incomplete-registrations-${dateRange}.csv`;
  }

  private getFormattedDate(date: Date | number): string {
    return formatDate(date, 'MM/dd/yy', 'en-US');
  }
}
