import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LoadCategory, RateType, StaticData } from 'src/app/data/static-data';

@Component({
  selector: 'app-rate-category',
  templateUrl: './rate-category.component.html',
  styleUrls: ['./rate-category.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RateCategoryComponent),
      multi: true,
    },
  ],
})
export class RateCategoryComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public formGroup: FormGroup;
  public readonly controlName: string = 'rateCategory';
  public loadCategories: LoadCategory[] = StaticData.loadCategories;

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [this.controlName]: [],
    });

    this.formGroup.controls[this.controlName].valueChanges
      .pipe(
        tap((value) => this.onChange(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: RateType): void {
    this.formGroup.controls[this.controlName].setValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private onChange = (value: RateType | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
