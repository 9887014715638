import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PercentageFlatRate } from './percentage-flat-rate';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';

@Component({
  selector: 'app-percentage-flat-rate',
  templateUrl: './percentage-flat-rate.component.html',
  styleUrls: ['./percentage-flat-rate.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PercentageFlatRateComponent),
      multi: true,
    },
  ],
})
export class PercentageFlatRateComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() set isRequired(required: boolean) {
    required
      ? this.formGroup.controls.amount.setValidators([Validators.required])
      : this.formGroup.controls.amount.clearValidators();
  }

  @Input() set touched(value: boolean) {
    if (value) {
      this.formGroup.markAsTouched();
    }
  }
  @Input() set dirty(value: boolean) {
    if (value) {
      this.formGroup.markAsDirty();
    }
  }

  public formGroup: FormGroup;

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private formValidationService: FormValidationService) {
    this.formGroup = this.formBuilder.group({
      percentage: [true, [Validators.required]],
      amount: [0, []],
    });
  }

  public ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(() => this.formGroup.controls.getRawValue),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(obj: PercentageFlatRate): void {
    if (obj?.percentage !== null && obj?.percentage !== undefined) {
      this.formGroup.controls.percentage.setValue(obj.percentage);
    }
    if (obj?.amount) {
      this.formGroup.controls.amount.setValue(obj.amount);
    }

    this.formatAmount();
  }

  public formatAmount(): void {
    const value = this.formGroup.controls.amount.value;

    if (value) {
      const numericValue = Math.abs(value);

      const formattedValue = this.formGroup.controls.percentage.value
        ? (+numericValue.toFixed(1)).toString()
        : numericValue.toFixed(2);

      this.formGroup.controls.amount.setValue(formattedValue);
    }
  }

  private onChange = (value: PercentageFlatRate | null): void => undefined;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  public validateForm(): boolean {
    return this.formValidationService.validateForm(this.formGroup);
  }
}
