<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<p-card>
  <div class="grid p-2">
    <div class="col-12 header">
      <h1 class="p-2">Search for carriers</h1>
      <p-button
        (onClick)="export()"
        label="Export to Excel"
        icon="fa fa-download"
        iconPos="right"
        class="export-button ui-button-secondary p-2"
      ></p-button>
    </div>
    <div class="col-12 p-3 pb-0">
      <form class="grid" [formGroup]="formGroup">
        <div class="col-2">
          <div class="grid">
            <div class="col-12">
              <div class="label-black">Equipment Type</div>
            </div>
            <div class="col-12">
              <app-equipment-type-multiselect
                [formGroup]="formGroup"
                [(ngModel)]="model.equipmentId"
                formControlName="equipmentId"
              >
              </app-equipment-type-multiselect>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-12">
              <div class="label-black">Dates</div>
            </div>
            <div class="col-3 lg:col-4">
              <p-calendar
                [formGroup]="formGroup"
                styleClass="w-full"
                [(ngModel)]="filterHelper.startDate"
                formControlName="startDate"
                class="dateRangeCalendar"
                showButtonBar="true"
                showIcon="true"
                placeholder="Start"
                (onClearClick)="filterDateRangeChange()"
                (onInput)="filterDateRangeChange()"
                (onSelect)="filterDateRangeChange()"
              >
              </p-calendar>
            </div>
            <div class="col-3 lg:col-4">
              <p-calendar
                [formGroup]="formGroup"
                styleClass="w-full"
                [(ngModel)]="filterHelper.endDate"
                class="dateRangeCalendar"
                formControlName="startDate"
                showButtonBar="true"
                showIcon="true"
                placeholder="End"
                (onClearClick)="filterDateRangeChange()"
                (onInput)="filterDateRangeChange()"
                (onSelect)="filterDateRangeChange()"
              >
              </p-calendar>
            </div>
            <div class="col-6 lg:col-4">
              <p-dropdown
                styleClass="w-full"
                [options]="dateCriteria"
                [(ngModel)]="filterHelper.dateCriteria"
                formControlName="dateCriteria"
                placeholder="Delivery Date"
                (onChange)="filterDateRangeChange()"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="grid">
            <div class="col-12">
              <div class="label-black">Location</div>
            </div>
            <div class="col-12">
              <input class="col-12" type="text" pInputText [(ngModel)]="model.location" formControlName="location" />
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="grid">
            <div class="col-12">
              <div class="label-black">Radius (miles)</div>
            </div>
            <div class="col-12">
              <div class="grid">
                <div class="col-3">
                  <input
                    class="w-full"
                    type="text"
                    pInputText
                    pKeyFilter="int"
                    [(ngModel)]="model.locationRadiusMiles"
                    formControlName="locationRadiusMiles"
                  />
                </div>
                <div class="col-8 col-offset-1">
                  <button
                    type="button"
                    pButton
                    (click)="search()"
                    label="Search"
                    class="ui-button-primary w-full"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="col-12">
      <div class="grid">
        <div class="col-7" [hidden]="carriers.length === 0">
          <p-table
            [columns]="cols"
            [value]="carriers"
            [scrollable]="true"
            scrollHeight="70vh"
            (onSort)="sortTable($event)"
            [customSort]="true"
            [resizableColumns]="true"
          >
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width" />
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : null" pResizableColumn>
                  {{ col.header }}
                  <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr pfs-table-body [columns]="columns" [row]="rowData" [isResizable]="true"></tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-5 marketplace-inner" [hidden]="carriers.length === 0">
          <p-gmap
            (onMapReady)="setMap($event)"
            [options]="mapOptions"
            [overlays]="overlays"
            (onOverlayClick)="handleOverlayClick($event)"
            [style]="{ height: '100%', width: '100%' }"
          ></p-gmap>
        </div>
      </div>
    </div>
  </div>
</p-card>
