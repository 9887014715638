<div class="grid grid-nogutter col-12 p-0">
  <div class="md:col-4 sm:col-12 p-2 font-bold" id="label">{{ label }}</div>
  <div class="md:col-8 sm:col-12 p-1 px-0">
    <p-radioButton
      id="falseInput"
      class="pr-4"
      [value]="false"
      [(ngModel)]="radioValue"
      [label]="falseLabel"
      [disabled]="disabled"
      (click)="radioClicked()"
    ></p-radioButton>
    <p-radioButton
      id="trueInput"
      [value]="true"
      [(ngModel)]="radioValue"
      [label]="trueLabel"
      [disabled]="disabled"
      (click)="radioClicked()"
    ></p-radioButton>
  </div>
</div>
