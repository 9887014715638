import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from './config/app.config';
import { AppUpdateService } from '../services/app-update-service';
import { Subscription } from 'rxjs';
import { ClientTokenService } from '../services/client-token-service';
import { LoggingService } from './core/logging.service';
import { TenantBillingService } from '../services/tenant-billing.service';
import { MenuService } from 'src/services/menu/menu.service';
import { EnvironmentService } from 'src/services/environment/environment.service';
import { filter } from 'rxjs/operators';

/**
 * Adapted from Apollo v9 `app.component.ts`.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public darkTheme: boolean = false;
  public topbarMenuActive: boolean = false;
  public overlayMenuActive: boolean = false;
  public staticMenuDesktopInactive: boolean = true;
  public staticMenuMobileActive: boolean = false;
  public layoutMenuScroller: HTMLDivElement;
  public menuMode = 'overlay';
  public menuHoverActive: boolean;
  public mainBackground: string = 'default-background';
  public layoutWrapperClass: string = 'layout-wrapper';
  public layoutClass: string = 'layout-content';
  public layoutContainerClass: string = 'layout-content-container main-content-container';
  public showHeader: boolean = true;
  public noFooter: boolean = false;
  public showBreadcrumb: boolean = true;
  public showMenu: boolean = true;
  public topbarMenuTenant: boolean;
  public showNeedsUpdate: boolean = false;
  public showSessionWarning: boolean = false;

  private menuClick: boolean;
  private topbarItemClick: boolean;
  private topbarTenantClick: boolean;
  private needsUpdate: Subscription;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    protected injector: Injector,
    private appUpdateService: AppUpdateService,
    private cdr: ChangeDetectorRef,
    private clientToken: ClientTokenService,
    private loggingService: LoggingService,
    private tenantBillingService: TenantBillingService,
    private menuService: MenuService
  ) {
    this.title.setTitle('Truxio');

    if (!EnvironmentService.isProd()) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.handleRouterEvent(event));

    this.clientToken.timeoutExpired.subscribe((res) => {
      this.showSessionWarningModal();
    });

    this.loggingService.loadAppInsights();
  }

  public ngOnInit(): void {
    this.needsUpdate = this.appUpdateService.needsUpdate.subscribe((value) => {
      this.showNeedsUpdate = value;
      this.cdr.detectChanges();
    });

    this.tenantBillingService.loadBillingStatus();
  }

  public onLayoutClick($event): void {
    if (!$event.target.className.includes('showTenants')) {
      if (!this.topbarItemClick) {
        this.topbarMenuActive = false;
      }

      if (!this.topbarTenantClick) {
        this.topbarMenuTenant = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuService.reset();
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.menuHoverActive = false;
      }

      this.topbarItemClick = false;
      this.topbarTenantClick = false;
      this.menuClick = false;
    }
  }

  public onMenuButtonClick(event): void {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }
    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  public onMenuClick(): void {
    this.menuClick = true;
  }

  public onTopbarMenuButtonClick(event): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  public onTenantMenuButtonClick(event): void {
    this.topbarTenantClick = true;
    this.topbarMenuTenant = !this.topbarMenuTenant;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  public isHorizontal(): boolean {
    return this.menuMode === 'horizontal';
  }

  public isSlim(): boolean {
    return this.menuMode === 'slim';
  }

  public isOverlay(): boolean {
    return this.menuMode === 'overlay';
  }

  public isStatic(): boolean {
    return this.menuMode === 'static';
  }

  public isMobile(): boolean {
    return window.innerWidth < 1025;
  }

  public isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  public isTablet(): boolean {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  public hideOverlayMenu(): void {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  public changeTheme(theme): void {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

    if (theme.indexOf('dark') !== -1) {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  }

  public closeAppUpdateModal(): void {
    this.showNeedsUpdate = false;
  }

  public refreshNowClicked(): void {
    window.location.reload();
    this.closeAppUpdateModal();
  }

  public showSessionWarningModal(): void {
    this.showSessionWarning = true;
  }

  public closeSessionWarningModal(): void {
    this.showSessionWarning = false;
  }

  private handleRouterEvent(event: NavigationEnd) {
    this.mainBackground = 'default-background';
    this.layoutWrapperClass = 'layout-wrapper';
    this.layoutClass = 'layout-content';
    this.layoutContainerClass = 'layout-content-container main-content-container';

    let currentRoute = event.urlAfterRedirects;

    if (currentRoute.includes(AppConfig.routes.userRegistration)) {
      currentRoute = '/' + AppConfig.routes.userRegistration;
    }

    this.showBreadcrumb = true;
    this.showMenu = true;

    switch (currentRoute) {
      case '/':
        this.meta.updateTag({
          name: 'description',
          content: 'Truxio',
        });
        break;
      case '/' + AppConfig.routes.registration:
      case '/' + AppConfig.routes.userRegistration:
        this.title.setTitle('Truxio User Registration');
        this.meta.updateTag({
          name: 'description',
          content: 'Welcome! Please register to use the Truxio application.',
        });
        this.mainBackground = 'truck-background';
        break;
      case '/dashboard':
        this.mainBackground = 'dashboard-background';
        break;
      case '/logistics/dashboard':
        this.mainBackground = 'truck-background';
        break;
      case '/workplace':
        this.layoutWrapperClass = 'layout-wrapper-workplace';
        this.layoutClass = 'layout-content-workplace';
        this.layoutContainerClass = 'layout-content-container-workplace main-content-container-workplace';
        break;
      case '/marketplace':
        this.layoutWrapperClass = 'layout-wrapper-marketplace';
        this.layoutClass = 'layout-content-marketplace';
        this.layoutContainerClass = 'layout-content-container-marketplace main-content-container-marketplace';
        break;
      case '/login':
      case '/' + AppConfig.routes.login.signUp:
        this.mainBackground = 'truck-background';
        this.layoutClass = 'layout-content-login';
        this.layoutContainerClass = 'layout-content-container-login main-content-container-login';
        break;
      case '/' + AppConfig.routes.subscription.required:
        this.mainBackground = 'truck-background';
        break;
      case '/' + AppConfig.routes.subscription.success:
        this.mainBackground = 'truck-background';
        break;
      case '/' + AppConfig.routes.subscription.cancelled:
        this.mainBackground = 'truck-background';
        break;
      default:
        this.mainBackground = 'default-background';
        this.title.setTitle('Truxio');
    }

    this.disableMenuForCertainPages(currentRoute);

    window.scrollTo(0, 0);
  }

  private disableMenuForCertainPages(currentRoute: string): void {
    if (
      currentRoute.includes(`/${AppConfig.routes.login.default}`) ||
      currentRoute === `/${AppConfig.routes.privacyPolicy}` ||
      currentRoute === `/${AppConfig.routes.userRegistration}`
    ) {
      this.showBreadcrumb = false;
      this.showMenu = false;
    }
  }
}
