<p-dialog
  header="Upload Notice Of Assignment"
  [modal]="true"
  [(visible)]="isVisible"
  [closable]="false"
  [style]="{ width: '31.25rem' }"
  appendTo="body"
  [focusOnShow]="false"
>
  <div class="grid grid-nogutter col-12 p-2 content">
    <div class="col-4 font-bold p-1">File </div>
    <div class="col-8 p-1">
      <app-file-upload (fileChanged)="fileSelected($event)" [accept]="accept"> </app-file-upload>
    </div>
    <div class="col-8 col-offset-4 p-1">
      <span class="formerrors">
        <ul>
          <li *ngIf="!isFileSelected">File is required.</li>
          <li *ngIf="isFileSelected && !isFilePdf">Only PDF files are allowed.</li>
          <li *ngIf="isFileSelected && isFileInvalidSize">File cannot exceed 10MB.</li>
        </ul>
      </span>
    </div>
  </div>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      label="Cancel"
      class="ui-button-secondary"
      (click)="cancel()"
    ></button>
    <button type="button" pButton icon="fa fa-upload" label="Upload" (click)="save()" [disabled]="isSaving"></button>
  </p-footer>
</p-dialog>
