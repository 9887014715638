import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ClientTokenService } from '../../../services/client-token-service';
import { AppConfig } from '../../config/app.config';

@Injectable({ providedIn: 'root' })
export class FactoringFeatureGuard implements CanActivate {
  constructor(private clientTokenService: ClientTokenService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return this.clientTokenService.isFactoringEnabledForTenant().pipe(
      take(1),
      tap((isFactoringEnabled) => {
        if (!isFactoringEnabled) {
          this.router.navigate([AppConfig.routes.dashboard]);
        }
      })
    );
  }
}
