<app-user-edit #userEditDialog (userEdited)="handleUserEdit($event)" [tenantId]="tenantId"></app-user-edit>

<div class="layout-content-container table-container">
  <div class="grid grid-nogutter col-12 p-0 table-inner">
    <div class="col-12 p-2">
      <div class="main-actions">
        <p-button
          (onClick)="userEditDialog.init(0)"
          icon="fa fa-fw fa-plus"
          label="New User"
          class="ui-button-secondary"
        ></p-button>
      </div>
    </div>
    <div class="col-12 p-2">
      <p-table
        [columns]="cols"
        [value]="users"
        [lazy]="true"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="70vh"
        (onSort)="sortTable($event)"
        [customSort]="true"
        [resizableColumns]="true"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : null" pResizableColumn>
              {{ col.header }}
              <p-sortIcon *ngIf="col.sortable || false" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr
            pfs-table-body
            [columns]="columns"
            [row]="rowData"
            [isResizable]="true"
            (oneAction)="actionHandler($event)"
          ></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog appendTo="body"></p-confirmDialog>

<app-user-change-password
  *ngIf="dialogVisible"
  [username]="changePUsername"
  (closeDialogEvent)="closeDialog()"
></app-user-change-password>
