import { Component, OnInit, Input, Injector, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { DtoFormBase } from './../../shared/FormBase';
import { LoadService } from './../../../apiclient/v1.1/services/load.service';
import { saveAs } from 'file-saver/FileSaver';
import { DocumentMailComponent } from './mail/mail.component';
import { PfsTableBodyComponent } from '../table/pfs-table-body/pfs-table-body.component';
import { catchError, finalize, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { SendFileDTO } from '../../../apiclient/v1.1/models';
import { TableColumn } from 'src/app/shared/models/TableColumn';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent extends DtoFormBase<null> implements OnInit {
  @Input() loadId: number;
  @Input() disabled: boolean = false;
  @ViewChild('documentMailer', { static: true }) documentMailer: DocumentMailComponent;
  @ViewChildren('pfsDocumentsTable') pfsDocumentsTable: QueryList<PfsTableBodyComponent>;
  loading: boolean;

  bulkAction: string;
  bulkCheckAll = false;
  bulkActions = [
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Delete',
      value: 'delete',
    },
  ];

  cols: TableColumn[] = [
    {
      header: '-',
      format: 'checkbox',
      field: 'isChecked',
      width: '3em',
    },
    {
      header: 'Name',
      field: 'fileName',
      format: 'actionCell',
      action: {
        icon: 'fa-eye',
        class: 'ui-button-danger',
        func: 'getFile',
        params: {
          row: 'row',
        },
      },
      width: '25em',
    },
    {
      header: 'Type',
      field: 'type',
      width: '7.5em',
    },
    {
      header: 'Last Modified',
      field: 'modifiedOn',
      format: 'DateLocale',
      width: '12.5em',
    },
  ];

  documents: any[];

  disableApply: boolean = false;

  constructor(protected injector: Injector, private loadService: LoadService) {
    super(injector);
  }

  ngOnInit() {
    this.documents = [];
    this.getData();
  }

  getData() {
    this.loading = true;
    this.loadService
      .GetAttachments({
        loadId: this.loadId,
        Authorization: this.clientToken.auth(),
      })
      .subscribe(
        (data) => (this.documents = data),
        this.handleBasicError,
        () => {
          this.loading = false;
          this.documents = this.documents.sort((b, a) => {
            return this.getTime(a.modifiedOn) - this.getTime(b.modifiedOn);
          });
        }
      );
  }
  getTime(date?: Date) {
    return date != null && date ? new Date(date).getTime() : 0;
  }
  actionHandler = (actionConfig) => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };

  getFile(attachment) {
    attachment = attachment.row;
    this.loadService
      .DownloadAttachment({
        id: attachment.attachmentId,
        Authorization: this.clientToken.auth(),
      })
      .subscribe((data) => {
        saveAs(data, attachment.fileName);
      }, this.handleBasicError);
  }

  getSelectedItems() {
    return this.documents.filter((item) => item.isChecked === true);
  }

  bulkToggleCheckAll() {
    this.documents.forEach((attachment) => {
      attachment.isChecked = this.bulkCheckAll;
    });
    this.pfsDocumentsTable.forEach((component) => component.updateValues());
  }

  public bulkApply(): void {
    switch (this.bulkAction) {
      case 'email':
        this.documentMailer.init({
          manualAttachments: null,
          manualOnly: false,
          chooseAttachments: true,
          onSend: (model) => this.sendAttachment(model),
          closeDialogOnSend: false,
        });
        break;
      case 'delete':
        this.bulkDelete();
        break;
      default:
        this._NS.info('No action selected', 'Please select a bulk action first.');
        break;
    }
  }

  private bulkDelete(): void {
    const selectedAttachments = this.getSelectedItems();
    if (selectedAttachments.length === 0) {
      return;
    }

    this.disableApply = true;
    combineLatest(selectedAttachments.map((attachment) => this.deleteAttachment(attachment.attachmentId)))
      .pipe(
        tap(() => this.getData()),
        finalize(() => (this.disableApply = false))
      )
      .subscribe();
  }

  private deleteAttachment(attachmentId: number): Observable<void> {
    return this.loadService
      .DeleteAttachment({
        id: attachmentId,
        Authorization: this.clientToken.auth(),
      })
      .pipe(
        catchError((error) => {
          this.handleBasicError(error);
          return of(null);
        })
      );
  }

  private sendAttachment(body: SendFileDTO): Observable<void> {
    return this.loadService
      .SendAttachment({
        Authorization: this.clientToken.auth(),
        body,
      })
      .pipe(tap(() => this._NS.success('Attachment(s) sent.', '')));
  }
}
