<form [formGroup]="formGroup">
  <p-dropdown
    [style]="{ width: '100%' }"
    [options]="dateOptions"
    [formControlName]="filterDateSelectorFormProperties.DateType"
    placeholder="Select"
  ></p-dropdown>

  <app-date-range-selector
    *ngIf="showCustomDateSelector()"
    [formControlName]="filterDateSelectorFormProperties.CustomDateRange"
    class="col-12 pt-1"
  ></app-date-range-selector>
</form>
