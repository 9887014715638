import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SuccessComponent } from './success/success.component';
import { CancelledComponent } from './cancelled/cancelled.component';
import { RequiredComponent } from './required/required.component';
import { PrimeNgModule } from '../../shared/modules/primeng.module';

@NgModule({
  declarations: [SuccessComponent, CancelledComponent, RequiredComponent],
  imports: [CommonModule, PrimeNgModule, SubscriptionRoutingModule],
})
export class SubscriptionModule {}
