<div [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'display'">
    <div class="label-black">
      {{ displayValue() }}
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <p-dropdown
      styleClass="col-12 p-0"
      [options]="equipmentOptions"
      [(ngModel)]="selectedEquipment"
      optionLabel="name"
      (onChange)="onChange($event)"
      (onBlur)="onBlur()"
      (autoDisplayFirst)="(false)"
      placeholder="Select Equipment"
      [disabled]="readonly"
    >
    </p-dropdown></ng-container
></div>
