import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FactoringAccountExecutiveDTO, FactoringClientDTO } from '../../apiclient/v1.1/models';
import { TenantFactoringService } from '../../apiclient/v1.1/services';
import { FactoringAccountExecutive } from '../../app/shared/models/factoring-account-executive';
import { ClientTokenService } from '../client-token-service';

@Injectable({
  providedIn: 'root',
})
export class AccountExecutivesService {
  constructor(private clientToken: ClientTokenService, private tenantFactoringService: TenantFactoringService) {}

  public getAccountExecutives(): Observable<FactoringAccountExecutive[]> {
    return this.tenantFactoringService
      .GetFactoringClient(this.clientToken.auth())
      .pipe(map((client) => this.mapAccountExecutives(client)));
  }

  public getAccountExecutivesByTenantId(tenantId: number): Observable<FactoringAccountExecutive[]> {
    return this.tenantFactoringService
      .GetFactoringClientByTenantId({
        Authorization: this.clientToken.auth(),
        id: tenantId,
      })
      .pipe(map((client) => this.mapAccountExecutives(client)));
  }

  private mapAccountExecutives(client: FactoringClientDTO): FactoringAccountExecutive[] {
    return client.accountExecutives.map<FactoringAccountExecutive>((accountExecutive) => ({
      ...accountExecutive,
      fullName: `${accountExecutive.firstName} ${accountExecutive.lastName}`,
    }));
  }
}
