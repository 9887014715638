<form class="grid grid-nogutter flex flex-wrap align-content-center" [formGroup]="formGroup">
  <div class="col-6 p-0">
    <p-calendar
      [formControlName]="dateRangeFormProperties.StartDate"
      [showIcon]="true"
      [maxDate]="formGroup.controls[dateRangeFormProperties.EndDate]?.value"
      placeholder="Start"
      showButtonBar="true"
    ></p-calendar>
  </div>
  <div class="col-6 p-0">
    <p-calendar
      [formControlName]="dateRangeFormProperties.EndDate"
      [showIcon]="true"
      [minDate]="formGroup.controls[dateRangeFormProperties.StartDate]?.value"
      placeholder="End"
      showButtonBar="true"
    ></p-calendar>
  </div>
</form>
