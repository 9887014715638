<p-dialog
  header="Send Attachment"
  appendTo="body"
  keepInViewport="true"
  [focusOnShow]="false"
  [focusTrap]="false"
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '37.5rem' }"
>
  <div class="p-2">
    <form [formGroup]="formGroup">
      <div class="grid grid-nogutter col-12 p-0">
        <div class="col-4 font-bold"> Recipients </div>
        <div class="col-8 p-0">
          <p-chips
            #focusableInput
            formControlName="recipientsCopy"
            styleClass="recipients"
            [(ngModel)]="recipients"
            (onBlur)="checkRecipients($event)"
            (onAdd)="checkEmail($event)"
            (onRemove)="deleteEmail($event)"
            [addOnTab]="true"
            [addOnBlur]="true"
          ></p-chips>
        </div>
        <span *ngIf="formErrors['recipients']" class="formerrors col-8 col-offset-4 p-1 px-0">{{
          formErrors['recipients']
        }}</span>
      </div>

      <app-form-field
        #focusableInput
        [(formGroup)]="formGroup"
        label="Subject"
        formControlName="subject"
        [(ngModel)]="model.subject"
        [formErrors]="formErrors"
        labelClass="col-4 "
        controlClass="col-8 p-0"
        errorClass="col-8 col-offset-4 p-1"
      >
      </app-form-field>
      <hr class="col-12" />
      <app-form-field
        #focusableInput
        [(formGroup)]="formGroup"
        label="Body"
        type="textarea"
        formControlName="body"
        [(ngModel)]="model.body"
        [formErrors]="formErrors"
        placeholder="Required"
        labelClass="col-4 "
        controlClass="col-8 p-0"
        errorClass="col-8 col-offset-4 p-1"
      >
      </app-form-field>
      <hr class="col-12" />

      <div class="grid grid-nogutter col-12 p-0">
        <div class="col-4 font-bold" *ngIf="!chooseAttachments"> Documents </div>
        <div class="col-8" [formGroup]="formGroup" *ngIf="!chooseAttachments">
          <ul>
            <li *ngFor="let attachment of attachments">{{ attachment.fileName }}</li>
          </ul>
        </div>
      </div>

      <div class="col-12" *ngIf="chooseAttachments">Include additional documents:</div>
      <div class="col-12" *ngIf="errorMessage">
        <span class="formerrors">{{ errorMessage }}</span>
      </div>

      <div class="col-12" *ngIf="chooseAttachments">
        <div class="col-12" *ngFor="let attachment of attachments">
          <p-checkbox
            #focusableInput
            name="attachmentChooser"
            [(formGroup)]="formGroup"
            [value]="attachment.attachmentId"
            label="{{ attachment.fileName }}"
            formControlName="attachmentIds"
            [(ngModel)]="model.attachmentIds"
            inputId="attachment_{{ attachment.attachmentId }}"
            (onChange)="sortAttachmentIDs()"
          >
          </p-checkbox>
        </div>
      </div>
      <hr class="col-12" *ngIf="chooseAttachments" />
      <div class="col-8" *ngIf="chooseAttachments">
        <p-checkbox
          #focusableInput
          name="mergePDFs"
          [(formGroup)]="formGroup"
          binary="true"
          (onChange)="sendAllSelected()"
          label="Send all attachments as a single PDF document."
          formControlName="merge"
          [(ngModel)]="model.merge"
          inputId="attachments_merge"
        >
        </p-checkbox>
      </div>
    </form>
  </div>
  <p-footer>
    <button
      #focusableInput
      type="button"
      pButton
      icon="fa fa-times"
      (click)="cancel()"
      label="Cancel Send"
      class="ui-button-secondary cancel-button"
    ></button>
    <button
      #focusableInput
      type="button"
      pButton
      icon="fa fa-check"
      form="formGroup"
      (click)="sendMail()"
      [disabled]="disableSend || !areAttachmentsSelected()"
      label="Send"
    ></button>
  </p-footer>
</p-dialog>
