<div class="grid grid-nogutter col-12 p-2">
  <div class="col-7">
    <p-dropdown
      [style]="{ width: '100%' }"
      [options]="actionOptions"
      [(ngModel)]="selectedAction"
      placeholder="Actions"
    >
      <ng-template let-action pTemplate="item">
        <div *ngIf="!shouldActionItemBeDisabled(action); else disabledItem">{{ action.label }}</div>
        <ng-template #disabledItem>
          <div [pTooltip]="getDisabledToolTip(action)">{{ action.label }}</div>
        </ng-template>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="col-5 pl-2">
    <button
      id="apply-button"
      class="w-full h-full"
      pButton
      type="button"
      (click)="applyAction()"
      [disabled]="disableApply"
      label="Apply"
    ></button>
  </div>
</div>
