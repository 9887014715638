<p-overlayPanel #overlayPanel id="overlay-panel" appendTo="body" styleClass="filter-overlay">
  <div class="filter-overlay">
    <div class="header">
      <span
        ><strong>{{ headerText }}</strong></span
      >
    </div>
    <hr />
    <div *ngFor="let suggestion of suggestions" class="suggestion" (click)="suggestionAccepted(suggestion)">
      <img class="status-icon" [src]="suggestion.buyStatus ? buyIconPath : noBuyIconPath" />
      <div class="col-12">
        <div
          ><strong
            >{{ suggestion.companyName }} - {{ suggestion.address.city }}, {{ suggestion.address.state }}</strong
          ></div
        >
        <div
          >{{ suggestion.address.line1 }}, {{ suggestion.address.city }}, {{ suggestion.address.state }}
          {{ suggestion.address.zip }}</div
        >
      </div>
    </div>
  </div>
</p-overlayPanel>
