<ng-container *ngIf="accountExecutives$ | async as accountExecutives">
  <p-table
    [columns]="columns"
    [value]="accountExecutives"
    [lazy]="true"
    [loading]="loading"
    [scrollable]="true"
    scrollHeight="9.375rem"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr pfs-table-body [columns]="columns" [row]="rowData"></tr>
    </ng-template>
  </p-table>
</ng-container>
