import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { PasswordRequirements } from './password-requirements';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
  @Input() formGroup: FormGroup;

  public passwordRequirements = PasswordRequirements;

  private destroy$ = new Subject<void>();

  private readonly passwordRegex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`\\\|~\!@#\$%\^\&\*\(\)-_=\+\[\]{}\\|/\.,\?><';:\"]).{8,100}/;

  public ngOnInit(): void {
    this.formGroup.controls['password'].setValidators(Validators.pattern(this.passwordRegex));

    this.formGroup.controls['password'].valueChanges
      .pipe(
        tap(() => this.conditionallySetPasswordsMatch()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.formGroup.controls['confirm-password'].valueChanges
      .pipe(
        tap(() => this.conditionallySetPasswordsMatch()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public getCurrentRequirementStatus(requirement: PasswordRequirements): string {
    const defaultIcon = 'far fa-circle';
    if (this.formGroup.controls['password']?.value?.length === 0) {
      return defaultIcon;
    }

    let validationResult = false;
    switch (requirement) {
      case PasswordRequirements.Length:
        validationResult = this.passwordLengthRequirement();
        break;
      case PasswordRequirements.LowerCase:
        validationResult = this.passwordLowercaseRequirement();
        break;
      case PasswordRequirements.UpperCase:
        validationResult = this.passwordUppercaseRequirement();
        break;
      case PasswordRequirements.Number:
        validationResult = this.passwordNumberRequirement();
        break;
      case PasswordRequirements.SpecialCharacter:
        validationResult = this.passwordSpecialCharacterRequirement();
        break;
      case PasswordRequirements.Match:
        validationResult = this.passwordMatchRequirement();
        break;
    }

    return validationResult ? 'fas fa-check-circle' : defaultIcon;
  }

  public containsWhiteSpace(): boolean {
    const passwordValue = this.formGroup.get('password').value;
    return passwordValue.length !== passwordValue.trim().length;
  }

  private passwordLengthRequirement(): boolean {
    return this.formGroup.controls['password']?.value?.length >= 8;
  }

  private passwordLowercaseRequirement(): boolean {
    const regex = /(?=.*[a-z])/;
    return regex.test(this.formGroup.controls['password']?.value);
  }

  private passwordUppercaseRequirement(): boolean {
    const regex = /(?=.*[A-Z])/;
    return regex.test(this.formGroup.controls['password']?.value);
  }

  private passwordNumberRequirement(): boolean {
    const regex = /(?=.*\d)/;
    return regex.test(this.formGroup.controls['password']?.value);
  }

  private passwordSpecialCharacterRequirement(): boolean {
    const regex = /(?=.*\W)/;
    return regex.test(this.formGroup.controls['password']?.value);
  }

  private passwordMatchRequirement(): boolean {
    const passwordValue = this.formGroup.controls['password']?.value;
    const confirmValue = this.formGroup.controls['confirm-password']?.value;

    if (!passwordValue || !confirmValue) {
      return false;
    }

    const passwordsMatch = passwordValue === confirmValue;
    return passwordsMatch;
  }

  private conditionallySetPasswordsMatch(): void {
    this.passwordMatchRequirement() ? this.setPasswordsMatch(true) : this.setPasswordsMatch(false);
  }

  private setPasswordsMatch(value: boolean): void {
    this.formGroup.controls['passwords-match'].setValue(value);
  }
}
