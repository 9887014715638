<p-dialog
  header="Change Password"
  [visible]="true"
  [style]="{ width: '600px' }"
  [modal]="true"
  appendTo="body"
  [focusOnShow]="false"
  [closable]="false"
>
  <div class="grid grid-nogutter col-12 p-0">
    <form [formGroup]="changePasswordForm">
      <app-form-field
        label="Username"
        type="text"
        labelClass="col-12 p-0 py-2"
        controlClass="col-12 md:col-12 p-0"
        errorClass="col-12 md:col-12"
        [formGroup]="changePasswordForm"
        formControlName="username"
      ></app-form-field>
      <app-password-confirmation [formGroup]="changePasswordForm"></app-password-confirmation>
      <p-checkbox
        class="flex justify-content-center p-2"
        formControlName="permanent"
        binary="true"
        label="Permanently change password"
        pTooltip="When un-checked user will have to change their password on logging in."
        tooltipPosition="bottom"
      ></p-checkbox> </form
  ></div>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      label="Cancel"
      class="ui-button-secondary"
      id="cancel-button"
      (click)="closeDialog()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      (click)="resetPassword()"
      label="Reset Password"
      id="reset-button"
      [disabled]="!(isFormValid$ | async) || resetInProgress"
    ></button>
  </p-footer>
</p-dialog>
