import { Component, forwardRef, EventEmitter, Output, Injector, OnDestroy, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FocusTrapService } from 'src/services/focus-trap.service';
import { MasterDebtor } from 'src/apiclient/v1.1/models';
import { CompanyAddressService, MasterDebtorService } from 'src/apiclient/v1.1/services';
import { Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ClientTokenService } from 'src/services/client-token-service';
import { DisplayBillToCompanyDTO } from 'src/app/shared/interfaces/DisplayBillToCompanyDTO';
import { HandleErrorBase } from 'src/app/shared/HandleErrorBase';
import { CompanyAddressType } from '../company-address-quick-add/company-quick-add-type';
import { CompanyStatus } from 'src/app/data/static-data';

@Component({
  selector: 'app-bill-to-company-address-selector',
  templateUrl: './bill-to-company-address-selector.component.html',
  styleUrls: ['./bill-to-company-address-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BillToCompanyAddressSelectorComponent),
      multi: true,
    },
  ],
})
export class BillToCompanyAddressSelectorComponent extends HandleErrorBase implements ControlValueAccessor, OnDestroy {
  @Input() readonly: boolean = false;
  @Output() changed = new EventEmitter<DisplayBillToCompanyDTO>();

  public selected: DisplayBillToCompanyDTO;
  public results: DisplayBillToCompanyDTO[];
  public searchValue: string;
  public disabled: boolean = false;
  public dialogVisible = false;
  public confirmBillToDialogVisible = false;
  public selectedMasterDebtor: MasterDebtor;
  public selectedCompanyAddressId: number;
  public companyAddressType = CompanyAddressType;

  private buyIconPath = '../../../../assets/images/factoring/buy.png';
  private noBuyIconPath = '../../../../assets/images/factoring/nobuy.png';
  private destroy$ = new Subject();

  constructor(
    private companyAddressService: CompanyAddressService,
    private focusTrap: FocusTrapService,
    private clientTokenService: ClientTokenService,
    private masterDebtorService: MasterDebtorService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(val: DisplayBillToCompanyDTO): void {
    this.selected = val;
    this.results = [val];
  }
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  public onSelect(): void {
    if (this.selected) {
      if (this.selected.companyAddressId === -1) {
        // Add new
        this.selected = null;
        this.openQuickAddDialog();
      } else {
        this.clientTokenService
          .isFactoringEnabledForTenant()
          .pipe(
            take(1),
            tap((isFactoringEnabled) => {
              if (
                isFactoringEnabled &&
                !this.selected.subDebtorId &&
                (this.selected.masterDebtorId || this.selected.companyAddressId)
              ) {
                this.openConfirmBillToDialog();
              } else {
                this.modelChanged();
              }
            })
          )
          .subscribe();
      }
    }
  }

  public onClear(): void {
    this.selected = null;
    this.modelChanged();
  }

  private modelChanged(): void {
    this.propagateChange(this.selected);
    this.changed.emit(this.selected);
  }

  public search(event): void {
    this.searchValue = event.query;
    const term = event.query === '' ? null : event.query;
    this.searchBillTo(term)
      .pipe(
        take(1),
        tap((data) => {
          // -1 is a control id which triggers the add customer dialog
          data.unshift(<DisplayBillToCompanyDTO>{ companyAddressId: -1, companyName: ' + Add New' });
          this.results = data;
        })
      )
      .subscribe();
  }

  public openQuickAddDialog() {
    this.dialogVisible = true;
    this.focusTrap.onDialog(true);
  }

  public openConfirmBillToDialog() {
    this.selected.masterDebtorId
      ? this.openConfirmBillToDialogForMasterDebtor()
      : this.openConfirmBillToDialogForCompanyAddress();
  }

  public dialogClosed() {
    this.dialogVisible = false;
    this.confirmBillToDialogVisible = false;
    this.selectedCompanyAddressId = null;
    this.focusTrap.onDialog(false);
  }

  public newBillToCreated(company: DisplayBillToCompanyDTO) {
    this.selected = company;
    this.results = [company];
    this.modelChanged();
  }

  private searchBillTo(term: string): Observable<DisplayBillToCompanyDTO[]> {
    return this.clientTokenService.isFactoringEnabledForTenant().pipe(
      take(1),
      mergeMap((isFactoringEnabled) => {
        return this.companyAddressService
          .SearchBillToV2({
            Name: term,
            Status: CompanyStatus.Active,
            IncludeMasterDebtors: isFactoringEnabled,
            Authorization: this.clientTokenService.auth(),
          })
          .pipe(
            take(1),
            map((data: DisplayBillToCompanyDTO[]) => {
              data.forEach(
                (d) => (d.iconPath = isFactoringEnabled ? (d.buyNoBuy ? this.buyIconPath : this.noBuyIconPath) : null)
              );
              return data;
            }),
            catchError((error) => this.handleBasicError(error))
          );
      })
    );
  }

  private openConfirmBillToDialogForMasterDebtor() {
    this.getMasterDebtorRecord(this.selected.masterDebtorId).subscribe((masterDebtor) => {
      this.selected = null;
      this.selectedMasterDebtor = masterDebtor;
      this.confirmBillToDialogVisible = true;
      this.focusTrap.onDialog(true);
    });
  }

  private openConfirmBillToDialogForCompanyAddress() {
    this.selectedCompanyAddressId = this.selected.companyAddressId;
    this.searchValue = this.selected.companyName;
    this.selected = null;
    this.openQuickAddDialog();
  }

  private getMasterDebtorRecord(masterDebtorId: number): Observable<MasterDebtor> {
    return this.masterDebtorService
      .GetById({
        Authorization: this.clientToken.auth(),
        id: masterDebtorId,
      })
      .pipe(takeUntil(this.destroy$));
  }
}
