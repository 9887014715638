import { Injectable, OnDestroy } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { UserFactoringService as ApiUserFactoringService } from '../../apiclient/v1.1/services';
import { Role } from '../../app/data/static-data';
import { ClientTokenService } from '../client-token-service';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoggingService } from '../../app/core/logging.service';

@Injectable({
  providedIn: 'root',
})
export class UserFactoringService implements OnDestroy {
  private destroy$ = new Subject();

  constructor(
    private apiUserFactoringService: ApiUserFactoringService,
    private clientTokenService: ClientTokenService,
    private loggingService: LoggingService
  ) {}

  public createFactorCloudUser(): void {
    this.clientTokenService
      .isFactoringEnabledForTenant()
      .pipe(
        takeUntil(this.destroy$),
        filter((isFactoringEnabled) => isFactoringEnabled),
        switchMap(() =>
          this.apiUserFactoringService
            .CreateFactorCloudUser(this.clientTokenService.auth())
            .pipe(takeUntil(this.destroy$))
        ),
        catchError((error) => {
          this.loggingService.logError(error);
          return of(undefined);
        })
      )
      .subscribe();
  }

  public hasFactorCloudUser(): boolean {
    return (
      this.clientTokenService.getClaims().hasFactorCloudUser &&
      (this.clientTokenService.hasRole(Role.Admin) || this.clientTokenService.hasRole(Role.Accounting))
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
