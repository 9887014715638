/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ContactDTO } from '../models/contact-dto';
@Injectable({
  providedIn: 'root',
})
class ContactService extends __BaseService {
  static readonly ApiContactGetPath = '/api/Contact';
  static readonly ApiContactPostPath = '/api/Contact';
  static readonly ApiContactByIdGetPath = '/api/Contact/{id}';
  static readonly ApiContactByIdPutPath = '/api/Contact/{id}';
  static readonly ApiContactByIdDeletePath = '/api/Contact/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of contacts
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiContactGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<ContactDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContactDTO>>;
      })
    );
  }
  /**
   * Summary list of contacts
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiContactGet(Authorization: string): __Observable<Array<ContactDTO>> {
    return this.ApiContactGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<ContactDTO>)
    );
  }

  /**
   * Create a new Contact
   * @param params The `ContactService.ApiContactPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiContactPostResponse(params: ContactService.ApiContactPostParams): __Observable<__StrictHttpResponse<ContactDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContactDTO>;
      })
    );
  }
  /**
   * Create a new Contact
   * @param params The `ContactService.ApiContactPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiContactPost(params: ContactService.ApiContactPostParams): __Observable<ContactDTO> {
    return this.ApiContactPostResponse(params).pipe(
      __map(_r => _r.body as ContactDTO)
    );
  }

  /**
   * Contact detail
   * @param params The `ContactService.ApiContactByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiContactByIdGetResponse(params: ContactService.ApiContactByIdGetParams): __Observable<__StrictHttpResponse<ContactDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Contact/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContactDTO>;
      })
    );
  }
  /**
   * Contact detail
   * @param params The `ContactService.ApiContactByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiContactByIdGet(params: ContactService.ApiContactByIdGetParams): __Observable<ContactDTO> {
    return this.ApiContactByIdGetResponse(params).pipe(
      __map(_r => _r.body as ContactDTO)
    );
  }

  /**
   * Update an existing contact
   * @param params The `ContactService.ApiContactByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiContactByIdPutResponse(params: ContactService.ApiContactByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Contact/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing contact
   * @param params The `ContactService.ApiContactByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiContactByIdPut(params: ContactService.ApiContactByIdPutParams): __Observable<null> {
    return this.ApiContactByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `ContactService.ApiContactByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiContactByIdDeleteResponse(params: ContactService.ApiContactByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Contact/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `ContactService.ApiContactByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiContactByIdDelete(params: ContactService.ApiContactByIdDeleteParams): __Observable<null> {
    return this.ApiContactByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ContactService {

  /**
   * Parameters for ApiContactPost
   */
  export interface ApiContactPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: ContactDTO;
  }

  /**
   * Parameters for ApiContactByIdGet
   */
  export interface ApiContactByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiContactByIdPut
   */
  export interface ApiContactByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: ContactDTO;
  }

  /**
   * Parameters for ApiContactByIdDelete
   */
  export interface ApiContactByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { ContactService }
