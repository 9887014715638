import {
  Component,
  OnInit,
  forwardRef,
  EventEmitter,
  Input,
  Output,
  Injector,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StatesService, State } from '../../../services/states-service';
import { HandleErrorBase } from '../../shared/HandleErrorBase';
import { AutoComplete } from 'primeng/autocomplete';

/**
 * Note: This is using ChangeDetectionStrategy.OnPush because change is propagated via event instead of model.
 */
@Component({
  selector: 'app-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateSelectorComponent),
      multi: true,
    },
  ],
})
export class StateSelectorComponent extends HandleErrorBase implements OnInit, ControlValueAccessor {
  @ViewChild(AutoComplete, { static: true }) autoCompleteComponent: AutoComplete;
  selected: State = <State>{};
  options: State[] = null;
  filteredStatesSingle: State[] = [];

  /**
   * This property is bound to the ngModel (state abbreviation)
   */
  model: string;

  @Input() styleClass: string;
  @Input() readonly: boolean = false;
  @Input() label: string;
  @Input() placeholder: string = 'State';
  @Input() labelClass: string;

  /**
   * When model changes
   */
  @Output() stateChanged: EventEmitter<State> = new EventEmitter();

  disabled: boolean = false;

  constructor(private states: StatesService, protected injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.options = await this.getOptions();
  }

  async writeValue(val: string): Promise<void> {
    this.model = val;
    if (this.model === undefined || this.model == null || this.model === '') {
      this.selected = {
        name: '',
        abbr: '',
        country: '',
      } as State;
    } else {
      if (this.options === undefined || this.options === null) {
        this.options = await this.getOptions();
      }

      const item = this.options.filter((x) => x.abbr === this.model);
      if (item.length === 0) {
        throw new Error(
          `Could not initialize app-state-selector with state: ${this.model}. State was not returned from API.`
        );
      } else if (this.selected == null || this.selected.abbr !== item[0].abbr) {
        this.selected = item[0];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  public onChange() {
    if (this.selected !== undefined && this.selected !== null) {
      if (this.model !== this.selected.abbr) {
        this.model = this.selected.abbr;
        this.modelChanged();
      }
    } else {
      if (this.model !== '') {
        this.model = '';
        this.modelChanged();
      }
    }
  }

  private modelChanged() {
    this.propagateChange(this.model);
    this.stateChanged.emit(this.selected);
  }

  private async getOptions(): Promise<State[]> {
    try {
      return await this.states.getStates();
    } catch (error) {
      this.handleBasicError(error);
    }
  }
  filterStateSingle(event) {
    const query = event.query;
    this.states.getStates().then((states) => {
      this.options = this.filterState(query, states);
    });
  }
  filterState(query, states: State[]): State[] {
    const filtered: State[] = [];
    for (let i = 0; i < states.length; i++) {
      const state = states[i];
      if (
        state.name.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
        state.abbr.toLowerCase().indexOf(query.toLowerCase()) === 0
      ) {
        filtered.push(state);
      }
    }
    return filtered;
  }
  onStateChange(event: any) {
    if (event) {
      this.selected = event;
      if (this.selected !== undefined && this.selected !== null) {
        if (this.model !== this.selected.abbr) {
          this.model = this.selected.abbr;
          this.modelChanged();
        }
      } else {
        if (this.model !== '') {
          this.model = '';
          this.modelChanged();
        }
      }
    }
  }
  stateCheck(event: any) {
    if (event.relatedTarget !== null) {
      if (this.options.length > 0) {
        let stateFound = false;

        // 2 char (abbr) match check first
        if (event.target.value.length === 2) {
          for (const stateKey of Object.keys(this.options)) {
            const state = this.options[stateKey];
            if (state.abbr === event.target.value.toUpperCase()) {
              this.autoCompleteComponent.selectItem(this.options[stateKey], false);
              stateFound = true;
            }
          }
        }

        // 2 char match not found, or <> 2 char entered, now try match on name
        if (!stateFound) {
          for (const stateKey of Object.keys(this.options)) {
            const state = this.options[stateKey];
            // tslint:disable-next-line:max-line-length
            if (
              (event.target.value.length > 0 &&
                state.name.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1) ||
              state.name.toUpperCase() === event.target.value.toUpperCase()
            ) {
              this.autoCompleteComponent.selectItem(this.options[stateKey], false);
              stateFound = true;
              break;
            }
          }
        }

        if (!stateFound) {
          this.autoCompleteComponent.selectItem(null, false);
          event.target.value = '';
        }
      } else {
        this.autoCompleteComponent.selectItem(null, false);
        event.target.value = '';
      }
    }
  }
}
