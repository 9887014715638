import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { ClientTokenService } from '../../../../services/client-token-service';
import { LoadService } from '../../../../apiclient/services/load.service';
import { DriverPayService } from '../../../../apiclient/services/driver-pay.service';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { SettlementComponent } from '../driverpay/settlement/settlement.component';
import { PdfUtilityComponent } from '../../../components/pdf-utility/pdf-utility.component';
import { NotificationsService } from '../../../components/notifications/notifications.service';
import { Utils } from '../../../../utils/utils';
import { LoadStatusName } from '../../../data/static-data';
import { IdNamePairDTO } from '../../../../apiclient/models';
import { PfsTableBodyComponent } from '../../../components/table/pfs-table-body/pfs-table-body.component';
import { TableColumn } from 'src/app/shared/models/TableColumn';

@Component({
  selector: 'app-driver-pay',
  templateUrl: './driverpay.component.html',
  styleUrls: ['./driverpay.component.scss'],
})
export class DriverPayComponent implements OnInit {
  loading: boolean;

  cols: TableColumn[];

  loads: any[] = [];
  unPaidStatuses = ['Alert', 'Eligible'];
  loadsOriginal: any[];
  statusNotEquals: any[] = [
    LoadStatusName.Open,
    LoadStatusName.Assigned,
    LoadStatusName.InYard,
    LoadStatusName.Dispatched,
    LoadStatusName.InTransit,
    LoadStatusName.Archive,
  ];
  rowsSelected: boolean = false;
  actionSelected: any = '';
  payStatuses = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Paid',
      value: 'paid',
    },
    {
      label: 'Unpaid',
      value: 'unpaid',
    },
  ];
  dateCriteria = [
    {
      label: 'Ship Date',
      value: 'shipping',
    },
    {
      label: 'Delivery Date',
      value: 'delivery',
    },
    {
      label: 'Invoice Date',
      value: 'invoice',
    },
  ];

  bulkCheckAll: boolean = false;

  filter: any = {
    driverUser: {} as IdNamePairDTO,
    payStatus: 'false',
    startDate: null,
    endDate: null,
    dateCriteria: 'delivery',
  };
  public showLoadDialog = false;
  public selectedLoadId: number;

  @ViewChild('settlementDialog', { static: true }) settlementDialog: SettlementComponent;
  @ViewChild('pdfutil', { static: true }) pdfUtil: PdfUtilityComponent;
  @ViewChildren('pfsPaymentTable') pfsPaymentTable: QueryList<PfsTableBodyComponent>;

  constructor(
    private router: Router,
    private loadService: LoadService,
    private driverPayService: DriverPayService,
    private clientToken: ClientTokenService,
    private breadcrumbService: BreadcrumbService,
    private _NS: NotificationsService
  ) {}

  ngOnInit() {
    const allowedRoles = ['SuperAdmin', 'Admin', 'Accounting'];
    const allowedPermissions = ['LoadAccounting'];
    if (
      !allowedPermissions.some((permission) => this.clientToken.permissions().includes(permission)) ||
      !allowedRoles.some((roles) => this.clientToken.roles().includes(roles))
    ) {
      this.router.navigate(['/']);
    }
    this.breadcrumbService.setItems('driverpay');
    // this.getData();
    this.cols = [
      {
        header: '-',
        format: 'conditionalCheckbox',
        conditions: {
          field: 'payStatus',
          values: ['Paid', 'PaidWithAlert'],
        },
        field: 'isChecked',
        width: '3em',
        action: {
          func: 'onRowSelect',
          params: {
            row: 'row',
          },
        },
      },
      {
        header: 'Load',
        field: 'loadNumber',
        sortable: true,
        format: 'actionCell',
        action: {
          icon: 'fa-eye',
          class: 'ui-button-danger',
          func: 'openEditModal',
          params: {
            row: 'row',
          },
        },
        width: '10em',
      },
      {
        header: 'Status',
        field: 'status',
        format: 'statusAlert',
        sortable: true,
        width: '8em',
      },
      {
        header: 'Driver',
        field: 'driver',
        sortable: true,
        sortableField: 'driver.name',
        format: 'UserName',
        noTbd: true,
        width: '10em',
      },
      {
        header: 'Amount',
        field: 'payTotalPreview',
        format: 'currency',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Pay status',
        field: 'payStatusDisplay',
        sortable: true,
        width: '10em',
      },
      {
        header: 'Settlement Date',
        field: 'payDate',
        sortable: true,
        format: 'dateWithAttachmentIcon',
        noTbd: true,
        action: {
          func: 'viewSettlement',
          params: {
            attachmentId: 'row.payAttachmentId',
          },
        },
        width: '11em',
      },
      {
        header: 'Ship Date',
        field: 'shipDate',
        sortable: true,
        format: 'Date',
        noTbd: true,
        width: '10em',
      },
      {
        header: 'Delivery Date',
        field: 'deliveryDate',
        sortable: true,
        format: 'Date',
        noTbd: true,
        width: '10em',
      },
      {
        header: 'Invoice Date',
        field: 'invoiceDate',
        sortable: true,
        format: 'Date',
        noTbd: true,
        width: '10em',
      },
    ];
  }

  getData() {
    if (!this.filter.driverUser.id) {
      this._NS.warn('Driver is required', 'Must choose a driver before search.');
      return;
    }
    this.loading = true;

    const params: DriverPayService.ApiDriverPayGetParams = {
      Authorization: this.clientToken.auth(),
      statusNotEquals: this.statusNotEquals,
      driverIdEquals: this.filter.driverUser.id,
      isPaid: this.filter.payStatus === 'unpaid' ? false : this.filter.payStatus === 'paid' ? true : null,
    };

    this.breadcrumbService.setItems('driverpay');
    if (this.filter.startDate) {
      switch (this.filter.dateCriteria) {
        case 'shipping':
          params.shippingBegin = Utils.formatDate(this.filter.startDate);
          break;
        case 'delivery':
          params.deliveryBegin = Utils.formatDate(this.filter.startDate);
          break;
        case 'invoice':
          params.invoiceBeginUtc = Utils.formatDateUtc(this.filter.startDate);
          break;
        default:
          throw Error('Unknown date criteria');
      }
    }
    if (this.filter.endDate) {
      const end = new Date(this.filter.endDate);
      end.setHours(23, 59, 59);

      switch (this.filter.dateCriteria) {
        case 'shipping':
          params.shippingEnd = Utils.formatDate(end);
          break;
        case 'delivery':
          params.deliveryEnd = Utils.formatDate(end);
          break;
        case 'invoice':
          params.invoiceEndUtc = Utils.formatDateUtc(end);
          break;
        default:
          throw Error('Unknown date criteria');
      }
    }

    this.driverPayService.ApiDriverPayGet(params).subscribe(
      (data) => {
        this.loads = data;
        this.loadsOriginal = data;
        this.formatLoadData();
        this.loading = false;
      },
      (error) => {
        this.router.navigate(['/dashboard']);
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  getDataFiltered() {
    this.loading = true;
    const params: LoadService.ApiLoadFilterGetParams = {
      Authorization: this.clientToken.auth(),
      statusEquals: this.filter.status,
      shippingBegin: this.filter.shippingBegin,
      shippingEnd: this.filter.shippingEnd,
      deliveryBegin: this.filter.deliveryBegin,
      deliveryEnd: this.filter.deliveryEnd,
    };

    if (this.filter.by) {
      params[this.filter.by + 'Contains'] = this.filter.contains;
    }

    this.loadService.ApiLoadFilterGet(params).subscribe(
      (data) => {
        this.filter.applied = true;
        this.loads = data;
        this.formatLoadData();
      },
      (error) => {
        this.router.navigate(['/dashboard']);
      },
      () => (this.loading = false)
    );
  }
  formatLoadData() {
    for (const load of this.loads) {
      load['payStatusDisplay'] = this.managePaidStatus(load.payStatus);
    }
  }
  managePaidStatus(status) {
    return this.unPaidStatuses.indexOf(status) > -1 ? 'Unpaid' : 'Paid';
  }

  actionHandler = (actionConfig) => {
    if (this[actionConfig.func]) {
      this[actionConfig.func](actionConfig.paramsParsed);
    }
  };
  onRowSelect(event) {
    const selectedLoadLegs = this.getSelectedLegIDs();
    this.rowsSelected = selectedLoadLegs.length === 0 ? false : true;
  }

  handleModelEdit(data) {
    if (data.dataChanged) {
      this.getData();
    }
  }

  public openEditModal(params: { row: { loadId: number } }): void {
    this.selectedLoadId = params.row.loadId;
    this.showLoadDialog = true;
  }

  sortTable(event: any) {
    const fieldInfo = this.cols.find((x) => x.field === event.field);
    if (fieldInfo) {
      event.sortableField = fieldInfo.sortableField;
    }
    Utils.sortData(event, this.loads);
  }

  filterTable() {
    if (this.filter.search !== '') {
      this.loads = this.loadsOriginal.filter(
        (item) =>
          item.loadId === parseInt(this.filter.search, 10) ||
          item.status.toLowerCase().includes(this.filter.search.toLowerCase()) ||
          item.customer.name.toLowerCase().includes(this.filter.search.toLowerCase())
      );
    } else {
      this.loads = this.loadsOriginal;
    }
  }

  getSelectedLegIDs() {
    return this.loads.filter((item) => item.isChecked === true).map((o) => o.loadLegId);
  }

  bulkToggleCheckAll($event) {
    this.loads.forEach((l) => {
      if (this.unPaidStatuses.indexOf(l.payStatus) > -1) {
        l.isChecked = $event;
      }
    });
    this.rowsSelected = this.getSelectedLegIDs().length === 0 ? false : true;

    this.pfsPaymentTable.forEach((component) => component.updateValues());
  }

  settlementProcess() {
    const selectedLoadLegs = this.getSelectedLegIDs();
    this.settlementDialog.init(
      this.filter.driverUser.id,
      selectedLoadLegs,
      this.filter.endDate ? new Date(this.filter.endDate).toLocaleDateString() : 'TBD'
    );
  }
  viewSettlement(payAttachmentIdObj: any) {
    this.pdfUtil.reviewSettlementPDF(payAttachmentIdObj.attachmentId, this.filter.driverUser.id);
  }
  onCloseDialog() {
    this.getData();
  }
}
