import { Component, OnDestroy, Output, ViewChild, EventEmitter, Input, ElementRef } from '@angular/core';
import { DebtorAddressMatchingDTO, FlexibleAddressDTO } from '../../../apiclient/v1.1/models';
import { DebtorAddressService } from '../../../apiclient/v1.1/services';
import { ClientTokenService } from '../../../services/client-token-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-address-matching-overlay',
  templateUrl: './address-matching-overlay.component.html',
  styleUrls: ['./address-matching-overlay.component.scss'],
})
export class AddressMatchingOverlayComponent implements OnDestroy {
  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;

  @Input() headerText: string;
  @Input() target: ElementRef;
  @Input() visible: boolean;
  @Input() showSuggestionsOnFirstInput: boolean;
  @Input('inputAddress') set inputAddress(value: FlexibleAddressDTO) {
    if (this.hasNewRecordBeenSelected(value, this.inputAddress)) {
      this._inputAddress = value;
    }
    if (
      this.visible &&
      this.isAddressValidForSearch(value) &&
      (this.isChange(value, this.inputAddress) || this.shouldShowOnFirstInput())
    ) {
      this._inputAddress = value;
      this.getMatches();
    }
    if (!this.isAddressValidForSearch(value) && this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }
  get inputAddress(): FlexibleAddressDTO {
    return this._inputAddress;
  }

  @Output() suggestionSelected: EventEmitter<DebtorAddressMatchingDTO> = new EventEmitter<DebtorAddressMatchingDTO>();

  public buyIconPath = '../../../../assets/images/factoring/buy-outline.png';
  public noBuyIconPath = '../../../../assets/images/factoring/nobuy-outline.png';

  public suggestions: DebtorAddressMatchingDTO[];

  private _inputAddress: FlexibleAddressDTO;
  private hasFirstSuggestionBeenShown = false;
  private destroy$ = new Subject();

  constructor(private debtorAddressService: DebtorAddressService, private clientTokenService: ClientTokenService) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public suggestionAccepted(suggestion: DebtorAddressMatchingDTO): void {
    this._inputAddress = suggestion.address;
    this.suggestionSelected.emit(suggestion);
    this.overlayPanel.hide();
  }

  public getMatches(): void {
    const request = {
      Authorization: this.clientTokenService.auth(),
      Line1: this.inputAddress.line1,
      Line2: this.inputAddress.line2,
      City: this.inputAddress.city,
      State: this.inputAddress.state,
      Zip: this.inputAddress.zip,
    };

    this.debtorAddressService
      .GetPotentialDebtorAddressMatches(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe((results) => this.handleSuggestions(results));
  }

  private handleSuggestions(suggestions: DebtorAddressMatchingDTO[]): void {
    this.suggestions = suggestions;
    if (this.suggestions && this.suggestions.length > 0) {
      this.overlayPanel.show({}, this.target);
    } else {
      this.overlayPanel.hide();
    }
  }

  private isAddressValidForSearch(address: FlexibleAddressDTO): boolean {
    if (!address) {
      return false;
    }

    return Object.keys(address).some((property) => !!address[property]);
  }

  private isChange(newAddress: FlexibleAddressDTO, currentAddress: FlexibleAddressDTO): boolean {
    if (!currentAddress && newAddress) {
      return true;
    }

    return Object.keys(newAddress).some((property) => newAddress[property] !== currentAddress[property]);
  }

  private hasNewRecordBeenSelected(inputAddress: FlexibleAddressDTO, currentAddress: FlexibleAddressDTO): boolean {
    const currentAddressIsEmpty = !currentAddress;
    const inputAddressIsComplete =
      inputAddress && !!inputAddress.line1 && !!inputAddress.city && !!inputAddress.state && !!inputAddress.zip;

    return currentAddressIsEmpty && inputAddressIsComplete;
  }

  private shouldShowOnFirstInput(): boolean {
    if (this.showSuggestionsOnFirstInput && !this.hasFirstSuggestionBeenShown) {
      this.hasFirstSuggestionBeenShown = true;
      return true;
    }
    return false;
  }
}
