import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { TenantAttachmentDTO } from '../../../../apiclient/v1.1/models';
import { TenantAttachmentService } from '../../../../apiclient/v1.1/services';
import { ClientTokenService } from '../../../../services/client-token-service';
import { FileSaverService } from '../../../../services/file-saver/file-saver.service';

@Component({
  selector: 'app-notice-of-assignment',
  templateUrl: './notice-of-assignment.component.html',
  styleUrls: ['./notice-of-assignment.component.scss'],
})
export class NoticeOfAssignmentComponent implements OnInit, OnDestroy {
  @Input() tenantId: number;
  public isNoticeOfAssignmentDialogVisible = false;
  public noaAttachment$: Observable<TenantAttachmentDTO>;
  private attachmentChanged$ = new BehaviorSubject<TenantAttachmentDTO>(undefined);
  private destroy$ = new Subject<void>();

  constructor(
    private clientTokenService: ClientTokenService,
    private tenantAttachmentService: TenantAttachmentService,
    private fileSaverService: FileSaverService
  ) {}

  public ngOnInit(): void {
    this.noaAttachment$ = combineLatest([this.getNoaAttachment(), this.attachmentChanged$]).pipe(
      map((p) => ({ attachment: p[0], attachmentChanged: p[1] })),
      map((p) => (p.attachmentChanged ? p.attachmentChanged : p.attachment)),
      shareReplay()
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public downloadFile(attachment: TenantAttachmentDTO): void {
    this.tenantAttachmentService
      .Download({
        Authorization: this.clientTokenService.auth(),
        attachmentId: attachment.attachmentId,
      })
      .pipe(
        tap((response) => this.fileSaverService.saveAs(response, attachment.fileName)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public openNoaDialog(): void {
    this.isNoticeOfAssignmentDialogVisible = true;
  }

  public noaDialogClosed(attachment?: TenantAttachmentDTO): void {
    this.isNoticeOfAssignmentDialogVisible = false;
    if (attachment) {
      this.attachmentChanged$.next(attachment);
    }
  }

  private getNoaAttachment(): Observable<TenantAttachmentDTO> {
    return this.tenantAttachmentService
      .Get({
        Authorization: this.clientTokenService.auth(),
        tenantId: this.tenantId,
        fileType: 'NOA',
      })
      .pipe(map((attachments) => (attachments.length ? attachments[0] : undefined)));
  }
}
