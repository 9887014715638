/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDTO } from '../models/user-dto';
import { ServiceResponseDTO } from '../models/service-response-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { ChangePasswordDTO } from '../models/change-password-dto';
import { VerifyEmailDTO } from '../models/verify-email-dto';
import { TenantUserAttachmentDTO } from '../models/tenant-user-attachment-dto';
import { SendFileDTO } from '../models/send-file-dto';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly ApiUserGetPath = '/api/User';
  static readonly ApiUserPostPath = '/api/User';
  static readonly ApiUserByIdGetPath = '/api/User/{id}';
  static readonly ApiUserByIdPutPath = '/api/User/{id}';
  static readonly ApiUserByIdDeletePath = '/api/User/{id}';
  static readonly ApiUserNamesGetPath = '/api/User/Names';
  static readonly ApiUserMeGetPath = '/api/User/Me';
  static readonly ApiUserByIdEnablePostPath = '/api/User/{id}/Enable';
  static readonly ApiUserByIdDisablePostPath = '/api/User/{id}/Disable';
  static readonly ApiUserChangePasswordPostPath = '/api/User/ChangePassword';
  static readonly ApiUserVerifyEmailPostPath = '/api/User/VerifyEmail';
  static readonly ApiUserConfirmVerifyEmailPostPath = '/api/User/ConfirmVerifyEmail';
  static readonly ApiUserResetLockoutPostPath = '/api/User/ResetLockout';
  static readonly ApiUserByUserIdAttachmentsGetPath = '/api/User/{userId}/attachments';
  static readonly ApiUserByUserIdAttachmentsPostPath = '/api/User/{userId}/attachments';
  static readonly ApiUserAttachmentsByIdGetPath = '/api/User/attachments/{id}';
  static readonly ApiUserAttachmentsByIdDeletePath = '/api/User/attachments/{id}';
  static readonly ApiUserAttachmentsSendPostPath = '/api/User/attachments/send';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of users
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiUserGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<UserDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDTO>>;
      })
    );
  }
  /**
   * Summary list of users
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiUserGet(Authorization: string): __Observable<Array<UserDTO>> {
    return this.ApiUserGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<UserDTO>)
    );
  }

  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `UserService.ApiUserPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiUserPostResponse(params: UserService.ApiUserPostParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `UserService.ApiUserPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiUserPost(params: UserService.ApiUserPostParams): __Observable<UserDTO> {
    return this.ApiUserPostResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * User detail
   * @param params The `UserService.ApiUserByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiUserByIdGetResponse(params: UserService.ApiUserByIdGetParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * User detail
   * @param params The `UserService.ApiUserByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiUserByIdGet(params: UserService.ApiUserByIdGetParams): __Observable<UserDTO> {
    return this.ApiUserByIdGetResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Update an existing user.
   * @param params The `UserService.ApiUserByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  ApiUserByIdPutResponse(params: UserService.ApiUserByIdPutParams): __Observable<__StrictHttpResponse<ServiceResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceResponseDTO>;
      })
    );
  }
  /**
   * Update an existing user.
   * @param params The `UserService.ApiUserByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  ApiUserByIdPut(params: UserService.ApiUserByIdPutParams): __Observable<ServiceResponseDTO> {
    return this.ApiUserByIdPutResponse(params).pipe(
      __map(_r => _r.body as ServiceResponseDTO)
    );
  }

  /**
   * Remove user from the tenant
   * @param params The `UserService.ApiUserByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdDeleteResponse(params: UserService.ApiUserByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove user from the tenant
   * @param params The `UserService.ApiUserByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdDelete(params: UserService.ApiUserByIdDeleteParams): __Observable<null> {
    return this.ApiUserByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `UserService.ApiUserNamesGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  ApiUserNamesGetResponse(params: UserService.ApiUserNamesGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.IncludeRoles || []).forEach(val => {if (val != null) __params = __params.append('IncludeRoles', val.toString())});
    if (params.IncludeDisabled != null) __params = __params.set('IncludeDisabled', params.IncludeDisabled.toString());
    (params.ExcludeRoles || []).forEach(val => {if (val != null) __params = __params.append('ExcludeRoles', val.toString())});
    if (params.ExcludeDriverOnly != null) __params = __params.set('ExcludeDriverOnly', params.ExcludeDriverOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/Names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `UserService.ApiUserNamesGetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  ApiUserNamesGet(params: UserService.ApiUserNamesGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiUserNamesGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Information on the current user. No permission requirements.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiUserMeGetResponse(Authorization: string): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/Me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Information on the current user. No permission requirements.
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiUserMeGet(Authorization: string): __Observable<UserDTO> {
    return this.ApiUserMeGetResponse(Authorization).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Enables a user for the tenant
   * @param params The `UserService.ApiUserByIdEnablePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdEnablePostResponse(params: UserService.ApiUserByIdEnablePostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables a user for the tenant
   * @param params The `UserService.ApiUserByIdEnablePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdEnablePost(params: UserService.ApiUserByIdEnablePostParams): __Observable<null> {
    return this.ApiUserByIdEnablePostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable a user for the tenant
   * @param params The `UserService.ApiUserByIdDisablePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdDisablePostResponse(params: UserService.ApiUserByIdDisablePostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable a user for the tenant
   * @param params The `UserService.ApiUserByIdDisablePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserByIdDisablePost(params: UserService.ApiUserByIdDisablePostParams): __Observable<null> {
    return this.ApiUserByIdDisablePostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Change the current user's password. This operation revokes current token - you must log back in after success.
   * @param params The `UserService.ApiUserChangePasswordPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserChangePasswordPostResponse(params: UserService.ApiUserChangePasswordPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/ChangePassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Change the current user's password. This operation revokes current token - you must log back in after success.
   * @param params The `UserService.ApiUserChangePasswordPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserChangePasswordPost(params: UserService.ApiUserChangePasswordPostParams): __Observable<null> {
    return this.ApiUserChangePasswordPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Re-Sends the user a confirmation code to be used with the VERIFY_EMAIL challenge
   * @param Authorization Bearer {token}
   */
  ApiUserVerifyEmailPostResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/VerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Re-Sends the user a confirmation code to be used with the VERIFY_EMAIL challenge
   * @param Authorization Bearer {token}
   */
  ApiUserVerifyEmailPost(Authorization: string): __Observable<null> {
    return this.ApiUserVerifyEmailPostResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * If user changes their email, they will get a code.
   * Must call this method with code to confirm users email.
   * @param params The `UserService.ApiUserConfirmVerifyEmailPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserConfirmVerifyEmailPostResponse(params: UserService.ApiUserConfirmVerifyEmailPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/ConfirmVerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * If user changes their email, they will get a code.
   * Must call this method with code to confirm users email.
   * @param params The `UserService.ApiUserConfirmVerifyEmailPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserConfirmVerifyEmailPost(params: UserService.ApiUserConfirmVerifyEmailPostParams): __Observable<null> {
    return this.ApiUserConfirmVerifyEmailPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `UserService.ApiUserResetLockoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ApiUserResetLockoutPostResponse(params: UserService.ApiUserResetLockoutPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/ResetLockout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `UserService.ApiUserResetLockoutPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ApiUserResetLockoutPost(params: UserService.ApiUserResetLockoutPostParams): __Observable<null> {
    return this.ApiUserResetLockoutPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a list of attachments for the user
   * @param params The `UserService.ApiUserByUserIdAttachmentsGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiUserByUserIdAttachmentsGetResponse(params: UserService.ApiUserByUserIdAttachmentsGetParams): __Observable<__StrictHttpResponse<Array<TenantUserAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.userId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantUserAttachmentDTO>>;
      })
    );
  }
  /**
   * Get a list of attachments for the user
   * @param params The `UserService.ApiUserByUserIdAttachmentsGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiUserByUserIdAttachmentsGet(params: UserService.ApiUserByUserIdAttachmentsGetParams): __Observable<Array<TenantUserAttachmentDTO>> {
    return this.ApiUserByUserIdAttachmentsGetResponse(params).pipe(
      __map(_r => _r.body as Array<TenantUserAttachmentDTO>)
    );
  }

  /**
   * Upload a user attachment
   * @param params The `UserService.ApiUserByUserIdAttachmentsPostParams` containing the following parameters:
   *
   * - `userId`: Valid user id
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Type of file being uploaded
   *
   * - `expiresOn`: Optional: Indicate when a document expires (such as a license or certificate)
   *
   * @return Success
   */
  ApiUserByUserIdAttachmentsPostResponse(params: UserService.ApiUserByUserIdAttachmentsPostParams): __Observable<__StrictHttpResponse<TenantUserAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.expiresOn != null) __params = __params.set('expiresOn', params.expiresOn.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.userId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantUserAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a user attachment
   * @param params The `UserService.ApiUserByUserIdAttachmentsPostParams` containing the following parameters:
   *
   * - `userId`: Valid user id
   *
   * - `file`: Upload File
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Type of file being uploaded
   *
   * - `expiresOn`: Optional: Indicate when a document expires (such as a license or certificate)
   *
   * @return Success
   */
  ApiUserByUserIdAttachmentsPost(params: UserService.ApiUserByUserIdAttachmentsPostParams): __Observable<TenantUserAttachmentDTO> {
    return this.ApiUserByUserIdAttachmentsPostResponse(params).pipe(
      __map(_r => _r.body as TenantUserAttachmentDTO)
    );
  }

  /**
   * User attachment detail
   * @param params The `UserService.ApiUserAttachmentsByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested user attachment
   */
  ApiUserAttachmentsByIdGetResponse(params: UserService.ApiUserAttachmentsByIdGetParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * User attachment detail
   * @param params The `UserService.ApiUserAttachmentsByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested user attachment
   */
  ApiUserAttachmentsByIdGet(params: UserService.ApiUserAttachmentsByIdGetParams): __Observable<Blob> {
    return this.ApiUserAttachmentsByIdGetResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `UserService.ApiUserAttachmentsByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserAttachmentsByIdDeleteResponse(params: UserService.ApiUserAttachmentsByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `UserService.ApiUserAttachmentsByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiUserAttachmentsByIdDelete(params: UserService.ApiUserAttachmentsByIdDeleteParams): __Observable<null> {
    return this.ApiUserAttachmentsByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sends attachments to email recipients
   * @param params The `UserService.ApiUserAttachmentsSendPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserAttachmentsSendPostResponse(params: UserService.ApiUserAttachmentsSendPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/attachments/send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends attachments to email recipients
   * @param params The `UserService.ApiUserAttachmentsSendPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiUserAttachmentsSendPost(params: UserService.ApiUserAttachmentsSendPostParams): __Observable<null> {
    return this.ApiUserAttachmentsSendPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for ApiUserPost
   */
  export interface ApiUserPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: UserDTO;
  }

  /**
   * Parameters for ApiUserByIdGet
   */
  export interface ApiUserByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserByIdPut
   */
  export interface ApiUserByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: UserDTO;
  }

  /**
   * Parameters for ApiUserByIdDelete
   */
  export interface ApiUserByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserNamesGet
   */
  export interface ApiUserNamesGetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    IncludeRoles?: Array<string>;
    IncludeDisabled?: boolean;
    ExcludeRoles?: Array<string>;
    ExcludeDriverOnly?: boolean;
  }

  /**
   * Parameters for ApiUserByIdEnablePost
   */
  export interface ApiUserByIdEnablePostParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserByIdDisablePost
   */
  export interface ApiUserByIdDisablePostParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserChangePasswordPost
   */
  export interface ApiUserChangePasswordPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: ChangePasswordDTO;
  }

  /**
   * Parameters for ApiUserConfirmVerifyEmailPost
   */
  export interface ApiUserConfirmVerifyEmailPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: VerifyEmailDTO;
  }

  /**
   * Parameters for ApiUserResetLockoutPost
   */
  export interface ApiUserResetLockoutPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    id?: number;
  }

  /**
   * Parameters for ApiUserByUserIdAttachmentsGet
   */
  export interface ApiUserByUserIdAttachmentsGetParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserByUserIdAttachmentsPost
   */
  export interface ApiUserByUserIdAttachmentsPostParams {

    /**
     * Valid user id
     */
    userId: number;

    /**
     * Upload File
     */
    file: Blob;

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * Type of file being uploaded
     */
    fileType?: 'Other' | 'License' | 'LongMedicalForm' | 'DriverPay' | 'DrugTestingEnrollment' | 'CertificateOfCompliance' | 'NonMotorCarrierActivityForm' | 'ProofOfReceivingFMCSRHandbook' | 'EmploymentApplication' | 'MedicalExaminersCertificate' | 'PreEmploymentDrugTest' | 'RoadTest' | 'ChecklistForCasuals' | 'InquiryToPreviousEmployers' | 'InquiryToStateAgencies' | 'AnnualCertificationOfViolations' | 'AnnualMVRReview' | 'HazmatCertification';

    /**
     * Optional: Indicate when a document expires (such as a license or certificate)
     */
    expiresOn?: string;
  }

  /**
   * Parameters for ApiUserAttachmentsByIdGet
   */
  export interface ApiUserAttachmentsByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserAttachmentsByIdDelete
   */
  export interface ApiUserAttachmentsByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiUserAttachmentsSendPost
   */
  export interface ApiUserAttachmentsSendPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: SendFileDTO;
  }
}

export { UserService }
