import { Injectable } from '@angular/core';
import { LoadRateDTO, LogisticsLoadDTO } from 'src/apiclient/v1.1/models';
import { CopyLoadService } from './copy-load-service';

@Injectable({
  providedIn: 'root',
})
export class CopyLogisticsLoadService {
  constructor(private copyLoadService: CopyLoadService) {}

  public copyLogisticsLoad(logisticsLoad: LogisticsLoadDTO): LogisticsLoadDTO {
    const newLogisticsLoad = { ...logisticsLoad };

    newLogisticsLoad.load = this.copyLoadService.copyLoad(newLogisticsLoad.load);
    newLogisticsLoad.logisticsLoadId = 0;
    newLogisticsLoad.loadId = 0;
    newLogisticsLoad.estimatedTimeOfArrival = null;
    newLogisticsLoad.estimatedTimeOfArrivalLastUpdated = null;
    newLogisticsLoad.carrierLoadRateId = 0;
    newLogisticsLoad.billToLoadRateId = 0;
    newLogisticsLoad.load.dispatcher = {};

    this.clearLoadRateProperties(newLogisticsLoad.carrierLoadRate);
    this.clearLoadRateProperties(newLogisticsLoad.billToLoadRate);

    return newLogisticsLoad;
  }

  private clearLoadRateProperties(rate: LoadRateDTO): void {
    rate.loadRateId = 0;

    rate.advances.forEach((advance) => {
      advance.loadRateId = 0;
      advance.loadRateAdvanceId = 0;
    });

    rate.otherCharges.forEach((otherCharge) => {
      otherCharge.loadRateId = 0;
      otherCharge.loadRateOtherChargeId = 0;
    });
  }
}
