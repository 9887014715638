import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FactoringFeatureGuard } from './factoring-feature.guard';
import { BuyNoBuyComponent } from './buy-no-buy/buy-no-buy.component';
import { AppConfig } from 'src/app/config/app.config';

const routes: Routes = [
  { path: AppConfig.routes.factoring.buyNoBuy, component: BuyNoBuyComponent, canActivate: [FactoringFeatureGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FactoringRoutingModule {}
