<div class="centered-card-container-small">
  <p-card [style]="{ padding: '10%' }">
    <div class="header-text">Unable To Confirm User</div>
    <span
      >We were unable to process your user confirmation. Either the user does not exist or the confirmation link has
      expired. Please sign up again, or try again later.
    </span>
    <div class="back-button">
      <span>Back to </span>
      <a class="fauxlink" (click)="routeToSignUp()">Sign Up</a>
    </div>
  </p-card>
</div>
