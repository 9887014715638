<div class="formgrid grid">
  <div class="col-12">
    <div class="flex justify-content-between p-2">
      <div class="label-black">Include Options</div>
      <div class="buttons">
        <button
          pButton
          label="Select All"
          class="ui-button-secondary mr-2"
          [disabled]="(allCheckboxesChecked$ | async) || formGroup.disabled"
          (click)="selectAll()"
        ></button>
        <button
          pButton
          label="Deselect All"
          class="ui-button-secondary deselect"
          [disabled]="(noCheckboxesChecked$ | async) || formGroup.disabled"
          (click)="deselectAll()"
        ></button>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="grid p-2" [formGroup]="formGroup">
      <div class="col-4 pb-3 flex align-items-center" *ngFor="let checkboxField of checkboxFields">
        <p-checkbox
          [label]="checkboxField.label"
          [formControl]="formGroup.controls[checkboxField.formControlName]"
          binary="true"
        ></p-checkbox>
        <i
          *ngIf="checkboxField.toolTip"
          class="fas fa-question-circle pl-2 tooltip"
          [pTooltip]="checkboxField.toolTip"
          tooltipPosition="right"
        ></i>
      </div>
    </div>
  </div>
</div>
