<div class="loading-message">
  <p>Checking For Matching Records</p>
  <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</div>

<app-company-address-matching-display
  id="company-address-matching-display"
  *ngIf="matchingResults"
  [matchingResults]="matchingResults"
  (matchSelected)="matchSelectedEvent($event)"
  (actionTaken)="actionTakenEvent($event)"
></app-company-address-matching-display>
