import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationComponent } from './user-registration.component';
import { UserRegistrationRoutingModule } from './user-registration-routing.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnableToGetUserComponent } from './unable-to-get-user/unable-to-get-user.component';
import { UserRegistrationWizardComponent } from './user-registration-wizard/user-registration-wizard.component';
import { WizardProgressComponent } from './user-registration-wizard/wizard-progress/wizard-progress.component';
import { WizardProgressStepHeaderComponent } from './user-registration-wizard/wizard-progress/wizard-progress-step-header/wizard-progress-step-header.component';
import { WizardNavigationComponent } from './user-registration-wizard/wizard-navigation/wizard-navigation.component';
import { CompanyInformationFormComponent } from './user-registration-wizard/wizard-forms/company-information-form/company-information-form.component';
import { AddressInformationFormComponent } from './user-registration-wizard/wizard-forms/mailing-address-form/mailing-address-form.component';
import { AdministratorInformationFormComponent } from './user-registration-wizard/wizard-forms/administrator-information-form/administrator-information-form.component';
import { EulaFormComponent } from './user-registration-wizard/wizard-forms/eula-form/eula-form.component';
import { PhysicalAddressFormComponent } from './user-registration-wizard/wizard-forms/physical-address-form/physical-address-form.component';

@NgModule({
  declarations: [
    UserRegistrationComponent,
    UnableToGetUserComponent,
    UserRegistrationWizardComponent,
    WizardProgressComponent,
    WizardProgressStepHeaderComponent,
    WizardNavigationComponent,
    CompanyInformationFormComponent,
    AddressInformationFormComponent,
    AdministratorInformationFormComponent,
    EulaFormComponent,
    PhysicalAddressFormComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, UserRegistrationRoutingModule],
})
export class UserRegistrationModule {}
