<p-dialog
  header="{{ dialogHeaderText }}"
  [(visible)]="showDialog"
  [modal]="true"
  [style]="{ width: '25vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [focusOnShow]="false"
>
  <div *ngIf="showDialog && !actionInProgress">
    <div
      class="load-copy-form py-2 px-0 w-full flex flex-row justify-content-between align-items-center"
      [formGroup]="loadCopyForm"
      *ngIf="actionType === BulkActionName.CopyLoad"
    >
      <span>Number of Copies</span>
      <p-inputNumber formControlName="numberOfCopies" mode="decimal" [showButtons]="true" [min]="1" [max]="10">
      </p-inputNumber>
    </div>

    <div class="load-list" *ngIf="actionType === BulkActionName.DeleteLoad">
      <span> Selected {{ selectedLoads?.length }} load(s) to delete:</span>
      <div class="load-number-list pt-3 pl-2">
        <div class="load-number-item" *ngFor="let load of selectedLoads; let i = index">
          <li>Load Number: {{ load.loadNumber }}</li>
        </div>
      </div>
      <div class="disclaimer pt-3">
        Deleted loads will be removed and not available for future invoicing or driver settlement. Additionally, deleted
        loads will not be included in any data reporting totals.
      </div>
    </div>

    <div class="load-list" *ngIf="actionType === BulkActionName.UndeleteLoad">
      <span> Selected {{ selectedLoads?.length }} load(s) to undelete:</span>
      <div class="load-number-list pt-3 pl-2">
        <div class="load-number-item" *ngFor="let load of selectedLoads; let i = index">
          <li>Load Number: {{ load.loadNumber }}</li>
        </div>
      </div>
      <div class="disclaimer pt-3"
        >Undeleted loads will become eligible for additional processing (including invoice and driver settlement
        processing) and will impact your existing data reporting totals.</div
      >
    </div>
  </div>

  <div *ngIf="actionInProgress" class="w-full flex flex-column align-items-center justify-content-center">
    {{ dialogProgressText }}
    <p-progressBar class="progress-bar pt-4 w-full" mode="indeterminate"></p-progressBar>
  </div>

  <p-footer>
    <button
      id="cancel-button"
      type="button"
      pButton
      label="Cancel"
      class="ui-button-secondary"
      (click)="toggleShowDialog()"
    ></button>
    <button
      id="action-button"
      type="button"
      pButton
      icon="{{ dialogButtonIcon }}"
      label="{{ dialogButtonText }}"
      [disabled]="actionInProgress"
      (click)="handleAction()"
    ></button>
  </p-footer>
</p-dialog>
