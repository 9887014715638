import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() accept: string;
  @Output() fileChanged = new EventEmitter<File>();

  public onFileSelect(event: { originalEvent: Event; files: FileList }): void {
    const files = event.files;
    this.fileChanged.emit(files[0]);
  }

  public onClear(): void {
    this.fileChanged.emit(null);
  }
}
