<p-fileUpload
  #fileUpload
  mode="basic"
  name="file"
  chooseLabel="Select file..."
  [customUpload]="true"
  (onSelect)="onFileSelect($event)"
  (onClear)="onClear()"
  [accept]="accept"
></p-fileUpload>
<button
  pButton
  *ngIf="fileUpload.files.length > 0"
  (click)="fileUpload.clear()"
  type="button"
  icon="fas fa-times"
></button>
