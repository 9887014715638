import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Injector,
  OnDestroy,
} from '@angular/core';
import { DtoFormBase } from '../../../shared/FormBase';
import { saveAs } from 'file-saver/FileSaver';
import { CompanyService } from '../../../../apiclient/v1.1/services/company.service';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { CompanyParseService } from '../../../../services/company-parse/company-parse.service';

@Component({
  selector: 'app-bulkimport',
  templateUrl: './bulkimport.component.html',
  styleUrls: ['./bulkimport.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BulkimportComponent extends DtoFormBase<any> implements OnInit, OnDestroy {
  @Output() dialogClosed = new EventEmitter<void>();
  @ViewChild('uploader', { static: true }) uploader: FileUpload;
  file = null;
  showProgressOverlay: any;
  showBulkimportErrorUI: boolean = false;
  showBulkimportUI: boolean = false;
  showAdditionalErrors: boolean = false;
  additionalErrors: number = 0;
  disableSave = false;
  fullErrorList: string = '';
  uploadSuccess: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    protected injector: Injector,
    private companyService: CompanyService,
    private companyParseService: CompanyParseService
  ) {
    super(injector);
    this.formErrors['type'] = '';
  }

  ngOnInit() {
    this.disableSave = false;
    this.formErrors['type'] = '';
    setTimeout((_) => {
      const focussedButton = <HTMLElement>document.activeElement;
      focussedButton.blur();
    });
  }

  onFileSelect(event) {
    if (event.originalEvent.target.files && event.originalEvent.target.files.length > 0) {
      const file = event.originalEvent.target.files[0];
      this.file = file;
      document.getElementById('fileUploader').children[0].children[1].innerHTML = file.name;
      this.formErrors['file'] = '';
      this.showAdditionalErrors = false;
    }
  }
  onFileClear() {
    this.file = null;
    this.resetUploadButtonText();
  }
  resetUploadButtonText() {
    if (document.getElementById('fileUploader') && document.getElementById('fileUploader').children) {
      document.getElementById('fileUploader').children[0].children[1].innerHTML = 'Select file';
    }
  }
  onCloseDialog() {
    this.dialogClosed.emit();
  }

  init() {
    this.showBulkimportUI = true;
    this.uploader.clear();
    this.onFileClear();
    this.formErrors['file'] = '';
    this.uploadSuccess = false;
    this.showAdditionalErrors = false;
    setTimeout((_) => {
      const focussedButton = <HTMLElement>document.activeElement;
      focussedButton.blur();
    });
  }
  isFormInValid() {
    return !(this.file == null);
  }
  downloadTemplate() {
    this.companyService.ImportTemplate(this.clientToken.auth()).subscribe((data) => {
      saveAs(data, 'template.csv');
    }, this.handleBasicError);
  }
  showBulkimportErrorModal() {
    this.showBulkimportErrorUI = true;
  }

  public saveModel(): void {
    if (!this.file) {
      this.formErrors['file'] = 'File is required.';
      return;
    }

    this.disableSave = true;
    this.importCompanies()
      .pipe(
        tap(() => {
          this.uploadSuccess = true;
          this.resetUploadButtonText();
          this.onCloseDialog();
          this._NS.success('Upload success!', 'The csv file was uploaded successfully.');
        }),
        catchError((err) => {
          this.handleOwnError(err.error);
          this.onFileClear();
          throw err;
        }),
        finalize(() => {
          this.disableSave = false;
          this.uploader.clear();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private importCompanies(): Observable<void> {
    return this.companyParseService.parseFile(this.file).pipe(
      catchError((err) => {
        this._NS.error('Error parsing file', 'One or more errors occurred while parsing the CSV file.');
        throw err;
      }),
      switchMap((companies) =>
        this.companyService
          .Import({
            body: companies,
            Authorization: this.clientToken.auth(),
          })
          .pipe(
            catchError((err) => {
              this._NS.error('Error uploading file', 'One or more errors occurred while importing the companies.');
              throw err;
            })
          )
      )
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleOwnError(problems): void {
    // tslint:disable-next-line:no-inferrable-types
    let errors: string = 'Upload failed due to the following errors:';
    let countErrors = 0;
    let rowIndex = -1;
    let countApiErrors = 0;
    this.fullErrorList = '';
    if (problems) {
      countApiErrors = Object.keys(problems).length;
      // tslint:disable-next-line:forin
      for (const key in problems) {
        const messages = problems[key].join(', ');
        let keyField = '';
        if (key.indexOf('.') > -1) {
          const parts = key.split('.');
          // need to parse [] off to get row number as #, then add 2 to get correct spreadsheet row number
          const rownum = Number(parts[0].substring(1, parts[0].length - 1)) + 2;
          if (rowIndex !== rownum) {
            rowIndex = rownum;
            if (!this.showAdditionalErrors) {
              errors += '<br>Row ' + rownum;
            }
            this.fullErrorList += 'Row ' + rownum + '\n';
          }
          for (let i = 0; i < parts.length; i++) {
            keyField += parts[i].substring(0, 1).toLowerCase() + parts[i].substring(1) + '-';
          }
          keyField = keyField.substring(0, keyField.length - 1);
        } else {
          keyField = key.substring(0, 1).toLowerCase() + key.substring(1);
        }
        if (!this.showAdditionalErrors) {
          if (countErrors > 4) {
            this.showAdditionalErrors = true;
            this.additionalErrors = countApiErrors - 5;
          } else {
            errors += '<br>- ' + messages;
            countErrors++;
          }
        }
        this.fullErrorList += '-' + messages + '\n';
      }
      this.formErrors['file'] = errors;
    }
  }
}
