export enum ReportingWizardOptions {
  Driver = 'Driver',
  BillTo = 'BillTo',
  Shipper = 'Shipper',
  Consignee = 'Consignee',
  Dispatcher = 'Dispatcher',
  TruckNumber = 'TruckNumber',
  TrailerNumber = 'TrailerNumber',
  EquipmentType = 'EquipmentType',
  Invoiced = 'Invoiced',
  Delivered = 'Delivered',
  TotalLoadedMiles = 'TotalLoadedMiles',
  FuelSurcharge = 'FuelSurcharge',
  OtherCharges = 'OtherCharges',
  Rate = 'Rate',
  TotalRevenue = 'TotalRevenue',
  RatePerMile = 'RatePerMile',
  DriverGrossPay = 'DriverGrossPay',
  Margin = 'Margin',
}
