import { Injectable } from '@angular/core';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Observable, Subject } from 'rxjs';
import { CompanyImportCsvDTO } from 'src/apiclient/v1.1/models';
import { CompanyStatus } from 'src/app/data/static-data';
import { CompanyImportCsv } from 'src/app/shared/models/company-import-csv';

@Injectable({
  providedIn: 'root',
})
export class CompanyParseService {
  private readonly booleanFieldIncludes = ['is', 'AppointmentRequired'];
  private readonly numberFields: (keyof CompanyImportCsvDTO)[] = ['creditLimit'];
  private readonly statusFields: (keyof CompanyImportCsvDTO)[] = ['status'];

  constructor(private papa: Papa) {}

  public parseFile(file: File): Observable<CompanyImportCsvDTO[]> {
    const resultStream$ = new Subject<CompanyImportCsvDTO[]>();
    this.papa.parse(file, {
      header: true,
      // Transform headers to be case-insensitive when mapping.
      transformHeader: (header) => header.toUpperCase(),
      skipEmptyLines: true,
      complete: (results) => {
        try {
          resultStream$.next(this.getResults(results));
        } catch (error) {
          resultStream$.error(error);
        } finally {
          resultStream$.complete();
        }
      },
    });

    return resultStream$.asObservable();
  }

  private getResults(results: ParseResult): CompanyImportCsvDTO[] {
    if (results.data instanceof Array && !results.data.length) {
      throw this.formatError({ file: ['File contents are blank'] });
    } else if (results.errors.length) {
      const formattedErrors = results.errors.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.row}.${currentValue.code}`]: [currentValue.message],
        }),
        {}
      );
      throw this.formatError(formattedErrors);
    } else {
      return this.mapCsvData(results);
    }
  }

  private mapCsvData(results: ParseResult): CompanyImportCsvDTO[] {
    const data: any[] = results.data;

    const csvMappingErrors = data
      .map((row, index) => this.getMappingErrors(results, row, index))
      .reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          ...currentValue,
        }),
        {}
      );

    if (Object.keys(csvMappingErrors).length) {
      throw this.formatError(Object.assign({}, csvMappingErrors));
    }

    return data.map<CompanyImportCsvDTO>((d) => this.mapRow(d));
  }

  private getMappingErrors(results: ParseResult, row: any, index: number): Record<string, string[]> {
    const mappingErrors: Record<string, string[]> = {};
    const fields = results.meta.fields.map((field) => field.toLowerCase());

    if (this.hasFieldsIncluded(fields, this.booleanFieldIncludes)) {
      this.getInvalidBooleanErrors(row, mappingErrors, index);
    }

    if (this.hasFieldsIncluded(fields, this.numberFields)) {
      this.getInvalidNumber(row, mappingErrors, index);
    }

    if (this.hasFieldsIncluded(fields, this.statusFields)) {
      this.getStatusErrors(row, mappingErrors, index);
    }

    return mappingErrors;
  }

  private getErrors(
    fieldIncludes: string[],
    row: any,
    mappingErrors: Record<string, string[]>,
    index: number,
    isError: (value: string) => boolean,
    acceptedValues: string | [string, string]
  ) {
    this.getKeys(row, fieldIncludes)
      .filter((key) => isError(row[key]))
      .forEach((key) => {
        let errorMessage = `The value '${row[key]}' for the field ${key} is invalid. `;
        if (acceptedValues instanceof Array) {
          errorMessage += `The accepted values are '${acceptedValues[0]}' or '${acceptedValues[1]}'.`;
        } else {
          errorMessage += `The value must be ${acceptedValues}.`;
        }
        mappingErrors[`[${index}].${key}`] = [errorMessage];
      });
  }

  private getInvalidBooleanErrors(row: any, mappingErrors: Record<string, string[]>, index: number): void {
    this.getErrors(
      this.booleanFieldIncludes,
      row,
      mappingErrors,
      index,
      (value) => {
        const boolValue = value.toLowerCase();
        return boolValue && boolValue !== 'true' && boolValue !== 'false';
      },
      ['true', 'false']
    );
  }

  private getStatusErrors(row: any, mappingErrors: Record<string, string[]>, index: number): void {
    this.getErrors(
      this.statusFields,
      row,
      mappingErrors,
      index,
      (value) => {
        const status = value.toLowerCase();
        return status !== CompanyStatus.Active.toLowerCase() && status !== CompanyStatus.Inactive.toLowerCase();
      },
      [CompanyStatus.Active, CompanyStatus.Inactive]
    );
  }

  private getInvalidNumber(row: any, mappingErrors: Record<string, string[]>, index: number): void {
    this.getErrors(this.numberFields, row, mappingErrors, index, (value) => value && isNaN(+value), 'a number');
  }

  private getKeys(row: any, keyIncludes: string[]): string[] {
    return Object.keys(row).filter((key) => this.isFieldIncluded(key, keyIncludes));
  }

  private hasFieldsIncluded(fields: string[], fieldIncludes: string[]): boolean {
    return fields.some((field) => this.isFieldIncluded(field, fieldIncludes));
  }

  private isFieldIncluded(field: string, fieldIncludes: string[]): boolean {
    return fieldIncludes.some((fieldInclude) => field.toLowerCase().includes(fieldInclude.toLowerCase()));
  }

  private formatError(err: Record<string, string[]>): { error: Record<string, string[]> } {
    return { error: err };
  }

  private mapRow(row: CompanyImportCsv): CompanyImportCsvDTO {
    return {
      addressCity: row.ADDRESSCITY,
      addressLine1: row.ADDRESSLINE1,
      addressLine2: row.ADDRESSLINE2,
      addressState: row.ADDRESSSTATE,
      addressZip: row.ADDRESSZIP,
      billToContactEmail: row.BILLTOCONTACTEMAIL,
      billToContactFirstName: row.BILLTOCONTACTFIRSTNAME,
      billToContactLastName: row.BILLTOCONTACTLASTNAME,
      billToContactMobileNumber: row.BILLTOCONTACTMOBILENUMBER,
      billToContactPhoneExt: row.BILLTOCONTACTPHONEEXT,
      billToContactPhoneNumber: row.BILLTOCONTACTPHONENUMBER,
      billToNotesInternal: row.BILLTONOTESINTERNAL,
      billToNotesReports: row.BILLTONOTESREPORTS,
      billToPaymentTerms: row.BILLTOPAYMENTTERMS,
      consigneeAppointmentRequired: this.stringToBoolean(row.CONSIGNEEAPPOINTMENTREQUIRED),
      consigneeContactEmail: row.CONSIGNEECONTACTEMAIL,
      consigneeContactFirstName: row.CONSIGNEECONTACTFIRSTNAME,
      consigneeContactLastName: row.CONSIGNEECONTACTLASTNAME,
      consigneeContactMobileNumber: row.CONSIGNEECONTACTMOBILENUMBER,
      consigneeContactPhoneExt: row.CONSIGNEECONTACTPHONEEXT,
      consigneeContactPhoneNumber: row.CONSIGNEECONTACTPHONENUMBER,
      consigneeNotesInternal: row.CONSIGNEENOTESINTERNAL,
      consigneeNotesReports: row.CONSIGNEENOTESREPORTS,
      consigneeOperatingHours: row.CONSIGNEEOPERATINGHOURS,
      creditLimit: this.stringToNumber(row.CREDITLIMIT),
      isBillTo: this.stringToBoolean(row.ISBILLTO),
      isConsignee: this.stringToBoolean(row.ISCONSIGNEE),
      isShipper: this.stringToBoolean(row.ISSHIPPER),
      legalName: row.LEGALNAME,
      locationContactEmail: row.LOCATIONCONTACTEMAIL,
      locationContactFirstName: row.LOCATIONCONTACTFIRSTNAME,
      locationContactLastName: row.LOCATIONCONTACTLASTNAME,
      locationContactMobileNumber: row.LOCATIONCONTACTMOBILENUMBER,
      locationContactPhoneExt: row.LOCATIONCONTACTPHONEEXT,
      locationContactPhoneNumber: row.LOCATIONCONTACTPHONENUMBER,
      name: row.NAME,
      primaryContactEmail: row.PRIMARYCONTACTEMAIL,
      primaryContactFirstName: row.PRIMARYCONTACTFIRSTNAME,
      primaryContactLastName: row.PRIMARYCONTACTLASTNAME,
      primaryContactMobileNumber: row.PRIMARYCONTACTMOBILENUMBER,
      primaryContactPhoneExt: row.PRIMARYCONTACTPHONEEXT,
      primaryContactPhoneNumber: row.PRIMARYCONTACTPHONENUMBER,
      shipperAppointmentRequired: this.stringToBoolean(row.SHIPPERAPPOINTMENTREQUIRED),
      shipperContactEmail: row.SHIPPERCONTACTEMAIL,
      shipperContactFirstName: row.SHIPPERCONTACTFIRSTNAME,
      shipperContactLastName: row.SHIPPERCONTACTLASTNAME,
      shipperContactMobileNumber: row.SHIPPERCONTACTMOBILENUMBER,
      shipperContactPhoneExt: row.SHIPPERCONTACTPHONEEXT,
      shipperContactPhoneNumber: row.SHIPPERCONTACTPHONENUMBER,
      shipperNotesInternal: row.SHIPPERNOTESINTERNAL,
      shipperNotesReports: row.SHIPPERNOTESREPORTS,
      shipperOperatingHours: row.SHIPPEROPERATINGHOURS,
      status: row.STATUS as CompanyStatus.Active | CompanyStatus.Inactive,
    };
  }

  private stringToBoolean(str: string): boolean {
    return !!str && str.toLowerCase() === 'true';
  }

  private stringToNumber(str: string): number {
    return !!str ? +str : 0;
  }
}
