import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone',
})
@Injectable({
  providedIn: 'root',
})
export class FormatPhone implements PipeTransform {
  transform(value: any, format?: any): any {
    if (!value || value === '') {
      return value;
    }

    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
}
