<div class="layout-content-container table-container">
  <div class="grid grid-nogutter col-12 p-0 table-inner">
    <div class="grid grid-nogutter flex col-12 p-0">
      <div class="px-0 p-2">
        <p-dropdown
          [options]="bulkActions"
          [style]="{ 'min-width': '20rem' }"
          placeholder="Bulk Actions"
          [(ngModel)]="bulkAction"
          [disabled]="disabled"
        ></p-dropdown>
      </div>
      <div class="p-2">
        <button
          pButton
          class="w-full"
          type="button"
          label="Apply"
          [disabled]="disableApply || disabled"
          (click)="bulkApply()"
        ></button>
      </div>
    </div>
    <div class="grid grid-nogutter col-12 p-0">
      <p-table
        [columns]="cols"
        [value]="documents"
        [lazy]="true"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="50vw"
        [resizableColumns]="true"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.format" pResizableColumn>
              <ng-container *ngSwitchCase="'checkbox'">
                <p-checkbox [(ngModel)]="bulkCheckAll" (onChange)="bulkToggleCheckAll()" binary="true"></p-checkbox>
              </ng-container>

              <ng-container *ngSwitchDefault>
                {{ col.header }}
              </ng-container>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr
            #pfsDocumentsTable
            pfs-table-body
            [columns]="columns"
            [row]="rowData"
            [isResizable]="true"
            (oneAction)="actionHandler($event)"
          ></tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-12 p-0 py-2">
      <app-document-add [loadId]="loadId" [disabled]="disabled" (uploadedFile)="getData()"></app-document-add>
      <app-document-mail #documentMailer [attachments]="getSelectedItems()"></app-document-mail>
    </div>
  </div>
</div>
