import { Component, OnInit } from '@angular/core';
import { TenantBillingService } from '../../../../services/tenant-billing.service';
import { Router } from '@angular/router';
import { AppConfig } from '../../../config/app.config';
import { ClientTokenService } from '../../../../services/client-token-service';
import { BillingStatus } from '../../../data/static-data';
import { TargetUrlService } from 'src/services/target-url-service/target-url-service';

@Component({
  selector: 'app-required',
  templateUrl: './required.component.html',
  styleUrls: ['./required.component.css'],
})
export class RequiredComponent implements OnInit {
  disabled = true;
  showPortal = false;
  showSubscribe = false;

  billingStatus: BillingStatus;
  message: string;

  constructor(
    private billing: TenantBillingService,
    private router: Router,
    private clientToken: ClientTokenService,
    private targetUrlService: TargetUrlService
  ) {}

  ngOnInit() {
    this.billing.billingStatus().subscribe((status) => {
      this.disabled = false;
      this.billingStatus = status;
      const canEdit = this.clientToken.hasPermission('TenantEdit');
      switch (status) {
        case BillingStatus.Loading:
          this.message = 'Loading ...';
          break;
        case BillingStatus.NoUser:
        case BillingStatus.Exempt:
        case BillingStatus.Active:
          this.conditionallyNavigate();
          break;
        case BillingStatus.Trialing:
          this.conditionallyNavigate();
          break;
        case BillingStatus.Required:
          this.message =
            'A subscription has not been setup for  <b>' + this.clientToken.getToken().tenant.name + '</b> yet. ';
          this.message += canEdit
            ? 'Please set up a subscription.'
            : 'Contact an administrator to set up a subscription.';
          this.showSubscribe = canEdit;
          break;
        default:
          this.message = 'The account subscription is inactive. ';
          this.message += canEdit
            ? 'Please activate your subscription.'
            : 'Contact an administrator to activate your subscription.';
          this.showPortal = canEdit;
          break;
      }
    });
  }

  async subscribe() {
    this.disabled = true;
    try {
      await this.billing.startSubscribe();
    } finally {
      this.disabled = false;
    }
  }

  async portal() {
    this.disabled = true;
    this.disabled = true;
    try {
      await this.billing.openPortal();
    } finally {
      this.disabled = false;
    }
  }

  private conditionallyNavigate(): void {
    if (!this.targetUrlService.tryNavigateToTargetUrl()) {
      this.router.navigate(['/' + AppConfig.routes.dashboard]);
    }
  }
}
