<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<app-load-edit
  *ngIf="showLoadDialog"
  [loadId]="selectedLoadId"
  [(dialogVisible)]="showLoadDialog"
  (modelEdited)="handleLoadEdited($event)"
>
</app-load-edit>

<div class="workplace-container bg-white">
  <button (click)="updateCardLists()" *ngIf="false">u</button>
  <div class="workplace-inner grid p-0">
    <div class="col grid pl-3 pt-3">
      <div class="col-12 pb-0">
        <p-button
          (onClick)="openCreateLoadDialog()"
          icon="fa fa-fw fa-plus"
          label="New Load"
          class="ui-button-secondary p-2"
        >
        </p-button>
      </div>
      <div class="col bg-white p-2 pt-0">
        <div class="col-12">
          <h2>Drivers</h2>
        </div>
        <p-scrollPanel *ngIf="mapdata" class="col-12" [style]="driversScrollStyle" styleClass="pfs-scroller">
          <app-drivercards
            #driverCards
            [drivers]="mapdata.drivers"
            (loadClicked)="openEditLoadDialog($event)"
            (trackingUpdated)="trackingUpdated($event)"
          ></app-drivercards>

          <div class="col-12">&nbsp;</div>
        </p-scrollPanel>
      </div>
      <div class="col bg-white p-2 pt-0">
        <div class="col-12 bg-white flex z-2">
          <div class="col p-0">
            <h2>Loads</h2>
          </div>
          <div class="col p-0 flex justify-content-end">
            <span
              class="filter-menu"
              *ngFor="let owner of loadFilters"
              [ngClass]="{ active: owner.active }"
              (click)="switchLoadList(owner)"
            >
              {{ owner.label }}
            </span>
          </div>
        </div>

        <p-scrollPanel *ngIf="mapdata" class="col-12" [style]="loadsScrollStyle" styleClass="pfs-scroller">
          <app-loadcards
            #loadCards
            [loads]="loadsowner == 'myloads' ? myloaddata : mapdata.loads"
            (loadClicked)="openEditLoadDialog($event)"
            (driverClicked)="showDriverOnMap($event)"
            (trackingUpdated)="trackingUpdated($event)"
          ></app-loadcards>
        </p-scrollPanel>
      </div>
    </div>
    <div class="col mapoutercontainer pb-4">
      <p-gmap
        (onMapReady)="setMap($event)"
        [options]="mapOptions"
        [style]="{ height: '100%', width: '100%' }"
        (onOverlayClick)="handleOverlayClick($event)"
      ></p-gmap>
    </div>
  </div>
</div>
