import { Component, Injector, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DtoFormBase } from '../../../../shared/FormBase';
import { ChargeDTO } from '../../../../../apiclient/models';

@Component({
  selector: 'app-form-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormChargeComponent extends DtoFormBase<ChargeDTO> implements OnInit {
  /**
   * Options:
   *   - display: Only shows content of field
   *   - readonly: Shows input(control) on read only mode
   *   - blank/not defined: Regular Input/Control mode
   * Default: blank
   */
  @Input() viewMode?: string;
  @Input() formGroup: FormGroup;
  @Input() public charge: ChargeDTO;

  private propagateChange = (_: any) => {};

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.initFormFor('ChargeDTO');
  }

  writeValue(charge: ChargeDTO): void {
    this.charge = charge;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }
}
