<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<p-dialog
  [(visible)]="displayDialog"
  blockScroll="true"
  [contentStyle]="contentDialogStyle"
  [style]="{ width: dialogWidth, height: dialogHeight, top: '0rem !important', left: '0rem !important' }"
  [modal]="true"
  [focusOnShow]="false"
  [closable]="false"
  styleClass="full-size-dialog"
  appendTo="body"
>
  <p-header>
    <div class="p-0 text-2xl font-normal">
      {{ model.name || emptyCompanyName }}
    </div>
  </p-header>
  <form [formGroup]="formGroup" (ngSubmit)="saveModel()">
    <div class="grid grid-nogutter col-12">
      <div class="grid grid-nogutter col-12 card-panel-body">
        <div class="col-4 p-2">
          <div class="grid grid-nogutter col-12 p-0">
            <div class="col-12">
              <h2>Company info</h2>
            </div>
            <div class="grid grid-nogutter col-12 p-1">
              <div class="lg:col-4 sm:col-12 p-1 font-bold">Company name</div>
              <div class="lg:col-8 sm:col-12 px-0">
                <app-form-field
                  [hidden]="formGroup.get('name').enabled"
                  [(formGroup)]="formGroup"
                  label=""
                  formControlName="name"
                  [(ngModel)]="model.name"
                  [formErrors]="formErrors"
                ></app-form-field>
                <app-company-selector
                  #companyNameSelector
                  [hidden]="!formGroup.get('name').enabled"
                  [(ngModel)]="model.companyId"
                  formControlName="companyId"
                  [forceSelection]="false"
                  [showDropdown]="false"
                  [required]="true"
                  (companyChanged)="onCompanyChange($event)"
                  [formSubmitted]="submitForm"
                ></app-company-selector>
              </div>
            </div>
            <app-form-field
              class="col-12 p-1"
              #legalNameCheck
              [(formGroup)]="formGroup"
              label="Legal name"
              type="check"
              [config]="{ checkLabel: 'Same as company name' }"
              formControlName="legalNameIsSame"
              [(ngModel)]="model.legalNameIsSame"
              [formErrors]="formErrors"
              placeholder="Required"
              labelClass="lg:col-4 sm:col-12 p-1"
              controlClass="lg:col-8 sm:col-12 py-1"
              (keydown)="legalNameCheckOnTab($event)"
            >
            </app-form-field>
            <app-form-field
              class="col-12 p-1"
              [hidden]="model.legalNameIsSame"
              [(formGroup)]="formGroup"
              label=""
              formControlName="legalName"
              [(ngModel)]="model.legalName"
              [formErrors]="formErrors"
              placeholder="Required"
              labelClass="lg:col-4 sm:col-12 p-1 "
              controlClass="lg:col-8 sm:col-12 p-0"
            >
            </app-form-field>
            <app-form-field
              class="col-12 p-1"
              #companyStatusSelect
              [(formGroup)]="formGroup"
              label="Company status"
              type="select"
              [config]="{
                selectOptions: [
                  { label: 'Active', value: 'Active' },
                  { label: 'Inactive', value: 'Inactive' }
                ]
              }"
              formControlName="status"
              [(ngModel)]="model.status"
              [formErrors]="formErrors"
              placeholder="Required"
              labelClass="lg:col-4 sm:col-12 p-1 "
              controlClass="lg:col-8 sm:col-12 p-0"
            >
            </app-form-field>
            <div class="grid grid-nogutter col-12 p-1">
              <div class="lg:col-4 sm:col-12 p-1 font-bold"> Company contact </div>
              <div class="lg:col-8 sm:col-12 p-0">
                <app-contact [(ngModel)]="model.primaryContact" [ngModelOptions]="{ standalone: true }"> </app-contact>
              </div>
            </div>
            <div class="grid grid-nogutter col-12 py-0" *ngIf="creditLimitAccess">
              <app-form-field
                class="col-12 p-1"
                *ngIf="model.creditLimitAccess"
                [(formGroup)]="formGroup"
                label=""
                type="check"
                [config]="{ checkLabel: 'Add credit limit' }"
                formControlName="creditLimitCheck"
                [(ngModel)]="creditLimitCheck"
                [formErrors]="formErrors"
                placeholder="Required"
                labelClass="lg:col-4 sm:col-12"
                controlClass="lg:col-8 sm:col-12 p-0"
              >
              </app-form-field>
              <app-form-field
                class="col-12 p-0"
                *ngIf="creditLimitCheck"
                [(formGroup)]="formGroup"
                label=""
                [config]="{ textIcon: '$' }"
                formControlName="creditLimit"
                [(ngModel)]="model.creditLimit"
                [formErrors]="formErrors"
                placeholder="Required"
                keyFilter="currency"
                labelClass="lg:col-4 sm:col-12"
                controlClass="lg:col-8 sm:col-12 pr-1 pl-0 py-1"
              >
              </app-form-field>
              <div *ngIf="creditLimitCheck" class="col-12 p-1">
                <div class="lg:col-8 lg:col-offset-4 sm:col-12 p-0">
                  ${{ model.remainingCredit | number : '1.2-2' }} remaining credit.
                </div>
              </div>
            </div>
            <div class="grid grid-nogutter col-12" *ngIf="canViewManagedAccount">
              <app-form-field
                class="col-12 p-1"
                #managedAccountCheck
                [(formGroup)]="formGroup"
                label="Managed Account"
                type="check"
                formControlName="isManagedAccount"
                [(ngModel)]="model.isManagedAccount"
                [formErrors]="formErrors"
                labelClass="lg:col-4 sm:col-12 p-1"
                controlClass="lg:col-8 sm:col-12 py-1"
                (checkChanged)="onManagedAccountCheckedChanged($event)"
                [viewMode]="canEditManagedAccount ? '' : 'readonly'"
              >
              </app-form-field>
              <app-tenant-user-selector
                class="col-12 p-1"
                label="Sales Representative"
                [roleFilter]="role.LogisticsSalesRep"
                [(formGroup)]="formGroup"
                formControlName="salesRepresentativeTenantUser"
                [(ngModel)]="model.salesRepresentativeTenantUser"
                [showClear]="true"
                (changed)="onSalesRepChange($event)"
                [readonly]="!canEditManagedAccount"
              ></app-tenant-user-selector>
              <app-tenant-user-selector
                class="col-12 p-1"
                label="Account Manager"
                [(formGroup)]="formGroup"
                [roleFilter]="role.LogisticsAccountManager"
                formControlName="accountManagerTenantUser"
                [(ngModel)]="model.accountManagerTenantUser"
                [showClear]="true"
                (changed)="onAccountManagerChange($event)"
                [readonly]="!canEditManagedAccount"
              ></app-tenant-user-selector>
            </div>
          </div>
        </div>
        <div class="col-8 p-2">
          <h2>Locations</h2>
          <app-company-address-list
            [parentVisible]="displayDialog"
            [addresses]="model.addresses"
            [companyId]="model.companyId"
            [primaryContact]="model.primaryContact"
            [creditLimitAccess]="creditLimitAccess"
            [addressId]="locationId"
            [formSubmitted]="submitForm"
            (newCreditLimitChanged)="onNewCreditLimitChanged($event)"
            [collapsed]="addressesCollapsed"
            [selectedIndex]="selectedIndex"
            (creditLimitError)="onCreditLimitError($event)"
            (doneUpdatesEvent)="doneUpdatesHandler()"
            (debtorSelectedEvent)="onDebtorSelected($event)"
            (companyAddressMergeEvent)="onCompanyAddressMerge($event)"
          ></app-company-address-list>
        </div>
      </div>
    </div>
  </form>

  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      (click)="cancel()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      form="htmlformGroup"
      (click)="saveModel()"
      [disabled]="showProgressOverlay"
      [label]="isNewRecord ? 'Create' : 'Update'"
    ></button>
  </p-footer>
</p-dialog>
