<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<form class="grid w-full sm:w-9 md:w-6 lg:w-4 m-auto" [formGroup]="formGroup">
  <div class="col-12 pb-4">
    <h1>Verify Email</h1>
  </div>

  <div class="col-12">
    <div class="ui-float-label py-2">
      <input
        class="w-full"
        id="verifyemail-code"
        type="text"
        pInputText
        [(ngModel)]="model.code"
        formControlName="code"
      />
      <label for="verifyemail-code">Confirmation Code</label>
    </div>
    <span class="formerrors">{{ formErrors.code }}</span>
  </div>

  <div class="col-6">
    <button
      pButton
      class="w-full"
      type="submit"
      label="Verify Email"
      icon="fa fa-check"
      (click)="verifyEmail()"
    ></button>
  </div>
  <div class="col-6">
    <button
      pButton
      class="w-full ui-button-secondary"
      type="submit"
      label="Resend Code"
      icon="fa fa-question-circle"
      (click)="resendCode()"
    ></button>
  </div>
</form>
