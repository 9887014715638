<p-multiSelect
  [options]="options"
  [(ngModel)]="selected"
  [filter]="true"
  inputId="id"
  dataKey="id"
  optionLabel="name"
  [style]="{ width: '100%' }"
  (onChange)="onChange()"
></p-multiSelect>
