import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  OnChanges,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { State } from '../../../../services/states-service';

function customValidator(required) {
  return (c: FormControl) => {
    // if there is a value => valid, otherwise error
    if (required) {
      return c.value ? null : { required: true };
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'app-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true,
    } /*,
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true
    }*/,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements OnInit, ControlValueAccessor, OnChanges {
  constructor(private cdRef: ChangeDetectorRef) {}
  @ViewChild('inputControl')
  public inputControl: ElementRef;

  @ViewChild('dropdownControl')
  public dropdownControl: Dropdown;

  @ViewChild('textAreaControl')
  public textAreaControl: ElementRef;

  @ViewChild('checkboxControl')
  public checkboxControl: ElementRef;

  @Input() formGroup: FormGroup;
  @Input() label: string;
  @Input() autoComplete: string = '';
  @Input() secondaryLabel: string = '';
  @Input() type: string = 'text';
  @Input() nullable: boolean = true;
  @Input() formControlName: string;
  @Input() formErrors: any = {};
  @Input() placeholder: any;
  @Input() tooltip: string;
  @Input() config: any = {};
  defaultConfig = {
    displayBold: true,
    displayTextIcon: true,
    boldLabel: true,
    yearRange: (new Date().getFullYear() - 5).toString() + ':' + (new Date().getFullYear() + 5).toString(),
  };
  @Input() selectOptions: any;
  @Input() autoDisplayFirst: boolean;
  @Input() keyFilter: string = 'text';
  @Input() additionalValidation: any;
  /**
   * Options:
   *   - display: Only shows content of field
   *   - readonly: Shows input(control) on read only mode
   *   - blank/not defined: Regular Input/Control mode
   * Default: blank
   */
  @Input() viewMode?: 'display' | 'readonly';

  @Input() labelClass: string;
  @Input() controlClass: string;
  @Input() controlClassLevel1: string = 'col-12';
  @Input() errorClass: string = 'col-12';
  @Input() errorUnderInput: boolean = false;
  @Output() checkChanged = new EventEmitter<boolean>();
  @Output() radioChanged = new EventEmitter();
  @Output() inputChanged = new EventEmitter<string>();
  @Output() dropdownChanged = new EventEmitter<string>();
  @Output() stateChanged = new EventEmitter();
  @Output() dateChanged = new EventEmitter();
  @Output() inputBlur = new EventEmitter();

  _model: any;

  checkLabel: string = '';
  checkLabelClass: string = '';
  isIcon: boolean = false;
  faIcon: string = '';
  textIcon: string = '';
  showCustomError: boolean = false;

  propagateChange: any = () => {};
  validateFn: any = () => {};

  ngOnInit() {
    if (this.additionalValidation) {
      if (this.additionalValidation.required && this.additionalValidation.required.isRequired) {
        this.formGroup.controls[this.formControlName].setValidators([Validators.required, Validators.required]);
        this.showCustomError = true;
      }
    }
  }

  /**
   * When Input properties changes
   * @param inputs
   */
  ngOnChanges(inputs) {
    this.validateFn = customValidator(inputs.required || false);
    this.config = Object.assign(this.defaultConfig, this.config);
    if (this.config) {
      if (this.config.selectOptions) {
        this.selectOptions = this.config.selectOptions;
      }
      if (this.config.checkLabel) {
        this.checkLabel = this.config.checkLabel;
      }
      if (this.config.checkLabelClass) {
        this.checkLabelClass = this.config.checkLabelClass;
      }
      if (this.config.faIcon) {
        this.faIcon = this.config.faIcon;
        this.isIcon = true;
      }
      if (this.config.textIcon) {
        this.textIcon = this.config.textIcon;
        this.isIcon = true;
      }
    }

    if (this.formGroup.controls[this.formControlName] && !this.formGroup.controls[this.formControlName].invalid) {
      this.showCustomError = false;
    }
  }
  onChangeSelect(event: any) {
    this._model = event.value;
    this.cdRef.detectChanges();
    this.dropdownChanged.emit(event.value);
  }
  onChangeCheck(event: boolean) {
    this.checkChanged.emit(event);
    this.cdRef.detectChanges();
  }
  onRadioChange() {
    this.radioChanged.emit();
    this.cdRef.detectChanges();
  }
  onTextChanged($event: any) {
    this.value = $event.target.value;
    setTimeout((_) => this.inputChanged.emit(this.value));
  }
  onNumberChanged(inputValue: number) {
    this.value = inputValue;
    if (!this.nullable) {
      this.value = this.value ? this.value : 0;
    }
    this.inputChanged.emit(this.value);
  }
  onStateChanged($event: State): void {
    this.stateChanged.emit($event);
  }
  onBlur($event: any) {
    this.inputBlur.emit($event);
  }
  onDateChanged() {
    this.dateChanged.emit(this.value);
  }

  /**
   * Set initial component value (dont propagate on initial value)
   * @param value
   */
  writeValue(value) {
    if (this.keyFilter === 'currency' && value) {
      value = parseFloat(value).toFixed(2);
    }
    if (this.type === 'calendar') {
      if (value) {
        if (value && !(value instanceof Date)) {
          value = new Date(value);
        }
      }
    }
    if (this._model !== value) {
      this._model = value;
      // this.cdRef.detectChanges(); // this was causing problematic errors on load screen - revisit if necessary, but don't think this is needed
      setTimeout((_) => this.propagateChange(this.value));
    }
  }

  /**
   * Automatically called each time propagateChange() is invoked
   * @param c
   * @returns {any}
   */
  validate(c: FormControl) {
    return this.validateFn(c);
  }

  // required by ControlValueAccessor
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

  get value() {
    return this._model;
  }

  set value(value) {
    if (this.keyFilter === 'currency' && value) {
      value = parseFloat(value).toFixed(2);
    }

    if (this.type === 'calendar') {
      if (value) {
        if (value && !(value instanceof Date)) {
          value = new Date(value);
        }
      }
    }

    if (this._model !== value) {
      if (this.showCustomError) {
        if (!this.formGroup.controls[this.formControlName].invalid) {
          this.showCustomError = false;
        }
      }
      this._model = value;
      this.cdRef.detectChanges();
      setTimeout((_) => {
        this.propagateChange(this.value);
        if (this.formErrors[this.formControlName] === 'undefined ') {
          this.formErrors[this.formControlName] = '';
        }
      });
    }
  }

  showErrors() {
    const control = this.formGroup.controls[this.formControlName];
    if (control && control.touched) {
      if (this.showCustomError && !this._model) {
        return this.additionalValidation.required.message;
      }

      // Make sure error text is not null or undefined
      if (!this.formErrors[this.formControlName]) {
        this.formErrors[this.formControlName] = '';
      }

      return this.formErrors[this.formControlName];
    }
    return '';
  }

  selectLabel(value) {
    const found = this.selectOptions.find((x) => x.value === value);
    if (found) {
      return found.label;
    }
    return value;
  }

  radioLabel(value) {
    value = value ? value.toString() : value;

    const found = this.selectOptions.find((x) => x.value.toString() === value);
    if (found) {
      return found.label;
    }
    return value;
  }
}
