<div class="flex justify-content-between w-12 pt-2">
  <div class="w-2 min-w-min">
    <button
      *ngIf="displayPrevious"
      id="previous-button"
      type="button"
      pButton
      label="Previous"
      class="ui-button-secondary w-12"
      (click)="previous()"
      tabindex="1"
    ></button>
  </div>
  <div class="w-2 min-w-min">
    <button
      id="next-button"
      type="button"
      pButton
      class="w-12"
      [disabled]="disableNext"
      [label]="nextButtonText"
      (click)="next()"
      tabindex="0"
    ></button>
  </div>
</div>
