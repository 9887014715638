<p-blockUI [blocked]="showProgressOverlay">
  <div class="middle">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>

<form class="grid w-full sm:w-9 md:w-6 lg:w-4 m-auto" [formGroup]="formGroup">
  <div class="col-12 pb-4">
    <h1>Forgot Password</h1>
  </div>

  <div class="col-12 p-2">
    <div class="ui-float-label p-2">
      <input
        class="w-full"
        id="forgotpass-email"
        [attr.valexists]="model.username ? 'true' : null"
        [attr.autofocus]="model.username ? '' : null"
        type="text"
        pInputText
        [(ngModel)]="model.username"
        formControlName="username"
      />
      <label for="forgotpass-email">Email Address</label>
    </div>
    <span class="formerrors">{{ formErrors.username }}</span>
  </div>

  <div class="col-6">
    <div class="forgotpassbuttons">
      <button
        pButton
        class="w-full"
        type="submit"
        label="Send Code"
        icon="fa fa-check"
        (click)="startForgotPassword()"
      ></button>
    </div>
  </div>
  <div class="col-6">
    <div class="forgotpassbuttons">
      <button
        pButton
        class="w-full ui-button-secondary"
        type="submit"
        label="Cancel"
        icon="fa fa-ban"
        (click)="cancelForgotPassword()"
      ></button>
    </div>
  </div>
</form>
