import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppUpdateService {
  public needsUpdate: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  constructor(updates: SwUpdate) {
    updates.available.subscribe((event) => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      this.needsUpdate.next(true);
    });
    updates.activated.subscribe((event) => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
      this.needsUpdate.next(false);
    });
  }
}
