<div [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'display'">
    <div class="grid grid-nogutter col-12 p-0">
      <div class="col-5 px-0 label-black">
        {{ charge.description }}
      </div>
      <div class="col-7 p-2 pl-5 label-black">
        {{ charge.amount | currency }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div [formGroup]="formGroup" class="grid grid-nogutter col-12 p-0">
      <div class="col-6 p-0">
        <app-form-field
          [formGroup]="formGroup"
          formControlName="description"
          [formErrors]="formErrors"
          [(ngModel)]="charge.description"
          controlClass="col-12 py-1 px-0 input-box-2x"
        >
        </app-form-field>
      </div>
      <div class="col-6 p-0">
        <app-form-field
          [formGroup]="formGroup"
          formControlName="amount"
          [config]="{ textIcon: '$' }"
          keyFilter="currency"
          placeholder="0.00"
          [formErrors]="formErrors"
          [(ngModel)]="charge.amount"
          controlClass="col-12 py-1 input-box-2x noPaddingInputGroup"
        >
        </app-form-field>
      </div>
    </div>
  </ng-container>
</div>
