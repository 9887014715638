import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TempUser } from 'src/apiclient/v1.1/models';
import { TempUserService } from 'src/apiclient/v1.1/services';
import { AppConfig } from 'src/app/config/app.config';
import { ClientTokenService } from 'src/services/client-token-service';
import { FormSanitizationService } from 'src/services/form-sanitization/form-sanitization.service';
import { FormValidationErrorsService } from 'src/services/form-validation-errors/form-validation-errors.service';
import { FormValidationService } from 'src/services/form-validation-service/form-validation.service';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  public signUpForm = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    phoneNumber: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
  });
  public formErrors: object;
  public formSubmitted: boolean = false;
  public readonly supportLink = Utils.supportLink;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private tempUserService: TempUserService,
    private authService: ClientTokenService,
    private formValidationErrorsService: FormValidationErrorsService,
    private formValidationService: FormValidationService,
    private formSanitizationService: FormSanitizationService
  ) {}

  public ngOnInit(): void {
    if (this.authService.auth()) {
      this.router.navigate([AppConfig.routes.userRegistration]);
    }

    this.signUpForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.formErrors = this.formValidationErrorsService.getFormErrors(this.signUpForm)));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public signUp(): void {
    if (this.validateForm()) {
      this.formSanitizationService.sanitizeForm(this.signUpForm);

      const tempUser: TempUser = {
        ...this.signUpForm.value,
      };

      this.tempUserService.Create(tempUser).pipe(takeUntil(this.destroy$)).subscribe();

      this.formSubmitted = true;
    }
  }

  public routeToLogin(): void {
    this.router.navigate([AppConfig.routes.login.default]);
  }

  private validateForm(): boolean {
    const isFormValid = this.formValidationService.validateForm(this.signUpForm);
    this.formErrors = this.formValidationErrorsService.getFormErrors(this.signUpForm);

    return isFormValid;
  }
}
