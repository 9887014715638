<p-panel>
  <p-header>Reporting Wizard</p-header>

  <div class="grid" [formGroup]="formGroup">
    <div class="lg:col-4 md:col-12">
      <app-reporting-wizard-filter #filterForm formControlName="filter"></app-reporting-wizard-filter>
    </div>
    <div class="lg:col-8 md:col-12">
      <app-reporting-wizard-options formControlName="columns"></app-reporting-wizard-options>
    </div>
  </div>

  <p-footer>
    <div class="flex justify-content-end align-items-center">
      <button
        pButton
        type="button"
        id="download-csv"
        label="Download CSV"
        [disabled]="reportInProgress"
        (click)="download()"
      ></button>
    </div>
  </p-footer>
</p-panel>
