import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistrationFields } from '../../registration-fields';
import { WizardFormBase } from '../wizard-form-base';

@Component({
  selector: 'app-company-information-form',
  templateUrl: './company-information-form.component.html',
  styleUrls: ['./company-information-form.component.scss', '../../user-registration-wizard.component.scss'],
})
export class CompanyInformationFormComponent extends WizardFormBase implements OnInit {
  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.initFormGroup(
      this.formBuilder.group({
        [RegistrationFields.CompanyLegalName]: ['', [Validators.required]],
        [RegistrationFields.Phone]: [this.tempUser.phoneNumber, [Validators.required]],
        [RegistrationFields.PhoneExtension]: [null, [Validators.maxLength(15)]],
        [RegistrationFields.NumberOfTrucks]: [null, [Validators.required]],
        [RegistrationFields.UsDotNumber]: [null, [Validators.required, Validators.maxLength(7)]],
        [RegistrationFields.MotorCarrierNumber]: [null, [Validators.maxLength(7)]],
      })
    );
  }
}
