/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyAddressDTO } from '../models/company-address-dto';
import { CompanyAddressQuickAddDTO } from '../models/company-address-quick-add-dto';
import { CompanyAddressQuickAddV2DTO } from '../models/company-address-quick-add-v2dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
@Injectable({
  providedIn: 'root',
})
class CompanyAddressService extends __BaseService {
  static readonly ApiCompanyAddressGetPath = '/api/CompanyAddress';
  static readonly ApiCompanyAddressPostPath = '/api/CompanyAddress';
  static readonly ApiCompanyAddressByIdGetPath = '/api/CompanyAddress/{id}';
  static readonly ApiCompanyAddressByIdPutPath = '/api/CompanyAddress/{id}';
  static readonly ApiCompanyAddressByIdDeletePath = '/api/CompanyAddress/{id}';
  static readonly ApiCompanyAddressQuickAddPostPath = '/api/CompanyAddress/QuickAdd';
  static readonly ApiCompanyAddressQuickAddV2PostPath = '/api/CompanyAddress/QuickAddV2';
  static readonly ApiCompanyAddressGetForCompanyByIdGetPath = '/api/CompanyAddress/GetForCompany/{id}';
  static readonly ApiCompanyAddressSearchByCompanyIdByTermGetPath = '/api/CompanyAddress/search/{companyId}/{term}';
  static readonly ApiCompanyAddressSearchBilltoByCompanyIdByTermGetPath = '/api/CompanyAddress/searchBillto/{companyId}/{term}';
  static readonly ApiCompanyAddressSearchBilltoV2ByTermGetPath = '/api/CompanyAddress/searchBilltoV2/{term}';
  static readonly ApiCompanyAddressSearchShipperByCompanyIdByTermGetPath = '/api/CompanyAddress/searchShipper/{companyId}/{term}';
  static readonly ApiCompanyAddressSearchShipperV2ByTermGetPath = '/api/CompanyAddress/searchShipperV2/{term}';
  static readonly ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetPath = '/api/CompanyAddress/searchConsignee/{companyId}/{term}';
  static readonly ApiCompanyAddressSearchConsigneeV2ByTermGetPath = '/api/CompanyAddress/searchConsigneeV2/{term}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of company addresses locations
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyAddressGetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanyAddressDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyAddressDTO>>;
      })
    );
  }
  /**
   * Summary list of company addresses locations
   * @param Authorization Bearer {token}
   * @return Success
   */
  ApiCompanyAddressGet(Authorization: string): __Observable<Array<CompanyAddressDTO>> {
    return this.ApiCompanyAddressGetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanyAddressDTO>)
    );
  }

  /**
   * Create a new CompanyAddress location
   * @param params The `CompanyAddressService.ApiCompanyAddressPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressPostResponse(params: CompanyAddressService.ApiCompanyAddressPostParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CompanyAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * Create a new CompanyAddress location
   * @param params The `CompanyAddressService.ApiCompanyAddressPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressPost(params: CompanyAddressService.ApiCompanyAddressPostParams): __Observable<CompanyAddressDTO> {
    return this.ApiCompanyAddressPostResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * CompanyAddress location detail
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressByIdGetResponse(params: CompanyAddressService.ApiCompanyAddressByIdGetParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * CompanyAddress location detail
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressByIdGet(params: CompanyAddressService.ApiCompanyAddressByIdGetParams): __Observable<CompanyAddressDTO> {
    return this.ApiCompanyAddressByIdGetResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * Update an existing CompanyAddress
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiCompanyAddressByIdPutResponse(params: CompanyAddressService.ApiCompanyAddressByIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing CompanyAddress
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   */
  ApiCompanyAddressByIdPut(params: CompanyAddressService.ApiCompanyAddressByIdPutParams): __Observable<null> {
    return this.ApiCompanyAddressByIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyAddressByIdDeleteResponse(params: CompanyAddressService.ApiCompanyAddressByIdDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `CompanyAddressService.ApiCompanyAddressByIdDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  ApiCompanyAddressByIdDelete(params: CompanyAddressService.ApiCompanyAddressByIdDeleteParams): __Observable<null> {
    return this.ApiCompanyAddressByIdDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a new minimum company address record
   * @param params The `CompanyAddressService.ApiCompanyAddressQuickAddPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressQuickAddPostResponse(params: CompanyAddressService.ApiCompanyAddressQuickAddPostParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CompanyAddress/QuickAdd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * Create a new minimum company address record
   * @param params The `CompanyAddressService.ApiCompanyAddressQuickAddPostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressQuickAddPost(params: CompanyAddressService.ApiCompanyAddressQuickAddPostParams): __Observable<CompanyAddressDTO> {
    return this.ApiCompanyAddressQuickAddPostResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * Create a new minimum company and CompanyAddress record
   * @param params The `CompanyAddressService.ApiCompanyAddressQuickAddV2PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressQuickAddV2PostResponse(params: CompanyAddressService.ApiCompanyAddressQuickAddV2PostParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.dto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CompanyAddress/QuickAddV2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * Create a new minimum company and CompanyAddress record
   * @param params The `CompanyAddressService.ApiCompanyAddressQuickAddV2PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `dto`:
   *
   * @return Success
   */
  ApiCompanyAddressQuickAddV2Post(params: CompanyAddressService.ApiCompanyAddressQuickAddV2PostParams): __Observable<CompanyAddressDTO> {
    return this.ApiCompanyAddressQuickAddV2PostResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * Get the CompanyAddress locations for a company
   * @param params The `CompanyAddressService.ApiCompanyAddressGetForCompanyByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressGetForCompanyByIdGetResponse(params: CompanyAddressService.ApiCompanyAddressGetForCompanyByIdGetParams): __Observable<__StrictHttpResponse<Array<CompanyAddressDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/GetForCompany/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyAddressDTO>>;
      })
    );
  }
  /**
   * Get the CompanyAddress locations for a company
   * @param params The `CompanyAddressService.ApiCompanyAddressGetForCompanyByIdGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressGetForCompanyByIdGet(params: CompanyAddressService.ApiCompanyAddressGetForCompanyByIdGetParams): __Observable<Array<CompanyAddressDTO>> {
    return this.ApiCompanyAddressGetForCompanyByIdGetResponse(params).pipe(
      __map(_r => _r.body as Array<CompanyAddressDTO>)
    );
  }

  /**
   * Searches for CompanyAddress by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchByCompanyIdByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchByCompanyIdByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/search/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for CompanyAddress by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchByCompanyIdByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchByCompanyIdByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchByCompanyIdByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company bill-to addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchBilltoByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchBilltoByCompanyIdByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchBilltoByCompanyIdByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchBillto/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company bill-to addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchBilltoByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchBilltoByCompanyIdByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchBilltoByCompanyIdByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchBilltoByCompanyIdByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company bill-to addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchBilltoV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchBilltoV2ByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchBilltoV2ByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchBilltoV2/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company bill-to addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchBilltoV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchBilltoV2ByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchBilltoV2ByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchBilltoV2ByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchShipperByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchShipperByCompanyIdByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchShipperByCompanyIdByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchShipper/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchShipperByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchShipperByCompanyIdByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchShipperByCompanyIdByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchShipperByCompanyIdByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchShipperV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchShipperV2ByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchShipperV2ByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchShipperV2/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchShipperV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchShipperV2ByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchShipperV2ByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchShipperV2ByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchConsignee/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchConsigneeByCompanyIdByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchConsigneeV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchConsigneeV2ByTermGetResponse(params: CompanyAddressService.ApiCompanyAddressSearchConsigneeV2ByTermGetParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchConsigneeV2/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.ApiCompanyAddressSearchConsigneeV2ByTermGetParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  ApiCompanyAddressSearchConsigneeV2ByTermGet(params: CompanyAddressService.ApiCompanyAddressSearchConsigneeV2ByTermGetParams): __Observable<Array<IdNamePairDTO>> {
    return this.ApiCompanyAddressSearchConsigneeV2ByTermGetResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }
}

module CompanyAddressService {

  /**
   * Parameters for ApiCompanyAddressPost
   */
  export interface ApiCompanyAddressPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyAddressDTO;
  }

  /**
   * Parameters for ApiCompanyAddressByIdGet
   */
  export interface ApiCompanyAddressByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressByIdPut
   */
  export interface ApiCompanyAddressByIdPutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyAddressDTO;
  }

  /**
   * Parameters for ApiCompanyAddressByIdDelete
   */
  export interface ApiCompanyAddressByIdDeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressQuickAddPost
   */
  export interface ApiCompanyAddressQuickAddPostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyAddressQuickAddDTO;
  }

  /**
   * Parameters for ApiCompanyAddressQuickAddV2Post
   */
  export interface ApiCompanyAddressQuickAddV2PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    dto?: CompanyAddressQuickAddV2DTO;
  }

  /**
   * Parameters for ApiCompanyAddressGetForCompanyByIdGet
   */
  export interface ApiCompanyAddressGetForCompanyByIdGetParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchByCompanyIdByTermGet
   */
  export interface ApiCompanyAddressSearchByCompanyIdByTermGetParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchBilltoByCompanyIdByTermGet
   */
  export interface ApiCompanyAddressSearchBilltoByCompanyIdByTermGetParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchBilltoV2ByTermGet
   */
  export interface ApiCompanyAddressSearchBilltoV2ByTermGetParams {
    term: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchShipperByCompanyIdByTermGet
   */
  export interface ApiCompanyAddressSearchShipperByCompanyIdByTermGetParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchShipperV2ByTermGet
   */
  export interface ApiCompanyAddressSearchShipperV2ByTermGetParams {
    term: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchConsigneeByCompanyIdByTermGet
   */
  export interface ApiCompanyAddressSearchConsigneeByCompanyIdByTermGetParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ApiCompanyAddressSearchConsigneeV2ByTermGet
   */
  export interface ApiCompanyAddressSearchConsigneeV2ByTermGetParams {
    term: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { CompanyAddressService }
