import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface State {
  name: string;
  abbr: string;
  country: string;
}

@Injectable()
export class StatesService {
  states: State[] = null;

  constructor(private http: HttpClient) {}

  public async getStates(): Promise<State[]> {
    if (this.states == null) {
      const res = await this.http.get<any>('assets/json/states.json').toPromise();
      this.states = <State[]>res.data;
    }

    return this.states;
  }

  public async getStateByAbbreviation(abbr: string): Promise<State> {
    const states = await this.getStates();
    return states.find((s) => s.abbr === abbr);
  }
}
