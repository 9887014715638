import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '../components/notifications/notifications.service';
import { LoggingService } from '../../app/core/logging.service';
import { Injector } from '@angular/core';
import { ClientTokenService } from '../../services/client-token-service';

/**
 * This logic is used all over the place. Needed a common place to store it
 */
export abstract class HandleErrorBase {
  protected logging: LoggingService;
  protected _NS: NotificationsService;
  protected clientToken: ClientTokenService;

  constructor(protected injector: Injector) {
    this.logging = this.injector.get(LoggingService);
    this._NS = this.injector.get(NotificationsService);
    this.clientToken = this.injector.get(ClientTokenService);
  }

  // Used as a call back so must implement w/ fat arrow
  protected handleBasicError = (error: HttpErrorResponse): Observable<never> => {
    if (error.status === 400) {
      this.logging.logBadRequest(error);
    } else {
      this.logging.logError(error);
    }

    switch (error.status) {
      case 400:
        this._NS.error('Bad Request', 'Check for validation errors (403)');
        break;
      case 403:
        if (error.error === 'REVOKED') {
          this._NS.error('Forbidden', 'Session has been revoked, please sign in again.');
          this.clientToken.revokeToken();
        } else {
          this._NS.error('Forbidden', 'Operation not allowed (403)');
        }
        break;
      case 404:
        this._NS.error('No Found', 'Requested resource was not found (404)');
        break;
      case 500:
        this._NS.error('Error', 'Internal Server Error (500)');
        break;
      default:
        this.logging.logError(new Error('Unhandled error code: ' + error.status));
        this._NS.error('Error', 'Unknown server error (' + error.status + ')');
        break;
    }

    return throwError(error);
  };
}
