import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/apiclient/v1.1/services';
import { ErrorHandlingService } from 'src/services/error-handling-service/error-handling-service';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { SetNewPasswordFormFields } from './set-new-password-form-fields';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { SetNewPasswordDTO, TokenResponseDTO } from 'src/apiclient/v1.1/models';
import { ClientTokenService } from 'src/services/client-token-service';
import { NavigationService } from 'src/services/navigation/navigation.service';

@Component({
  selector: 'app-setnewpassword',
  templateUrl: './setnewpassword.component.html',
  styleUrls: ['./setnewpassword.component.scss'],
})
export class SetnewpasswordComponent implements OnInit, OnDestroy {
  public setNewPasswordForm: FormGroup;
  public showProgressOverlay: boolean;
  public isFormValid$: Observable<boolean>;

  private setNewPasswordModel: SetNewPasswordDTO;
  private destroy$ = new Subject<void>();

  constructor(
    private clientTokenService: ClientTokenService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationsService,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.setNewPasswordModel = {
        username: params['userandtoken'].split(',')[0],
        session: params['userandtoken'].split(',')[1],
      } as SetNewPasswordDTO;
    });

    this.setNewPasswordForm = this.formBuilder.group({
      [SetNewPasswordFormFields.Password]: ['', [Validators.required]],
      [SetNewPasswordFormFields.ConfirmPassword]: ['', [Validators.required]],
      [SetNewPasswordFormFields.PasswordsMatch]: [false, [Validators.requiredTrue]],
    });

    this.isFormValid$ = this.setNewPasswordForm.valueChanges.pipe(
      startWith(false),
      map(() => this.setNewPasswordForm.valid),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setNewPassword(): void {
    this.showProgressOverlay = true;

    this.setNewPasswordModel.password = this.setNewPasswordForm.controls[SetNewPasswordFormFields.Password].value;

    this.authenticationService
      .SetNewPassword(this.setNewPasswordModel)
      .pipe(
        tap((result: TokenResponseDTO) => {
          this.clientTokenService.setToken(result);
          this.notificationService.success('Success!', 'Password has been changed.');
          this.navigationService.navigateToDefaultDashboard();
        }),
        catchError((error) => this.errorHandlingService.handleError(error)),
        finalize(() => (this.showProgressOverlay = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
