import {
  Component,
  Input,
  Injector,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { LegComponent } from './leg/leg.component';
import { LoadLegDTO, LoadLegStopDTO } from 'src/apiclient/v1.1/models';
import { FeatureFlags } from 'src/app/data/feature-flags';

@Component({
  selector: 'app-legs',
  templateUrl: './legs.component.html',
  styleUrls: ['./legs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LegsComponent),
      multi: true,
    },
  ],
})
export class LegsComponent extends DtoFormBase<LoadLegDTO> implements ControlValueAccessor, OnChanges {
  @ViewChildren(LegComponent) legsComponent: QueryList<LegComponent>;
  @Input() loadId: number;
  @Input() loadNumber: number;
  @Input() loadStatus: string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  public legs: LoadLegDTO[] = [];
  public readonly isAutomaticMilesCalculationFeatureEnabled = this.clientToken.isFeatureFlagEnabled(
    FeatureFlags.AutomaticMilesCalculation
  );

  constructor(protected injector: Injector) {
    super(injector);
  }

  private propagateChange = (_: any) => {};

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.legsModelChanged();
  }

  private legsModelChanged() {
    this.propagateChange(this.legs);
  }

  writeValue(legsObj: Array<LoadLegDTO>): void {
    this.legs = legsObj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // Touch event not handled atm
  }

  public addLeg(): void {
    const newLeg = {
      loadedMiles: 0,
      overrideLoadedMiles: !this.isAutomaticMilesCalculationFeatureEnabled,
      driverUser: {},
      otherCharges: [],
      advances: [],
      stops: [],
    } as LoadLegDTO;

    if (!this.legs[this.legs.length - 1].stops || this.legs[this.legs.length - 1].stops.length < 1) {
      this._NS.warn('No previous drop', 'Previous leg must end with a drop.');
      return;
    }

    const lastStop: LoadLegStopDTO = Object.assign(
      {} as LoadLegStopDTO,
      this.legs[this.legs.length - 1].stops[this.legs[this.legs.length - 1].stops.length - 1]
    );

    if (lastStop.stopType !== 'Drop') {
      this._NS.warn('No previous drop', 'Previous leg must end with a drop.');
      return;
    }

    // lastStop should be the last Drop on the previous leg
    lastStop.loadLegStopId = 0;
    lastStop.stopType = 'Pick';
    newLeg.stops.push(lastStop);
    this.legs.push(newLeg);
    this.clearPrevLoadedMiles();
    this.legsModelChanged();
  }

  clearPrevLoadedMiles() {
    const legs = this.legsComponent.map((obj) => obj);
    for (let i = 0; i < this.legs.length - 1; i++) {
      this.legs[i].loadedMiles = 0;
      legs[i].setLoadMilesHighlight();
    }
  }
  removeLeg(leg) {
    const index = this.legs.indexOf(leg);
    this.legs.splice(index, 1);
    this.legsModelChanged();
  }

  dropStop($event: any, idx: number) {
    if (!this.legs[idx].stops) {
      this.legs[idx].stops = new Array();
    }
    this.legs[idx].stops.push($event.dragData);
    this.legsModelChanged();
  }

  legChanged($event, idx) {
    setTimeout((_) => {
      this.legs[idx] = $event;
      this.legsModelChanged();
    });
  }

  getLegStopLocations(leg: LoadLegDTO) {
    let locationsStr = '';

    if (leg.stops) {
      leg.stops.forEach(
        (s) =>
          (locationsStr +=
            (locationsStr.length > 0 ? ' > ' : '') +
            (!s.address.city && !s.address.state
              ? 'TBD'
              : (s.address.city ? s.address.city : 'TBD') + ', ' + (s.address.state ? s.address.state : 'TBD')))
      );
    }
    return locationsStr;
  }
}
