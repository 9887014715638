import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-debug-formgroup',
  templateUrl: './formgroup.component.html',
  styleUrls: ['./formgroup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DebugFormGroupComponent implements OnInit {
  @Input() formGroup: FormGroup;
  invalidControls: any;
  invalidControlsShowSidebar = false;

  controls: any;
  controlsShowSidebar = false;

  constructor() {}

  ngOnInit() {}

  findInvalidControls() {
    this.invalidControls = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalidControls.push(name);
      }
    }
    this.invalidControlsShowSidebar = true;
  }

  findControls() {
    this.controls = [];
    const controls = this.formGroup.controls;
    // tslint:disable-next-line:forin
    for (const name in controls) {
      this.controls.push(name);
    }
    this.controlsShowSidebar = true;
  }
}
