<button type="button" (click)="findControls()"> List All Controls </button>
<button (click)="findInvalidControls()"> List Invalid Controls </button>

<p-sidebar [(visible)]="controlsShowSidebar" appendTo="body" position="right" blockScroll="true">
  <ul>
    <li *ngFor="let control of controls"> {{ control }} </li>
  </ul>
</p-sidebar>

<p-sidebar [(visible)]="invalidControlsShowSidebar" appendTo="body" position="right" blockScroll="true">
  <ul>
    <li *ngFor="let control of invalidControls"> {{ control }} </li>
  </ul>
</p-sidebar>
