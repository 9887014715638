import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistrationFields } from '../../registration-fields';
import { WizardFormBase } from '../wizard-form-base';

@Component({
  selector: 'app-eula-form',
  templateUrl: './eula-form.component.html',
  styleUrls: ['./eula-form.component.scss', '../../user-registration-wizard.component.scss'],
})
export class EulaFormComponent extends WizardFormBase implements OnInit, AfterViewInit {
  @ViewChild('bottomOfScrollView') public bottomOfScrollView: ElementRef;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.initFormGroup(
      this.formBuilder.group({
        [RegistrationFields.AcceptEula]: [false, [Validators.requiredTrue]],
      })
    );
  }

  public ngAfterViewInit() {
    const self = this;
    const observer = new IntersectionObserver(function (entities: IntersectionObserverEntry[]) {
      if (entities && entities[0].isIntersecting) {
        self.acceptEula();
      }
    });
    observer.observe(this.bottomOfScrollView.nativeElement);
  }

  public disableNext(): boolean {
    return !this.wizardStep.stepForm.controls[RegistrationFields.AcceptEula].value;
  }

  public acceptEula(): void {
    this.wizardStep.stepForm.controls[RegistrationFields.AcceptEula].setValue(true);
  }
}
