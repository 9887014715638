import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MapViewLoadDTO } from '../../../../apiclient/models';
import { ViewModes } from 'src/app/shared/load/components/edit/legs/leg/leg-tracking/leg-tracking.component';
import * as moment from 'moment';

@Component({
  selector: 'app-loadcards',
  templateUrl: './loadcards.component.html',
  styleUrls: ['./loadcards.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadcardsComponent {
  trackingViewMode: string = ViewModes.LoadCard;
  @Input() loads: MapViewLoadDTO[];
  @Output() loadClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() driverClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() trackingUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  doChangeDetection() {
    this.cdr.detectChanges();
  }

  loadNumClicked(loadId) {
    this.loadClicked.emit(loadId);
  }

  driverNameClicked(truckNumber) {
    this.driverClicked.emit(truckNumber);
  }
  getFormatedDateLong(loadDate) {
    return moment.utc(loadDate).local().format('MM/DD/YYYY h:mm:ss A');
  }
  updateTracking(loadId) {
    this.trackingUpdated.emit(loadId);
  }
}
