import { Injectable } from '@angular/core';
import { UserFactoringService } from '../../apiclient/v1.1/services';
import { ClientTokenService } from '../client-token-service';
import { catchError, tap } from 'rxjs/operators';
import { Utils } from '../../utils/utils';
import { of } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class FactoringLoginService {
  private readonly factorCloudLoginUrl = EnvironmentService.environment.factorCloudLoginUrl;

  constructor(
    private userFactoringService: UserFactoringService,
    private clientTokenService: ClientTokenService,
    private utils: Utils
  ) {}

  public logInToFactorCloud(): void {
    this.userFactoringService
      .GetFactorCloudSSOLoginUrl(this.clientTokenService.auth())
      .pipe(
        tap((loginUrl) => this.utils.openExternalLinkInNewTab(loginUrl)),
        catchError(() => of(this.utils.openExternalLinkInNewTab(this.factorCloudLoginUrl)))
      )
      .subscribe();
  }
}
