import { FormControl } from '@angular/forms';
import { FullAddressDTO } from 'src/apiclient/v1.1/models';

export class AddressV3Validator {
  public static required(control: FormControl) {
    const value: FullAddressDTO = control.value;

    const requiredFieldsProvided = value?.line1 && value?.city && value?.stateOrTerritory && value?.postalCode;

    if (!requiredFieldsProvided) {
      return { required: true };
    }

    const isZipValid = AddressV3Validator.getCurrentZipPattern(value?.country).test(value?.postalCode);

    return isZipValid ? null : { pattern: 'Invalid' };
  }

  public static getCurrentZipPattern(country: string): RegExp {
    const caValidation = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
    const usValidation = /^\d{5}(?:[-\s]\d{4})?$/;

    return country === 'CA' ? caValidation : usValidation;
  }
}
