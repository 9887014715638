import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ConfirmpasswordComponent } from './forgotpassword/confirmpassword/confirmpassword.component';
import { SetnewpasswordComponent } from './setnewpassword/setnewpassword.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { SignUpComponent } from './sign-up/sign-up.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, LoginRoutingModule],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ConfirmpasswordComponent,
    SetnewpasswordComponent,
    VerifyemailComponent,
    SignUpComponent,
  ],
})
export class LoginModule {}
