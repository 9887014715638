import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ChildIdNamePairDTO,
  CompanyAddressDTO,
  CompanyDTO,
  DebtorAddressMatchingDTO,
  FlexibleAddressDTO,
  SimpleContactDTO,
} from 'src/apiclient/v1.1/models';
import { CompanyAddressService, CompanyService } from 'src/apiclient/v1.1/services';
import { DtoFormBase } from 'src/app/shared/FormBase';
import { Validators } from '@angular/forms';
import { DisplayBillToCompanyDTO } from 'src/app/shared/interfaces/DisplayBillToCompanyDTO';
import { Observable, of, Subject } from 'rxjs';
import { ClientTokenService } from 'src/services/client-token-service';
import { ApiValidators } from 'src/utils/api-validators';
import { CompanyAddressQuickAdd } from 'src/app/shared/models/CompanyAddressQuickAdd';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyAddressType } from './company-quick-add-type';
import { CompanySelectorComponent } from 'src/app/components/company-selector/company-selector.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyAddressConflictService } from 'src/services/company-address-conflict-service';
import { State } from 'src/services/states-service';

@Component({
  selector: 'app-company-address-quick-add',
  templateUrl: './company-address-quick-add.component.html',
  styleUrls: ['./company-address-quick-add.component.scss'],
})
// tslint:disable:one-line
export class CompanyAddressQuickAddComponent
  extends DtoFormBase<CompanyAddressQuickAdd>
  implements OnInit, AfterViewInit, OnDestroy
{
  // tslint:enable:one-line
  @Input() visible: boolean;
  @Input() quickAddType: CompanyAddressType;
  @Input() companyName: string;
  @Input() inputCompanyAddressId: number;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() dialogClosedEvent = new EventEmitter<void>();
  @Output() companySelectedEvent = new EventEmitter<DisplayBillToCompanyDTO>();
  @ViewChild('companySelector') companySelector: CompanySelectorComponent;

  public title: string;
  public disableSave: boolean = false;
  public factoringStatus$: Observable<boolean>;
  public selectedCompanyAddress: CompanyAddressDTO;
  private destroy$ = new Subject();

  constructor(
    private companyService: CompanyService,
    private companyAddressService: CompanyAddressService,
    private clientTokenService: ClientTokenService,
    private companyAddressConflictService: CompanyAddressConflictService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setupForm();
    this.setZipValidator('US');
    this.initCompanyRole();
    this.factoringStatus$ = this.clientTokenService.isFactoringEnabledForTenant();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.companySelector.setNewCompanyName(this.companyName);
      this.companySelector.autoComplete.focusInput();
      if (this.inputCompanyAddressId) {
        this.populateFormWithCompanyAddressRecord();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public closeDialog(): void {
    this.visible = false;
    this.disableSave = false;
    this.visibleChange.emit();
    this.dialogClosedEvent.emit();
  }

  public companySelected(event: ChildIdNamePairDTO): void {
    this.toggleDebtorRelatedReadonlyFields(true);

    const formControls = this.formGroup.controls;
    if (formControls.name.value !== event.name) {
      this.formGroup.patchValue({
        name: event.name,
      } as Partial<CompanyAddressQuickAdd>);
      formControls.companyAddressId.setValue(0);
    }
  }

  public companyAddressSelected(): void {
    this.toggleDebtorRelatedReadonlyFields(true);

    const companyAddressId = this.getFormValue().companyAddressId;
    if (companyAddressId) {
      this.companyAddressService
        .GetCompanyAddressById({
          id: companyAddressId,
          Authorization: this.clientToken.auth(),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => this.updateFormWithValuesFromExistingCompany(data));
    } else {
      this.clearFormValues();
    }
  }

  public getAddress(): FlexibleAddressDTO {
    const quickAdd: CompanyAddressQuickAdd = this.getFormValue();
    return {
      line1: quickAdd.line1,
      line2: quickAdd.line2,
      city: quickAdd.city,
      state: quickAdd.state,
      zip: quickAdd.zip,
    } as FlexibleAddressDTO;
  }

  public suggestedAddressSelected(debtor: DebtorAddressMatchingDTO): void {
    const isSubDebtor = debtor.subDebtorId;

    isSubDebtor ? this.subDebtorSuggestionSelected(debtor) : this.masterDebtorSuggestionSelected(debtor);
  }

  public save(): void {
    if (!this.isFormValidForSave()) {
      return;
    }

    this.disableSave = true;
    const quickAdd: CompanyAddressQuickAdd = this.getFormValue();
    this.saveCompanyAddress(quickAdd)
      .pipe(
        catchError((error) => this.handleSaveError(error)),
        finalize(() => (this.disableSave = false)),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const tmp = {
          companyAddressId: data.companyAddressId,
          companyName: data.name,
          companyDisplayName: data.displayName,
          address: data.address,
        } as DisplayBillToCompanyDTO;

        this.selectCompanyAndCloseDialog(tmp);
      });
  }

  public isBillToRoleValid(): boolean {
    const quickAdd: CompanyAddressQuickAdd = this.getFormValue();
    return quickAdd.isBillTo || quickAdd.isShipper || quickAdd.isConsignee;
  }

  public showBillToRoleValidation(): boolean {
    return (
      this.formGroup.controls['isBillTo'].touched ||
      this.formGroup.controls['isShipper'].touched ||
      this.formGroup.controls['isConsignee'].touched
    );
  }

  public shouldShowAddressSuggestions(): boolean {
    const quickAdd: CompanyAddressQuickAdd = this.getFormValue();
    const companySelected = !!quickAdd.companyId;
    const companyAddressSelected = !!quickAdd.companyAddressId;
    const companyAddressPassedIn = !!this.inputCompanyAddressId;

    return !companySelected || (companySelected && !companyAddressSelected) || companyAddressPassedIn;
  }

  public onStateChange(event: State) {
    if (event && event.country) {
      const country = event.country;

      this.setZipValidator(country);
    }
  }

  private masterDebtorSuggestionSelected(debtor: DebtorAddressMatchingDTO): void {
    this.updateFormWithValuesFromExistingDebtor(debtor);
    this.formGroup.controls['isBillTo'].setValue(true);

    this.formGroup.get('name').disable();
    this.formGroup.get('companyAddressId').disable();
    this.formGroup.get('line1').disable();
    this.formGroup.get('line2').disable();
    this.formGroup.get('city').disable();
    this.formGroup.get('state').disable();
    this.formGroup.get('zip').disable();
    this.formGroup.get('isBillTo').disable();

    this.toggleDebtorRelatedReadonlyFields(false);

    this.formGroup.clearValidators();
  }

  private subDebtorSuggestionSelected(debtor: DebtorAddressMatchingDTO): void {
    const tmp = {
      companyAddressId: debtor.companyAddressId,
      companyName: debtor.companyName,
      companyDisplayName: `${debtor.companyName} - ${debtor.address.city}, ${debtor.address.state}`,
      address: debtor.address,
    } as DisplayBillToCompanyDTO;

    this.selectCompanyAndCloseDialog(tmp);
  }

  private selectCompanyAndCloseDialog(selectedCompany: DisplayBillToCompanyDTO): void {
    this.closeDialog();

    this.companySelectedEvent.emit(selectedCompany);
  }

  private updateFormWithValuesFromExistingDebtor(debtor: DebtorAddressMatchingDTO): void {
    const newFormModelValues: CompanyAddressQuickAdd = {
      name: this.inputCompanyAddressId ? this.formGroup.get('name').value : debtor.companyName,
      addressName: '',
      companyId: this.formGroup.get('companyId').value,
      phoneNumber: debtor.phoneNumber,
      email: debtor.emailAddress,
      line1: debtor.address.line1,
      line2: debtor.address.line2,
      city: debtor.address.city,
      state: debtor.address.state,
      zip: debtor.address.zip,
      mcNumber: debtor.mcNumber,
      dotNumber: debtor.dotNumber,
      firstName: this.formGroup.get('firstName').value,
      lastName: this.formGroup.get('lastName').value,
    };

    this.patchFormValues(newFormModelValues);
  }

  private populateFormWithCompanyAddressRecord() {
    this.formGroup.controls['companyAddressId'].setValue(this.inputCompanyAddressId);
    this.companyAddressSelected();
  }

  private updateFormWithValuesFromExistingCompany(companyAddress: CompanyAddressDTO): void {
    this.selectedCompanyAddress = companyAddress;

    const newFormModelValues: CompanyAddressQuickAdd = {
      addressName: companyAddress.name,
      companyId: companyAddress.companyId,
      companyAddressId: companyAddress.companyAddressId,
      phoneNumber: companyAddress.primaryContact.phoneNumber,
      email: companyAddress.primaryContact.email,
      firstName: companyAddress.primaryContact.firstName,
      lastName: companyAddress.primaryContact.lastName,
      line1: companyAddress.address.line1,
      line2: companyAddress.address.line2,
      city: companyAddress.address.city,
      state: companyAddress.address.state,
      zip: companyAddress.address.zip,
      mcNumber: companyAddress.mcNumber,
      dotNumber: companyAddress.dotNumber,
      isBillTo: companyAddress.isBillTo,
      isShipper: companyAddress.isShipper,
      isConsignee: companyAddress.isConsignee,
    };

    if (companyAddress.factorCloudSubDebtorId) {
      this.toggleDebtorRelatedReadonlyFields(false);
    }

    this.patchFormValues(newFormModelValues);
  }

  private clearFormValues(): void {
    this.formGroup.reset();
    this.selectedCompanyAddress = null;
  }

  private patchFormValues(model: CompanyAddressQuickAdd): void {
    this.formGroup.patchValue({ ...model });
  }

  private initCompanyRole() {
    const titles: Record<CompanyAddressType, string> = {
      [CompanyAddressType.BillTo]: 'Bill To',
      [CompanyAddressType.Shipper]: 'Shipper',
      [CompanyAddressType.Consignee]: 'Consignee',
    };

    if (this.quickAddType) {
      const titleVerb = this.inputCompanyAddressId ? 'Verify' : 'Add';
      this.title = `${titleVerb} ${titles[this.quickAddType]}`;
      this.formGroup.controls[this.quickAddType].setValue(true);
    }
  }

  private setZipValidator(country: String): void {
    this.formGroup.controls['zip'].clearValidators();
    let zipPattern;
    let zipPatternMessage;
    if (country === 'CA') {
      zipPatternMessage = 'Zip must be in the formats: T2X 1V4 or T2X1V4';
      zipPattern = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
    } else {
      zipPatternMessage = 'Zip must be in the formats: 12345, 12345-1234, or 12345 1234';
      zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    }
    const theValidator = Validators.pattern(zipPattern);

    this.validationMessages['zip'] = {
      required: 'Zip is required.',
      pattern: zipPatternMessage,
    };

    this.formGroup.controls['zip'].setValidators([theValidator, Validators.required]);
    this.formGroup.controls['zip'].updateValueAndValidity();
  }

  private isFormValidForSave(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formValid() && this.isBillToRoleValid();
  }

  private setupForm(): void {
    const fieldsByDto: Record<string, string[]> = {
      CompanyDTO: ['name'],
      CompanyAddressDTO: ['isBillTo', 'isShipper', 'isConsignee', 'mcNumber', 'dotNumber'],
      AddressDTO: ['line1', 'line2', 'city', 'state', 'zip'],
      SimpleContactDTO: ['lastName', 'email'],
    };
    const formGroup = new FormGroup({});
    const validationMessages: Object[] = [];

    // Create form using multiple DTOs and their associated validators.
    Object.keys(fieldsByDto).forEach((dto) => {
      const validator = this.validatorsService.getByName(dto);
      validationMessages.push(ApiValidators.getValidationMessagesForFormGroup(validator));
      fieldsByDto[dto].forEach((field) =>
        formGroup.addControl(field, new FormControl(null, ApiValidators.getValidatorsForFormField(field, validator)))
      );
    });

    // These controls are added separate since they have no validation.
    formGroup.addControl('companyId', new FormControl());
    formGroup.addControl('addressName', new FormControl());
    formGroup.addControl('companyAddressId', new FormControl());

    // In the API, FirstName and PhoneNumber are required fields for each contact (Bill-To, Shipper, Consignee) in SimpleContactDTO.
    // Because they are not using a [Required] attribute and are validated using custom logic in `.IsValid` of the DTO, the validation rules aren't captured in Angular.
    // Therefore, the required validator and associated validation message must be added separately.
    const simpleContactValidator = this.validatorsService.getByName('SimpleContactDTO');
    const simpleContactValidationMessages = ApiValidators.getValidationMessagesForFormGroup(simpleContactValidator);
    const requiredFields = ['firstName', 'phoneNumber'];
    requiredFields.forEach((field) => {
      const fieldValidators = ApiValidators.getValidatorsForFormField(field, simpleContactValidator);
      // Uppercase first letter to match existing validation messages
      simpleContactValidationMessages[field].required = `${field.charAt(0).toUpperCase()}${field.slice(
        1
      )} is required.`;
      formGroup.addControl(field, new FormControl(null, [...fieldValidators, Validators.required]));
    });
    validationMessages.push(simpleContactValidationMessages);

    this.formGroup = formGroup;
    // Flatten validation message objects into a single validation messages object.
    this.validationMessages = validationMessages.reduce(
      (acc, validationMessage) => ({ ...acc, ...validationMessage }),
      {}
    );
  }

  private saveCompanyAddress(quickAdd: CompanyAddressQuickAdd): Observable<CompanyAddressDTO> {
    if (!quickAdd.companyId) {
      return this.createCompany(quickAdd);
    } else {
      return this.getCompany(quickAdd.companyId).pipe(
        switchMap((company) => {
          // Edit Company status to be active before adding/editing an address.
          const editCompanyStatus$: Observable<void> =
            company.status === 'Inactive' ? this.updateCompanyToBeActive(quickAdd.companyId) : of(null);
          return editCompanyStatus$.pipe(switchMap(() => of(company)));
        }),
        switchMap((company) => {
          const address = this.mapFormToCompanyAddress(company, quickAdd);
          const existingAddress = company.addresses.find((a) => a.companyAddressId === address.companyAddressId);

          if (!address.companyAddressId && existingAddress) {
            address.companyAddressId = existingAddress.companyAddressId;
          }

          return !address.companyAddressId ? this.createCompanyAddress(address) : this.updateCompanyAddress(address);
        })
      );
    }
  }

  private getCompany(companyId: number): Observable<CompanyDTO> {
    return this.companyService.GetCompanyById({
      Authorization: this.clientTokenService.auth(),
      id: companyId,
    });
  }

  private createCompany(quickAdd: CompanyAddressQuickAdd): Observable<CompanyAddressDTO> {
    const company = this.mapFormToCompany(quickAdd);
    const address = this.mapFormToCompanyAddress(company, quickAdd);
    address.primaryContactIsSame = true;
    address.billToContactIsSame = true;
    address.shipperContactIsSame = true;
    address.consigneeContactIsSame = true;
    address.shipperAppointmentRequired = true;
    address.consigneeAppointmentRequired = true;
    company.addresses = [address];
    return this.companyService
      .Post({ Authorization: this.clientTokenService.auth(), body: company })
      .pipe(map((companyResponse) => companyResponse.addresses[0]));
  }

  private updateCompanyToBeActive(companyId: number): Observable<CompanyDTO> {
    return this.companyService.Patch({
      Authorization: this.clientTokenService.auth(),
      id: companyId,
      body: [
        {
          value: 'Active',
          path: '/status',
          op: 'replace',
        },
      ],
    });
  }

  private createCompanyAddress(address: CompanyAddressDTO): Observable<CompanyAddressDTO> {
    return this.companyAddressService.Post({ Authorization: this.clientTokenService.auth(), body: address });
  }

  private updateCompanyAddress(address: CompanyAddressDTO): Observable<CompanyAddressDTO> {
    return this.companyAddressService
      .Put({ Authorization: this.clientTokenService.auth(), body: address, id: address.companyAddressId })
      .pipe(map(() => address));
  }

  private mapFormToCompany(quickAdd: CompanyAddressQuickAdd): CompanyDTO {
    const contact: SimpleContactDTO = {
      firstName: quickAdd.firstName,
      lastName: quickAdd.lastName,
      email: quickAdd.email,
      phoneNumber: quickAdd.phoneNumber,
    };

    const company: CompanyDTO = {
      name: quickAdd.name,
      status: 'Active',
      primaryContact: contact,
    };

    return company;
  }

  private mapFormToCompanyAddress(company: CompanyDTO, quickAdd: CompanyAddressQuickAdd): CompanyAddressDTO {
    const contact: SimpleContactDTO = {
      firstName: quickAdd.firstName,
      lastName: quickAdd.lastName,
      email: quickAdd.email,
      phoneNumber: quickAdd.phoneNumber,
    };

    const addressName = `${quickAdd.city}, ${quickAdd.state}`;
    const isIdSelected = (id: number) => (id ? id : undefined);

    const address: CompanyAddressDTO = {
      ...this.getBaseCompanyAddress(quickAdd),
      name: addressName,
      companyId: isIdSelected(quickAdd.companyId),
      displayName: `${company.name} - ${addressName}`,
      address: {
        line1: quickAdd.line1,
        line2: quickAdd.line2,
        city: quickAdd.city,
        state: quickAdd.state,
        zip: quickAdd.zip,
      },
      primaryContact: contact,
      primaryContactIsSame: this.isContactTheSame(contact, company.primaryContact),
      mcNumber: quickAdd.mcNumber,
      dotNumber: quickAdd.dotNumber,
    };

    if (quickAdd.isBillTo) {
      address.isBillTo = true;
      address.billToContact = contact;
      address.billToContactIsSame = true;
    }

    if (quickAdd.isShipper) {
      address.isShipper = true;
      address.shipperContact = contact;
      address.shipperContactIsSame = true;
    }

    if (quickAdd.isConsignee) {
      address.isConsignee = true;
      address.consigneeContact = contact;
      address.consigneeContactIsSame = true;
    }

    return address;
  }

  private getBaseCompanyAddress(quickAdd: CompanyAddressQuickAdd): CompanyAddressDTO {
    if (quickAdd.companyAddressId) {
      return this.selectedCompanyAddress;
    }

    const newCompanyAddress: CompanyAddressDTO = {
      companyAddressId: undefined,
      address: undefined,
      name: '',
    };

    return newCompanyAddress;
  }

  private isContactTheSame(contactA: SimpleContactDTO, contactB: SimpleContactDTO): boolean {
    return (
      contactA.email === contactB.email &&
      contactA.firstName === contactB.firstName &&
      contactA.lastName === contactB.lastName &&
      contactA.phoneNumber === contactB.phoneNumber
    );
  }

  private getFormValue(): CompanyAddressQuickAdd {
    // Must use `getRawValue` to get values of disabled controls when a suggestion is selected.
    return this.formGroup.getRawValue();
  }

  private handleSaveError(error: HttpErrorResponse): Observable<never> {
    if (error instanceof HttpErrorResponse && error.status === 409) {
      this.companyAddressConflictService.displayConflictingAddresses(error.error);
      throw error;
    }

    return this.handleError(error);
  }

  private toggleDebtorRelatedReadonlyFields(enableFields: boolean): void {
    const readonlyFields: (keyof CompanyAddressQuickAdd)[] = ['mcNumber', 'dotNumber', 'email', 'isBillTo'];

    readonlyFields.forEach((fieldName) => {
      const field = this.formGroup.get(fieldName);
      enableFields ? field.enable() : field.disable();
    });
  }
}
