import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/modules/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarketplaceDashboardComponent } from './dashboard/dashboard.component';
import { MarketplaceRoutingModule } from './marketplace-routing.module';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, MarketplaceRoutingModule],
  declarations: [MarketplaceDashboardComponent],
})
export class MarketplaceModule {}
