import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ResolveEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ClientTokenService } from '../../services/client-token-service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/services/environment/environment.service';

/**
 * source: http://geeklearning.io/monitor-your-angular-app-with-application-insights/
 */

@Injectable()
export class LoggingService implements OnDestroy {
  routerSubscription: Subscription;

  userProps: { [key: string]: string } = {};
  loggedInSubscription: Subscription;

  private insights: ApplicationInsights;
  private readonly appInsightsConfig = EnvironmentService.environment.appInsightsConfig;
  private readonly consoleConfig = EnvironmentService.environment.consoleConfig;
  private readonly googleAnalyticsKey = EnvironmentService.environment.googleAnalyticsKey;

  constructor(private injector: Injector, private router: Router) {
    if (this.appInsightsConfig && this.appInsightsConfig.instrumentationKey) {
      this.insights = new ApplicationInsights({
        config: {
          instrumentationKey: this.appInsightsConfig.instrumentationKey,
        },
      });
    }

    if (this.googleAnalyticsKey || (this.appInsightsConfig && this.appInsightsConfig.instrumentationKey)) {
      this.routerSubscription = this.router.events
        .pipe(filter((event) => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(activatedComponent.name, this.getRouteTemplate(event.state.root));
          }
        });

      const clientToken = this.injector.get(ClientTokenService);
      this.loggedInSubscription = clientToken.isLoggedIn().subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          const claims = clientToken.getClaims();
          this.userProps = {
            tenantId: claims.tenantId + '',
            userId: claims.userId + '',
            fullName: claims.firstName,
            roles: claims.roles.length ? claims.roles.join(',') : '',
            permissions: claims.permissions.length ? claims.permissions.join(',') : '',
          };
        } else {
          this.userProps = {};
        }
      });
    }
  }

  public loadAppInsights(): void {
    if (this.insights) {
      this.insights.loadAppInsights();
    }
  }

  public logPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    if (url && !url.startsWith('/')) {
      url = `/${url}`;
    }

    if (this.appInsightsConfig.logPageView) {
      if (this.appInsightsConfig.instrumentationKey) {
        const props = this.AddGlobalProperties({ ...properties, ...measurements, duration: duration });
        this.insights.trackPageView({
          name,
          uri: url,
          properties: props,
        });
      }

      if (this.googleAnalyticsKey && (<any>window).ga) {
        (<any>window).ga('send', 'pageview', url);
      }
    }
  }

  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    if (this.appInsightsConfig.logEvent) {
      const props = this.AddGlobalProperties({ ...properties, ...measurements });
      this.insights.trackEvent({
        name,
        properties: props,
      });
    }
  }

  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    if (this.consoleConfig.logError) {
      console.log(error);
    }
    if (this.appInsightsConfig.logError) {
      const props = this.AddGlobalProperties({ ...properties, ...measurements });
      this.insights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: props,
      });
    }
  }

  public logBadRequest(error: HttpErrorResponse, properties?: { [key: string]: string }) {
    if (this.consoleConfig.logError) {
      console.log(error);
    }

    if (this.appInsightsConfig.logError) {
      const props = this.AddGlobalProperties(properties);
      props.errors = JSON.stringify(error.error);

      this.insights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Information,
        properties: props,
      });
    }
  }

  public logTrace(message: string, properties?: { [key: string]: string }) {
    if (this.consoleConfig.logTrace) {
      console.log(message);
    }
    if (this.appInsightsConfig.logTrace) {
      const props = this.AddGlobalProperties({ ...properties });
      this.insights.trackTrace({ message, properties: props });
    }
  }

  private AddGlobalProperties(properties?: { [key: string]: string | number }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    const result = {};
    Object.keys(properties).forEach((k) => (result[k] = k.toString()));

    // add your custom properties such as app version
    return Object.assign(result, this.userProps);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  ngOnDestroy(): void {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
  }
}
