import { Component, OnInit, EventEmitter, forwardRef, Input, Output, Injector, ViewChild } from '@angular/core';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NotificationsService } from '../notifications/notifications.service';
import { Calendar } from 'primeng/calendar';

const noop = () => {};

@Component({
  selector: 'app-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateTimeSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() readonly: boolean = false;
  @Input() label: string = null;

  @Output() dateChanged: EventEmitter<any> = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();

  defaultDate = new Date();
  innerValue: Date;
  TAB_KEY: String = 'Tab';

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  dtDate: Date;
  dtTime: Date;

  @ViewChild('calendarTime', { static: true }) dtTimeField: Calendar;
  @ViewChild('calendarDate', { static: true }) dtDateField: Calendar;

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit() {
    this.defaultDate.setHours(0, 0, 0, 0);
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.dtDate = value;
      this.dtTime = value;
    }
  }
  clearDate() {
    this.innerValue = null;
    this.dtDate = null;
    this.dtTime = null;
    this.dateChanged.emit(this.innerValue);
    this.onChangeCallback(this.innerValue);
  }

  public updateDate(selectedDay: Date): void {
    this.emitNewDate(selectedDay, this.dtTime);
  }

  public updateTime(selectedTime: Date): void {
    this.emitNewDate(this.dtDate, selectedTime);
  }

  updateByInput() {
    if (this.dtDate) {
      this.updateDate(this.dtDate);
    }
  }

  updateTimeByInput(event) {
    if (!this.dtTime) {
      this.notificationsService.warn('Invalid Date', 'Please check the date and time you entered.');
      this.dtTimeField?.inputfieldViewChild?.nativeElement.focus();
    } else {
      this.updateTime(this.dtTime);
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // used for correcting tab order when tabbing through date/time pickers
  onTabDateField(event) {
    if (!event.shiftKey && event.key === this.TAB_KEY) {
      this.dtTimeField?.inputfieldViewChild?.nativeElement.focus();
    }
  }
  onTabTimeField(event) {
    if (!event.shiftKey && event.key === this.TAB_KEY) {
      this.dtTimeField.overlayVisible = false;
      this.blurEvent.emit();
    }
  }

  // needed to focus on textfield instead of time inputs
  onFocus(event) {
    setTimeout(() => {
      event.target.focus();
    });
  }

  /**
   * Combines the day, month, and year from `day` as well as the hours and minutes from `time` to create a new date.
   * @param day Uses the day, month, and year when emitted
   * @param time Uses the hours and minutes when emitted
   */
  private emitNewDate(day: Date, time: Date): void {
    // Assign a default day if not selected already.
    if (!day) {
      day = new Date(time);
    }

    const dayWithUpdatedTime = new Date(day);

    if (time) {
      // Update time of day
      const hours = time.getHours();
      const minutes = time.getMinutes();
      dayWithUpdatedTime.setHours(hours, minutes, 0, 0);
    }

    // Assign new value to respective UI elements.
    this.dtDate = new Date(dayWithUpdatedTime);
    this.dtTime = new Date(dayWithUpdatedTime);
    // Emit new date.
    this.innerValue = dayWithUpdatedTime;
    this.dateChanged.emit(this.innerValue);
    this.onChangeCallback(this.innerValue);
  }
}
