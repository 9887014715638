import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FactoringAccountExecutiveDTO } from '../../../../apiclient/v1.1/models';
import { AccountExecutivesService } from '../../../../services/account-executives/account-executives.service';
import { ClientTokenService } from '../../../../services/client-token-service';
import { Role } from '../../../data/static-data';

@Component({
  selector: 'app-account-executives',
  templateUrl: './account-executives.component.html',
  styleUrls: ['./account-executives.component.scss'],
})
export class AccountExecutivesComponent implements OnInit {
  @Input() tenantId: number;
  public accountExecutives$: Observable<FactoringAccountExecutiveDTO[]>;

  constructor(
    private clientTokenService: ClientTokenService,
    private accountExecutivesService: AccountExecutivesService
  ) {}

  public ngOnInit(): void {
    this.accountExecutives$ = this.getAccountExecutives(this.tenantId);
  }

  private getAccountExecutives(tenantId: number): Observable<FactoringAccountExecutiveDTO[]> {
    const isSuperAdmin = this.clientTokenService.hasRole(Role.SuperAdmin);
    const currentTenantId = this.clientTokenService.getClaims().tenantId;
    return isSuperAdmin && tenantId && currentTenantId !== tenantId
      ? this.accountExecutivesService.getAccountExecutivesByTenantId(this.tenantId)
      : this.accountExecutivesService.getAccountExecutives();
  }
}
