<div class="p-0 grid grid-nogutter col-12">
  <div class="col-12 p-0 flex currency-component">
    <div class="{{ labelClass }}" *ngIf="labelClass !== 'nolabel'" id="label-container">
      <div class="{{ boldLabel === true ? 'label-black' : '' }} col-12 p-0" id="label">
        {{ label }}
      </div>
    </div>

    <div id="input-container" class="{{ controlClass }}">
      <div class="ui-inputgroup {{ inputClass }}" *ngIf="showCurrency">
        <span class="ui-inputgroup-addon">$</span>
        <p-inputNumber
          class="w-full"
          styleClass="w-full"
          [formControl]="currencyControl"
          mode="decimal"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          [inputStyleClass]="alignTextRight ? 'text-right' : 'text-left'"
        ></p-inputNumber>
      </div>

      <div *ngIf="!showCurrency" class="{{ inputClass }}" id="basic-input">
        <p-inputNumber
          class="w-full"
          styleClass="w-full"
          [formControl]="currencyControl"
          mode="decimal"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          [inputStyleClass]="alignTextRight ? 'text-right' : 'text-left'"
        ></p-inputNumber>
      </div>

      <div class="formerrors {{ errorClass }} p-0" *ngIf="getFormError()">
        {{ getFormError() | formatErrorMessage }}
      </div>
    </div>
  </div>
</div>
